import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Pagination, Progress, Table } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import { getEvaluationRatingForSessions } from '../../../helpers/evaluationPlayerHelper';
import { useRegAssociationBranding } from '../../../helpers/styles';
import AppText from '../../common/AppText';
import AppTitle from '../../common/AppTitle';
import PlayerStatsCard from './PlayerStatsCard';
import AlertAction from '../../common/AlertAction';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const PlayerStatsModal = ({
  player,
  regAssociationDivisionTeamPerson,
  regAssociationDivisionTeam,
  regScoutReportEvaluationSessions,
  regAssociation,
  isOpen,
  onClose
}) => {
  const mqIndex = useMediaQueryIndex();
  const isMobileView = mqIndex === 0;
  const isTabletView = mqIndex <= 1;
  const navigate = useNavigate();
  const STATS_MAX_PER_PAGE = 5;
  const [filterState, setFilterState] = useState({ pageIndex: 1 });
  const evaluationInfo =
    regAssociationDivisionTeamPerson && regAssociationDivisionTeam
      ? {
          pkRegAssociationDivision:
            regAssociationDivisionTeam.regAssociationDivision
              .pkRegAssociationDivision,
          pkRegPerson: regAssociationDivisionTeamPerson.regPerson.pkRegPerson,
          pkRegScoutReportPerson: null
        }
      : player
      ? {
          pkRegAssociationDivision:
            player.regAssociationDivision.pkRegAssociationDivision,
          pkRegPerson: player.person.pkRegPerson,
          pkRegScoutReportPerson: player.person.pkRegScoutReportPerson
        }
      : null;

  const finalPlayerEvaluationRating = getEvaluationRatingForSessions(
    {
      pkRegAssociationDivision: evaluationInfo?.pkRegAssociationDivision,
      pkRegPerson: evaluationInfo?.pkRegPerson,
      pkRegScoutReportPerson: evaluationInfo?.pkRegScoutReportPerson
    },
    regScoutReportEvaluationSessions
  );
  const {
    primaryColor,
    secondaryColor,
    primaryTextColor,
    secondaryTextColor
  } = useRegAssociationBranding(regAssociation);

  useEffect(() => {
    if (isOpen) {
      setFilterState({ pageIndex: 1 });
    }
  }, []);

  return (
    <ResponsiveModal
      onClose={onClose}
      opened={isOpen}
      size={finalPlayerEvaluationRating.answerRatings.length === 0 ? 600 : 1100}
      title={<AppTitle order={2}>Player Stats</AppTitle>}
    >
      {finalPlayerEvaluationRating.answerRatings.length === 0 ? (
        <AlertAction
          actionLabel="Create Session"
          message="No evaluations completed for the selected player. Create a session and start evaluating the player to see their stats."
          onAction={() => {
            navigate('/admin/evaluations/sessions', {
              state: { openModal: true }
            });
          }}
        />
      ) : (
        evaluationInfo && (
          <AppStack>
            <AppFlexbox
              style={{
                gap: 20,
                flexDirection: isTabletView ? 'column' : 'row'
              }}
            >
              <AppStack
                style={{
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <AppStack
                  style={{
                    alignSelf: isMobileView ? 'stretch' : 'center',
                    width: isMobileView ? 'unset' : 300
                  }}
                >
                  <PlayerStatsCard
                    maxPerPage={STATS_MAX_PER_PAGE}
                    pageIndex={filterState.pageIndex}
                    primaryColor={primaryColor}
                    primaryTextColor={primaryTextColor}
                    ratedEvaluation={finalPlayerEvaluationRating}
                    secondaryColor={secondaryColor}
                    secondaryTextColor={secondaryTextColor}
                  />
                </AppStack>
              </AppStack>
              <AppStack style={{ flex: 1 }}>
                <Table
                  striped
                  style={{ border: `solid 2px ${primaryColor}`, flex: 1 }}
                  withRowBorders={false}
                >
                  <Table.Thead>
                    <Table.Tr style={{ backgroundColor: primaryColor }}>
                      <Table.Th colSpan={2}>
                        <AppText
                          style={{ color: primaryTextColor, fontSize: 22 }}
                          weight={500}
                        >
                          Metrics
                        </AppText>
                      </Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {finalPlayerEvaluationRating.answerRatings
                      .slice(
                        -STATS_MAX_PER_PAGE +
                          filterState.pageIndex * STATS_MAX_PER_PAGE,
                        filterState.pageIndex * STATS_MAX_PER_PAGE
                      )
                      .map((r) => (
                        <Table.Tr key={r.label}>
                          <Table.Td style={{ alignContent: 'end' }}>
                            <AppStack style={{ gap: 0, flex: 1 }}>
                              <AppText
                                style={{ textWrap: 'nowrap', fontSize: 18 }}
                                weight={500}
                              >
                                {r.label} ({r.abbreviation})
                              </AppText>
                              <AppText
                                style={{
                                  color: '#767678',
                                  height: 25,
                                  fontSize: 18
                                }}
                                weight={500}
                              >
                                {r.value}
                              </AppText>
                            </AppStack>
                          </Table.Td>
                          <Table.Td
                            style={{ width: '100%', alignContent: 'end' }}
                          >
                            <AppStack
                              style={{ height: 25, justifyContent: 'center' }}
                            >
                              <Progress
                                color={primaryColor}
                                radius="xs"
                                size="xl"
                                value={r.value * 2 * 10}
                              />
                            </AppStack>
                          </Table.Td>
                        </Table.Tr>
                      ))}
                  </Table.Tbody>
                </Table>

                {finalPlayerEvaluationRating.answerRatings.length >
                  STATS_MAX_PER_PAGE && (
                  <AppFlexbox style={{ justifyContent: 'center' }}>
                    <Pagination
                      color="blue"
                      onChange={(pageIndex) =>
                        setFilterState({
                          ...filterState,
                          pageIndex
                        })
                      }
                      total={Math.ceil(
                        finalPlayerEvaluationRating.answerRatings.length /
                          STATS_MAX_PER_PAGE
                      )}
                      value={filterState.pageIndex}
                    />
                  </AppFlexbox>
                )}
              </AppStack>
            </AppFlexbox>
          </AppStack>
        )
      )}
    </ResponsiveModal>
  );
};

PlayerStatsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  player: PropTypes.object,
  regAssociation: PropTypes.object,
  regAssociationDivisionTeam: PropTypes.object,
  regAssociationDivisionTeamPerson: PropTypes.object,
  regScoutReportEvaluationSessions: PropTypes.array
};

export default PlayerStatsModal;
