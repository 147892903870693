import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@mantine/core';

const AppText = React.forwardRef(
  ({ size, color, weight, children, ...rest }, ref) => (
    <Text
      ref={ref}
      c={color}
      size={size}
      {...rest}
      style={{
        fontWeight: weight ?? 'inherit',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ...rest.style
      }}
    >
      {children}
    </Text>
  )
);

AppText.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  weight: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default AppText;
