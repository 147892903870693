import React from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import EvaluatorInviteView from './EvaluatorInviteView';
import AdministratorInviteView from './AdministratorInviteView';

const INVITE_TYPES = {
  EVALUATOR: {
    label: 'Evaluator',
    value: 'EVALUATOR'
  },
  ADMINISTRATOR: {
    label: 'Administrator',
    value: 'ADMINISTRATOR'
  }
};

const AcceptInviteView = () => {
  const { inviteType } = useParams();
  const { search } = useLocation();
  const inviteCode = new URLSearchParams(search).get('code');
  const inviteTypeUpper = inviteType?.toUpperCase();

  return inviteTypeUpper === INVITE_TYPES.EVALUATOR.value ? (
    <EvaluatorInviteView inviteCode={inviteCode} />
  ) : inviteTypeUpper === INVITE_TYPES.ADMINISTRATOR.value ? (
    <AdministratorInviteView inviteCode={inviteCode} />
  ) : (
    <Navigate replace to="/invites" />
  );
};

export default AcceptInviteView;
