import React, { useContext, useEffect, useRef } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Plus } from 'tabler-icons-react';
import { Context as RegistrationAdminContext } from '../providers/RegistrationAdminProvider';
import { Context as RegistrationAdminDashboardContext } from '../providers/RegistrationAdminDashboardProvider';
import AdminView from '../components/common/AdminView';
import { useModalState } from '../helpers/hooks';
import ReportListView from '../components/content/reports/ReportListView';
import SavedReportTemplateListView from '../components/content/reports/SavedReportTemplateListView';
import SUPPORT_LINKS from '../components/content/navigation/supportLinks';
import AssociationReportTableView from '../components/content/reports/AssociationReportTableView';
import StaffReportView from '../components/content/reports/StaffReportView';

const VIEW_TABS = [
  {
    to: '/admin/reports/templates',
    title: 'Templates',
    value: 'templates',
    index: 0,
    control: {
      label: 'Create Report',
      icon: <Plus />,
      action: 'CREATE'
    },
    indexHelpLink: SUPPORT_LINKS.reportLinks.index,
    helpLinks: [
      SUPPORT_LINKS.reportLinks.globalReports,
      SUPPORT_LINKS.reportLinks.customReport,
      SUPPORT_LINKS.reportLinks.savedTemplates
    ]
  },
  {
    to: '/admin/reports/custom',
    title: 'Saved Reports',
    value: 'saved',
    index: 1,
    control: {
      label: 'Create Report',
      icon: <Plus />,
      action: 'CREATE'
    },
    indexHelpLink: SUPPORT_LINKS.reportLinks.index,
    helpLinks: [SUPPORT_LINKS.reportLinks.savedTemplates]
  }
];

const ReportsView = () => {
  const hasFetched = useRef(false);
  const { state: modalState, onOpenModal } = useModalState({
    closeOnOpen: true
  });
  const { state: dashboardState } = useContext(
    RegistrationAdminDashboardContext
  );
  const {
    state,
    fetchAdminRegAssociationLeagueOptions,
    fetchAdminRegReports,
    fetchAdminRegForms
  } = useContext(RegistrationAdminContext);
  const regAssociation = dashboardState.regAssociation.value;
  const isLoading = !hasFetched.current || state.regReports.loading;

  useEffect(() => {
    if (regAssociation) {
      fetchAdminRegAssociationLeagueOptions(regAssociation.pkRegAssociation);
      fetchAdminRegForms();
      fetchAdminRegReports(regAssociation.pkRegAssociation);
      hasFetched.current = true;
    }
  }, [regAssociation]);

  return (
    <Routes>
      <Route
        element={
          <AdminView
            headerTitle="Reports"
            isViewLoading={isLoading}
            onAction={onOpenModal}
            tabs={VIEW_TABS}
          >
            <ReportListView
              isLoading={isLoading}
              modalAction={modalState.isOpen ? modalState.action : ''}
              regAssociation={regAssociation}
            />
          </AdminView>
        }
        path="/templates"
      />
      <Route
        element={
          <AdminView
            headerTitle="Reports"
            isViewLoading={isLoading}
            onAction={onOpenModal}
            tabs={VIEW_TABS}
          >
            <SavedReportTemplateListView
              isLoading={isLoading}
              modalAction={modalState.isOpen ? modalState.action : ''}
              regAssociation={regAssociation}
            />
          </AdminView>
        }
        path="/custom"
      />
      <Route element={<StaffReportView />} path="/staff-report" />
      <Route
        element={<AssociationReportTableView />}
        path="/:pkRegAssociation/:pkRegReport"
      />
      <Route element={<Navigate replace to={VIEW_TABS[0].to} />} path="*" />
    </Routes>
  );
};

export default ReportsView;
