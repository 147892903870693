import React, { useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Checkbox } from '@mantine/core';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppTitle from '../../common/AppTitle';
import FormSection from '../../common/FormSection';
import AppStack from '../../common/AppStack';
import { triggerNotification } from '../../../helpers/notificationHelper';
import AppText from '../../common/AppText';

const SendOrderReceiptModal = ({
  isAdminViewing,
  isOpen,
  onClose,
  pkRegCarts,
  pkRegFormSubmissions
}) => {
  const { sendAdminRegFormSubmissionOrderReceipt } = useContext(
    RegistrationAdminContext
  );
  const [formState, setFormState] = useState({
    sendCopy: false,
    sendToRegistrant: false,
    isLoading: false
  });

  useEffect(() => {
    if (isOpen) {
      setFormState({
        sendCopy: false,
        sendToRegistrant: false,
        isLoading: false
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClose}
      title={<AppTitle order={2}>Email Receipt</AppTitle>}
    >
      <FormSection
        isLoading={formState.isLoading}
        isSubmitDisabled={
          isAdminViewing && !formState.sendCopy && !formState.sendToRegistrant
        }
        onCancel={onClose}
        onSubmit={() => {
          setFormState({
            ...formState,
            isLoading: true
          });
          sendAdminRegFormSubmissionOrderReceipt(
            [
              ...(pkRegCarts
                ? pkRegCarts.map((entityId) => ({
                    entityId,
                    entityType: 'regCart',
                    sendCopy: isAdminViewing ? formState.sendCopy : false,
                    sendToRegistrant: isAdminViewing
                      ? formState.sendToRegistrant
                      : true
                  }))
                : []),
              ...(pkRegFormSubmissions
                ? pkRegFormSubmissions.map((entityId) => ({
                    entityId,
                    entityType: 'regFormSubmission',
                    sendCopy: isAdminViewing ? formState.sendCopy : false,
                    sendToRegistrant: isAdminViewing
                      ? formState.sendToRegistrant
                      : true
                  }))
                : [])
            ],
            () => {
              triggerNotification('Receipt Sent!', 'Success', 'green');
              onClose();
            },
            (e) => {
              triggerNotification(e);
              setFormState({
                ...formState,
                isLoading: false
              });
            }
          );
        }}
        submitTitle="Send Receipt"
      >
        {isAdminViewing ? (
          <AppStack style={{ gap: 10 }}>
            <AppText weight={500}>Select who to send the receipt to;</AppText>
            <Checkbox
              disabled={formState.isLoading}
              label="Registrants mailbox"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  sendToRegistrant: e.target.checked
                })
              }
              value={formState.sendToRegistrant}
            />
            <Checkbox
              disabled={formState.isLoading}
              label="Your own mailbox"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  sendCopy: e.target.checked
                })
              }
              value={formState.sendCopy}
            />
          </AppStack>
        ) : (
          <AppStack style={{ paddingTop: 20 }}>
            <AppText style={{ textAlign: 'center' }}>
              Receipt will be sent to your mailbox
            </AppText>
          </AppStack>
        )}
      </FormSection>
    </ResponsiveModal>
  );
};

SendOrderReceiptModal.propTypes = {
  isAdminViewing: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  pkRegCarts: PropTypes.array,
  pkRegFormSubmissions: PropTypes.array
};
export default SendOrderReceiptModal;
