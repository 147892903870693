import React, { useContext, useEffect, useRef } from 'react';
import AnalyticsOverviewView from './AnalyticsOverviewView';
import { regAssociationAnalyticsAndReportsConfig } from './regAssociationAnalyticsAndReportsConfig';
import { ECOM_REPORT_ENUM } from './reportsConfig';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { Context as RegistrationAdminDashboardContext } from '../../../providers/RegistrationAdminDashboardProvider';

const RegAssociationAnalyticsView = () => {
  const hasFetched = useRef(false);
  const {
    state,
    generateAdminRegAssociationAnalyticsReport,
    fetchAdminRegAssociationLeagueOptions
  } = useContext(RegistrationAdminContext);
  const { state: dashboardState } = useContext(
    RegistrationAdminDashboardContext
  );
  const reportConfig = regAssociationAnalyticsAndReportsConfig.reports.find(
    (c) => c.uuid === ECOM_REPORT_ENUM.OVERVIEW
  );
  const regAssociation = dashboardState.regAssociation.value;

  const loading =
    !hasFetched.current ||
    state.regAssociationAnalyticsReport.loading ||
    reportConfig.uuid !== state.regAssociationAnalyticsReport.value?.uuid;

  useEffect(() => {
    if (regAssociation) {
      fetchAdminRegAssociationLeagueOptions(regAssociation?.pkRegAssociation);
    }
  }, [regAssociation?.pkRegAssociation]);

  const onFetchAnalyticsOverview = (filter) => {
    const groupByPeriod = reportConfig.groupBy === 'period';
    const compareEnabled = groupByPeriod && reportConfig.compareEnabled;
    generateAdminRegAssociationAnalyticsReport(
      regAssociation.pkRegAssociation,
      {
        uuid: reportConfig.uuid,
        headers: reportConfig.defaultHeaders,
        groupBy: reportConfig.groupBy,
        ...filter,
        periodInterval:
          filter.compareBy === 'season' || filter.compareBy === 'division'
            ? null
            : groupByPeriod
            ? filter.periodInterval
            : null,
        compareStartDate: compareEnabled ? filter.compareStartDate : null,
        compareEndDate: compareEnabled ? filter.compareEndDate : null
      }
    );
    hasFetched.current = true;
  };

  return (
    <AnalyticsOverviewView
      analyticConfigs={regAssociationAnalyticsAndReportsConfig.analytics}
      entityId={regAssociation?.pkRegAssociation}
      loading={loading}
      onFetchOverview={onFetchAnalyticsOverview}
      reportResult={state.regAssociationAnalyticsReport.value}
      urlBase="/admin/analytics"
    />
  );
};

export default RegAssociationAnalyticsView;
