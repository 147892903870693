import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { RingProgress } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import { getEvaluationEntryProgress } from '../../../helpers/evaluationPlayerHelper';
import AppStack from '../../common/AppStack';

const EvaluationSessionEntryItem = ({
  regScoutReportEvaluationSessionEntry,
  regScoutReportEvaluationSession,
  menuContent,
  onClick
}) => {
  const { hovered, ref } = useHover();

  const completedPercentage = getEvaluationEntryProgress(
    regScoutReportEvaluationSessionEntry,
    regScoutReportEvaluationSession
  );
  const isCompleted = completedPercentage >= 100;

  return (
    <AppFlexbox
      ref={ref}
      onClick={onClick}
      style={{
        padding: 10,
        justifyContent: 'space-between',
        border:
          hovered && onClick ? 'solid 1px dodgerblue' : 'solid 1px lightgrey',
        cursor: onClick ? 'pointer' : 'default',
        alignItems: 'center'
      }}
    >
      <AppFlexbox style={{ gap: 10, alignItems: 'center' }}>
        <RingProgress
          label={
            <AppText
              style={{
                textAlign: 'center',
                fontSize: 12
              }}
              weight={700}
            >
              <NumberFormat
                allowNegative={false}
                decimalScale={0}
                displayType="text"
                fixedDecimalScale
                suffix="%"
                value={completedPercentage}
              />
            </AppText>
          }
          sections={[
            {
              value: { completedPercentage },
              color: isCompleted ? '#40C057' : '#FCC419'
            }
          ]}
          size={50}
          thickness={3}
        />
        <AppStack style={{ gap: 0 }}>
          <AppText style={{ fontSize: 14 }} weight={500}>
            {regScoutReportEvaluationSessionEntry.regAssociationAdmin.user.name}
          </AppText>
          <AppText style={{ fontSize: 12, color: 'darkgrey' }} weight={500}>
            {
              regScoutReportEvaluationSessionEntry.regAssociationAdmin.user
                .email
            }
          </AppText>
        </AppStack>
      </AppFlexbox>

      {menuContent}
    </AppFlexbox>
  );
};

EvaluationSessionEntryItem.propTypes = {
  menuContent: PropTypes.node,
  onClick: PropTypes.func,
  regScoutReportEvaluationSession: PropTypes.object,
  regScoutReportEvaluationSessionEntry: PropTypes.object
};

export default EvaluationSessionEntryItem;
