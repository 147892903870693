import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import AppCard from '../../common/AppCard';
import TableView from '../../common/TableView';
import { useModalState, useSortByFilter } from '../../../helpers/hooks';
import { formatUtcDate } from '../../../helpers/format';
import ColoredAvatar from '../../common/ColoredAvatar';
import MerchPackageOrderRefundModal from './MerchPackageOrderRefundModal';
import MerchPackageOrderPaymentsModal from './MerchPackageOrderPaymentsModal';
import OrderDetailsModal from '../orders/OrderDetailsModal';
import MerchPackageModal from './MerchPackageModal';

const VIEW_ACTIONS_ENUM = {
  details: 'DETAILS',
  cart: 'CART',
  payments: 'PAYMENTS',
  refund: 'REFUND',
  email: 'EMAil'
};

const VIEW_ACTIONS = [
  {
    label: 'View Details',
    value: VIEW_ACTIONS_ENUM.details
  },
  {
    label: 'View Cart',
    value: VIEW_ACTIONS_ENUM.cart
  },
  {
    label: 'Email Contact',
    value: VIEW_ACTIONS_ENUM.email
  },
  {
    label: 'View Payments',
    value: VIEW_ACTIONS_ENUM.payments
  },
  {
    label: 'Refund',
    value: VIEW_ACTIONS_ENUM.refund,
    showWhenAccepted: true
  }
];

const TABLE_COLUMNS = [
  {
    label: 'Name',
    value: 'name',
    sortable: true
  },
  {
    label: 'Merch Package',
    value: 'merchPackage',
    sortable: true
  },
  {
    label: 'Status',
    value: 'status',
    sortable: true
  },
  {
    label: 'Date Purchased',
    value: 'date',
    sortable: true
  }
];

const MerchOrders = ({ regAssociation }) => {
  const hasFetched = useRef(false);
  const searchRef = useRef('');
  const { state, fetchMerchPackageOrders } = useContext(
    RegistrationAdminContext
  );
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const { customFilterData } = useSortByFilter(TABLE_COLUMNS);
  const isLoading =
    !hasFetched.current ||
    state.regAssociations.loading ||
    state.paginatedRegCartMerchPackages.loading;
  const {
    pageIndex,
    totalCount,
    data: regCartMerchPackages,
    filter
  } = state.paginatedRegCartMerchPackages;
  const currentFilter = filter || {};
  const isDescendingSort = currentFilter.orderBy === 'desc';

  const merchPackageFilterOptions = regCartMerchPackages.reduce((r, c) => {
    if (!r.find((f) => f.value === c.merchPackage.uuid)) {
      r.push({
        label: c.merchPackage.name,
        value: c.merchPackage.uuid
      });
    }
    return r;
  }, []);

  useEffect(() => {
    if (regAssociation) {
      fetchMerchPackageOrders({
        fkRegAssociation: regAssociation.pkRegAssociation
      });
      hasFetched.current = true;
    }
  }, [regAssociation]);

  const onFetchMore = () => {
    fetchMerchPackageOrders(
      {
        fkRegAssociation: regAssociation.pkRegAssociation,
        ...currentFilter,
        page: pageIndex + 1
      },
      true
    );
  };

  const onFilter = (newFilter) => {
    fetchMerchPackageOrders({
      fkRegAssociation: regAssociation.pkRegAssociation,
      ...newFilter
    });
  };

  return (
    <AppCard style={{ flex: 1, padding: 0, height: '100%' }}>
      <TableView
        columns={TABLE_COLUMNS}
        emptyMessage="No Merch Order Available"
        filters={[
          {
            key: 1,
            label: 'Merch Package',
            type: 'select',
            value: currentFilter.merchPackageUuid,
            placeholder: 'Filter by merch',
            data: merchPackageFilterOptions,
            clearable: true,
            onChange: (value) => {
              onFilter({
                ...currentFilter,
                merchPackageUuid: value
              });
            }
          },
          {
            key: 2,
            label: 'Status',
            type: 'select',
            value: currentFilter.status || null,
            placeholder: 'Filter by status',
            searchable: true,
            clearable: true,
            data: [
              { value: 'paid', label: 'Paid' },
              { value: 'unpaid', label: 'Pending' },
              { value: 'refunded', label: 'Refunded' }
            ],
            onChange: (value) =>
              onFilter({
                ...currentFilter,
                status: value
              })
          },
          {
            key: 3,
            label: 'Customer',
            placeholder: 'Filter by customer',
            value: currentFilter.search || '',
            untrackedValue: true,
            onBlur: () =>
              searchRef.current === currentFilter.search
                ? onFilter({
                    ...currentFilter,
                    search: ''
                  })
                : onFilter({
                    ...currentFilter,
                    search: searchRef.current
                  }),
            onChange: (value, clearInput) => {
              if (clearInput) {
                onFilter({
                  ...currentFilter,
                  search: ''
                });
              }
              else {
                searchRef.current = value;
              }
            }
          }
        ]}
        isDescendingSort={isDescendingSort}
        isLoading={isLoading}
        lastUpdated={state.paginatedRegCartMerchPackages.lastUpdated}
        noSort
        onAction={(action, item) => {
          if (action === VIEW_ACTIONS_ENUM.email) {
            window.open(`mailto:${item.email}`);
          }
          else {
            onOpenModal(
              action,
              regCartMerchPackages.find(
                (s) => s.pkRegCartMerchPackage === item.key
              )
            );
          }
        }}
        onChangeSortBy={(sortBy, isDescending) => {
          if (
            sortBy !== currentFilter.sortBy ||
            isDescending !== isDescendingSort
          ) {
            fetchMerchPackageOrders({
              fkRegAssociation: regAssociation.pkRegAssociation,
              ...currentFilter,
              sortBy,
              orderBy: isDescending ? 'desc' : 'asc'
            });
          }
        }}
        onFetchMore={onFetchMore}
        onRefresh={() =>
          fetchMerchPackageOrders({
            fkRegAssociation: regAssociation.pkRegAssociation,
            ...currentFilter
          })
        }
        rows={regCartMerchPackages.map((a) => {
          const isFullyRefunded =
            a.regCartMerchPackagePayments.reduce(
              (r, c) =>
                c.fkRegFormSubmissionPaymentType === 5
                  ? r - (c.amount > 0 ? c.amount : c.amount * -1)
                  : r + c.amount,
              0
            ) === 0;
          const isPartiallyRefunded = a.regCartMerchPackagePayments.some(
            (p) => p.fkRegFormSubmissionPaymentType === 5
          );

          const statusInfo =
            a.regCartMerchPackagePayments.length === 0
              ? {
                  label: 'Unpaid',
                  color: 'blue'
                }
              : !isPartiallyRefunded
              ? {
                  label: 'Paid',
                  color: 'green'
                }
              : {
                  label: isFullyRefunded ? 'Refunded' : 'Partially Refunded',
                  color: 'red'
                };

          return {
            key: a.pkRegCartMerchPackage,
            actions: VIEW_ACTIONS.filter(
              (f) => !f.showWhenAccepted || statusInfo.label === 'Paid'
            ),
            email: a.regCart.user.email,
            columns: [
              {
                key: 1,
                label: a.regCart.user.name,
                weight: 500,
                subLabel: a.regCart.user.email,
                onSubLabelClick: () =>
                  window.open(`mailto:${a.regCart.user.email}`),
                icon: (
                  <ColoredAvatar
                    name={a.regCart.user.name}
                    uniqueId={a.regCart.user.email}
                  />
                ),
                showOnMobile: true
              },
              {
                key: 2,
                label: a.merchPackage.name,
                showOnMobile: true
              },
              {
                key: 3,
                label: statusInfo.label,
                color: statusInfo.color,
                showOnMobile: true
              },
              {
                key: 4,
                label: a.regCartMerchPackagePayments[0]
                  ? dayjs(
                      formatUtcDate(a.regCartMerchPackagePayments[0].createdAt)
                    ).format('MMM D, YYYY')
                  : null,
                showOnMobile: true,
                sortValue: a.regCartMerchPackagePayments[0]
                  ? new Date(a.regCartMerchPackagePayments[0].createdAt)
                  : null,
                sortValueType: 'date'
              }
            ]
          };
        })}
        sortBy={currentFilter.sortBy || TABLE_COLUMNS[0].value}
        sortFilter={customFilterData}
        tableTitle="Payee"
        totalCount={totalCount}
      />

      <MerchPackageModal
        isDisabled
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.details
        }
        merchPackage={modalState.item?.merchPackage}
        onClose={onCloseModal}
        regCartMerchPackage={modalState.item}
      />

      <OrderDetailsModal
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.cart
        }
        onClose={onCloseModal}
        pkRegCart={modalState.item?.regCart.pkRegCart}
      />

      <MerchPackageOrderPaymentsModal
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.payments
        }
        onClose={onCloseModal}
        regCartMerchPackage={modalState.item}
      />

      <MerchPackageOrderRefundModal
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.refund
        }
        onClose={onCloseModal}
        onRefundComplete={onCloseModal}
        regCartMerchPackage={modalState.item}
      />
    </AppCard>
  );
};

MerchOrders.propTypes = { regAssociation: PropTypes.object };

export default MerchOrders;
