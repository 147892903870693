import React, { useContext, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Context as RegistrationContext } from '../providers/RegistrationProvider';
import RegistrantWaitlist from '../components/content/waitlist/RegistrantWaitlist';

const WaitlistView = () => {
  const { fetchWaitlistRegistrants } = useContext(RegistrationContext);

  useEffect(() => {
    fetchWaitlistRegistrants();
  }, []);

  return (
    <Routes>
      <Route element={<RegistrantWaitlist />} path="/" />
      <Route element={<Navigate replace to="/waitlist" />} path="*" />
    </Routes>
  );
};

export default WaitlistView;
