import React from 'react';
import { PropTypes } from 'prop-types';
import { Badge, ThemeIcon } from '@mantine/core';
import dayjs from 'dayjs';
import { Check, X } from 'tabler-icons-react';
import { formatUtcDate } from '../../../helpers/format';
import ActionableListItem from '../../common/ActionableListItem';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const RegistrationListItem = ({
  regAssociation,
  regFormSubmission,
  onAction,
  showStatus
}) => {
  const mqIndex = useMediaQueryIndex();
  const acceptedActions =
    regFormSubmission?.status === 'ACCEPTED'
      ? [
          {
            label: 'Refund',
            value: 'REFUND'
          }
        ]
      : [];
  const nonRefundedActions =
    regFormSubmission?.status !== 'REFUNDED'
      ? [
          {
            label: 'Change Division',
            value: 'CHANGE-DIVISION'
          },
          {
            label: 'Invoice User',
            value: 'INVOICE'
          }
        ]
      : [];
  const deniedActions =
    regFormSubmission?.status === 'DENIED'
      ? [
          {
            label: 'Reinstate',
            value: 'REINSTATE'
          }
        ]
      : [];

  const viewPaymentActions =
    regFormSubmission?.status === 'ACCEPTED' ||
    regFormSubmission?.status === 'PENDING' ||
    regFormSubmission?.status === 'PENDING_PAYMENT'
      ? [
          {
            label: 'Deny',
            value: 'DENY'
          },
          {
            label: 'Record Payment',
            value: 'RECORD_PAYMENT'
          }
        ]
      : [
          {
            label: 'View Payments',
            value: 'RECORD_PAYMENT'
          }
        ];

  return regFormSubmission ? (
    <ActionableListItem
      badge={
        showStatus && (
          <AppStack
            style={{
              alignSelf: rs(['end', 'end', 'center'], mqIndex),
              justifyContent: 'center',
              flex: rs(['unset', 1], mqIndex),
              placeItems: rs(['end', 'end', 'center'], mqIndex),
              textAlign: 'center'
            }}
          >
            {regFormSubmission.status === 'ACCEPTED' ? (
              <Badge
                color="green"
                size="lg"
                style={{ display: rs(['none', 'block'], mqIndex), width: 125 }}
                variant="filled"
              >
                {regFormSubmission.status}
              </Badge>
            ) : regFormSubmission.status === 'DENIED' ? (
              <Badge
                color="red"
                size="lg"
                style={{ display: rs(['none', 'block'], mqIndex), width: 125 }}
                variant="filled"
              >
                {regFormSubmission.status}
              </Badge>
            ) : regFormSubmission.status === 'REFUNDED' ? (
              <Badge
                color="gray"
                size="lg"
                style={{ display: rs(['none', 'block'], mqIndex), width: 125 }}
                variant="filled"
              >
                {regFormSubmission.status}
              </Badge>
            ) : (
              <Badge
                color="blue"
                size="lg"
                style={{ display: rs(['none', 'block'], mqIndex), width: 125 }}
                variant="filled"
              >
                {regFormSubmission.status === 'PENDING_PAYMENT'
                  ? 'PENDING'
                  : regFormSubmission.status}
              </Badge>
            )}
          </AppStack>
        )
      }
      description={regFormSubmission.regAssociationDivisionForm.regForm.name}
      extraDescription={[
        `${dayjs(formatUtcDate(regFormSubmission.submittedAt)).format(
          'MMM D, YYYY'
        )}`
      ]}
      extraLabels={[
        `${dayjs(formatUtcDate(regFormSubmission.regPerson.dob)).format(
          'MM/DD/YYYY'
        )}`
      ]}
      image={regAssociation?.logoImageUrl}
      indicator={
        showStatus &&
        (regFormSubmission.status === 'ACCEPTED' ? (
          <ThemeIcon
            color="green"
            radius="xl"
            style={{
              display: rs(['flex', 'none'], mqIndex),
              placeItems: 'center',
              alignSelf: 'start',
              padding: 3
            }}
          >
            <Check />
          </ThemeIcon>
        ) : regFormSubmission.status === 'DENIED' ? (
          <ThemeIcon
            color="red"
            radius="xl"
            style={{
              display: rs(['flex', 'none'], mqIndex),
              placeItems: 'center',
              alignSelf: 'start',
              padding: 3
            }}
          >
            <X />
          </ThemeIcon>
        ) : regFormSubmission.status === 'REFUNDED' ? (
          <ThemeIcon
            color="red"
            radius="xl"
            style={{
              display: rs(['flex', 'none'], mqIndex),
              placeItems: 'center',
              alignSelf: 'start',
              padding: 3
            }}
          >
            <X />
          </ThemeIcon>
        ) : (
          <ThemeIcon
            color="blue"
            radius="xl"
            style={{
              display: rs(['flex', 'none'], mqIndex),
              placeItems: 'center',
              textAlign: 'center',
              alignSelf: 'start',
              padding: 3
            }}
          >
            <AppText style={{ fontSize: 18 }} weight={500}>
              !
            </AppText>
          </ThemeIcon>
        ))
      }
      label={`${regFormSubmission.regPerson.firstName} 
    ${regFormSubmission.regPerson.lastName}`}
      menuActions={
        onAction
          ? [
              {
                label: 'View',
                value: 'VIEW'
              },
              {
                label: 'Order Summary',
                value: 'ORDER_SUMMARY'
              },
              {
                label: 'Email Contact',
                value: 'EMAIL'
              },
              ...nonRefundedActions,
              ...acceptedActions,
              ...deniedActions,
              ...viewPaymentActions
            ]
          : null
      }
      onAction={onAction}
      reverseMiddleColumns
      showImage={!!regAssociation}
      subDescription={
        regFormSubmission.regAssociationDivisionOverride?.name ??
        regFormSubmission.regAssociationDivisionForm.regAssociationDivision.name
      }
      subLabel={regFormSubmission.overrideEmail ?? regFormSubmission.user.email}
    />
  ) : (
    <ActionableListItem centerBadge isLoading reverseMiddleColumns showImage />
  );
};

RegistrationListItem.propTypes = {
  onAction: PropTypes.func,
  regAssociation: PropTypes.object,
  regFormSubmission: PropTypes.object,
  showStatus: PropTypes.bool
};

export default RegistrationListItem;
