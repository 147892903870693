import React from 'react';
import PropTypes from 'prop-types';

const TeamBuilderView = ({}) => {
  const t = 1;
  return <></>;
};

TeamBuilderView.propTypes = {};

export default TeamBuilderView;
