import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import FormBuilder from '../components/content/Forms/FormBuilder';
import FormView from '../components/content/Forms/FormView';

const FormsView = () => (
  <Routes>
    <Route element={<FormView />} path="/" />
    <Route element={<FormBuilder />} path="/:pkRegAssociation/:uuid" />
    <Route element={<Navigate replace to="/admin/forms" />} path="*" />
  </Routes>
);

export default FormsView;
