import React from 'react';
import { PropTypes } from 'prop-types';
import PaginationList from '../../common/PaginationList';
import RegistrationListItem from './RegistrationListItem';
import RegistrationListActionModals from './RegistrationListActionModals';
import AppStack from '../../common/AppStack';
import { useModalState } from '../../../helpers/hooks';

const RegistrationList = ({
  regAssociation,
  isLoading,
  regFormSubmissions,
  pageIndex,
  onPageChange,
  onRefresh
}) => {
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();

  return (
    <AppStack>
      <PaginationList
        emptyMessage="No Registrations"
        isLoading={isLoading}
        items={regFormSubmissions.map((s) => (
          <RegistrationListItem
            key={s.pkRegFormSubmission}
            onAction={(action) => {
              if (action === 'EMAIL') {
                window.open(
                  `mailto:${s.overrideEmail ?? s.user.email}`,
                  '_blank'
                );
              }
              else {
                onOpenModal(action, s);
              }
            }}
            regAssociation={regAssociation}
            regFormSubmission={s}
            showStatus
          />
        ))}
        LoadingComponent={RegistrationListItem}
        onPageChange={onPageChange}
        pageIndex={pageIndex}
      />
      <RegistrationListActionModals
        modalState={modalState}
        onCloseModal={onCloseModal}
        onRefresh={onRefresh}
        regAssociation={regAssociation}
      />
    </AppStack>
  );
};

RegistrationList.propTypes = {
  isLoading: PropTypes.bool,
  onPageChange: PropTypes.func,
  onRefresh: PropTypes.func,
  pageIndex: PropTypes.number,
  regAssociation: PropTypes.object,
  regFormSubmissions: PropTypes.array
};

export default RegistrationList;
