import React, { useContext, useEffect } from 'react';
import { FileReport } from 'tabler-icons-react';
import PropTypes from 'prop-types';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { Context as AuthContext } from '../../../providers/AuthProvider';
import AppCard from '../../common/AppCard';
import { useModalState, useSortByFilter } from '../../../helpers/hooks';
import TableView from '../../common/TableView';
import GenerateReportModal from './GenerateReportModal';
import CustomReportModal from './CustomReportModal';
import MerchReportModal from './MerchReportModal';
import AdminMerchReportModal from './AdminMerchReportModal';

const VIEW_ACTIONS_ENUM = {
  create: 'CREATE',
  generate: 'GENERATE'
};

const VIEW_ACTIONS = [
  {
    label: 'Generate Report',
    value: VIEW_ACTIONS_ENUM.generate
  }
];

const defaultReports = [
  {
    pkRegReportTemplate: -1,
    name: 'Admin Merch Report',
    description: 'Admin access to merch reporting',
    inputs: [],
    fkRegAssociation: null,
    guid: 'ADMIN_MERCH_REPORT',
    isGlobal: true,
    isVisible: (userData) => userData.isAdmin
  }
];

const TABLE_COLUMNS = [{ label: 'Name', value: 'name', sortable: true }];

const ReportListView = ({ isLoading, regAssociation, modalAction }) => {
  const { state: authState } = useContext(AuthContext);
  const { state, fetchAdminRegReports } = useContext(RegistrationAdminContext);
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const {
    sortValue,
    isDescendingSort,
    onChangeSortBy,
    customFilterData
  } = useSortByFilter(TABLE_COLUMNS);
  const reports = [
    ...defaultReports.filter((f) => f.isVisible(authState.userData)),
    ...state.regReports.value
  ];

  useEffect(() => {
    if (modalAction === VIEW_ACTIONS_ENUM.create) {
      onOpenModal(modalAction);
    }
  }, [modalAction]);

  return (
    <AppCard style={{ flex: 1, padding: 0, height: '100%' }}>
      <TableView
        columns={TABLE_COLUMNS}
        emptyMessage="No Reports Available"
        isDescendingSort={isDescendingSort}
        isLoading={isLoading}
        lastUpdated={state.regReports.lastUpdated}
        onAction={(action, item) => {
          onOpenModal(
            action,
            reports.find((s) => s.pkRegReportTemplate === item.key)
          );
        }}
        onChangeSortBy={onChangeSortBy}
        onRefresh={() => fetchAdminRegReports(regAssociation.pkRegAssociation)}
        rows={reports.map((r) => ({
          key: r.pkRegReportTemplate,
          actions: VIEW_ACTIONS,
          columns: [
            {
              key: 1,
              label: r.name,
              weight: 500,
              subLabel: r.description,
              onClick: () => onOpenModal(VIEW_ACTIONS_ENUM.generate, r),
              icon: <FileReport color="black" size="30" />
            }
          ]
        }))}
        sortBy={sortValue}
        sortFilter={customFilterData}
        tableTitle="Report Templates"
      />

      <GenerateReportModal
        isOpen={
          modalState.isOpen &&
          modalState.action === VIEW_ACTIONS_ENUM.generate &&
          modalState.item?.guid !== 'CUSTOM' &&
          modalState.item?.guid !== 'ADMIN_MERCH_REPORT' &&
          modalState.item?.guid !== 'MERCH_TRANSACTIONS'
        }
        onClose={onCloseModal}
        pkRegAssociation={regAssociation?.pkRegAssociation}
        regReportTemplate={modalState.item}
      />
      <CustomReportModal
        isOneTimeReport={modalState.item?.guid === 'CUSTOM'}
        isOpen={
          modalState.isOpen &&
          ((modalState.action === VIEW_ACTIONS_ENUM.generate &&
            modalState.item?.guid === 'CUSTOM') ||
            modalState.action === VIEW_ACTIONS_ENUM.create)
        }
        onClose={onCloseModal}
        pkRegAssociation={regAssociation?.pkRegAssociation}
        regReportTemplate={modalState.item}
      />
      <MerchReportModal
        isOpen={
          modalState.isOpen && modalState.item?.guid === 'MERCH_TRANSACTIONS'
        }
        onClose={onCloseModal}
        pkRegAssociation={regAssociation?.pkRegAssociation}
        regReportTemplate={modalState.item}
      />

      <AdminMerchReportModal
        isOpen={
          modalState.isOpen && modalState.item?.guid === 'ADMIN_MERCH_REPORT'
        }
        onClose={onCloseModal}
      />
    </AppCard>
  );
};

ReportListView.propTypes = {
  isLoading: PropTypes.bool,
  modalAction: PropTypes.string,
  regAssociation: PropTypes.object
};

export default ReportListView;
