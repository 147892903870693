import React, { useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Button, Checkbox } from '@mantine/core';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import ParticipantForm from '../participants/ParticipantsForm';
import ResponsiveModal from '../../common/ResponsiveModal';
import { triggerNotification } from '../../../helpers/notificationHelper';
import AppTitle from '../../common/AppTitle';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import RegistrationCartSummary from './RegistrationCartSummary';
import RegisterRegistrantList from './RegisterRegistrantList';
import { getInelligibleRegPersonReason } from '../../../helpers/registrationHelper';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const RegisterFormModal = ({
  isOpen,
  regAssociation,
  regAssociationDivisionForm,
  externalInvite,
  onClose
}) => {
  const mqIndex = useMediaQueryIndex();
  const isMobile = mqIndex === 0;
  const navigate = useNavigate();
  const { state, addProductsToCart } = useContext(RegistrationContext);
  const [selectRegistrantState, setSelectRegistrantState] = useState({
    showForm: false,
    showCartNav: false,
    regPerson: null,
    showDelete: false,
    showOverride: false,
    subscribeEmail: false,
    availableRegistrantsPageIndex: 1,
    unavailableRegistrantsPageIndex: 1,
    selectedRegistrants: [],
    loading: false
  });
  const isLoading =
    selectRegistrantState.loading ||
    state.regRegistrants.loading ||
    state.regCart.loading;

  const availableRegistrants = state.regRegistrants.value
    .filter(
      (r) =>
        !getInelligibleRegPersonReason(
          r,
          regAssociationDivisionForm,
          externalInvite
        )
    )
    .sort((a, b) =>
      `${a.firstName}${a.lastName}`.localeCompare(`${b.firstName}${b.lastName}`)
    );
  const unavailableRegistrants = state.regRegistrants.value
    .filter(
      (r) =>
        availableRegistrants.findIndex(
          (a) => a.pkRegPerson === r.pkRegPerson
        ) === -1
    )
    .sort((a, b) =>
      `${a.firstName}${a.lastName}`.localeCompare(`${b.firstName}${b.lastName}`)
    );
  const isRegistrationFull =
    (regAssociationDivisionForm?.regAssociationDivision.registrantLimit ||
      regAssociationDivisionForm?.regAssociationDivision.registrantLimit ===
        0) &&
    regAssociationDivisionForm.regAssociationDivision.regFormSubmissionCount +
      regAssociationDivisionForm.regAssociationDivision
        .regWaitlistPersonCount >=
      regAssociationDivisionForm.regAssociationDivision.registrantLimit;

  const maxSelectableAmount =
    regAssociationDivisionForm?.regAssociationDivision.registrantLimit &&
    !regAssociationDivisionForm?.regAssociationDivision.waitlistEnabled
      ? regAssociationDivisionForm.regAssociationDivision.registrantLimit -
        (regAssociationDivisionForm.regAssociationDivision
          .regFormSubmissionCount +
          regAssociationDivisionForm.regAssociationDivision
            .regWaitlistPersonCount)
      : null;

  useEffect(() => {
    if (isOpen && regAssociationDivisionForm) {
      setSelectRegistrantState({
        showForm:
          externalInvite?.inviteCode && availableRegistrants.length === 0,
        showCartNav: false,
        regPerson: null,
        showDelete: false,
        showOverride: false,
        subscribeEmail: false,
        availableRegistrantsPageIndex: 1,
        unavailableRegistrantsPageIndex: 1,
        selectedRegistrants: [],
        loading: false
      });
    }
  }, [isOpen]);

  const onError = (message) => {
    triggerNotification(message);
    setSelectRegistrantState({
      ...selectRegistrantState,
      loading: false
    });
  };

  const addRegistrationToCart = (regPersons = []) => {
    const existingProductInCart = state.regCart.value.regCartProducts.find(
      (p) =>
        regPersons.some(
          (r) =>
            p.regFormSubmission.regPerson.pkRegPerson === r.pkRegPerson &&
            p.regFormSubmission.regAssociationDivisionForm.regForm.pkRegForm ===
              regAssociationDivisionForm.regForm.pkRegForm &&
            p.regProduct.pkRegProduct ===
              regAssociationDivisionForm.fkRegProduct
        )
    );
    if (existingProductInCart) {
      onError('Product already in cart');
    }
    else {
      setSelectRegistrantState({
        ...selectRegistrantState,
        loading: true
      });
      addProductsToCart(
        {
          regCartProducts: regPersons.map((r) => ({
            fkRegAssociationDivisionForm:
              regAssociationDivisionForm.pkRegAssociationDivisionForm,
            fkRegPerson: r.pkRegPerson,
            inviteCode: externalInvite?.inviteCode
          }))
        },
        () => {
          if (externalInvite) {
            navigate('/checkout');
          }
          else {
            setSelectRegistrantState({
              ...selectRegistrantState,
              loading: false,
              showCartNav: true
            });
            triggerNotification(
              isRegistrationFull ? 'Added to waitlist' : 'Item added to cart',
              'Success',
              'green'
            );
          }
        },
        onError
      );
    }
  };

  return (
    <ResponsiveModal
      isLoading={isLoading}
      onClose={onClose}
      opened={isOpen}
      size={
        selectRegistrantState.showDelete || selectRegistrantState.showOverride
          ? 600
          : 800
      }
      title={
        <AppTitle
          order={2}
          style={{ marginBottom: selectRegistrantState.showCartNav ? 0 : 10 }}
        >
          {selectRegistrantState.showCartNav
            ? 'Continue Shopping?'
            : selectRegistrantState.showDelete
            ? 'Delete Participant'
            : selectRegistrantState.showOverride
            ? 'Update Participant'
            : selectRegistrantState.regPerson && selectRegistrantState.showForm
            ? 'Edit Participant'
            : selectRegistrantState.showForm
            ? 'Create Participant'
            : 'Select Participants'}
        </AppTitle>
      }
    >
      {selectRegistrantState.showCartNav ? (
        <RegistrationCartSummary
          isRegistrationFull={isRegistrationFull}
          onClose={onClose}
          regAssociation={regAssociation}
          regCart={state.regCart.value}
        />
      ) : selectRegistrantState.showForm ? (
        <ParticipantForm
          externalInvite={externalInvite}
          onCancel={() =>
            setSelectRegistrantState({
              ...selectRegistrantState,
              showForm: false,
              showCartNav: false,
              showDelete: false,
              showOverride: false
            })
          }
          participant={selectRegistrantState.regPerson}
          setShowDelete={(showDelete) =>
            setSelectRegistrantState({
              ...selectRegistrantState,
              showDelete
            })
          }
          setShowOverride={(isShown) =>
            setSelectRegistrantState({
              ...selectRegistrantState,
              showOverride: isShown
            })
          }
          showDelete={selectRegistrantState.showDelete}
        />
      ) : (
        <AppStack style={{ gap: 15 }}>
          {!externalInvite && state.regRegistrants.value.length > 0 && (
            <AppFlexbox
              component="form"
              onSubmit={(e) => {
                e.stopPropagation();
                e.preventDefault();

                addRegistrationToCart(
                  selectRegistrantState.selectedRegistrants
                );
              }}
              style={{
                justifyContent: 'flex-end',
                alignItems: 'center',
                flexDirection: isMobile ? 'column' : 'row'
              }}
            >
              {isRegistrationFull && (
                <Checkbox
                  checked={selectRegistrantState.subscribeEmail}
                  label={`Subscribe to receive emails from ${regAssociation.association.name}`}
                  onChange={() =>
                    setSelectRegistrantState({
                      ...selectRegistrantState,
                      subscribeEmail: !selectRegistrantState.subscribeEmail
                    })
                  }
                  required
                  style={{ marginRight: 'auto' }}
                  styles={{ label: { fontWeight: 500 } }}
                />
              )}

              <Button
                disabled={
                  selectRegistrantState.selectedRegistrants.length === 0
                }
                style={{ width: '100%', maxWidth: isMobile ? 'unset' : 250 }}
                type="submit"
              >
                {selectRegistrantState.selectedRegistrants.length > 0
                  ? isRegistrationFull
                    ? `Join Waitlist (${selectRegistrantState.selectedRegistrants.length})`
                    : `Add to Cart (${selectRegistrantState.selectedRegistrants.length})`
                  : isRegistrationFull
                  ? `Join Waitlist`
                  : 'Add to Cart'}
              </Button>
            </AppFlexbox>
          )}

          {availableRegistrants.length > 0 && (
            <RegisterRegistrantList
              externalInvite={externalInvite}
              isSingleSelect={!!externalInvite}
              onEditRegistrant={(regPerson) =>
                setSelectRegistrantState({
                  ...selectRegistrantState,
                  showForm: true,
                  showCartNav: false,
                  regPerson,
                  showDelete: false,
                  showOverride: false
                })
              }
              onPageChange={(pageIndex) =>
                setSelectRegistrantState({
                  ...selectRegistrantState,
                  availableRegistrantsPageIndex: pageIndex
                })
              }
              onSelect={(r) => {
                if (externalInvite) {
                  addRegistrationToCart([r]);
                }
                else if (
                  selectRegistrantState.selectedRegistrants.some(
                    (s) => s.pkRegPerson === r.pkRegPerson
                  )
                ) {
                  setSelectRegistrantState({
                    ...selectRegistrantState,
                    selectedRegistrants: [
                      ...selectRegistrantState.selectedRegistrants.filter(
                        (s) => s.pkRegPerson !== r.pkRegPerson
                      )
                    ]
                  });
                }
                else if (
                  !maxSelectableAmount ||
                  selectRegistrantState.selectedRegistrants.length <
                    maxSelectableAmount
                ) {
                  setSelectRegistrantState({
                    ...selectRegistrantState,
                    selectedRegistrants: [
                      ...selectRegistrantState.selectedRegistrants,
                      r
                    ]
                  });
                }
              }}
              pageIndex={selectRegistrantState.availableRegistrantsPageIndex}
              regAssociationDivisionForm={regAssociationDivisionForm}
              registrants={availableRegistrants}
              selectDisabled={
                !!maxSelectableAmount &&
                selectRegistrantState.selectedRegistrants.length >=
                  maxSelectableAmount
              }
              selectedRegistrants={selectRegistrantState.selectedRegistrants}
              title={unavailableRegistrants.length > 0 ? 'Eligible' : null}
            />
          )}
          {unavailableRegistrants.length > 0 && (
            <RegisterRegistrantList
              externalInvite={externalInvite}
              onAddToCart={() =>
                triggerNotification('Selected participant not eligible')
              }
              onEditRegistrant={(regPerson) =>
                setSelectRegistrantState({
                  ...selectRegistrantState,
                  showForm: true,
                  showCartNav: false,
                  regPerson,
                  showDelete: false,
                  showOverride: false
                })
              }
              onPageChange={(pageIndex) =>
                setSelectRegistrantState({
                  ...selectRegistrantState,
                  unavailableRegistrantsPageIndex: pageIndex
                })
              }
              pageIndex={selectRegistrantState.unavailableRegistrantsPageIndex}
              regAssociationDivisionForm={regAssociationDivisionForm}
              registrants={unavailableRegistrants}
              title="Ineligible"
            />
          )}

          <AppFlexbox
            style={{
              alignSelf: 'center',
              flexWrap: 'wrap',
              margin: 25,
              marginTop: 10
            }}
          >
            <AppText>Don't See the participant you need?</AppText>
            <AppText
              aria-label="createRegPersonView"
              onClick={() =>
                setSelectRegistrantState({
                  ...selectRegistrantState,
                  showForm: true,
                  showCartNav: false,
                  regPerson: null,
                  showDelete: false,
                  showOverride: false
                })
              }
              role="button"
              style={{
                color: '#1C7ED6',
                cursor: 'pointer'
              }}
            >
              Create New Participant
            </AppText>
          </AppFlexbox>
        </AppStack>
      )}
    </ResponsiveModal>
  );
};

RegisterFormModal.propTypes = {
  externalInvite: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  regAssociation: PropTypes.object,
  regAssociationDivisionForm: PropTypes.object
};

export default RegisterFormModal;
