import React, { useContext, useEffect, useRef, useState } from 'react';
import { Alert, Button, Divider } from '@mantine/core';
import { AlertCircle, InfoCircle } from 'tabler-icons-react';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import { triggerNotification } from '../../../helpers/notificationHelper';
import { formatUtcDate } from '../../../helpers/format';
import AppTitle from '../../common/AppTitle';
import LoadableView from '../../common/LoadableView';
import HeaderView from '../../common/HeaderView';
import AppCard from '../../common/AppCard';
import AppStack from '../../common/AppStack';
import FormSection from '../../common/FormSection';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';

const CheckoutWaitlist = () => {
  const navigate = useNavigate();
  const {
    state,
    removeWaitlistPerson,
    fetchWaitlistRegistrants,
    addProductsToCart
  } = useContext(RegistrationContext);
  const hasFetchedWaitlist = useRef(false);
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { uuid } = useParams();

  const registration = state.regWaitlistRegistrants.value.find((r) =>
    r.waitlistReservations.some((w) => w.uuid === uuid)
  );
  const reservation = registration?.waitlistReservations.find(
    (r) => r.uuid === uuid
  );
  const expiryDate = reservation ? formatUtcDate(reservation.expiryDate) : null;
  const hasExpired = reservation
    ? reservation.deleted || new Date() >= new Date(expiryDate)
    : true;
  let expiryTimeHours = reservation
    ? dayjs(expiryDate).diff(dayjs(new Date()), 'hour')
    : 0;
  const expiryTimeMinutes = reservation
    ? Math.ceil(dayjs(expiryDate).diff(dayjs(new Date()), 'minute', true))
    : 0;
  const expiryTimeDays = Math.ceil(expiryTimeHours / 24);
  expiryTimeHours -= Math.floor(expiryTimeHours / 24) * 24;

  useEffect(() => {
    fetchWaitlistRegistrants();
  }, []);

  useEffect(() => {
    if (state.regWaitlistRegistrants.loading) {
      hasFetchedWaitlist.current = true;
    }
  }, [state.regWaitlistRegistrants.loading]);

  useEffect(() => {
    if (hasFetchedWaitlist.current) {
      if (!registration) {
        navigate('/checkout/cart');
      }
    }
  }, [state.regWaitlistRegistrants.value]);

  return (
    <LoadableView isLoading={!registration}>
      <HeaderView maxViewWidth={550}>
        {registration && (
          <AppCard
            style={{ margin: 'auto', width: '100%', alignSelf: 'stretch' }}
          >
            {hasExpired ? (
              <AppStack style={{ gap: 30, margin: 10 }}>
                <AppTitle order={3}>Reservation Expired</AppTitle>
                <Alert
                  color="red"
                  icon={<AlertCircle style={{ width: 35, height: 35 }} />}
                  styles={{ icon: { marginTop: 15, width: 35 } }}
                  variant="outline"
                >
                  <AppStack style={{ gap: 20 }}>
                    <AppText>
                      The reservation to register{' '}
                      <b>
                        {registration.regPerson.firstName}{' '}
                        {registration.regPerson.lastName}
                      </b>{' '}
                      for{' '}
                      <b>
                        {
                          registration.regAssociationDivisionWaitlist
                            .regAssociationDivision.name
                        }
                      </b>{' '}
                      has expired.
                    </AppText>
                    <AppText>
                      You will have to rejoin the waitlist to gain the
                      opportunity to register again.
                    </AppText>
                  </AppStack>
                </Alert>
                <Divider />
                <AppFlexbox style={{ justifyContent: 'center' }}>
                  <Button
                    color="dark"
                    onClick={() => navigate('/leagues')}
                    style={{
                      flex: 1,
                      maxWidth: 250
                    }}
                  >
                    View Leagues & Clubs
                  </Button>
                </AppFlexbox>
              </AppStack>
            ) : confirmCancel ? (
              <FormSection
                isLoading={isLoading}
                onCancel={() => setConfirmCancel(false)}
                onSubmit={() => {
                  setIsLoading(true);
                  removeWaitlistPerson(
                    registration.pkRegWaitlistPerson,
                    () => {
                      triggerNotification(
                        'Successfully removed from waitlist',
                        'Success',
                        'green'
                      );
                      navigate('/');
                    },
                    () => {
                      triggerNotification();
                      setIsLoading(false);
                    }
                  );
                }}
                style={{ gap: 30, margin: 10 }}
                submitColor="red"
                submitTitle="Forfeit"
              >
                <AppTitle order={3}>Forfeit Registration</AppTitle>
                <Alert
                  color="red"
                  icon={<AlertCircle style={{ width: 35, height: 35 }} />}
                  styles={{ icon: { marginTop: 15, width: 35 } }}
                  variant="outline"
                >
                  <AppStack style={{ gap: 20 }}>
                    <AppText>
                      Are you sure you want to forfeit registering{' '}
                      <b>
                        {registration.regPerson.firstName}{' '}
                        {registration.regPerson.lastName}
                      </b>{' '}
                      for{' '}
                      <b>
                        {
                          registration.regAssociationDivisionWaitlist
                            .regAssociationDivision.name
                        }
                      </b>
                      ?
                    </AppText>
                    <AppText>
                      This offer will be passed on to the next person. You will
                      have to rejoin the waitlist to gain the opportunity to
                      register again.
                    </AppText>
                  </AppStack>
                </Alert>
              </FormSection>
            ) : (
              <FormSection
                cancelColor="red"
                cancelTitle="Forfeit"
                isLoading={isLoading}
                onCancel={() => setConfirmCancel(true)}
                onSubmit={() => {
                  setIsLoading(true);
                  addProductsToCart(
                    {
                      regCartProducts: [
                        {
                          uuid: reservation.uuid,
                          fkRegAssociationDivisionForm:
                            registration.regAssociationDivisionForm
                              .pkRegAssociationDivisionForm,
                          fkRegPerson: registration.regPerson.pkRegPerson
                        }
                      ]
                    },
                    () => {
                      triggerNotification(
                        'Item added to cart',
                        'Success',
                        'green'
                      );
                      navigate('/checkout');
                    },
                    (message) => {
                      triggerNotification(message);
                      setIsLoading(false);
                    }
                  );
                }}
                style={{ gap: 30, margin: 10 }}
                submitTitle="Add to Cart"
              >
                <AppTitle order={3}>Registration Available</AppTitle>
                <Alert
                  color="blue"
                  icon={<InfoCircle style={{ width: 35, height: 35 }} />}
                  styles={{ icon: { marginTop: 15, width: 35 } }}
                  variant="outline"
                >
                  <AppStack style={{ gap: 20 }}>
                    <AppText>
                      A registration that you were on a waitlist for has become
                      available!
                    </AppText>
                    <AppText>
                      <b>
                        {
                          registration.regAssociationDivisionWaitlist
                            .regAssociationDivision.name
                        }
                      </b>{' '}
                      is now available for{' '}
                      <b>
                        {registration.regPerson.firstName}{' '}
                        {registration.regPerson.lastName}
                      </b>{' '}
                      and can be added to your cart.
                    </AppText>
                    <AppText>
                      This offer will expire in{' '}
                      {expiryTimeDays > 1 ? (
                        <b>
                          {expiryTimeDays}{' '}
                          {expiryTimeDays === 1 ? 'day.' : 'days.'}{' '}
                        </b>
                      ) : expiryTimeHours > 0 ? (
                        <b>
                          {expiryTimeHours}{' '}
                          {expiryTimeHours === 1 ? 'hour.' : 'hours.'}{' '}
                        </b>
                      ) : (
                        expiryTimeMinutes > 0 && (
                          <b>
                            {expiryTimeMinutes}{' '}
                            {expiryTimeMinutes === 1 ? 'minute.' : 'minutes.'}{' '}
                          </b>
                        )
                      )}
                    </AppText>
                  </AppStack>
                </Alert>
              </FormSection>
            )}
          </AppCard>
        )}
      </HeaderView>
    </LoadableView>
  );
};

export default CheckoutWaitlist;
