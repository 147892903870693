import React from 'react';
import { PropTypes } from 'prop-types';
import { Grid, Radio, Tooltip } from '@mantine/core';
import NumberFormat from 'react-number-format';
import { toTitleCase } from '../../../helpers/format';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import AppRadioGroup from '../../common/AppRadioGroup';

const RegistrationFormRoleSection = ({
  divisionRoles,
  availableRoles,
  onSelectRole,
  selectedRoles,
  isRequired,
  isDisabled,
  isLoading
}) => {
  const mqIndex = useMediaQueryIndex();
  const selectedRole = selectedRoles[0];
  const selectedRolePriceDoesNotExists =
    isDisabled &&
    selectedRole &&
    !divisionRoles.some(
      (r) =>
        r.name === selectedRole.role &&
        (r.price === selectedRole.price ||
          (!r.price && selectedRole.price === 0))
    );

  return (
    <AppStack
      style={{ gap: 5, margin: rs(['0px 10px', '0px 10px', 0], mqIndex) }}
    >
      <AppFlexbox style={{ display: 'inline-flex' }}>
        <AppText weight={700}>Preferred Roles</AppText>
      </AppFlexbox>

      <AppStack
        role="region"
        style={{
          border: 'solid 1px lightgrey',
          position: 'relative'
        }}
      >
        <Grid
          styles={{
            root: { margin: 10, overflow: 'visible' },
            inner: {
              margin: 0,
              padding: 0,
              width: 'unset',
              position: 'relative'
            },
            col: { padding: 0 }
          }}
        >
          <Grid.Col
            role="gridcell"
            span={12}
            style={{
              padding: 8,
              position: 'relative',
              display: 'flex',
              justifyContent: 'start',
              placeItems: 'center',
              minHeight: 80,
              height: rs(['unset', 'unset', 'unset', 'unset', 80], mqIndex)
            }}
          >
            <AppRadioGroup
              label={
                isRequired
                  ? 'Select your unique role'
                  : '(Optional) Are you registering for a unique role?'
              }
              onChange={() => {}}
              required={isRequired}
              spacing="lg"
              styles={{ label: { fontSize: 15, fontWeight: 500 } }}
              value={
                selectedRolePriceDoesNotExists
                  ? selectedRole.pkRegFormSubmissionRoleAnswer.toString()
                  : selectedRole?.role
              }
            >
              {divisionRoles.map((role) => {
                const maxLimitReached = !availableRoles.some(
                  (a) =>
                    a.pkRegAssociationDivisionRole ===
                    role.pkRegAssociationDivisionRole
                );
                return (
                  <Radio
                    key={role.pkRegAssociationDivisionRole}
                    disabled={isDisabled || isLoading || maxLimitReached}
                    label={
                      <Tooltip
                        disabled={!maxLimitReached}
                        label="Limit Reached"
                        withArrow
                        withinPortal
                      >
                        <AppText style={{ fontSize: 15 }}>
                          {toTitleCase(role.name)}
                          {role.price && (
                            <>
                              {' - '}
                              {role.price < 0 ? (
                                <>
                                  (
                                  <NumberFormat
                                    decimalScale={2}
                                    displayType="text"
                                    fixedDecimalScale
                                    prefix="$"
                                    thousandSeparator
                                    value={role.price}
                                  />
                                  )
                                </>
                              ) : (
                                <NumberFormat
                                  decimalScale={2}
                                  displayType="text"
                                  fixedDecimalScale
                                  prefix="$"
                                  thousandSeparator
                                  value={role.price}
                                />
                              )}
                            </>
                          )}
                        </AppText>
                      </Tooltip>
                    }
                    onClick={() => {
                      onSelectRole(role.name);
                    }}
                    required={isRequired}
                    style={{ fontSize: 13 }}
                    value={role.name}
                  />
                );
              })}
              {selectedRole && selectedRolePriceDoesNotExists && (
                <Radio
                  disabled={isDisabled || isLoading}
                  label={
                    <AppText
                      style={{ fontSize: 15, textDecoration: 'line-through' }}
                    >
                      {toTitleCase(selectedRole.role)}
                      {selectedRole.price && (
                        <>
                          {' - '}
                          {selectedRole.price < 0 ? (
                            <>
                              (
                              <NumberFormat
                                decimalScale={2}
                                displayType="text"
                                fixedDecimalScale
                                prefix="$"
                                thousandSeparator
                                value={selectedRole.price}
                              />
                              )
                            </>
                          ) : (
                            <NumberFormat
                              decimalScale={2}
                              displayType="text"
                              fixedDecimalScale
                              prefix="$"
                              thousandSeparator
                              value={selectedRole.price}
                            />
                          )}
                        </>
                      )}
                    </AppText>
                  }
                  style={{ fontSize: 13 }}
                  value={selectedRole.pkRegFormSubmissionRoleAnswer.toString()}
                />
              )}
            </AppRadioGroup>
          </Grid.Col>
        </Grid>
      </AppStack>
    </AppStack>
  );
};

RegistrationFormRoleSection.propTypes = {
  availableRoles: PropTypes.array,
  divisionRoles: PropTypes.array,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isRequired: PropTypes.bool,
  onSelectRole: PropTypes.func,
  selectedRoles: PropTypes.array
};

export default RegistrationFormRoleSection;
