import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { TextInput } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppTitle from '../../common/AppTitle';
import FormSection from '../../common/FormSection';
import AppStack from '../../common/AppStack';
import { triggerNotification } from '../../../helpers/notificationHelper';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import DeleteItemView from '../../common/DeleteItemView';

const CreateEvaluationTemplateModal = ({
  isOpen,
  onClose,
  pkRegAssociation,
  showDelete,
  regScoutReport
}) => {
  const navigate = useNavigate();
  const { createRegScoutReport, deleteRegScoutReport } = useContext(
    RegistrationAdminContext
  );
  const [formState, setFormState] = useState({ name: '', isLoading: false });

  useEffect(() => {
    if (isOpen) {
      setFormState({
        name: regScoutReport ? `${regScoutReport.name} - Copy` : '',
        isLoading: false
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      onClose={onClose}
      opened={isOpen}
      title={
        <AppTitle order={2}>
          {showDelete ? 'Delete' : regScoutReport ? 'Copy' : 'Create'}{' '}
          Evaluation Template
        </AppTitle>
      }
    >
      {showDelete ? (
        <DeleteItemView
          isLoading={formState.isLoading}
          itemLabel="Evaluation Template"
          onCancel={onClose}
          onDelete={() => {
            setFormState({
              ...formState,
              isLoading: true
            });
            deleteRegScoutReport(
              regScoutReport.pkRegScoutReport,
              onClose,
              (e) => {
                setFormState({
                  ...formState,
                  isLoading: false
                });
                triggerNotification(e);
              }
            );
          }}
          warnings={[
            'The ability to set any evaluation sessions to this template.'
          ]}
        />
      ) : (
        <FormSection
          isLoading={formState.isLoading}
          onCancel={onClose}
          onSubmit={() => {
            setFormState({
              ...formState,
              isLoading: true
            });

            createRegScoutReport(
              pkRegAssociation,
              {
                name: formState.name,
                pkRegScoutReportCopy: regScoutReport?.pkRegScoutReport
              },
              (data) => {
                navigate(
                  `/admin/evaluations/templates/${data.pkRegScoutReport}`
                );
              },
              (e) => {
                setFormState({ ...formState, isLoading: false });
                triggerNotification(e);
              }
            );
          }}
          submitTitle={regScoutReport ? 'Copy' : 'Create'}
        >
          <AppStack style={{ gap: 20 }}>
            <TextInput
              disabled={formState.isLoading}
              label="Template Name"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  name: e.target.value.substring(0, 255)
                })
              }
              required
              value={formState.name}
            />
          </AppStack>
        </FormSection>
      )}
    </ResponsiveModal>
  );
};

CreateEvaluationTemplateModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  pkRegAssociation: PropTypes.number,
  regScoutReport: PropTypes.object,
  showDelete: PropTypes.bool
};

export default CreateEvaluationTemplateModal;
