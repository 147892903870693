import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Accordion,
  Button,
  Checkbox,
  Divider,
  Radio,
  Textarea,
  TextInput,
  Tooltip
} from '@mantine/core';
import { ChevronDown, ChevronUp } from 'tabler-icons-react';
import NumberFormat from 'react-number-format';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import AppRadioGroup from '../../common/AppRadioGroup';

const AssignDivisionAccordionListItem = ({
  currencyAbbr,
  division,
  formState,
  onAssignChange,
  onValueChange
}) => {
  const mqIndex = useMediaQueryIndex();
  const [accordionState, setAccordionState] = useState(null);
  const accordionValue = division.value.toString();

  return (
    <Accordion
      chevronSize={0}
      onChange={(value) => {
        if (formState?.isActive) {
          setAccordionState(value);
        }
      }}
      radius={0}
      styles={{
        control: { height: rs(['unset', 'unset', 60], mqIndex), padding: 16 },
        content: { padding: 0 },
        root: { padding: 0 },
        contentInner: { padding: 0 }
      }}
      value={accordionState}
      variant="contained"
    >
      <Accordion.Item value={accordionValue}>
        <Accordion.Control>
          <AppFlexbox
            key={division.value}
            style={{
              gap: rs([10, 0], mqIndex),
              justifyContent: 'space-between',
              flexDirection: rs(['column', 'row'], mqIndex),
              alignItems: 'center'
            }}
          >
            <Tooltip
              label="Assign Division"
              style={{ alignSelf: 'stretch', margin: 'auto 0px' }}
              withArrow
              withinPortal
            >
              <Checkbox
                checked={formState?.isActive ?? false}
                label={division.label}
                onChange={(e) => {
                  setAccordionState(
                    e.currentTarget.checked ? accordionValue : null
                  );
                  onAssignChange(e);
                }}
                styles={{label: { fontWeight: formState?.isActive ? 600 : 400 }}}
              />
            </Tooltip>
            {formState?.isActive && (
              <Button
                radius="xl"
                rightSection={
                  accordionState ? (
                    <ChevronUp color="dodgerblue" />
                  ) : (
                    <ChevronDown color="dodgerblue" />
                  )
                }
                variant="outline"
              >
                {accordionState ? 'Show Less' : 'Show More'}
              </Button>
            )}
          </AppFlexbox>
        </Accordion.Control>
        <Accordion.Panel>
          <Divider color="#dee2e6" />
          <AppStack style={{ padding: 16 }}>
            {formState?.isActive && (
              <AppStack style={{ gap: 20 }}>
                <AppFlexbox>
                  <NumberFormat
                    allowNegative={false}
                    customInput={TextInput}
                    decimalScale={2}
                    fixedDecimalScale
                    label="Price"
                    onValueChange={(e) =>
                      onValueChange({
                        ...formState,
                        price: e.value
                      })
                    }
                    placeholder="$0.00"
                    prefix="$"
                    required
                    rightSection={
                      currencyAbbr && (
                        <AppText
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                            color: '#999',
                            paddingRight: 5
                          }}
                        >
                          {currencyAbbr}
                        </AppText>
                      )
                    }
                    style={{ flex: 1, maxWidth: rs(['unset', 300], mqIndex) }}
                    thousandSeparator
                    value={formState.price}
                  />
                </AppFlexbox>
                <AppRadioGroup
                  label="Platform Fee"
                  onChange={(value) =>
                    onValueChange({
                      ...formState,
                      customerPaysServiceFees: value === 'ADDITIONAL-FEE'
                    })
                  }
                  size="sm"
                  spacing="lg"
                  value={
                    formState.customerPaysServiceFees
                      ? 'ADDITIONAL-FEE'
                      : 'NORMAL-FEE'
                  }
                >
                  <Radio
                    label="Platform fee is taken from principle amount"
                    value="NORMAL-FEE"
                  />
                  <Radio
                    label="Platform fee is paid as an additional fee by the customer"
                    value="ADDITIONAL-FEE"
                  />
                </AppRadioGroup>
                <TextInput
                  label="Title Override"
                  onChange={(e) =>
                    onValueChange({
                      ...formState,
                      titleOverride: e.currentTarget.value.substring(0, 255)
                    })
                  }
                  style={{ flex: 1, maxWidth: rs(['unset', 300], mqIndex) }}
                  value={formState.titleOverride ?? ''}
                />
                {division?.hasOrderEmails && (
                  <Checkbox
                    checked={formState.orderEmailEnabled}
                    label="Include order email"
                    onChange={(e) => {
                      onValueChange({
                        ...formState,
                        orderEmailEnabled: e.currentTarget.checked
                      });
                    }}
                    styles={{ label: { fontWeight: 500 } }}
                  />
                )}
                <AppStack style={{ gap: 10 }}>
                  <AppText style={{ fontSize: 14 }} weight={500}>
                    Days of play
                  </AppText>
                  <AppFlexbox style={{ flexWrap: 'wrap' }}>
                    {[
                      'Monday',
                      'Tuesday',
                      'Wednesday',
                      'Thursday',
                      'Friday',
                      'Saturday',
                      'Sunday'
                    ].map((day) => (
                      <Checkbox
                        key={day}
                        checked={formState.daysOfPlay.includes(day)}
                        label={day}
                        onChange={(e) => {
                          const newDaysOfPlayState = formState.daysOfPlay.filter(
                            (d) => d !== day
                          );
                          onValueChange({
                            ...formState,
                            daysOfPlay: e.currentTarget.checked
                              ? [...newDaysOfPlayState, day]
                              : newDaysOfPlayState
                          });
                        }}
                        style={{ width: rs(['45%', 'unset'], mqIndex) }}
                      />
                    ))}
                  </AppFlexbox>
                </AppStack>

                <AppStack style={{ gap: 15 }}>
                  <AppRadioGroup
                    label="Additional Info"
                    onChange={(value) => {
                      onValueChange({
                        ...formState,
                        isAdditionalInfoHyperlink: value === '2'
                      });
                    }}
                    size="sm"
                    value={
                      formState.isAdditionalInfoHyperlink === true ? '2' : '1'
                    }
                  >
                    <Radio label="Text" value="1" />
                    <Radio label="Link" value="2" />
                  </AppRadioGroup>
                  {formState.isAdditionalInfoHyperlink ? (
                    <TextInput
                      onChange={(e) => {
                        onValueChange({
                          ...formState,
                          additionalInfo: e.currentTarget.value.substring(
                            0,
                            2000
                          )
                        });
                      }}
                      placeholder="https://website.com/additional-info"
                      value={formState.additionalInfo ?? ''}
                    />
                  ) : (
                    <Textarea
                      autosize
                      maxRows={10}
                      minRows={4}
                      onChange={(e) => {
                        onValueChange({
                          ...formState,
                          additionalInfo: e.currentTarget.value.substring(
                            0,
                            2000
                          )
                        });
                      }}
                      value={formState.additionalInfo ?? ''}
                    />
                  )}
                </AppStack>
              </AppStack>
            )}
          </AppStack>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

AssignDivisionAccordionListItem.propTypes = {
  currencyAbbr: PropTypes.string,
  division: PropTypes.object,
  formState: PropTypes.object,
  onAssignChange: PropTypes.func,
  onValueChange: PropTypes.func
};

export default AssignDivisionAccordionListItem;
