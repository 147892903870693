import React from 'react';
import { Divider, Skeleton } from '@mantine/core';
import dayjs from 'dayjs';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import CheckoutItem from '../checkout/CheckoutItem';
import { formatUtcDate } from '../../../helpers/format';
import { calculateCartTotals } from '../../../helpers/paymentHelper';
import AppCard from '../../common/AppCard';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import CheckoutMerchItem from '../checkout/CheckoutMerchItem';
import { PAYMENT_PROVIDER_LINE_ITEM_TYPES } from '../../../config/constants';

const OrderDetailsInfo = ({ isLoading, regCart, withBorder, hideTitle }) => {
  const mqIndex = useMediaQueryIndex();

  const isPendingPayment = regCart?.regCartProducts.some(
    (p) => p.regFormSubmission.status === 'PENDING_PAYMENT'
  );
  const hasBeenRefunded = regCart?.cart.lineItems.some(
    (i) => i.refundedAmountInCents > 0
  );
  const cartTotals = regCart
    ? calculateCartTotals(regCart.cart.lineItems, true)
    : {};
  const currency = regCart?.cart.currency?.toUpperCase();

  return !isLoading && regCart ? (
    <>
      <AppCard style={{ border: withBorder ? 'solid 1px lightgrey' : 'none' }}>
        <AppFlexbox
          style={{
            padding: '0px 15px',
            alignItems: 'start',
            flexDirection: rs(
              ['column-reverse', 'column-reverse', 'row'],
              mqIndex
            )
          }}
        >
          <AppStack
            style={{
              flex: 1,
              gap: 0,
              alignSelf: 'stretch'
            }}
          >
            {!hideTitle && (
              <AppText size="lg" weight={700}>
                Order Summary
              </AppText>
            )}

            <AppStack style={{ gap: 0 }}>
              <AppFlexbox
                style={{
                  justifyContent: 'space-between',
                  flexWrap: 'nowrap',
                  gap: 40
                }}
              >
                <AppText size="lg">Subtotal</AppText>
                <AppFlexbox style={{ gap: 10 }}>
                  <AppText size="lg">
                    <NumberFormat
                      decimalScale={2}
                      displayType="text"
                      fixedDecimalScale
                      prefix="$"
                      thousandSeparator
                      value={cartTotals.subtotalInCents / 100}
                    />
                  </AppText>
                  <AppText size="lg">{currency}</AppText>
                </AppFlexbox>
              </AppFlexbox>
              {cartTotals.discountInCents > 0 && (
                <AppFlexbox
                  style={{
                    justifyContent: 'space-between',
                    flexWrap: 'nowrap'
                  }}
                >
                  <AppText size="lg">Discount</AppText>
                  <AppFlexbox style={{ gap: 10 }}>
                    <AppText size="lg">
                      <NumberFormat
                        decimalScale={2}
                        displayType="text"
                        fixedDecimalScale
                        prefix="$"
                        thousandSeparator
                        value={(cartTotals.discountInCents / 100) * -1}
                      />
                    </AppText>
                    <AppText size="lg">{currency}</AppText>
                  </AppFlexbox>
                </AppFlexbox>
              )}
              <AppFlexbox
                style={{
                  justifyContent: 'space-between',
                  flexWrap: 'nowrap'
                }}
              >
                <AppText size="lg">Taxes</AppText>
                <AppFlexbox style={{ gap: 10 }}>
                  <AppText size="lg">
                    <NumberFormat
                      decimalScale={2}
                      displayType="text"
                      fixedDecimalScale
                      prefix="$"
                      thousandSeparator
                      value={cartTotals.taxInCents / 100}
                    />
                  </AppText>
                  <AppText size="lg">{currency}</AppText>
                </AppFlexbox>
              </AppFlexbox>
              {cartTotals.serviceFeeInCents > 0 && (
                <AppFlexbox
                  style={{
                    justifyContent: 'space-between',
                    flexWrap: 'nowrap'
                  }}
                >
                  <AppText size="lg">Service Fee</AppText>
                  <AppFlexbox style={{ gap: 10 }}>
                    <AppText size="lg">
                      <NumberFormat
                        decimalScale={2}
                        displayType="text"
                        fixedDecimalScale
                        prefix="$"
                        thousandSeparator
                        value={cartTotals.serviceFeeInCents / 100}
                      />
                    </AppText>
                    <AppText size="lg">{currency}</AppText>
                  </AppFlexbox>
                </AppFlexbox>
              )}
              <Divider style={{ margin: '10px 0px' }} />
              <AppFlexbox
                style={{
                  justifyContent: 'space-between',
                  flexWrap: 'nowrap'
                }}
              >
                <AppText size="lg" weight={700}>
                  Total
                </AppText>
                <AppFlexbox style={{ gap: 10 }}>
                  <AppText size="lg" weight={700}>
                    <NumberFormat
                      decimalScale={2}
                      displayType="text"
                      fixedDecimalScale
                      prefix="$"
                      thousandSeparator
                      value={cartTotals.totalInCents / 100}
                    />
                  </AppText>
                  <AppText size="lg" weight={700}>
                    {currency}
                  </AppText>
                </AppFlexbox>
              </AppFlexbox>
            </AppStack>
          </AppStack>
          <AppStack
            style={{
              flex: rs([1, 1, 2], mqIndex),
              gap: 0,
              alignItems: rs(['start', 'start', 'end'], mqIndex),
              alignSelf: 'stretch',
              justifyContent: 'space-between',
              textAlign: rs(['start', 'start', 'end'], mqIndex)
            }}
          >
            <AppStack
              style={{
                gap: 0,
                alignSelf: 'stretch',
                flexDirection: rs(['row', 'row', 'column'], mqIndex),
                justifyContent: 'space-between'
              }}
            >
              <AppText
                style={{ fontSize: 18, whiteSpace: 'nowrap' }}
                weight={700}
              >
                Order # {regCart.pkRegCart}
              </AppText>
              <AppText style={{ fontSize: 18 }}>
                {isPendingPayment
                  ? 'PENDING'
                  : hasBeenRefunded
                  ? 'REFUNDED'
                  : 'COMPLETED'}
              </AppText>
            </AppStack>

            {regCart?.cart.purchasedAt && (
              <AppText style={{ fontSize: 18, textAlign: 'end' }}>
                Ordered on{' '}
                {dayjs(formatUtcDate(regCart?.cart.purchasedAt)).format(
                  'MMM D, YYYY'
                )}
              </AppText>
            )}
          </AppStack>
        </AppFlexbox>
      </AppCard>

      <AppStack
        style={{
          flex: 2,
          gap: rs([10, 10, 20], mqIndex),
          marginBottom: rs([10, 10, 0], mqIndex)
        }}
      >
        {regCart.cart.lineItems.map((i) => {
          const regCartProduct = regCart.regCartProducts.find(
            (p) =>
              p.regFormSubmission.pkRegFormSubmission === i.entityId &&
              p.regProduct.pkRegProduct ===
                p.regFormSubmission.regAssociationDivisionForm.fkRegProduct &&
              i.lineItemType ===
                PAYMENT_PROVIDER_LINE_ITEM_TYPES.registration.value
          );

          let regCartMerchPackage = null;
          if (!regCartProduct) {
            regCartMerchPackage = regCart.regCartMerchPackages.find(
              (p) =>
                p.pkRegCartMerchPackage === i.entityId &&
                i.lineItemType === PAYMENT_PROVIDER_LINE_ITEM_TYPES.merch.value
            );
          }

          return regCartMerchPackage ? (
            <CheckoutMerchItem
              key={regCartMerchPackage.pkRegCartMerchPackage}
              isDisabled
              lineItem={i}
              regCartMerchPackage={regCartMerchPackage}
              withBorder={withBorder}
            />
          ) : (
            <CheckoutItem
              key={i.entityId}
              cartProduct={regCartProduct}
              isDisabled
              lineItem={i}
              withBorder={withBorder}
            />
          );
        })}
      </AppStack>
    </>
  ) : (
    <>
      <AppCard style={{ border: withBorder ? 'solid 1px lightgrey' : 'none' }}>
        <AppFlexbox
          style={{
            padding: '0px 15px',
            alignItems: 'start',
            flexDirection: rs(
              ['column-reverse', 'column-reverse', 'row'],
              mqIndex
            )
          }}
        >
          <AppStack
            style={{
              flex: 1,
              gap: 10,
              alignSelf: 'stretch'
            }}
          >
            <Skeleton height={14} width={150} />
            <AppStack style={{ gap: 10 }}>
              <AppFlexbox
                style={{
                  justifyContent: 'space-between',
                  flexWrap: 'nowrap',
                  gap: 40
                }}
              >
                <Skeleton height={14} width={100} />
                <Skeleton height={14} width={100} />
              </AppFlexbox>
              <AppFlexbox
                style={{
                  justifyContent: 'space-between',
                  flexWrap: 'nowrap'
                }}
              >
                <Skeleton height={14} width={100} />
                <Skeleton height={14} width={100} />
              </AppFlexbox>
              <Divider style={{ margin: '10px 0px' }} />
              <AppFlexbox
                style={{
                  justifyContent: 'space-between',
                  flexWrap: 'nowrap'
                }}
              >
                <Skeleton height={14} width={100} />
                <Skeleton height={14} width={100} />
              </AppFlexbox>
            </AppStack>
          </AppStack>
          <AppStack
            style={{
              flex: rs([1, 1, 2], mqIndex),
              gap: 0,
              alignItems: 'end',
              alignSelf: 'stretch',
              justifyContent: 'space-between',
              flexDirection: rs(['row', 'row', 'column'], mqIndex)
            }}
          >
            <Skeleton height={14} width={100} />
            <Skeleton height={14} width={250} />
          </AppStack>
        </AppFlexbox>
      </AppCard>

      <AppStack
        style={{
          flex: 2,
          gap: rs([10, 10, 20], mqIndex),
          width: rs(['100%', '100%', 'unset'], mqIndex),
          marginBottom: rs([10, 10, 0], mqIndex)
        }}
      >
        <CheckoutItem isDisabled />
        <CheckoutItem isDisabled />
      </AppStack>
    </>
  );
};

OrderDetailsInfo.propTypes = {
  hideTitle: PropTypes.bool,
  isLoading: PropTypes.bool,
  regCart: PropTypes.object,
  withBorder: PropTypes.bool
};

export default OrderDetailsInfo;
