import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Ticket } from 'tabler-icons-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { Context as AuthContext } from '../../../providers/AuthProvider';
import { useModalState, useSortByFilter } from '../../../helpers/hooks';
import AppCard from '../../common/AppCard';
import TableView from '../../common/TableView';
import CreateEvaluationSessionModal from './CreateEvaluationSessionModal';
import { REG_SCOUT_REPORT_QUESTION_TYPE } from '../../../config/constants';
import { getEvaluationRating } from '../../../helpers/evaluationPlayerHelper';

const VIEW_ACTIONS_ENUM = {
  create: 'CREATE',
  view: 'VIEW',
  delete: 'DELETE'
};

const VIEW_ACTIONS = [
  {
    label: 'View',
    value: VIEW_ACTIONS_ENUM.view
  },
  {
    label: 'Copy',
    value: VIEW_ACTIONS_ENUM.create
  },
  {
    label: 'Delete',
    value: VIEW_ACTIONS_ENUM.delete
  }
];

const TABLE_COLUMNS = [
  {
    label: 'Name',
    value: 'name',
    sortable: true
  },
  {
    label: 'Division',
    value: 'division',
    sortable: true
  },
  {
    label: 'Evaluations',
    value: 'evaluations',
    sortable: true
  },
  {
    label: 'Overall',
    value: 'overallRating',
    sortable: true
  }
];

const EvaluationSessionListView = ({
  isLoading,
  regAssociation,
  modalAction,
  isAssociationSuperAdmin
}) => {
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const { state: authState } = useContext(AuthContext);
  const { state, fetchScoutReportEvaluations } = useContext(
    RegistrationAdminContext
  );
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const [filterState, setFilterState] = useState({
    search: '',
    fkRegAssociationDivision: ''
  });
  const {
    sortValue,
    isDescendingSort,
    onChangeSortBy,
    customFilterData
  } = useSortByFilter(TABLE_COLUMNS);

  const regAssociationDivisionOptions = state.regScoutReportEvaluationSessions.value.reduce(
    (r, c) => {
      if (
        !r.some(
          (s) =>
            s.value ===
            c.regAssociationDivision.pkRegAssociationDivision.toString()
        )
      ) {
        r.push({
          label: c.regAssociationDivision.name,
          value: c.regAssociationDivision.pkRegAssociationDivision.toString()
        });
      }
      return r;
    },
    []
  );

  useEffect(() => {
    if (
      (locationState?.openModal && !isLoading) ||
      modalAction === VIEW_ACTIONS_ENUM.create
    ) {
      onOpenModal(VIEW_ACTIONS_ENUM.create);
    }
  }, [locationState?.openModal, isLoading, modalAction]);

  return (
    <AppCard style={{ flex: 1, padding: 0, height: '100%' }}>
      <TableView
        columns={TABLE_COLUMNS}
        emptyMessage="No Reports Available"
        filters={[
          {
            key: 1,
            label: 'Division',
            type: 'select',
            value: filterState.fkRegAssociationDivision,
            placeholder: 'Filter by division',
            data: regAssociationDivisionOptions,
            clearable: true,
            searchable: true,
            onChange: (value) => {
              setFilterState({
                ...filterState,
                fkRegAssociationDivision: value
              });
            }
          },
          {
            key: 2,
            label: 'Session Name',
            type: 'text',
            value: filterState.search,
            placeholder: 'Filter by session',
            onChange: (value) => {
              setFilterState((c) => ({
                ...c,
                search: value
              }));
            }
          }
        ]}
        isDescendingSort={isDescendingSort}
        isLoading={isLoading}
        lastUpdated={state.regScoutReportEvaluationSessions.lastUpdated}
        onAction={(action, item) => {
          if (action === VIEW_ACTIONS_ENUM.view) {
            navigate(`/admin/evaluations/sessions/${item.key}`);
          }
          else {
            onOpenModal(
              action,
              state.regScoutReportEvaluationSessions.value.find(
                (s) => s.pkRegScoutReportEvaluationSession === item.key
              )
            );
          }
        }}
        onChangeSortBy={onChangeSortBy}
        onRefresh={() =>
          fetchScoutReportEvaluations(regAssociation.pkRegAssociation)
        }
        rows={state.regScoutReportEvaluationSessions.value
          .filter(
            (s) =>
              (isAssociationSuperAdmin ||
                s.regScoutReportEvaluationSessionEntries.some(
                  (e) =>
                    e.regAssociationAdmin.user.pkUser ===
                    authState.userData.user.pkUser
                )) &&
              (!filterState.search ||
                s.name
                  .toLowerCase()
                  .includes(filterState.search.toLowerCase())) &&
              (!filterState.fkRegAssociationDivision ||
                s.regAssociationDivision.pkRegAssociationDivision.toString() ===
                  filterState.fkRegAssociationDivision)
          )
          .map((s) => {
            const regScoutReportQuestions = s.regScoutReportEvaluationSessionTemplates.reduce(
              (r, c) => [...r, ...c.regScoutReport.regScoutReportQuestions],
              []
            );
            const metricQuestions = regScoutReportQuestions.filter(
              (q) =>
                q.fkRegScoutReportQuestionType ===
                REG_SCOUT_REPORT_QUESTION_TYPE.METRIC
            );

            const ratedEvaluations = s.regScoutReportEvaluations
              .filter((e) => !e.deleted)
              .map((e) =>
                getEvaluationRating(
                  e,
                  metricQuestions,
                  s.regScoutReportEvaluationSessionEntries
                )
              );
            const overallRating =
              ratedEvaluations.reduce((r, c) => r + c.overallRating, 0) /
              ratedEvaluations.length;

            return {
              key: s.pkRegScoutReportEvaluationSession,
              actions: VIEW_ACTIONS,
              columns: [
                {
                  key: 1,
                  label: s.name,
                  weight: 500,
                  icon: <Ticket color="black" size="30" />,
                  onClick: () =>
                    navigate(
                      `/admin/evaluations/sessions/${s.pkRegScoutReportEvaluationSession}`
                    )
                },
                {
                  key: 2,
                  label: s.regAssociationDivision.name,
                  showOnMobile: true
                },
                {
                  key: 3,
                  label: s.regScoutReportEvaluations.filter((e) => !e.deleted)
                    .length,
                  sortValueType: 'number'
                },
                {
                  key: 4,
                  label: overallRating
                    ? Math.round(overallRating * 10) / 10
                    : '',
                  sortValueType: 'number'
                }
              ]
            };
          })}
        sortBy={sortValue}
        sortFilter={customFilterData}
        tableTitle="Evaluation Sessions"
      />
      <CreateEvaluationSessionModal
        isOpen={modalState.isOpen}
        onClose={onCloseModal}
        pkRegAssociation={regAssociation?.pkRegAssociation}
        regScoutReportEvaluationSession={modalState.item}
        showDelete={modalState.action === VIEW_ACTIONS_ENUM.delete}
      />
    </AppCard>
  );
};

EvaluationSessionListView.propTypes = {
  isAssociationSuperAdmin: PropTypes.bool,
  isLoading: PropTypes.bool,
  modalAction: PropTypes.string,
  regAssociation: PropTypes.object
};

export default EvaluationSessionListView;
