import React from 'react';
import { PropTypes } from 'prop-types';
import { Loader } from '@mantine/core';
import AppStack from './AppStack';

const LoadableView = ({ isLoading, maxWidth, children }) => (
  <AppStack
    style={{
      flex: 1,
      gap: 0,
      maxWidth,
      alignSelf: 'center',
      width: '100%',
      height: '100%'
    }}
  >
    {isLoading ? (
      <AppStack style={{ margin: 'auto' }}>
        <Loader color="dark" size="xl" style={{ margin: 20 }} />
      </AppStack>
    ) : (
      children
    )}
  </AppStack>
);

LoadableView.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default LoadableView;
