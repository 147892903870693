import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Carousel, useAnimationOffsetEffect } from '@mantine/carousel';
import NumberFormat from 'react-number-format';
import { Divider, Select, TextInput } from '@mantine/core';
import AppStack from '../../common/AppStack';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import ResponsiveModal from '../../common/ResponsiveModal';
import FormSection from '../../common/FormSection';
import { triggerNotification } from '../../../helpers/notificationHelper';
import AppImage from '../../common/AppImage';
import AppText from '../../common/AppText';
import RegistrationCartSummary from '../associations/RegistrationCartSummary';
import { currencyFormat } from '../../../helpers/format';
import classes from '../../../styles/nestedStyles.module.css';
import {
  CURRENCY_TYPE_LIST,
  REG_FORM_CONTROL_TYPE_ENUM
} from '../../../config/constants';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const MerchPackageModal = ({
  regAssociation,
  regAssociationOptions,
  merchPackage,
  regCartMerchPackage,
  isDisabled,
  isOpen,
  onClose,
  analyticCta
}) => {
  const mqIndex = useMediaQueryIndex();
  const TRANSITION_DURATION = 200;
  const {
    state,
    updateMerchPackagesInCart,
    addMerchPackagesToCart
  } = useContext(RegistrationContext);
  const [formState, setFormState] = useState({
    isLoading: false,
    inputAnswers: [],
    showCartNav: false,
    fkRegAssociation: null
  });
  const [embla, setEmbla] = useState(null);
  const carouselEnabled = merchPackage?.merchPackageImages.length > 1;
  const currency = CURRENCY_TYPE_LIST.find(
    (c) =>
      c.value.toString() ===
      merchPackage?.regAssociationMerchPackages[0]?.regAssociation.regAssociationPaymentProvider?.fkCurrencyType?.toString()
  )?.abbr;

  useAnimationOffsetEffect(embla, TRANSITION_DURATION);

  useEffect(() => {
    if (isOpen) {
      if (regCartMerchPackage) {
        setFormState({
          isLoading: false,
          inputAnswers: regCartMerchPackage.regCartMerchPackageInputAnswers,
          showCartNav: false,
          fkRegAssociation: regCartMerchPackage.regAssociation.pkRegAssociation.toString()
        });
      }
      else {
        setFormState({
          isLoading: false,
          inputAnswers: [],
          showCartNav: false,
          fkRegAssociation: regAssociationOptions
            ? regAssociation?.pkRegAssociation.toString() ||
              regAssociationOptions[0]?.pkRegAssociation.toString()
            : regAssociation?.pkRegAssociation
        });
      }
    }
  }, [isOpen]);

  const onSuccess = () => {
    if (!regAssociation || regCartMerchPackage) {
      onClose();
    }
    else {
      setFormState({
        ...formState,
        showCartNav: true,
        isLoading: false
      });
    }

    triggerNotification(
      regCartMerchPackage ? 'Merch Info Updated!' : 'Merch Added to Cart!',
      'Success ',
      'green'
    );
  };

  const onError = (error) => {
    setFormState({
      ...formState,
      isLoading: false
    });
    triggerNotification(error);
  };

  const onInputAnswerChange = (input, value) => {
    setFormState({
      ...formState,
      inputAnswers: [
        ...formState.inputAnswers.filter(
          (f) => f.fkMerchPackageInput !== input.pkMerchPackageInput
        ),
        {
          fkMerchPackageInput: input.pkMerchPackageInput,
          answer: value
        }
      ]
    });
  };

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClose}
      size={formState.showCartNav ? 800 : 600}
      transitionProps={{ duration: TRANSITION_DURATION }}
    >
      {formState.showCartNav ? (
        <RegistrationCartSummary
          hideMerchPackageShowcase
          onClose={onClose}
          regAssociation={regAssociation}
          regCart={state.regCart.value}
        />
      ) : (
        <FormSection
          cancelTitle={
            isDisabled ||
            (regCartMerchPackage &&
              merchPackage.merchPackageInputs.length === 0)
              ? 'Close'
              : 'Cancel'
          }
          isLoading={formState.isLoading}
          isSubmitHidden={
            isDisabled ||
            (regCartMerchPackage &&
              merchPackage.merchPackageInputs.length === 0)
          }
          onCancel={onClose}
          onSubmit={(e) => {
            e.stopPropagation();
            setFormState({
              ...formState,
              isLoading: true
            });

            const requiredInputs = merchPackage.merchPackageInputs.filter(
              (i) => i.isRequired
            );
            const allRequiredInputsFilled =
              requiredInputs.length === 0 ||
              requiredInputs.every((i) =>
                formState.inputAnswers.some(
                  (a) => a.fkMerchPackageInput === i.pkMerchPackageInput
                )
              );
            if (!allRequiredInputsFilled) {
              setFormState({
                ...formState,
                isLoading: false
              });
              triggerNotification('Please fill in all required fields');
            }
            else if (regCartMerchPackage) {
              updateMerchPackagesInCart(
                {
                  merchPackages: [
                    {
                      pkRegCartMerchPackage:
                        regCartMerchPackage.pkRegCartMerchPackage,
                      merchPackageUuid: merchPackage.uuid,
                      inputAnswers: formState.inputAnswers,
                      fkRegAssociation: formState.fkRegAssociation,
                      showLoadingState: true
                    }
                  ]
                },
                onSuccess,
                onError
              );
            }
            else {
              addMerchPackagesToCart(
                {
                  merchPackages: [
                    {
                      merchPackageUuid: merchPackage.uuid,
                      inputAnswers: formState.inputAnswers,
                      fkRegAssociation: formState.fkRegAssociation,
                      analytics: { ctaCaptured: analyticCta }
                    }
                  ]
                },
                onSuccess,
                onError
              );
            }
          }}
          submitTitle={regCartMerchPackage ? 'Update Cart' : 'Add to Cart'}
          submitVariant="outline"
        >
          {merchPackage && (
            <AppStack style={{ gap: 20 }}>
              <AppStack
                style={{
                  border: 'solid 1px lightgrey',
                  margin: carouselEnabled
                    ? rs(['0px 50px', '0px 65px', '0px 80px'], mqIndex)
                    : 0
                }}
              >
                <Carousel
                  classNames={{control: classes['mantine-Carousel-control-large']}}
                  controlSize={rs([40, 60, 80], mqIndex)}
                  draggable={carouselEnabled}
                  getEmblaApi={setEmbla}
                  height={300}
                  loop
                  slideGap="sm"
                  slideSize="100%"
                  styles={{
                    controls: {
                      left: rs([-75, -90, -105], mqIndex),
                      right: rs([-75, -90, -105], mqIndex)
                    }
                  }}
                  withControls={carouselEnabled}
                >
                  {merchPackage.merchPackageImages.map((i) => (
                    <Carousel.Slide key={i.pkMerchPackageImage}>
                      <AppStack
                        style={{ flex: 1, height: '100%', overflow: 'hidden' }}
                      >
                        <AppImage src={i.image} />
                      </AppStack>
                    </Carousel.Slide>
                  ))}
                </Carousel>
              </AppStack>

              <AppStack style={{ gap: 0 }}>
                <AppText style={{ fontSize: 18 }} weight={500}>
                  {merchPackage.name}
                </AppText>
                <AppText
                  style={{
                    color: 'grey',
                    fontSize: 14,
                    whiteSpace: 'pre-wrap'
                  }}
                >
                  {merchPackage.description}
                </AppText>
                <AppText size="lg" style={{ marginTop: 5 }} weight={500}>
                  <NumberFormat
                    decimalScale={2}
                    displayType="text"
                    fixedDecimalScale
                    prefix="$"
                    thousandSeparator
                    value={merchPackage.price / 100}
                  />{' '}
                  {currency}
                </AppText>
              </AppStack>

              {!regAssociation && regAssociationOptions?.length > 1 && (
                <Select
                  data={regAssociationOptions.map((a) => ({
                    label: regAssociation.association.name,
                    value: regAssociation.pkRegAssociation.toString()
                  }))}
                  label="Association"
                  onChange={(value) =>
                    setFormState({
                      ...formState,
                      fkRegAssociation: value
                    })
                  }
                  placeholder="Select an option"
                  value={formState.fkRegAssociation}
                />
              )}

              {merchPackage.merchPackageInputs.length > 0 && (
                <>
                  <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                  <AppStack>
                    {merchPackage.merchPackageInputs
                      .sort((a, b) => a.sort - b.sort)
                      .map((i) => {
                        const formValue = formState.inputAnswers.find(
                          (f) => f.fkMerchPackageInput === i.pkMerchPackageInput
                        );

                        return i.fkRegFormControlType ===
                          REG_FORM_CONTROL_TYPE_ENUM.TEXT_INPUT ? (
                          <TextInput
                            key={i.pkMerchPackageInput}
                            disabled={formState.isLoading || isDisabled}
                            label={i.label}
                            onChange={(e) =>
                              onInputAnswerChange(i, e.currentTarget.value)
                            }
                            required={i.isRequired}
                            value={formValue?.answer || ''}
                          />
                        ) : (
                          <Select
                            key={i.pkMerchPackageInput}
                            clearable
                            data={i.merchPackageInputOptions
                              .sort(
                                (a, b) =>
                                  a.pkMerchPackageInputOption -
                                  b.pkMerchPackageInputOption
                              )
                              .map((p) => ({
                                label: p.adjustedCost
                                  ? `${p.value} - ${currencyFormat(
                                      p.adjustedCost / 100
                                    )}`
                                  : p.value,
                                value: p.value
                              }))}
                            disabled={formState.isLoading || isDisabled}
                            label={i.label}
                            onChange={(value) => onInputAnswerChange(i, value)}
                            placeholder="Select an option"
                            required={i.isRequired}
                            searchable
                            value={formValue?.answer || ''}
                          />
                        );
                      })}
                  </AppStack>
                </>
              )}
            </AppStack>
          )}
        </FormSection>
      )}
    </ResponsiveModal>
  );
};

MerchPackageModal.propTypes = {
  analyticCta: PropTypes.string,
  isDisabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  merchPackage: PropTypes.object,
  onClose: PropTypes.func,
  regAssociation: PropTypes.object,
  regAssociationOptions: PropTypes.array,
  regCartMerchPackage: PropTypes.object
};

export default MerchPackageModal;
