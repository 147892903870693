import React, { useState, useContext, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { TextInput } from '@mantine/core';
import { Context as AuthContext } from '../../../providers/AuthProvider';
import AuthCardForm from './AuthCardForm';

const LoginView = ({ navigate }) => {
  const { state, signin, reset } = useContext(AuthContext);
  const [formState, setFormState] = useState({
    email: state.email,
    password: state.password
  });

  useEffect(() => {
    if (!state.email || state.isWaitingConfirmSignUp) {
      reset();
    }

    setFormState({
      email: state.email,
      password: state.password
    });
  }, []);

  return (
    <AuthCardForm
      isLoading={state.loading}
      links={[
        {
          key: 1,
          text: 'Sign Up?',
          onClick: () => navigate('/auth/register'),
          secondaryText: "Don't have a SportsHeadz account?"
        }
      ]}
      navigate={navigate}
      onSubmit={(e) => {
        e.preventDefault();
        signin(formState);
      }}
      submitSecondaryLink={{
        text: 'Forgot Password?',
        onClick: () => navigate('/auth/forgot-password')
      }}
      submitText="Sign In"
      title="Sign In"
    >
      <TextInput
        autoComplete="on"
        disabled={state.loading}
        label="Email Address"
        onChange={(e) =>
          setFormState({
            ...formState,
            email: e.target.value
          })
        }
        required
        size="md"
        type="email"
        value={formState.email}
      />
      <TextInput
        autoComplete="on"
        disabled={state.loading}
        label="Password"
        onChange={(e) =>
          setFormState({
            ...formState,
            password: e.target.value
          })
        }
        required
        size="md"
        type="password"
        value={formState.password}
      />
    </AuthCardForm>
  );
};

LoginView.propTypes = { navigate: PropTypes.func };

export default LoginView;
