import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Divider, Select, TextInput } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppTitle from '../../common/AppTitle';
import FormSection from '../../common/FormSection';
import AppStack from '../../common/AppStack';
import { triggerNotification } from '../../../helpers/notificationHelper';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import DeleteItemView from '../../common/DeleteItemView';
import PaginationSelectList from '../../common/PaginationSelectList';
import AlertAction from '../../common/AlertAction';

const CreateEvaluationSessionModal = ({
  isOpen,
  onClose,
  pkRegAssociation,
  regScoutReportEvaluationSession,
  showDelete
}) => {
  const navigate = useNavigate();
  const {
    state,
    createRegScoutReportEvaluationSession,
    deleteRegScoutReportEvaluationSession
  } = useContext(RegistrationAdminContext);
  const [formState, setFormState] = useState({
    name: '',
    fkRegAssociationDivision: null,
    fkRegScoutReports: [],
    pageIndex: 1,
    isLoading: false
  });

  useEffect(() => {
    if (isOpen) {
      if (regScoutReportEvaluationSession) {
        setFormState({
          name: `${regScoutReportEvaluationSession.name} - Copy`,
          fkRegAssociationDivision: regScoutReportEvaluationSession.regAssociationDivision.pkRegAssociationDivision.toString(),
          fkRegScoutReports: regScoutReportEvaluationSession.regScoutReportEvaluationSessionTemplates.map(
            (t) => t.fkRegScoutReport
          ),
          pageIndex: 1,
          isLoading: false
        });
      }
      else {
        setFormState({
          name: '',
          fkRegAssociationDivision: null,
          fkRegScoutReports: [],
          pageIndex: 1,
          isLoading: false
        });
      }
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      onClose={onClose}
      opened={isOpen}
      title={
        <AppTitle order={2}>
          {showDelete
            ? 'Delete'
            : regScoutReportEvaluationSession
            ? 'Copy'
            : 'Create'}{' '}
          Evaluation Session
        </AppTitle>
      }
    >
      {showDelete ? (
        <DeleteItemView
          isLoading={formState.isLoading}
          itemLabel="Evaluation Session"
          onCancel={onClose}
          onDelete={() => {
            setFormState({
              ...formState,
              isLoading: true
            });
            deleteRegScoutReportEvaluationSession(
              regScoutReportEvaluationSession.pkRegScoutReportEvaluationSession,
              onClose,
              (e) => {
                setFormState({
                  ...formState,
                  isLoading: false
                });
                triggerNotification(e);
              }
            );
          }}
          warnings={[
            'The ability to evaluate and view scores for players from this evaluation.'
          ]}
        />
      ) : (
        <FormSection
          isLoading={formState.isLoading}
          isSubmitDisabled={state.regScoutReports.value.length === 0}
          onCancel={onClose}
          onSubmit={() => {
            setFormState({
              ...formState,
              isLoading: true
            });

            createRegScoutReportEvaluationSession(
              {
                ...formState,
                pkRegScoutReportEvaluationSessionCopy:
                  regScoutReportEvaluationSession?.pkRegScoutReportEvaluationSession,
                regScoutReportEvaluationSessionTemplates: formState.fkRegScoutReports.map(
                  (fkRegScoutReport) => ({ fkRegScoutReport })
                )
              },
              (data) => {
                navigate(
                  `/admin/evaluations/sessions/${data.pkRegScoutReportEvaluationSession}`
                );
              },
              (e) => {
                setFormState({ ...formState, isLoading: false });
                triggerNotification(e);
              }
            );
          }}
          submitTitle={regScoutReportEvaluationSession ? 'Copy' : 'Create'}
        >
          <AppStack style={{ gap: 20 }}>
            {state.regScoutReports.value.length === 0 ? (
              <AlertAction
                actionLabel="Create Template"
                message="No templates available. Please create one before you continue."
                onAction={() => {
                  navigate('/admin/evaluations/templates', {state: { openModal: true }});
                }}
              />
            ) : (
              <>
                <TextInput
                  disabled={formState.isLoading}
                  label="Name"
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      name: e.target.value.substring(0, 255)
                    })
                  }
                  required
                  value={formState.name}
                />
                <Select
                  clearable
                  data={state.regAssociationDivisions.value
                    .filter(
                      (d) =>
                        d.regAssociationSeason.fkRegAssociation ===
                        pkRegAssociation
                    )
                    .map((r) => ({
                      label: r.name,
                      description: r.regAssociationSeason.name,
                      value: r.pkRegAssociationDivision.toString()
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label))}
                  disabled={
                    regScoutReportEvaluationSession || formState.isLoading
                  }
                  label="Division"
                  onChange={(value) =>
                    setFormState({
                      ...formState,
                      fkRegAssociationDivision: value
                    })
                  }
                  renderOption={({ option }) => (
                    <AppFlexbox gap="sm">
                      <div>
                        <AppText size="sm">{option.label}</AppText>
                        {option.description && (
                          <AppText size="xs" style={{ color: 'grey' }}>
                            {option.description}
                          </AppText>
                        )}
                      </div>
                    </AppFlexbox>
                  )}
                  required
                  searchable
                  value={formState.fkRegAssociationDivision}
                />
                <AppStack style={{ gap: 10 }}>
                  <Divider
                    label="ASSIGN TEMPLATES"
                    labelPosition="center"
                    style={{ marginTop: 10 }}
                  />

                  <AppStack style={{ gap: 5 }}>
                    <AppFlexbox>
                      <AppText weight={500}>Evaluation Templates</AppText>
                    </AppFlexbox>

                    <PaginationSelectList
                      disabled={
                        !!regScoutReportEvaluationSession || formState.isLoading
                      }
                      items={state.regScoutReports.value
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((r) => ({
                          key: r.pkRegScoutReport,
                          label: r.name
                        }))}
                      onChange={(fkRegScoutReports) =>
                        setFormState({
                          ...formState,
                          fkRegScoutReports
                        })
                      }
                      selectedItemKeys={formState.fkRegScoutReports}
                    />
                  </AppStack>
                </AppStack>
              </>
            )}
          </AppStack>
        </FormSection>
      )}
    </ResponsiveModal>
  );
};

CreateEvaluationSessionModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  pkRegAssociation: PropTypes.number,
  regScoutReportEvaluationSession: PropTypes.object,
  showDelete: PropTypes.bool
};

export default CreateEvaluationSessionModal;
