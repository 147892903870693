import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mantine/core';
import { ArrowRight } from 'tabler-icons-react';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import { REG_SCOUT_REPORT_QUESTION_TYPE } from '../../../config/constants';
import PlayerStatisticsChart from '../players/PlayerStatisticsChart';
import {
  getEvaluationEntryProgress,
  getEvaluationRating
} from '../../../helpers/evaluationPlayerHelper';
import PlayerCompareChart from '../players/PlayerCompareChart';

const EvaluationSessionStatisticsView = ({
  regScoutReportEvaluationSession,
  continueToEvaluation,
  regScoutReportQuestions,
  regAssociation,
  regAssociationDivisionPlayers
}) => {
  const completedEvaluationEntries = regScoutReportEvaluationSession?.regScoutReportEvaluationSessionEntries?.filter(
    (entry) =>
      getEvaluationEntryProgress(entry, regScoutReportEvaluationSession) >= 100
  );
  const metricQuestions = regScoutReportQuestions.filter(
    (q) =>
      q.fkRegScoutReportQuestionType === REG_SCOUT_REPORT_QUESTION_TYPE.METRIC
  );

  const ratedEvaluations =
    regScoutReportEvaluationSession?.regScoutReportEvaluations
      ?.filter((e) => !e.deleted)
      .map((e) =>
        getEvaluationRating(e, metricQuestions, completedEvaluationEntries)
      ) || [];

  return completedEvaluationEntries.length === 0 ? (
    <AppStack
      style={{
        padding: '40px 10px',
        gap: 10,
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <AppText style={{ textAlign: 'center' }} weight={500}>
        Complete an evaluation to view statistics
      </AppText>
      <Button
        color="dark"
        disabled={
          regScoutReportEvaluationSession.regScoutReportEvaluations.length === 0
        }
        onClick={() => continueToEvaluation()}
        rightSection={<ArrowRight />}
        type="button"
        visibleFrom="md"
      >
        Start Session
      </Button>
    </AppStack>
  ) : (
    <AppStack style={{ padding: 10, gap: 10 }}>
      <PlayerStatisticsChart
        ratedEvaluations={ratedEvaluations}
        regAssociation={regAssociation}
        regAssociationDivisionPlayers={regAssociationDivisionPlayers}
        regScoutReportEvaluationSession={regScoutReportEvaluationSession}
      />
      <PlayerCompareChart
        ratedEvaluations={ratedEvaluations}
        regAssociation={regAssociation}
      />
    </AppStack>
  );
};

EvaluationSessionStatisticsView.propTypes = {
  continueToEvaluation: PropTypes.func,
  regAssociation: PropTypes.object,
  regAssociationDivisionPlayers: PropTypes.array,
  regScoutReportEvaluationSession: PropTypes.object,
  regScoutReportQuestions: PropTypes.array
};

export default EvaluationSessionStatisticsView;
