import { Button, Divider, TextInput } from '@mantine/core';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import ResponsiveModal from '../../common/ResponsiveModal';
import FormSection from '../../common/FormSection';
import AppTitle from '../../common/AppTitle';
import AppText from '../../common/AppText';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const FormSectionModal = ({
  isOpen,
  onClose,
  section,
  onAdd,
  onRemove,
  onUpdate
}) => {
  const mqIndex = useMediaQueryIndex();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [formState, setFormState] = useState({
    title: '',
    positionOrder: null
  });

  useEffect(() => {
    if (isOpen) {
      if (section) {
        setFormState({
          title: section.title,
          positionOrder: section.positionOrder
        });
      }
      else {
        setFormState({ title: '', positionOrder: null });
      }
    }
    setShowDeleteConfirmation(false);
  }, [isOpen]);

  return (
    <ResponsiveModal
      onClose={onClose}
      opened={isOpen}
      size={600}
      title={
        <AppTitle
          style={{
            fontSize: rs([25, 34], mqIndex),
            textAlign: rs(['start', 'center'], mqIndex)
          }}
        >
          {section
            ? showDeleteConfirmation
              ? 'Delete Section'
              : 'Edit Section'
            : 'Create New Section'}
        </AppTitle>
      }
    >
      {!showDeleteConfirmation ? (
        <FormSection
          onCancel={onClose}
          onSubmit={() => {
            if (section) {
              onUpdate(section.pkRegFormSection, formState);
            }
            else {
              onAdd(formState);
            }
            onClose();
          }}
          style={{ gap: 30, flex: 1 }}
          submitTitle={section ? 'Save Changes ' : 'Create'}
        >
          <TextInput
            label="Section Title"
            name="title"
            onChange={(e) =>
              setFormState({
                ...formState,
                title: e.currentTarget.value
              })
            }
            placeholder="Personal Information"
            required
            value={formState.title}
          />

          {section && (
            <>
              <Divider />
              <Button
                color="red"
                onClick={() => setShowDeleteConfirmation(true)}
                style={{ width: '100%', maxWidth: 250, alignSelf: 'center' }}
              >
                Delete Section
              </Button>
            </>
          )}
        </FormSection>
      ) : (
        <FormSection
          onCancel={() => setShowDeleteConfirmation(false)}
          onSubmit={() => {
            onRemove(section.pkRegFormSection);
            onClose();
          }}
          style={{ gap: 30, flex: 1 }}
          submitColor="red"
          submitTitle="Delete Section"
        >
          <AppText style={{ textAlign: 'center' }}>
            Are you sure you want to delete <b>{section.title}</b>?
          </AppText>
        </FormSection>
      )}
    </ResponsiveModal>
  );
};

FormSectionModal.propTypes = {
  isOpen: PropTypes.bool,
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
  onRemove: PropTypes.func,
  onUpdate: PropTypes.func,
  section: PropTypes.object
};

export default FormSectionModal;
