import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import AssociationDetails from '../components/content/associations/AssociationDetails';

const AssociationsView = () => (
  <Routes>
    <Route element={<AssociationDetails />} path="/:pkRegAssociation" />
    <Route element={<Navigate replace to="/leagues" />} path="*" />
  </Routes>
);

export default AssociationsView;
