import PropTypes from 'prop-types';
import React from 'react';
import {
  ActionIcon,
  Alert,
  Button,
  Divider,
  Menu,
  Select,
  Skeleton,
  TextInput
} from '@mantine/core';
import { AlertCircle, DotsVertical, Edit } from 'tabler-icons-react';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import EvaluationSessionEntryItem from './EvaluationSessionEntryItem';
import MenuContent from '../../common/MenuContent';

const EVALUATOR_MENU_ACTIONS_ENUM = {
  view: 'VIEW',
  upload: 'UPLOAD',
  download: 'DOWNLOAD',
  clearAnswers: 'CLEAR_ANSWERS'
};

const EVALUATOR_MENU_ACTIONS = [
  {
    label: 'View',
    value: EVALUATOR_MENU_ACTIONS_ENUM.view
  },
  {
    label: 'Download CSV',
    value: EVALUATOR_MENU_ACTIONS_ENUM.download
  },
  {
    label: 'Upload CSV',
    value: EVALUATOR_MENU_ACTIONS_ENUM.upload
  },
  {
    label: 'Clear Answers',
    value: EVALUATOR_MENU_ACTIONS_ENUM.clearAnswers
  }
];

const EvaluationSessionSettings = ({
  isLoading,
  isSaving,
  canEdit,
  evaluationSessionName,
  regScoutReportEvaluations,
  regScoutReportEvaluationSession,
  evaluationTemplateMetricQuestions,
  regScoutReports,
  onEvaluatorMenuAction,
  onUpdateEvaluationSession,
  onAssignAction
}) =>
  isLoading ? (
    <>
      <AppStack style={{ gap: 5 }}>
        <Skeleton height={18} width={110} />
        <Skeleton height={36} width="100%" />
      </AppStack>
      <AppStack style={{ gap: 5 }}>
        <Skeleton height={18} width={110} />
        <Skeleton height={36} width="100%" />
      </AppStack>
      <Divider />

      <AppStack style={{ gap: 5 }}>
        <Skeleton height={18} width={110} />
        <AppFlexbox
          style={{
            padding: 10,
            border: 'solid 1px lightgrey',
            alignItems: 'center'
          }}
        >
          <Skeleton height={16} width="40%" />
        </AppFlexbox>
      </AppStack>
    </>
  ) : (
    <>
      <TextInput
        disabled={!canEdit || isSaving}
        label="Session Name"
        onChange={(e) =>
          onUpdateEvaluationSession({
            name: e.currentTarget.value.substring(0, 255)
          })
        }
        required
        value={evaluationSessionName}
      />

      <Select
        data={
          regScoutReportEvaluationSession
            ? [
                {
                  label:
                    regScoutReportEvaluationSession.regAssociationDivision.name,
                  value: regScoutReportEvaluationSession.regAssociationDivision.pkRegAssociationDivision.toString()
                }
              ]
            : []
        }
        disabled
        label="Division"
        required
        value={
          regScoutReportEvaluationSession?.regAssociationDivision.pkRegAssociationDivision.toString() ||
          ''
        }
      />

      <Divider />
      <AppStack style={{ gap: 5 }}>
        <AppFlexbox style={{ justifyContent: 'space-between' }}>
          <AppText weight={500}>Evaluation Template</AppText>

          {canEdit && (
            <Button
              disabled={isSaving}
              leftSection={<Edit size={20} />}
              onClick={() => onAssignAction('ASSIGN-TEMPLATES')}
              size="compact-sm"
              variant="outline"
            >
              Assign
            </Button>
          )}
        </AppFlexbox>
        {regScoutReportEvaluationSession
          .regScoutReportEvaluationSessionTemplates.length === 0 ? (
          <AppStack
            style={{
              padding: '30px 10px',
              justifyContent: 'center',
              border: 'solid 1px lightgrey'
            }}
          >
            <AppText style={{ fontWeight: 500, textAlign: 'center ' }}>
              No template added
            </AppText>
          </AppStack>
        ) : (
          regScoutReportEvaluationSession.regScoutReportEvaluationSessionTemplates.map(
            (t) => (
              <AppFlexbox
                key={t.fkRegScoutReport}
                style={{
                  padding: 10,
                  justifyContent: 'space-between',
                  border: 'solid 1px lightgrey',
                  alignItems: 'center'
                }}
              >
                <AppText style={{ fontSize: 14 }} weight={500}>
                  {t.regScoutReport.name}
                </AppText>
              </AppFlexbox>
            )
          )
        )}
      </AppStack>

      {regScoutReportEvaluationSession.regScoutReportEvaluationSessionTemplates
        .length > 0 &&
        evaluationTemplateMetricQuestions.length === 0 && (
          <Alert
            color="red"
            icon={<AlertCircle />}
            title="No metric questions assigned"
            variant="outline"
          >
            <AppStack style={{ gap: 10 }}>
              <AppText style={{ fontSize: 14 }}>
                Please assign a template with metric questions to enable this
                session.
              </AppText>

              <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                You will not be able to use this session to view stats or
                generate balanced teams without metric questions.
              </AppText>
            </AppStack>
          </Alert>
        )}

      {canEdit && (
        <>
          <Divider />
          <AppStack style={{ gap: 5 }}>
            <AppFlexbox style={{ justifyContent: 'space-between' }}>
              <AppText weight={500}>Evaluators</AppText>

              <Button
                disabled={isSaving}
                leftSection={<Edit size={20} />}
                onClick={() => onAssignAction('ASSIGN-EVALUATORS')}
                size="compact-sm"
                variant="outline"
              >
                Assign
              </Button>
            </AppFlexbox>
            {regScoutReportEvaluationSession
              .regScoutReportEvaluationSessionEntries.length === 0 ? (
              <AppStack
                style={{
                  padding: '30px 10px',
                  justifyContent: 'center',
                  border: 'solid 1px lightgrey'
                }}
              >
                <AppText
                  style={{
                    fontWeight: 500,
                    textAlign: 'center '
                  }}
                >
                  No evaluators assigned
                </AppText>
              </AppStack>
            ) : (
              regScoutReportEvaluationSession.regScoutReportEvaluationSessionEntries
                .sort((a, b) =>
                  a.regAssociationAdmin.user.name.localeCompare(
                    b.regAssociationAdmin.user.name
                  )
                )
                .map((e) => (
                  <EvaluationSessionEntryItem
                    key={e.pkRegScoutReportEvaluationSessionEntry}
                    menuContent={
                      <MenuContent
                        control={
                          <ActionIcon color="dark" variant="subtle">
                            <DotsVertical />
                          </ActionIcon>
                        }
                      >
                        {EVALUATOR_MENU_ACTIONS.map((a) => (
                          <Menu.Item
                            key={a.value}
                            onClick={() => onEvaluatorMenuAction(a.value, e)}
                          >
                            {a.label}
                          </Menu.Item>
                        ))}
                      </MenuContent>
                    }
                    regScoutReportEvaluationSession={
                      regScoutReportEvaluationSession
                    }
                    regScoutReportEvaluationSessionEntry={e}
                  />
                ))
            )}
          </AppStack>
        </>
      )}
    </>
  );

EvaluationSessionSettings.propTypes = {
  canEdit: PropTypes.bool,
  evaluationSessionName: PropTypes.string,
  evaluationTemplateMetricQuestions: PropTypes.array,
  isLoading: PropTypes.bool,
  isSaving: PropTypes.bool,
  onAssignAction: PropTypes.func,
  onEvaluatorMenuAction: PropTypes.func,
  onUpdateEvaluationSession: PropTypes.func,
  regScoutReportEvaluationSession: PropTypes.object,
  regScoutReportEvaluations: PropTypes.array,
  regScoutReports: PropTypes.array
};

export default EvaluationSessionSettings;
