import React from 'react';
import PropTypes from 'prop-types';
import { AppShell as MantineAppShell, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import AppFlexbox from './AppFlexbox';

const AppShell = ({ header, footer, navbar, children }) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  return (
    <MantineAppShell
      footer={{ position: 'static' }}
      header={header ? { height: 90, maxHeight: 90 } : null}
      styles={{
        footer: { position: 'static' },
        main: { display: 'flex', flexDirection: 'column' },
        header: { width: '100%' }
      }}
    >
      {header && <MantineAppShell.Header>{header}</MantineAppShell.Header>}
      {navbar && <MantineAppShell.Navbar>{navbar}</MantineAppShell.Navbar>}
      <MantineAppShell.Main style={{ minHeight: '100vh' }}>
        <AppFlexbox style={{ flex: 1, gap: 0, flexDirection: 'column' }}>
          <AppFlexbox
            style={{
              flex: 1,
              gap: 0,
              flexDirection: 'column',
              minHeight: isMobile
                ? header
                  ? 'calc(100vh - 90px)'
                  : '100vh'
                : 'unset'
            }}
          >
            {children}
          </AppFlexbox>
          <AppFlexbox style={{ gap: 0, flexDirection: 'column' }}>
            {footer}
          </AppFlexbox>
        </AppFlexbox>
      </MantineAppShell.Main>
    </MantineAppShell>
  );
};

AppShell.propTypes = {
  children: PropTypes.node,
  footer: PropTypes.node,
  header: PropTypes.node,
  navbar: PropTypes.node
};

export default AppShell;
