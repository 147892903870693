import React, { useContext, useEffect, useRef } from 'react';
import { Anchor, Skeleton } from '@mantine/core';
import { Link } from 'react-router-dom';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import OrderListItem from './OrderListItem';
import AppTitle from '../../common/AppTitle';
import HeaderView from '../../common/HeaderView';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import AppCard from '../../common/AppCard';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import PaginationList from '../../common/PaginationList';

const OrderHistory = () => {
  const mqIndex = useMediaQueryIndex();
  const hasFetched = useRef(false);
  const { state, fetchCartHistory } = useContext(RegistrationContext);
  const isLoading = !hasFetched.current || state.regCartHistory.loading;
  const regCartHistory = state.regCartHistory.value
    .filter(
      (f) => f.regCartProducts.length > 0 || f.regCartMerchPackages.length > 0
    )
    .sort(
      (a, b) => new Date(b?.cart.purchaseDate) - new Date(a?.cart.purchaseDate)
    );

  useEffect(() => {
    fetchCartHistory();
    hasFetched.current = true;
  }, []);

  return (
    <HeaderView
      maxViewWidth={1100}
      viewProps={{
        style: {
          gap: rs([10, 10, 20], mqIndex),
          marginBottom: rs([10, 10, 40], mqIndex)
        }
      }}
    >
      {isLoading ? (
        <Skeleton
          height={44}
          style={{ marginTop: rs([10, 10, 0], mqIndex) }}
          width={250}
        />
      ) : (
        <AppTitle style={{ marginTop: rs([5, 5, 0], mqIndex) }}>
          Your Orders
        </AppTitle>
      )}

      {!isLoading && regCartHistory.length === 0 ? (
        <AppCard>
          <AppFlexbox
            style={{
              alignSelf: 'center',
              justifyContent: 'center',
              padding: 40,
              gap: 10
            }}
          >
            <AppText style={{ fontSize: 16 }}>
              You have not placed any orders.
            </AppText>
            <Anchor component={Link} to="/leagues">
              <AppText style={{ fontSize: 16 }}>
                View available registrations
              </AppText>
            </Anchor>
          </AppFlexbox>
        </AppCard>
      ) : (
        <PaginationList
          isLoading={isLoading}
          items={regCartHistory.map((c) => (
            <OrderListItem key={c.pkRegCart} regCart={c} />
          ))}
          itemsPerPage={5}
          listProps={{ style: { gap: 20 } }}
          LoadingComponent={OrderListItem}
          noBorder
        />
      )}
    </HeaderView>
  );
};

export default OrderHistory;
