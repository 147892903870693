import React, { useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import FormSection from '../../common/FormSection';
import FinancialRegistrationTransactionList from './FinancialRegistrationTransactionList';
import { triggerNotification } from '../../../helpers/notificationHelper';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppTitle from '../../common/AppTitle';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';

const RebaseInvoiceModal = ({ isOpen, onClose, regFormSubmissionPayment }) => {
  const {
    rebaseAdminRegistrationInvoice,
    fetchUpdatedAdminFinancialRegistration,
    fetchAdminRegFormSubmissionPayments
  } = useContext(RegistrationAdminContext);
  const [formState, setFormState] = useState({ isLoading: false });

  useEffect(() => {
    if (isOpen) {
      setFormState({
        ...formState,
        isLoading: false
      });
    }
  }, []);

  return (
    <ResponsiveModal
      onClose={onClose}
      opened={isOpen}
      title={<AppTitle order={3}>Registration Payment Summary</AppTitle>}
    >
      <FormSection
        isLoading={formState.isLoading}
        onCancel={onClose}
        onSubmit={() => {
          setFormState({
            ...formState,
            isLoading: true
          });
          rebaseAdminRegistrationInvoice(
            regFormSubmissionPayment.regFormSubmissionInvoice
              .pkRegFormSubmissionInvoice,
            { isAdditionalCharge: false },
            () => {
              fetchUpdatedAdminFinancialRegistration(
                regFormSubmissionPayment.fkRegFormSubmission
              );
              fetchAdminRegFormSubmissionPayments(
                regFormSubmissionPayment.fkRegFormSubmission
              );
              onClose();
            },
            (error) => {
              triggerNotification(error);
              setFormState({
                ...formState,
                isLoading: false
              });
            }
          );
        }}
        style={{ gap: 30 }}
        submitTitle="Rebase"
      >
        <AppStack style={{ gap: 30, marginTop: 20 }}>
          <AppText style={{ textAlign: 'center' }} weight={500}>
            Are you sure you want to rebase the following payment?
          </AppText>
          <FinancialRegistrationTransactionList
            payments={[regFormSubmissionPayment]}
          />
        </AppStack>
      </FormSection>
    </ResponsiveModal>
  );
};

RebaseInvoiceModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  regFormSubmissionPayment: PropTypes.object
};
export default RebaseInvoiceModal;
