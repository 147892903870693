import React, { useContext, useEffect, useRef, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Plus } from 'tabler-icons-react';
import { Context as RegistrationAdminContext } from '../providers/RegistrationAdminProvider';
import { Context as RegistrationAdminDashboardContext } from '../providers/RegistrationAdminDashboardProvider';
import { Context as AuthContext } from '../providers/AuthProvider';
import AdminView from '../components/common/AdminView';
import { useModalState } from '../helpers/hooks';
import SUPPORT_LINKS from '../components/content/navigation/supportLinks';
import EvaluationPlayersListView from '../components/content/evaluation/EvaluationPlayersListView';
import EvaluatorListView from '../components/content/evaluation/EvaluatorListView';
import { REG_PERMISSION_ENUM } from '../config/constants';
import EvaluationTemplateView from '../components/content/evaluation/EvaluationTemplateView';
import EvaluationSessionView from '../components/content/evaluation/EvaluationSessionView';
import EvaluationSessionListView from '../components/content/evaluation/EvaluationSessionListView';
import EvaluationSessionEvaluateView from '../components/content/evaluation/EvaluationSessionEvaluateView';
import EvaluationTemplateListView from '../components/content/evaluation/EvaluationTemplateListView';

const VIEW_TABS = [
  {
    to: '/admin/evaluations/sessions',
    title: 'Sessions',
    value: 'sessions',
    control: {
      label: 'Create Session',
      icon: <Plus />,
      action: 'CREATE'
    },
    indexHelpLink: SUPPORT_LINKS.scoutingLinks.index,
    helpLinks: [SUPPORT_LINKS.scoutingLinks.evaluatingPlayers]
  },
  {
    to: '/admin/evaluations/templates',
    title: 'Templates',
    value: 'templates',
    control: {
      label: 'Create Template',
      icon: <Plus />,
      action: 'CREATE'
    },
    indexHelpLink: SUPPORT_LINKS.scoutingLinks.index,
    helpLinks: [SUPPORT_LINKS.scoutingLinks.evaluatingPlayers],
    isSuperAdminOnly: true
  },
  {
    to: '/admin/evaluations/players',
    title: 'Players',
    value: 'players',
    control: {
      label: 'Create Player',
      icon: <Plus />,
      action: 'CREATE'
    },
    indexHelpLink: SUPPORT_LINKS.scoutingLinks.index,
    helpLinks: [SUPPORT_LINKS.scoutingLinks.evaluatingPlayers],
    isSuperAdminOnly: true
  },
  {
    to: '/admin/evaluations/evaluators',
    title: 'Evaluators',
    value: 'evaluators',
    control: {
      label: 'Invite Evaluator',
      icon: <Plus />,
      action: 'CREATE'
    },
    indexHelpLink: SUPPORT_LINKS.scoutingLinks.index,
    helpLinks: [SUPPORT_LINKS.scoutingLinks.evaluatingPlayers],
    isSuperAdminOnly: true
  }
];

const EvaluationsView = () => {
  const [isRenderingPlayers, setIsRenderingPlayers] = useState(false);
  const fetchedPkRegAssociation = useRef(false);
  const fetchedEvaluatorsPkRegAssociation = useRef(null);
  const { pathname } = useLocation();
  const { state: modalState, onOpenModal } = useModalState({closeOnOpen: true});
  const { state: authState } = useContext(AuthContext);
  const { state: dashboardState } = useContext(
    RegistrationAdminDashboardContext
  );
  const {
    state,
    fetchScoutReportEvaluations,
    fetchAdminRegAssociationDivisions,
    fetchRegAssociationAdministrators
  } = useContext(RegistrationAdminContext);
  const regAssociation = dashboardState.regAssociation.value;
  const isLoading =
    !fetchedPkRegAssociation.current ||
    state.regScoutReportEvaluationSessions.loading;

  const currentView = VIEW_TABS.find((tab) => pathname.startsWith(tab.to));

  const regAssociationAdmin = authState.regAssociationAdmins.value.find(
    (a) =>
      a.regAssociation.pkRegAssociation === regAssociation?.pkRegAssociation
  );
  const isAssociationSuperAdmin = regAssociationAdmin?.regAssociationAdminPermissions.some(
    (p) => p.fkRegPermission === REG_PERMISSION_ENUM.SUPER_ADMIN
  );

  const isPlayersLoading =
    currentView?.value === 'players' &&
    (!isRenderingPlayers || state.regScoutReportEvaluationPlayers.loading);

  useEffect(() => {
    if (
      regAssociation &&
      regAssociation.pkRegAssociation !== fetchedPkRegAssociation.current
    ) {
      fetchScoutReportEvaluations(regAssociation.pkRegAssociation);
      if (!fetchedPkRegAssociation.current) {
        fetchAdminRegAssociationDivisions();
      }

      fetchedPkRegAssociation.current = regAssociation.pkRegAssociation;
    }
  }, [regAssociation]);

  useEffect(() => {
    if (currentView && regAssociation) {
      if (currentView.value === 'players') {
        setIsRenderingPlayers(true);
      }
      else {
        setIsRenderingPlayers(false);

        if (
          currentView.value === 'evaluators' &&
          fetchedEvaluatorsPkRegAssociation.current !==
            regAssociation.pkRegAssociation
        ) {
          fetchRegAssociationAdministrators(regAssociation.pkRegAssociation, {includeDeleted: true});
          fetchedEvaluatorsPkRegAssociation.current =
            regAssociation.pkRegAssociation;
        }
      }
    }
  }, [regAssociation, currentView?.value]);

  return (
    <Routes>
      <Route
        element={<EvaluationTemplateView />}
        path="/templates/:pkRegScoutReport"
      />
      <Route
        element={
          <EvaluationSessionView
            isAssociationSuperAdmin={isAssociationSuperAdmin}
          />
        }
        path="/sessions/:pkRegScoutReportEvaluationSession"
      />
      <Route
        element={<EvaluationSessionEvaluateView />}
        path="/sessions/:pkRegScoutReportEvaluationSession/evaluate"
      />
      <Route
        element={<EvaluationSessionEvaluateView />}
        path="/sessions/:pkRegScoutReportEvaluationSession/evaluate/:pkRegScoutReportEvaluationSessionEntry"
      />
      <Route
        element={
          <AdminView
            headerTitle="Scouting & Evaluations"
            isViewLoading={isLoading || isPlayersLoading}
            onAction={onOpenModal}
            tabs={
              isAssociationSuperAdmin
                ? VIEW_TABS
                : VIEW_TABS.filter((t) => !t.isSuperAdminOnly).map((t) => ({
                    ...t,
                    control: null
                  }))
            }
          >
            <Routes>
              <Route
                element={
                  <EvaluationSessionListView
                    isAssociationSuperAdmin={isAssociationSuperAdmin}
                    isLoading={isLoading}
                    modalAction={modalState.isOpen ? modalState.action : ''}
                    regAssociation={regAssociation}
                  />
                }
                path="/sessions"
              />
              {isAssociationSuperAdmin && (
                <>
                  <Route
                    element={
                      <EvaluationTemplateListView
                        isLoading={isLoading}
                        modalAction={modalState.isOpen ? modalState.action : ''}
                        regAssociation={regAssociation}
                      />
                    }
                    path="/templates"
                  />
                  <Route
                    element={
                      <EvaluationPlayersListView
                        isLoading={
                          isLoading ||
                          state.regScoutReportEvaluationPlayers.loading
                        }
                        modalAction={modalState.isOpen ? modalState.action : ''}
                        regAssociation={regAssociation}
                      />
                    }
                    path="/players"
                  />
                  <Route
                    element={
                      <EvaluatorListView
                        isLoading={
                          isLoading || state.regAssociationAdmins.loading
                        }
                        modalAction={modalState.isOpen ? modalState.action : ''}
                        regAssociation={regAssociation}
                      />
                    }
                    path="/evaluators"
                  />
                </>
              )}

              {regAssociation && (
                <Route
                  element={<Navigate replace to={VIEW_TABS[0].to} />}
                  path="*"
                />
              )}
            </Routes>
          </AdminView>
        }
        path="*"
      />
    </Routes>
  );
};

export default EvaluationsView;
