import React from 'react';
import { Divider, Skeleton } from '@mantine/core';
import { PropTypes } from 'prop-types';
import RegistrationFormSection from './RegistrationFormSection';
import { persoanlInformationSectionData } from './managedFormSectionsData';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';

const RegistrationFormSkeleton = ({ showSubmitButton }) => (
  <AppStack style={{ gap: 20 }}>
    <RegistrationFormSection
      isLoading
      section={persoanlInformationSectionData}
    />
    <Divider style={{ marginTop: 20 }} />
    <AppFlexbox
      style={{
        justifyContent: 'center',
        gap: 25,
        marginTop: 10
      }}
    >
      <Skeleton height={34} style={{ flex: 1, maxWidth: 200 }} width="25%" />
      {showSubmitButton && (
        <Skeleton height={34} style={{ flex: 1, maxWidth: 200 }} width="25%" />
      )}
    </AppFlexbox>
  </AppStack>
);

RegistrationFormSkeleton.propTypes = { showSubmitButton: PropTypes.bool };

export default RegistrationFormSkeleton;
