import React from 'react';
import PropTypes from 'prop-types';
import { useHover } from '@mantine/hooks';
import NumberFormat from 'react-number-format';
import { Skeleton } from '@mantine/core';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppCard from '../../common/AppCard';
import AppImage from '../../common/AppImage';
import AppFlexbox from '../../common/AppFlexbox';
import { CURRENCY_TYPE_LIST } from '../../../config/constants';

const MerchPackageShowcaseCard = ({ merchPackage, onClick }) => {
  const { hovered, ref } = useHover();
  const firstImage = merchPackage?.merchPackageImages.sort(
    (a, b) => a.sort - b.sort
  )[0];
  const currency = CURRENCY_TYPE_LIST.find(
    (c) =>
      c.value.toString() ===
      merchPackage?.regAssociationMerchPackages[0]?.regAssociation.regAssociationPaymentProvider?.fkCurrencyType?.toString()
  )?.abbr;

  return merchPackage ? (
    <AppCard
      innerRef={ref}
      onClick={onClick}
      style={{
        flex: 1,
        height: '100%',
        cursor: 'pointer',
        padding: 0,
        border: hovered ? 'solid 1px dodgerblue' : 'solid 1px lightgrey'
      }}
    >
      <AppStack
        style={{
          flex: 1,
          height: '100%',
          gap: 0,
          padding: 10
        }}
      >
        <AppStack
          style={{
            flex: 1,
            height: '100%',
            overflow: 'hidden'
          }}
        >
          {!firstImage ? (
            <AppStack
              style={{
                flex: 1,
                height: '100%',
                overflow: 'hidden',
                border: 'solid 1px lightgrey',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <AppText style={{ textAlign: 'center' }}>
                No image available
              </AppText>
            </AppStack>
          ) : (
            <AppImage src={firstImage?.image} />
          )}
        </AppStack>
        <AppStack style={{ gap: 0 }}>
          <AppText style={{ lineHeight: '22px' }} weight={500}>
            {merchPackage.name}
          </AppText>
          <AppText
            style={{
              fontSize: 14,
              color: 'grey',
              textOverflow: 'ellipsis',
              lineHeight: '16px',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              lineClamp: '2',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              whiteSpace: 'pre-wrap'
            }}
          >
            {merchPackage.description}
          </AppText>
          <AppFlexbox style={{ gap: 5, marginTop: 5 }}>
            <AppText size="lg" weight={500}>
              <NumberFormat
                decimalScale={2}
                displayType="text"
                fixedDecimalScale
                prefix="$"
                thousandSeparator
                value={merchPackage.price / 100}
              />{' '}
              {currency}
            </AppText>
          </AppFlexbox>
        </AppStack>
      </AppStack>
    </AppCard>
  ) : (
    <AppCard
      innerRef={ref}
      onClick={onClick}
      style={{
        flex: 1,
        height: '100%',
        padding: 0,
        border: 'solid 1px lightgrey'
      }}
    >
      <AppStack
        style={{
          flex: 1,
          height: '100%',
          gap: 10,
          padding: 10
        }}
      >
        <AppStack style={{ flex: 1, height: '100%', overflow: 'hidden' }}>
          <Skeleton height="100%" width="100%" />
        </AppStack>

        <AppStack style={{ gap: 5 }}>
          <Skeleton height={16} width="40%" />
          <Skeleton height={10} width="80%" />
          <Skeleton height={10} width="80%" />
        </AppStack>
        <Skeleton height={22} width="25%" />
      </AppStack>
    </AppCard>
  );
};

MerchPackageShowcaseCard.propTypes = {
  merchPackage: PropTypes.object,
  onClick: PropTypes.func
};

export default MerchPackageShowcaseCard;
