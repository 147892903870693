const calculateGridSize = (dragEvent) => {
  const parent = dragEvent.target.closest('[role="region"]');
  const parentWidth = parent.getBoundingClientRect().width;

  return {
    columnWidth: parentWidth / 12 - 2,
    rowHeight: 80
  };
};

const calcualteNewPosition = (
  dragPosition,
  control,
  columnWidth,
  rowHeight,
  sectionRowInfo
) => {
  const { y } = dragPosition;

  let extraHeight = 0;
  if (sectionRowInfo) {
    sectionRowInfo.forEach((s, index) => {
      const currentRow = Math.round((y - extraHeight) / rowHeight);

      if (
        currentRow > 0 &&
        index > control.positionRow - 1 &&
        index < control.positionRow + currentRow - 1
      ) {
        extraHeight += s.height - rowHeight;
      }
      else if (
        currentRow < 0 &&
        index < control.positionRow - 1 &&
        index >= control.positionRow + currentRow
      ) {
        extraHeight -= s.height - rowHeight;
      }
    });
  }

  let dragRow = (y - extraHeight) / rowHeight;
  if (dragRow > 0) {
    dragRow = Math.ceil(dragRow);
  }
  else {
    dragRow = Math.floor(dragRow);
  }
  const dragColumn = Math.round(dragPosition.x / columnWidth);

  let positionRow = control.positionRow + dragRow;
  if (positionRow <= 0) {
    positionRow = 1;
  }

  return {
    positionRow,
    positionColumn: control.positionColumn + dragColumn,
    positionSpan: control.positionSpan
  };
};

const calcualteResizePosition = (
  dragPosition,
  control,
  initialPosition,
  columnWidth,
  direction
) => {
  let resizedPosition = {};
  if (direction === 'left') {
    const dragColumn =
      Math.round((dragPosition.x + columnWidth / 2) / columnWidth) - 1;
    let resizeColumn = control.positionColumn + dragColumn;
    if (resizeColumn < 1) {
      resizeColumn = 1;
    }
    else if (resizeColumn > control.positionColumn + control.positionSpan - 1) {
      resizeColumn = control.positionColumn + control.positionSpan - 1;
    }

    let resizeSpan =
      control.positionSpan + (control.positionColumn - resizeColumn);
    if (resizeSpan < 1) {
      resizeSpan = 1;
    }
    resizedPosition = {
      positionRow: control.positionRow,
      positionColumn: resizeColumn,
      positionSpan: resizeSpan
    };
  }
  else if (direction === 'right') {
    const dragColumn = Math.round(
      (dragPosition.x + columnWidth / 2) / columnWidth
    );

    const resizeColumn =
      initialPosition.positionColumn +
      initialPosition.positionSpan +
      dragColumn;

    let resizeSpan = resizeColumn - initialPosition.positionColumn;
    if (resizeSpan < 1) {
      resizeSpan = 1;
    }
    else if (initialPosition.positionColumn + resizeSpan > 13) {
      resizeSpan = 13 - initialPosition.positionColumn;
    }
    resizedPosition = {
      positionRow: initialPosition.positionRow,
      positionColumn: initialPosition.positionColumn,
      positionSpan: resizeSpan
    };
  }

  return resizedPosition;
};

const calculateMinSize = (control, columnWidth, domRef) => {
  let totalRadioGroupWidth = 1;
  if (control.type === 'RADIO_GROUP') {
    const radioInputs = domRef.querySelector("[role='radiogroup']").childNodes;

    [...radioInputs].forEach((element) => {
      totalRadioGroupWidth += element.getBoundingClientRect().width + 12;
    });
  }

  return Math.ceil(totalRadioGroupWidth / columnWidth);
};

const calculateOpenPosition = (section, control) => {
  const isMaxSize = control.type === 'RADIO_GROUP' || control.type === 'TEXT';

  let positionRow = 1;
  let positionColumn = 1;
  let positionSpan = isMaxSize ? 12 : 3;
  if (section.regFormControls.length > 0) {
    const maxRow = Math.max(
      ...section.regFormControls.map((c) => c.positionRow)
    );

    positionRow = maxRow + 1;
    if (isMaxSize) {
      positionSpan = 12;
    }
    else {
      const spaceTakenOnRow = Math.max(
        ...section.regFormControls
          .filter((c) => c.positionRow === maxRow)
          .map((c) => c.positionColumn + c.positionSpan - 1)
      );

      if (spaceTakenOnRow <= 6) {
        positionRow = maxRow;
        positionColumn = spaceTakenOnRow + 1;
      }
    }
  }

  return {
    positionRow,
    positionColumn,
    positionSpan
  };
};

const isNewPositionOverlapping = (newPosition, sectionControls) =>
  sectionControls.find(
    (c) =>
      c.positionRow === newPosition.positionRow &&
      newPosition.positionColumn + newPosition.positionSpan - 1 >=
        c.positionColumn &&
      newPosition.positionColumn <= c.positionColumn + c.positionSpan - 1
  ) !== undefined;

const getSectionRowInfo = (sectionRef) => {
  if (!sectionRef) {
    return [];
  }

  const rows = [];
  const gridColumns = sectionRef.querySelectorAll("[role='gridcell']") ?? [];
  [...gridColumns].forEach((element) => {
    const columnPosition = element.getBoundingClientRect();
    const existingRowIndex = rows.findIndex((r) => r.y === columnPosition.y);
    if (existingRowIndex === -1) {
      rows.push(columnPosition);
    }
    else if (columnPosition.height > rows[existingRowIndex].height) {
      rows.splice(existingRowIndex, 1);
      rows.push(columnPosition);
    }
  });
  return rows;
};

export {
  calculateGridSize,
  calcualteNewPosition,
  calcualteResizePosition,
  calculateMinSize,
  calculateOpenPosition,
  getSectionRowInfo,
  isNewPositionOverlapping
};
