import React, { useContext, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Context as AuthContext } from '../../../providers/AuthProvider';
import SendAuthCode from './SendAuthCode';
import { triggerNotification } from '../../../helpers/notificationHelper';

const ForgotPasswordView = ({ navigate }) => {
  const { reset, sendForgotPasswordConfirmation } = useContext(AuthContext);

  useEffect(() => {
    reset();
  }, []);

  return (
    <SendAuthCode
      onCancel={() => {
        navigate('/auth/login');
      }}
      onSendCode={(email) => {
        sendForgotPasswordConfirmation(email, () => {
          triggerNotification('Reset code sent!', 'Success', 'green');
          navigate('/auth/reset-password');
        });
      }}
      title="Reset Your Password"
      type="password"
    />
  );
};

ForgotPasswordView.propTypes = { navigate: PropTypes.func };

export default ForgotPasswordView;
