import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Context as RegistrationContext } from '../providers/RegistrationProvider';
import LoadableView from '../components/common/LoadableView';
import { triggerNotification } from '../helpers/notificationHelper';

const AssocioationRedirectView = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { fetchRegAssociations } = useContext(RegistrationContext);
  const pkAssociation = new URLSearchParams(search).get('pkAssociation');

  useEffect(() => {
    if (pkAssociation) {
      fetchRegAssociations(
        { fkAssociation: pkAssociation },
        (data) => {
          const regAssociation = data.find(
            (a) => a.association.pkAssociation.toString() === pkAssociation
          );
          if (!regAssociation) {
            window.location.replace(
              'https://www.sportsheadz.com/sportsheadz-registration'
            );
          }
          else {
            navigate(`/association/${regAssociation.pkRegAssociation}`);
          }
        },
        (e) => {
          triggerNotification(e);
          navigate('/leagues');
        }
      );
    }
    else {
      navigate('/leagues');
    }
  }, [pkAssociation]);

  return <LoadableView isLoading />;
};
export default AssocioationRedirectView;
