import React, { useContext, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import ParticipantList from '../components/content/participants/ParticipantList';
import { Context as RegistrationContext } from '../providers/RegistrationProvider';

const ParticipantsView = () => {
  const { fetchRegistrants } = useContext(RegistrationContext);

  useEffect(() => {
    fetchRegistrants();
  }, []);

  return (
    <Routes>
      <Route element={<ParticipantList />} path="/" />
      <Route element={<Navigate replace to="/participants" />} path="*" />
    </Routes>
  );
};

export default ParticipantsView;
