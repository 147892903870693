import React, { useState } from 'react';
import { CircleCheck, Copy, Edit, Trash } from 'tabler-icons-react';
import {
  ActionIcon,
  Button,
  Divider,
  Menu,
  Skeleton,
  TextInput,
  Textarea
} from '@mantine/core';
import PropTypes from 'prop-types';
import AppCard from '../../common/AppCard';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import ActionableIcon from '../../common/ActionableIcon';
import AppText from '../../common/AppText';
import {
  REG_FORM_CONTROL_TYPE_ENUM,
  REG_SCOUT_REPORT_QUESTION_TYPE
} from '../../../config/constants';
import ColoredAvatar from '../../common/ColoredAvatar';
import MenuContent from '../../common/MenuContent';
import EvaluationTemplateQuestionInput from './EvaluationTemplateQuestionInput';
import EvaluationTemplateQuestionRestrictions from './EvaluationTemplateQuestionRestrictions';
import ConfirmModal from '../../common/ConfirmModal';

const QUESTION_TYPE = [
  {
    label: 'Slider',
    value: REG_FORM_CONTROL_TYPE_ENUM.SLIDER
  },
  {
    label: 'Dropdown',
    value: REG_FORM_CONTROL_TYPE_ENUM.DROP_DOWN
  },
  {
    label: 'Buttons',
    value: REG_FORM_CONTROL_TYPE_ENUM.BUTTON_GROUP
  }
];

const ATTRIBUTE_TYPE = [
  {
    label: 'Dropdown',
    value: REG_FORM_CONTROL_TYPE_ENUM.DROP_DOWN
  },
  {
    label: 'Text',
    value: REG_FORM_CONTROL_TYPE_ENUM.TEXT_INPUT
  }
];

const QUESTION_ACTIONS = {
  snippets: 'SNIPPETS',
  removeAnswer: 'REMOVE_ANSWER'
};

const EvaluationTemplateQuestion = ({
  id,
  question,
  evaluation,
  showPlayerView,
  onChange,
  onRemove,
  onCopy,
  onModalAction,
  isEditMode,
  onRemoveRestriction,
  onUpdateEvaluationAnswer,
  evaluationSessionEntry,
  isSmallScreen
}) => {
  const [actionState, setActionState] = useState(null);
  const isAttribute =
    question?.fkRegScoutReportQuestionType ===
    REG_SCOUT_REPORT_QUESTION_TYPE.ATTRIBUTE;

  const questionAnswer = evaluation?.regScoutReportQuestionAnswers.find(
    (a) =>
      a.fkRegScoutReportQuestion === question?.pkRegScoutReportQuestion &&
      (isAttribute ||
        a.fkRegScoutReportEvaluationSessionEntry ===
          evaluationSessionEntry?.pkRegScoutReportEvaluationSessionEntry)
  );

  return question ? (
    <AppCard id={id} radius="md" shadow="md" style={{ overflow: 'unset' }}>
      <AppStack style={{ gap: 15 }}>
        {isEditMode ? (
          <>
            <AppFlexbox>
              <AppFlexbox
                style={{
                  gap: 10,
                  alignItems: 'center',
                  flex: 1
                }}
              >
                <AppText weight={500}>{question.sort}</AppText>
                <TextInput
                  onChange={(e) =>
                    onChange({
                      ...question,
                      value: e.currentTarget.value
                    })
                  }
                  placeholder={
                    isAttribute
                      ? 'Height, Weight, ect.'
                      : 'Shooting, Passing, Dribbling, etc.'
                  }
                  required
                  style={{ flex: 1 }}
                  value={question.value}
                />
              </AppFlexbox>
              <AppFlexbox style={{ gap: 10, alignItems: 'center' }}>
                <ActionableIcon
                  color="dark"
                  onClick={() => onCopy(question)}
                  radius="xl"
                  variant="subtle"
                >
                  <Copy />
                </ActionableIcon>
                <ActionableIcon
                  color="red"
                  onClick={() => onRemove(question)}
                  radius="xl"
                  variant="subtle"
                >
                  <Trash />
                </ActionableIcon>
              </AppFlexbox>
            </AppFlexbox>
            {isAttribute ? (
              <AppFlexbox>
                <AppStack style={{ flex: 1 }}>
                  <AppFlexbox
                    style={{
                      justifyContent: 'space-between',
                      alignItems: 'end'
                    }}
                  >
                    <AppStack style={{ gap: 10 }}>
                      <AppFlexbox>
                        <AppText style={{ fontSize: 14 }} weight={500}>
                          Attribute Type
                        </AppText>
                        {question.fkRegFormControlType ===
                          REG_FORM_CONTROL_TYPE_ENUM.DROP_DOWN && (
                          <Button
                            color="blue"
                            leftSection={<Edit size={20} />}
                            onClick={() => onModalAction('QUESTION-OPTIONS')}
                            size="compact-xs"
                            variant="outline"
                          >
                            Edit Options
                          </Button>
                        )}
                      </AppFlexbox>

                      <Button.Group>
                        {ATTRIBUTE_TYPE.map((t) => {
                          const isSelected =
                            question.fkRegFormControlType === t.value;
                          return (
                            <Button
                              key={t.value}
                              color="dark"
                              onClick={() =>
                                onChange({
                                  ...question,
                                  fkRegFormControlType: t.value
                                })
                              }
                              variant={isSelected ? 'filled' : 'light'}
                            >
                              {t.label}
                            </Button>
                          );
                        })}
                      </Button.Group>
                    </AppStack>
                  </AppFlexbox>
                  <Divider label="PREVIEW" labelPosition="center" />

                  <EvaluationTemplateQuestionInput
                    evaluation={evaluation}
                    isEditMode={isEditMode}
                    isSmallScreen={isSmallScreen}
                    onUpdateEvaluationAnswer={onUpdateEvaluationAnswer}
                    question={question}
                    questionAnswer={questionAnswer}
                  />
                </AppStack>
                <EvaluationTemplateQuestionRestrictions
                  onAddRestriction={() =>
                    onModalAction('QUESTION-RESTRICTIONS')
                  }
                  onEditRestriction={(r) =>
                    onModalAction('QUESTION-RESTRICTIONS', r)
                  }
                  onRemoveRestriction={(r) => onRemoveRestriction(r)}
                  regScoutReportQuestion={question}
                />
              </AppFlexbox>
            ) : (
              <>
                <AppFlexbox
                  style={{
                    justifyContent: 'space-between',
                    alignItems: 'end'
                  }}
                >
                  <AppStack style={{ gap: 10 }}>
                    <AppFlexbox>
                      <AppText style={{ fontSize: 14 }} weight={500}>
                        Question Type
                      </AppText>
                      {question.fkRegFormControlType !==
                        REG_FORM_CONTROL_TYPE_ENUM.SLIDER && (
                        <Button
                          color="blue"
                          leftSection={<Edit size={20} />}
                          onClick={() => onModalAction('QUESTION-OPTIONS')}
                          size="compact-xs"
                          variant="outline"
                        >
                          Edit Options
                        </Button>
                      )}
                    </AppFlexbox>
                    <Button.Group>
                      {QUESTION_TYPE.map((t) => {
                        const isSelected =
                          question.fkRegFormControlType === t.value;
                        return (
                          <Button
                            key={t.value}
                            color="dark"
                            onClick={() =>
                              onChange({
                                ...question,
                                fkRegFormControlType: t.value
                              })
                            }
                            variant={isSelected ? 'filled' : 'light'}
                          >
                            {t.label}
                          </Button>
                        );
                      })}
                    </Button.Group>
                  </AppStack>
                </AppFlexbox>
                <Divider label="PREVIEW" labelPosition="center" />

                <EvaluationTemplateQuestionInput
                  isSmallScreen={isSmallScreen}
                  question={question}
                />

                <AppStack style={{ gap: 10, marginTop: 5 }}>
                  <AppFlexbox>
                    <AppText style={{ fontSize: 14 }} weight={500}>
                      Comments
                    </AppText>
                    <Button
                      onClick={() => onModalAction('QUESTION-SNIPPETS')}
                      size="compact-xs"
                      variant="outline"
                    >
                      Snippets{' '}
                      {`(${question.regScoutReportQuestionSnippets.length})`}
                    </Button>
                  </AppFlexbox>
                  <Textarea disabled minRows={2} onChange={() => {}} value="" />
                </AppStack>
              </>
            )}
          </>
        ) : (
          <>
            <AppFlexbox
              style={{
                justifyContent: 'space-between',
                gap: 10,
                alignItems: 'center',
                minHeight: 30
              }}
            >
              <AppFlexbox style={{ gap: 10, alignItems: 'center' }}>
                {questionAnswer?.value ? (
                  <CircleCheck color="#40C057" size={25} />
                ) : (
                  <AppStack
                    style={{
                      width: 25,
                      height: 25,
                      justifyContent: 'center',
                      placeItems: 'center'
                    }}
                  >
                    <AppStack
                      style={{
                        backgroundColor: 'lightgrey',
                        borderRadius: 15,
                        height: 15,
                        width: 15
                      }}
                    />
                  </AppStack>
                )}

                {showPlayerView && evaluation ? (
                  <>
                    <ColoredAvatar
                      name={`${evaluation.person.firstName} ${evaluation.person.lastName}`}
                      style={{ padding: 0 }}
                      uniqueId={evaluation.person.email}
                    />
                    <AppText weight={500}>
                      {evaluation.person.firstName} {evaluation.person.lastName}
                    </AppText>
                  </>
                ) : (
                  <AppText weight={500}>{question.value}</AppText>
                )}
              </AppFlexbox>
              {questionAnswer?.value && (
                <ActionIcon color="dark" radius="xl" variant="subtle">
                  <Trash
                    onClick={() =>
                      setActionState(QUESTION_ACTIONS.removeAnswer)
                    }
                  />
                </ActionIcon>
              )}
            </AppFlexbox>

            <EvaluationTemplateQuestionInput
              evaluation={evaluation}
              isEditMode={isEditMode}
              isSmallScreen={isSmallScreen}
              onUpdateEvaluationAnswer={onUpdateEvaluationAnswer}
              question={question}
              questionAnswerValue={questionAnswer?.value}
            />

            <AppStack style={{ gap: 10, marginTop: 5 }}>
              <AppFlexbox>
                <AppText style={{ fontSize: 14 }} weight={500}>
                  Comments
                </AppText>
                {isEditMode ? (
                  <Button
                    onClick={() => onModalAction('QUESTION-SNIPPETS')}
                    size="compact-xs"
                    variant="outline"
                  >
                    Snippets{' '}
                    {`(${question.regScoutReportQuestionSnippets.length})`}
                  </Button>
                ) : (
                  question.regScoutReportQuestionSnippets.length > 0 && (
                    <MenuContent
                      control={
                        <Button size="compact-xs" variant="outline">
                          Snippets
                        </Button>
                      }
                      onChange={(open) =>
                        setActionState(open ? QUESTION_ACTIONS.snippets : null)
                      }
                      opened={actionState === QUESTION_ACTIONS.snippets}
                      position="bottom-start"
                      withinPortal
                    >
                      {question.regScoutReportQuestionSnippets
                        .sort((a, b) => a.sort - b.sort)
                        .map((s) => (
                          <Menu.Item
                            key={s.pkRegScoutReportQuestionSnippet || s.value}
                            onClick={(e) => {
                              e.preventDefault();
                              onUpdateEvaluationAnswer(
                                evaluation,
                                question,
                                questionAnswer?.value,
                                questionAnswer?.notes
                                  ? `${questionAnswer.notes} ${s.value} `
                                  : `${s.value} `
                              );
                            }}
                          >
                            {s.value}
                          </Menu.Item>
                        ))}
                    </MenuContent>
                  )
                )}
              </AppFlexbox>
              <Textarea
                autosize
                disabled={!onUpdateEvaluationAnswer}
                minRows={2}
                onChange={(e) => {
                  if (e.currentTarget.value || questionAnswer?.value) {
                    onUpdateEvaluationAnswer(
                      evaluation,
                      question,
                      questionAnswer?.value,
                      e.currentTarget.value
                    );
                  }
                }}
                value={questionAnswer?.notes || ''}
              />
            </AppStack>
            <ConfirmModal
              confirmActionColor="red"
              confirmActionText="Delete Score"
              isOpen={actionState === QUESTION_ACTIONS.removeAnswer}
              message="Are you sure you want to delete this score?"
              onCancel={() => setActionState(null)}
              onConfirm={() => {
                onUpdateEvaluationAnswer(evaluation, question, null);
                setActionState(null);
              }}
              title="Delete Score"
            />
          </>
        )}
      </AppStack>
    </AppCard>
  ) : (
    <AppCard id={id} radius="md" shadow="md" style={{ overflow: 'unset' }}>
      <AppStack style={{ gap: 15 }}>
        {isEditMode ? (
          <>
            <AppFlexbox>
              <AppFlexbox
                style={{
                  gap: 10,
                  alignItems: 'center',
                  flex: 1
                }}
              >
                <Skeleton height={36} width="100%" />
              </AppFlexbox>
              <AppFlexbox style={{ gap: 10, alignItems: 'center' }}>
                <Skeleton height={24} width={24} />
                <Skeleton height={24} width={24} />
              </AppFlexbox>
            </AppFlexbox>
            <AppFlexbox
              style={{ justifyContent: 'space-between', alignItems: 'end' }}
            >
              <AppStack style={{ gap: 10 }}>
                <Skeleton height={20} width={100} />
                <AppFlexbox style={{ gap: 0 }}>
                  <Skeleton height={36} radius={0} width={265} />
                </AppFlexbox>
              </AppStack>
            </AppFlexbox>
            <Divider label="PREVIEW" labelPosition="center" />
          </>
        ) : (
          <AppFlexbox
            style={{
              justifyContent: 'space-between',
              gap: 10,
              alignItems: 'center',
              minHeight: 30
            }}
          >
            <AppFlexbox style={{ gap: 10, alignItems: 'center' }}>
              <></>
            </AppFlexbox>
          </AppFlexbox>
        )}

        <EvaluationTemplateQuestionInput isSmallScreen={isSmallScreen} />

        <AppStack style={{ gap: 10, marginTop: 5 }}>
          <AppStack style={{ gap: 5 }}>
            <Skeleton height={16} width={100} />
            <Skeleton height={57} width="100%" />
          </AppStack>
        </AppStack>
      </AppStack>
    </AppCard>
  );
};

EvaluationTemplateQuestion.propTypes = {
  evaluation: PropTypes.object,
  evaluationSessionEntry: PropTypes.object,
  id: PropTypes.string,
  isEditMode: PropTypes.bool,
  isSmallScreen: PropTypes.bool,
  onChange: PropTypes.func,
  onCopy: PropTypes.func,
  onModalAction: PropTypes.func,
  onRemove: PropTypes.func,
  onRemoveRestriction: PropTypes.func,
  onUpdateEvaluationAnswer: PropTypes.func,
  question: PropTypes.object,
  showPlayerView: PropTypes.bool
};

export default EvaluationTemplateQuestion;
