import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Radio,
  Tabs,
  Textarea,
  TextInput
} from '@mantine/core';
import AssociationSidebar from './AssociationSidebar';
import { uploadFileToStorage } from '../../../helpers/awsHelper';
import TabsContent from '../../common/TabsContent';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import AppRadioGroup from '../../common/AppRadioGroup';

const EditAssociationSidebars = ({
  regAssociation,
  sidebars,
  onSidebarChange,
  primaryColor,
  secondaryColor,
  isLoading
}) => {
  const mqIndex = useMediaQueryIndex();
  const imageUploadRef = useRef(null);
  const [activeTab, setActiveTab] = useState('');

  const sidebarsSorted = sidebars.sort((a, b) => a.order - b.order);

  const handleSidebarChange = (updatedSidebar) => {
    const updatedSidebars = [
      ...sidebars.filter((f) => f.order !== updatedSidebar.order),
      { ...updatedSidebar }
    ];
    onSidebarChange(updatedSidebars, true);
  };

  useEffect(() => {
    if (!activeTab) {
      setActiveTab(sidebarsSorted[0]?.order.toString());
    }
  }, [sidebars]);

  return (
    <AppStack style={{ gap: 5, alignSelf: 'stretch' }}>
      <AppStack style={{ gap: 0 }}>
        {sidebars.length > 0 ? (
          <TabsContent
            activeTab={activeTab}
            onTabChange={setActiveTab}
            styles={{tabInner: { justifyContent: rs(['start', 'center'], mqIndex) }}}
            tabs={sidebarsSorted.map((s) => ({
              label: `Sidebar ${s.order}`,
              value: s.order.toString()
            }))}
          >
            {sidebarsSorted.map((s) => (
              <Tabs.Panel
                // eslint-disable-next-line react/no-array-index-key
                key={s.order}
                style={{ flex: rs([1, 'unset'], mqIndex) }}
                value={s.order.toString()}
              >
                <AppStack
                  style={{ justifyContent: 'space-between', padding: 16 }}
                >
                  <AppFlexbox
                    style={{
                      alignItems: 'start',
                      gap: rs([20, 20, 60], mqIndex),
                      flexDirection: rs(['column', 'column', 'row'], mqIndex),
                      flex: 1
                    }}
                  >
                    <AppStack style={{ flex: 1, alignSelf: 'stretch' }}>
                      <AppFlexbox
                        style={{
                          justifyContent: 'space-between',
                          alignItems: 'end'
                        }}
                      >
                        <AppFlexbox style={{ flex: 1 }}>
                          <AppRadioGroup
                            label="Display type"
                            onChange={(value) =>
                              handleSidebarChange({
                                ...s,
                                type: value
                              })
                            }
                            size="sm"
                            value={s.type}
                          >
                            <Radio
                              disabled={isLoading}
                              label="Text"
                              value="TEXT"
                            />
                            <Radio
                              disabled={isLoading}
                              label="Image"
                              value="IMAGE"
                            />
                          </AppRadioGroup>
                        </AppFlexbox>
                        <AppFlexbox>
                          <Checkbox
                            checked={!s.visible}
                            disabled={isLoading}
                            label="Hidden"
                            onChange={(e) =>
                              handleSidebarChange({
                                ...s,
                                visible: !e.currentTarget.checked
                              })
                            }
                          />
                        </AppFlexbox>
                      </AppFlexbox>
                      <TextInput
                        disabled={isLoading}
                        label="Title"
                        onChange={(e) =>
                          handleSidebarChange({
                            ...s,
                            title: e.currentTarget.value.substring(0, 50)
                          })
                        }
                        value={s.title ?? ''}
                      />

                      {s.type === 'IMAGE' && (
                        <AppFlexbox
                          style={{
                            gap: 0,
                            alignItems: 'end'
                          }}
                        >
                          <TextInput
                            disabled={isLoading}
                            label="Image"
                            onChange={(e) =>
                              handleSidebarChange({
                                ...s,
                                imageUrl: e.currentTarget.value
                              })
                            }
                            required={s.visible}
                            style={{ flex: 1 }}
                            value={s.imageUrl ?? ''}
                          />
                          <Button
                            color="black"
                            onClick={() => imageUploadRef.current.click()}
                          >
                            Upload
                          </Button>
                        </AppFlexbox>
                      )}

                      <AppStack>
                        <TextInput
                          disabled={isLoading}
                          label="Hyperlink"
                          onChange={(e) =>
                            handleSidebarChange({
                              ...s,
                              hyperlink: e.currentTarget.value
                            })
                          }
                          value={s.hyperlink ?? ''}
                        />
                      </AppStack>

                      <AppStack>
                        <Textarea
                          autosize
                          disabled={isLoading}
                          label="Message"
                          minRows={4}
                          onChange={(e) =>
                            handleSidebarChange({
                              ...s,
                              content: e.currentTarget.value.substring(0, 510)
                            })
                          }
                          required={s.type === 'TEXT' && s.visible}
                          value={s.content ?? ''}
                        />
                      </AppStack>
                    </AppStack>
                    <AppStack
                      style={{
                        flex: 1,
                        gap: 10,
                        alignSelf: 'stretch'
                      }}
                    >
                      <AppText style={{ fontSize: 14 }} weight={500}>
                        Preview
                      </AppText>
                      <AssociationSidebar
                        headerColor={
                          (s.order + 1) % 2 === 0
                            ? secondaryColor
                            : primaryColor
                        }
                        sidebar={s}
                      />
                    </AppStack>
                  </AppFlexbox>
                </AppStack>
              </Tabs.Panel>
            ))}
          </TabsContent>
        ) : (
          <AppStack style={{ padding: '50px 15px' }}>
            <AppText style={{ textAlign: 'center' }}>
              No sidebars created.
            </AppText>
          </AppStack>
        )}
      </AppStack>
      <input
        ref={imageUploadRef}
        accept="image/png, image/jpeg"
        data-testid="fileInput"
        hidden
        onChange={(e) =>
          uploadFileToStorage(
            `regAssociation-${regAssociation.pkRegAssociation}-sidebar`,
            e.target.files[0],
            (imageUrl) => {
              const sidebar = sidebars.find(
                (s) => s.order.toString() === activeTab
              );
              handleSidebarChange({
                ...sidebar,
                imageUrl
              });
            }
          )
        }
        type="file"
      />
    </AppStack>
  );
};

EditAssociationSidebars.propTypes = {
  isLoading: PropTypes.bool,
  onSidebarChange: PropTypes.func,
  primaryColor: PropTypes.string,
  regAssociation: PropTypes.object,
  secondaryColor: PropTypes.string,
  sidebars: PropTypes.array
};

export default EditAssociationSidebars;
