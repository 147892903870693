import { PropTypes } from 'prop-types';
import React, { useContext, useEffect, useRef } from 'react';
import { Checkbox, Skeleton } from '@mantine/core';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { REG_ASSOCIATION_SAVED_TEMPLATE_INPUT_TYPE } from '../../../config/constants';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const DEFAULT_FORM_CONTROL_SECTIONS = [
  {
    label: 'Account',
    controlType: REG_ASSOCIATION_SAVED_TEMPLATE_INPUT_TYPE[0].value,
    controls: [
      {
        label: 'First Name',
        name: 'FirstName'
      },
      {
        label: 'Last Name',
        name: 'LastName'
      },
      {
        label: 'Email',
        name: 'Email'
      },
      {
        label: 'Phone Number',
        name: 'Phone'
      }
    ]
  },
  {
    label: 'Submission Financials',
    controlType: REG_ASSOCIATION_SAVED_TEMPLATE_INPUT_TYPE[1].value,
    controls: [
      {
        label: 'Transaction Total',
        name: 'TransactionTotal'
      },
      {
        label: 'Remaining Balance',
        name: 'RemainingBalance'
      },
      {
        label: 'Amount Settled',
        name: 'AmountSettled'
      },
      {
        label: 'Platform Fee',
        name: 'PlatformFee'
      },
      {
        label: 'Amount Refunded',
        name: 'AmountRefunded'
      },
      {
        label: 'Net Total',
        name: 'NetTotal'
      },
      {
        label: 'Currency',
        name: 'Currency'
      },
      {
        label: 'Payment Type',
        name: 'PaymentType'
      },
      {
        label: 'Coupon',
        name: 'Coupon'
      },
      {
        label: 'Status',
        name: 'Status'
      }
    ]
  },
  {
    label: 'Financial Assistance',
    controlType: REG_ASSOCIATION_SAVED_TEMPLATE_INPUT_TYPE[1].value,
    controls: [
      {
        label: 'Include Financial Assistance',
        name: 'FinancialAssistance'
      }
    ]
  },
  {
    label: 'Preferred Roles',
    controlType: REG_ASSOCIATION_SAVED_TEMPLATE_INPUT_TYPE[1].value,
    controls: [
      {
        label: 'Include Roles',
        name: 'Role'
      }
    ]
  }
];

const ALLOWED_CONTROLS = [
  'TEXT_INPUT',
  'DROP_DOWN',
  'DATE',
  'RADIO_GROUP',
  'CHECKBOX',
  'FILE_UPLOAD',
  'SIGNATURE'
];

const SelectReportFormInputs = ({
  isLoading,
  regForm,
  disabled,
  selectedControls,
  onSelectControls,
  includeRemovedFormControls,
  setIncludeRemovedFormControls
}) => {
  const mqIndex = useMediaQueryIndex();
  const hasFetchedRegForm = useRef(false);
  const { state, fetchAdminRegFormVersionHistory } = useContext(
    RegistrationAdminContext
  );
  const formVariableType = REG_ASSOCIATION_SAVED_TEMPLATE_INPUT_TYPE[2].value;
  const { regForm: currentRegForm, regFormControls, removedRegFormControls } =
    state.regFormVersionHistory.value || {};

  const formControlSections = currentRegForm
    ? [...regFormControls, ...removedRegFormControls].reduce(
        (result, current) => {
          const existingSection = result.find(
            (s) => s.title.toLowerCase() === current.sectionTitle.toLowerCase()
          );
          if (existingSection) {
            const existingControl = existingSection.regFormControls.find(
              (c) => c.title.toLowerCase() === current.title.toLowerCase()
            );
            if (!existingControl) {
              existingSection.regFormControls.push({
                ...current,
                removed: !!removedRegFormControls.find(
                  (c) =>
                    c.sectionTitle === current.sectionTitle &&
                    c.title === current.title &&
                    c.type === current.type
                )
              });
            }
          }
          else {
            const isRemovedControl = !!removedRegFormControls.find(
              (c) => c.sectionTitle === current.sectionTitle
            );
            result.push({
              title: current.sectionTitle,
              regFormControls: [
                {
                  ...current,
                  removed: isRemovedControl
                }
              ],
              removed: isRemovedControl
            });
          }

          return result;
        },
        []
      )
    : [];

  const defaultFormControls = DEFAULT_FORM_CONTROL_SECTIONS.reduce(
    (r, c) => [
      ...r,
      ...c.controls.map((a) => ({ value: a.name, type: c.controlType }))
    ],
    []
  );
  const customFormControls = formControlSections
    .filter(
      (s) =>
        s.regFormControls.some((c) => ALLOWED_CONTROLS.includes(c.type)) > 0 &&
        (includeRemovedFormControls || !s.removed)
    )
    .reduce(
      (r, s) => [
        ...r,
        ...s.regFormControls
          .filter(
            (control) =>
              ALLOWED_CONTROLS.includes(control.type) &&
              (includeRemovedFormControls || !control.removed)
          )
          .map((control) => ({
            value: control.title,
            section: s.title,
            type: formVariableType,
            removed: s.removed || control.removed
          }))
      ],
      []
    );
  const allRegFormControls = [...defaultFormControls, ...customFormControls];

  const areAllControlsSelected = allRegFormControls.every((c) =>
    selectedControls.some(
      (s) => s.value === c.value && s.type === c.type && s.section === c.section
    )
  );

  useEffect(() => {
    if (regForm) {
      fetchAdminRegFormVersionHistory(regForm.uuid);
      hasFetchedRegForm.current = true;
    }
  }, [regForm]);

  return (
    <AppStack style={{ gap: 15 }}>
      {regForm && (
        <AppStack style={{ flex: 1 }}>
          {isLoading ||
          !hasFetchedRegForm.current ||
          state.regFormVersionHistory.loading ||
          currentRegForm?.uuid !== regForm?.uuid ? (
            <>
              <AppStack
                style={{
                  gap: 5,
                  flex: 1
                }}
              >
                <Skeleton height={12} width={100} />
                <AppFlexbox
                  style={{
                    gap: 20,
                    border: 'solid 1px lightgrey',
                    justifyContent: 'space-between',
                    padding: 10,
                    flexWrap: 'wrap',
                    flex: 1
                  }}
                >
                  {DEFAULT_FORM_CONTROL_SECTIONS[0].controls.map((a) => (
                    <AppStack
                      key={a.name}
                      style={{ flex: rs(['100%', '45%'], mqIndex) }}
                    >
                      <Skeleton height={12} width={150} />
                    </AppStack>
                  ))}
                </AppFlexbox>
              </AppStack>
              <AppStack
                style={{
                  gap: 5,
                  flex: 1
                }}
              >
                <Skeleton height={12} width={150} />
                <AppFlexbox
                  style={{
                    gap: 20,
                    border: 'solid 1px lightgrey',
                    justifyContent: 'space-between',
                    padding: 10,
                    flexWrap: 'wrap',
                    flex: 1
                  }}
                >
                  {[...Array(10)].map((a, index) => (
                    <AppStack
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      style={{ flex: rs(['100%', '45%'], mqIndex) }}
                    >
                      <Skeleton height={12} width={150} />
                    </AppStack>
                  ))}
                </AppFlexbox>
              </AppStack>
            </>
          ) : (
            currentRegForm && (
              <>
                {!disabled &&
                  removedRegFormControls.length > 0 &&
                  setIncludeRemovedFormControls && (
                    <Checkbox
                      checked={includeRemovedFormControls}
                      disabled={isLoading || disabled}
                      label="Include removed form controls"
                      onChange={(e) =>
                        setIncludeRemovedFormControls(e.currentTarget.checked)
                      }
                      style={{ fontWeight: 500, marginTop: 10 }}
                    />
                  )}
                {!disabled && (
                  <Checkbox
                    checked={areAllControlsSelected}
                    disabled={isLoading || disabled}
                    label={
                      areAllControlsSelected
                        ? 'Unselect all controls'
                        : 'Select all controls'
                    }
                    onChange={() =>
                      areAllControlsSelected
                        ? onSelectControls([])
                        : onSelectControls(allRegFormControls)
                    }
                    style={{ fontWeight: 500, marginTop: 10 }}
                  />
                )}
                {DEFAULT_FORM_CONTROL_SECTIONS.map((s) => {
                  const allSelected = s.controls.every((c) =>
                    selectedControls.some(
                      (i) =>
                        i.value === c.name &&
                        i.type === s.controlType &&
                        i.section === c.section
                    )
                  );

                  return (
                    <AppStack
                      key={s.label}
                      style={{
                        gap: 5,
                        flex: 1
                      }}
                    >
                      <AppFlexbox
                        style={{
                          justifyContent: 'space-between',
                          flex: 1,
                          alignItems: 'end'
                        }}
                      >
                        <AppText style={{ fontSize: 14 }} weight={500}>
                          {s.label}
                        </AppText>
                        {!disabled && (
                          <Checkbox
                            checked={allSelected}
                            disabled={isLoading || disabled}
                            label={allSelected ? 'Unselect all' : 'Select all'}
                            onChange={() => {
                              const filteredControls = [
                                ...selectedControls.filter(
                                  (i) =>
                                    !s.controls.some(
                                      (c) =>
                                        i.value === c.name &&
                                        i.type === s.controlType &&
                                        i.section === c.section
                                    )
                                )
                              ];
                              if (allSelected) {
                                onSelectControls(filteredControls);
                              }
                              else {
                                onSelectControls([
                                  ...filteredControls,
                                  ...s.controls.map((c) => ({
                                    value: c.name,
                                    type: s.controlType
                                  }))
                                ]);
                              }
                            }}
                            style={{ fontWeight: 500, marginTop: 10 }}
                          />
                        )}
                      </AppFlexbox>

                      <AppFlexbox
                        style={{
                          flexWrap: 'wrap',
                          gap: 20,
                          border: 'solid 1px lightgrey',
                          justifyContent: 'space-between',
                          padding: 10,
                          flex: 1
                        }}
                      >
                        {s.controls.map((a) => {
                          const isSelected = !!selectedControls.find(
                            (i) =>
                              i.value === a.name && i.type === s.controlType
                          );

                          return (
                            <AppStack
                              key={a.name}
                              style={{ flex: rs(['100%', '45%'], mqIndex) }}
                            >
                              <Checkbox
                                checked={isSelected}
                                disabled={isLoading || disabled}
                                label={a.label}
                                onChange={() =>
                                  onSelectControls(
                                    isSelected
                                      ? [
                                          ...selectedControls.filter(
                                            (c) => c.value !== a.name
                                          )
                                        ]
                                      : [
                                          ...selectedControls,
                                          { value: a.name, type: s.controlType }
                                        ]
                                  )
                                }
                                style={{ fontWeight: 500 }}
                              />
                            </AppStack>
                          );
                        })}
                      </AppFlexbox>
                    </AppStack>
                  );
                })}
                {formControlSections
                  .filter(
                    (s) =>
                      s.regFormControls.some((c) =>
                        ALLOWED_CONTROLS.includes(c.type)
                      ) > 0 &&
                      (includeRemovedFormControls || !s.removed)
                  )
                  .map((section) => {
                    const allSelected = section.regFormControls.every((c) =>
                      selectedControls.some(
                        (i) =>
                          i.value === c.title &&
                          i.type === formVariableType &&
                          i.section === section.title
                      )
                    );

                    return (
                      <AppStack
                        key={section.title}
                        style={{
                          gap: 5,
                          flex: 1
                        }}
                      >
                        <AppFlexbox
                          style={{
                            justifyContent: 'space-between',
                            flex: 1,
                            alignItems: 'end'
                          }}
                        >
                          <AppText style={{ fontSize: 14 }} weight={500}>
                            {section.title}
                          </AppText>
                          {!disabled && (
                            <Checkbox
                              checked={allSelected}
                              disabled={isLoading || disabled}
                              label={
                                allSelected ? 'Unselect all' : 'Select all'
                              }
                              onChange={() => {
                                const filteredControls = [
                                  ...selectedControls.filter(
                                    (i) =>
                                      !section.regFormControls.some(
                                        (c) =>
                                          i.value === c.title &&
                                          i.type === formVariableType &&
                                          i.section === section.title
                                      )
                                  )
                                ];
                                if (allSelected) {
                                  onSelectControls(filteredControls);
                                }
                                else {
                                  onSelectControls([
                                    ...filteredControls,
                                    ...section.regFormControls.map((c) => ({
                                      value: c.title,
                                      section: section.title,
                                      type: formVariableType,
                                      removed: section.removed || c.removed
                                    }))
                                  ]);
                                }
                              }}
                              style={{ fontWeight: 500, marginTop: 10 }}
                            />
                          )}
                        </AppFlexbox>

                        <AppFlexbox
                          style={{
                            flexWrap: 'wrap',
                            gap: 20,
                            border: 'solid 1px lightgrey',
                            justifyContent: 'space-between',
                            padding: 10,
                            flex: 1
                          }}
                        >
                          {section.regFormControls
                            .filter(
                              (c) =>
                                ALLOWED_CONTROLS.includes(c.type) &&
                                (includeRemovedFormControls || !c.removed)
                            )
                            .sort((a, b) => {
                              if (a.positionRow === b.positionRow) {
                                return !!b.removed - !!a.removed ||
                                  a.positionColumn > b.positionColumn
                                  ? 1
                                  : -1;
                              }
                              return !!b.removed - !!a.removed ||
                                a.positionRow > b.positionRow
                                ? 1
                                : -1;
                            })
                            .map((control) => {
                              const isSelected = !!selectedControls.find(
                                (i) =>
                                  i.value === control.title &&
                                  i.section === section.title &&
                                  i.type === formVariableType
                              );

                              return (
                                <AppStack
                                  key={`${control.title}-${control.type}`}
                                  style={{ flex: rs(['100%', '45%'], mqIndex) }}
                                >
                                  <Checkbox
                                    checked={isSelected}
                                    disabled={isLoading || disabled}
                                    label={control.title}
                                    onChange={() =>
                                      onSelectControls(
                                        isSelected
                                          ? [
                                              ...selectedControls.filter(
                                                (c) =>
                                                  c.value !== control.title &&
                                                  c.section !== section.title &&
                                                  c.type !== formVariableType
                                              )
                                            ]
                                          : [
                                              ...selectedControls,
                                              {
                                                value: control.title,
                                                section: section.title,
                                                type: formVariableType,
                                                removed:
                                                  section.removed ||
                                                  control.removed
                                              }
                                            ]
                                      )
                                    }
                                    style={{ fontWeight: 500 }}
                                  />
                                </AppStack>
                              );
                            })}
                        </AppFlexbox>
                      </AppStack>
                    );
                  })}
              </>
            )
          )}
        </AppStack>
      )}
    </AppStack>
  );
};

SelectReportFormInputs.propTypes = {
  disabled: PropTypes.bool,
  includeRemovedFormControls: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSelectControls: PropTypes.func,
  regForm: PropTypes.object,
  selectedControls: PropTypes.array,
  setIncludeRemovedFormControls: PropTypes.func
};

export default SelectReportFormInputs;
