import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Alert, Badge, Button, Divider, TextInput } from '@mantine/core';
import { ArrowsMove, InfoCircle, Plus, Trash } from 'tabler-icons-react';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppTitle from '../../common/AppTitle';
import FormSection from '../../common/FormSection';
import { triggerNotification } from '../../../helpers/notificationHelper';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import ActionableIcon from '../../common/ActionableIcon';
import {
  DEFUALT_EVALUATION_BUTTON_OPTIONS,
  REG_FORM_CONTROL_TYPE_ENUM,
  REG_SCOUT_REPORT_QUESTION_TYPE
} from '../../../config/constants';
import AppStack from '../../common/AppStack';
import DragDropList from '../../common/DragDropList';
import HelpBlurb from '../../common/Helpblurb';

const EvaluationTemplateQuestionOptionsModal = ({
  isOpen,
  onClose,
  onConfirm,
  fkRegScoutReportQuestionType,
  fkRegFormControlType,
  options
}) => {
  const hasInitialized = useRef(false);
  const [formState, setFormState] = useState({
    inputValue: '',
    options: []
  });
  const isOptionLimitReached =
    fkRegScoutReportQuestionType === REG_SCOUT_REPORT_QUESTION_TYPE.METRIC &&
    fkRegFormControlType === REG_FORM_CONTROL_TYPE_ENUM.BUTTON_GROUP &&
    formState.options.length >= 3;

  const isAttribute =
    fkRegScoutReportQuestionType === REG_SCOUT_REPORT_QUESTION_TYPE.ATTRIBUTE;

  useEffect(() => {
    if (isOpen) {
      setFormState({
        inputValue: '',
        options:
          options.length === 0 &&
          fkRegScoutReportQuestionType === REG_SCOUT_REPORT_QUESTION_TYPE.METRIC
            ? [...DEFUALT_EVALUATION_BUTTON_OPTIONS.map((p) => ({ ...p }))]
            : [...options.map((p) => ({ ...p }))]
      });
      hasInitialized.current = true;
    }
    else {
      hasInitialized.current = false;
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      onClose={onClose}
      opened={isOpen}
      title={<AppTitle order={2}>Question Options</AppTitle>}
      transitionProps={{
        duration: 0,
        transition: 'none'
      }}
      width={500}
    >
      <AppStack style={{ gap: 20, flex: 1 }}>
        {hasInitialized.current &&
          (isOptionLimitReached ? (
            <HelpBlurb title="Option Limit Reached">
              <AppText>
                You can only add up to 3 options for Button Groups to ensure
                usability on mobile. Please remove an option to add a new one or
                switch to a dropdown.
              </AppText>
            </HelpBlurb>
          ) : (
            <AppFlexbox
              component="form"
              onSubmit={(e) => {
                e.stopPropagation();
                e.preventDefault();

                const existingSnippet = formState.options.find(
                  (s) => s.label === formState.inputValue.trim()
                );
                if (existingSnippet) {
                  triggerNotification('Option already added.');
                }
                else {
                  setFormState({
                    ...formState,
                    options: [
                      ...formState.options,
                      {
                        key: new Date().getTime(),
                        value: formState.inputValue.trim(),
                        sort:
                          formState.options.length === 0
                            ? 1
                            : Math.max(
                                ...formState.options.map((i) => i.sort)
                              ) + 1
                      }
                    ],
                    inputValue: ''
                  });
                }
              }}
              style={{ gap: 5 }}
            >
              <TextInput
                disabled={isOptionLimitReached}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    inputValue: e.currentTarget.value
                  })
                }
                placeholder="Enter your option here"
                style={{ flex: 1 }}
                value={formState.inputValue}
              />
              <Button
                color="blue"
                disabled={!formState.inputValue || isOptionLimitReached}
                leftSection={<Plus />}
                type="submit"
              >
                Add Option
              </Button>
            </AppFlexbox>
          ))}

        <FormSection
          isSubmitDisabled={formState.options.length === 0}
          onCancel={onClose}
          onSubmit={() => {
            onConfirm(formState.options);
          }}
          submitTitle="Confirm"
        >
          <AppStack style={{ gap: 20, minHeight: 200 }}>
            {hasInitialized.current && (
              <>
                <Divider label="ORDER OPTIONS" labelPosition="center" />

                {formState.options.length === 0 ? (
                  <AppStack
                    style={{
                      flex: 1,
                      border: 'solid 1px lightgrey',
                      padding: '30px 10px',
                      justifyContent: 'center',
                      placeItems: 'center',
                      gap: 5
                    }}
                  >
                    <AppText weight={500}>No options added</AppText>
                  </AppStack>
                ) : (
                  <AppStack style={{ gap: 10 }}>
                    {!isAttribute && <Badge color="red">WORST</Badge>}
                    <DragDropList
                      items={formState.options}
                      onUpdate={(newListSort) =>
                        setFormState({
                          ...formState,
                          options: newListSort
                        })
                      }
                      renderOptions={({ item, index }) => (
                        <AppFlexbox
                          key={item.sort}
                          style={{
                            gap: 10,
                            padding: 10,
                            alignItems: 'center',
                            border: 'solid 1px lightgrey'
                          }}
                        >
                          <AppFlexbox
                            style={{
                              gap: 10,
                              alignItems: 'center',
                              flex: 1
                            }}
                          >
                            <ArrowsMove style={{ cursor: 'pointer' }} />
                            <AppText>
                              {index + 1} - {item.value}
                            </AppText>
                          </AppFlexbox>
                          <ActionableIcon
                            color="red"
                            onClick={() => {
                              setFormState({
                                ...formState,
                                options: formState.options.filter(
                                  (option) => option.sort !== item.sort
                                )
                              });
                            }}
                          >
                            <Trash />
                          </ActionableIcon>
                        </AppFlexbox>
                      )}
                    />
                    {!isAttribute && <Badge color="green">BEST</Badge>}
                  </AppStack>
                )}
              </>
            )}
          </AppStack>
        </FormSection>
      </AppStack>
    </ResponsiveModal>
  );
};

EvaluationTemplateQuestionOptionsModal.propTypes = {
  fkRegFormControlType: PropTypes.number,
  fkRegScoutReportQuestionType: PropTypes.number,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  options: PropTypes.array
};

export default EvaluationTemplateQuestionOptionsModal;
