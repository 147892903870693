import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../../helpers/styles';

const AssociationCustomButtom = ({
  primaryColor,
  primaryTextColor,
  secondaryColor,
  secondaryTextColor,
  children,
  disabled,
  disabledReason,
  ...rest
}) => {
  const mqIndex = useMediaQueryIndex();
  const { hovered, ref } = useHover();

  return (
    <Tooltip
      disabled={!disabled || !disabledReason}
      label={disabledReason}
      withArrow
    >
      <Button
        ref={ref}
        disabled={disabled}
        radius="xs"
        size="sm"
        variant="filled"
        {...rest}
        style={{
          color: disabled
            ? '#adb5bd'
            : hovered
            ? secondaryTextColor ?? '#FFF'
            : primaryTextColor ?? '#FFF',
          backgroundColor: disabled
            ? '#f1f3f5'
            : hovered
            ? secondaryColor ?? 'red'
            : primaryColor ?? 'red',
          border: 'solid 1px lightgrey',
          width: '100%',
          maxWidth: rs(['100%', '100%', '100%', 200], mqIndex),
          ...rest.style
        }}
      >
        {children}
      </Button>
    </Tooltip>
  );
};

AssociationCustomButtom.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  disabledReason: PropTypes.string,
  primaryColor: PropTypes.string,
  primaryTextColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  secondaryTextColor: PropTypes.string
};

export default AssociationCustomButtom;
