import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { List, Stack, TextInput } from '@mantine/core';
import AppStack from './AppStack';
import FormSection from './FormSection';
import ResponsiveModal from './ResponsiveModal';
import AppTitle from './AppTitle';
import AppText from './AppText';

const ConfirmModal = ({
  isLoading,
  isOpen,
  onConfirm,
  onCancel,
  message,
  title,
  confirmActionText,
  confirmActionColor,
  cancelActionText,
  children,
  warnings,
  warningListTitle,
  confirmationValue
}) => {
  const [formState, setFormState] = useState({ confirmation: '' });
  const confirmationRequired = confirmationValue;
  const confirmationText = confirmationValue || 'delete';

  useEffect(() => {
    setFormState({ confirmation: '' });
  }, []);

  return (
    <ResponsiveModal
      onClose={onCancel}
      opened={isOpen}
      size={600}
      title={<AppTitle order={2}>{title}</AppTitle>}
    >
      <FormSection
        cancelTitle={cancelActionText || 'Cancel'}
        isLoading={isLoading}
        isSubmitDisabled={
          confirmationRequired && formState.confirmation !== confirmationText
        }
        onCancel={onCancel}
        onSubmit={onConfirm}
        submitColor={confirmActionColor}
        submitTitle={confirmActionText || 'Confirm'}
      >
        <AppStack style={{ gap: 25 }}>
          {message && (
            <AppStack style={{ marginTop: 20 }}>
              {typeof message === 'string' ? (
                <AppText style={{ textAlign: 'center', fontWeight: 500 }}>
                  {message}
                </AppText>
              ) : (
                message
              )}
            </AppStack>
          )}

          {warnings && warnings.length > 0 && (
            <Stack style={{ gap: 5 }}>
              <AppText color="grey">{warningListTitle}</AppText>
              <List withPadding>
                {warnings.map((w) => (
                  <List.Item key={w}>
                    <AppText color="grey">{w}</AppText>
                  </List.Item>
                ))}
              </List>
            </Stack>
          )}

          {children}

          {confirmationRequired && (
            <AppStack style={{ gap: 5 }}>
              <AppText color="grey">
                Type '{confirmationText}' to confirm.
              </AppText>
              <TextInput
                disabled={isLoading}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    confirmation: e.target.value
                  })
                }
                placeholder={confirmationText}
                value={formState.confirmation}
              />
            </AppStack>
          )}
        </AppStack>
      </FormSection>
    </ResponsiveModal>
  );
};

ConfirmModal.propTypes = {
  cancelActionText: PropTypes.string,
  children: PropTypes.any,
  confirmActionColor: PropTypes.string,
  confirmActionText: PropTypes.string,
  confirmationValue: PropTypes.string,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  message: PropTypes.any,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  warningListTitle: PropTypes.string,
  warnings: PropTypes.array
};

export default ConfirmModal;
