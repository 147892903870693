import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { Context as AuthContext } from '../../../providers/AuthProvider';
import ReportTableView from './ReportTableView';

const StaffReportView = () => {
  const navigate = useNavigate();
  const { state: authState } = useContext(AuthContext);
  const { state } = useContext(RegistrationAdminContext);

  const {
    loading: isLoading,
    value: reportResult
  } = state.regStaffReportResult;

  useEffect(() => {
    if (!authState.userData.isAdmin || (!isLoading && !reportResult)) {
      navigate('/admin/reports');
    }
  }, [isLoading, authState.userData]);

  return (
    <ReportTableView
      isLoading={isLoading || !reportResult}
      reportResult={reportResult}
    />
  );
};

StaffReportView.propTypes = {};

export default StaffReportView;
