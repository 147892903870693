import React, { useState, useContext, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Anchor, TextInput } from '@mantine/core';
import { Context as AuthContext } from '../../../providers/AuthProvider';
import { PASSWORD_CONSTRAINTS } from '../../../config/constants';
import AuthCardForm from './AuthCardForm';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const RegisterView = ({ navigate }) => {
  const mqIndex = useMediaQueryIndex();
  const { state, reset, createAccount } = useContext(AuthContext);
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    error: ''
  });

  useEffect(() => {
    reset();

    setFormState({
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      error: ''
    });
  }, []);

  const handleInputChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value
    });
  };

  return (
    <AuthCardForm
      error={formState.error}
      isLoading={state.loading}
      links={[
        {
          key: 1,
          LinkComponent: (
            <AppText weight={700}>
              By clicking Join Now, you agree to our{' '}
              <Anchor
                href="https://www.sportsheadz.com/privacy-policy"
                style={{ fontWeight: 700, color: 'dodgerblue' }}
                target="_blank"
              >
                Privacy Policy
              </Anchor>
            </AppText>
          )
        }
      ]}
      navigate={navigate}
      onSubmit={() => {
        if (formState.password !== formState.confirmPassword) {
          setFormState({
            ...formState,
            error: 'Passwords must match.'
          });
        }
        else if (
          formState.confirmPassword.length < PASSWORD_CONSTRAINTS.length
        ) {
          setFormState({
            ...formState,
            error: `Password must be at least ${PASSWORD_CONSTRAINTS.length} characters long`
          });
        }
        else {
          setFormState({
            ...formState,
            error: ''
          });
          createAccount(formState);
        }
      }}
      submitSecondaryLink={{
        text: 'Already a member?',
        onClick: () => navigate('/auth/login')
      }}
      submitText="Join Now"
      title="Join Now"
    >
      <AppFlexbox
        style={{
          flex: 1,
          flexDirection: rs(['column', 'row'], mqIndex),
          flexWrap: 'nowrap'
        }}
      >
        <TextInput
          disabled={state.loading}
          label="First Name"
          name="firstName"
          onChange={handleInputChange}
          required
          size="md"
          style={{ width: '100%' }}
          value={formState.firstName}
        />
        <TextInput
          disabled={state.loading}
          label="Last Name"
          name="lastName"
          onChange={handleInputChange}
          required
          size="md"
          style={{ width: '100%' }}
          value={formState.lastName}
        />
      </AppFlexbox>

      <TextInput
        disabled={state.loading}
        label="Email"
        name="email"
        onChange={handleInputChange}
        required
        size="md"
        type="email"
        value={formState.email}
      />

      <TextInput
        autoComplete="on"
        disabled={state.loading}
        label="Password"
        name="password"
        onChange={handleInputChange}
        required
        size="md"
        type="password"
        value={formState.password}
      />

      <TextInput
        autoComplete="on"
        disabled={state.loading}
        label="Confirm Password"
        name="confirmPassword"
        onChange={handleInputChange}
        required
        size="md"
        type="password"
        value={formState.confirmPassword}
      />
    </AuthCardForm>
  );
};

RegisterView.propTypes = { navigate: PropTypes.func };

export default RegisterView;
