import { Divider, Grid, Transition } from '@mantine/core';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Draggable from 'react-draggable';
import headerBg from '../images/Header-BG.png';
import hero from '../images/Hero.png';
import logo from '../images/logo.png';
import pillarsImage from '../images/Pillars.png';
import halfoneDotsImage from '../images/halftone-dots.png';
import halfoneDotsRightImage from '../images/halftone-dots-right.png';
import findYourLeagueImage from '../images/find-your-league.png';
import findYourLeagueMobileImage from '../images/find-your-league-mobile.png';
import gradientRuleWhite from '../images/gradient-rule-white.png';
import gradientRuleLeftWhite from '../images/gradient-rule-left-white.png';
import claringtonBaseballLogo from '../images/clarington-baseball-logo.png';
import mountForestLogo from '../images/mount-forest-logo.png';
import mintoSoccerLogo from '../images/minto-soccer-logo.png';
import newtonTigersLogo from '../images/newton-tigers-logo.png';
import listowelSoccerLogo from '../images/listowel-soccer-logo.png';
import halfoneBgImage from '../images/Halftone-bg.png';
import { useMediaQueryIndex, useOnScreen } from '../helpers/hooks';
import AppFlexbox from '../components/common/AppFlexbox';
import AppImage from '../components/common/AppImage';
import classes from '../styles/nestedStyles.module.css';
import AppText from '../components/common/AppText';
import { getResponsiveStyle as rs } from '../helpers/styles';
import AppTitle from '../components/common/AppTitle';

const associations = [
  {
    pkRegAssociation: 18,
    label: 'Oakville Baseball',
    image:
      'https://baseballoakville.com/domains/baseballoakville.com/logo_A.png'
  },
  {
    pkRegAssociation: 19,
    label: 'Relm Sports',
    image: 'https://relmsports.com/public/images/common/relm-sports-logo.png'
  },
  {
    pkRegAssociation: 20,
    label: 'Grimsby Baseball',
    image:
      'https://hockeyheadz-storage.s3.amazonaws.com/public/regAssociation-201663697033.png'
  },
  {
    pkRegAssociation: 24,
    label: 'NHSC',
    image: 'https://nhsc.ca/domains/nhsc.ca/logo.png'
  },
  {
    pkRegAssociation: 21,
    label: 'Orangeville Baseball',
    image:
      'https://playbaseball.ca/domains/playbaseball.ca/logo.png?version=2022.08.08.12.00.00'
  },
  {
    pkRegAssociation: 25,
    label: 'Clarington Baseball',
    image: claringtonBaseballLogo
  },
  {
    pkRegAssociation: 23,
    label: 'UOVLL',
    image:
      'https://hockeyheadz-storage.s3.amazonaws.com/public/regAssociation-231664917770.png'
  },
  {
    pkRegAssociation: 27,
    label: 'Dundas Baseball',
    image:
      'https://hockeyheadz-storage.s3.amazonaws.com/public/regAssociation-271666024851.png'
  },
  {
    pkRegAssociation: 35,
    label: 'Alexander Park Minor Baseball',
    image:
      'https://hockeyheadz-storage.s3.amazonaws.com/public/regAssociation-351674674323.png'
  },
  {
    pkRegAssociation: 52,
    label: 'Ingersoll Minor Baseball',
    image:
      'https://hockeyheadz-storage.s3.amazonaws.com/public/regAssociation-521670507329.png'
  },
  {
    pkRegAssociation: 58,
    label: 'Kingston Area Minor Hockey Association',
    image:
      'https://hockeyheadz-storage.s3.amazonaws.com/public/regAssociation-581672951287.png'
  },
  {
    pkRegAssociation: 50,
    label: 'Listowel Minor Soccer',
    image: listowelSoccerLogo
  },
  {
    pkRegAssociation: 34,
    label: 'Midland-Penetang Minor Baseball',
    image:
      'https://hockeyheadz-storage.s3.amazonaws.com/public/regAssociation-341667416346.png'
  },
  {
    pkRegAssociation: 60,
    label: 'Minto United Soccer',
    image: mintoSoccerLogo
  },
  {
    pkRegAssociation: 33,
    label: 'Mount Forest Minor Baseball',
    image: mountForestLogo
  },
  {
    pkRegAssociation: 29,
    label: 'New Market Minor Ball Hockey Association',
    image:
      'https://hockeyheadz-storage.s3.amazonaws.com/public/regAssociation-291666367276.png'
  },
  {
    pkRegAssociation: 66,
    label: 'Newton Tigers Minor Soccer',
    image: newtonTigersLogo
  },
  {
    pkRegAssociation: 31,
    label: 'St Marys Minor Baseball Association',
    image:
      'https://hockeyheadz-storage.s3.amazonaws.com/public/regAssociation-311675792337.png'
  },
  {
    pkRegAssociation: 47,
    label: 'Wasaga Beach Minor Baseball Association',
    image:
      'https://hockeyheadz-storage.s3.amazonaws.com/public/regAssociation-471669137067.png'
  }
];

const Home = () => {
  const associationsListRef = useRef();
  const hasLoaded = useRef(false);
  const [showAssociations, setShowAssociations] = useState(false);
  const isAssociationsListVisable = useOnScreen(associationsListRef);
  const mqIndex = useMediaQueryIndex();

  useEffect(() => {
    if (isAssociationsListVisable && hasLoaded.current) {
      setShowAssociations(true);
    }
  }, [isAssociationsListVisable, hasLoaded.current]);

  if (mqIndex === 0) {
    setTimeout(() => {
      hasLoaded.current = true;
    }, 1500);
  }
  else {
    hasLoaded.current = true;
  }

  return (
    <AppFlexbox style={{ flex: 1, flexDirection: 'column', gap: 0 }}>
      <AppFlexbox
        style={{
          backgroundImage: `url(${headerBg})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: rs(
            ['100% 140px', '100% 205px', '100% 255px', '100% 400px'],
            mqIndex
          ),
          minHeight: rs([130, 190, 240, 380], mqIndex),
          position: 'relative',
          flexDirection: 'column',
          gap: 0
        }}
      >
        <AppFlexbox
          style={{
            flex: 1,
            top: rs(['-10px', '-20px'], mqIndex),
            left: 0,
            position: 'relative',
            backgroundImage: `url(${hero})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: rs(
              ['100% 140px', '100% 205px', '100% 255px', '100% 400px'],
              mqIndex
            ),
            height: rs([130, 190, 240, 380], mqIndex)
          }}
          visibleFrom="sm"
        >
          <AppFlexbox
            style={{
              flex: 1,
              flexDirection: 'column',
              position: 'absolute',
              top: rs([0, 0, '-10px', '-10px', '-25px'], mqIndex),
              left: rs(['10%', '15%'], mqIndex),
              width: rs([125, 175, 275, 350, 450], mqIndex),
              height: '100%',
              justifyContent: 'center'
            }}
          >
            <AppImage fit="contain" height="100%" src={logo} />
          </AppFlexbox>
        </AppFlexbox>
        <AppFlexbox
          hiddenFrom="sm"
          style={{
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <AppImage
            fit="contain"
            src={logo}
            style={{ maxHeight: rs([130, 190], mqIndex) }}
          />
        </AppFlexbox>
      </AppFlexbox>

      <AppFlexbox
        style={{
          backgroundColor: '#000',
          padding: rs([10, 25], mqIndex),
          placeItems: 'center'
        }}
      >
        <AppFlexbox
          style={{ flex: 1, maxHeight: 80, justifyContent: 'center' }}
        >
          <AppImage
            fit="contain"
            height="100%"
            src={pillarsImage}
            style={{ maxHeight: 80 }}
          />
        </AppFlexbox>
      </AppFlexbox>

      <AppFlexbox
        style={{
          flex: 1,
          paddingBottom: rs([0, 10, 15, 30], mqIndex),
          paddingTop: rs([0, 10, 15, 30], mqIndex),
          gap: rs([0, 10, 10, 20], mqIndex),
          backgroundColor: '#F2F2F2',
          flexWrap: 'nowrap',
          flexDirection: rs(['column', 'row'], mqIndex)
        }}
      >
        <AppFlexbox
          style={{
            flexDirection: 'column',
            padding: rs([15, 30], mqIndex),
            paddingBottom: rs([0, 30], mqIndex),
            flex: rs([1, 1, 2, 1], mqIndex),
            backgroundImage: `url(${halfoneDotsImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '75% 100%',
            minHeight: rs(['unset', 'unset', 240, 375], mqIndex),
            justifyContent: 'center'
          }}
        >
          <AppFlexbox
            style={{
              flex: 1,
              flexDirection: 'column',
              width: rs(
                ['100%', '100%', '100%', '100%', '75%', '50%'],
                mqIndex
              ),
              alignSelf: rs(
                ['center', 'center', 'center', 'flex-end'],
                mqIndex
              ),
              maxHeight: rs(['unset', 'unset', 'unset', 'unset', 300], mqIndex),
              justifyContent: 'space-between'
            }}
          >
            <Divider size={3} />
            <AppText
              style={{
                color: '#868686',
                fontSize: rs([16, 16, 20, 28], mqIndex),
                textAlign: 'center',
                whiteSpace: 'normal',
                overflow: 'normal'
              }}
            >
              SportsHeadz is making youth sports more accessible than ever. With
              our registration platform, you are now able to register for your
              local leagues & clubs faster than ever before. Time to throw your
              hat in the ring, and get involved today!
            </AppText>
            <Divider size={3} />
          </AppFlexbox>
        </AppFlexbox>
        <Divider
          orientation="vertical"
          size={3}
          style={{
            padding: rs(['10px 0px', '10px 0px', 'none'], mqIndex),
            display: rs(['none', 'block'], mqIndex)
          }}
        />
        <AppFlexbox
          style={{
            flexDirection: 'column',
            padding: rs([15, 30], mqIndex),
            flex: 1,
            backgroundImage: `url(${halfoneDotsRightImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%',
            height: rs(['unset', 'unset', 240, 375], mqIndex),
            justifyContent: 'center',
            alignSelf: 'center',
            gap: rs([0, 20], mqIndex)
          }}
        >
          <AppFlexbox>
            <AppImage
              fit="contain"
              height="100%"
              src={
                mqIndex === 0 ? findYourLeagueMobileImage : findYourLeagueImage
              }
              style={{
                maxHeight: rs([125, 160, 200, 300], mqIndex),
                maxWidth: '100%'
              }}
              width="unset"
            />
          </AppFlexbox>
        </AppFlexbox>
      </AppFlexbox>

      <AppFlexbox
        style={{
          flex: 1,
          backgroundColor: '#000',
          padding: 20,
          gap: rs([10, 20, 20, 40], mqIndex),
          flexDirection: rs(['column', 'column', 'row'], mqIndex)
        }}
      >
        <AppFlexbox
          style={{ flex: 1, flexDirection: 'column', justifyContent: 'center' }}
          visibleFrom="sm"
        >
          <AppImage fit="contain" src={gradientRuleLeftWhite} width="100%" />
        </AppFlexbox>
        <AppFlexbox>
          <AppTitle
            order={1}
            style={{
              textAlign: 'center',
              wordBreak: 'break-word',
              color: '#FFF',
              flex: 1
            }}
            weight={700}
          >
            MEET OUR ASSOCIATION PARTNERS
          </AppTitle>
        </AppFlexbox>
        <AppFlexbox
          style={{ flex: 1, lexDirection: 'column', justifyContent: 'center' }}
          visibleFrom="sm"
        >
          <AppImage fit="contain" src={gradientRuleWhite} width="100%" />
        </AppFlexbox>
      </AppFlexbox>

      <AppFlexbox
        ref={associationsListRef}
        style={{
          flex: 1,
          flexDirection: 'column',
          backgroundImage: `url(${halfoneBgImage})`,
          backgroundRepeat: 'repeat',
          justifyContent: 'start',
          alignSelf: 'stretch'
        }}
      >
        <AppFlexbox
          style={{
            flex: 1,
            maxWidth: '90%',
            alignSelf: 'center',
            justifyContent: 'center',
            gap: 20,
            paddingTop: 50,
            paddingBottom: 50,
            flexWrap: 'wrap',
            position: 'relative'
          }}
        >
          {associations.map((a, index) => {
            const size = rs([75, 100, 125], mqIndex);
            const margin = rs([25, 35, 50], mqIndex);

            return (
              <Transition
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                duration={500}
                mounted={showAssociations}
                timingFunction="ease"
                transition="fade"
              >
                {(styles) => (
                  <AppFlexbox
                    component={Link}
                    style={{
                      ...styles,
                      flex: 1,
                      transitionDelay: `${index * 0.1}s`,
                      flexDirection: 'column',
                      justifyContent: 'center',
                      maxWidth: size,
                      maxHeight: size,
                      marginLeft: margin,
                      marginRight: margin
                    }}
                    to={`/association/${a.pkRegAssociation}`}
                  >
                    <AppImage
                      className={classes['hover-clickable-image']}
                      fit="contain"
                      height={size}
                      src={a.image}
                      width={size}
                    />
                  </AppFlexbox>
                )}
              </Transition>
            );
          })}
        </AppFlexbox>
      </AppFlexbox>
    </AppFlexbox>
  );
};

export default Home;
