import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Skeleton } from '@mantine/core';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import HeaderView from '../../common/HeaderView';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import OrderDetailsInfo from './OrderDetailsInfo';
import AppTitle from '../../common/AppTitle';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import SendOrderReceiptModal from '../financials/SendOrderReceiptModal';

const OrderDetailsView = () => {
  const mqIndex = useMediaQueryIndex();
  const { pkRegCart } = useParams();
  const { state, fetchCartOrderHistory } = useContext(RegistrationContext);
  const hasFetched = useRef(false);
  const regCart = state.regCartOrderHistory.value;
  const isLoading =
    !hasFetched.current || state.regCartOrderHistory.loading || !regCart;
  const [receiptModalState, setReceiptModalState] = useState({ isOpen: false });

  useEffect(() => {
    if (pkRegCart) {
      fetchCartOrderHistory(pkRegCart);
      hasFetched.current = true;
    }
  }, [pkRegCart]);

  return (
    <HeaderView
      maxViewWidth={1100}
      viewProps={{ gap: rs([10, 10, 20], mqIndex) }}
    >
      {!isLoading ? (
        <>
          <AppFlexbox
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            <AppTitle
              style={{ display: rs(['none', 'none', 'block'], mqIndex) }}
            >
              Order Details
            </AppTitle>
            <Button
              onClick={() =>
                setReceiptModalState({
                  ...receiptModalState,
                  isOpen: true
                })
              }
              style={{
                flex: 1,
                marginTop: rs([10, 10, 0], mqIndex),
                maxWidth: rs(['unset', 'unset', 250], mqIndex)
              }}
            >
              Email Receipt
            </Button>
          </AppFlexbox>

          <OrderDetailsInfo regCart={regCart} />
        </>
      ) : (
        <>
          <AppStack style={{ display: rs(['none', 'none', 'block'], mqIndex) }}>
            <Skeleton height={44} width={250} />
          </AppStack>
          <OrderDetailsInfo isLoading />
        </>
      )}
      <SendOrderReceiptModal
        isOpen={receiptModalState.isOpen}
        onClose={() =>
          setReceiptModalState({
            ...receiptModalState,
            isOpen: false
          })
        }
        pkRegCarts={[regCart?.pkRegCart]}
      />
    </HeaderView>
  );
};

export default OrderDetailsView;
