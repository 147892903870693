import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import OrderHistory from '../components/content/orders/OrderHistory';
import OrderDetailsView from '../components/content/orders/OrderDetailsView';

const OrdersView = () => (
  <Routes>
    <Route element={<OrderHistory />} path="/" />
    <Route element={<OrderDetailsView />} path="/:pkRegCart" />
    <Route element={<Navigate replace to="/orders" />} path="*" />
  </Routes>
);

export default OrdersView;
