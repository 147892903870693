import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Button, Skeleton } from '@mantine/core';
import NumberFormat from 'react-number-format';
import { formatUtcDate } from '../../../helpers/format';
import RegistrationInfoModal from './RegistrationInfoModal';
import {
  CURRENCY_TYPE_LIST,
  REG_GENDER_TYPES
} from '../../../config/constants';
import AppStack from '../../common/AppStack';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import AssociationCustomButtom from './AssociationCustomButtom';

const daysOfPlay = {
  monday: 'Mon',
  tuesday: 'Tues',
  wednesday: 'Wed',
  thursday: 'Thurs',
  friday: 'Fri',
  saturday: 'Sat',
  sunday: 'Sun'
};

const RegistrationSignupListItem = ({
  regAssociationDivisionForm,
  primaryColor,
  primaryTextColor,
  secondaryColor,
  secondaryTextColor,
  isDisabled,
  onSelectRegForm,
  hideButton
}) => {
  const mqIndex = useMediaQueryIndex();
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const {
    regAssociationDivision: division,
    regForm: form
  } = regAssociationDivisionForm;
  const registrationsAvailableCount = division.registrantLimit
    ? division.registrantLimit -
      (division.regFormSubmissionCount + division.regWaitlistPersonCount)
    : 0;
  const isFull =
    (division.registrantLimit || division.registrantLimit === 0) &&
    registrationsAvailableCount <= 0;
  const actionButtonText = isFull
    ? division.waitlistEnabled
      ? 'Join Waitlist'
      : 'Registration Full'
    : 'Register Now';
  const endDateInfo =
    new Date(formatUtcDate(form.startDate)) > new Date()
      ? `Starts ${dayjs(formatUtcDate(form.startDate)).format('MMM D, YYYY')}`
      : `${isDisabled ? 'Ended' : 'Ends'} ${dayjs(
          formatUtcDate(form.endDate)
        ).format('MMM D, YYYY')}`;
  const currencyAbbr = CURRENCY_TYPE_LIST.find(
    (c) =>
      c.value.toString() ===
      regAssociationDivisionForm.regProduct.regAssociation?.regAssociationPaymentProvider?.fkCurrencyType?.toString()
  )?.abbr;

  const getDaysOfPlayString = () => {
    let daysOfPlayString = '';
    if (regAssociationDivisionForm.daysOfPlay.length === 0) {
      daysOfPlayString = 'Variable';
    }
    else {
      const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
      const weedends = ['saturday', 'sunday'];
      const daysOfWeekLowercase = regAssociationDivisionForm.daysOfPlay.map(
        (d) => d.toLowerCase()
      );
      const containsAllWeekdays = !weekdays.some(
        (d) => !daysOfWeekLowercase.includes(d)
      );
      const containsAllWeekends = !weedends.some(
        (d) => !daysOfWeekLowercase.includes(d)
      );

      if (containsAllWeekdays && containsAllWeekends) {
        daysOfPlayString = 'Variable';
      }
      else if (containsAllWeekdays) {
        daysOfPlayString = 'Weekdays';
      }
      else if (containsAllWeekends) {
        daysOfPlayString = 'Weekends';
      }
      else {
        daysOfPlayString = daysOfWeekLowercase
          .map((d) => daysOfPlay[d])
          .join(' / ');
      }
    }

    return daysOfPlayString;
  };

  return regAssociationDivisionForm ? (
    <AppStack
      style={{
        flex: 1,
        justifyContent: 'space-between',
        gap: rs([10, 10, 10, 5], mqIndex),
        flexDirection: rs(['column', 'column', 'column', 'row'], mqIndex),
        alignItems: 'center'
      }}
    >
      <AppFlexbox
        style={{
          gap: 5,
          flex: 3,
          alignSelf: 'stretch',
          alignItems: rs(['start', 'start', 'start', 'center'], mqIndex),
          flexDirection: rs(['column', 'row'], mqIndex)
        }}
      >
        <AppStack style={{ gap: 0, flex: 3 }}>
          <AppText weight={500}>
            {regAssociationDivisionForm.titleOverride
              ? regAssociationDivisionForm.titleOverride
              : form.name}
          </AppText>
          <AppFlexbox style={{ flex: 1, gap: 5 }}>
            <AppText style={{ fontSize: 14 }}>{division.name}</AppText>
          </AppFlexbox>
          <AppText style={{ fontSize: 14 }}>
            {
              REG_GENDER_TYPES.find(
                (t) => t.value === division.fkRegGenderType.toString()
              )?.label
            }{' '}
            {division.isAllAges
              ? '(All Ages)'
              : `(${dayjs(new Date()).diff(
                  division.minDob,
                  'year'
                )} years old and younger)`}
          </AppText>
          <AppFlexbox
            style={{ flex: 1, gap: 5, minHeight: 25, alignItems: 'center' }}
          >
            <AppText style={{ fontSize: 14 }}>Days of Play:</AppText>
            <AppText style={{ fontSize: 14 }}>{getDaysOfPlayString()}</AppText>
          </AppFlexbox>
        </AppStack>
        <AppStack
          style={{
            gap: 0,
            flex: 2,
            display: 'block',
            flexDirection: rs(['row', 'column'], mqIndex)
          }}
        >
          <AppStack
            style={{ flex: 1, marginLeft: rs([0, 15], mqIndex), gap: 0 }}
          >
            <AppText weight={500}>{endDateInfo}</AppText>
            <AppText style={{ fontSize: 14 }}>
              <NumberFormat
                decimalScale={2}
                displayType="text"
                fixedDecimalScale
                prefix="$"
                thousandSeparator
                value={regAssociationDivisionForm.regProduct.price}
              />{' '}
              {currencyAbbr}
            </AppText>

            <AppFlexbox style={{ flex: 1, gap: 5 }}>
              <AppText style={{ fontSize: 14 }}>Slots: </AppText>
              <AppText style={{ fontSize: 14 }}>
                {!onSelectRegForm
                  ? 'Closed'
                  : division.registrantLimit == null
                  ? 'Unlimited'
                  : isFull
                  ? 'Full'
                  : `${registrationsAvailableCount} remaining`}
              </AppText>
            </AppFlexbox>
          </AppStack>
          <Button
            color="dodgerblue"
            onClick={() => setIsInfoModalOpen(true)}
            radius="xl"
            size="xs"
            style={{
              height: 25,
              padding: rs(['0', '0px 14px'], mqIndex),
              marginTop: rs([5, 0], mqIndex)
            }}
            variant="subtle"
          >
            More Info
          </Button>
        </AppStack>
      </AppFlexbox>

      {!hideButton && onSelectRegForm ? (
        <AssociationCustomButtom
          disabled={isDisabled || (isFull && !division.waitlistEnabled)}
          disabledReason={isFull ? 'Registration has no slots available' : null}
          onClick={() => onSelectRegForm(regAssociationDivisionForm)}
          primaryColor={primaryColor}
          primaryTextColor={primaryTextColor}
          secondaryColor={secondaryColor}
          secondaryTextColor={secondaryTextColor}
        >
          {actionButtonText}
        </AssociationCustomButtom>
      ) : (
        !hideButton && (
          <AppStack
            style={{
              width: 200,
              display: rs(['none', 'none', 'none', 'flex'], mqIndex)
            }}
          />
        )
      )}
      <RegistrationInfoModal
        daysOfPlayString={getDaysOfPlayString()}
        isDisabled={isDisabled}
        isOpen={isInfoModalOpen}
        onClose={() => setIsInfoModalOpen(false)}
        onSelectRegForm={onSelectRegForm}
        primaryColor={primaryColor}
        primaryTextColor={primaryTextColor}
        regAssociationDivisionForm={regAssociationDivisionForm}
        registrationsAvailableCount={registrationsAvailableCount}
        secondaryColor={secondaryColor}
        secondaryTextColor={secondaryTextColor}
        selectRegFormButtonText={actionButtonText}
      />
    </AppStack>
  ) : (
    <AppFlexbox style={{ flex: 1, justifyContent: 'space-between', gap: 5 }}>
      <AppStack
        style={{ gap: 5, flex: 3, whiteSpace: rs(['wrap', 'nowrap'], mqIndex) }}
      >
        <Skeleton height={10} width="35%" />
        <Skeleton height={8} width="15%" />
      </AppStack>
      <Skeleton height={35} style={{ flex: 1 }} />
    </AppFlexbox>
  );
};

RegistrationSignupListItem.propTypes = {
  hideButton: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onSelectRegForm: PropTypes.func,
  primaryColor: PropTypes.string,
  primaryTextColor: PropTypes.string,
  regAssociationDivisionForm: PropTypes.object,
  secondaryColor: PropTypes.string,
  secondaryTextColor: PropTypes.string
};

export default RegistrationSignupListItem;
