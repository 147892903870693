import { Button, Checkbox, Radio, Select, Tooltip } from '@mantine/core';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { DatePickerInput } from '@mantine/dates';
import {
  Calendar,
  FileDownload,
  FileUpload,
  Signature
} from 'tabler-icons-react';
import NumberFormat from 'react-number-format';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
import { currencyFormat, formatUtcDate } from '../../../helpers/format';
import { downloadUrlFile } from '../../../helpers/awsHelper';
import SignatureControlModal from './SignatureControlModal';
import FileControlModal from './FileControlModal';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import AppRadioGroup from '../../common/AppRadioGroup';

const FormControlPreview = ({ formState }) => {
  const [previewValue, setPreviewValue] = useState(null);
  const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false);
  const [isFileModalOpen, setIsFileModalOpen] = useState(false);

  useEffect(() => {
    setPreviewValue(null);
  }, []);

  return (
    <AppStack
      style={{
        flex: 1,
        gap: 20,
        alignSelf: 'stretch'
      }}
    >
      <AppText weight={500}>Preview</AppText>
      <Tooltip
        disabled={!formState.tooltip}
        label={formState.tooltip}
        multiline
        style={{ maxWidth: 250 }}
        withArrow
        withinPortal
      >
        <AppStack>
          {formState.type === 'TEXT' ? (
            <AppText
              style={{
                margin: 0,
                whiteSpace: 'pre-wrap',
                textAlign: formState.constraints.textAlign,
                fontStyle:
                  formState.constraints.italicize === 'true'
                    ? 'italic'
                    : 'none',
                textDecoration:
                  formState.constraints.underline === 'true'
                    ? 'underline'
                    : 'none',
                fontSize: formState.constraints.fontSize
              }}
              weight={formState.constraints.bold === 'true' ? 700 : 'normal'}
            >
              {formState.title}
            </AppText>
          ) : formState.type === 'DATE' ? (
            <DatePickerInput
              clearable
              label={formState.title}
              leftSection={<Calendar color="#000" size={16} />}
              maxDate={
                formState.constraints.maxDate &&
                new Date(formatUtcDate(formState.constraints.maxDate))
              }
              minDate={
                formState.constraints.minDate &&
                new Date(formatUtcDate(formState.constraints.minDate))
              }
              onChange={setPreviewValue}
              style={{
                flex: 1,
                width: '100%',
                maxWidth: 250,
                margin: '0px auto'
              }}
              styles={{
                input: { cursor: 'pointer !important' },
                label: { cursor: 'pointer' }
              }}
              value={previewValue ?? null}
              withAsterisk={formState.constraints.required === 'true'}
            />
          ) : formState.type === 'FILE_UPLOAD' ? (
            <AppStack
              style={{
                maxHeight: 80,
                flex: 1,
                gap: 4,
                justifyContent: 'center',
                overflow: 'hidden'
              }}
            >
              <AppStack style={{ justifyContent: 'flex-end', flex: 1 }}>
                <AppFlexbox style={{ gap: 5 }}>
                  <AppText
                    style={{
                      fontSize: 14,
                      whiteSpace: 'nowrap'
                    }}
                    weight={500}
                  >
                    {formState.title}
                  </AppText>
                  {formState.constraints.required === 'true' && (
                    <AppText style={{ color: '#f03e3e' }} weight={500}>
                      *
                    </AppText>
                  )}
                </AppFlexbox>
              </AppStack>
              <Button
                color="dark"
                disabled={formState.uploading}
                leftSection={<FileUpload />}
                onClick={() => setIsFileModalOpen(true)}
                style={{ flex: 1, minHeight: 36 }}
              >
                {previewValue ? 'Update File' : 'Upload File'}
              </Button>
              <FileControlModal
                file={previewValue}
                isOpen={isFileModalOpen}
                onClose={() => setIsFileModalOpen(false)}
                onConfirm={(file) => {
                  setPreviewValue(file);
                  setIsFileModalOpen(false);
                }}
              />
            </AppStack>
          ) : formState.type === 'FILE_DOWNLOAD' ? (
            <AppStack
              style={{
                maxHeight: 80,
                flex: 1,
                gap: 4,
                justifyContent: 'center',
                overflow: 'hidden'
              }}
            >
              <AppStack style={{ justifyContent: 'flex-end', flex: 1 }}>
                <AppText
                  style={{
                    fontSize: 14,
                    whiteSpace: 'nowrap',
                    flex: 1
                  }}
                  weight={500}
                >
                  {formState.title}
                </AppText>
              </AppStack>
              {formState.documents[0] ? (
                <Button
                  color="dark"
                  disabled={formState.uploading}
                  leftSection={<FileDownload />}
                  onClick={() =>
                    downloadUrlFile(
                      formState.documents[0].url,
                      formState.documents[0].fileName
                    )
                  }
                  style={{ flex: 1, minHeight: 36 }}
                >
                  Download
                </Button>
              ) : (
                <Button
                  color="dark"
                  disabled
                  leftSection={<FileDownload />}
                  style={{ flex: 1, minHeight: 36 }}
                >
                  Download
                </Button>
              )}
            </AppStack>
          ) : formState.type === 'RADIO_GROUP' ? (
            <AppRadioGroup
              label={formState.title}
              onChange={setPreviewValue}
              orientation="vertical"
              style={{ margin: '0px auto' }}
              styles={{
                input: { cursor: 'pointer !important' },
                label: { cursor: 'pointer' }
              }}
              value={previewValue ?? ''}
            >
              {formState.options
                .sort((c) => c.positionOrder)
                .filter(
                  (c, i, a) =>
                    c.option &&
                    a.findIndex((c2) => c2.option === c.option) === i
                )
                .map((option) => (
                  <Radio
                    key={option.pkRegFormControlOption}
                    label={
                      formState.constraints.isProduct === 'true' ? (
                        <AppText>
                          {option.option} -{' '}
                          {option.product?.price &&
                          option.product?.price < 0 ? (
                            <>
                              (
                              <NumberFormat
                                decimalScale={2}
                                displayType="text"
                                fixedDecimalScale
                                prefix="$"
                                thousandSeparator
                                value={option.product?.price}
                              />
                              )
                            </>
                          ) : (
                            <NumberFormat
                              decimalScale={2}
                              displayType="text"
                              fixedDecimalScale
                              prefix="$"
                              thousandSeparator
                              value={option.product?.price}
                            />
                          )}
                        </AppText>
                      ) : (
                        option.option
                      )
                    }
                    value={option.option}
                  />
                ))}
            </AppRadioGroup>
          ) : formState.type === 'DROP_DOWN' ? (
            <Select
              clearable
              data={formState.options
                .filter(
                  (c, i, a) =>
                    c.option &&
                    a.findIndex((c2) => c2.option === c.option) === i
                )
                .map((option) => ({
                  value: option.option,
                  label:
                    formState.constraints.isProduct === 'true'
                      ? `${option.option} - ${
                          option.product?.price < 0
                            ? `(${currencyFormat(option.product?.price)})`
                            : currencyFormat(option.product?.price)
                        }`
                      : option.option
                }))}
              label={formState.title}
              onChange={setPreviewValue}
              searchable
              style={{ margin: '0px auto' }}
              styles={{
                input: { cursor: 'pointer !important' },
                label: { cursor: 'pointer' }
              }}
              value={previewValue}
              withAsterisk={formState.constraints.required === 'true'}
            />
          ) : formState.type === 'CHECKBOX' ? (
            <Checkbox
              checked={previewValue === true}
              label={
                <AppFlexbox style={{ gap: 5 }}>
                  <AppText>{formState.title}</AppText>
                  {formState.constraints.isProduct === 'true' && (
                    <AppText>
                      -{' '}
                      {formState.product?.price < 0 ? (
                        <>
                          (
                          <NumberFormat
                            decimalScale={2}
                            displayType="text"
                            fixedDecimalScale
                            prefix="$"
                            thousandSeparator
                            value={formState.product?.price}
                          />
                          )
                        </>
                      ) : (
                        <NumberFormat
                          decimalScale={2}
                          displayType="text"
                          fixedDecimalScale
                          prefix="$"
                          thousandSeparator
                          value={formState.product?.price}
                        />
                      )}
                    </AppText>
                  )}
                  {formState.constraints.required === 'true' && (
                    <AppText style={{ color: '#f03e3e' }} weight={500}>
                      *
                    </AppText>
                  )}
                </AppFlexbox>
              }
              onChange={(e) => setPreviewValue(e.currentTarget.checked)}
              styles={{ body: { alignItems: 'center' } }}
            />
          ) : formState.type === 'SIGNATURE' ? (
            <AppStack
              style={{
                maxHeight: 80,
                flex: 1,
                gap: 4,
                justifyContent: 'center',
                overflow: 'hidden'
              }}
            >
              <AppStack style={{ justifyContent: 'flex-end', flex: 1 }}>
                <AppFlexbox style={{ gap: 5 }}>
                  <AppText
                    style={{
                      fontSize: 14,
                      whiteSpace: 'nowrap'
                    }}
                    weight={500}
                  >
                    {formState.title}
                  </AppText>
                  {formState.constraints.required === 'true' && (
                    <AppText style={{ color: '#f03e3e' }} weight={500}>
                      *
                    </AppText>
                  )}
                </AppFlexbox>
              </AppStack>
              <Button
                color="dark"
                disabled={formState.uploading}
                leftSection={<Signature />}
                onClick={() => setIsSignatureModalOpen(true)}
                style={{ flex: 1, minHeight: 36 }}
              >
                {previewValue ? 'Update Signature' : 'Enter Signature'}
              </Button>
              <SignatureControlModal
                base64String={previewValue}
                isOpen={isSignatureModalOpen}
                onClose={() => setIsSignatureModalOpen(false)}
                onConfirm={(base64String) => {
                  setPreviewValue(base64String);
                  setIsSignatureModalOpen(false);
                }}
              />
            </AppStack>
          ) : (
            <formState.inputComponent
              label={formState.title}
              onChange={(e) => {
                let textValue = e.currentTarget.value;
                if (formState.type === 'TEXT_INPUT') {
                  if (formState.constraints.allowNumbers === 'false') {
                    textValue = textValue.replace(/[^a-z]/gi, '');
                  }
                  if (formState.constraints.allowLetters === 'false') {
                    textValue = textValue.replace(/\D/g, '');
                  }
                }
                setPreviewValue(textValue);
              }}
              style={{
                flex: 1,
                width: '100%',
                maxWidth: 250,
                margin: '0px auto'
              }}
              styles={{
                input: { cursor: 'pointer !important' },
                label: { cursor: 'pointer' }
              }}
              type={
                formState.constraints.allowNumbers &&
                !formState.constraints.allowLetters
                  ? 'number'
                  : 'text'
              }
              value={previewValue ?? ''}
            />
          )}
        </AppStack>
      </Tooltip>
    </AppStack>
  );
};

FormControlPreview.propTypes = { formState: PropTypes.object };

export default FormControlPreview;
