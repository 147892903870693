import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Ticket } from 'tabler-icons-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { useModalState, useSortByFilter } from '../../../helpers/hooks';
import AppCard from '../../common/AppCard';
import TableView from '../../common/TableView';
import CreateEvaluationTemplateModal from './CreateEvaluationTemplateModal';

const VIEW_ACTIONS_ENUM = {
  create: 'CREATE',
  edit: 'EDIT',
  copy: 'COPY',
  delete: 'DELETE'
};

const VIEW_ACTIONS = [
  {
    label: 'Edit',
    value: VIEW_ACTIONS_ENUM.edit
  },
  {
    label: 'Copy',
    value: VIEW_ACTIONS_ENUM.copy
  },
  {
    label: 'Delete',
    value: VIEW_ACTIONS_ENUM.delete
  }
];

const TABLE_COLUMNS = [
  {
    label: 'Name',
    value: 'name',
    sortable: true
  },
  {
    label: 'Questions',
    value: 'questions',
    sortable: true
  }
];

const EvaluationTemplateListView = ({
  isLoading,
  regAssociation,
  modalAction
}) => {
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const { state, fetchScoutReportEvaluations } = useContext(
    RegistrationAdminContext
  );
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const [filterState, setFilterState] = useState({ search: '' });
  const {
    sortValue,
    isDescendingSort,
    onChangeSortBy,
    customFilterData
  } = useSortByFilter(TABLE_COLUMNS);

  useEffect(() => {
    if (
      (locationState?.openModal && !isLoading) ||
      modalAction === VIEW_ACTIONS_ENUM.create
    ) {
      onOpenModal(VIEW_ACTIONS_ENUM.create);
    }
  }, [locationState?.openModal, isLoading, modalAction]);

  return (
    <AppCard style={{ flex: 1, padding: 0, height: '100%' }}>
      <TableView
        columns={TABLE_COLUMNS}
        emptyMessage="No Templates Available"
        filters={[
          {
            key: 1,
            label: 'Template Name',
            type: 'text',
            value: filterState.search,
            placeholder: 'Filter by template',
            onChange: (value) => {
              setFilterState((c) => ({
                ...c,
                search: value
              }));
            }
          }
        ]}
        isDescendingSort={isDescendingSort}
        isLoading={isLoading}
        lastUpdated={state.regScoutReports.lastUpdated}
        onAction={(action, item) => {
          if (action === VIEW_ACTIONS_ENUM.edit) {
            navigate(`/admin/evaluations/templates/${item.key}`);
          }
          else {
            onOpenModal(
              action,
              state.regScoutReports.value.find(
                (s) => s.pkRegScoutReport === item.key
              )
            );
          }
        }}
        onChangeSortBy={onChangeSortBy}
        onRefresh={() =>
          fetchScoutReportEvaluations(regAssociation.pkRegAssociation)
        }
        rows={state.regScoutReports.value
          .filter(
            (r) =>
              !filterState.search ||
              r.name.toLowerCase().includes(filterState.search.toLowerCase())
          )
          .map((c) => ({
            key: c.pkRegScoutReport,
            actions: VIEW_ACTIONS,
            columns: [
              {
                key: 1,
                label: c.name,
                weight: 500,
                icon: <Ticket color="black" size="30" />,
                onClick: () =>
                  navigate(`/admin/evaluations/templates/${c.pkRegScoutReport}`)
              },
              {
                key: 2,
                label: c.regScoutReportQuestions.length,
                sortValueType: 'number'
              }
            ]
          }))}
        sortBy={sortValue}
        sortFilter={customFilterData}
        tableTitle="Templates"
      />

      <CreateEvaluationTemplateModal
        isOpen={modalState.isOpen}
        onClose={onCloseModal}
        pkRegAssociation={regAssociation?.pkRegAssociation}
        regScoutReport={modalState.item}
        showDelete={modalState.action === VIEW_ACTIONS_ENUM.delete}
      />
    </AppCard>
  );
};

EvaluationTemplateListView.propTypes = {
  isLoading: PropTypes.bool,
  modalAction: PropTypes.string,
  regAssociation: PropTypes.object
};

export default EvaluationTemplateListView;
