import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mantine/core';
import { CreditCard } from 'tabler-icons-react';
import { Context as RegistrationAdminDashboardContext } from '../../../providers/RegistrationAdminDashboardProvider';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { Context as AuthContext } from '../../../providers/AuthProvider';
import AppCard from '../../common/AppCard';
import TableView from '../../common/TableView';
import { useModalState, useSortByFilter } from '../../../helpers/hooks';
import {
  CURRENCY_TYPE_LIST,
  PAYMENT_PROVIDER_LIST
} from '../../../config/constants';
import FinancialPayeeOnboardingModal from './FinancialPayeeOnboardingModal';
import ChangeCurrencyModal from '../settings/ChangeCurrencyModal';
import { triggerNotification } from '../../../helpers/notificationHelper';
import ConfirmModal from '../../common/ConfirmModal';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';

const VIEW_ACTIONS_ENUM = {
  onboard: 'ONBOARD',
  changeCurrency: 'CHANGE_CURRENCY',
  REMOVE_PAYEE: 'REMOVE_PAYEE'
};

const VIEW_ACTIONS = [
  {
    label: 'Update',
    value: VIEW_ACTIONS_ENUM.onboard
  },
  {
    label: 'Change Currency',
    value: VIEW_ACTIONS_ENUM.changeCurrency
  },
  {
    label: 'Remove Payee',
    value: VIEW_ACTIONS_ENUM.REMOVE_PAYEE,
    adminOnly: true
  }
];

const TABLE_COLUMNS = [
  {
    label: 'Name',
    value: 'name',
    sortable: true
  },
  {
    label: 'Payment Provider',
    value: 'paymentProvider',
    sortable: true
  },
  {
    label: 'Currency',
    value: 'currency',
    sortable: true
  },
  {
    label: 'Status',
    value: 'status',
    sortable: true
  }
];

const FinancialPayees = ({ regAssociation }) => {
  const { state: authState } = useContext(AuthContext);
  const { changeAssociation } = useContext(RegistrationAdminDashboardContext);
  const {
    state,
    fetchAdminRegAssociations,
    updateRegAssociation,
    disconnectPaymentProvider
  } = useContext(RegistrationAdminContext);
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const [modalLoading, setModalLoading] = useState(false);
  const {
    sortValue,
    isDescendingSort,
    onChangeSortBy,
    customFilterData
  } = useSortByFilter(TABLE_COLUMNS);
  const isLoading = !regAssociation || state.regAssociations.loading;

  return (
    <AppCard style={{ flex: 1, padding: 0, height: '100%' }}>
      <TableView
        columns={TABLE_COLUMNS}
        emptyActionButton={
          regAssociation && (
            <Button
              onClick={() => {
                onOpenModal(VIEW_ACTIONS_ENUM.onboard, regAssociation);
              }}
            >
              Onboard Payee
            </Button>
          )
        }
        emptyMessage="No Payee Setup"
        isDescendingSort={isDescendingSort}
        isLoading={isLoading}
        lastUpdated={state.regAssociations.lastUpdated}
        onAction={(action, item) => {
          onOpenModal(
            action,
            state.regAssociations.value.find(
              (s) => s.pkRegAssociation === item.key
            )
          );
        }}
        onChangeSortBy={onChangeSortBy}
        onRefresh={() => fetchAdminRegAssociations()}
        rows={state.regAssociations.value
          .filter(
            (s) =>
              s.pkRegAssociation === regAssociation?.pkRegAssociation &&
              !!s.regAssociationPaymentProvider
          )
          .map((a) => {
            const statusInfo = !a.regAssociationPaymentProvider
              ? {
                  color: 'red',
                  label: 'Disabled'
                }
              : !a.regAssociationPaymentProvider.completed
              ? {
                  color: 'blue',
                  label: 'Info Missing'
                }
              : {
                  color: 'green',
                  label: ' Active'
                };

            return {
              key: a.pkRegAssociation,
              actions: VIEW_ACTIONS.filter(
                (f) => !f.adminOnly || authState.userData?.isAdmin
              ),
              columns: [
                {
                  key: 1,
                  label: a.association.name,
                  weight: 500,
                  subLabel: a.regAssociationPaymentProvider?.accountId,
                  onClick: () => onOpenModal(VIEW_ACTIONS_ENUM.onboard, a),
                  icon: <CreditCard size={30} />
                },
                {
                  key: 2,
                  label:
                    PAYMENT_PROVIDER_LIST.find(
                      (p) =>
                        p.value ===
                        a.regAssociationPaymentProvider.fkPaymentProvider
                    )?.label ?? '',
                  showOnMobile: true
                },
                {
                  key: 3,
                  label:
                    CURRENCY_TYPE_LIST.find(
                      (p) =>
                        p.value.toString() ===
                        a.regAssociationPaymentProvider.fkCurrencyType?.toString()
                    )?.fullLabel ?? '',
                  showOnMobile: true
                },
                {
                  key: 4,
                  label: statusInfo.label,
                  color: statusInfo.color,
                  showOnMobile: true
                }
              ]
            };
          })}
        sortBy={sortValue}
        sortFilter={customFilterData}
        tableTitle="Payee"
      />

      <FinancialPayeeOnboardingModal
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.onboard
        }
        onClose={onCloseModal}
        regAssociation={modalState.item}
      />

      <ChangeCurrencyModal
        confirmLoading={modalLoading}
        currencyType={
          modalState.item?.regAssociationPaymentProvider?.fkCurrencyType
        }
        isOpen={
          modalState.isOpen &&
          modalState.action === VIEW_ACTIONS_ENUM.changeCurrency
        }
        onClose={onCloseModal}
        onConfirm={(fkCurrencyType) => {
          setModalLoading(true);
          updateRegAssociation(
            modalState.item?.pkRegAssociation,
            { ...regAssociation, fkCurrencyType },
            () => {
              setModalLoading(false);
              onCloseModal();
              triggerNotification(
                'Payment currency Updated',
                'Success',
                'green'
              );
            },
            (message) => {
              triggerNotification(message);
              setModalLoading(false);
            }
          );
        }}
        sandboxEnabled={modalState.item?.sandboxEnabled}
        showRedirectButton
      />

      <ConfirmModal
        confirmActionColor="red"
        confirmActionText="Yes, remove payee"
        isLoading={modalLoading}
        isOpen={
          modalState.isOpen &&
          authState.userData?.isAdmin &&
          modalState.action === VIEW_ACTIONS_ENUM.REMOVE_PAYEE
        }
        message={
          <AppStack style={{ gap: 10, textAlign: 'center' }}>
            <AppText>
              Are you sure you want to remove this payee from{' '}
              <b>{modalState.item?.association.name}</b>?
            </AppText>
          </AppStack>
        }
        onCancel={onCloseModal}
        onConfirm={() => {
          setModalLoading(true);
          disconnectPaymentProvider(
            modalState.item?.pkRegAssociation,
            () => {
              fetchAdminRegAssociations((data) => {
                const updatedRegAssociation = data.find(
                  (s) =>
                    s.pkRegAssociation === modalState.item?.pkRegAssociation
                );
                changeAssociation({
                  pkRegAssociation: updatedRegAssociation.pkRegAssociation,
                  regAssociation: updatedRegAssociation
                });
              });

              setModalLoading(false);
              triggerNotification('Payee removed!', 'Success', 'green');
              onCloseModal();
            },
            (message) => {
              triggerNotification(message);
              setModalLoading(false);
            }
          );
        }}
        title="Remove payee"
      />
    </AppCard>
  );
};

FinancialPayees.propTypes = { regAssociation: PropTypes.object };

export default FinancialPayees;
