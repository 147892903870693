import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  TextInput,
  Tooltip,
  useMantineTheme
} from '@mantine/core';
import { AlertCircle } from 'tabler-icons-react';
import AppStack from '../../common/AppStack';
import AppTitle from '../../common/AppTitle';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import RegistrationSignupListItem from './RegistrationSignupListItem';

const RegistrationsList = ({
  regAssociationDivisionForms,
  title,
  titleTooltip,
  onSelectRegForm,
  primaryColor,
  primaryTextColor,
  secondaryColor,
  secondaryTextColor,
  disabled
}) => {
  const mqIndex = useMediaQueryIndex();
  const theme = useMantineTheme();
  const [filteredRegFormData, setFilteredRegFormData] = useState({
    search: '',
    displayCount: 5
  });

  const filteredForms = regAssociationDivisionForms.filter(
    (r) =>
      !filteredRegFormData.search ||
      r.regForm.name
        .toLowerCase()
        .includes(filteredRegFormData.search.toLowerCase())
  );

  useEffect(() => {
    setFilteredRegFormData({
      search: '',
      displayCount: 5
    });
  }, []);

  return (
    <AppStack style={{ flex: 1, margin: '15px 20px 25px 20px', gap: 20 }}>
      <AppFlexbox
        style={{
          margin: '0px 10px',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 10
        }}
      >
        <AppTitle>{title}</AppTitle>
        {titleTooltip && (
          <Tooltip
            disabled={!titleTooltip}
            events={{ hover: true, focus: false, touch: true }}
            label={titleTooltip}
            multiline
            position={mqIndex >= 2 ? 'right' : 'bottom'}
            withArrow
            withinPortal
          >
            <AppStack>
              <AlertCircle color="dodgerblue" size={35} />
            </AppStack>
          </Tooltip>
        )}
      </AppFlexbox>

      <TextInput
        onChange={(e) =>
          setFilteredRegFormData({
            search: e.target.value,
            displayCount: 5
          })
        }
        placeholder="Search..."
        size="md"
        style={{ margin: '0px 10px' }}
        value={filteredRegFormData.search}
      />
      <AppStack style={{ flex: 1, gap: 15, padding: '0px 10px' }}>
        {filteredForms.length === 0 ? (
          <AppText style={{ textAlign: 'center' }}>
            No Registration forms available
          </AppText>
        ) : (
          <>
            <Divider />
            {filteredForms
              .slice(0, filteredRegFormData.displayCount)
              .map((r) => (
                <React.Fragment key={r.pkRegAssociationDivisionForm}>
                  <RegistrationSignupListItem
                    isDisabled={disabled}
                    onSelectRegForm={onSelectRegForm}
                    primaryColor={primaryColor}
                    primaryTextColor={primaryTextColor}
                    regAssociationDivisionForm={r}
                    secondaryColor={secondaryColor}
                    secondaryTextColor={secondaryTextColor}
                  />
                  <Divider />
                </React.Fragment>
              ))}
            {filteredForms.length > filteredRegFormData.displayCount && (
              <AppFlexbox style={{ flex: 1, justifyContent: 'center' }}>
                <Button
                  color="dark"
                  onClick={() =>
                    setFilteredRegFormData({
                      ...filteredRegFormData,
                      displayCount: filteredRegFormData.displayCount + 5
                    })
                  }
                  style={{
                    backgroundColor: theme.black,
                    marginTop: 20,
                    paddingLeft: 40,
                    paddingRight: 40
                  }}
                >
                  See More
                </Button>
              </AppFlexbox>
            )}
          </>
        )}
      </AppStack>
    </AppStack>
  );
};

RegistrationsList.propTypes = {
  disabled: PropTypes.bool,
  onSelectRegForm: PropTypes.func,
  primaryColor: PropTypes.string,
  primaryTextColor: PropTypes.string,
  regAssociationDivisionForms: PropTypes.array,
  secondaryColor: PropTypes.string,
  secondaryTextColor: PropTypes.string,
  title: PropTypes.string,
  titleTooltip: PropTypes.string
};

export default RegistrationsList;
