import React from 'react';
import { PropTypes } from 'prop-types';
import { Button } from '@mantine/core';
import { Link } from 'react-router-dom';
import AppText from './AppText';
import { getResponsiveStyle as rs } from '../../helpers/styles';
import { useMediaQueryIndex } from '../../helpers/hooks';
import AppStack from './AppStack';
import AppTitle from './AppTitle';
import AppFlexbox from './AppFlexbox';
import AppCard from './AppCard';

const HeaderView = ({
  title,
  titleSize,
  maxViewWidth,
  viewTabs,
  activeViewTab,
  children,
  viewProps
}) => {
  const mqIndex = useMediaQueryIndex();
  return (
    <AppStack
      style={{
        flex: 1,
        alignSelf: 'stretch',
        gap: rs([10, 10, 20], mqIndex)
      }}
    >
      {title && (
        <AppCard
          radius={0}
          shadow="md"
          style={{
            display: 'flex',
            flexDirection: 'row',
            minHeight: 80,
            borderBottom: `solid 1px #CED4DA`,
            alignSelf: 'stretch',
            padding: 20
          }}
        >
          <AppStack style={{ margin: 'auto', placeItems: 'center', gap: 15 }}>
            <AppTitle order={titleSize} style={{ margin: 'auto' }}>
              {title}
            </AppTitle>

            {viewTabs && viewTabs.length > 1 && (
              <AppFlexbox
                style={{
                  gap: 20,
                  justifyContent: 'center',
                  flexWrap: 'wrap'
                }}
              >
                {viewTabs.map((tab) => (
                  <Button
                    key={tab.title}
                    color="blue"
                    component={Link}
                    style={{ borderRadius: 12 }}
                    to={tab.to}
                    variant={tab.value === activeViewTab ? 'filled' : 'subtle'}
                  >
                    <AppText style={{ margin: 10, fontSize: 16 }} weight={500}>
                      {tab.title}
                    </AppText>
                  </Button>
                ))}
              </AppFlexbox>
            )}
          </AppStack>
        </AppCard>
      )}

      <AppFlexbox
        {...viewProps}
        style={{
          flex: 1,
          alignSelf: 'center',
          width: '100%',
          maxWidth: rs(
            ['unset', 'unset', 'unset', maxViewWidth ?? 1000],
            mqIndex
          ),
          paddingBottom: rs([0, 0, 0, 40], mqIndex),
          gap: rs([10, 10, 20], mqIndex),
          flexDirection: 'column',
          marginTop: title ? 0 : rs([0, 0, 0, 20], mqIndex),
          ...viewProps?.style
        }}
      >
        {children}
      </AppFlexbox>
    </AppStack>
  );
};

HeaderView.propTypes = {
  activeViewTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
  maxViewWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  titleSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  viewProps: PropTypes.object,
  viewTabs: PropTypes.array
};

export default HeaderView;
