import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  MultiSelect,
  NumberInput,
  Radio,
  TextInput
} from '@mantine/core';
import NumberFormat from 'react-number-format';
import { Plus } from 'tabler-icons-react';
import ResponsiveModal from '../../common/ResponsiveModal';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import FormSection from '../../common/FormSection';
import CouponRegistrationAssign from './CouponRegistrationAssign';
import { triggerNotification } from '../../../helpers/notificationHelper';
import AppTitle from '../../common/AppTitle';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import AppRadioGroup from '../../common/AppRadioGroup';
import { CURRENCY_TYPE_LIST } from '../../../config/constants';

const GroupCouponModal = ({
  isOpen,
  onClose,
  groupCoupon,
  fkRegAssociation,
  showExpireView,
  showReinstateView
}) => {
  const mqIndex = useMediaQueryIndex();
  const hasFetchedDivisions = useRef(false);
  const {
    state,
    fetchAdminRegAssociationLeagueOptions,
    createAdminRegAssociationGroupCoupon,
    updateAdminRegAssociationGroupCoupon,
    expireAdminRegAssociationGroupCoupon
  } = useContext(RegistrationAdminContext);
  const [formState, setFormState] = useState({
    code: '',
    discountType: 'FLAT',
    discountAmount: 0,
    limitRedemptions: false,
    maxRedemptions: 1,
    showAssignDivisionForms: false,
    divisionForms: [],
    qualifyPerItem: false,
    minimumQualifiedAmount: 1,
    confirmExpire: showExpireView ?? false,
    confirmReinstate: showReinstateView ?? false,
    isLoading: false
  });
  const isExpired = groupCoupon && !groupCoupon.active;
  const regAssociationDivisions =
    state.regAssociation.value?.regAssociationDivisions.sort((a, b) =>
      a.name.localeCompare(b.name)
    ) ?? [];
  const regAssociation = state.regAssociations.value.find(
    (a) => a.pkRegAssociation.toString() === fkRegAssociation?.toString()
  );
  const currency = CURRENCY_TYPE_LIST.find(
    (c) =>
      c.value.toString() ===
      regAssociation?.regAssociationPaymentProvider?.fkCurrencyType?.toString()
  )?.abbr;

  useEffect(() => {
    if (isOpen && !state.regAssociation.loading) {
      if (groupCoupon) {
        setFormState({
          code: groupCoupon.code,
          discountType: groupCoupon.flatDiscount ? 'FLAT' : 'PERCENT',
          discountAmount:
            groupCoupon.flatDiscount ?? groupCoupon.percentageDiscount,
          limitRedemptions: !!groupCoupon.maxRedemptions,
          maxRedemptions: groupCoupon.maxRedemptions ?? 1,
          showAssignDivisionForms: false,
          divisionForms: groupCoupon.regAssociationGroupCouponDivisionForms,
          qualifyPerItem: groupCoupon.qualifyPerItem,
          minimumQualifiedAmount: groupCoupon.minimumQualifiedAmount,
          confirmExpire: showExpireView ?? false,
          confirmReinstate: showReinstateView ?? false,
          isLoading: false
        });
      }
      else {
        setFormState({
          code: '',
          discountType: 'FLAT',
          discountAmount: 0,
          limitRedemptions: false,
          maxRedemptions: 1,
          showAssignDivisionForms: false,
          divisionForms: [],
          qualifyPerItem: false,
          minimumQualifiedAmount: 1,
          confirmExpire: false,
          confirmReinstate: false,
          isLoading: false
        });
      }

      if (
        !hasFetchedDivisions.current ||
        state.regAssociation.value?.pkRegAssociation.toString() !==
          fkRegAssociation.toString()
      ) {
        fetchAdminRegAssociationLeagueOptions(fkRegAssociation);
        hasFetchedDivisions.current = true;
      }
    }
  }, [isOpen, state.regAssociation.loading]);

  const onError = (message) => {
    setFormState({
      ...formState,
      isLoading: false
    });
    triggerNotification(message);
  };

  const onSubmitGroupCoupon = ({ reinstate } = {}) => {
    setFormState({
      ...formState,
      isLoading: true
    });
    const couponData = {
      ...formState,
      divisionForms: [
        ...formState.divisionForms.map((d) => ({
          ...d.regAssociationDivisionForm,
          minimumQualifiedAmount: d.minimumQualifiedAmount
        }))
      ],
      maxRedemptions: formState.limitRedemptions
        ? formState.maxRedemptions
        : null,
      percentageDiscount:
        formState.discountType === 'FLAT' ? null : formState.discountAmount,
      flatDiscount:
        formState.discountType === 'FLAT' ? formState.discountAmount : null,
      reinstate
    };

    if (groupCoupon) {
      updateAdminRegAssociationGroupCoupon(
        groupCoupon.pkRegAssociationGroupCoupon,
        couponData,
        onClose,
        onError
      );
    }
    else {
      createAdminRegAssociationGroupCoupon(
        fkRegAssociation,
        couponData,
        onClose,
        onError
      );
    }
  };

  return (
    <ResponsiveModal
      isLoading={!hasFetchedDivisions.current || state.regAssociation.loading}
      isOpen={isOpen}
      onClose={onClose}
      title={
        <AppTitle order={2}>
          {formState.confirmReinstate
            ? 'Reactivate Coupon'
            : formState.confirmExpire
            ? 'Expire Coupon'
            : groupCoupon
            ? 'Edit Coupon'
            : 'Create Coupon'}
        </AppTitle>
      }
    >
      {groupCoupon && formState.confirmReinstate ? (
        <FormSection
          isLoading={formState.isLoading}
          onCancel={() => {
            if (showReinstateView) {
              onClose();
            }
            else {
              setFormState({
                ...formState,
                confirmReinstate: false
              });
            }
          }}
          onSubmit={() => onSubmitGroupCoupon({ reinstate: true })}
          submitTitle="Reactivate Coupon"
        >
          <AppStack style={{ gap: 20, marginTop: 20 }}>
            <AppText style={{ textAlign: 'center' }}>
              Are you sure you want to reinstate <b>{groupCoupon.code}</b>?
            </AppText>
          </AppStack>
        </FormSection>
      ) : groupCoupon && formState.confirmExpire ? (
        <FormSection
          cancelTitle="Cancel"
          isLoading={formState.isLoading}
          onCancel={() => {
            if (showExpireView) {
              onClose();
            }
            else {
              setFormState({
                ...formState,
                confirmExpire: false
              });
            }
          }}
          onSubmit={() => {
            setFormState({
              ...formState,
              isLoading: true
            });
            expireAdminRegAssociationGroupCoupon(
              groupCoupon.pkRegAssociationGroupCoupon,
              onClose,
              onError
            );
          }}
          style={{ marginTop: 20 }}
          submitColor="red"
          submitTitle="Expire Coupon"
        >
          <AppText style={{ textAlign: 'center' }}>
            Are you sure you want to expire <b>{groupCoupon.code}</b>?
          </AppText>
        </FormSection>
      ) : formState.showAssignDivisionForms ? (
        <CouponRegistrationAssign
          assignedDivisionForms={formState.divisionForms}
          onAssign={(divisionForms) =>
            setFormState({
              ...formState,
              divisionForms,
              showAssignDivisionForms: false
            })
          }
          onCancel={() =>
            setFormState({
              ...formState,
              showAssignDivisionForms: false
            })
          }
          qualifyPerItem={formState.qualifyPerItem}
          regAssociationDivisions={regAssociationDivisions}
        />
      ) : (
        <FormSection
          cancelTitle="Cancel"
          isLoading={formState.isLoading}
          onCancel={onClose}
          onSubmit={() => onSubmitGroupCoupon()}
          submitTitle={groupCoupon ? 'Save Changes' : 'Create'}
        >
          <AppStack style={{ gap: 20 }}>
            <TextInput
              disabled={groupCoupon || formState.isLoading}
              label="Coupon Code"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  code: e.currentTarget.value.toUpperCase().replace(' ', '')
                })
              }
              required
              value={formState.code}
            />

            <AppStack style={{ gap: 15 }}>
              <AppFlexbox>
                <AppRadioGroup
                  label="Discount"
                  onChange={(value) =>
                    setFormState({
                      ...formState,
                      discountType: value
                    })
                  }
                  size="sm"
                  spacing="lg"
                  styles={{
                    input: { cursor: 'pointer !important' },
                    label: { cursor: 'pointer' },
                    root: {
                      alignItems: 'start',
                      flexDirection: rs(['column', 'row'], mqIndex)
                    }
                  }}
                  value={formState.discountType}
                >
                  <Radio
                    disabled={groupCoupon || formState.isLoading}
                    label="Flat Discount"
                    value="FLAT"
                  />
                  <Radio
                    disabled={groupCoupon || formState.isLoading}
                    label="Percentage Discount"
                    value="PERCENT"
                  />
                </AppRadioGroup>
              </AppFlexbox>
              <NumberFormat
                allowNegative={false}
                customInput={TextInput}
                decimalScale={2}
                disabled={groupCoupon || formState.isLoading}
                onValueChange={(e) =>
                  setFormState({
                    ...formState,
                    discountAmount: e.value
                  })
                }
                {...(formState.discountType === 'FLAT'
                  ? {
                      placeholder: '$0.00',
                      prefix: '$',
                      fixedDecimalScale: true,
                      thousandSeparator: true,
                      rightSection: currency && (
                        <AppText
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                            color: '#999',
                            paddingRight: 5
                          }}
                        >
                          {currency}
                        </AppText>
                      )
                    }
                  : { placeholder: '10%', suffix: '%' })}
                required
                style={{ flex: 1 }}
                value={formState.discountAmount}
              />
            </AppStack>

            <AppStack style={{ gap: 15 }}>
              <AppStack style={{ gap: 10 }}>
                <AppText style={{ fontSize: 14 }} weight={500}>
                  Redemption Limit
                </AppText>
                <Checkbox
                  checked={formState.limitRedemptions}
                  disabled={formState.isLoading}
                  label="Enable Redemption Limit"
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      limitRedemptions: e.currentTarget.checked
                    })
                  }
                />
              </AppStack>

              {formState.limitRedemptions && (
                <NumberInput
                  disabled={formState.isLoading}
                  label="Limit"
                  min={1}
                  onChange={(value) =>
                    setFormState({
                      ...formState,
                      maxRedemptions: value || null
                    })
                  }
                  value={formState.maxRedemptions}
                />
              )}
            </AppStack>

            <AppRadioGroup
              label=""
              onChange={(value) =>
                setFormState({
                  ...formState,
                  qualifyPerItem: value === 'item'
                })
              }
              size="sm"
              spacing="lg"
              styles={{
                input: { cursor: 'pointer !important' },
                label: { cursor: 'pointer' },
                root: {
                  alignItems: 'start',
                  flexDirection: rs(['column', 'row'], mqIndex)
                }
              }}
              value={formState.qualifyPerItem ? 'item' : 'overall'}
            >
              <Radio
                disabled={groupCoupon || formState.isLoading}
                label="Overall Minimum Purchase Amount"
                style={{ fontWeight: 500 }}
                value="overall"
              />
              <Radio
                disabled={groupCoupon || formState.isLoading}
                label="Per-Item Minimum Purchase Amount"
                style={{ fontWeight: 500 }}
                value="item"
              />
            </AppRadioGroup>
            <TextInput
              disabled={groupCoupon || formState.isLoading}
              label="Minimum Purchase Amount"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  minimumQualifiedAmount: e.currentTarget.value
                })
              }
              required
              value={formState.minimumQualifiedAmount}
            />
            <AppStack style={{ gap: 5 }}>
              <AppFlexbox
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'end'
                }}
              >
                <AppText style={{ fontSize: 14 }} weight={500}>
                  Registration Forms
                </AppText>
                <Button
                  disabled={
                    formState.isLoading ||
                    (groupCoupon && groupCoupon.qualifyPerItem)
                  }
                  leftSection={<Plus />}
                  onClick={() =>
                    setFormState({
                      ...formState,
                      showAssignDivisionForms: true
                    })
                  }
                  size="xs"
                >
                  Assign
                </Button>
              </AppFlexbox>
              <MultiSelect
                clearable
                data={formState.divisionForms.map((d) => ({
                  label: `${
                    d.regAssociationDivisionForm.regAssociationDivision.name
                  } - ${d.regAssociationDivisionForm.regForm.name}${
                    formState.qualifyPerItem
                      ? `- X${d.minimumQualifiedAmount}` ?? '- X1'
                      : ''
                  }`,
                  value: d.regAssociationDivisionForm.pkRegAssociationDivisionForm.toString()
                }))}
                disabled
                required
                searchable
                value={formState.divisionForms.map((d) =>
                  d.regAssociationDivisionForm.pkRegAssociationDivisionForm.toString()
                )}
              />
            </AppStack>
          </AppStack>
          {groupCoupon && (
            <AppStack style={{ alignItems: 'center' }}>
              <Button
                color={isExpired ? 'blue' : 'red'}
                disabled={formState.isLoading}
                onClick={() =>
                  setFormState({
                    ...formState,
                    confirmReinstate: isExpired,
                    confirmExpire: !isExpired
                  })
                }
                style={{ maxWidth: 250, width: '100%' }}
                variant={isExpired ? 'outline' : 'filled'}
              >
                {isExpired ? 'Reactivate Coupon' : 'Expire Coupon'}
              </Button>
            </AppStack>
          )}
        </FormSection>
      )}
    </ResponsiveModal>
  );
};

GroupCouponModal.propTypes = {
  fkRegAssociation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  groupCoupon: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  showExpireView: PropTypes.bool,
  showReinstateView: PropTypes.bool
};

export default GroupCouponModal;
