import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, Anchor, Button } from '@mantine/core';
import { Home, Send } from 'tabler-icons-react';
import { Context as RegistrationContext } from '../providers/RegistrationProvider';
import { triggerNotification } from '../helpers/notificationHelper';
import LoadableView from '../components/common/LoadableView';
import AppFlexbox from '../components/common/AppFlexbox';
import AppStack from '../components/common/AppStack';
import AppText from '../components/common/AppText';
import AppCard from '../components/common/AppCard';
import EmailSubscriptionInfo from '../components/content/communication/EmailSubscriptionInfo';

const EmailUnsubscribeView = () => {
  const navigate = useNavigate();
  const hasFetched = useRef(false);
  const { search } = useLocation();
  const { state, unsubscribeFromAssociationEmails } = useContext(
    RegistrationContext
  );
  const [viewState, setViewState] = useState({ showError: false });
  const fkRegAssociation = new URLSearchParams(search).get('association');
  const fkRegFormSubmission = new URLSearchParams(search).get('submission');
  const fkRegWaitlistPerson = new URLSearchParams(search).get('waitlist');
  const fkRegAssociationDivisionFormExternalInvite = new URLSearchParams(
    search
  ).get('invite');
  const email = new URLSearchParams(search).get('email');
  const { value: emailUnsubscribe, loading } = state.regEmailUnsubscribe;
  const isLoading = !hasFetched.current || loading;

  const hasResubscribed = emailUnsubscribe?.resubscribedDate;

  useEffect(() => {
    if (email) {
      // eslint-disable-next-line no-use-before-define
      toggleEmailSubscription();
      hasFetched.current = true;
    }
  }, [email]);

  const toggleEmailSubscription = (resubscribe = false) => {
    unsubscribeFromAssociationEmails(
      {
        fkRegAssociation,
        fkRegFormSubmission,
        fkRegWaitlistPerson,
        fkRegAssociationDivisionFormExternalInvite,
        email,
        resubscribe
      },
      () => {
        triggerNotification(
          `Email ${resubscribe ? 'Resubscribed' : 'Unsubscribed'}!`,
          'Success',
          'green'
        );
      },
      () => {
        setViewState({
          ...viewState,
          showError: true
        });
        triggerNotification(
          `Association not found. Please contact support with the name of association and we will ${
            resubscribe ? 'resubscribe' : 'unsubscribe'
          } you right away!`
        );
      }
    );
  };

  return (
    <LoadableView isLoading={isLoading}>
      <AppFlexbox style={{ justifyContent: 'center', marginTop: 20 }}>
        {viewState.showError ? (
          <AppCard style={{ padding: 5, maxWidth: 550 }}>
            <Alert color="red" title="Please Contact Support" variant="outline">
              <AppStack style={{ gap: 5 }}>
                <AppText weight={500}>Association not found.</AppText>
                <AppText>
                  Please email support at '
                  <b>
                    <a
                      href="mailto:support@sportsheadz.com"
                      style={{
                        color: 'dodgerblue',
                        textDecoration: 'none'
                      }}
                    >
                      support@sportsheadz.com
                    </a>
                  </b>
                  ' with the name of association and we will unsubscribe you
                  right away!
                </AppText>
                <Button
                  leftSection={<Send />}
                  onClick={() => window.open(`mailto:support@sportsheadz.com`)}
                  style={{
                    margin: 'auto',
                    width: '100%',
                    maxWidth: 250,
                    marginTop: 20
                  }}
                  variant="outline"
                >
                  Email Support
                </Button>
              </AppStack>
            </Alert>
          </AppCard>
        ) : (
          emailUnsubscribe && (
            <AppStack>
              <AppCard style={{ padding: 5, maxWidth: 525 }}>
                <EmailSubscriptionInfo
                  actionButton={
                    <Button
                      color="dark"
                      leftSection={<Home />}
                      onClick={() => navigate('/')}
                      style={{
                        margin: 'auto',
                        width: '100%',
                        maxWidth: 250
                      }}
                      variant="outline"
                    >
                      Home Page
                    </Button>
                  }
                  email={emailUnsubscribe.email}
                  regAssociation={emailUnsubscribe.regAssociation}
                  regEmailUnsubscribe={emailUnsubscribe}
                />
              </AppCard>
              <AppFlexbox
                style={{
                  alignSelf: 'center',
                  justifyContent: 'center',
                  gap: 5
                }}
              >
                <AppText
                  style={{ fontSize: 14, color: '#313234' }}
                  weight={500}
                >
                  Was this by accident?
                </AppText>
                <Anchor
                  onClick={() => toggleEmailSubscription(!hasResubscribed)}
                >
                  <AppText
                    style={{ fontSize: 14, color: '#dodgerblue' }}
                    weight={500}
                  >
                    Click here to{' '}
                    {hasResubscribed ? 'unsubscribe' : 'resubscribe'}.
                  </AppText>
                </Anchor>
              </AppFlexbox>
            </AppStack>
          )
        )}
      </AppFlexbox>
    </LoadableView>
  );
};

export default EmailUnsubscribeView;
