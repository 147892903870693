import React from 'react';
import { PropTypes } from 'prop-types';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import PaginationList from '../../common/PaginationList';
import RegisterRegistrantListItem from './RegisterRegistrantListItem';

const RegisterRegistrantList = ({
  title,
  registrants,
  regAssociationDivisionForm,
  externalInvite,
  onEditRegistrant,
  pageIndex,
  onPageChange,
  selectedRegistrants,
  onSelect,
  isSingleSelect,
  selectDisabled
}) => (
  <AppStack style={{ gap: 0 }}>
    {title && (
      <AppText style={{ fontSize: 18 }} weight={700}>
        {title}
      </AppText>
    )}

    <PaginationList
      emptyMessage="No recipients"
      items={registrants.map((r) => {
        const isSelected = selectedRegistrants?.some(
          (s) => s.pkRegPerson === r.pkRegPerson
        );

        return (
          <RegisterRegistrantListItem
            key={r.pkRegPerson}
            externalInvite={externalInvite}
            isSelected={isSelected}
            isSingleSelect={isSingleSelect}
            onEditRegistrant={onEditRegistrant}
            onSelect={onSelect}
            regAssociationDivisionForm={regAssociationDivisionForm}
            regPerson={r}
            selectDisabled={selectDisabled}
          />
        );
      })}
      itemsPerPage={3}
      listProps={{ style: { gap: 30 } }}
      noBorder
      onPageChange={onPageChange}
      pageIndex={pageIndex}
      style={{ gap: 30, margin: '20px 15px', marginTop: 15 }}
    />
  </AppStack>
);

RegisterRegistrantList.propTypes = {
  externalInvite: PropTypes.object,
  isSingleSelect: PropTypes.bool,
  onEditRegistrant: PropTypes.func,
  onPageChange: PropTypes.func,
  onSelect: PropTypes.func,
  pageIndex: PropTypes.number,
  regAssociationDivisionForm: PropTypes.object,
  registrants: PropTypes.array,
  selectDisabled: PropTypes.bool,
  selectedRegistrants: PropTypes.array,
  title: PropTypes.string
};

export default RegisterRegistrantList;
