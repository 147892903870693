import React from 'react';
import PropTypes from 'prop-types';
import { Image } from '@mantine/core';

const AppImage = ({ src, width, height, fit, alt, ...rest }) => (
  <Image
    alt={alt}
    fit={fit ?? 'contain'}
    h={height}
    src={src}
    w={width}
    {...rest}
    style={{
      minWidth: width ?? '100%',
      minHeight: height ?? '100%',
      ...rest.style
    }}
  />
);

AppImage.propTypes = {
  alt: PropTypes.string,
  fit: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  src: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default AppImage;
