import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@mantine/core';
import ResponsiveModal from '../../common/ResponsiveModal';
import FormSection from '../../common/FormSection';
import AppTitle from '../../common/AppTitle';
import EmailSubscriptionInfo from './EmailSubscriptionInfo';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import { triggerNotification } from '../../../helpers/notificationHelper';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import { singularPluralFormat } from '../../../helpers/format';

const MailingSubscribeModal = ({
  isOpen,
  onClose,
  regAssociation,
  regFormSubmissionEmails
}) => {
  const {
    fetchRegistrationEmails,
    unsubscribeFromAssociationEmails
  } = useContext(RegistrationContext);
  const [formState, setFormState] = useState({
    mailboxEmail: '',
    subscribeEmail: false,
    isLoading: false
  });

  const associationMailboxes = regFormSubmissionEmails
    .filter((e) =>
      e.regAssociations.some(
        (s) => s.pkRegAssociation === regAssociation?.pkRegAssociation
      )
    )
    .sort((a, b) => a.email.localeCompare(b.email));
  const selectedMailbox =
    associationMailboxes.length === 1
      ? associationMailboxes[0]
      : associationMailboxes.find((e) => e.email === formState.mailboxEmail);

  const unsubscribeEntry = selectedMailbox?.regEmailUnsubscribes.find(
    (f) =>
      f.regAssociation.pkRegAssociation === regAssociation?.pkRegAssociation
  );

  useEffect(() => {
    if (isOpen) {
      setFormState({
        mailboxEmail: '',
        subscribeEmail: false,
        isLoading: false
      });
    }
  }, [isOpen]);

  const onSuccess = (resubscribe) => {
    triggerNotification(
      `Email ${resubscribe ? 'Resubscribed' : 'Unsubscribed'}!`,
      'Success',
      'green'
    );
    onClose();
  };

  const toggleEmailSubscription = (resubscribe = false) => {
    setFormState({
      ...formState,
      isLoading: true
    });
    const fkRegFormSubmission = selectedMailbox.regFormSubmissions.find(
      (s) =>
        s.regAssociationDivisionForm.regForm.fkRegAssociation ===
          regAssociation.pkRegAssociation &&
        (s.overrideEmail?.trim().toLowerCase() ===
          selectedMailbox.email.trim().toLowerCase() ||
          s.user.email.toLowerCase() ===
            selectedMailbox.email.trim().toLowerCase())
    )?.pkRegFormSubmission;

    const fkRegWaitlistPerson = selectedMailbox.regWaitlistPersons.find(
      (w) =>
        w.regAssociationDivisionForm.regForm.fkRegAssociation ===
          regAssociation.pkRegAssociation &&
        w.regPerson.user.email.toLowerCase() ===
          selectedMailbox.email.trim().toLowerCase()
    )?.pkRegWaitlistPerson;

    const fkRegAssociationDivisionFormExternalInvite = selectedMailbox.regAssociationDivisionFormExternalInvites.find(
      (i) =>
        i.regAssociationDivisionForm.regForm.fkRegAssociation ===
          regAssociation.pkRegAssociation &&
        i.sentTo.toLowerCase() === selectedMailbox.email.trim().toLowerCase()
    )?.pkRegAssociationDivisionFormExternalInvite;

    unsubscribeFromAssociationEmails(
      {
        fkRegAssociation: regAssociation.pkRegAssociation,
        fkRegFormSubmission,
        fkRegWaitlistPerson,
        fkRegAssociationDivisionFormExternalInvite,
        email: selectedMailbox.email,
        resubscribe
      },
      () => {
        fetchRegistrationEmails(
          () => onSuccess(resubscribe),
          () => onSuccess(resubscribe)
        );
      },
      () => {
        setFormState({
          ...formState,
          isLoading: false
        });
        triggerNotification(
          `Association not found. Please contact support with the name of association and we will ${
            resubscribe ? 'resubscribe' : 'unsubscribe'
          } you right away!`
        );
      }
    );
  };

  return (
    <ResponsiveModal
      centered
      onClose={onClose}
      opened={isOpen}
      size={600}
      title={
        <AppTitle order={2}>
          {!selectedMailbox
            ? 'Select Email'
            : unsubscribeEntry
            ? 'Resubscribe to mailings'
            : 'Unsubscribe from mailings'}
        </AppTitle>
      }
    >
      {!selectedMailbox ? (
        <FormSection cancelTitle="Cancel" isSubmitHidden onCancel={onClose}>
          <AppText style={{ textAlign: 'center' }}>
            Please select an email address to manage your subscription.
          </AppText>
          <AppStack style={{ gap: 10 }}>
            {associationMailboxes.map((e) => {
              const unsubEntry = e.regEmailUnsubscribes.find(
                (u) =>
                  u.regAssociation.pkRegAssociation ===
                  regAssociation.pkRegAssociation
              );

              const regFormSubmissions = e.regFormSubmissions.filter(
                (s) =>
                  s.regAssociationDivisionForm.regForm.fkRegAssociation ===
                    regAssociation.pkRegAssociation &&
                  (s.overrideEmail?.trim().toLowerCase() ===
                    e.email.trim().toLowerCase() ||
                    s.user.email.toLowerCase() === e.email.trim().toLowerCase())
              );
              return (
                <AppFlexbox
                  key={e.email}
                  onClick={() =>
                    setFormState({
                      ...formState,
                      mailboxEmail: e.email
                    })
                  }
                  style={{
                    padding: 10,
                    alignItems: 'center',
                    overflow: 'hidden',
                    cursor: 'pointer',
                    border: 'solid 1px lightgrey'
                  }}
                >
                  <AppStack style={{ flex: 1, gap: 0, overflow: 'hidden' }}>
                    <AppText weight={500}>{e.email}</AppText>
                    <AppText>
                      {singularPluralFormat(
                        regFormSubmissions.length,
                        'registration',
                        'registrations'
                      )}
                    </AppText>
                    <AppText style={{ color: unsubEntry ? 'red' : 'green' }}>
                      {unsubEntry ? 'Unsubscribed' : 'Subscribed'}
                    </AppText>
                  </AppStack>
                </AppFlexbox>
              );
            })}
          </AppStack>
        </FormSection>
      ) : (
        <FormSection
          cancelTitle="Cancel"
          isLoading={formState.isLoading}
          onCancel={onClose}
          onSubmit={() =>
            toggleEmailSubscription(
              !!unsubscribeEntry && !unsubscribeEntry.resubscribedDate
            )
          }
          submitColor={unsubscribeEntry ? 'blue' : 'red'}
          submitTitle={unsubscribeEntry ? 'Subscribe' : 'Unsubscribe'}
        >
          {regAssociation && (
            <>
              <EmailSubscriptionInfo
                email={selectedMailbox.email}
                regAssociation={regAssociation}
                regEmailUnsubscribe={unsubscribeEntry}
              />

              {unsubscribeEntry && (
                <Checkbox
                  checked={formState.subscribeEmail}
                  disabled={formState.isLoading}
                  label={`Subscribe to receive emails from ${regAssociation.association.name}`}
                  onChange={() =>
                    setFormState({
                      ...formState,
                      subscribeEmail: !formState.subscribeEmail
                    })
                  }
                  required
                  styles={{ label: { fontWeight: 500 } }}
                />
              )}
            </>
          )}
        </FormSection>
      )}
    </ResponsiveModal>
  );
};

MailingSubscribeModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  regAssociation: PropTypes.object,
  regFormSubmissionEmails: PropTypes.array
};

export default MailingSubscribeModal;
