import { PropTypes } from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Checkbox, Select } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import ResponsiveModal from '../../common/ResponsiveModal';
import { triggerNotification } from '../../../helpers/notificationHelper';
import AppTitle from '../../common/AppTitle';
import FormSection from '../../common/FormSection';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const MerchReportModal = ({
  isOpen,
  onClose,
  regReportTemplate,
  pkRegAssociation
}) => {
  const mqIndex = useMediaQueryIndex();
  const fetchedPkRegAssociation = useRef(null);
  const navigate = useNavigate();
  const {
    state,
    generateAdminRegAssociationReport,
    fetchMerchPackageOrderFilters
  } = useContext(RegistrationAdminContext);
  const [formState, setFormState] = useState({
    merchPackageUuid: '',
    includeInputAnswers: false,
    inputAnswers: [],
    isLoading: false
  });
  const isFetching =
    fetchedPkRegAssociation.current !== pkRegAssociation ||
    state.regCartMerchPackageFilters.loading;
  const merchPackageOptions =
    state.regCartMerchPackageFilters.value?.merchPackages.reduce((r, c) => {
      if (!r.find((f) => f.value === c.uuid)) {
        r.push({ value: c.uuid, label: c.name });
      }
      return r;
    }, []) || [];

  const inputAnswers =
    state.regCartMerchPackageFilters.value?.merchPackages
      .filter((f) => f.uuid === formState.merchPackageUuid)
      .reduce((r, c) => {
        c.merchPackageInputs.forEach((i) => {
          if (
            !r.find(
              (f) =>
                f.value.trim().toLowerCase() === i.label.trim().toLowerCase()
            )
          ) {
            r.push({ value: i.label, label: i.label, sort: i.sort });
          }
        });
        return r;
      }, []) || [];

  const allInputsSelected = inputAnswers.every((i) =>
    formState.inputAnswers.some((a) => a.value === i.value)
  );

  useEffect(() => {
    if (isOpen) {
      setFormState({
        merchPackageUuid: '',
        includeInputAnswers: false,
        inputAnswers: [],
        isLoading: false
      });

      if (fetchedPkRegAssociation !== pkRegAssociation) {
        fetchedPkRegAssociation.current = pkRegAssociation;
        fetchMerchPackageOrderFilters(pkRegAssociation);
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (formState.merchPackageUuid) {
      setFormState({
        ...formState,
        inputAnswers: inputAnswers.map((i) => ({
          value: i.value,
          type: 'merchPackageInput'
        }))
      });
    }
  }, [formState.merchPackageUuid]);

  return (
    <ResponsiveModal
      centered
      isLoading={isFetching}
      isOpen={isOpen}
      onClose={onClose}
      title={<AppTitle order={2}>Generate Report</AppTitle>}
    >
      {!isFetching && (
        <FormSection
          isLoading={formState.isLoading}
          onCancel={onClose}
          onSubmit={() => {
            setFormState({
              ...formState,
              isLoading: true
            });
            generateAdminRegAssociationReport(
              {
                pkRegAssociation,
                pkRegReportTemplate: regReportTemplate.pkRegReportTemplate,
                inputAnswers: [
                  {
                    valueName: 'merchPackageUuid',
                    value: formState.merchPackageUuid
                  },
                  {
                    valueName: 'includeInputAnswers',
                    value: formState.includeInputAnswers ? 'yes' : 'no'
                  },
                  ...formState.inputAnswers.map((i) => ({
                    valueName: i.type,
                    value: i.value
                  }))
                ]
              },
              () =>
                navigate(
                  `/admin/reports/${pkRegAssociation}/${
                    regReportTemplate.pkRegReportTemplate
                  }?r=${new Date().getTime()}`
                ),
              (e) => {
                triggerNotification(e);
                setFormState({
                  ...formState,
                  isLoading: false
                });
              }
            );
          }}
          style={{ gap: 30 }}
          submitTitle="Generate"
        >
          <Select
            data={merchPackageOptions}
            disabled={formState.isLoading}
            label="Merch Package"
            onChange={(value) =>
              setFormState({ ...formState, merchPackageUuid: value })
            }
            placeholder="Filter by merch package..."
            value={formState.merchPackageUuid ?? ''}
          />

          <Checkbox
            checked={formState.includeInputAnswers}
            disabled={formState.isLoading}
            label="Include input answers"
            onChange={(e) =>
              setFormState({
                ...formState,
                includeInputAnswers: e.target.checked
              })
            }
            style={{ fontWeight: 500 }}
          />

          {formState.merchPackageUuid && formState.includeInputAnswers && (
            <AppStack style={{ gap: 5 }}>
              <AppFlexbox style={{ justifyContent: 'space-between' }}>
                <AppText weight={500}>Input Answers</AppText>
                <Checkbox
                  checked={allInputsSelected}
                  label={allInputsSelected ? 'Unselect All' : 'Select All'}
                  onChange={() => {
                    if (allInputsSelected) {
                      setFormState({
                        ...formState,
                        inputAnswers: []
                      });
                    }
                    else {
                      setFormState({
                        ...formState,
                        inputAnswers: inputAnswers.map((i) => ({
                          value: i.value,
                          type: 'merchPackageInput'
                        }))
                      });
                    }
                  }}
                  style={{ fontWeight: 500 }}
                />
              </AppFlexbox>

              <AppFlexbox
                style={{
                  flexWrap: 'wrap',
                  gap: 20,
                  border: 'solid 1px lightgrey',
                  justifyContent: 'space-between',
                  padding: 10,
                  flex: 1
                }}
              >
                {inputAnswers.length === 0 ? (
                  <AppStack style={{ padding: '30px 10px' }}>
                    <AppText style={{ textAlign: 'center' }}>
                      No inputs available for the selected form
                    </AppText>
                  </AppStack>
                ) : (
                  inputAnswers.map((i) => {
                    const isSelected = formState.inputAnswers.some(
                      (a) => a.value === i.value
                    );
                    return (
                      <AppStack
                        key={i.value}
                        style={{ flex: rs(['100%', '45%'], mqIndex) }}
                      >
                        <Checkbox
                          checked={isSelected}
                          disabled={formState.isLoading}
                          label={i.label}
                          onChange={() => {
                            if (isSelected) {
                              setFormState({
                                ...formState,
                                inputAnswers: [
                                  ...formState.inputAnswers.filter(
                                    (a) => a.value !== i.value
                                  )
                                ]
                              });
                            }
                            else {
                              setFormState({
                                ...formState,
                                inputAnswers: [
                                  ...formState.inputAnswers,
                                  {
                                    value: i.value,
                                    type: 'merchPackageInput'
                                  }
                                ]
                              });
                            }
                          }}
                          style={{ fontWeight: 500 }}
                          styles={{ body: { alignItems: 'center' } }}
                        />
                      </AppStack>
                    );
                  })
                )}
              </AppFlexbox>
            </AppStack>
          )}
        </FormSection>
      )}
    </ResponsiveModal>
  );
};

MerchReportModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  pkRegAssociation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  regReportTemplate: PropTypes.object
};

export default MerchReportModal;
