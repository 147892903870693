import React, { useState, useContext, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Alert, Checkbox, TextInput } from '@mantine/core';
import { AlertCircle } from 'tabler-icons-react';
import { Context as AuthContext } from '../../../providers/AuthProvider';
import AuthCardForm from './AuthCardForm';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';

const SendAuthCode = ({ email, title, type, onSendCode, onCancel }) => {
  const { state, clearErrors, checkForExistingUser } = useContext(AuthContext);
  const [formState, setFormState] = useState({
    email: email ?? '',
    emailFound: !!email,
    codeOption: 'email'
  });

  useEffect(() => {
    setFormState({
      email: email ?? '',
      emailFound: !!email,
      codeOption: 'email'
    });
  }, []);

  useEffect(() => {
    clearErrors();
  }, [formState.emailFound]);

  return (
    <AuthCardForm
      hideError
      isLoading={state.loading}
      onSubmit={() => {
        if (formState.emailFound) {
          onSendCode(formState.email);
        }
        else {
          checkForExistingUser(formState.email, () => {
            setFormState({
              ...formState,
              emailFound: true
            });
          });
        }
      }}
      reverseSubmit
      submitSecondaryLink={{
        text: formState.emailFound
          ? 'Not you?'
          : type === 'password'
          ? 'Remember your password?'
          : 'Already have a code?',
        onClick: () => {
          formState.emailFound
            ? setFormState({
                ...formState,
                email: '',
                emailFound: false
              })
            : onCancel();
        }
      }}
      submitText={formState.emailFound ? 'Continue' : 'Search'}
      title={formState.emailFound ? title : 'Find Your Account'}
    >
      {formState.emailFound ? (
        <AppFlexbox>
          {type === 'password' ? (
            <AppText style={{ marginBottom: '24px' }}>
              How do you want to get the code to reset your password?
            </AppText>
          ) : (
            <AppText style={{ marginBottom: '24px' }}>
              How do you want to get the verification code to confirm your
              account?
            </AppText>
          )}
          <AppFlexbox flex={1} style={{ cursor: 'pointer', gap: 15 }}>
            <Checkbox defaultChecked />
            <AppStack style={{ marginLeft: 20, gap: 0 }}>
              <AppText>Send code via email</AppText>
              <AppText size="sm" style={{ fontWeight: 'bold' }}>
                {formState.email}
              </AppText>
            </AppStack>
          </AppFlexbox>
        </AppFlexbox>
      ) : (
        <>
          <TextInput
            disabled={state.loading}
            label="Email"
            onChange={(e) =>
              setFormState({
                ...formState,
                email: e.target.value
              })
            }
            required
            size="md"
            value={formState.email}
          />
          {state.error && (
            <Alert
              color="red"
              icon={<AlertCircle size={16} />}
              radius="xs"
              style={{ marginTop: 15 }}
              title="No Account Found"
              variant="filled"
            >
              Your search did not return any results. Please try again with
              other information.
            </Alert>
          )}
        </>
      )}
    </AuthCardForm>
  );
};

SendAuthCode.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSendCode: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  email: PropTypes.string,
  type: PropTypes.string
};

export default SendAuthCode;
