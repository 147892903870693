import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Menu, Skeleton, Button } from '@mantine/core';
import { ChevronDown } from 'tabler-icons-react';
import MenuContent from './MenuContent';
import AppStack from './AppStack';
import AppFlexbox from './AppFlexbox';
import AppText from './AppText';
import { getResponsiveStyle as rs } from '../../helpers/styles';
import { useMediaQueryIndex } from '../../helpers/hooks';

const SelectableListItem = ({
  isSelected,
  isDisabled,
  onSelect,
  label,
  subLabel,
  description,
  subDescription,
  subDescriptionColor,
  onAction,
  menuActions
}) => {
  const mqIndex = useMediaQueryIndex();

  return label || subLabel ? (
    <AppFlexbox
      style={{
        padding: 10,
        flex: 1,
        justifyContent: 'space-between',
        flexDirection: rs(['column', 'row'], mqIndex),
        gap: rs([10, 15], mqIndex)
      }}
    >
      <AppStack
        style={{
          width: '100%',
          alignSelf: 'stretch',
          flex: 2,
          justifyContent: 'space-between',
          flexDirection: rs(['column', 'row'], mqIndex),
          gap: rs([0, 15], mqIndex)
        }}
      >
        <AppFlexbox
          onClick={onSelect}
          style={{
            width: '100%',
            flex: 1,
            cursor: onSelect ? 'pointer' : 'cursor',
            flexWrap: 'nowrap',
            overflow: 'hidden',
            alignItems: 'center',
            whiteSpace: 'nowrap'
          }}
        >
          {onSelect && (
            <Checkbox
              checked={isSelected}
              disabled={isDisabled}
              onChange={() => {}}
            />
          )}

          <AppStack
            style={{
              gap: 0,
              flex: 1,
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            }}
          >
            {label && (
              <AppText
                style={{
                  lineHeight: '20px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
                weight={500}
              >
                {label}
              </AppText>
            )}
            {subLabel && (
              <AppText
                style={{
                  lineHeight: '20px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontWeight: 'normal'
                }}
              >
                {subLabel}
              </AppText>
            )}
          </AppStack>
        </AppFlexbox>
        {description && (
          <AppFlexbox style={{ alignSelf: 'stretch' }}>
            <AppStack
              style={{
                gap: 0,
                flex: 1,
                whiteSpace: 'nowrap'
              }}
            >
              <AppText
                style={{
                  lineHeight: '20px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
                weight={500}
              >
                {description}
              </AppText>
              {subDescription && (
                <AppText
                  style={{
                    color: subDescriptionColor || '#000',
                    lineHeight: '20px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontWeight: 'normal'
                  }}
                >
                  {subDescription}
                </AppText>
              )}
            </AppStack>
          </AppFlexbox>
        )}
      </AppStack>
      {onAction && (
        <AppStack
          style={{
            alignSelf: 'stretch',
            flex: rs([1, 'unset'], mqIndex),
            justifyContent: 'center'
          }}
        >
          {menuActions.length === 1 ? (
            <Button
              color="blue"
              onClick={() => {
                onAction(menuActions[0].value);
              }}
              rightSection={<ChevronDown />}
              size="xs"
              style={{ width: rs(['100%', 125], mqIndex) }}
              variant="outline"
            >
              {menuActions[0].label}
            </Button>
          ) : (
            <MenuContent
              control={
                <Button
                  color="blue"
                  rightSection={<ChevronDown />}
                  size="xs"
                  style={{ width: rs(['100%', 125], mqIndex) }}
                  variant="outline"
                >
                  Actions
                </Button>
              }
            >
              {menuActions.map((a) => (
                <Menu.Item
                  key={a.value}
                  onClick={() => {
                    onAction(a.value);
                  }}
                >
                  {a.label}
                </Menu.Item>
              ))}
            </MenuContent>
          )}
        </AppStack>
      )}
    </AppFlexbox>
  ) : (
    <AppFlexbox
      style={{
        flex: 1,
        padding: 10,
        gap: 15
      }}
    >
      <Skeleton height={20} width={20} />
      <AppStack style={{ gap: 0, flex: 1, justifyContent: 'center' }}>
        <Skeleton height={8} width="30%" />
      </AppStack>
    </AppFlexbox>
  );
};

SelectableListItem.propTypes = {
  description: PropTypes.string,
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  label: PropTypes.string,
  menuActions: PropTypes.array,
  onAction: PropTypes.func,
  onSelect: PropTypes.func,
  subDescription: PropTypes.string,
  subDescriptionColor: PropTypes.string,
  subLabel: PropTypes.string
};

export default SelectableListItem;
