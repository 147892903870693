import PropTypes from 'prop-types';
import React from 'react';
import { Accordion, Checkbox, Select, TextInput } from '@mantine/core';
import { CircleCheck } from 'tabler-icons-react';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { REG_FORM_CONTROL_TYPE_ENUM } from '../../../config/constants';
import PaginationList from '../../common/PaginationList';
import SelectCreatable from '../../common/SelectCreatable';

const EvaluationSessionAttributesAccordionAssign = ({
  fkRegFormControlType,
  regScoutReportQuestionOptions,
  assignValue,
  onAssignValueChange,
  onAssign,
  onAssignAll,
  regScoutReportEvaluations,
  pageIndex,
  onPageChange,
  getEvaluationAnswer,
  label,
  value,
  isCompleted,
  createableInput
}) => {
  const areAllPlayersAssigned = regScoutReportEvaluations.every(
    (e) => (getEvaluationAnswer(e) || null) === (assignValue || null)
  );

  return (
    <Accordion.Item value={value}>
      <Accordion.Control>
        <AppStack style={{ gap: 0 }}>
          <AppFlexbox style={{ alignItems: 'center', gap: 10 }}>
            {isCompleted ? (
              <CircleCheck color="#40C057" size={25} />
            ) : (
              <AppStack
                style={{
                  width: 25,
                  height: 25,
                  justifyContent: 'center',
                  placeItems: 'center'
                }}
              >
                <AppStack
                  style={{
                    backgroundColor: 'lightgrey',
                    borderRadius: 15,
                    height: 15,
                    width: 15
                  }}
                />
              </AppStack>
            )}
            <AppText weight={500}>{label}</AppText>
          </AppFlexbox>
        </AppStack>
      </Accordion.Control>
      <Accordion.Panel>
        <AppStack style={{ gap: 10 }}>
          {createableInput &&
          (fkRegFormControlType === REG_FORM_CONTROL_TYPE_ENUM.DROP_DOWN ||
            fkRegFormControlType ===
              REG_FORM_CONTROL_TYPE_ENUM.BUTTON_GROUP) ? (
            <SelectCreatable
              clearable
              defaultData={regScoutReportQuestionOptions.map((p) => p.label)}
              onChange={onAssignValueChange}
              placeholder="Select a value to assign..."
              value={assignValue || ''}
            />
          ) : fkRegFormControlType === REG_FORM_CONTROL_TYPE_ENUM.DROP_DOWN ||
            fkRegFormControlType === REG_FORM_CONTROL_TYPE_ENUM.BUTTON_GROUP ? (
            <Select
              clearable
              data={regScoutReportQuestionOptions
                .sort((a, b) => a.sort - b.sort)
                .map((op) => ({
                  value:
                    op.pkRegScoutReportQuestionOption?.toString() || op.value,
                  label: op.value
                }))}
              onChange={onAssignValueChange}
              placeholder="Select a value to assign..."
              searchable
              style={{ flex: 1 }}
              value={
                regScoutReportQuestionOptions
                  .find(
                    (f) =>
                      f.value.trim().toLowerCase() ===
                      assignValue?.trim().toLowerCase()
                  )
                  ?.pkRegScoutReportQuestionOption.toString() ||
                assignValue ||
                null
              }
            />
          ) : (
            <TextInput
              onChange={(e) => onAssignValueChange(e.currentTarget.value)}
              placeholder="Enter a value to assign..."
              style={{ flex: 1 }}
              value={assignValue || ''}
            />
          )}
          <AppStack style={{ gap: 5 }}>
            <AppFlexbox style={{ justifyContent: 'space-between' }}>
              <AppText weight={500}>Players</AppText>
              {regScoutReportEvaluations.length > 0 && (
                <Checkbox
                  checked={areAllPlayersAssigned}
                  label={areAllPlayersAssigned ? 'Unselect All' : 'Select All'}
                  onChange={() => {
                    onAssignAll(
                      regScoutReportEvaluations,
                      areAllPlayersAssigned ? '' : assignValue
                    );
                  }}
                  style={{ fontWeight: 500 }}
                />
              )}
            </AppFlexbox>

            <PaginationList
              items={regScoutReportEvaluations
                .sort(
                  (a, b) =>
                    `${a.person.firstName}${a.person.lastName}`.localeCompare(
                      `${b.person.firstName}${b.person.lastName}`
                    ) ||
                    new Date(a.person.createdAt) - new Date(b.person.createdAt)
                )
                .map((e) => {
                  const evaluationAnswerValue = getEvaluationAnswer(e);

                  return (
                    <AppFlexbox
                      key={e.pkRegScoutReportEvaluation}
                      onClick={() =>
                        onAssign(
                          e,
                          evaluationAnswerValue === assignValue
                            ? ''
                            : assignValue
                        )
                      }
                      style={{
                        padding: 10,
                        alignItems: 'center',
                        gap: 10
                      }}
                    >
                      <Checkbox
                        checked={
                          (evaluationAnswerValue || null) ===
                          (assignValue || null)
                        }
                        onChange={() => {}}
                      />
                      <AppStack style={{ gap: 0 }}>
                        <AppText
                          style={{
                            fontWeight: 500,
                            lineHeight: '18px'
                          }}
                        >
                          {e.person.firstName} {e.person.lastName}
                        </AppText>
                        <AppText
                          style={{
                            fontSize: 14,
                            lineHeight: '16px',
                            color: evaluationAnswerValue ? 'grey' : 'red'
                          }}
                        >
                          {evaluationAnswerValue || 'Not Assigned'}
                        </AppText>
                      </AppStack>
                    </AppFlexbox>
                  );
                })}
              itemsPerPage={6}
              onPageChange={onPageChange}
              pageIndex={pageIndex}
            />
          </AppStack>
        </AppStack>
      </Accordion.Panel>
    </Accordion.Item>
  );
};

EvaluationSessionAttributesAccordionAssign.propTypes = {
  assignValue: PropTypes.string,
  createableInput: PropTypes.bool,
  fkRegFormControlType: PropTypes.number,
  getEvaluationAnswer: PropTypes.func,
  hideAssignedPlayers: PropTypes.bool,
  hideMatchingAnswerPlayers: PropTypes.bool,
  isCompleted: PropTypes.bool,
  label: PropTypes.string,
  onAssign: PropTypes.func,
  onAssignAll: PropTypes.func,
  onAssignValueChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onUpdateEvaluationAnswer: PropTypes.func,
  pageIndex: PropTypes.number,
  regScoutReportEvaluations: PropTypes.array,
  regScoutReportQuestionOptions: PropTypes.array,
  value: PropTypes.string
};

export default EvaluationSessionAttributesAccordionAssign;
