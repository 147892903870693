import React, { useContext, useEffect } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tournament } from 'tabler-icons-react';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import AppCard from '../../common/AppCard';
import TableView from '../../common/TableView';
import { formatUtcDate } from '../../../helpers/format';
import LeagueSeasonModal from './LeagueSeasonModal';
import { useModalState, useSortByFilter } from '../../../helpers/hooks';
import SendEmailModal from '../../common/SendEmailModal';
import SyncDivisionTeamsModal from './SyncDivisionTeamsModal';

const SEASON_ACTIONS_ENUM = {
  create: 'CREATE',
  edit: 'EDIT',
  duplicate: 'DUPLICATE',
  emailUsers: 'EMAIL',
  sync: 'SYNC',
  delete: 'DELETE'
};

const SEASON_ACTIONS = [
  {
    label: 'Edit',
    value: SEASON_ACTIONS_ENUM.edit
  },
  {
    label: 'Duplicate',
    value: SEASON_ACTIONS_ENUM.duplicate
  },
  {
    label: 'Email Users',
    value: SEASON_ACTIONS_ENUM.emailUsers
  },
  {
    label: 'Launch Team(s)',
    value: SEASON_ACTIONS_ENUM.sync
  },
  {
    label: 'Delete',
    value: SEASON_ACTIONS_ENUM.delete
  }
];

const TABLE_COLUMNS = [
  {
    label: 'Name',
    value: 'name',
    sortable: true
  },
  {
    label: 'End Date',
    value: 'date',
    sortable: true
  }
];

const LeagueSeasonsView = ({
  isLoading,
  regAssociation,
  modalAction,
  filters,
  filterState,
  setFilterState
}) => {
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const { state, fetchAdminRegAssociationSeasons } = useContext(
    RegistrationAdminContext
  );
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const {
    sortValue,
    isDescendingSort,
    onChangeSortBy,
    customFilterData
  } = useSortByFilter(TABLE_COLUMNS);

  useEffect(() => {
    if (modalAction === SEASON_ACTIONS_ENUM.create) {
      onOpenModal(modalAction);
    }
  }, [modalAction]);

  useEffect(() => {
    if (locationState?.openModal && !isLoading) {
      onOpenModal(SEASON_ACTIONS_ENUM.create);
    }
  }, [locationState?.openModal, isLoading]);

  return (
    <AppCard style={{ flex: 1, padding: 0, height: '100%' }}>
      <TableView
        columns={TABLE_COLUMNS}
        emptyMessage="No Seasons Available"
        filters={filters}
        isDescendingSort={isDescendingSort}
        isLoading={isLoading}
        lastUpdated={state.regAssociationSeasons.lastUpdated}
        onAction={(action, item) => {
          onOpenModal(
            action,
            state.regAssociationSeasons.value.find(
              (s) => s.pkRegAssociationSeason === item.key
            )
          );
        }}
        onChangeSortBy={onChangeSortBy}
        onRefresh={() =>
          fetchAdminRegAssociationSeasons(regAssociation.pkRegAssociation)
        }
        rows={state.regAssociationSeasons.value.map((s) => ({
          key: s.pkRegAssociationSeason,
          actions: SEASON_ACTIONS,
          columns: [
            {
              key: 1,
              label: s.name,
              onClick: () => onOpenModal(SEASON_ACTIONS_ENUM.edit, s),
              weight: 500,
              subLabel: `${s.regAssociationDivisions.length} Division(s)`,
              onSubLabelClick: () => {
                setFilterState({
                  ...filterState,
                  pkRegAssociationSeason: s.pkRegAssociationSeason.toString()
                });
                navigate('/admin/league/divisions');
              },
              icon: <Tournament size={30} />
            },
            {
              key: 2,
              label: `Ends ${dayjs(formatUtcDate(s.endDate)).format(
                'MMM D, YYYY'
              )}`,
              sortValue: new Date(s.endDate),
              sortValueType: 'date',
              showOnMobile: true
            }
          ]
        }))}
        sortBy={sortValue}
        sortFilter={customFilterData}
        tableTitle="Seasons"
      />

      <LeagueSeasonModal
        createCopy={modalState.action === SEASON_ACTIONS_ENUM.duplicate}
        isOpen={
          modalState.isOpen &&
          modalState.action !== SEASON_ACTIONS_ENUM.emailUsers &&
          modalState.action !== SEASON_ACTIONS_ENUM.sync
        }
        onClose={onCloseModal}
        regAssociation={regAssociation}
        regAssociationSeason={modalState.item}
        showDelete={modalState.action === SEASON_ACTIONS_ENUM.delete}
      />

      <SendEmailModal
        isOpen={
          modalState.isOpen &&
          modalState.action === SEASON_ACTIONS_ENUM.emailUsers
        }
        onClose={onCloseModal}
        pkRegAssociation={regAssociation?.pkRegAssociation}
        pkRegAssociationSeason={modalState.item?.pkRegAssociationSeason}
      />
      <SyncDivisionTeamsModal
        isOpen={
          modalState.isOpen && modalState.action === SEASON_ACTIONS_ENUM.sync
        }
        onClose={onCloseModal}
        regAssociationSeason={modalState.item}
      />
    </AppCard>
  );
};

LeagueSeasonsView.propTypes = {
  filterState: PropTypes.object,
  filters: PropTypes.array,
  isLoading: PropTypes.bool,
  modalAction: PropTypes.string,
  regAssociation: PropTypes.object,
  setFilterState: PropTypes.func
};

export default LeagueSeasonsView;
