import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Divider } from '@mantine/core';
import { AlertCircle } from 'tabler-icons-react';
import { useNavigate } from 'react-router-dom';
import AppTitle from '../../common/AppTitle';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppStack from '../../common/AppStack';
import PaginationList from '../../common/PaginationList';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import EvaluationSessionEntryItem from './EvaluationSessionEntryItem';
import { triggerNotification } from '../../../helpers/notificationHelper';
import { Context as AuthContext } from '../../../providers/AuthProvider';

const EvaluationSessionEvaluatorSelectModal = ({
  isOpen,
  onClose,
  regScoutReportEvaluationSession,
  regScoutReportEvaluationSessionEntries,
  evaluationTemplateMetricQuestions,
  regScoutReportEvaluations,
  regScoutReports,
  onSaveChanges
}) => {
  const { state: authState } = useContext(AuthContext);
  const navigate = useNavigate();
  const [formState, setFormState] = useState({ isLoading: false });

  const regAssociationAdmin = authState.regAssociationAdmins.value.find(
    (a) =>
      a.user.pkUser === authState.userData.user.pkUser &&
      !a.deleted &&
      a.regAssociation.pkRegAssociation ===
        regScoutReportEvaluationSession?.regAssociationDivision
          .regAssociationSeason.fkRegAssociation
  );

  const userHasExistingEntry = regScoutReportEvaluationSessionEntries?.some(
    (e) =>
      e.regAssociationAdmin.user.pkUser === authState.userData.user.pkUser &&
      !e.deleted
  );

  useEffect(() => {
    if (isOpen) {
      setFormState({ isLoading: false });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      onClose={onClose}
      opened={isOpen}
      title={<AppTitle order={2}>Select Evaluator</AppTitle>}
    >
      <AppStack style={{ gap: 25 }}>
        {!userHasExistingEntry && (
          <>
            <Alert color="blue" variant="outline">
              <AppFlexbox style={{ gap: 20, alignItems: 'center' }}>
                <AlertCircle color="red" size={30} />
                <AppStack style={{ gap: '0' }}>
                  <AppText weight={500}>
                    You are not assigned as an evaluator.
                  </AppText>
                  <AppText>
                    Please assign yourself or select an evaluator.
                  </AppText>
                </AppStack>
              </AppFlexbox>
            </Alert>

            <AppFlexbox
              style={{ alignItems: 'center', justifyContent: 'center' }}
            >
              <Button
                loading={formState.isLoading}
                onClick={() => {
                  setFormState({
                    ...formState,
                    isLoading: true
                  });
                  onSaveChanges(
                    (data) => {
                      const entry = data.regScoutReportEvaluationSessionEntries.find(
                        (e) =>
                          e.regAssociationAdmin.user.pkUser ===
                          authState.userData.user.pkUser
                      );
                      navigate(
                        `/admin/evaluations/sessions/${entry.fkRegScoutReportEvaluationSession}/evaluate/${entry.pkRegScoutReportEvaluationSessionEntry}`
                      );
                    },
                    (error) => {
                      setFormState({
                        ...formState,
                        isLoading: false
                      });
                      triggerNotification(error);
                    },
                    {
                      regScoutReportEvaluationSessionEntries: [
                        ...regScoutReportEvaluationSessionEntries,
                        {
                          fkRegAssociationAdmin:
                            regAssociationAdmin.pkRegAssociationAdmin,
                          canViewPlayerName: true,
                          canSelectPlayers: false,
                          canEditPlayerAttributes: false
                        }
                      ]
                    }
                  );
                }}
                style={{ maxWidth: 300, flex: 1 }}
                variant="outline"
              >
                Assign Yourself
              </Button>
            </AppFlexbox>
          </>
        )}

        <Divider
          label={
            userHasExistingEntry
              ? 'SELECT AN EVALUATOR'
              : 'OR SELECT AN EVALUATOR'
          }
          labelPosition="center"
        />

        <PaginationList
          items={
            regScoutReportEvaluationSessionEntries?.map((e) => (
              <EvaluationSessionEntryItem
                key={e.pkRegScoutReportEvaluationSessionEntry}
                onClick={
                  formState.isLoading
                    ? null
                    : () => {
                        navigate(
                          `/admin/evaluations/sessions/${e.fkRegScoutReportEvaluationSession}/evaluate/${e.pkRegScoutReportEvaluationSessionEntry}`
                        );
                      }
                }
                regScoutReportEvaluationSession={
                  regScoutReportEvaluationSession
                }
                regScoutReportEvaluationSessionEntry={e}
              />
            )) || []
          }
          itemsPerPage={4}
          listProps={{ style: { gap: 5 } }}
          noBorder
        />
      </AppStack>
    </ResponsiveModal>
  );
};

EvaluationSessionEvaluatorSelectModal.propTypes = {
  evaluationTemplateMetricQuestions: PropTypes.array,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSaveChanges: PropTypes.func,
  regScoutReportEvaluationSession: PropTypes.object,
  regScoutReportEvaluationSessionEntries: PropTypes.array,
  regScoutReportEvaluations: PropTypes.array,
  regScoutReports: PropTypes.array
};

export default EvaluationSessionEvaluatorSelectModal;
