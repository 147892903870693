import React from 'react';
import { PropTypes } from 'prop-types';
import { Grid, Checkbox, TextInput } from '@mantine/core';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const RegistrationFormFinancialAssistanceSection = ({
  isChecked,
  onCheck,
  onChange,
  financialAssistanceId,
  isDisabled
}) => {
  const mqIndex = useMediaQueryIndex();

  return (
    <AppStack
      style={{
        gap: 5,
        margin: rs(['0px 10px', '0px 10px', 0], mqIndex),
        cursor: isDisabled ? 'not-allowed' : 'normal'
      }}
    >
      <AppFlexbox style={{ display: 'inline-flex' }}>
        <AppText weight={700}>Financial Assistance</AppText>
      </AppFlexbox>

      <AppStack
        role="region"
        style={{
          border: 'solid 1px lightgrey',
          position: 'relative'
        }}
      >
        <Grid
          styles={{
            root: { margin: 10, overflow: 'visible' },
            inner: {
              margin: 0,
              padding: 0,
              width: 'unset',
              position: 'relative'
            },
            col: { padding: 0 }
          }}
        >
          <Grid.Col
            lg={12}
            md={12}
            role="gridcell"
            sm={12}
            style={{
              padding: 8,
              display: 'flex',
              justifyContent: 'start',
              placeItems: 'center',
              minHeight: 80,
              height: 'unset'
            }}
          >
            <AppText
              style={{
                flex: 1,
                fontSize: 16,
                overflow: 'hidden',
                minHeight: 80,
                height: 'unset',
                textOverflow: 'ellipsis',
                whiteSpace: 'pre-wrap'
              }}
            >
              Are you applying for financial assistance? The Jumpstart
              Individual Child Grant application (the “Application”) is an
              online application that a parent/guardian can use to submit a
              request for funding to Jumpstart on behalf of their
              child/children. Applications typically take between 6 to 8 weeks
              to process. You must provide an application number to proceed with
              financial assistance for this program.
            </AppText>
          </Grid.Col>
          <Grid.Col
            role="gridcell"
            span={12}
            style={{
              padding: 8,
              position: 'relative',
              display: 'flex',
              justifyContent: 'start',
              placeItems: 'center',
              height: 80
            }}
          >
            <Checkbox
              checked={isChecked}
              disabled={isDisabled}
              label={
                <AppText style={{ fontSize: 15 }} weight={500}>
                  I applied for financial assistance with Jumpstart
                </AppText>
              }
              onChange={(e) => onCheck(e.currentTarget.checked)}
              spacing="lg"
              style={{
                margin: 8,
                flex: 1
              }}
              styles={{label: { cursor: isDisabled ? 'not-allowed' : 'pointer' }}}
            />
          </Grid.Col>
          {isChecked && (
            <Grid.Col
              span={{
                sm: 12,
                md: 6,
                lg: 4
              }}
              style={{
                padding: 8,
                position: 'relative',
                display: 'flex',
                justifyContent: 'start',
                placeItems: 'center',
                height: 80
              }}
            >
              <TextInput
                disabled={isDisabled}
                label="Financial Assistance Id"
                onChange={(e) => onChange(e.currentTarget.value)}
                required
                style={{
                  margin: 8,
                  flex: 1
                }}
                styles={{
                  label: { cursor: isDisabled ? 'not-allowed' : 'pointer' }
                }}
                value={financialAssistanceId ?? ''}
              />
            </Grid.Col>
          )}
        </Grid>
      </AppStack>
    </AppStack>
  );
};

RegistrationFormFinancialAssistanceSection.propTypes = {
  financialAssistanceId: PropTypes.string,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  onCheck: PropTypes.func
};

export default RegistrationFormFinancialAssistanceSection;
