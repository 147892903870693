import React from 'react';
import { Menu } from '@mantine/core';
import PropTypes from 'prop-types';
import { Help } from 'tabler-icons-react';
import ActionableIcon from './ActionableIcon';
import MenuContent from './MenuContent';
import AppStack from './AppStack';

const SupportInfoMenu = ({ links, indexLink }) => (
  <MenuContent
    control={
      <AppStack style={{ position: 'relative' }}>
        <ActionableIcon
          color="dodgerblue"
          radius="xl"
          size={32}
          variant="subtle"
        >
          <Help size={32} />
        </ActionableIcon>
      </AppStack>
    }
  >
    <Menu.Label
      component="a"
      href={indexLink.href}
      style={{
        cursor: indexLink ? 'pointer' : 'default',
        textDecoration: 'none',
        color: '#000'
      }}
      target="_blank"
    >
      Support
    </Menu.Label>
    <Menu.Divider />
    {links.map((link) => (
      <Menu.Item key={link.href} component="a" href={link.href} target="_blank">
        {link.label}
      </Menu.Item>
    ))}
  </MenuContent>
);

SupportInfoMenu.propTypes = {
  indexLink: PropTypes.object,
  links: PropTypes.array
};

export default SupportInfoMenu;
