import React from 'react';
import { PropTypes } from 'prop-types';
import { FileInvoice } from 'tabler-icons-react';
import dayjs from 'dayjs';
import NumberFormat from 'react-number-format';
import ActionableListItem from '../../common/ActionableListItem';

const RegistrationPaymentInvoiceListItem = ({
  amount,
  createdAt,
  description,
  onAction
}) =>
  amount ? (
    <ActionableListItem
      description={
        <NumberFormat
          decimalScale={2}
          displayType="text"
          fixedDecimalScale
          prefix="$"
          thousandSeparator
          value={amount}
        />
      }
      indicator={<FileInvoice color="black" size="30" />}
      label={description}
      onClick={onAction}
      subLabel={dayjs(createdAt).format('MM/DD/YYYY')}
    />
  ) : (
    <ActionableListItem isLoading showImage />
  );

RegistrationPaymentInvoiceListItem.propTypes = {
  amount: PropTypes.number,
  createdAt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  description: PropTypes.string,
  onAction: PropTypes.func
};

export default RegistrationPaymentInvoiceListItem;
