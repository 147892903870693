import React, { useContext, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Context as RegistrationContext } from '../providers/RegistrationProvider';
import AcceptInviteView from '../components/content/invites/AcceptInviteView';
import InviteList from '../components/content/invites/InviteList';

const InvitesView = () => {
  const { fetchRegAssociationDivisionFormInvites } = useContext(
    RegistrationContext
  );

  useEffect(() => {
    fetchRegAssociationDivisionFormInvites();
  }, []);

  return (
    <Routes>
      <Route element={<InviteList />} path="/" />
      <Route element={<AcceptInviteView />} path="/accept/:inviteType/*" />
      <Route element={<Navigate replace to="/invites" />} path="*" />
    </Routes>
  );
};

export default InvitesView;
