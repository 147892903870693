import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import CheckoutItem from './CheckoutItem';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppCard from '../../common/AppCard';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import CheckoutMerchItem from './CheckoutMerchItem';
import { PAYMENT_PROVIDER_LINE_ITEM_TYPES } from '../../../config/constants';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import { triggerNotification } from '../../../helpers/notificationHelper';

const CheckoutItemList = ({
  regCart,
  isLoading,
  isDisabled,
  isCheckoutSubmitting,
  onRedeemCoupon
}) => {
  const { updateMerchPackagesInCart } = useContext(RegistrationContext);
  const mqIndex = useMediaQueryIndex();
  const deletedMerchPackages = regCart?.regCartMerchPackages.filter(
    (p) => p.merchPackage.deleted
  );

  return (
    <AppStack
      style={{
        flex: 2,
        gap: rs([10, 10, 20], mqIndex),
        marginBottom: rs([10, 10, 0], mqIndex),
        alignSelf: 'stretch'
      }}
    >
      {isLoading ? (
        !regCart || regCart?.cart.lineItems.length === 0 ? (
          <>
            <CheckoutItem />
            <CheckoutItem />
          </>
        ) : (
          regCart.cart.lineItems.map((i) => <CheckoutItem key={i.entityId} />)
        )
      ) : !regCart || regCart.cart.lineItems.length === 0 ? (
        <AppCard>
          <AppText weight={500}>Cart Empty</AppText>
        </AppCard>
      ) : (
        regCart.cart.lineItems.map((i) => {
          const regCartProduct = regCart.regCartProducts.find(
            (p) =>
              p.regFormSubmission.pkRegFormSubmission === i.entityId &&
              p.regProduct.pkRegProduct ===
                p.regFormSubmission.regAssociationDivisionForm.fkRegProduct &&
              i.lineItemType ===
                PAYMENT_PROVIDER_LINE_ITEM_TYPES.registration.value
          );

          let regCartMerchPackage = null;
          if (!regCartProduct) {
            regCartMerchPackage = regCart.regCartMerchPackages.find(
              (p) =>
                p.pkRegCartMerchPackage === i.entityId &&
                i.lineItemType === PAYMENT_PROVIDER_LINE_ITEM_TYPES.merch.value
            );
          }

          return regCartMerchPackage ? (
            <CheckoutMerchItem
              key={regCartMerchPackage.pkRegCartMerchPackage}
              isDisabled={isDisabled}
              isLoading={isLoading || isCheckoutSubmitting}
              lineItem={i}
              onUpdateMerchPackageVersion={() =>
                updateMerchPackagesInCart(
                  {
                    merchPackages: deletedMerchPackages.map((p) => ({
                      pkRegCartMerchPackage: p.pkRegCartMerchPackage,
                      merchPackageUuid: p.merchPackage.uuid,
                      inputAnswers:
                        regCartMerchPackage.regCartMerchPackageInputAnswers,
                      fkRegAssociation:
                        regCartMerchPackage.regAssociation.pkRegAssociation,
                      getLatestVersion: true
                    })),
                    showLoadingState: true
                  },
                  null,
                  (e) => {
                    triggerNotification(e);
                  }
                )
              }
              regCartMerchPackage={regCartMerchPackage}
            />
          ) : (
            <CheckoutItem
              key={i.entityId}
              cartProduct={regCartProduct}
              isDisabled={isDisabled}
              isLoading={isLoading || isCheckoutSubmitting}
              lineItem={i}
              onRedeemCoupon={(code) =>
                onRedeemCoupon(regCartProduct.pkRegCartProduct, code)
              }
            />
          );
        })
      )}
    </AppStack>
  );
};

CheckoutItemList.propTypes = {
  isCheckoutSubmitting: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onRedeemCoupon: PropTypes.func,
  regCart: PropTypes.object
};

export default CheckoutItemList;
