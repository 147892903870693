import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import AppStack from '../../common/AppStack';
import { links } from './links';
import AdminSidebarButton from './AdminSidebarButton';
import AppFlexbox from '../../common/AppFlexbox';
import SportsHeadzCircleLogo from '../../../images/sportsheads-logo.png';
import AppText from '../../common/AppText';
import AppImage from '../../common/AppImage';
import SUPPORT_LINKS from './supportLinks';
import FilterInput from '../../common/FilterInput';
import { getRegAssociationAdminPermissions } from '../../../helpers/adminHelper';
import { REG_PERMISSION_ENUM } from '../../../config/constants';
import { Context as AuthContext } from '../../../providers/AuthProvider';

const AdminSidebar = () => {
  const { pathname } = useLocation();
  const { state } = useContext(AuthContext);
  const adminPermissions = getRegAssociationAdminPermissions(
    state.regAssociationAdmins.value
  );
  const adminLinks = links.admin.filter(
    (link) =>
      adminPermissions.includes(REG_PERMISSION_ENUM.SUPER_ADMIN) ||
      link.permissions.some((p) => adminPermissions.includes(p))
  );

  return (
    <AppStack
      style={{
        width: '100%',
        maxWidth: 250,
        gap: 0
      }}
      visibleFrom="sm"
    >
      <AppStack
        style={{
          position: '-webkit-sticky',
          position: 'sticky',
          top: 90,
          flex: 1,
          gap: 0
        }}
      >
        <AppFlexbox
          style={{
            height: 70,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <AppFlexbox
            style={{
              alignItems: 'center',
              gap: 10
            }}
          >
            <AppImage height={50} src={SportsHeadzCircleLogo} width={50} />
            <AppStack
              style={{
                gap: 0,
                textAlign: 'center'
              }}
            >
              <AppText
                style={{ fontSize: 18, lineHeight: '20px', fontWeight: 700 }}
              >
                SportsHeadz
              </AppText>
              <AppText
                color="grey"
                style={{ fontSize: 14, lineHeight: '16px' }}
              >
                Registration
              </AppText>
            </AppStack>
          </AppFlexbox>
        </AppFlexbox>
        <AppStack
          style={{
            overflow: 'auto',
            maxHeight: 'calc(100vh - 160px)',
            flex: 1
          }}
        >
          <AppStack
            style={{
              padding: 5,
              paddingTop: 0,
              gap: 5,
              flex: 1
            }}
          >
            <FilterInput preventUrlUpdate type="association" />

            {adminLinks.map((link) => (
              <AdminSidebarButton
                key={link.to}
                isSelected={link.isSelected(pathname)}
                label={link.title}
                link={link.to}
              />
            ))}
          </AppStack>
          <AppStack
            style={{
              padding: 5,
              paddingBottom: 10,
              gap: 5
            }}
          >
            <AdminSidebarButton
              label="Support"
              link={SUPPORT_LINKS.index.href}
              target="_blank"
            />
          </AppStack>
        </AppStack>
      </AppStack>
    </AppStack>
  );
};

export default AdminSidebar;
