import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { Alert, Anchor, Button, Loader, useMantineTheme } from '@mantine/core';
import { AlertCircle } from 'tabler-icons-react';
import AppCard from '../../common/AppCard';
import AppStack from '../../common/AppStack';
import AppTitle from '../../common/AppTitle';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { Context as AuthContext } from '../../../providers/AuthProvider';

const AuthCardForm = ({
  onSubmit,
  title,
  submitText,
  submitSecondaryLink,
  reverseSubmit,
  links,
  error,
  hideError,
  isLoading,
  children,
  navigate
}) => {
  const { state } = useContext(AuthContext);
  const errorMessage = error || state.error;
  const mqIndex = useMediaQueryIndex();
  const theme = useMantineTheme();

  return (
    <AppCard
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(e);
      }}
      shadow="lg"
      style={{
        padding: 0,
        backgroundColor: '#FFF',
        borderRadius: rs([0, 0, 4], mqIndex),
        border: 'none',
        display: 'flex',
        flex: 1
      }}
    >
      <AppStack style={{ gap: 0, flex: 1 }}>
        <AppFlexbox
          style={{
            color: '#000',
            padding: rs(
              [
                '25px 20px 40px 20px',
                '25px 20px 40px 20px',
                '25px 65px 40px 65px'
              ],
              mqIndex
            ),
            display: 'flex',
            flex: 1
          }}
        >
          <AppStack
            style={{ gap: 20, maxWidth: '600px', margin: 'auto', flex: 1 }}
          >
            <AppTitle style={{ padding: 0, fontSize: 30 }}>{title}</AppTitle>
            {children}
            {!hideError && errorMessage && (
              <Alert
                color="red"
                icon={<AlertCircle size={16} />}
                radius="xs"
                style={{ marginTop: 15 }}
                variant="filled"
              >
                {errorMessage}
              </Alert>
            )}
            {isLoading && (
              <AppFlexbox style={{ marginTop: 15, justifyContent: 'center' }}>
                <Loader color="dark" type="dots" />
              </AppFlexbox>
            )}
          </AppStack>
        </AppFlexbox>
        <AppFlexbox
          style={{
            padding: rs(['30px 20px', '30px 20px', '30px 65px'], mqIndex),
            backgroundColor: theme.black,
            color: '#FFF',
            opacity: 0.8,
            fontWeight: 'bold'
          }}
        >
          <AppStack style={{ flex: 1, gap: 20, maxWidth: '600px' }}>
            <AppFlexbox
              style={{
                flex: 1,
                gap: 20,
                justifyContent: reverseSubmit ? 'space-between' : 'start',
                flexDirection: rs(
                  ['column', reverseSubmit ? 'row-reverse' : 'row'],
                  mqIndex
                )
              }}
            >
              <AppFlexbox
                style={{
                  flex: rs([1, 'none'], mqIndex),
                  display: 'flex',
                  width: rs(['100%', 'unset'], mqIndex)
                }}
              >
                <Button
                  color="blue"
                  disabled={isLoading}
                  radius={0}
                  size="md"
                  style={{ flex: rs([1, 'none'], mqIndex) }}
                  type="submit"
                  variant="filled"
                >
                  {submitText}
                </Button>
              </AppFlexbox>
              {submitSecondaryLink && (
                <AppFlexbox style={{ alignSelf: 'center' }}>
                  <Anchor onClick={submitSecondaryLink.onClick}>
                    <AppText color="dodgerblue" weight={700}>
                      {submitSecondaryLink.text}
                    </AppText>
                  </Anchor>
                </AppFlexbox>
              )}
            </AppFlexbox>

            {links &&
              links.map((link) => (
                <AppFlexbox
                  key={link.key}
                  style={{
                    gap: 20,
                    justifyContent: rs(['center', 'start'], mqIndex)
                  }}
                >
                  {link.LinkComponent ? (
                    link.LinkComponent
                  ) : (
                    <>
                      {link.secondaryText && (
                        <AppText weight={700}>{link.secondaryText}</AppText>
                      )}
                      <Anchor onClick={() => navigate('/auth/register')}>
                        <AppText color="dodgerblue" weight={700}>
                          {link.text}
                        </AppText>
                      </Anchor>
                    </>
                  )}
                </AppFlexbox>
              ))}
          </AppStack>
        </AppFlexbox>
      </AppStack>
    </AppCard>
  );
};

AuthCardForm.propTypes = {
  children: PropTypes.node,
  error: PropTypes.string,
  hideError: PropTypes.bool,
  isLoading: PropTypes.bool,
  links: PropTypes.array,
  navigate: PropTypes.func,
  onSubmit: PropTypes.func,
  reverseSubmit: PropTypes.bool,
  submitSecondaryLink: PropTypes.object,
  submitText: PropTypes.string,
  title: PropTypes.string
};

export default AuthCardForm;
