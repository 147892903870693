import React, { useEffect, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import SignatureCanvas from 'react-signature-canvas';
import { Button } from '@mantine/core';
import { urlToDataURL } from '../../../helpers/awsHelper';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppStack from '../../common/AppStack';
import FormSection from '../../common/FormSection';
import AppTitle from '../../common/AppTitle';

const SignatureControlModal = ({
  isDisabled,
  isOpen,
  onClose,
  onConfirm,
  base64String
}) => {
  const signatureRef = useRef(null);
  const [isEmpty, setIsEmpty] = useState(true);
  const [hasReset, setHasReset] = useState(true);

  useEffect(() => {
    if (isOpen) {
      if (signatureRef.current && !base64String) {
        signatureRef.current.clear();
        setIsEmpty(true);
      }
      setHasReset(false);
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      onClose={onClose}
      onMouseDown={(e) => e.stopPropagation()}
      opened={isOpen}
      size={600}
      title={
        <AppTitle>{isDisabled ? 'View Signature' : 'Draw Signature'}</AppTitle>
      }
    >
      <FormSection
        isSubmitHidden={isDisabled}
        onCancel={onClose}
        onSubmit={(e) => {
          e.stopPropagation();
          const signatureImg = signatureRef.current.toDataURL('image/png');
          onConfirm(signatureImg);
        }}
        submitTitle="Confirm Signature"
      >
        <AppStack
          onMouseMove={() => {
            if (isEmpty) {
              setIsEmpty(signatureRef.current.isEmpty());
            }
          }}
          onTouchMove={() => {
            if (isEmpty) {
              setIsEmpty(signatureRef.current.isEmpty());
            }
          }}
          style={{ border: 'solid 1px lightgrey', flex: 1 }}
        >
          <SignatureCanvas
            ref={(ref) => {
              if (ref) {
                signatureRef.current = ref;
                if (base64String && !hasReset) {
                  if (!base64String.startsWith('data:image/png;base64,')) {
                    urlToDataURL(base64String, (dataUrl) => {
                      signatureRef.current.fromDataURL(dataUrl);
                    });
                  }
                  else {
                    signatureRef.current.fromDataURL(base64String);
                  }
                  setIsEmpty(false);
                }

                if (isDisabled) {
                  signatureRef.current.off();
                }
              }
            }}
            canvasProps={{
              className: 'sigCanvas',
              style: { flex: 1, minHeight: 200 }
            }}
            penColor="black"
          />
        </AppStack>

        {!isDisabled && (
          <Button
            color="dark"
            disabled={isEmpty}
            onClick={() => {
              signatureRef.current.clear();
              setHasReset(true);
              setIsEmpty(true);
            }}
            style={{ width: '100%', maxWidth: 250, alignSelf: 'center' }}
          >
            Reset
          </Button>
        )}
      </FormSection>
    </ResponsiveModal>
  );
};

SignatureControlModal.propTypes = {
  base64String: PropTypes.string,
  isDisabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func
};

export default SignatureControlModal;
