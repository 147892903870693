import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import WaitlistModal from './WaitlistModal';
import RegistrantWaitlistItem from './RegistrantWaitlistItem';
import PaginationList from '../../common/PaginationList';
import HeaderView from '../../common/HeaderView';
import AppCardList from '../../common/AppCardList';

const RegistrantWaitlist = () => {
  const navigate = useNavigate();
  const { state } = useContext(RegistrationContext);
  const [registrantModal, setRegistrantModal] = useState({
    isOpen: false,
    waitlistPerson: null,
    showDelete: false
  });

  useEffect(() => {
    setRegistrantModal({
      isOpen: false,
      waitlistPerson: null,
      showDelete: false
    });
  }, []);

  return (
    <HeaderView maxViewWidth={1000}>
      <AppCardList title="My Waitlist">
        <PaginationList
          emptyMessage="No registrants on waitlist"
          isLoading={state.regWaitlistRegistrants.loading}
          items={state.regWaitlistRegistrants.value
            .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
            .map((p) => (
              <RegistrantWaitlistItem
                key={p.pkRegWaitlistPerson}
                onAction={(action, uuid) => {
                  if (uuid) {
                    navigate(`/checkout/${uuid}`);
                  }
                  else {
                    setRegistrantModal({
                      isOpen: true,
                      waitlistPerson: p,
                      showDelete: action === 'REMOVE'
                    });
                  }
                }}
                waitlistPerson={p}
              />
            ))}
          itemsPerPage={10}
          LoadingComponent={RegistrantWaitlistItem}
        />
      </AppCardList>
      <WaitlistModal
        isOpen={registrantModal.isOpen}
        onClose={() =>
          setRegistrantModal({
            ...registrantModal,
            isOpen: false
          })
        }
        regWaitlistPerson={registrantModal.waitlistPerson}
        showDelete={registrantModal.showDelete}
      />
    </HeaderView>
  );
};

export default RegistrantWaitlist;
