import React, { useContext, useEffect, useState } from 'react';
import { FileReport } from 'tabler-icons-react';
import PropTypes from 'prop-types';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import AppCard from '../../common/AppCard';
import { useModalState, useSortByFilter } from '../../../helpers/hooks';
import TableView from '../../common/TableView';
import CustomReportModal from './CustomReportModal';

const VIEW_ACTIONS_ENUM = {
  create: 'CREATE',
  edit: 'EDIT',
  delete: 'DELETE',
  generate: 'GENERATE'
};

const VIEW_ACTIONS = [
  {
    label: 'Generate',
    value: VIEW_ACTIONS_ENUM.generate
  },
  {
    label: 'Edit',
    value: VIEW_ACTIONS_ENUM.edit
  },
  {
    label: 'Delete',
    value: VIEW_ACTIONS_ENUM.delete
  }
];

const TABLE_COLUMNS = [
  { label: 'Name', value: 'name', sortable: true },
  { label: 'Form', value: 'form', sortable: true }
];

const SavedReportTemplateListView = ({
  isLoading,
  regAssociation,
  modalAction
}) => {
  const { state, fetchAdminRegReports } = useContext(RegistrationAdminContext);
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const [filterState, setFilterState] = useState({
    regFormUuid: null,
    search: ''
  });
  const {
    sortValue,
    isDescendingSort,
    onChangeSortBy,
    customFilterData
  } = useSortByFilter(TABLE_COLUMNS);

  const filterOptions = state.regAssociationSavedTemplates.value.reduce(
    (r, c) => {
      if (!r.regFormOptions.find((f) => f.value === c.regForm.uuid)) {
        r.regFormOptions.push({
          label: c.regForm.name,
          value: c.regForm.uuid
        });
      }
      return r;
    },
    { regFormOptions: [] }
  );

  useEffect(() => {
    if (modalAction === VIEW_ACTIONS_ENUM.create) {
      onOpenModal(modalAction);
    }
  }, [modalAction]);

  return (
    <AppCard style={{ flex: 1, padding: 0, height: '100%' }}>
      <TableView
        columns={TABLE_COLUMNS}
        emptyMessage="No Reports Available"
        filters={[
          {
            key: 2,
            label: 'Form',
            type: 'select',
            value: filterState.regFormUuid,
            placeholder: 'Filter by form',
            data: filterOptions.regFormOptions.sort((a, b) =>
              a.label.localeCompare(b.label)
            ),
            clearable: true,
            onChange: (value) => {
              setFilterState({
                ...filterState,
                regFormUuid: value
              });
            }
          },
          {
            key: 3,
            label: 'Report Name',
            type: 'text',
            value: filterState.search,
            placeholder: 'Filter by report name',
            onChange: (value) =>
              setFilterState({
                ...filterState,
                search: value
              })
          }
        ]}
        isDescendingSort={isDescendingSort}
        isLoading={isLoading}
        lastUpdated={state.regAssociationSavedTemplates.lastUpdated}
        onAction={(action, item) => {
          onOpenModal(
            action,
            state.regAssociationSavedTemplates.value.find(
              (s) => s.pkRegAssociationSavedTemplate === item.key
            )
          );
        }}
        onChangeSortBy={onChangeSortBy}
        onRefresh={() => fetchAdminRegReports(regAssociation.pkRegAssociation)}
        rows={state.regAssociationSavedTemplates.value
          .filter(
            (f) =>
              (!filterState.regFormUuid ||
                f.regForm.uuid === filterState.regFormUuid) &&
              (!filterState.search ||
                f.name
                  .replace(' ', '')
                  .toLowerCase()
                  .includes(filterState.search.replace(' ', '').toLowerCase()))
          )
          .map((r) => ({
            key: r.pkRegAssociationSavedTemplate,
            actions: VIEW_ACTIONS,
            columns: [
              {
                key: 1,
                label: r.name,
                weight: 500,
                subLabel: r.description,
                onClick: () => onOpenModal(VIEW_ACTIONS_ENUM.generate, r),
                icon: <FileReport color="black" size="30" />
              },
              {
                key: 2,
                label: r.regForm.name
              }
            ]
          }))}
        sortBy={sortValue}
        sortFilter={customFilterData}
        tableTitle="Saved Reports"
      />

      <CustomReportModal
        generateSavedTemplate={modalState.action === VIEW_ACTIONS_ENUM.generate}
        isOpen={modalState.isOpen}
        onClose={onCloseModal}
        pkRegAssociation={regAssociation?.pkRegAssociation}
        regAssociationSavedTemplate={modalState.item}
        showDelete={modalState.action === VIEW_ACTIONS_ENUM.delete}
      />
    </AppCard>
  );
};

SavedReportTemplateListView.propTypes = {
  isLoading: PropTypes.bool,
  modalAction: PropTypes.string,
  regAssociation: PropTypes.object
};

export default SavedReportTemplateListView;
