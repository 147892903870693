import { Radio } from '@mantine/core';
import { PropTypes } from 'prop-types';
import React, { useRef, useState } from 'react';
import { DatePickerInput } from '@mantine/dates';
import {
  Calendar,
  ChevronLeft,
  ChevronRight,
  FileDownload,
  FileUpload,
  Signature
} from 'tabler-icons-react';
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import classes from '../../../styles/nestedStyles.module.css';
import AppRadioGroup from '../../common/AppRadioGroup';

const FormControlSelector = ({ inputOptions, onSelect }) => {
  const mqIndex = useMediaQueryIndex();
  const swiperRef = useRef(null);
  const [swiperPage, setSwiperPage] = useState({
    index: 0,
    progress: 0
  });

  return (
    <AppStack
      style={{
        marginTop: rs([30, 50], mqIndex),
        marginBottom: rs([30, 60], mqIndex)
      }}
    >
      <AppText>Select the form control type from the options below</AppText>
      <AppFlexbox
        style={{
          flexWrap: 'nowrap',
          flexDirection: rs(['column', 'column', 'row'], mqIndex)
        }}
      >
        {swiperPage.index !== 0 && (
          <AppStack
            onClick={() => {
              swiperRef.current.swiper.slidePrev();
              setSwiperPage({
                index: swiperRef.current.swiper.realIndex,
                progress: swiperRef.current.swiper.progress
              });
            }}
            style={{
              alignSelf: 'stretch',
              padding: 20,
              cursor: 'pointer',
              display: rs(['none', 'none', 'flex'], mqIndex)
            }}
          >
            <ChevronLeft
              color="dodgerblue"
              size={40}
              style={{ margin: 'auto' }}
            />
          </AppStack>
        )}
        <Swiper
          ref={swiperRef}
          onSlideChange={(swiper) => {
            setSwiperPage({
              index: swiper.realIndex,
              progress: swiper.progress
            });
          }}
          onSwiper={(swiper) => {
            setSwiperPage({
              index: swiper.realIndex,
              progress: swiper.progress
            });
          }}
          slidesPerGroupAuto
          slidesPerView="auto"
          spaceBetween={40}
          style={{ width: '100%', maxHeight: 500 }}
        >
          {inputOptions.map((option) => (
            <SwiperSlide
              key={option.type}
              style={{ marginBottom: 40, width: 200 }}
            >
              <AppStack
                key={option.type}
                style={{ gap: 10, alignItems: 'center' }}
              >
                <AppFlexbox
                  className={classes['hover-action-blue']}
                  style={{
                    gap: 40,
                    cursor: 'pointer',
                    padding: 20,
                    flexWrap: 'nowrap',
                    border: 'solid 1px',
                    borderColor: 'lightgrey',
                    width: 200,
                    height: 200,
                    borderRadius: 20,
                    alignItems: 'center',
                    position: 'relative'
                  }}
                >
                  <AppStack
                    onClick={() => onSelect(option)}
                    style={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      top: 0,
                      cursor: 'pointer',
                      zIndex: 99
                    }}
                  />
                  {option.type === 'TEXT' ? (
                    <AppText style={{ margin: 'auto' }}>Text Message</AppText>
                  ) : option.type === 'DATE' ? (
                    <DatePickerInput
                      clearable
                      disabled
                      label="Label"
                      leftSection={<Calendar color="#000" size={16} />}
                      style={{ flex: 1 }}
                      styles={{
                        input: { cursor: 'pointer !important' },
                        label: { cursor: 'pointer' }
                      }}
                    />
                  ) : option.type === 'RADIO_GROUP' ? (
                    <AppRadioGroup
                      disabled
                      label="Label"
                      styles={{
                        input: { cursor: 'pointer !important' },
                        label: { cursor: 'pointer' }
                      }}
                      value="1"
                    >
                      <Radio label="Option 1 Label" value="1" />
                      <Radio label="Option 2 Label" value="2" />
                    </AppRadioGroup>
                  ) : option.type === 'FILE_DOWNLOAD' ? (
                    <AppStack
                      style={{
                        flex: 1,
                        justifyContent: 'center',
                        placeItems: 'center'
                      }}
                    >
                      <FileDownload color="#000" size={50} />
                    </AppStack>
                  ) : option.type === 'FILE_UPLOAD' ? (
                    <AppStack
                      style={{
                        flex: 1,
                        justifyContent: 'center',
                        placeItems: 'center'
                      }}
                    >
                      <FileUpload color="#000" size={50} />
                    </AppStack>
                  ) : option.type === 'SIGNATURE' ? (
                    <AppStack
                      style={{
                        flex: 1,
                        justifyContent: 'center',
                        placeItems: 'center'
                      }}
                    >
                      <Signature color="#000" size={50} />
                    </AppStack>
                  ) : (
                    <option.component
                      checked
                      data={[]}
                      disabled
                      label="Label"
                      styles={{
                        input: { cursor: 'pointer !important' },
                        label: { cursor: 'pointer' }
                      }}
                    />
                  )}
                </AppFlexbox>
                <AppText weight={700}>{option.title}</AppText>
              </AppStack>
            </SwiperSlide>
          ))}
        </Swiper>
        {swiperPage.progress < 1 && (
          <AppStack
            onClick={() => {
              swiperRef.current.swiper.slideNext();
              setSwiperPage({
                index: swiperRef.current.swiper.realIndex,
                progress: swiperRef.current.swiper.progress
              });
            }}
            style={{
              alignSelf: 'stretch',
              padding: 20,
              cursor: 'pointer',
              display: rs(['none', 'none', 'flex'], mqIndex)
            }}
          >
            <ChevronRight
              color="dodgerblue"
              size={40}
              style={{ margin: 'auto' }}
            />
          </AppStack>
        )}
      </AppFlexbox>
    </AppStack>
  );
};

FormControlSelector.propTypes = {
  inputOptions: PropTypes.array,
  onSelect: PropTypes.func
};

export default FormControlSelector;
