import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { List, Stack, TextInput } from '@mantine/core';
import AppStack from './AppStack';
import AppText from './AppText';
import FormSection from './FormSection';

const DeleteItemView = ({
  isLoading,
  itemLabel,
  onCancel,
  onDelete,
  warnings,
  confirmationValue,
  ...rest
}) => {
  const [formState, setFormState] = useState({ confirmation: '' });
  const confirmationRequired = warnings && warnings.length > 0;
  const confirmationText = confirmationValue || 'delete';

  useEffect(() => {
    setFormState({ confirmation: '' });
  }, []);

  return (
    <FormSection
      isLoading={isLoading}
      isSubmitDisabled={
        confirmationRequired && formState.confirmation !== confirmationText
      }
      onCancel={onCancel}
      onSubmit={onDelete}
      submitColor="red"
      submitTitle={`Delete ${itemLabel}`}
      {...rest}
    >
      {confirmationRequired ? (
        <AppStack>
          <Stack style={{ gap: 5 }}>
            <AppText color="grey">This will permanently delete:</AppText>
            <List withPadding>
              <List.Item>
                <AppText color="grey">The {itemLabel.toLowerCase()}.</AppText>
              </List.Item>
              {warnings.map((w) => (
                <List.Item key={w}>
                  <AppText color="grey">{w}</AppText>
                </List.Item>
              ))}
            </List>
          </Stack>

          <AppStack style={{ gap: 5 }}>
            <AppText color="grey">
              Type '{confirmationText}' to confirm.
            </AppText>
            <TextInput
              disabled={isLoading}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  confirmation: e.target.value
                })
              }
              placeholder={confirmationText}
              value={formState.confirmation}
            />
          </AppStack>
        </AppStack>
      ) : (
        <AppStack style={{ marginTop: 20 }}>
          <AppText style={{ textAlign: 'center' }}>
            Are you sure you want to delete this {itemLabel.toLowerCase()}?
          </AppText>
        </AppStack>
      )}
    </FormSection>
  );
};

DeleteItemView.propTypes = {
  confirmationValue: PropTypes.string,
  isLoading: PropTypes.bool,
  itemLabel: PropTypes.string,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  warnings: PropTypes.array
};

export default DeleteItemView;
