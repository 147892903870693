import { Button } from '@mantine/core';
import PropTypes from 'prop-types';
import React from 'react';
import { AlertCircle } from 'tabler-icons-react';
import { useNavigate } from 'react-router-dom';
import { formatUtcDate } from '../../../helpers/format';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';

const LeagueSeasonEndedNotification = ({
  pkRegAssociation,
  regAssociationSeason
}) => {
  const navigate = useNavigate();
  const hasSeasonEnded =
    regAssociationSeason &&
    new Date(formatUtcDate(regAssociationSeason.endDate)) <= new Date();

  return (
    <AppStack
      style={{
        gap: 20,
        padding: '30px 20px',
        placeItems: 'center',
        border: 'solid 1px lightgrey'
      }}
    >
      <AppFlexbox
        style={{
          gap: 20,
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <AlertCircle color="red" size={45} />
        <AppText style={{ textAlign: 'center' }}>
          {regAssociationSeason
            ? hasSeasonEnded
              ? 'The selected season has ended. Please create a new one.'
              : 'No division available for the selected season.'
            : 'No seasons available for the selected association.'}
        </AppText>
      </AppFlexbox>
      <Button
        color="blue"
        onClick={() => {
          const url =
            !regAssociationSeason || hasSeasonEnded
              ? `/admin/league/seasons?association=${pkRegAssociation}`
              : `/admin/league/divisions?association=${pkRegAssociation}`;
          navigate(url, { state: { openModal: true } });
        }}
        style={{ maxWidth: 250, width: '100%' }}
      >
        Create Season
      </Button>
    </AppStack>
  );
};

LeagueSeasonEndedNotification.propTypes = {
  pkRegAssociation: PropTypes.number,
  regAssociationSeason: PropTypes.object
};

export default LeagueSeasonEndedNotification;
