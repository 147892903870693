import React, { useContext, useEffect, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import SpecialRequestsModalSection from './SpecialRequestsModalSection';
import ResponsiveModal from '../../common/ResponsiveModal';

const SpecialRequestModal = ({ isOpen, regAssociationDivision, onClose }) => {
  const {
    state,
    fetchAdminRegSpecialRequests,
    fetchAdminRegAssociationDivisionRegistrants
  } = useContext(RegistrationAdminContext);
  const fetchedPkRegAssociationSeason = useRef(null);

  const divisionRegistrants = regAssociationDivision
    ? state.regAssociationDivisionRegistrants.value.filter(
        (d) =>
          d.regAssociationDivisionOverride?.pkRegAssociationDivision ===
            regAssociationDivision.pkRegAssociationDivision ||
          d.regAssociationDivisionForm.regAssociationDivision
            .pkRegAssociationDivision ===
            regAssociationDivision.pkRegAssociationDivision
      )
    : [];

  useEffect(() => {
    if (
      isOpen &&
      regAssociationDivision &&
      fetchedPkRegAssociationSeason.current !==
        regAssociationDivision.regAssociationSeason.pkRegAssociationSeason
    ) {
      fetchAdminRegSpecialRequests({
        pkRegAssociation:
          regAssociationDivision.regAssociationSeason.fkRegAssociation
      });
      fetchAdminRegAssociationDivisionRegistrants(
        regAssociationDivision.regAssociationSeason.fkRegAssociation,
        {
          pkRegAssociationSeason:
            regAssociationDivision.regAssociationSeason.pkRegAssociationSeason
        }
      );
      fetchedPkRegAssociationSeason.current =
        regAssociationDivision.regAssociationSeason.pkRegAssociationSeason;
    }
  }, [isOpen, regAssociationDivision]);

  return (
    <ResponsiveModal centered onClose={onClose} opened={isOpen} size={600}>
      <SpecialRequestsModalSection
        divisionRegistrants={divisionRegistrants}
        onCancel={onClose}
        onSubmit={onClose}
        pkRegAssociationDivision={
          regAssociationDivision?.pkRegAssociationDivision
        }
      />
    </ResponsiveModal>
  );
};

SpecialRequestModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  regAssociationDivision: PropTypes.object
};

export default SpecialRequestModal;
