import React, { useContext, useState } from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import UserSettingView from './UserSettingView';
import { Context as AuthContext } from '../../../providers/AuthProvider';
import SettingsModal from './SettingsModal';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';

const PersonalInfoView = ({ onBack }) => {
  const { state } = useContext(AuthContext);
  const [modalState, setModalState] = useState({
    isOpen: false,
    settingView: null
  });
  const { user } = state.userData;

  const userSettings = [
    {
      label: 'Profile Picture',
      value: 'avatar'
    },
    {
      label: 'Contact Info',
      description: (
        <AppFlexbox style={{ gap: 5 }}>
          <AppText style={{ fontSize: 14, color: 'grey' }}>
            {user.name},
          </AppText>
          <NumberFormat
            decimalScale={2}
            displayType="text"
            fixedDecimalScale
            format="+1 (###) ###-####"
            mask="_"
            style={{ fontSize: 14, color: 'grey' }}
            thousandSeparator
            value={user.phone}
          />
        </AppFlexbox>
      ),
      value: 'contact_info'
    }
  ];

  return (
    <>
      <UserSettingView
        description="SportsHeadz uses this information to verify your identity and to keep our community safe."
        onBack={onBack}
        onSelectSetting={(setting) =>
          setModalState({
            isOpen: true,
            settingView: setting.value
          })
        }
        settings={userSettings}
        title="Personal Details"
      />
      <SettingsModal
        isOpen={modalState.isOpen}
        onClose={() =>
          setModalState({
            ...modalState,
            isOpen: false
          })
        }
        settingView={modalState.settingView}
      />
    </>
  );
};

PersonalInfoView.propTypes = { onBack: PropTypes.func };

export default PersonalInfoView;
