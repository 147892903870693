import React from 'react';
import PropTypes from 'prop-types';
import { isDarkText } from '../../../helpers/format';
import AppCard from '../../common/AppCard';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppImage from '../../common/AppImage';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../../helpers/styles';

const AssociationSidebar = ({ sidebar, headerColor }) => {
  const mqIndex = useMediaQueryIndex();

  return (
    <AppCard
      component={sidebar.hyperlink ? 'a' : 'div'}
      href={
        sidebar.hyperlink && sidebar.hyperlink.match(/^http[s]?:\/\//)
          ? sidebar.hyperlink
          : `//${sidebar.hyperlink}`
      }
      shadow="lg"
      style={{ padding: '0px', borderRadius: rs([0, 0, 4], mqIndex) }}
      target="_blank"
    >
      {sidebar.title && (
        <AppStack
          style={{
            padding: 10,
            backgroundColor: headerColor,
            color: isDarkText(headerColor) ? '#000' : '#FFF',
            wordBreak: 'break-word',
            textAlign: 'center',
            minHeight: 25
          }}
        >
          <AppText weight={500}>{sidebar.title}</AppText>
        </AppStack>
      )}

      <AppStack
        style={{
          flex: 1,
          padding: sidebar.type === 'IMAGE' ? 0 : 10,
          placeItems: 'center',
          justifyContent: 'center',
          wordBreak: 'break-word',
          minHeight: 75,
          whiteSpace: 'pre-wrap'
        }}
      >
        {sidebar.type === 'TEXT' ? (
          <AppText>{sidebar.content}</AppText>
        ) : (
          <AppStack style={{ flex: 1 }}>
            {sidebar.imageUrl && (
              <AppImage height="unset" src={sidebar.imageUrl} width="100%" />
            )}
            {sidebar.content && (
              <AppText style={{ margin: 10 }}>{sidebar.content}</AppText>
            )}
          </AppStack>
        )}
      </AppStack>
    </AppCard>
  );
};

AssociationSidebar.propTypes = {
  headerColor: PropTypes.string,
  sidebar: PropTypes.object
};

export default AssociationSidebar;
