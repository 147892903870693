import React, { useContext, useEffect, useRef, useState } from 'react';
import { Divider } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import { triggerNotification } from '../../../helpers/notificationHelper';
import CheckoutOrderSummary from './CheckoutOrderSummary';
import CheckoutItemList from './CheckoutItemList';
import OfflinePaymentModal from './OfflinePaymentModal';
import HeaderView from '../../common/HeaderView';
import AppTitle from '../../common/AppTitle';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import AppFlexbox from '../../common/AppFlexbox';
import MerchPackageShowcase from '../merch/MerchPackageShowcase';
import AppCard from '../../common/AppCard';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import { ANALYTIC_MERCH_PACKAGE_SHOWCASE_LOCATION_ENUM } from '../../../config/constants';

const CheckoutCart = () => {
  const hasFetchedMerchPackages = useRef(false);
  const mqIndex = useMediaQueryIndex();
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search.toLowerCase());
  const secureId = searchParams.get('secureid');
  const {
    state,
    checkoutCart,
    fetchCart,
    fetchCheckoutResult,
    redeemProductCoupon,
    fetchMerchPackages
  } = useContext(RegistrationContext);
  const [loadingSecureId, setLoadingSecureId] = useState(false);
  const [checkoutState, setCheckoutState] = useState({
    paymentType: '',
    showOfflinePaymentModal: false,
    isLoading: false
  });
  const isLoading = state.regCart.loading || (secureId && loadingSecureId);
  const regAssociations = state.regCart.value
    ? [
        ...state.regCart.value.regCartMerchPackages.map(
          (p) => p.regAssociation
        ),
        ...state.regCart.value.regCartProducts.map(
          (p) => p.regProduct.regAssociation
        )
      ].reduce((r, c) => {
        if (!r.find((f) => f.pkRegAssociation === c.pkRegAssociation)) {
          r.push(c);
        }
        return r;
      }, [])
    : [];

  const merchEnabled = regAssociations.some((a) => a.merchEnabled);

  useEffect(() => {
    setCheckoutState({
      ...checkoutState,
      isLoading: false
    });
    if (
      !hasFetchedMerchPackages.current &&
      state.regCart.value &&
      regAssociations.length > 0
    ) {
      fetchMerchPackages(regAssociations.map((a) => a.pkRegAssociation));
      hasFetchedMerchPackages.current = true;
    }
  }, [state.regCart.value]);

  useEffect(() => {
    if (secureId) {
      setLoadingSecureId(true);
      fetchCheckoutResult(
        { secureId },
        (result) => {
          triggerNotification('Checkout Complete', 'Success', 'green');
          setLoadingSecureId(false);
          fetchCart();
          navigate('/checkout/cart');
        },
        (e) => {
          triggerNotification(e);
          setCheckoutState({
            ...checkoutState,
            isLoading: false
          });
          setLoadingSecureId(false);
          navigate('/checkout/cart');
        }
      );
    }
  }, [secureId]);

  const onError = (e) => {
    triggerNotification(e);
    setCheckoutState({
      ...checkoutState,
      isLoading: false
    });
  };

  const createCheckoutSession = (paymentType) => {
    const isCartCompleted =
      state.regCart.value.regCartProducts.length === 0 ||
      state.regCart.value.regCartProducts.every(
        (p) => p.regFormSubmission.isCompleted
      );

    const isMerchCompleted =
      state.regCart.value.regCartMerchPackages.length === 0 ||
      state.regCart.value.regCartMerchPackages.every((p) => {
        const requiredInputs = p.merchPackage.merchPackageInputs.filter(
          (i) => i.isRequired
        );
        return (
          requiredInputs.length === 0 ||
          requiredInputs.every((i) =>
            p.regCartMerchPackageInputAnswers.some(
              (a) => a.fkMerchPackageInput === i.pkMerchPackageInput
            )
          )
        );
      });

    if (
      state.regCart.value.regCartMerchPackages.length === 0 &&
      state.regCart.value.regCartProducts.length === 0
    ) {
      triggerNotification('Cart is empty');
    }
    else if (!isCartCompleted || !isMerchCompleted) {
      triggerNotification('Please complete all forms before checking out');
    }
    else if (paymentType === 'offline') {
      setCheckoutState({
        paymentType,
        showOfflinePaymentModal: true,
        isLoading: false
      });
    }
    else if (
      state.regCart.value.subtotalInCents !== 0 &&
      state.regCart.value.subtotalInCents / 100 < 10
    ) {
      triggerNotification('Minimum order amount of $10 required');
    }
    else {
      setCheckoutState({
        paymentType,
        showOfflinePaymentModal: false,
        isLoading: true
      });
      checkoutCart(
        { paymentType },
        (sessionUrl) => {
          if (sessionUrl) {
            window.location.href = sessionUrl;
          }
          else {
            triggerNotification('Checkout Successful', 'Success', 'green');
            fetchCart();
          }
        },
        onError
      );
    }
  };

  return (
    <HeaderView
      maxViewWidth={1300}
      viewProps={{ style: { gap: rs([0, 0, 20], mqIndex) } }}
    >
      <AppTitle
        style={{
          backgroundColor: rs(['#FFF', '#FFF', 'unset'], mqIndex),
          padding: rs([10, 10, 0], mqIndex)
        }}
      >
        Your Cart
      </AppTitle>
      <Divider style={{ display: rs(['flex', 'flex', 'none'], mqIndex) }} />

      <AppFlexbox
        style={{
          alignItems: 'start',
          gap: rs([10, 10, 40], mqIndex),
          flexDirection: rs(['column', 'column', 'column', 'row'], mqIndex),
          flexWrap: 'nowrap'
        }}
      >
        <CheckoutItemList
          isCheckoutSubmitting={checkoutState.isLoading}
          isLoading={isLoading}
          onRedeemCoupon={(pkRegCartProduct, code) => {
            setCheckoutState({
              ...checkoutState,
              isLoading: true
            });
            redeemProductCoupon(
              pkRegCartProduct,
              { code },
              () => {
                triggerNotification(
                  code ? 'Coupon redeemed' : 'Coupon removed',
                  'Success',
                  'green'
                );
                setCheckoutState({
                  ...checkoutState,
                  isLoading: false
                });
              },
              onError
            );
          }}
          regCart={state.regCart.value}
        />
        <CheckoutOrderSummary
          isCheckoutSubmitting={checkoutState.isLoading}
          isLoading={isLoading}
          onCheckout={createCheckoutSession}
          regAssociation={regAssociations[0]}
          regCart={state.regCart.value}
        />
      </AppFlexbox>

      {(hasFetchedMerchPackages.current || regAssociations.length > 0) &&
        merchEnabled && (
          <AppCard>
            <AppStack style={{ gap: 10, padding: '10px 20px' }}>
              <AppText style={{ fontSize: 22 }} weight={500}>
                Available Merch
              </AppText>
              <MerchPackageShowcase
                analyticCta={
                  ANALYTIC_MERCH_PACKAGE_SHOWCASE_LOCATION_ENUM.CART_FOOTER
                }
                isLoading={state.regCart.loading}
                regAssociationOptions={regAssociations}
              />
            </AppStack>
          </AppCard>
        )}

      <OfflinePaymentModal
        isOpen={checkoutState.showOfflinePaymentModal}
        onClose={() =>
          setCheckoutState({
            ...checkoutState,
            showOfflinePaymentModal: false
          })
        }
        regCart={state.regCart.value}
      />
    </HeaderView>
  );
};

export default CheckoutCart;
