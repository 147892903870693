import React, { useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Alert, Checkbox, Divider } from '@mantine/core';
import { AlertCircle, InfoCircle } from 'tabler-icons-react';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import RegistrationListItem from './RegistrationListItem';
import { triggerNotification } from '../../../helpers/notificationHelper';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppTitle from '../../common/AppTitle';
import AppStack from '../../common/AppStack';
import FormSection from '../../common/FormSection';
import AppText from '../../common/AppText';

const RegistrationDenyModal = ({
  regAssociation,
  isOpen,
  onClose,
  regFormSubmission,
  onRefresh
}) => {
  const { respondToFinancialRegistration } = useContext(
    RegistrationAdminContext
  );
  const [formState, setFormState] = useState({
    skipEmailNotice: false,
    loading: false,
    showConfirm: false
  });

  useEffect(() => {
    if (isOpen) {
      setFormState({
        skipEmailNotice: false,
        loading: false,
        showConfirm: false
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      onClose={onClose}
      opened={isOpen}
      size={600}
      title={<AppTitle order={2}>Deny Registration</AppTitle>}
    >
      <AppStack style={{ gap: 20 }}>
        <RegistrationListItem
          regAssociation={regAssociation}
          regFormSubmission={regFormSubmission}
        />

        {formState.showConfirm ? (
          <FormSection
            isLoading={formState.loading}
            onCancel={() =>
              setFormState({
                ...formState,
                showConfirm: false
              })
            }
            onSubmit={() => {
              setFormState({
                ...formState,
                loading: true
              });
              respondToFinancialRegistration(
                regFormSubmission.pkRegFormSubmission,
                {
                  status: 'DENIED',
                  skipEmailNotice: formState.skipEmailNotice
                },
                () => {
                  triggerNotification(
                    'Registration has been Denied',
                    'Success',
                    'green'
                  );
                  onClose();
                  if (onRefresh) {
                    onRefresh();
                  }
                },
                (message) => {
                  setFormState({
                    ...formState,
                    loading: false
                  });
                  triggerNotification(message);
                }
              );
            }}
            submitColor="red"
            submitTitle="Deny"
          >
            <AppStack style={{ gap: 20 }}>
              <Divider />
              <AppText style={{ textAlign: 'center' }}>
                Are you sure you want to <b>Deny</b> the selected registration?
              </AppText>

              <AppStack style={{ gap: 20 }}>
                <Divider />
                <Checkbox
                  checked={!formState.skipEmailNotice}
                  disabled={formState.loading}
                  label="Notify registrant"
                  onChange={(e) => {
                    setFormState({
                      ...formState,
                      skipEmailNotice: !e.currentTarget.checked
                    });
                  }}
                  styles={{
                    label: {
                      fontSize: 16,
                      fontWeight: 500
                    }
                  }}
                />
                <Alert
                  color={formState.skipEmailNotice ? 'yellow' : 'blue'}
                  icon={
                    formState.skipEmailNotice ? (
                      <AlertCircle style={{ width: 35, height: 35 }} />
                    ) : (
                      <InfoCircle style={{ width: 35, height: 35 }} />
                    )
                  }
                  styles={{ icon: { marginTop: 15, width: 35 } }}
                  variant="outline"
                >
                  {formState.skipEmailNotice ? (
                    <AppText>
                      The registrant will not be notified that their
                      registration has been denied.
                    </AppText>
                  ) : (
                    <AppText>
                      We will notify the registrant that their registration has
                      been denied.
                    </AppText>
                  )}
                </Alert>
              </AppStack>
            </AppStack>
          </FormSection>
        ) : (
          <>
            {regFormSubmission?.status !== 'ACCEPTED' && (
              <>
                <Divider />
                <AppText style={{ textAlign: 'center' }}>
                  This submission has outstanding charges/invoices that will be
                  automatically voided if you deny the submission. Would you
                  like to continue?
                </AppText>
              </>
            )}
            <FormSection
              isLoading={formState.loading}
              onCancel={onClose}
              onSubmit={() => {
                setFormState({
                  ...formState,
                  showConfirm: true
                });
              }}
              submitColor="red"
              submitTitle="Deny"
            />
          </>
        )}
      </AppStack>
    </ResponsiveModal>
  );
};

RegistrationDenyModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onRefresh: PropTypes.func,
  regAssociation: PropTypes.object,
  regFormSubmission: PropTypes.object
};
export default RegistrationDenyModal;
