import { PropTypes } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button } from '@mantine/core';
import { AlertCircle, InfoCircle } from 'tabler-icons-react';
import { useNavigate } from 'react-router-dom';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { triggerNotification } from '../../../helpers/notificationHelper';
import FormSection from '../../common/FormSection';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppTitle from '../../common/AppTitle';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';

const WaitlistModal = ({
  isOpen,
  onClose,
  regWaitlistPerson,
  showDelete,
  isAdmin
}) => {
  const navigate = useNavigate();
  const {
    removeAdminWaitlistPerson,
    sendAdminWaitlistReservation
  } = useContext(RegistrationAdminContext);
  const { removeWaitlistPerson } = useContext(RegistrationContext);
  const [isLoading, setIsLoading] = useState(false);

  const activeReservation = regWaitlistPerson?.waitlistReservations
    .filter((r) => !r.deleted)
    .sort((a, b) => new Date(a.expiryDate) - new Date(b.expiryDate))[0];

  const divisionInfo = regWaitlistPerson
    ? {
        regAssociationDivision:
          regWaitlistPerson.regAssociationDivisionForm.regAssociationDivision,
        waitlistDisabled: !regWaitlistPerson.regAssociationDivisionForm
          .regAssociationDivision.waitlistEnabled,
        pkRegAssociation:
          regWaitlistPerson.regAssociationDivisionForm.regProduct
            .fkRegAssociation
      }
    : {};

  useEffect(() => {
    if (isOpen) {
      setIsLoading(false);
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      centered
      onClose={onClose}
      opened={isOpen}
      size={600}
      title={
        <AppTitle order={2}>
          {showDelete
            ? 'Remove Registrant'
            : divisionInfo.waitlistDisabled
            ? 'Waitlist Disabled'
            : activeReservation
            ? 'Resend Reservation'
            : 'Send Reservation'}
        </AppTitle>
      }
    >
      {showDelete ? (
        <FormSection
          cancelTitle="Cancel"
          isLoading={isLoading}
          onCancel={onClose}
          onSubmit={() => {
            setIsLoading(true);
            if (isAdmin) {
              removeAdminWaitlistPerson(
                regWaitlistPerson.pkRegWaitlistPerson,
                () => {
                  onClose();
                  triggerNotification(
                    'Registrant removed from waitlist',
                    'Success',
                    'green'
                  );
                },
                () => {
                  setIsLoading(false);
                  triggerNotification();
                }
              );
            }
            else {
              removeWaitlistPerson(
                regWaitlistPerson.pkRegWaitlistPerson,
                () => {
                  onClose();
                  triggerNotification(
                    'Registrant removed from waitlist',
                    'Success',
                    'green'
                  );
                },
                () => {
                  setIsLoading(false);
                  triggerNotification();
                }
              );
            }
          }}
          submitColor="red"
          submitTitle="Remove Registrant"
        >
          {regWaitlistPerson && (
            <Alert
              color="red"
              icon={<AlertCircle size={35} />}
              radius="xs"
              style={{ padding: 20, marginTop: 20 }}
              styles={{
                wrapper: { alignItems: 'center' },
                icon: { width: 30, height: 25 }
              }}
              variant="outline"
            >
              <AppText style={{ fontSize: 16 }}>
                Are you sure you want to remove{' '}
                <b>
                  {regWaitlistPerson.regPerson.firstName}{' '}
                  {regWaitlistPerson.regPerson.lastName}
                </b>{' '}
                from{' '}
                <b>
                  {
                    regWaitlistPerson.regAssociationDivisionWaitlist
                      .regAssociationDivision.name
                  }
                </b>{' '}
                waitlist?
              </AppText>
            </Alert>
          )}
        </FormSection>
      ) : divisionInfo.waitlistDisabled ? (
        <FormSection
          isSubmitDisabled
          onCancel={onClose}
          submitTitle="Send Reservation"
        >
          <AppStack
            style={{
              gap: 20,
              padding: '30px 20px',
              placeItems: 'center',
              border: 'solid 1px lightgrey'
            }}
          >
            <AppFlexbox
              style={{
                gap: 20,
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <AlertCircle color="red" size={45} />
              <AppText style={{ textAlign: 'center' }} weight={500}>
                Waitlist must be enabled to send a reservation.
              </AppText>
            </AppFlexbox>
            <Button
              color="blue"
              onClick={() =>
                navigate(
                  `/admin/league/divisions?association=${divisionInfo.pkRegAssociation}&season=${divisionInfo.regAssociationDivision.fkRegAssociationSeason}`,
                  {
                    state: {
                      openModal: true,
                      pkRegAssociationDivision:
                        divisionInfo.regAssociationDivision
                          .pkRegAssociationDivision
                    }
                  }
                )
              }
              style={{ maxWidth: 250, width: '100%' }}
            >
              Edit Division
            </Button>
          </AppStack>
        </FormSection>
      ) : (
        <FormSection
          cancelTitle="Cancel"
          isLoading={isLoading}
          onCancel={onClose}
          onSubmit={() => {
            setIsLoading(true);
            sendAdminWaitlistReservation(
              regWaitlistPerson.pkRegWaitlistPerson,
              () => {
                onClose();
                triggerNotification('Reservation sent', 'Success', 'green');
              },
              (error) => {
                setIsLoading(false);
                triggerNotification(error);
              }
            );
          }}
          submitTitle={
            activeReservation ? 'Resend Reservation' : 'Send Reservation'
          }
        >
          {regWaitlistPerson && (
            <Alert
              color="blue"
              icon={<InfoCircle style={{ width: 35, height: 35 }} />}
              radius="xs"
              style={{ padding: 20, marginTop: 20 }}
              styles={{ icon: { marginTop: 15, width: 35 } }}
              variant="outline"
            >
              {activeReservation ? (
                <AppStack>
                  <AppText>
                    Are you sure you want to resend the reservation to{' '}
                    <b>
                      {regWaitlistPerson.regPerson.firstName}{' '}
                      {regWaitlistPerson.regPerson.lastName}
                    </b>{' '}
                    to register for{' '}
                    <b>
                      {
                        regWaitlistPerson.regAssociationDivisionWaitlist
                          .regAssociationDivision.name
                      }
                    </b>
                    ?
                  </AppText>
                </AppStack>
              ) : (
                <AppStack>
                  <AppText>
                    Are you sure you want to send{' '}
                    <b>
                      {regWaitlistPerson.regPerson.firstName}{' '}
                      {regWaitlistPerson.regPerson.lastName}
                    </b>{' '}
                    a reservation to register for{' '}
                    <b>
                      {
                        regWaitlistPerson.regAssociationDivisionWaitlist
                          .regAssociationDivision.name
                      }
                    </b>
                    ?
                  </AppText>
                  <AppText>
                    The registrant will be notified that the registration is
                    available for purchase.
                  </AppText>
                </AppStack>
              )}
            </Alert>
          )}
        </FormSection>
      )}
    </ResponsiveModal>
  );
};

WaitlistModal.propTypes = {
  isAdmin: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  regWaitlistPerson: PropTypes.object,
  showDelete: PropTypes.bool
};

export default WaitlistModal;
