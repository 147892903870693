import Auth from '@aws-amplify/auth';
import sportsheadzApi from '../apis/sportsheadzApi';
import createDataProvider from './createDataProvider';
import { registrationReducer } from './reducer';
import { triggerNotification } from '../helpers/notificationHelper';

const initialState = {
  paginatedRegCartMerchPackages: {
    pageIndex: 1,
    pageSize: 50,
    totalCount: 0,
    totalPages: 0,
    data: [],
    filter: null,
    loading: false,
    error: null
  },
  paginatedRegFormSubmissions: {
    pageIndex: 1,
    pageSize: 50,
    totalCount: 0,
    totalPages: 0,
    data: [],
    filter: null,
    loading: false,
    error: null
  },
  regAssociations: { value: [], loading: false, error: null },
  regAssociation: { value: null, loading: false, error: null },
  regAssociationAdmins: { value: [], loading: false, error: null },
  regAssociationAdminInvite: { value: null, loading: false, error: null },
  regAssociationAnalyticsReport: { value: null, loading: false, error: null },
  regAssociationRegistrants: { value: [], loading: false, error: null },
  regAssociationCoupons: { value: [], loading: false, error: null },
  regAssociationEvaluator: { value: null, loading: false, error: null },
  regAssociationEvaluators: { value: [], loading: false, error: null },
  regAssociationGroupCoupons: { value: [], loading: false, error: null },
  regAssociationSeasons: { value: [], loading: false, error: null },
  regAssociationDivisionFormExternalInvites: {
    value: [],
    loading: false,
    error: null
  },
  regAssociationDivisionFormInvites: { value: [], loading: false, error: null },
  regAssociationDivisions: { value: [], loading: false, error: null, type: '' },
  regAssociationDivisionRegistrants: { value: [], loading: false, error: null },
  regAssociationDivisionTeams: { value: [], loading: false, error: null },
  regAssociationDivisionTeamsSummary: {
    value: [],
    loading: false,
    error: null
  },
  regAssociationSavedTemplates: { value: [], loading: false, error: null },
  regCartMerchPackageFilters: { value: null, loading: false, error: null },
  regCartMerchPackageBalance: { value: null, loading: false, error: null },
  regEmailUnsubscribe: { value: [], loading: false, error: null },
  regForm: { value: null, loading: false, error: null },
  regForms: { value: [], loading: false, error: null },
  regFormVersionHistory: { value: null, loading: false, error: null },
  regFormSubmissionBalance: { value: null, loading: false, error: null },
  regFormSubmissionOrderHistory: { value: null, loading: false, error: null },
  regFormSubmissions: { value: [], loading: false, error: null },
  regFormSubmissionPaymentTransactionDetails: {
    value: null,
    loading: false,
    error: null
  },
  regFormSubmissionInvoiceTransactionDetails: {
    value: null,
    loading: false,
    error: null
  },
  regReportInputSources: { value: [], loading: false, error: null },
  regReportResult: { value: null, loading: false, error: null },
  regStaffReportResult: { value: null, loading: false, error: null },
  regReports: { value: [], loading: false, error: null },
  regSpecialRequests: { value: [], loading: false, error: null },
  regScoutReportPersons: { value: [], loading: false, error: null },
  regScoutReport: { value: null, loading: false, error: null },
  regScoutReports: { value: [], loading: false, error: null },
  regScoutReportEvaluationPlayers: { value: [], loading: false, error: null },
  regScoutReportEvaluationSession: { value: null, loading: false, error: null },
  regScoutReportEvaluationSessions: { value: [], loading: false, error: null },
  regWaitlistRegistrants: { value: [], loading: false, error: null },
  sesEmailSessions: { value: [], loading: false, error: null }
};

const defaultFetchListConfig = {
  resetStateWhenFetching: true,
  dataSelector: null,
  onErrorCallback: null,
  onSuccessCallback: null,
  triggerErrorNotification: true,
  filter: null,
  params: null
};

// #region Helpers
const getErrorMessage = (e) => {
  let error = 'Oops something went wrong!';
  let type = 'general';
  if (e?.response?.status === 409 && e.response.data) {
    error = e.response.data.error;
    type = e.response.data.type;
  }
  return { error, type };
};

const handleError = (
  e,
  stateName,
  dispatch,
  onErrorCallback = null,
  triggerErrorNotification = false
) => {
  const { error, type } = getErrorMessage(e);

  if (stateName && dispatch) {
    dispatch({
      type: 'error',
      stateName,
      payload: error
    });
  }

  if (triggerErrorNotification) {
    triggerNotification(error);
  }

  if (onErrorCallback) {
    onErrorCallback(error, type);
  }
};

const fetchListResource = async (
  dispatch,
  stateName,
  apiPath,
  config = defaultFetchListConfig
) => {
  const fetchConfig = {
    ...defaultFetchListConfig,
    ...config
  };
  const fetchFilter =
    config.filter || config.params
      ? { ...config.filter, ...config.params }
      : null;

  try {
    dispatch({
      type: 'fetching',
      stateName,
      payload: fetchConfig.resetStateWhenFetching
        ? { value: [], filter: fetchFilter }
        : null
    });

    const response = await sportsheadzApi.get(apiPath, {
      params: fetchConfig.params,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    });

    dispatch({
      type: 'success',
      stateName,
      payload: {
        filter: fetchFilter,
        value: fetchConfig.dataSelector
          ? response.data[fetchConfig.dataSelector]
          : response.data
      }
    });

    if (fetchConfig.onSuccessCallback) {
      fetchConfig.onSuccessCallback(response.data);
    }

    return response;
  } catch (e) {
    handleError(
      e,
      stateName,
      dispatch,
      fetchConfig.onErrorCallback,
      fetchConfig.triggerErrorNotification
    );
  }
  return null;
};
// #endregion

// #region RegAssociations
const fetchAdminRegAssociations = (dispatch) => async (
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regAssociations'
    });

    const response = await sportsheadzApi.get(
      '/api/registration/admin/associations',
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regAssociations',
      payload: { value: response.data }
    });

    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch {
    dispatch({
      type: 'error',
      stateName: 'regAssociations',
      payload: 'Oops something went wrong'
    });
    if (onErrorCallback) {
      onErrorCallback();
    }
  }
};

const fetchAdminRegAssociationLeagueOptions = (dispatch) => async (
  pkRegAssociation,
  { includeDeleted } = {}
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regAssociation'
    });

    const response = await sportsheadzApi.get(
      `/api/registration/${pkRegAssociation}/league-options`,
      {
        params: { includeDeleted },
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regAssociation',
      payload: { value: response.data }
    });
  } catch {
    dispatch({
      type: 'error',
      stateName: 'regAssociation',
      payload: 'Oops something went wrong'
    });
  }
};

const updateRegAssociation = (dispatch) => async (
  pkRegAssociation,
  {
    primaryColor,
    secondaryColor,
    supportEmail,
    treasurerAlertsEmail,
    sortOverride,
    heroImageUrl,
    logoImageUrl,
    lightModeTheme,
    fkRegFormSortType,
    fkCurrencyType,
    isSortDescending,
    sandboxEnabled,
    socials,
    sidebars
  },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.put(
      `/api/registration/${pkRegAssociation}`,
      {
        heroImageUrl,
        logoImageUrl,
        primaryColor,
        secondaryColor,
        supportEmail,
        treasurerAlertsEmail,
        sortOverride,
        lightModeTheme,
        fkRegFormSortType,
        fkCurrencyType,
        isSortDescending,
        sandboxEnabled,
        socials,
        sidebars
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'replace',
      stateName: 'regAssociations',
      payload: {
        value: response.data,
        filter: (f) => f.pkRegAssociation !== response.data.pkRegAssociation
      }
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, 'regAssociations', dispatch, onErrorCallback);
  }
};

const onboardPaymentProvider = (dispatch) => async (
  pkRegAssociation,
  {
    accountId,
    accountPassword,
    regAssociationPaymentProviderType,
    flatRateFee,
    percentageRateFee,
    rebasePaymentFees
  },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.post(
      `/api/payment/registration/onboard/${pkRegAssociation}`,
      {
        accountId,
        accountPassword,
        regAssociationPaymentProviderType,
        flatRateFee,
        percentageRateFee,
        rebasePaymentFees
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch (e) {
    handleError(e, null, null, onErrorCallback);
  }
};

const disconnectPaymentProvider = (dispatch) => async (
  pkRegAssociation,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.post(
      `/api/payment/registration/onboard/disconnect?pkRegAssociation=${pkRegAssociation}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch (e) {
    handleError(e, null, null, onErrorCallback);
  }
};
// #endregion

// #region Reports
const fetchAdminRegReports = (dispatch) => async (
  pkRegAssociation,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regReports',
      payload: {
        value: [],
        filter: { pkRegAssociation }
      }
    });

    const response = await fetchListResource(
      dispatch,
      'regAssociationSavedTemplates',
      `/api/registration/admin/association/reports/${pkRegAssociation}`,
      {
        filter: { pkRegAssociation },
        onSuccessCallback,
        onErrorCallback,
        dataSelector: 'regAssociationSavedTemplates'
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regReports',
      payload: {
        value: response.data.regReportTemplates,
        filter: { pkRegAssociation }
      }
    });
  } catch {
    dispatch({
      type: 'error',
      stateName: 'regReports',
      payload: 'Oops something went wrong'
    });
  }
};

const fetchAdminRegReportInputSources = (dispatch) => async (
  pkRegAssociation,
  pkRegReportTemplate
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regReportInputSources'
    });
    const response = await sportsheadzApi.get(
      `/api/registration/admin/association/reports/sources/${pkRegAssociation}/${pkRegReportTemplate}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'replace',
      stateName: 'regReportInputSources',
      payload: {
        value: response.data,
        filter: (f) =>
          !response.data.some(
            (d) =>
              d.type === f.type && d.fkRegAssociation === f.fkRegAssociation
          )
      }
    });
  } catch {
    dispatch({
      type: 'error',
      stateName: 'regReportInputSources',
      payload: 'Oops something went wrong'
    });
  }
};

const generateAdminRegAssociationReport = (dispatch) => async (
  { pkRegAssociation, pkRegReportTemplate, inputAnswers },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regReportResult'
    });
    const response = await sportsheadzApi.post(
      `/api/registration/admin/association/reports/generate/${pkRegReportTemplate}`,
      { pkRegAssociation, inputAnswers },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regReportResult',
      payload: { value: response.data }
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, 'regReportResult', dispatch, onErrorCallback);
  }
};

const generateStaffRegistrationReport = (dispatch) => async (
  {
    reportGuid,
    pkRegAssociation,
    inputAnswers,
    startDate,
    endDate,
    includeTesting
  },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.post(
      `/api/staff/registration/reports/generate`,
      {
        reportGuid,
        pkRegAssociation,
        inputAnswers,
        startDate,
        endDate,
        includeTesting
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regStaffReportResult',
      payload: { value: response.data }
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    if (onErrorCallback) {
      onErrorCallback();
    }
  }
};

const createAdminRegAssociationSavedTemplate = (dispatch) => async (
  { name, description, fkRegForm, regAssociationSavedTemplateFormInputs },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regAssociationSavedTemplates'
    });
    const response = await sportsheadzApi.post(
      `/api/registration/admin/reports/saved-templates`,
      { name, description, fkRegForm, regAssociationSavedTemplateFormInputs },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'append',
      stateName: 'regAssociationSavedTemplates',
      payload: response.data
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, 'regAssociationSavedTemplates', dispatch, onErrorCallback);
  }
};

const updateAdminRegAssociationSavedTemplate = (dispatch) => async (
  pkRegAssociationSavedTemplate,
  { name, description, regAssociationSavedTemplateFormInputs },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regAssociationSavedTemplates'
    });
    const response = await sportsheadzApi.put(
      `/api/registration/admin/reports/saved-templates/${pkRegAssociationSavedTemplate}`,
      { name, description, regAssociationSavedTemplateFormInputs },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'replace',
      stateName: 'regAssociationSavedTemplates',
      payload: {
        value: response.data,
        filter: (f) =>
          f.pkRegAssociationSavedTemplate !== pkRegAssociationSavedTemplate
      }
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, 'regAssociationSavedTemplates', dispatch, onErrorCallback);
  }
};

const deleteAdminRegAssociationSavedTemplate = (dispatch) => async (
  pkRegAssociationSavedTemplate,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regAssociationSavedTemplates'
    });
    await sportsheadzApi.delete(
      `/api/registration/admin/reports/saved-templates/${pkRegAssociationSavedTemplate}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'remove',
      stateName: 'regAssociationSavedTemplates',
      payload: (t) =>
        t.pkRegAssociationSavedTemplate !== pkRegAssociationSavedTemplate
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, 'regAssociationSavedTemplates', dispatch, onErrorCallback);
  }
};

const generateAdminRegAssociationSavedTemplate = (dispatch) => async (
  pkRegAssociationSavedTemplate,
  { inputAnswers },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regReportResult'
    });
    const response = await sportsheadzApi.post(
      `/api/registration/admin/reports/saved-templates/${pkRegAssociationSavedTemplate}/generate`,
      { inputAnswers },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regReportResult',
      payload: { value: response.data }
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, 'regReportResult', dispatch, onErrorCallback);
  }
};

const generateAdminRegAssociationAnalyticsReport = (dispatch) => async (
  pkRegAssociation,
  {
    uuid,
    startDate,
    endDate,
    compareStartDate,
    compareEndDate,
    periodInterval,
    groupBy,
    compareBy,
    headers,
    options
  },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regAssociationAnalyticsReport'
    });
    const response = await sportsheadzApi.post(
      `/api/registration/admin/association/${pkRegAssociation}/analytics/generate/${uuid}`,
      {
        startDate,
        endDate,
        compareStartDate,
        compareEndDate,
        periodInterval,
        groupBy,
        compareBy,
        headers,
        options
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regAssociationAnalyticsReport',
      payload: { value: response.data }
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, 'regAssociationAnalyticsReport', dispatch, onErrorCallback);
  }
};
// #endregion

// #region Coupons
const fetchAdminRegAssociationCoupons = (dispatch) => async (
  pkRegAssociation,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regAssociationCoupons',
      payload: {
        value: [],
        filter: { pkRegAssociation }
      }
    });

    const response = await fetchListResource(
      dispatch,
      'regAssociationGroupCoupons',
      `/api/registration/admin/association/coupons/${pkRegAssociation}`,
      {
        filter: { pkRegAssociation },
        onSuccessCallback,
        onErrorCallback,
        dataSelector: 'groupCoupons'
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regAssociationCoupons',
      payload: {
        value: response.data.coupons,
        filter: { pkRegAssociation }
      }
    });
  } catch {
    dispatch({
      type: 'error',
      stateName: 'regAssociationCoupons',
      payload: 'Oops something went wrong'
    });
  }
};

const createAdminRegAssociationCoupon = (dispatch) => async (
  pkRegAssociation,
  {
    code,
    maxRedemptions,
    flatDiscount,
    percentageDiscount,
    fkRegAssociationSeason,
    fkRegAssociationDivision,
    fkRegAssociationDivisionForm
  },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.post(
      `/api/registration/admin/association/coupons/${pkRegAssociation}`,
      {
        code,
        maxRedemptions,
        flatDiscount,
        percentageDiscount,
        fkRegAssociationSeason,
        fkRegAssociationDivision,
        fkRegAssociationDivisionForm
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    if (onSuccessCallback) {
      onSuccessCallback();
    }
    dispatch({
      type: 'append',
      stateName: 'regAssociationCoupons',
      payload: response.data
    });
  } catch (e) {
    handleError(e, 'regAssociationCoupons', dispatch, onErrorCallback);
  }
};

const updateAdminRegAssociationCoupon = (dispatch) => async (
  pkRegAssociationCoupon,
  {
    maxRedemptions,
    fkRegAssociationSeason,
    fkRegAssociationDivision,
    fkRegAssociationDivisionForm,
    reinstate
  },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.put(
      `/api/registration/admin/association/coupons/${pkRegAssociationCoupon}`,
      {
        maxRedemptions,
        fkRegAssociationSeason,
        fkRegAssociationDivision,
        fkRegAssociationDivisionForm,
        reinstate
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'replace',
      stateName: 'regAssociationCoupons',
      payload: {
        value: response.data,
        filter: (f) =>
          f.pkRegAssociationCoupon !== response.data.pkRegAssociationCoupon
      }
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, 'regAssociationCoupons', dispatch, onErrorCallback);
  }
};

const expireAdminRegAssociationCoupon = (dispatch) => async (
  pkRegAssociationCoupon,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.delete(
      `/api/registration/admin/association/coupons/${pkRegAssociationCoupon}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    if (onSuccessCallback) {
      onSuccessCallback();
    }
    dispatch({
      type: 'replace',
      stateName: 'regAssociationCoupons',
      payload: {
        value: response.data,
        filter: (f) =>
          f.pkRegAssociationCoupon !== response.data.pkRegAssociationCoupon
      }
    });
  } catch (e) {
    handleError(e, 'regAssociationCoupons', dispatch, onErrorCallback);
  }
};

const createAdminRegAssociationGroupCoupon = (dispatch) => async (
  pkRegAssociation,
  {
    code,
    maxRedemptions,
    flatDiscount,
    percentageDiscount,
    divisionForms,
    minimumQualifiedAmount,
    qualifyPerItem
  },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.post(
      `/api/registration/admin/association/group-coupons/${pkRegAssociation}`,
      {
        code,
        maxRedemptions,
        flatDiscount,
        percentageDiscount,
        divisionForms,
        minimumQualifiedAmount,
        qualifyPerItem
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    if (onSuccessCallback) {
      onSuccessCallback();
    }
    dispatch({
      type: 'append',
      stateName: 'regAssociationGroupCoupons',
      payload: response.data
    });
  } catch (e) {
    handleError(e, 'regAssociationGroupCoupons', dispatch, onErrorCallback);
  }
};

const updateAdminRegAssociationGroupCoupon = (dispatch) => async (
  pkRegAssociationGroupCoupon,
  { maxRedemptions, minimumQualifiedAmount, divisionForms, reinstate },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.put(
      `/api/registration/admin/association/group-coupons/${pkRegAssociationGroupCoupon}`,
      {
        maxRedemptions,
        divisionForms,
        minimumQualifiedAmount,
        reinstate
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'replace',
      stateName: 'regAssociationGroupCoupons',
      payload: {
        value: response.data,
        filter: (f) =>
          f.pkRegAssociationGroupCoupon !==
          response.data.pkRegAssociationGroupCoupon
      }
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, 'regAssociationGroupCoupons', dispatch, onErrorCallback);
  }
};

const expireAdminRegAssociationGroupCoupon = (dispatch) => async (
  pkRegAssociationCoupon,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.delete(
      `/api/registration/admin/association/group-coupons/${pkRegAssociationCoupon}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    if (onSuccessCallback) {
      onSuccessCallback();
    }
    dispatch({
      type: 'replace',
      stateName: 'regAssociationGroupCoupons',
      payload: {
        value: response.data,
        filter: (f) =>
          f.pkRegAssociationGroupCoupon !==
          response.data.pkRegAssociationGroupCoupon
      }
    });
  } catch (e) {
    handleError(e, 'regAssociationGroupCoupons', dispatch, onErrorCallback);
  }
};
// #endregion

// #region Association League
const fetchRegAssociationDivisionTeams = (dispatch) => async (
  {
    pkRegAssociation,
    pkRegAssociationSeason,
    pkRegAssociationDivision,
    summaryOnly
  },
  onSuccessCallback,
  onErrorCallback
) => {
  await fetchListResource(
    dispatch,
    summaryOnly
      ? 'regAssociationDivisionTeamsSummary'
      : 'regAssociationDivisionTeams',
    `/api/registration/admin/league/teams`,
    {
      params: {
        pkRegAssociation,
        pkRegAssociationSeason,
        pkRegAssociationDivision,
        summaryOnly
      },
      onSuccessCallback,
      onErrorCallback
    }
  );
};

const importRegAssociationDivisionTeams = (dispatch) => async (
  pkRegAssociation,
  { teams },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.post(
      `/api/registration/admin/league/teams/import/${pkRegAssociation}`,
      { teams },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'append',
      stateName: 'regAssociationDivisionTeams',
      payload: response.data
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, 'regAssociationDivisionTeams', dispatch, onErrorCallback);
  }
};

const createAdminRegAssociationDivisionTeam = (dispatch) => async (
  { name, fkRegAssociationDivision, regAssociationDivisionTeamPersons },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.post(
      `/api/registration/admin/league/teams`,
      {
        name,
        fkRegAssociationDivision,
        regAssociationDivisionTeamPersons
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'append',
      stateName: 'regAssociationDivisionTeams',
      payload: response.data
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, 'regAssociationDivisionTeams', dispatch, onErrorCallback);
  }
};

const updateAdminRegAssociationDivisionTeam = (dispatch) => async (
  pkRegAssociationDivisionTeam,
  { name, fkRegAssociationDivision, regAssociationDivisionTeamPersons },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.put(
      `/api/registration/admin/league/teams/${pkRegAssociationDivisionTeam}`,
      {
        name,
        fkRegAssociationDivision,
        regAssociationDivisionTeamPersons
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );

    dispatch({
      type: 'replace',
      stateName: 'regAssociationDivisionTeams',
      payload: {
        value: response.data,
        filter: (f) =>
          f.pkRegAssociationDivisionTeam !==
          response.data.pkRegAssociationDivisionTeam
      }
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, 'regAssociationDivisionTeams', dispatch, onErrorCallback);
  }
};

const deleteAdminRegAssociationDivisionTeam = (dispatch) => async (
  pkRegAssociationDivisionTeam,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    await sportsheadzApi.delete(
      `/api/registration/admin/league/teams/${pkRegAssociationDivisionTeam}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'remove',
      stateName: 'regAssociationDivisionTeams',
      payload: (t) =>
        t.pkRegAssociationDivisionTeam !== pkRegAssociationDivisionTeam
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, 'regAssociationDivisionTeams', dispatch, onErrorCallback);
  }
};

const generateAdminRegAssociationDivisionTeams = (dispatch) => async (
  {
    pkRegAssociationDivision,
    pkRegScoutReportEvaluationSessions,
    teams,
    attributes
  },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    await sportsheadzApi.post(
      `/api/registration/admin/league/teams/generate`,
      {
        pkRegAssociationDivision,
        pkRegScoutReportEvaluationSessions,
        teams,
        attributes
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, 'regAssociationDivisionTeams', dispatch, onErrorCallback);
  }
};

const fetchAdminRegAssociationDivisionRegistrants = (dispatch) => async (
  pkRegAssociation,
  { pkRegAssociationDivision, pkRegAssociationSeason },
  onSuccessCallback,
  onErrorCallback
) => {
  await fetchListResource(
    dispatch,
    'regAssociationDivisionRegistrants',
    `/api/registration/admin/league/registrants/${pkRegAssociation}`,
    {
      filter: { pkRegAssociation },
      params: {
        pkRegAssociationDivision,
        pkRegAssociationSeason
      },
      onSuccessCallback,
      onErrorCallback
    }
  );
};

const fetchAdminRegAssociationDivisions = (dispatch) => async (
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regAssociationDivisions'
    });
    const response = await sportsheadzApi.get(
      `/api/registration/form/divisions`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regAssociationDivisions',
      payload: { value: response.data, type: 'form-divisions' }
    });

    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, 'regAssociationDivisions', dispatch, onErrorCallback);
  }
};

const fetchUpdatedAdminRegAssociationDivision = (dispatch) => async (
  pkRegAssociationDivision,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regAssociationDivisions'
    });
    const response = await sportsheadzApi.get(
      `/api/registration/admin/league/divisions/${pkRegAssociationDivision}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'replace',
      stateName: 'regAssociationDivisions',
      payload: {
        value: response.data,
        filter: (f) =>
          f.pkRegAssociationDivision !== response.data.pkRegAssociationDivision
      }
    });

    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(
      e,
      'regAssociationDivisionRegistrants',
      dispatch,
      onErrorCallback
    );
  }
};

const fetchAdminRegAssociationSeasons = (dispatch) => async (
  pkRegAssociation,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regAssociationDivisions',
      payload: {
        value: [],
        filter: { pkRegAssociation }
      }
    });
    const response = await fetchListResource(
      dispatch,
      'regAssociationSeasons',
      `/api/registration/admin/league/seasons/${pkRegAssociation}`,
      { onSuccessCallback, onErrorCallback, filter: { pkRegAssociation } }
    );

    dispatch({
      type: 'success',
      stateName: 'regAssociationDivisions',
      payload: {
        filter: { pkRegAssociation },
        value: response.data.reduce(
          (result, current) => result.concat(current.regAssociationDivisions),
          []
        ),
        type: 'league'
      }
    });
  } catch {
    dispatch({
      type: 'error',
      stateName: 'regAssociationDivisions',
      payload: 'Oops something went wrong'
    });
  }
};

const createAdminRegAssociationSeason = (dispatch) => async (
  { name, endDate, fkRegAssociation, pkRegAssociationSeasonDuplicate },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.post(
      `/api/registration/admin/league/seasons`,
      {
        name,
        endDate,
        fkRegAssociation,
        pkRegAssociationSeasonDuplicate
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'append',
      stateName: 'regAssociationSeasons',
      payload: response.data
    });

    if (response.data.regAssociationDivisions.length > 0) {
      dispatch({
        type: 'append',
        stateName: 'regAssociationDivisions',
        payload: response.data.regAssociationDivisions
      });
    }

    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, 'regAssociationSeasons', dispatch, onErrorCallback);
  }
};

const updateAdminRegAssociationSeason = (dispatch) => async (
  pkRegAssociationSeason,
  { name, endDate, fkRegAssociation },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.put(
      `/api/registration/admin/league/seasons/${pkRegAssociationSeason}`,
      {
        name,
        endDate,
        fkRegAssociation
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'replace',
      stateName: 'regAssociationSeasons',
      payload: {
        value: response.data,
        filter: (f) =>
          f.pkRegAssociationSeason !== response.data.pkRegAssociationSeason
      }
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, 'regAssociationSeasons', dispatch, onErrorCallback);
  }
};

const deleteAdminRegAssociationSeason = (dispatch) => async (
  pkRegAssociationSeason,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    await sportsheadzApi.delete(
      `/api/registration/admin/league/seasons/${pkRegAssociationSeason}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'remove',
      stateName: 'regAssociationSeasons',
      payload: (f) => f.pkRegAssociationSeason !== pkRegAssociationSeason
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, 'regAssociationSeasons', dispatch, onErrorCallback);
  }
};

const createAdminRegAssociationDivision = (dispatch) => async (
  {
    name,
    minDob,
    maxDob,
    registrantLimit,
    waitlistExpireDays,
    waitlistEnabled,
    fkRegAssociationSeason,
    fkRegGenderType,
    roles,
    rolesRequired,
    orderEmails,
    notifyWaitlist,
    notifyWaitlistAmount,
    expireWaitlistReservations,
    offlinePaymentsEnabled,
    isInviteOnly,
    isAllAges
  },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.post(
      `/api/registration/admin/league/divisions`,
      {
        name,
        minDob,
        maxDob,
        registrantLimit,
        waitlistExpireDays,
        waitlistEnabled,
        fkRegAssociationSeason,
        fkRegGenderType,
        roles,
        rolesRequired,
        orderEmails,
        notifyWaitlist,
        notifyWaitlistAmount,
        expireWaitlistReservations,
        offlinePaymentsEnabled,
        isInviteOnly,
        isAllAges
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'append',
      stateName: 'regAssociationDivisions',
      payload: response.data
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, 'regAssociationDivisions', dispatch, onErrorCallback);
  }
};

const updateAdminRegAssociationDivision = (dispatch) => async (
  pkRegAssociationDivision,
  {
    name,
    minDob,
    maxDob,
    registrantLimit,
    waitlistExpireDays,
    waitlistEnabled,
    fkRegAssociationSeason,
    fkRegGenderType,
    roles,
    rolesRequired,
    orderEmails,
    notifyWaitlist,
    notifyWaitlistAmount,
    expireWaitlistReservations,
    offlinePaymentsEnabled,
    isInviteOnly,
    isAllAges
  },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.put(
      `/api/registration/admin/league/divisions/${pkRegAssociationDivision}`,
      {
        name,
        minDob,
        maxDob,
        registrantLimit,
        waitlistExpireDays,
        waitlistEnabled,
        roles,
        rolesRequired,
        orderEmails,
        fkRegAssociationSeason,
        fkRegGenderType,
        notifyWaitlist,
        notifyWaitlistAmount,
        expireWaitlistReservations,
        offlinePaymentsEnabled,
        isInviteOnly,
        isAllAges
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'replace',
      stateName: 'regAssociationDivisions',
      payload: {
        value: response.data,
        filter: (f) =>
          f.pkRegAssociationDivision !== response.data.pkRegAssociationDivision
      }
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, 'regAssociationDivisions', dispatch, onErrorCallback);
  }
};

const deleteAdminRegAssociationDivision = (dispatch) => async (
  pkRegAssociationDivision,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    await sportsheadzApi.delete(
      `/api/registration/admin/league/divisions/${pkRegAssociationDivision}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'remove',
      stateName: 'regAssociationDivisions',
      payload: (f) => f.pkRegAssociationDivision !== pkRegAssociationDivision
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, 'regAssociationDivisions', dispatch, onErrorCallback);
  }
};

const fetchAdminRegSpecialRequests = (dispatch) => async (
  {
    pkRegAssociation,
    pkRegAssociationSeason,
    pkRegAssociationDivision,
    pkRegAssociationDivisionTeam
  },
  onSuccessCallback,
  onErrorCallback
) => {
  await fetchListResource(
    dispatch,
    'regSpecialRequests',
    `/api/registration/admin/league/divisions/special-request`,
    {
      params: {
        pkRegAssociation,
        pkRegAssociationSeason,
        pkRegAssociationDivision,
        pkRegAssociationDivisionTeam
      },
      onSuccessCallback,
      onErrorCallback
    }
  );
};

const updateAdminRegSpecialRequests = (dispatch) => async (
  pkRegAssociationDivision,
  { regSpecialRequests },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.put(
      `/api/registration/admin/league/divisions/${pkRegAssociationDivision}/special-request`,
      regSpecialRequests,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'replace',
      stateName: 'regSpecialRequests',
      payload: {
        filter: (r) =>
          r.regFormSubmission1.regAssociationDivisionOverride
            ? r.regFormSubmission1.regAssociationDivisionOverride.pkRegAssociationDivision.toString() !==
              pkRegAssociationDivision.toString()
            : r.regFormSubmission1.regAssociationDivisionForm.regAssociationDivision.pkRegAssociationDivision.toString() !==
              pkRegAssociationDivision.toString(),
        value: response.data
      }
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, 'regSpecialRequests', dispatch, onErrorCallback);
  }
};

const fetchAdminRegAssociationRegistrants = (dispatch) => async (
  pkRegAssociation,
  { season, division, divisionTeam },
  onSuccessCallback,
  onErrorCallback
) => {
  await fetchListResource(
    dispatch,
    'regAssociationRegistrants',
    `/api/registration/admin/registrants/${pkRegAssociation}`,
    {
      filter: { pkRegAssociation },
      params: { season, division, divisionTeam },
      onSuccessCallback,
      onErrorCallback
    }
  );
};
// #endregion

// #region RegForms
const fetchAdminRegForm = (dispatch) => async (uuid) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regForm'
    });

    const response = await sportsheadzApi.get(
      `/api/registration/form/regForm/${uuid}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regForm',
      payload: { value: response.data }
    });
  } catch {
    dispatch({
      type: 'error',
      stateName: 'regForm',
      payload: 'Oops something went wrong'
    });
  }
};

const fetchAdminRegFormVersionHistory = (dispatch) => async (uuid) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regFormVersionHistory'
    });

    const response = await sportsheadzApi.get(
      `/api/registration/form/${uuid}/version-history`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regFormVersionHistory',
      payload: { value: response.data }
    });
  } catch {
    dispatch({
      type: 'error',
      stateName: 'regFormVersionHistory',
      payload: 'Oops something went wrong'
    });
  }
};

const fetchAdminRegForms = (dispatch) => async (
  onSuccessCallback,
  onErrorCallback
) => {
  await fetchListResource(dispatch, 'regForms', `/api/registration/form`, {
    onSuccessCallback,
    onErrorCallback
  });
};

const fetchPaginatedRegFormSubmissions = (dispatch) => async (
  pkRegAssociation,
  {
    season,
    division,
    form,
    status,
    search,
    page,
    pageSize,
    sortBy,
    orderBy
  } = {},
  appendResults = false
) => {
  try {
    if (!appendResults) {
      dispatch({
        type: 'fetching',
        stateName: 'paginatedRegFormSubmissions',
        payload: {
          value: [],
          filter: {
            season,
            division,
            form,
            status,
            search,
            page,
            pageSize,
            sortBy,
            orderBy
          }
        }
      });
    }

    const response = await sportsheadzApi.get(
      `/api/registration/admin/form-submissions`,
      {
        params: {
          pkRegAssociation,
          season,
          division,
          form,
          status,
          search,
          page,
          pageSize,
          sortBy,
          orderBy
        },
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );

    dispatch({
      type: appendResults ? 'paginated-append' : 'success',
      stateName: 'paginatedRegFormSubmissions',
      payload: {
        pageIndex: response.data.pageIndex,
        pageSize: response.data.pageSize,
        totalCount: response.data.totalCount,
        totalPages: response.data.totalPages,
        data: response.data.data
      }
    });
  } catch {
    triggerNotification('Oops something went wrong');
    dispatch({
      type: 'error',
      stateName: 'paginatedRegFormSubmissions',
      payload: 'Oops something went wrong'
    });
  }
};

const fetchAdminFinancialRegistrations = (dispatch) => async (
  pkRegAssociation,
  { coupon, groupCoupon, season },
  onSuccessCallback,
  onErrorCallback
) => {
  await fetchListResource(
    dispatch,
    'regFormSubmissions',
    `/api/registration/admin/registrations`,
    {
      params: { pkRegAssociation, coupon, groupCoupon, season },
      onSuccessCallback,
      onErrorCallback
    }
  );
};

const fetchUpdatedAdminFinancialRegistration = (dispatch) => async (
  pkRegFormSubmission,
  onSuccessCallback,
  hideLoading = false
) => {
  try {
    if (!hideLoading) {
      dispatch({
        type: 'fetching',
        stateName: 'regFormSubmissions'
      });
      dispatch({
        type: 'fetching',
        stateName: 'paginatedRegFormSubmissions'
      });
    }

    const response = await sportsheadzApi.get(
      `/api/registration/admin/registrations/${pkRegFormSubmission}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'replace',
      stateName: 'regFormSubmissions',
      payload: {
        value: response.data,
        filter: (s) =>
          s.pkRegFormSubmission !== response.data.pkRegFormSubmission
      }
    });
    dispatch({
      type: 'paginated-replace-index',
      stateName: 'paginatedRegFormSubmissions',
      payload: {
        value: response.data,
        filter: (s) =>
          s.pkRegFormSubmission === response.data.pkRegFormSubmission
      }
    });

    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch {
    dispatch({
      type: 'error',
      stateName: 'regFormSubmissions',
      payload: 'Oops something went wrong'
    });
    dispatch({
      type: 'error',
      stateName: 'paginatedRegFormSubmissions',
      payload: 'Oops something went wrong'
    });
  }
};

const respondToFinancialRegistration = (dispatch) => async (
  pkRegFormSubmission,
  { status, skipEmailNotice, reinstate },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.post(
      `/api/registration/admin/registrations/${pkRegFormSubmission}/respond`,
      { status, skipEmailNotice, reinstate },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'replace',
      stateName: 'regFormSubmissions',
      payload: {
        value: response.data,
        filter: (s) =>
          s.pkRegFormSubmission !== response.data.pkRegFormSubmission
      }
    });
    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch (e) {
    handleError(e, 'regFormSubmissions', dispatch, onErrorCallback);
  }
};

const changeRegistrationDivision = (dispatch) => async (
  pkRegFormSubmission,
  pkRegAssociationDivision,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.post(
      `/api/registration/admin/registrations/${pkRegFormSubmission}/division?pkRegAssociationDivision=${pkRegAssociationDivision}`,
      pkRegAssociationDivision,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'replace',
      stateName: 'regFormSubmissions',
      payload: {
        value: response.data,
        filter: (s) =>
          s.pkRegFormSubmission !== response.data.pkRegFormSubmission
      }
    });
    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch (e) {
    handleError(e, 'regFormSubmissions', dispatch, onErrorCallback);
  }
};

const changeRegistrationOverrideEmail = (dispatch) => async (
  pkRegFormSubmission,
  { overrideEmail },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.post(
      `/api/registration/admin/registrations/${pkRegFormSubmission}/change-email`,
      null,
      {
        params: { overrideEmail },
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'replace',
      stateName: 'regFormSubmissions',
      payload: {
        value: response.data,
        filter: (s) =>
          s.pkRegFormSubmission !== response.data.pkRegFormSubmission
      }
    });
    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch (e) {
    handleError(e, 'regFormSubmissions', dispatch, onErrorCallback);
  }
};

const refundFinancialRegistration = (dispatch) => async (
  pkRegFormSubmission,
  {
    skipWaitlist,
    partialRefundAmount,
    deactivateRegPerson,
    excludeGroupCouponAmount,
    refundWithoutTransferReverse
  },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.post(
      `/api/payment/registration/cart/${pkRegFormSubmission}/refund`,
      {
        skipWaitlist,
        partialRefundAmount,
        deactivateRegPerson,
        excludeGroupCouponAmount,
        refundWithoutTransferReverse
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'replace',
      stateName: 'regFormSubmissions',
      payload: {
        value: response.data,
        filter: (s) =>
          s.pkRegFormSubmission !== response.data.pkRegFormSubmission
      }
    });
    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch (e) {
    handleError(e, 'regFormSubmissions', dispatch, onErrorCallback);
  }
};

const fetchAdminRegFormSubmissionOrderHistory = (dispatch) => async (
  pkRegFormSubmission,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regFormSubmissionOrderHistory'
    });
    const response = await sportsheadzApi.get(
      `/api/registration/admin/registrations/${pkRegFormSubmission}/order-history`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regFormSubmissionOrderHistory',
      payload: { value: response.data }
    });
    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch (e) {
    handleError(e, 'regFormSubmissionOrderHistory', dispatch, onErrorCallback);
  }
};

const sendAdminRegFormSubmissionOrderReceipt = (dispatch) => async (
  orderReceiptRequests,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    await sportsheadzApi.post(
      `/api/registration/admin/registrations/submissions/order-receipts`,
      orderReceiptRequests,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, null, null, onErrorCallback);
  }
};

const fetchAdminRegFormSubmissionPayments = (dispatch) => async (
  pkRegFormSubmission,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regFormSubmissionBalance'
    });
    const response = await sportsheadzApi.get(
      `/api/registration/admin/registrations/${pkRegFormSubmission}/balance`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regFormSubmissionBalance',
      payload: { value: response.data }
    });
    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch (e) {
    handleError(e, 'regFormSubmissionBalance', dispatch, onErrorCallback);
  }
};

const fetchAdminRegFormSubmissionPaymentTransactionDetails = (
  dispatch
) => async (pkRegFormSubmission, onSuccessCallback, onErrorCallback) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regFormSubmissionPaymentTransactionDetails'
    });

    const response = await sportsheadzApi.get(
      `/api/registration/admin/registrations/${pkRegFormSubmission}/payment/transaction-details`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
    dispatch({
      type: 'success',
      stateName: 'regFormSubmissionPaymentTransactionDetails',
      payload: { value: response.data }
    });
  } catch (e) {
    handleError(
      e,
      'regFormSubmissionPaymentTransactionDetails',
      dispatch,
      onErrorCallback
    );
  }
};

const fetchAdminRegFormSubmissionInvoiceTransactionDetails = (
  dispatch
) => async (pkRegFormSubmissionInvoice, onSuccess, onErrorCallback) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regFormSubmissionInvoiceTransactionDetails'
    });

    const response = await sportsheadzApi.get(
      `/api/payment/registration/invoice/${pkRegFormSubmissionInvoice}/transaction-details`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    if (onSuccess) {
      onSuccess();
    }
    dispatch({
      type: 'success',
      stateName: 'regFormSubmissionInvoiceTransactionDetails',
      payload: { value: response.data }
    });
  } catch (e) {
    handleError(
      e,
      'regFormSubmissionInvoiceTransactionDetails',
      dispatch,
      onErrorCallback
    );
  }
};

const createAdminRegFormSubmissionPayment = (dispatch) => async (
  pkRegFormSubmission,
  {
    amount,
    fkRegFormSubmissionPaymentType,
    note,
    skipEmailNotice,
    fkRegFormSubmissionInvoice
  },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.post(
      `/api/registration/admin/registrations/${pkRegFormSubmission}/payment`,
      {
        amount,
        fkRegFormSubmissionPaymentType,
        note,
        skipEmailNotice,
        fkRegFormSubmissionInvoice
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regFormSubmissionBalance',
      payload: { value: response.data }
    });
    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch (e) {
    handleError(e, 'regFormSubmissionBalance', dispatch, onErrorCallback);
  }
};

const createAdminRegForm = (dispatch) => async (
  {
    name,
    pkRegFormCopy,
    startDate,
    endDate,
    description,
    fkRegAssociation,
    financialAssistanceType,
    baseCost,
    taxRate,
    enabled,
    sections,
    divisions
  },
  successCallback,
  onErrorCallback
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regForm'
    });

    const response = await sportsheadzApi.post(
      `/api/registration/form`,
      {
        name,
        pkRegFormCopy,
        startDate,
        endDate,
        description,
        fkRegAssociation,
        financialAssistanceType,
        baseCost,
        taxRate,
        enabled,
        sections,
        divisions
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regForm',
      payload: { value: response.data }
    });
    if (successCallback) {
      successCallback(response.data);
    }
  } catch (e) {
    handleError(e, 'regForm', dispatch, onErrorCallback);
  }
};

const updateAdminRegForm = (dispatch) => async (
  uuid,
  {
    name,
    startDate,
    endDate,
    description,
    fkRegAssociation,
    financialAssistanceType,
    baseCost,
    taxRate,
    enabled,
    sections,
    divisions
  },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.put(
      `/api/registration/form/${uuid}`,
      {
        name,
        startDate,
        endDate,
        description,
        fkRegAssociation,
        financialAssistanceType,
        baseCost,
        taxRate,
        enabled,
        sections,
        divisions
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regForm',
      payload: { value: response.data }
    });
    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch (e) {
    handleError(e, 'regForm', dispatch, onErrorCallback);
  }
};

const deleteAdminRegForm = (dispatch) => async (
  uuid,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    await sportsheadzApi.delete(`/api/registration/form/${uuid}`, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
    dispatch({
      type: 'remove',
      stateName: 'regForms',
      payload: (t) => t.uuid !== uuid
    });
  } catch (e) {
    handleError(e, 'regForm', dispatch, onErrorCallback);
  }
};
// #endregion

// #region Waitlist
const fetchAdminWaitlistRegistrants = (dispatch) => async (
  { pkRegAssociation, pkRegAssociationSeason, pkRegAssociationDivision },
  onSuccessCallback,
  onErrorCallback
) => {
  await fetchListResource(
    dispatch,
    'regWaitlistRegistrants',
    `/api/registration/waitlist`,
    {
      params: {
        pkRegAssociation,
        pkRegAssociationSeason,
        pkRegAssociationDivision
      },
      onSuccessCallback,
      onErrorCallback
    }
  );
};

const removeAdminWaitlistPerson = (dispatch) => async (
  pkRegWaitlistPerson,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    await sportsheadzApi.delete(
      `/api/registration/waitlist/${pkRegWaitlistPerson}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'remove',
      stateName: 'regWaitlistRegistrants',
      payload: (f) => f.pkRegWaitlistPerson !== pkRegWaitlistPerson
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, 'regWaitlistRegistrants', dispatch, onErrorCallback);
  }
};

const sendAdminWaitlistReservation = (dispatch) => async (
  pkRegWaitlistPerson,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.post(
      `/api/registration/waitlist/${pkRegWaitlistPerson}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'replace',
      stateName: 'regWaitlistRegistrants',
      payload: {
        value: response.data,
        filter: (f) => f.pkRegWaitlistPerson !== pkRegWaitlistPerson
      }
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, 'regWaitlistRegistrants', dispatch, onErrorCallback);
  }
};

const sendAdminWaitlistEmail = (dispatch) => async (
  pkRegAssociationDivision,
  { pkRegWaitlistPersons, subject, body, sendCopy },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    await sportsheadzApi.post(
      `/api/registration/waitlist/${pkRegAssociationDivision}/email`,
      { pkRegWaitlistPersons, subject, body, sendCopy },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, 'regWaitlistRegistrants', dispatch, onErrorCallback);
  }
};
// #endregion

const sendAdminRegistrationEmail = (dispatch) => async (
  pkRegAssociation,
  { pkSesEmailSession, emails, subject, body, sendCopy },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.post(
      `/api/registration/admin/registrants/${pkRegAssociation}/send-email`,
      {
        pkSesEmailSession,
        emails,
        subject,
        body,
        sendCopy
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch (e) {
    handleError(e, null, null, onErrorCallback);
  }
};

// #region Invoices
const sendAdminRegistrationInvoice = (dispatch) => async (
  pkRegFormSubmission,
  {
    invoiceAmount,
    description,
    isOfflineInvoice,
    isAdditionalCharge,
    customerPaysServiceFees
  },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.post(
      `/api/payment/registration/invoice/${pkRegFormSubmission}`,
      {
        invoiceAmount,
        description,
        isOfflineInvoice,
        isAdditionalCharge,
        customerPaysServiceFees
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'replace',
      stateName: 'regFormSubmissions',
      payload: {
        value: response.data,
        filter: (f) => f.pkRegFormSubmission !== pkRegFormSubmission
      }
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, 'regFormSubmissions', dispatch, onErrorCallback);
  }
};

const updateAdminRegistrationInvoiceStatus = (dispatch) => async (
  pkRegFormSubmissionInvoice,
  { status, fkRegFormSubmissionPayments, fkRegFormSubmissionPaymentType },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.put(
      `/api/payment/registration/invoice/${pkRegFormSubmissionInvoice}`,
      { status, fkRegFormSubmissionPayments, fkRegFormSubmissionPaymentType },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regFormSubmissionBalance',
      payload: { value: response.data }
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, 'regFormSubmissionBalance', dispatch, onErrorCallback);
  }
};

const resendAdminRegistrationInvoice = (dispatch) => async (
  pkRegFormSubmissionInvoice,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    await sportsheadzApi.post(
      `/api/payment/registration/invoice/${pkRegFormSubmissionInvoice}/resend`,
      null,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, 'regFormSubmissionBalance', dispatch, onErrorCallback);
  }
};

const rebaseAdminRegistrationInvoice = (dispatch) => async (
  pkRegFormSubmissionInvoice,
  { isAdditionalCharge },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    await sportsheadzApi.post(
      `/api/payment/registration/invoice/${pkRegFormSubmissionInvoice}/rebase?isAdditionalCharge=${isAdditionalCharge}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, null, null, onErrorCallback);
  }
};

const creditAdminRegistrationInvoice = (dispatch) => async (
  pkRegFormSubmissionInvoice,
  { creditAmount, creditReason, isOfflineRefund },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.post(
      `/api/payment/registration/invoice/${pkRegFormSubmissionInvoice}/credit`,
      { creditAmount, creditReason, isOfflineRefund },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regFormSubmissionBalance',
      payload: { value: response.data }
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, 'regFormSubmissionBalance', dispatch, onErrorCallback);
  }
};
// #endregion

// #region Division Invites
const fetchAdminRegAssociationDivisionFormInvites = (dispatch) => async (
  {
    pkRegAssociation,
    pkRegAssociationSeason,
    pkRegAssociationDivision,
    pkRegAssociationDivisionForm
  },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regAssociationDivisionFormInvites',
      payload: {
        value: [],
        filter: {
          pkRegAssociation,
          pkRegAssociationSeason,
          pkRegAssociationDivision,
          pkRegAssociationDivisionForm
        }
      }
    });

    const response = await fetchListResource(
      dispatch,
      'regAssociationDivisionFormExternalInvites',
      `/api/registration/admin/league/divisions/invite`,
      {
        params: {
          pkRegAssociation,
          pkRegAssociationSeason,
          pkRegAssociationDivision,
          pkRegAssociationDivisionForm
        },
        dataSelector: 'externalInvites',
        onSuccessCallback,
        onErrorCallback
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regAssociationDivisionFormInvites',
      payload: {
        value: response.data.invites,
        filter: {
          pkRegAssociation,
          pkRegAssociationSeason,
          pkRegAssociationDivision,
          pkRegAssociationDivisionForm
        }
      }
    });
  } catch (e) {
    dispatch({
      type: 'error',
      stateName: 'regAssociationDivisionFormInvites',
      payload: 'Oops something went wrong'
    });
  }
};

const expireAdminRegAssociationDivisionFormInvite = (dispatch) => async (
  pkRegAssociationDivisionFormInvite,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.delete(
      `/api/registration/admin/league/divisions/invite/${pkRegAssociationDivisionFormInvite}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'replace',
      stateName: 'regAssociationDivisionFormInvites',
      payload: {
        value: response.data,
        filter: (f) =>
          f.pkRegAssociationDivisionFormInvite !==
          pkRegAssociationDivisionFormInvite
      }
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(
      e,
      'regAssociationDivisionFormInvites',
      dispatch,
      onErrorCallback
    );
  }
};

const sendAdminRegAssociationDivisionFormInvites = (dispatch) => async (
  pkRegAssociationDivision,
  {
    fkRegFormSubmissions,
    externalUsers,
    fkRegAssociationDivisionForm,
    expiresAt
  },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    await sportsheadzApi.post(
      `/api/registration/admin/league/divisions/${pkRegAssociationDivision}/invite`,
      {
        fkRegFormSubmissions,
        externalUsers,
        fkRegAssociationDivisionForm,
        expiresAt
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, null, null, onErrorCallback);
  }
};

const expireAdminRegAssociationDivisionFormExternalInvite = (
  dispatch
) => async (
  pkRegAssociationDivisionFormExternalInvite,
  { inviteCode },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.delete(
      `/api/registration/admin/league/divisions/invite/external/${pkRegAssociationDivisionFormExternalInvite}?inviteCode=${inviteCode}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'replace',
      stateName: 'regAssociationDivisionFormExternalInvites',
      payload: {
        value: response.data,
        filter: (f) =>
          f.pkRegAssociationDivisionFormExternalInvite !==
          pkRegAssociationDivisionFormExternalInvite
      }
    });
    dispatch({
      type: 'success',
      stateName: 'regAssociationDivisionFormExternalInvite',
      payload: { value: response.data }
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(
      e,
      'regAssociationDivisionFormInvites',
      dispatch,
      onErrorCallback
    );
  }
};
// #endregion

// #region Evaluations
const fetchScoutReportEvaluations = (dispatch) => async (
  pkRegAssociation,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regScoutReports',
      payload: {
        value: [],
        filter: { pkRegAssociation }
      }
    });

    const response = await fetchListResource(
      dispatch,
      'regScoutReportEvaluationSessions',
      `/api/registration/admin/evaluations/${pkRegAssociation}`,
      {
        filter: { pkRegAssociation },
        onSuccessCallback,
        onErrorCallback,
        dataSelector: 'regScoutReportEvaluationSessions'
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regScoutReports',
      payload: {
        value: response.data.regScoutReports,
        filter: { pkRegAssociation }
      }
    });
  } catch {
    dispatch({
      type: 'error',
      stateName: 'regScoutReports',
      payload: 'Oops something went wrong'
    });
  }
};

const fetchScoutReportEvaluationPlayers = (dispatch) => async (
  pkRegAssociation,
  { pkRegAssociationDivision, pkRegAssociationSeason, regFormUuid } = {},
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  await fetchListResource(
    dispatch,
    'regScoutReportEvaluationPlayers',
    `/api/registration/admin/evaluations/players/${pkRegAssociation}`,
    {
      params: { pkRegAssociationDivision, pkRegAssociationSeason, regFormUuid },
      filter: {
        pkRegAssociation,
        pkRegAssociationDivision,
        pkRegAssociationSeason,
        regFormUuid
      },
      onSuccessCallback,
      onErrorCallback
    }
  );
};

const fetchScoutReportTemplates = (dispatch) => async (
  pkRegAssociation,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regScoutReports'
    });
    const response = await sportsheadzApi.get(
      `/api/registration/admin/scout-reports`,
      {
        params: { pkRegAssociation },
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regScoutReports',
      payload: { value: response.data }
    });
    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch (e) {
    handleError(e, 'regScoutReports', dispatch, onErrorCallback);
  }
};

const fetchRegScoutReport = (dispatch) => async (
  pkRegScoutReport,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regScoutReport'
    });
    const response = await sportsheadzApi.get(
      `/api/registration/admin/scout-reports/${pkRegScoutReport}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regScoutReport',
      payload: { value: response.data }
    });
    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch (e) {
    handleError(e, 'regScoutReport', dispatch, onErrorCallback);
  }
};

const createRegScoutReport = (dispatch) => async (
  pkRegAssociation,
  { name, pkRegScoutReportCopy, regScoutReportQuestions },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regScoutReports'
    });
    const response = await sportsheadzApi.post(
      `/api/registration/admin/scout-reports/${pkRegAssociation}`,
      {
        name,
        pkRegScoutReportCopy,
        regScoutReportQuestions
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'append',
      stateName: 'regScoutReports',
      payload: response.data
    });
    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch (e) {
    handleError(e, 'regScoutReports', dispatch, onErrorCallback);
  }
};

const updateRegScoutReport = (dispatch) => async (
  pkRegScoutReport,
  { name, regScoutReportQuestions },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regScoutReport'
    });
    const response = await sportsheadzApi.put(
      `/api/registration/admin/scout-reports/${pkRegScoutReport}`,
      {
        name,
        regScoutReportQuestions
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regScoutReport',
      payload: { value: response.data }
    });
    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch (e) {
    handleError(e, 'regScoutReport', dispatch, onErrorCallback);
  }
};

const deleteRegScoutReport = (dispatch) => async (
  pkRegScoutReport,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    await sportsheadzApi.delete(
      `/api/registration/admin/scout-reports/${pkRegScoutReport}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    if (onSuccessCallback) {
      onSuccessCallback();
    }

    dispatch({
      type: 'remove',
      stateName: 'regScoutReports',
      payload: (f) => f.pkRegScoutReport !== pkRegScoutReport
    });
  } catch (e) {
    handleError(e, 'regScoutReports', dispatch, onErrorCallback);
  }
};

const fetchScoutReportEvaluationSession = (dispatch) => async (
  pkRegScoutReportEvaluationSession,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regScoutReportEvaluationSession'
    });
    const response = await sportsheadzApi.get(
      `/api/registration/admin/scout-reports/evaluation-sessions/${pkRegScoutReportEvaluationSession}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regScoutReportEvaluationSession',
      payload: { value: response.data }
    });
    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch (e) {
    handleError(
      e,
      'regScoutReportEvaluationSession',
      dispatch,
      onErrorCallback
    );
  }
};

const createRegScoutReportEvaluationSession = (dispatch) => async (
  {
    name,
    pkRegScoutReportEvaluationSessionCopy,
    fkRegAssociationDivision,
    regScoutReportEvaluations,
    regScoutReportEvaluationSessionEntries,
    regScoutReportEvaluationSessionTemplates
  },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.post(
      `/api/registration/admin/scout-reports/evaluation-sessions`,
      {
        name,
        pkRegScoutReportEvaluationSessionCopy,
        fkRegAssociationDivision,
        regScoutReportEvaluations,
        regScoutReportEvaluationSessionEntries,
        regScoutReportEvaluationSessionTemplates
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'append',
      stateName: 'regScoutReportEvaluationSessions',
      payload: response.data
    });
    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch (e) {
    handleError(
      e,
      'regScoutReportEvaluationSessions',
      dispatch,
      onErrorCallback
    );
  }
};

const updateRegScoutReportEvaluationSession = (dispatch) => async (
  pkRegScoutReportEvaluationSession,
  {
    name,
    regScoutReportEvaluations,
    regScoutReportEvaluationSessionEntries,
    regScoutReportEvaluationSessionTemplates
  },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.put(
      `/api/registration/admin/scout-reports/evaluation-sessions/${pkRegScoutReportEvaluationSession}`,
      {
        name,
        regScoutReportEvaluations,
        regScoutReportEvaluationSessionEntries,
        regScoutReportEvaluationSessionTemplates
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regScoutReportEvaluationSession',
      payload: { value: response.data }
    });
    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch (e) {
    handleError(
      e,
      'regScoutReportEvaluationSessions',
      dispatch,
      onErrorCallback
    );
  }
};

const deleteRegScoutReportEvaluationSession = (dispatch) => async (
  pkRegScoutReportEvaluationSession,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    await sportsheadzApi.delete(
      `/api/registration/admin/scout-reports/evaluation-sessions/${pkRegScoutReportEvaluationSession}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    if (onSuccessCallback) {
      onSuccessCallback();
    }

    dispatch({
      type: 'remove',
      stateName: 'regScoutReportEvaluationSessions',
      payload: (f) =>
        f.pkRegScoutReportEvaluationSession !==
        pkRegScoutReportEvaluationSession
    });
  } catch (e) {
    handleError(
      e,
      'regScoutReportEvaluationSessions',
      dispatch,
      onErrorCallback
    );
  }
};

const updateRegScoutReportEvaluationSessionEntry = (dispatch) => async (
  pkRegScoutReportEvaluationSession,
  { pkRegScoutReportEvaluationSessionEntry, regScoutReportEvaluations },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.put(
      `/api/registration/admin/scout-reports/evaluation-sessions/${pkRegScoutReportEvaluationSession}/entry`,
      {
        pkRegScoutReportEvaluationSessionEntry,
        regScoutReportEvaluations
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regScoutReportEvaluationSession',
      payload: { value: response.data }
    });
    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch (e) {
    handleError(
      e,
      'regScoutReportEvaluationSessions',
      dispatch,
      onErrorCallback
    );
  }
};

const createRegScoutReportPerson = (dispatch) => async (
  { firstName, lastName, email, phone, gender, role, fkRegAssociationDivision },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.post(
      `/api/registration/admin/scout-reports/persons`,
      {
        firstName,
        lastName,
        email,
        phone,
        gender,
        role,
        fkRegAssociationDivision
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'append',
      stateName: 'regScoutReportEvaluationPlayers',
      payload: response.data
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(
      e,
      'regScoutReportEvaluationPlayers',
      dispatch,
      onErrorCallback
    );
  }
};

const createRegScoutReportPersons = (dispatch) => async (
  regScoutReportPersons,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.post(
      `/api/registration/admin/scout-reports/persons/bulk`,
      regScoutReportPersons,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regScoutReportEvaluationPlayers',
      payload: { value: response.data }
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(
      e,
      'regScoutReportEvaluationPlayers',
      dispatch,
      onErrorCallback
    );
  }
};

const updateRegScoutReportPerson = (dispatch) => async (
  pkRegScoutReportPerson,
  { firstName, lastName, email, phone, gender, role, fkRegAssociationDivision },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.put(
      `/api/registration/admin/scout-reports/persons/${pkRegScoutReportPerson}`,
      {
        firstName,
        lastName,
        email,
        phone,
        gender,
        role,
        fkRegAssociationDivision
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'replace',
      stateName: 'regScoutReportEvaluationPlayers',
      payload: {
        value: response.data,
        filter: (f) => f.pkRegScoutReportPerson !== response.data.entityId
      }
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(
      e,
      'regScoutReportEvaluationPlayers',
      dispatch,
      onErrorCallback
    );
  }
};

const deleteRegScoutReportPerson = (dispatch) => async (
  pkRegScoutReportPerson,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    await sportsheadzApi.delete(
      `/api/registration/admin/scout-reports/persons/${pkRegScoutReportPerson}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'remove',
      stateName: 'regScoutReportEvaluationPlayers',
      payload: (f) => f.pkRegScoutReportPerson !== pkRegScoutReportPerson
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(
      e,
      'regScoutReportEvaluationPlayers',
      dispatch,
      onErrorCallback
    );
  }
};
// #endregion

const syncRegAssociationSeasonTeams = (dispatch) => async (
  pkRegAssociationSeason,
  { pkRegAssociationDivisions, pkRegAssociationDivisionTeam },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    await sportsheadzApi.post(
      `/api/registration/sync/season-teams/${pkRegAssociationSeason}`,
      { pkRegAssociationDivisionTeam, pkRegAssociationDivisions },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, null, null, onErrorCallback);
  }
};

// #region Merchandise
const fetchMerchPackageOrders = (dispatch) => async (
  {
    fkRegAssociation,
    merchPackageUuid,
    purchasedAfter,
    purchasedBefore,
    analyticsCta,
    search,
    status,
    page,
    pageSize,
    sortBy,
    orderBy
  },
  appendResults = false
) => {
  try {
    if (!appendResults) {
      dispatch({
        type: 'fetching',
        stateName: 'paginatedRegCartMerchPackages',
        payload: {
          value: [],
          filter: {
            fkRegAssociation,
            merchPackageUuid,
            purchasedAfter,
            purchasedBefore,
            analyticsCta,
            search,
            status,
            page,
            pageSize,
            sortBy,
            orderBy
          }
        }
      });
    }

    const response = await sportsheadzApi.get(
      `/api/registration/merchandise/orders`,
      {
        params: {
          fkRegAssociation,
          merchPackageUuid,
          purchasedAfter,
          purchasedBefore,
          analyticsCta,
          search,
          status,
          page,
          pageSize,
          sortBy,
          orderBy
        },
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );

    dispatch({
      type: appendResults ? 'paginated-append' : 'success',
      stateName: 'paginatedRegCartMerchPackages',
      payload: {
        pageIndex: response.data.pageIndex,
        pageSize: response.data.pageSize,
        totalCount: response.data.totalCount,
        totalPages: response.data.totalPages,
        data: response.data.data
      }
    });
  } catch {
    triggerNotification('Oops something went wrong');
    dispatch({
      type: 'error',
      stateName: 'paginatedRegCartMerchPackages',
      payload: 'Oops something went wrong'
    });
  }
};

const fetchMerchPackageOrderFilters = (dispatch) => async (
  fkRegAssociation,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regCartMerchPackageFilters',
      payload: {
        value: null,
        filter: { fkRegAssociation }
      }
    });

    const response = await sportsheadzApi.get(
      '/api/registration/merchandise/orders/filters',
      {
        params: { fkRegAssociation },
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );

    dispatch({
      type: 'success',
      stateName: 'regCartMerchPackageFilters',
      payload: {
        filter: { fkRegAssociation },
        value: response.data
      }
    });

    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, 'regCartMerchPackageFilters', dispatch, onErrorCallback);
  }
};

const updateMerchPackageDetails = (dispatch) => async (
  pkRegCartMerchPackage,
  { inputAnswers },
  onSuccess,
  onError
) => {
  try {
    const response = await sportsheadzApi.put(
      `/api/staff/merchandise/orders/${pkRegCartMerchPackage}`,
      { inputAnswers },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );

    dispatch({
      type: 'paginated-replace-index',
      stateName: 'paginatedRegCartMerchPackages',
      payload: {
        value: response.data,
        filter: (s) =>
          s.pkRegCartMerchPackage === response.data.pkRegCartMerchPackage
      }
    });
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    handleError(e, 'paginatedRegCartMerchPackages', dispatch, onError);
  }
};

const fetchRegCartMerchPackageBalance = (dispatch) => async (
  pkRegCartMerchPackage,
  onSuccess,
  onError
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regCartMerchPackageBalance'
    });
    const response = await sportsheadzApi.get(
      `/api/registration/merchandise/orders/${pkRegCartMerchPackage}/balance`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regCartMerchPackageBalance',
      payload: { value: response.data }
    });
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    handleError(e, 'regCartMerchPackageBalance', dispatch, onError);
  }
};

const refundRegCartMerchPackage = (dispatch) => async (
  pkRegCartMerchPackage,
  { partialRefundAmount },
  onSuccess,
  onError
) => {
  try {
    const response = await sportsheadzApi.post(
      `/api/payment/merchandise/refund/${pkRegCartMerchPackage}`,
      { partialRefundAmount },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );

    dispatch({
      type: 'paginated-replace-index',
      stateName: 'paginatedRegCartMerchPackages',
      payload: {
        value: response.data,
        filter: (s) =>
          s.pkRegCartMerchPackage === response.data.pkRegCartMerchPackage
      }
    });
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    handleError(e, 'regCartMerchPackageBalance', dispatch, onError);
  }
};
// #endregion

// #region Administrators & Permissions
const fetchRegAssociationAdministrators = (dispatch) => async (
  pkRegAssociation,
  { includeDeleted },
  onSuccessCallback,
  onErrorCallback
) => {
  await fetchListResource(
    dispatch,
    'regAssociationAdmins',
    `/api/registration/admin/administrators/${pkRegAssociation}`,
    {
      params: { includeDeleted },
      filter: { pkRegAssociation },
      onSuccessCallback,
      onErrorCallback
    }
  );
};

const createRegAssociationAdministrator = (dispatch) => async (
  regAssociationAdmins,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.post(
      `/api/registration/admin/administrators`,
      regAssociationAdmins,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'replace',
      stateName: 'regAssociationAdmins',
      payload: {
        filter: (f) =>
          !response.data.some(
            (d) => d.pkRegAssociationAdmin === f.pkRegAssociationAdmin
          ),
        value: response.data
      }
    });
    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch (e) {
    handleError(e, 'regAssociationAdmins', dispatch, onErrorCallback);
  }
};

const updateRegAssociationAdministrator = (dispatch) => async (
  regAssociationAdmins,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.put(
      `/api/registration/admin/administrators`,
      regAssociationAdmins,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'replace',
      stateName: 'regAssociationAdmins',
      payload: {
        filter: (f) =>
          !response.data.some(
            (d) => d.pkRegAssociationAdmin === f.pkRegAssociationAdmin
          ),
        value: response.data
      }
    });
    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch (e) {
    handleError(e, 'regAssociationAdmins', dispatch, onErrorCallback);
  }
};

const fetchRegAssociationAdminInvite = (dispatch) => async (inviteCode) => {
  dispatch({
    type: 'fetching',
    stateName: 'regAssociationAdminInvite'
  });
  try {
    const response = await sportsheadzApi.get(
      `/api/registration/admin/administrators/invite/${inviteCode}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regAssociationAdminInvite',
      payload: { value: response.data }
    });
  } catch (e) {
    handleError(e, 'regAssociationAdminInvite', dispatch);
  }
};

const respondToRegAssociationAdminInvite = (dispatch) => async (
  pkRegAssociationAdmin,
  { isAccepted },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.post(
      `/api/registration/admin/administrators/invite/${pkRegAssociationAdmin}/respond?isAccepted=${isAccepted}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'replace',
      stateName: 'regAssociationAdmins',
      payload: {
        filter: (f) => pkRegAssociationAdmin !== f.pkRegAssociationAdmin,
        value: response.data
      }
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, 'regAssociationAdmins', dispatch, onErrorCallback);
  }
};

const fetchAdminRegAssociationEmailUnsubscribe = (dispatch) => async (
  pkRegAssociation,
  onSuccessCallback,
  onErrorCallback
) => {
  await fetchListResource(
    dispatch,
    'regEmailUnsubscribe',
    `/api/registration/mailings/unsubscribe/${pkRegAssociation}`,
    {
      filter: { pkRegAssociation },
      onSuccessCallback,
      onErrorCallback
    }
  );
};

const fetchAdminRegAssociationSentEmails = (dispatch) => async (
  onSuccessCallback,
  onErrorCallback
) => {
  await fetchListResource(
    dispatch,
    'sesEmailSessions',
    `/api/registration/mailings/sent/`,
    {
      onSuccessCallback,
      onErrorCallback
    }
  );
};
// #endregion

export const { Provider, Context } = createDataProvider(
  registrationReducer,
  {
    changeRegistrationDivision,
    changeRegistrationOverrideEmail,
    createAdminRegAssociationCoupon,
    createAdminRegAssociationDivision,
    createAdminRegAssociationDivisionTeam,
    createAdminRegAssociationGroupCoupon,
    createAdminRegAssociationSavedTemplate,
    createAdminRegAssociationSeason,
    createAdminRegForm,
    createAdminRegFormSubmissionPayment,
    createRegAssociationAdministrator,
    createRegScoutReportEvaluationSession,
    createRegScoutReportPerson,
    createRegScoutReportPersons,
    createRegScoutReport,
    creditAdminRegistrationInvoice,
    deleteAdminRegAssociationDivision,
    deleteAdminRegAssociationDivisionTeam,
    deleteAdminRegAssociationSavedTemplate,
    deleteAdminRegAssociationSeason,
    deleteRegScoutReportEvaluationSession,
    deleteRegScoutReportPerson,
    deleteRegScoutReport,
    deleteAdminRegForm,
    disconnectPaymentProvider,
    expireAdminRegAssociationCoupon,
    expireAdminRegAssociationDivisionFormExternalInvite,
    expireAdminRegAssociationDivisionFormInvite,
    expireAdminRegAssociationGroupCoupon,
    generateAdminRegAssociationDivisionTeams,
    generateAdminRegAssociationReport,
    generateAdminRegAssociationSavedTemplate,
    generateStaffRegistrationReport,
    importRegAssociationDivisionTeams,
    onboardPaymentProvider,
    updateRegAssociation,
    updateAdminRegAssociationCoupon,
    updateAdminRegAssociationDivision,
    updateAdminRegAssociationDivisionTeam,
    updateAdminRegAssociationGroupCoupon,
    updateAdminRegAssociationSavedTemplate,
    updateAdminRegAssociationSeason,
    updateAdminRegistrationInvoiceStatus,
    updateAdminRegSpecialRequests,
    updateAdminRegForm,
    updateMerchPackageDetails,
    updateRegAssociationAdministrator,
    updateRegScoutReportEvaluationSession,
    updateRegScoutReportEvaluationSessionEntry,
    updateRegScoutReportPerson,
    updateRegScoutReport,
    fetchAdminFinancialRegistrations,
    generateAdminRegAssociationAnalyticsReport,
    fetchAdminRegAssociationCoupons,
    fetchAdminRegAssociationDivisionFormInvites,
    fetchAdminRegAssociationDivisions,
    fetchAdminRegAssociationDivisionRegistrants,
    fetchAdminRegAssociationEmailUnsubscribe,
    fetchAdminRegAssociationLeagueOptions,
    fetchAdminRegAssociationRegistrants,
    fetchAdminRegAssociations,
    fetchAdminRegAssociationSeasons,
    fetchAdminRegAssociationSentEmails,
    fetchAdminRegFormSubmissionOrderHistory,
    fetchAdminRegFormSubmissionPayments,
    fetchAdminRegFormSubmissionPaymentTransactionDetails,
    fetchAdminRegFormSubmissionInvoiceTransactionDetails,
    fetchAdminRegForm,
    fetchAdminRegForms,
    fetchAdminRegFormVersionHistory,
    fetchAdminRegReports,
    fetchAdminRegReportInputSources,
    fetchAdminRegSpecialRequests,
    fetchAdminWaitlistRegistrants,
    fetchMerchPackageOrderFilters,
    fetchMerchPackageOrders,
    fetchPaginatedRegFormSubmissions,
    fetchRegAssociationAdministrators,
    fetchRegAssociationAdminInvite,
    fetchRegAssociationDivisionTeams,
    fetchRegCartMerchPackageBalance,
    fetchRegScoutReport,
    fetchUpdatedAdminFinancialRegistration,
    fetchUpdatedAdminRegAssociationDivision,
    fetchScoutReportEvaluationPlayers,
    fetchScoutReportEvaluations,
    fetchScoutReportEvaluationSession,
    fetchScoutReportTemplates,
    rebaseAdminRegistrationInvoice,
    respondToFinancialRegistration,
    refundFinancialRegistration,
    refundRegCartMerchPackage,
    removeAdminWaitlistPerson,
    sendAdminRegFormSubmissionOrderReceipt,
    sendAdminRegAssociationDivisionFormInvites,
    sendAdminWaitlistEmail,
    syncRegAssociationSeasonTeams,
    resendAdminRegistrationInvoice,
    respondToRegAssociationAdminInvite,
    sendAdminRegistrationEmail,
    sendAdminRegistrationInvoice,
    sendAdminWaitlistReservation
  },
  initialState
);
