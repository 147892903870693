import React, { useContext, useEffect, useState } from 'react';
import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import AppText from '../components/common/AppText';
import AppFlexbox from '../components/common/AppFlexbox';
import { getFromStorage, saveToStorage } from './storageHelper';
import { Context as RegistrationAdminDashboardContext } from '../providers/RegistrationAdminDashboardProvider';

const useOnScreen = (ref) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting)
  );

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }
    return () => {};
  }, [ref.current]);

  return isIntersecting;
};

const useMediaQueryIndex = () => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
  const isTablet = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const isLaptop = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const isDesktop = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`);
  const isLargeDesktop = useMediaQuery(`(max-width: ${theme.breakpoints.xl})`);

  const savedMqIndex = Number(getFromStorage('mqIndexRef'));
  if (isMobile === undefined) {
    return savedMqIndex;
  }

  const mqIndex = isMobile
    ? 0
    : isTablet
    ? 1
    : isLaptop
    ? 2
    : isDesktop
    ? 3
    : isLargeDesktop
    ? 4
    : 5;

  if (mqIndex !== savedMqIndex) {
    saveToStorage('mqIndexRef', mqIndex.toString());
  }
  return mqIndex;
};

const useModalState = ({ closeOnOpen } = {}) => {
  const [modalState, setModalState] = useState({
    isOpen: false,
    action: null,
    item: null,
    secondItem: null
  });

  useEffect(() => {
    if (modalState.isOpen && closeOnOpen) {
      // eslint-disable-next-line no-use-before-define
      onCloseModal();
    }
  }, [modalState.isOpen]);

  const onOpenModal = (action, item, secondItem) => {
    setModalState({
      isOpen: true,
      action,
      item,
      secondItem
    });
  };

  const onCloseModal = () => {
    setModalState({
      ...modalState,
      isOpen: false
    });
  };

  return { state: modalState, onCloseModal, onOpenModal };
};

const useSortByFilter = (
  sortableTableColumns,
  config = { defaultSort: null, defaultDescending: false }
) => {
  const [sortFilter, setSortFilter] = useState({
    sortBy:
      config.defaultSort != null
        ? config.defaultSort
        : sortableTableColumns[0]?.value,
    descendingOrder: config.defaultDescending || false
  });
  const selectedSort = sortableTableColumns.find(
    (c) => c.value === sortFilter.sortBy
  );

  useEffect(() => {
    setSortFilter({
      sortBy:
        config.defaultSort != null
          ? config.defaultSort
          : sortableTableColumns[0]?.value,
      descendingOrder: config.defaultDescending || false
    });
  }, []);

  const onChangeSortBy = (value, isDescending = false) => {
    setSortFilter({
      ...sortFilter,
      sortBy: value,
      descendingOrder: isDescending
    });
  };

  return {
    sortValue: sortFilter.sortBy,
    isDescendingSort: sortFilter.descendingOrder,
    onChangeSortBy,
    customFilterData: {
      type: 'custom-select',
      value: sortFilter.sortBy,
      placeholder: 'Sort by...',
      searchValue: sortFilter.sortBy ? (
        <AppFlexbox style={{ gap: 5, textWrap: 'nowrap' }}>
          <AppText color="#808080" style={{ fontSize: 14 }} weight={400}>
            Sort by
          </AppText>
          <AppText color="#000" style={{ fontSize: 14, flex: 1 }} weight={400}>
            {selectedSort?.label}
          </AppText>
        </AppFlexbox>
      ) : (
        ''
      ),
      onlyShowSearchValue: true,
      data: sortableTableColumns.map((c) => ({
        label: c.label,
        value: c.value
      })),
      onChange: (value) => {
        if (value && value !== sortFilter.sortBy) {
          onChangeSortBy(value);
        }
      }
    }
  };
};

const useDashboardCookies = () => {
  const isLegacyViewCookie = getFromStorage('isLegacyView');
  const pkRegAssociation = getFromStorage('pkRegAssociation');
  const { state, changeLegacySettings, changeAssociation } = useContext(
    RegistrationAdminDashboardContext
  );
  const isLegacyView = !isLegacyViewCookie || isLegacyViewCookie === 'true';

  useEffect(() => {
    if (isLegacyView && isLegacyView !== state.legacy.isLegacyView) {
      changeLegacySettings({ isLegacyView }, true);
    }

    if (
      pkRegAssociation &&
      pkRegAssociation !== state.regAssociation.pkRegAssociation
    ) {
      changeAssociation({ pkRegAssociation }, true);
    }
  }, []);

  return {
    pkRegAssociation,
    isLegacyView
  };
};

export {
  useOnScreen,
  useMediaQueryIndex,
  useModalState,
  useSortByFilter,
  useDashboardCookies
};
