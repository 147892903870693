const SUPPORT_LINKS = {
  index: {
    label: 'Support',
    href: 'https://support.sportsheadz.com/collection/186-registration'
  },
  communicationLinks: {
    index: {
      label: 'Communications Index',
      href: 'https://support.sportsheadz.com/category/231-communications'
    },
    emailUsers: {
      label: 'Email/Communicate with Users',
      href: 'https://support.sportsheadz.com/article/215-email-users'
    }
  },
  couponLinks: {
    index: {
      label: 'Coupons Index',
      href: 'https://support.sportsheadz.com/category/221-coupons'
    },
    regularCoupons: {
      label: 'How to create Standard Coupons',
      href: 'https://support.sportsheadz.com/article/227-standard-coupon'
    },
    groupCoupons: {
      label: 'How to create Group Coupons',
      href: 'https://support.sportsheadz.com/article/237-group-coupon'
    }
  },
  financialLinks: {
    index: {
      label: 'Financials Index',
      href: 'https://support.sportsheadz.com/category/223-financials'
    },
    reconciliation: {
      label: 'Reconciliation for Stripe',
      href: 'https://support.sportsheadz.com/article/194-reconciliation'
    },
    payouts: {
      label: 'Pay-Outs from Stripe',
      href: 'https://support.sportsheadz.com/article/190-pay-outs-from-stripe'
    },
    paymentProviders: {
      label: 'Payment Provider Options',
      href:
        'https://support.sportsheadz.com/article/241-payment-provider-options'
    },
    registrations: {
      label: 'How to manage Registrations',
      href: 'https://support.sportsheadz.com/article/242-registrations'
    }
  },
  formLinks: {
    index: {
      label: 'Forms Index',
      href: 'https://support.sportsheadz.com/category/222-forms'
    },
    createForm: {
      label: 'How to create blank (New) form',
      href: 'https://support.sportsheadz.com/article/228-create-blank-form'
    },
    formBuilder: {
      label: 'How to build/Edit your forms',
      href:
        'https://support.sportsheadz.com/article/236-building-editing-your-form-structure'
    },
    editForm: {
      label: 'How to edit existing Forms',
      href: 'https://support.sportsheadz.com/article/230-edit-existing-forms'
    },
    copyForm: {
      label: 'How to copy a Form',
      href: 'https://support.sportsheadz.com/article/229-copy-form'
    }
  },
  myLeagueLinks: {
    index: {
      label: 'My League Index',
      href: 'https://support.sportsheadz.com/category/213-my-league'
    },
    emailUsers: {
      label: 'Email/Communicate with Users',
      href: 'https://support.sportsheadz.com/article/215-email-users'
    },
    createSeason: {
      label: 'How to create a Season',
      href: 'https://support.sportsheadz.com/article/214-create-season'
    },
    setupDivision: {
      label: 'How to setup Divisions',
      href: 'https://support.sportsheadz.com/article/217-setting-up-divisions'
    },
    waitlists: {
      label: 'How to manage Waitlists',
      href: 'https://support.sportsheadz.com/article/219-waitlists'
    },
    invites: {
      label: 'How to invite Registrants',
      href: 'https://support.sportsheadz.com/article/226-inviting-registrants'
    }
  },
  publicFacingFeatureLinks: {
    index: {
      label: 'Public Facing Features Index',
      href:
        'https://support.sportsheadz.com/category/233-public-facing-features'
    },
    personalProfiles: {
      label: 'Personal Profiles',
      href: 'https://support.sportsheadz.com/article/232-personal-profiles'
    },
    userInvites: {
      label: 'Accepting/Declining Invites',
      href:
        'https://support.sportsheadz.com/article/234-accepting-declining-invites'
    }
  },
  reportLinks: {
    index: {
      label: 'Reports Index',
      href: 'https://support.sportsheadz.com/category/224-reports'
    },
    globalReports: {
      label: 'Pre-Built Reports',
      href: 'https://support.sportsheadz.com/article/240-template-reports'
    },
    customReport: {
      label: 'One-Time Custom Report',
      href: 'https://support.sportsheadz.com/article/244-one-time-custom-report'
    },
    savedTemplates: {
      label: 'Saved (Custom) Templates',
      href: 'https://support.sportsheadz.com/article/243-saved-templates'
    }
  },
  scoutingLinks: {
    index: {
      label: 'Scouting & Evaluations Index',
      href: 'https://support.sportsheadz.com/category/225-scouting-evaluations'
    },
    evaluatingPlayers: {
      label: 'How to create Evaluation Reports',
      href:
        'https://support.sportsheadz.com/article/239-creating-evaluation-reports'
    }
  },
  setupLinks: {
    index: {
      label: 'Setup Index',
      href: 'https://support.sportsheadz.com/category/212-setup'
    },
    branding: {
      label: 'How to setup Association Branding',
      href: 'https://support.sportsheadz.com/article/211-branding'
    }
  }
};

export default SUPPORT_LINKS;
