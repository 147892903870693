import { PropTypes } from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  Select,
  TextInput,
  Textarea,
  Tooltip
} from '@mantine/core';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import FormSection from '../../common/FormSection';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppTitle from '../../common/AppTitle';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import AppText from '../../common/AppText';
import PaginationList from '../../common/PaginationList';
import SelectableListItem from '../../common/SelectableListItem';
import { getExpiryDateInfo } from '../../../helpers/registrationHelper';
import { formatUtcDate, singularPluralFormat } from '../../../helpers/format';
import { triggerNotification } from '../../../helpers/notificationHelper';
import LeagueSeasonEndedNotification from '../leagues/LeagueSeasonEndedNotification';

const WaitlistEmailModal = ({
  isOpen,
  onClose,
  regWaitlistRegistrants,
  pkRegAssociation,
  fkRegAssociationSeason,
  fkRegAssociationDivision
}) => {
  const fetchedPkRegAssociation = useRef(false);
  const mqIndex = useMediaQueryIndex();
  const {
    state,
    sendAdminWaitlistEmail,
    fetchAdminRegAssociationEmailUnsubscribe
  } = useContext(RegistrationAdminContext);
  const [formState, setFormState] = useState({
    showDivisionSelect: true,
    fkRegAssociationSeason: null,
    fkRegAssociationDivision: null,
    subject: '',
    body: '',
    sendCopy: true,
    selectedRegistrants: [],
    pageIndex: 1,
    isLoading: false,
    hasUnsavedChanges: false,
    showCloseConfirmation: false
  });

  const filterOptions = regWaitlistRegistrants.reduce(
    (r, c) => {
      if (
        !r.seasonOptions.find(
          (f) =>
            f.value ===
            c.regAssociationDivisionForm.regAssociationDivision.regAssociationSeason.pkRegAssociationSeason.toString()
        )
      ) {
        r.seasonOptions.push({
          label:
            c.regAssociationDivisionForm.regAssociationDivision
              .regAssociationSeason.name,
          value: c.regAssociationDivisionForm.regAssociationDivision.regAssociationSeason.pkRegAssociationSeason.toString(),
          endDate:
            c.regAssociationDivisionForm.regAssociationDivision
              .regAssociationSeason.endDate
        });
      }

      if (
        !r.divisionOptions.find(
          (f) =>
            f.value ===
            c.regAssociationDivisionForm.regAssociationDivision.pkRegAssociationDivision.toString()
        )
      ) {
        r.divisionOptions.push({
          label: c.regAssociationDivisionForm.regAssociationDivision.name,
          value: c.regAssociationDivisionForm.regAssociationDivision.pkRegAssociationDivision.toString(),
          fkRegAssociationSeason: c.regAssociationDivisionForm.regAssociationDivision.regAssociationSeason.pkRegAssociationSeason.toString()
        });
      }
      return r;
    },
    {
      seasonOptions: [],
      divisionOptions: []
    }
  );

  const selectedSeason = filterOptions.seasonOptions.find(
    (f) => f.value === formState.fkRegAssociationSeason
  );
  const seasonHasEnded =
    selectedSeason &&
    new Date(formatUtcDate(selectedSeason.endDate)) <= new Date();

  const filteredWaitlistRegistrants = regWaitlistRegistrants.filter(
    (r) =>
      (!formState.fkRegAssociationSeason ||
        r.regAssociationDivisionForm.regAssociationDivision.regAssociationSeason.pkRegAssociationSeason.toString() ===
          formState.fkRegAssociationSeason) &&
      (!formState.fkRegAssociationDivision ||
        r.regAssociationDivisionForm.regAssociationDivision.pkRegAssociationDivision.toString() ===
          formState.fkRegAssociationDivision)
  );

  const allRegistrantsSelected = filteredWaitlistRegistrants.every((r) =>
    formState.selectedRegistrants.some(
      (sr) => sr.pkRegWaitlistPerson === r.pkRegWaitlistPerson
    )
  );
  const hasAvailableRegistrants = filteredWaitlistRegistrants.some(
    (r) =>
      new Date(
        formatUtcDate(
          r.regAssociationDivisionForm.regAssociationDivision
            .regAssociationSeason.endDate
        )
      ) >= new Date()
  );

  const selectedAndSubscribedRegistrants = formState.selectedRegistrants.filter(
    (r) =>
      !state.regEmailUnsubscribe.value.some(
        (u) => u.email === r.regPerson.user.email
      )
  );

  useEffect(() => {
    if (isOpen) {
      const divisionOption = filterOptions.divisionOptions.find(
        (d) => d.value === fkRegAssociationDivision?.toString()
      );
      setFormState({
        showDivisionSelect: true,
        fkRegAssociationSeason:
          divisionOption?.fkRegAssociationSeason ||
          fkRegAssociationSeason?.toString() ||
          null,
        fkRegAssociationDivision: fkRegAssociationDivision?.toString() || null,
        subject: '',
        body: '',
        sendCopy: true,
        selectedRegistrants: [],
        pageIndex: 1,
        isLoading: false,
        hasUnsavedChanges: false,
        showCloseConfirmation: false
      });

      if (
        pkRegAssociation &&
        fetchedPkRegAssociation.current !== pkRegAssociation
      ) {
        fetchAdminRegAssociationEmailUnsubscribe(pkRegAssociation);
        fetchedPkRegAssociation.current = pkRegAssociation;
      }
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      centered
      onClose={() => {
        if (formState.hasUnsavedChanges) {
          setFormState({
            ...formState,
            showCloseConfirmation: true
          });
        }
        else {
          onClose();
        }
      }}
      opened={isOpen}
      size={600}
      title={<AppTitle order={2}>Email Waitlist</AppTitle>}
    >
      {formState.showDivisionSelect ? (
        <FormSection
          cancelTitle="Cancel"
          isLoading={formState.isLoading}
          isSubmitDisabled={
            seasonHasEnded || !formState.fkRegAssociationDivision
          }
          onCancel={onClose}
          onSubmit={() => {
            setFormState({
              ...formState,
              showDivisionSelect: false,
              selectedRegistrants: filteredWaitlistRegistrants.filter(
                (r) =>
                  new Date(
                    formatUtcDate(
                      r.regAssociationDivisionForm.regAssociationDivision
                        .regAssociationSeason.endDate
                    )
                  ) >= new Date()
              )
            });
          }}
          submitTitle="Continue"
        >
          <AppStack style={{ gap: 20 }}>
            <AppText style={{ textAlign: 'center' }}>
              Select the division and season to setup the initial email
              recipients
            </AppText>
            <Select
              data={filterOptions.seasonOptions}
              label="Season"
              onChange={(value) =>
                setFormState({
                  ...formState,
                  fkRegAssociationSeason: value,
                  fkRegAssociationDivision: null
                })
              }
              value={formState.fkRegAssociationSeason}
            />
            {seasonHasEnded ? (
              <LeagueSeasonEndedNotification
                pkRegAssociation={pkRegAssociation}
                regAssociationSeason={selectedSeason}
              />
            ) : (
              <Select
                data={filterOptions.divisionOptions.filter(
                  (d) =>
                    !formState.fkRegAssociationSeason ||
                    d.fkRegAssociationSeason ===
                      formState.fkRegAssociationSeason
                )}
                label="Division"
                onChange={(value) => {
                  const divisionOption = filterOptions.divisionOptions.find(
                    (d) => d.value === value
                  );
                  setFormState({
                    ...formState,
                    fkRegAssociationDivision: value,
                    fkRegAssociationSeason:
                      divisionOption?.fkRegAssociationSeason
                  });
                }}
                value={formState.fkRegAssociationDivision}
              />
            )}
          </AppStack>
        </FormSection>
      ) : !formState.showEmailSelect ? (
        <FormSection
          cancelTitle="Cancel"
          isLoading={formState.isLoading}
          isSubmitDisabled={
            !formState.subject ||
            !formState.body ||
            selectedAndSubscribedRegistrants.length === 0
          }
          onCancel={() => {
            if (formState.hasUnsavedChanges) {
              setFormState({
                ...formState,
                showCloseConfirmation: true
              });
            }
            else {
              onClose();
            }
          }}
          onSubmit={() => {
            setFormState({
              ...formState,
              isLoading: true
            });
            sendAdminWaitlistEmail(
              pkRegAssociation,
              {
                subject: formState.subject,
                body: formState.body,
                sendCopy: formState.sendCopy,
                pkRegWaitlistPersons: selectedAndSubscribedRegistrants.map(
                  (r) => r.pkRegWaitlistPerson
                )
              },
              () => {
                triggerNotification('Email Sent!', 'Success', 'green');
                onClose();
              },
              (e) => {
                triggerNotification(e);
                setFormState({
                  ...formState,
                  isLoading: false
                });
              }
            );
          }}
          submitTitle={
            selectedAndSubscribedRegistrants.length === 0
              ? 'Send Email'
              : `Send Email (${selectedAndSubscribedRegistrants.length})`
          }
        >
          <AppStack style={{ gap: 20 }}>
            <TextInput
              disabled={formState.isLoading}
              label="Subject"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  subject: e.currentTarget.value,
                  hasUnsavedChanges: true
                })
              }
              required
              value={formState.subject}
            />
            <Textarea
              autosize
              disabled={formState.isLoading}
              label="Body"
              minRows={12}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  body: e.currentTarget.value,
                  hasUnsavedChanges: true
                })
              }
              required
              value={formState.body}
            />
            <AppFlexbox
              style={{
                justifyContent: 'space-between',
                flexDirection: rs(['column-reverse', 'row'], mqIndex),
                gap: 30,
                paddingTop: 10
              }}
            >
              <Checkbox
                checked={formState.sendCopy}
                disabled={formState.isLoading}
                label="Send a copy to my email"
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    sendCopy: e.currentTarget.checked,
                    hasUnsavedChanges: true
                  })
                }
              />
              <Button
                disabled={formState.isLoading}
                onClick={() =>
                  setFormState({
                    ...formState,
                    showEmailSelect: true
                  })
                }
                size="compact-sm"
                style={{
                  width: rs(['100%', 'unset'], mqIndex),
                  maxWidth: 250
                }}
              >
                Edit Recipients
              </Button>
            </AppFlexbox>
          </AppStack>
        </FormSection>
      ) : (
        <FormSection
          cancelTitle="Back"
          isSubmitHidden
          onCancel={() =>
            setFormState({
              ...formState,
              showEmailSelect: false
            })
          }
        >
          <AppStack style={{ gap: 5 }}>
            <Select
              data={filterOptions.seasonOptions}
              label="Season"
              onChange={(value) =>
                setFormState({
                  ...formState,
                  fkRegAssociationSeason: value,
                  fkRegAssociationDivision: null
                })
              }
              value={formState.fkRegAssociationSeason}
            />
            <Select
              data={filterOptions.divisionOptions.filter(
                (d) =>
                  !formState.fkRegAssociationSeason ||
                  d.fkRegAssociationSeason === formState.fkRegAssociationSeason
              )}
              label="Division"
              onChange={(value) =>
                setFormState({
                  ...formState,
                  fkRegAssociationDivision: value
                })
              }
              value={formState.fkRegAssociationDivision}
            />
            <AppFlexbox
              style={{ justifyContent: 'space-between', marginTop: 10 }}
            >
              <AppText weight={500}>Recipients</AppText>
              <Checkbox
                checked={allRegistrantsSelected}
                disabled={!hasAvailableRegistrants || seasonHasEnded}
                label={allRegistrantsSelected ? 'Unselect All' : 'Select All'}
                onChange={() => {
                  if (allRegistrantsSelected) {
                    setFormState({
                      ...formState,
                      selectedRegistrants: [],
                      hasUnsavedChanges: true
                    });
                  }
                  else {
                    setFormState({
                      ...formState,
                      selectedRegistrants: filteredWaitlistRegistrants.filter(
                        (r) =>
                          new Date(
                            formatUtcDate(
                              r.regAssociationDivisionForm
                                .regAssociationDivision.regAssociationSeason
                                .endDate
                            )
                          ) >= new Date()
                      ),
                      hasUnsavedChanges: true
                    });
                  }
                }}
              />
            </AppFlexbox>
            <PaginationList
              emptyMessage="No Registrants Available"
              items={filteredWaitlistRegistrants
                .sort((a, b) =>
                  `${a.regPerson.firstName}${a.regPerson.lastName}`.localeCompare(
                    `${b.regPerson.firstName}${b.regPerson.lastName}`
                  )
                )
                .map((e) => {
                  const reservation = e?.waitlistReservations
                    .filter((r) => !r.deleted)
                    .sort(
                      (a, b) => new Date(a.expiryDate) - new Date(b.expiryDate)
                    )[0];
                  const expiryDateInfo = reservation
                    ? getExpiryDateInfo(reservation.expiryDate)
                    : null;

                  const hasRegistrantSeasonEnded =
                    new Date(
                      formatUtcDate(
                        e.regAssociationDivisionForm.regAssociationDivision
                          .regAssociationSeason.endDate
                      )
                    ) <= new Date();

                  const statusInfo = !reservation
                    ? {
                        label: 'Waiting',
                        color: 'dodgerblue'
                      }
                    : reservation.deleted || expiryDateInfo.hasExpired
                    ? {
                        label: 'Expired',
                        color: 'red'
                      }
                    : {
                        label: `Expires in ${
                          expiryDateInfo.expiryTimeDays > 1
                            ? singularPluralFormat(
                                expiryDateInfo.expiryTimeDays,
                                'day',
                                'days'
                              )
                            : expiryDateInfo.expiryTimeHours > 0
                            ? singularPluralFormat(
                                expiryDateInfo.expiryTimeHours,
                                'hour',
                                'hours'
                              )
                            : expiryDateInfo.expiryTimeMinutes > 0 &&
                              singularPluralFormat(
                                expiryDateInfo.expiryTimeMinutes,
                                'minute',
                                'minutes'
                              )
                        }`,
                        color: 'green'
                      };

                  const hasUnsubscribed = state.regEmailUnsubscribe.value.some(
                    (u) => u.email === e.regPerson.user.email
                  );

                  const isSelected =
                    !hasUnsubscribed &&
                    formState.selectedRegistrants.some(
                      (r) => r.pkRegWaitlistPerson === e.pkRegWaitlistPerson
                    );
                  return (
                    <Tooltip
                      key={e.pkRegWaitlistPerson}
                      disabled={!hasRegistrantSeasonEnded}
                      label="Season has ended"
                      position="top-start"
                      withArrow
                    >
                      <AppFlexbox
                        onClick={() => {
                          if (!hasUnsubscribed && !hasRegistrantSeasonEnded) {
                            if (isSelected) {
                              setFormState({
                                ...formState,
                                selectedRegistrants: formState.selectedRegistrants.filter(
                                  (r) =>
                                    r.regPerson.user.email !==
                                    e.regPerson.user.email
                                ),
                                hasUnsavedChanges: true
                              });
                            }
                            else {
                              setFormState({
                                ...formState,
                                selectedRegistrants: [
                                  ...formState.selectedRegistrants,
                                  ...filteredWaitlistRegistrants.filter(
                                    (r) =>
                                      r.regPerson.user.email ===
                                      e.regPerson.user.email
                                  )
                                ],
                                hasUnsavedChanges: true
                              });
                            }
                          }
                        }}
                        style={{
                          gap: 5,
                          justifyContent: 'space-between',
                          padding: 10,
                          cursor: 'pointer'
                        }}
                      >
                        <AppFlexbox
                          style={{
                            flex: 1,
                            alignItems: 'center',
                            gap: 10
                          }}
                        >
                          <Checkbox
                            checked={isSelected}
                            disabled={
                              hasUnsubscribed || hasRegistrantSeasonEnded
                            }
                            onChange={() => {}}
                          />
                          <AppStack style={{ flex: 1, gap: 0 }}>
                            <AppText
                              style={{ lineHeight: '20px' }}
                              weight={500}
                            >
                              {e.regPerson.firstName} {e.regPerson.lastName}
                            </AppText>
                            <AppText style={{ lineHeight: '20px' }}>
                              {e.regPerson.user.email}
                            </AppText>
                            {hasUnsubscribed && (
                              <AppText
                                style={{
                                  lineHeight: '20px',
                                  fontSize: 14,
                                  color: 'red'
                                }}
                              >
                                Unsubscribed
                              </AppText>
                            )}
                          </AppStack>
                        </AppFlexbox>

                        <AppStack style={{ textAlign: 'end', gap: 0 }}>
                          <AppText style={{ lineHeight: '20px' }} weight={500}>
                            {e.regAssociationDivisionForm.regForm.name}
                          </AppText>
                          <AppText
                            style={{
                              lineHeight: '20px',
                              color: statusInfo.color
                            }}
                          >
                            {statusInfo.label}
                          </AppText>
                        </AppStack>
                      </AppFlexbox>
                    </Tooltip>
                  );
                })}
              itemsPerPage={10}
              LoadingComponent={SelectableListItem}
              onPageChange={(pageIndex) =>
                setFormState({
                  ...formState,
                  pageIndex
                })
              }
              pageIndex={formState.pageIndex}
            />
          </AppStack>
        </FormSection>
      )}
      <ResponsiveModal
        isOpen={formState.showCloseConfirmation}
        onClose={() =>
          setFormState({
            ...formState,
            showCloseConfirmation: false
          })
        }
        title={<AppTitle order={2}>Discard Email?</AppTitle>}
      >
        <FormSection
          onCancel={() =>
            setFormState({
              ...formState,
              showCloseConfirmation: false
            })
          }
          onSubmit={() => {
            setFormState({
              ...formState,
              showCloseConfirmation: false
            });
            onClose();
          }}
          submitColor="red"
          submitTitle="Discard"
        >
          <AppText style={{ marginTop: 20, textAlign: 'center' }}>
            Are you sure you want to discard this email?
          </AppText>
        </FormSection>
      </ResponsiveModal>
    </ResponsiveModal>
  );
};

WaitlistEmailModal.propTypes = {
  fkRegAssociationDivision: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  fkRegAssociationSeason: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  pkRegAssociation: PropTypes.number,
  regWaitlistRegistrants: PropTypes.array
};

export default WaitlistEmailModal;
