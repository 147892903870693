import React, { useContext, useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate
} from 'react-router-dom';
import { Context as AuthContext } from '../providers/AuthProvider';
import CheckoutView from './CheckoutView';
import InvitesView from './InvitesView';
import OrdersView from './OrdersView';
import ParticipantsView from './ParticipantsView';
import RegistrationAdminViews from './RegistrationAdminViews';
import LoadableView from '../components/common/LoadableView';
import WaitlistView from './WaitlistView';
import UserSettingsView from './UserSettingsView';
import UserCommunicationView from '../components/content/communication/UserCommunicationView';

const UserViews = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { state: authState } = useContext(AuthContext);

  useEffect(() => {
    if (authState.tokenAttempted && !authState.isAuthenticated) {
      navigate(`/auth/login?redirect=${pathname}${search}`);
    }
  }, [authState.tokenAttempted]);

  return (
    <LoadableView
      isLoading={
        !authState.isAuthenticated || authState.regAssociationAdmins.loading
      }
    >
      {authState.isAuthenticated && (
        <Routes>
          <Route element={<CheckoutView />} path="checkout/*" />
          <Route element={<InvitesView />} path="invites/*" />
          <Route element={<OrdersView />} path="orders/*" />
          <Route element={<ParticipantsView />} path="participants/*" />
          <Route element={<UserSettingsView />} path="settings/*" />
          <Route element={<WaitlistView />} path="waitlist/*" />
          <Route element={<UserCommunicationView />} path="communication/*" />
          <Route element={<RegistrationAdminViews />} path="admin/*" />
          <Route element={<Navigate replace to="/" />} path="*" />
        </Routes>
      )}
    </LoadableView>
  );
};

export default UserViews;
