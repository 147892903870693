import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Select, TextInput } from '@mantine/core';
import dayjs from 'dayjs';
import ResponsiveModal from '../../common/ResponsiveModal';
import FormSection from '../../common/FormSection';
import AppTitle from '../../common/AppTitle';
import AppStack from '../../common/AppStack';
import TabsContent from '../../common/TabsContent';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import PaginationList from '../../common/PaginationList';
import { formatUtcDate } from '../../../helpers/format';

const MODAL_VIEW_TABS_ENUM = {
  REGISTRATIONS: 'REGISTRATIONS',
  EMAILS: 'EMAILS'
};

const MODAL_VIEW_TABS = [
  {
    label: 'Registrations',
    value: MODAL_VIEW_TABS_ENUM.REGISTRATIONS
  },
  {
    label: 'Emails',
    value: MODAL_VIEW_TABS_ENUM.EMAILS
  }
];

const EmailMailingsModal = ({
  isOpen,
  onClose,
  regFormSubmissionEmails,
  regAssociation
}) => {
  const [viewState, setViewState] = useState({
    activeTab: MODAL_VIEW_TABS_ENUM.REGISTRATIONS,
    registrationFilter: '',
    mailboxEmail: '',
    emailFilter: '',
    pageIndex: 1
  });

  const associationMailboxes = regFormSubmissionEmails
    .filter((e) =>
      e.regAssociations.some(
        (s) => s.pkRegAssociation === regAssociation?.pkRegAssociation
      )
    )
    .sort((a, b) => a.email.localeCompare(b.email));
  const associationFormSubmissions = associationMailboxes.reduce(
    (r, c) => [
      ...r,
      ...c.regFormSubmissions.filter(
        (s) =>
          s.regAssociationDivisionForm.regForm.fkRegAssociation ===
          regAssociation?.pkRegAssociation
      )
    ],
    []
  );
  const selectedMailbox = associationMailboxes.find(
    (e) => e.email === viewState.mailboxEmail
  );

  useEffect(() => {
    if (isOpen) {
      setViewState({
        activeTab: MODAL_VIEW_TABS_ENUM.REGISTRATIONS,
        mailboxEmail: associationMailboxes[0].email,
        registrationFilter: '',
        emailFilter: '',
        pageIndex: 1
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      centered
      onClose={onClose}
      opened={isOpen}
      size={700}
      title={<AppTitle order={2}>Email Details</AppTitle>}
    >
      <FormSection
        cancelTitle="Close"
        isSubmitHidden
        onCancel={onClose}
        onSubmit={() => {}}
      >
        {regAssociation && associationMailboxes.length > 0 && (
          <AppStack style={{ gap: 20 }}>
            <TabsContent
              activeTab={viewState.activeTab}
              color="blue"
              onTabChange={(value) =>
                setViewState({
                  ...viewState,
                  activeTab: value,
                  pageIndex: 1
                })
              }
              tabs={MODAL_VIEW_TABS}
              variant="outline"
            />

            {viewState.activeTab === MODAL_VIEW_TABS_ENUM.EMAILS ? (
              <>
                <Alert
                  color="blue"
                  styles={{ root: { padding: 10 }, body: { gap: 0 } }}
                  title="Important"
                  variant="outline"
                >
                  <AppStack style={{ gap: 0 }}>
                    <AppText style={{ fontSize: 14 }}>
                      This is a list of emails that were sent to '
                      <b>{selectedMailbox.email}</b>'. This list contains emails
                      from every association.
                    </AppText>
                    <AppText
                      style={{ fontSize: 14, color: '#313234' }}
                      weight={500}
                    >
                      This does not include invites or payment emails. If an
                      email was not delivered, it will be marked as "Not
                      Delivered".
                    </AppText>
                  </AppStack>
                </Alert>
                {associationMailboxes.length > 1 && (
                  <Select
                    data={associationMailboxes.map((e) => ({
                      label: e.email,
                      value: e.email
                    }))}
                    label="Mailbox Email"
                    onChange={(value) => {
                      if (value) {
                        setViewState({
                          ...viewState,
                          mailboxEmail: value
                        });
                      }
                    }}
                    value={viewState.mailboxEmail}
                  />
                )}
                <AppStack style={{ gap: 5 }}>
                  <AppFlexbox
                    style={{
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <AppText style={{ fontSize: 20 }} weight={500}>
                      Emails ({selectedMailbox.sesEmails.length})
                    </AppText>
                    <TextInput
                      onChange={(e) =>
                        setViewState({
                          ...viewState,
                          emailFilter: e.target.value
                        })
                      }
                      placeholder="Search emails..."
                      style={{ flex: 1, maxWidth: 300 }}
                      value={viewState.emailFilter}
                    />
                  </AppFlexbox>
                  <PaginationList
                    emptyMessage="No emails found."
                    items={selectedMailbox.sesEmails
                      .filter(
                        (e) =>
                          !viewState.emailFilter ||
                          e.sesEmailSession.subject
                            .replace(' ', '')
                            .toLowerCase()
                            .includes(
                              viewState.emailFilter
                                .replace(' ', '')
                                .toLowerCase()
                            ) ||
                          e.sesEmailSession.body
                            .replace(' ', '')
                            .toLowerCase()
                            .includes(
                              viewState.emailFilter
                                .replace(' ', '')
                                .toLowerCase()
                            )
                      )
                      .sort(
                        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                      )
                      .map((e) => {
                        const statusInfo = e.sent
                          ? {
                              color: 'green',
                              label: 'Sent'
                            }
                          : {
                              color: 'red',
                              label: 'Not Delivered'
                            };
                        return (
                          <AppFlexbox key={e.pkSesEmail} style={{ padding: 5 }}>
                            <AppStack style={{ flex: 1, gap: 0 }}>
                              <AppText
                                style={{ lineHeight: '20px' }}
                                weight={500}
                              >
                                {e.sesEmailSession.subject}
                              </AppText>
                              <AppStack style={{ lineHeight: '20px' }}>
                                {dayjs(formatUtcDate(e.createdAt)).format(
                                  'MM/DD/YYYY h:mm a'
                                )}
                              </AppStack>
                              <AppStack
                                style={{
                                  color: statusInfo.color,
                                  lineHeight: '20px'
                                }}
                              >
                                {statusInfo.label}
                              </AppStack>
                            </AppStack>
                          </AppFlexbox>
                        );
                      })}
                    onPageChange={(pageIndex) =>
                      setViewState({
                        ...viewState,
                        pageIndex
                      })
                    }
                    pageIndex={viewState.pageIndex}
                  />
                </AppStack>
              </>
            ) : (
              <AppStack style={{ gap: 5 }}>
                <AppFlexbox
                  style={{
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <AppText style={{ fontSize: 20 }} weight={500}>
                    Registrations ({associationFormSubmissions.length})
                  </AppText>
                  <TextInput
                    onChange={(e) =>
                      setViewState({
                        ...viewState,
                        registrationFilter: e.target.value
                      })
                    }
                    placeholder="Search registrations..."
                    style={{ flex: 1, maxWidth: 300 }}
                    value={viewState.registrationFilter}
                  />
                </AppFlexbox>
                <PaginationList
                  emptyMessage="No registrations found."
                  items={associationFormSubmissions
                    .filter(
                      (s) =>
                        !viewState.registrationFilter ||
                        `${s.regAssociationDivisionForm.regAssociationDivision.name}-${s.regAssociationDivisionForm.regForm.name}`
                          .replace(' ', '')
                          .toLowerCase()
                          .includes(
                            viewState.registrationFilter
                              .replace(' ', '')
                              .toLowerCase()
                          ) ||
                        `${s.regPerson.firstName}${s.regPerson.lastName}`
                          .replace(' ', '')
                          .toLowerCase()
                          .includes(
                            viewState.registrationFilter
                              .replace(' ', '')
                              .toLowerCase()
                          )
                    )
                    .map((s) => {
                      const statusInfo =
                        s.status === 'ACCEPTED'
                          ? {
                              color: 'green',
                              label: 'ACCEPTED'
                            }
                          : s.status === 'DENIED'
                          ? {
                              color: 'red',
                              label: 'DENIED'
                            }
                          : s.status === 'REFUNDED'
                          ? {
                              color: 'gray',
                              label: 'REFUNDED'
                            }
                          : {
                              color: 'blue',
                              label: 'PENDING'
                            };
                      return (
                        <AppFlexbox
                          key={s.pkRegFormSubmission}
                          style={{ padding: 5 }}
                        >
                          <AppStack style={{ flex: 1, gap: 0 }}>
                            <AppText
                              style={{ lineHeight: '20px' }}
                              weight={500}
                            >
                              {
                                s.regAssociationDivisionForm
                                  .regAssociationDivision.name
                              }{' '}
                              - {s.regAssociationDivisionForm.regForm.name}
                            </AppText>
                            <AppStack style={{ lineHeight: '20px' }}>
                              {s.regPerson.firstName} {s.regPerson.lastName}
                            </AppStack>
                            <AppStack style={{ lineHeight: '20px' }}>
                              {dayjs(formatUtcDate(s.submittedAt)).format(
                                'MMM D, YYYY'
                              )}
                            </AppStack>
                            <AppStack
                              style={{
                                color: statusInfo.color,
                                lineHeight: '20px'
                              }}
                            >
                              {statusInfo.label}
                            </AppStack>
                          </AppStack>
                        </AppFlexbox>
                      );
                    })}
                  onPageChange={(pageIndex) =>
                    setViewState({
                      ...viewState,
                      pageIndex
                    })
                  }
                  pageIndex={viewState.pageIndex}
                />
              </AppStack>
            )}
          </AppStack>
        )}
      </FormSection>
    </ResponsiveModal>
  );
};

EmailMailingsModal.propTypes = {
  fkRegAssociation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  regAssociation: PropTypes.object,
  regFormSubmissionEmails: PropTypes.array
};

export default EmailMailingsModal;
