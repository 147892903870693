import React from 'react';
import { Button } from '@mantine/core';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const AdminSidebarButton = ({ link, label, isSelected, isSublink, target }) => (
  <Button
    color="gray"
    component={Link}
    justify="start"
    size={isSublink ? 'sm' : 'lg'}
    style={{
      marginLeft: isSublink ? 30 : 0,
      background: 'unset',
      ...(isSelected
        ? {
            color: '#000',
            background: '#ededed',
            outline: '1px solid #FFF',
            boxShadow:
              'inset 0px -2px 6px 0px rgba(100, 100, 100, 0.2), inset 0px 2px 6px 0px rgba(100, 100, 100, 0.2), inset 0px 2px 4px 0px rgba(100, 100, 100, 0.2)'
          }
        : { color: 'grey' })
    }}
    target={target}
    to={link}
    variant={isSelected ? 'light' : 'subtle'}
  >
    {label}
  </Button>
);

AdminSidebarButton.propTypes = {
  isSelected: PropTypes.bool,
  isSublink: PropTypes.bool,
  label: PropTypes.string,
  link: PropTypes.string,
  target: PropTypes.string
};

export default AdminSidebarButton;
