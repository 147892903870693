import React from 'react';
import { notifications } from '@mantine/notifications';
import { AlertCircle, CircleCheck } from 'tabler-icons-react';
import { theme } from '../styles/theme';

const triggerNotification = (message, title, notificationColor) => {
  const color = notificationColor ?? 'red';
  const icon =
    notificationColor === 'green' ? <CircleCheck /> : <AlertCircle />;

  notifications.show({
    id: 'app-notification',
    withCloseButton: true,
    title: title ?? 'Error',
    message: message ?? 'Oops something went wrong!',
    color,
    icon,
    className: 'my-notification-class',
    styles: {
      root: {
        backgroundColor: theme.colors[color][6],
        borderColor: theme.colors[color][6]
      },
      title: { color: '#FFF' },
      description: { color: '#FFF' },
      closeButton: { color: '#FFF' }
    }
  });
};

export { triggerNotification };
