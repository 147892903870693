import React from 'react';
import {
  Button,
  Checkbox,
  Divider,
  Skeleton,
  Switch,
  TextInput
} from '@mantine/core';
import PropTypes from 'prop-types';
import { DeviceFloppy } from 'tabler-icons-react';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import EvaluationSessionEvaluateMenuList from './EvaluationSessionEvaluateMenuList';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { validateEvaluationQuestionRestrictions } from '../../../helpers/evaluationPlayerHelper';

const EVALUATION_SESSION_VIEWS_ENUM = {
  player: 'SCORE_BY_PLAYER',
  criterion: 'SCORE_BY_CRITERION',
  grid: 'SCORING_GRID'
};

const EVALUATION_SESSION_VIEWS = [
  {
    label: 'SCORE BY PLAYER',
    value: EVALUATION_SESSION_VIEWS_ENUM.player
  },
  {
    label: 'SCORE BY CRITERION',
    value: EVALUATION_SESSION_VIEWS_ENUM.criterion
  }
];

const EvaluationSessionEvaluateMenuNav = ({
  regAssociationDivision,
  regScoutReports,
  regScoutReportQuestions,
  onSelectQuestion,
  onSelectEvaluation,
  evaluationSessionEntry,
  regScoutReportEvaluations,
  selectedRegScoutReportEvaluation,
  selectedRegScoutReportQuestion,
  currentView,
  onViewChange,
  onSaveChanges,
  hasUnsavedChanges,
  isLoading,
  isSaving,
  filter,
  setFilter
}) => {
  const mqIndex = useMediaQueryIndex();
  const isMobileView = mqIndex === 0;
  const isTabletView = mqIndex <= 1;
  const isMediumView = mqIndex <= 2;

  return !isLoading ? (
    <AppStack
      style={{
        flex: 1,
        gap: 15
      }}
    >
      <AppFlexbox style={{ gap: 10 }}>
        {EVALUATION_SESSION_VIEWS.map((view) => {
          const isSelected = currentView === view.value;

          return (
            <Button
              key={view.value}
              color="dark"
              onClick={() => onViewChange(view.value)}
              size={isTabletView ? 'large' : 'compact-md'}
              style={{ flex: 1 }}
              styles={{ label: { whiteSpace: 'pre-wrap' } }}
              variant={isSelected ? 'filled' : 'outline'}
            >
              {view.label}
            </Button>
          );
        })}
      </AppFlexbox>
      <Divider />
      <AppFlexbox
        style={{
          justifyContent: 'space-between',
          gap: 15,
          flexDirection: isMobileView ? 'column' : 'row'
        }}
      >
        <AppStack style={{ gap: 15 }}>
          <Switch
            checked={filter.hideCompleted}
            label="HIDE COMPLETED"
            onChange={() =>
              setFilter({
                ...filter,
                hideCompleted: !filter.hideCompleted
              })
            }
          />
          <Switch
            checked={filter.expandAll}
            label="EXPAND ALL"
            onChange={() =>
              setFilter({
                ...filter,
                expandAll: !filter.expandAll
              })
            }
          />
        </AppStack>

        {currentView === EVALUATION_SESSION_VIEWS_ENUM.player && (
          <AppStack style={{ gap: 15 }}>
            <Checkbox
              checked={filter.sortBy === 'name'}
              label="SORT BY NAME"
              onChange={() =>
                setFilter({
                  ...filter,
                  sortBy: 'name'
                })
              }
            />
            <Checkbox
              checked={filter.sortBy === 'number'}
              label="SORT BY NUMBER"
              onChange={() =>
                setFilter({
                  ...filter,
                  sortBy: 'number'
                })
              }
            />
          </AppStack>
        )}
      </AppFlexbox>
      <AppStack style={{ gap: 10 }}>
        <TextInput
          onChange={(e) =>
            setFilter({
              ...filter,
              search: e.currentTarget.value
            })
          }
          placeholder="Search Players"
          value={filter.search}
        />
        <AppFlexbox
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            minHeight: 30
          }}
        >
          <AppText style={{ fontSize: 20, textWrap: 'nowrap' }} weight={500}>
            {currentView === EVALUATION_SESSION_VIEWS_ENUM.criterion
              ? 'Eval Criteria'
              : regAssociationDivision?.name}
          </AppText>

          {hasUnsavedChanges && (
            <Button
              color="blue"
              loading={isSaving}
              onClick={onSaveChanges}
              rightSection={!isTabletView && <DeviceFloppy />}
              size="compact-md"
              style={{ minWidth: 75 }}
              variant="filled"
            >
              {isMobileView ? 'Save' : 'Save Changes'}
            </Button>
          )}
        </AppFlexbox>

        {currentView === EVALUATION_SESSION_VIEWS_ENUM.player ? (
          <EvaluationSessionEvaluateMenuList
            expandAll={filter.expandAll}
            hideCompleted={filter.hideCompleted}
            items={regScoutReportEvaluations
              .filter(
                (e) =>
                  !filter.search ||
                  `${e.person.firstName}${e.person.lastName}`
                    .replace(' ', '')
                    .toLowerCase()
                    .includes(filter.search.replace(' ', '').toLowerCase())
              )
              .sort((a, b) =>
                filter.sortBy === 'number'
                  ? Number(a.jerseyNumber) - Number(b.jerseyNumber) ||
                    `${a.person.firstName}${a.person.lastName}`.localeCompare(
                      `${b.person.firstName}${b.person.lastName}`
                    ) ||
                    new Date(a.person.createdAt) - new Date(b.person.createdAt)
                  : `${a.person.firstName}${a.person.lastName}`.localeCompare(
                      `${b.person.firstName}${b.person.lastName}`
                    ) ||
                    new Date(a.person.createdAt) - new Date(b.person.createdAt)
              )
              .map((e) => ({
                key: e.pkRegScoutReportEvaluation,
                circleColor: e.jerseyColor,
                avatarLabel: `${e.person.firstName} ${e.person.lastName}`,
                label: `${e.jerseyNumber ? `${e.jerseyNumber} - ` : ''}${
                  e.person.firstName
                } ${e.person.lastName}`,
                subLabel: e.person.email,
                isSelected:
                  selectedRegScoutReportEvaluation?.pkRegScoutReportEvaluation ===
                  e.pkRegScoutReportEvaluation,
                subSections: regScoutReports.map((r) => ({
                  key: r.pkRegScoutReport,
                  label: r.name,
                  items: regScoutReportQuestions
                    .filter(
                      (q) =>
                        q.fkRegScoutReport === r.pkRegScoutReport &&
                        validateEvaluationQuestionRestrictions(e, q)
                    )
                    .sort((a, b) => a.sort - b.sort)
                    .map((q) => ({
                      key: q.pkRegScoutReportQuestion,
                      label: q.value,
                      answerValue: e.regScoutReportQuestionAnswers.find(
                        (f) =>
                          f.fkRegScoutReportQuestion ===
                            q.pkRegScoutReportQuestion &&
                          f.fkRegScoutReportEvaluationSessionEntry ===
                            evaluationSessionEntry?.pkRegScoutReportEvaluationSessionEntry
                      )?.value,
                      isSelected:
                        q.pkRegScoutReportQuestion ===
                        selectedRegScoutReportQuestion?.pkRegScoutReportQuestion
                    }))
                }))
              }))}
            onChangeHideCompleted={(hideCompleted) =>
              setFilter({
                ...filter,
                hideCompleted
              })
            }
            onClearSearch={() => setFilter({ ...filter, search: '' })}
            onSelectItem={(item) => {
              const evaluation = regScoutReportEvaluations.find(
                (e) => e.pkRegScoutReportEvaluation === item.key
              );

              const questions = regScoutReportQuestions
                .filter((q) =>
                  validateEvaluationQuestionRestrictions(evaluation, q)
                )
                .sort((a, b) => a.sort - b.sort);

              const question = questions.find(
                (f) =>
                  f.pkRegScoutReportQuestion ===
                  selectedRegScoutReportQuestion?.pkRegScoutReportQuestion
              );

              onSelectEvaluation(
                item.key,
                question?.pkRegScoutReportQuestion ||
                  questions[0]?.pkRegScoutReportQuestion
              );
            }}
            onSelectSubItem={(item, subItem) => {
              document
                .getElementById(`question-card-${subItem.key}`)
                ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
              onSelectEvaluation(item.key, subItem.key, true);
            }}
          />
        ) : (
          <EvaluationSessionEvaluateMenuList
            expandAll={filter.expandAll}
            hideCompleted={filter.hideCompleted}
            items={regScoutReportQuestions
              .filter(
                (q) =>
                  !filter.search ||
                  q.value
                    .replace(' ', '')
                    .toLowerCase()
                    .includes(filter.search.replace(' ', '').toLowerCase())
              )
              .sort((a, b) => a.sort - b.sort)
              .map((q) => ({
                key: q.pkRegScoutReportQuestion,
                label: q.value,
                isSelected:
                  selectedRegScoutReportQuestion?.pkRegScoutReportQuestion ===
                  q.pkRegScoutReportQuestion,
                subSections: [
                  {
                    key: regAssociationDivision.pkRegAssociationDivision,
                    label: regAssociationDivision.name,
                    items: regScoutReportEvaluations
                      .filter((e) =>
                        validateEvaluationQuestionRestrictions(e, q)
                      )
                      .sort((a, b) =>
                        filter.sortBy === 'number'
                          ? Number(a.jerseyNumber) - Number(b.jerseyNumber) ||
                            `${a.person.firstName}${a.person.lastName}`.localeCompare(
                              `${b.person.firstName}${b.person.lastName}`
                            ) ||
                            new Date(a.person.createdAt) -
                              new Date(b.person.createdAt)
                          : `${a.person.firstName}${a.person.lastName}`.localeCompare(
                              `${b.person.firstName}${b.person.lastName}`
                            ) ||
                            new Date(a.person.createdAt) -
                              new Date(b.person.createdAt)
                      )
                      .map((e) => ({
                        key: e.pkRegScoutReportEvaluation,
                        circleColor: e.jerseyColor,
                        label: `${
                          e.jerseyNumber ? `${e.jerseyNumber} - ` : ''
                        }${e.person.firstName} ${e.person.lastName}`,
                        answerValue: e.regScoutReportQuestionAnswers.find(
                          (f) =>
                            f.fkRegScoutReportQuestion ===
                              q.pkRegScoutReportQuestion &&
                            f.fkRegScoutReportEvaluationSessionEntry ===
                              evaluationSessionEntry?.pkRegScoutReportEvaluationSessionEntry
                        )?.value,
                        isSelected:
                          e.pkRegScoutReportEvaluation ===
                          selectedRegScoutReportEvaluation?.pkRegScoutReportEvaluation
                      }))
                  }
                ]
              }))}
            onChangeHideCompleted={(hideCompleted) =>
              setFilter({
                ...filter,
                hideCompleted
              })
            }
            onClearSearch={() => setFilter({ ...filter, search: '' })}
            onSelectItem={(item) => {
              onSelectQuestion(item.key);
            }}
            onSelectSubItem={(item, subItem) => {
              document
                .getElementById(`question-card-${subItem.key}`)
                ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
              onSelectEvaluation(subItem.key, item.key, true);
            }}
            variant="progress"
          />
        )}
      </AppStack>
    </AppStack>
  ) : (
    <AppStack style={{ flex: 1, gap: 15 }}>
      <AppFlexbox style={{ gap: 10 }}>
        {EVALUATION_SESSION_VIEWS.map((v) => (
          <Skeleton key={v.value} height={28} width="100%" />
        ))}
      </AppFlexbox>
      <Divider />
      <AppFlexbox style={{ justifyContent: 'space-between' }}>
        <AppStack>
          <Skeleton height={20} width={150} />
          <Skeleton height={20} width={150} />
        </AppStack>

        <AppStack>
          <Skeleton height={20} width={150} />
          <Skeleton height={20} width={150} />
        </AppStack>
      </AppFlexbox>
      <Skeleton height={36} width="100%" />
      <AppFlexbox
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          minHeight: 30
        }}
      >
        <Skeleton height={30} width="25%" />
      </AppFlexbox>
      <EvaluationSessionEvaluateMenuList isLoading />{' '}
    </AppStack>
  );
};

EvaluationSessionEvaluateMenuNav.propTypes = {
  currentView: PropTypes.string,
  evaluationSessionEntry: PropTypes.object,
  filter: PropTypes.object,
  hasUnsavedChanges: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSaving: PropTypes.bool,
  onSaveChanges: PropTypes.func,
  onSelectEvaluation: PropTypes.func,
  onSelectQuestion: PropTypes.func,
  onViewChange: PropTypes.func,
  regAssociationDivision: PropTypes.object,
  regScoutReportEvaluations: PropTypes.array,
  regScoutReportQuestions: PropTypes.array,
  regScoutReports: PropTypes.array,
  selectedRegScoutReportEvaluation: PropTypes.object,
  selectedRegScoutReportQuestion: PropTypes.object,
  setFilter: PropTypes.func
};

export default EvaluationSessionEvaluateMenuNav;
