import { Pagination, Skeleton, Table } from '@mantine/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';

const MerchPackageTransactionList = ({ payments, isLoading }) => {
  const MAX_PAGE_LENGTH = 5;
  const [pageIndex, setPageIndex] = useState(1);

  return (
    <AppStack style={{ gap: 10 }}>
      <AppFlexbox style={{ flex: 1, justifyContent: 'space-between' }}>
        <AppText weight={500}>Transaction Log</AppText>
      </AppFlexbox>

      <AppStack>
        <AppStack style={{ overflow: 'auto', flex: 1, gap: 0 }}>
          <Table
            horizontalSpacing="md"
            style={{ border: 'solid 1px #ced4da' }}
            verticalSpacing="md"
          >
            <Table.Thead>
              <Table.Tr>
                <Table.Th>
                  <AppText style={{ whiteSpace: 'nowrap' }}>Status</AppText>
                </Table.Th>
                <Table.Th>
                  <AppText style={{ whiteSpace: 'nowrap' }}>Date</AppText>
                </Table.Th>
                <Table.Th>
                  <AppText style={{ whiteSpace: 'nowrap' }}>
                    Transaction ID
                  </AppText>
                </Table.Th>
                <Table.Th>
                  <AppText style={{ whiteSpace: 'nowrap' }}>Type</AppText>
                </Table.Th>
                <Table.Th>
                  <AppText style={{ whiteSpace: 'nowrap' }}>
                    Description
                  </AppText>
                </Table.Th>
                <Table.Th style={{ borderRight: 'solid 1px #ced4da' }}>
                  <AppText style={{ whiteSpace: 'nowrap', textAlign: 'end' }}>
                    Gross Amount
                  </AppText>
                </Table.Th>
              </Table.Tr>
            </Table.Thead>

            <Table.Tbody>
              {isLoading ? (
                <>
                  {[...Array(2)].map((x, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Table.Tr key={i}>
                      <Table.Td>
                        <Skeleton height={10} width={60} />
                      </Table.Td>
                      <Table.Td>
                        <Skeleton height={10} width={125} />
                      </Table.Td>
                      <Table.Td>
                        <Skeleton height={10} width={80} />
                      </Table.Td>
                      <Table.Td>
                        <Skeleton height={10} width={80} />
                      </Table.Td>
                      <Table.Td style={{ borderRight: 'solid 1px #ced4da' }}>
                        <Skeleton
                          height={10}
                          style={{ alignSelf: 'end' }}
                          width={80}
                        />
                      </Table.Td>
                    </Table.Tr>
                  ))}
                </>
              ) : payments.length === 0 ? (
                <Table.Tr>
                  <Table.Td
                    colSpan="6"
                    style={{ padding: 50, textAlign: 'center' }}
                  >
                    <AppText weight={500}>- No Payments Received -</AppText>
                  </Table.Td>
                </Table.Tr>
              ) : (
                payments
                  ?.sort(
                    (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
                  )
                  .slice(
                    -MAX_PAGE_LENGTH + pageIndex * MAX_PAGE_LENGTH,
                    pageIndex * MAX_PAGE_LENGTH
                  )
                  .map((p) => (
                    <Table.Tr key={p.key}>
                      {p.rows.map((r) => (
                        <Table.Td key={r.key}>
                          <AppText
                            style={{
                              color: r.color,
                              textAlign: r.textAlign || 'start'
                            }}
                            weight={r.weight}
                          >
                            {r.label}
                          </AppText>
                        </Table.Td>
                      ))}
                    </Table.Tr>
                  ))
              )}
            </Table.Tbody>
          </Table>
        </AppStack>
        <AppFlexbox style={{ justifyContent: 'center' }}>
          <AppStack>
            {!isLoading && payments.length > MAX_PAGE_LENGTH && (
              <Pagination
                color="blue"
                onChange={setPageIndex}
                total={Math.ceil(payments.length / MAX_PAGE_LENGTH)}
                value={pageIndex}
              />
            )}
          </AppStack>
        </AppFlexbox>
      </AppStack>
    </AppStack>
  );
};

MerchPackageTransactionList.propTypes = {
  isLoading: PropTypes.bool,
  payments: PropTypes.array
};

export default MerchPackageTransactionList;
