import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Context as RegistrationAdminContext } from '../providers/RegistrationAdminProvider';

const getLeagueSelectOptions = (regAssociation, includeAll = false) => {
  const seasonOptions =
    regAssociation?.regAssociationSeasons
      .filter((a) => includeAll || a.regFormSubmissionCount > 0 || !a.deleted)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((s) => ({
        label: s.name,
        value: s.pkRegAssociationSeason.toString(),
        createdAt: s.createdAt
      })) ?? [];

  const divisionOptions =
    regAssociation?.regAssociationDivisions
      .filter((a) => includeAll || a.regFormSubmissionCount > 0 || !a.deleted)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((s) => ({
        label: s.name,
        value: s.pkRegAssociationDivision.toString(),
        fkRegAssociationSeason: s.fkRegAssociationSeason.toString(),
        createdAt: s.createdAt
      })) ?? [];

  const formOptions = regAssociation?.regAssociationDivisionForms
    .filter((a) => includeAll || a.regFormSubmissionCount > 0 || !a.deleted)
    .sort((a, b) => a.regForm.name.localeCompare(b.regForm.name))
    .reduce(
      (r, c) => {
        r.divisionForms.push({
          label: `${c.regAssociationDivision.name} - ${c.regForm.name}`,
          value: c.pkRegAssociationDivisionForm.toString(),
          fkRegAssociationDivision: c.regAssociationDivision.pkRegAssociationDivision.toString(),
          createdAt: c.createdAt
        });
        if (!r.forms.find((f) => f.value === c.regForm.uuid)) {
          r.forms.push({
            label: c.regForm.name,
            value: c.regForm.uuid,
            createdAt: c.regForm.createdAt
          });
        }
        return r;
      },
      {
        divisionForms: [],
        forms: []
      }
    );

  return {
    seasonOptions,
    divisionOptions,
    divisionFormOptions: formOptions?.divisionForms || [],
    formOptions: formOptions?.forms || [],
    unfilteredDivisionOptions: regAssociation?.regAssociationDivisions || []
  };
};

const useMyLeagueFilterOptions = (
  regAssociation,
  {
    includeSeason,
    includeDivision,
    includeRegPerson,
    useMyLeagueOptions,
    includeAllOptions,
    notRequiredFields
  }
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { state } = useContext(RegistrationAdminContext);
  const [filterState, setFilterState] = useState({
    pkRegAssociationSeason: '',
    pkRegAssociationDivision: '',
    regPersonName: ''
  });
  const {
    seasonOptions,
    divisionOptions,
    unfilteredDivisionOptions
  } = useMyLeagueOptions
    ? getLeagueSelectOptions(state.regAssociation.value, includeAllOptions)
    : {
        seasonOptions: state.regAssociationSeasons.value
          .filter(
            (s) => s.fkRegAssociation === regAssociation?.pkRegAssociation
          )
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((a) => ({
            value: a.pkRegAssociationSeason.toString(),
            label: a.name,
            createdAt: a.createdAt
          })),
        divisionOptions: state.regAssociationDivisions.value
          .filter(
            (d) =>
              !filterState.pkRegAssociationSeason ||
              d.fkRegAssociationSeason.toString() ===
                filterState.pkRegAssociationSeason
          )
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((a) => ({
            value: a.pkRegAssociationDivision.toString(),
            label: a.name,
            fkRegAssociationSeason: a.fkRegAssociationSeason.toString(),
            createdAt: a.createdAt
          })),
        unfilteredDivisionOptions: state.regAssociationDivisions.value
      };

  const filters = [
    ...(includeSeason || includeDivision
      ? [
          {
            key: 2,
            label: 'Season',
            type: 'select',
            value: filterState.pkRegAssociationSeason,
            placeholder: 'Filter by season',
            data: seasonOptions,
            clearable: notRequiredFields,
            onChange: (value) => {
              if (
                (notRequiredFields || value) &&
                value !== filterState.pkRegAssociationSeason
              ) {
                const newDivisionOptions = unfilteredDivisionOptions
                  .filter((d) => d.fkRegAssociationSeason.toString() === value)
                  .sort((a, b) => a.name.localeCompare(b.name));
                const selectedDivisionStillValid =
                  filterState.pkRegAssociationDivision &&
                  newDivisionOptions.some(
                    (p) =>
                      p.pkRegAssociationDivision.toString() ===
                      filterState.pkRegAssociationDivision
                  );
                const pkRegAssociationDivision = selectedDivisionStillValid
                  ? filterState.pkRegAssociationDivision
                  : !notRequiredFields
                  ? newDivisionOptions[0]?.pkRegAssociationDivision.toString() ||
                    null
                  : null;

                setFilterState({
                  ...filterState,
                  pkRegAssociationSeason: value,
                  pkRegAssociationDivision
                });
              }
            }
          }
        ]
      : []),
    ...(includeDivision
      ? [
          {
            key: 3,
            label: 'Division',
            type: 'select',
            value: filterState.pkRegAssociationDivision,
            placeholder: 'Filter by division',
            data: divisionOptions.filter(
              (d) =>
                !filterState.pkRegAssociationSeason ||
                d.fkRegAssociationSeason === filterState.pkRegAssociationSeason
            ),
            clearable: notRequiredFields,
            onChange: (value) => {
              const division = divisionOptions.find(
                (d) => d.value.toString() === value
              );
              if (
                (notRequiredFields || division) &&
                value !== filterState.pkRegAssociationDivision
              ) {
                const isSelectedSeasonStillValid =
                  !division ||
                  (filterState.pkRegAssociationSeason &&
                    division.fkRegAssociationSeason.toString() ===
                      filterState.pkRegAssociationSeason);
                setFilterState({
                  ...filterState,
                  pkRegAssociationDivision: value,
                  pkRegAssociationSeason:
                    isSelectedSeasonStillValid || !notRequiredFields
                      ? filterState.pkRegAssociationSeason
                      : null
                });
              }
            }
          }
        ]
      : []),
    ...(includeRegPerson
      ? [
          {
            key: 4,
            label: 'Registrant',
            type: 'text',
            value: filterState.regPersonName,
            placeholder: 'Filter by registrant',
            onChange: (value) =>
              setFilterState({
                ...filterState,
                regPersonName: value
              })
          }
        ]
      : [])
  ];

  useEffect(() => {
    if (
      regAssociation &&
      !filterState.pkRegAssociationSeason &&
      !notRequiredFields
    ) {
      const pkSeasonString = searchParams.get('season');
      const season = seasonOptions.find((d) => d.value === pkSeasonString);
      const pkSeason =
        season?.value ??
        seasonOptions.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )[0]?.value;
      const pkDivision = unfilteredDivisionOptions
        .filter((d) => d.fkRegAssociationSeason.toString() === pkSeason)
        .sort((a, b) => a.name.localeCompare(b.name))[0]
        ?.pkRegAssociationDivision.toString();
      setFilterState({
        ...filterState,
        pkRegAssociationSeason: pkSeason,
        pkRegAssociationDivision: includeDivision ? pkDivision : null
      });
    }
  }, [
    regAssociation,
    state.regAssociationSeasons.value,
    state.regAssociation.value,
    notRequiredFields
  ]);

  useEffect(() => {
    if (
      includeDivision &&
      filterState.pkRegAssociationSeason &&
      !filterState.pkRegAssociationDivision &&
      !notRequiredFields
    ) {
      const pkDivisionString = searchParams.get('division');
      const division = divisionOptions.find(
        (d) => d.value === pkDivisionString
      );
      setFilterState({
        ...filterState,
        pkRegAssociationDivision: division?.value ?? divisionOptions[0]?.value
      });
    }
  }, [
    filterState.pkRegAssociationSeason,
    state.regAssociationDivisions.value,
    state.regAssociation.value
  ]);

  const onResetFilterState = () => {
    setFilterState({
      pkRegAssociationSeason: null,
      pkRegAssociationDivision: null,
      regPersonName: null
    });
  };

  return {
    filters,
    regAssociationSeasonOptions: seasonOptions,
    regAssociationDivisionOptions: divisionOptions,
    filterState,
    onResetFilterState,
    setFilterState
  };
};

export { useMyLeagueFilterOptions, getLeagueSelectOptions };
