import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Ticket } from 'tabler-icons-react';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { useModalState, useSortByFilter } from '../../../helpers/hooks';
import AppCard from '../../common/AppCard';
import TableView from '../../common/TableView';
import GroupCouponModal from './GroupCouponModal';
import GroupCouponRegistrationsModal from './GroupCouponRegistrationsModal';
import { currencyFormat } from '../../../helpers/format';

const VIEW_ACTIONS_ENUM = {
  create: 'CREATE',
  edit: 'EDIT',
  expire: 'EXPIRE',
  reactivate: 'REACTIVATE',
  viewRegistrants: 'VIEW_REGISTRANTS'
};

const VIEW_ACTIONS = [
  {
    label: 'Edit',
    value: VIEW_ACTIONS_ENUM.edit
  },
  {
    label: 'Expire',
    value: VIEW_ACTIONS_ENUM.expire,
    hideWhenExpired: true
  },
  {
    label: 'Reactivate Coupon',
    value: VIEW_ACTIONS_ENUM.reactivate,
    showWhenExpired: true
  },
  {
    label: 'View Registrants',
    value: VIEW_ACTIONS_ENUM.viewRegistrants
  }
];

const COUPON_COLUMNS = [
  {
    label: 'Coupon Code',
    value: 'code',
    sortable: true
  },
  {
    label: 'Status',
    value: 'status',
    sortable: true
  },
  {
    label: 'Redemption Count',
    value: 'redeemedCount',
    sortable: true
  },
  {
    label: 'Max Redemptions',
    value: 'maxRedemptions',
    sortable: true
  },
  {
    label: 'Minimum Amount',
    value: 'minAmount',
    sortable: true
  },
  {
    label: 'Qualify Per Item',
    value: 'qualifyAmount',
    sortable: true
  },
  {
    label: 'Flat Discount',
    value: 'flatDiscount',
    sortable: true
  },
  {
    label: 'Percentage Discount',
    value: 'percentageDiscount',
    sortable: true
  }
];

const GroupCouponView = ({
  isLoading,
  regAssociation,
  modalAction,
  filterState,
  setFilterState
}) => {
  const { state, fetchAdminRegAssociationCoupons } = useContext(
    RegistrationAdminContext
  );
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const {
    sortValue,
    isDescendingSort,
    onChangeSortBy,
    customFilterData
  } = useSortByFilter(COUPON_COLUMNS);

  useEffect(() => {
    if (modalAction === VIEW_ACTIONS_ENUM.create) {
      onOpenModal(modalAction);
    }
  }, [modalAction]);

  return (
    <AppCard style={{ flex: 1, padding: 0, height: '100%' }}>
      <TableView
        columns={COUPON_COLUMNS}
        emptyMessage="No Coupons Available"
        filters={[
          {
            key: 1,
            label: 'Coupon Name',
            type: 'text',
            value: filterState.search,
            placeholder: 'Filter by coupon name',
            onChange: (value) =>
              setFilterState({
                ...filterState,
                search: value
              })
          }
        ]}
        isDescendingSort={isDescendingSort}
        isLoading={isLoading}
        lastUpdated={state.regAssociationGroupCoupons.lastUpdated}
        onAction={(action, item) => {
          onOpenModal(
            action,
            state.regAssociationGroupCoupons.value.find(
              (s) => s.pkRegAssociationGroupCoupon === item.key
            )
          );
        }}
        onChangeSortBy={onChangeSortBy}
        onRefresh={() =>
          fetchAdminRegAssociationCoupons(regAssociation.pkRegAssociation)
        }
        rows={state.regAssociationGroupCoupons.value
          .filter(
            (c) =>
              !filterState.search ||
              c.code
                .toLowerCase()
                .includes(filterState.search.replace(' ', '').toLowerCase())
          )
          .map((c) => ({
            key: c.pkRegAssociationGroupCoupon,
            actions: VIEW_ACTIONS.filter(
              (v) =>
                (!c.active && !v.hideWhenExpired) ||
                (c.active && !v.showWhenExpired)
            ),
            columns: [
              {
                key: 1,
                label: c.code,
                weight: 500,
                onClick: () => onOpenModal(VIEW_ACTIONS_ENUM.edit, c),
                subLabel: `${c.redeemedCount} ${
                  c.maxRedemptions ? `/ ${c.maxRedemptions}` : ''
                } redeemed`,
                icon: <Ticket color="black" size="30" />
              },
              {
                key: 2,
                label: !c.active ? 'Expired' : 'Active',
                color: !c.active ? 'red' : 'green'
              },
              {
                key: 3,
                label: c.redeemedCount,
                sortValueType: 'number'
              },
              {
                key: 4,
                label: c.maxRedemptions || 'N/A',
                sortValue: c.maxRedemptions ?? 0,
                sortValueType: 'number'
              },
              {
                key: 5,
                label: c.minimumQualifiedAmount,
                sortValueType: 'number'
              },
              {
                key: 6,
                label: c.qualifyPerItem ? 'Yes' : 'No' || 'N/A'
              },
              {
                key: 7,
                label: c.flatDiscount ? currencyFormat(c.flatDiscount) : 'N/A',
                sortValue: c.flatDiscount ?? 0,
                sortValueType: 'number'
              },
              {
                key: 8,
                label: c.percentageDiscount
                  ? `${c.percentageDiscount}%`
                  : 'N/A',
                sortValue: c.percentageDiscount ?? 0,
                sortValueType: 'number'
              }
            ]
          }))}
        sortBy={sortValue}
        sortFilter={customFilterData}
        tableTitle="Group Coupons"
      />

      <GroupCouponModal
        fkRegAssociation={regAssociation?.pkRegAssociation}
        groupCoupon={modalState.item}
        isOpen={
          modalState.isOpen &&
          modalState.action !== VIEW_ACTIONS_ENUM.viewRegistrants
        }
        onClose={onCloseModal}
        showExpireView={modalState.action === VIEW_ACTIONS_ENUM.expire}
        showReinstateView={modalState.action === VIEW_ACTIONS_ENUM.reactivate}
      />
      <GroupCouponRegistrationsModal
        groupCoupon={modalState.item}
        isOpen={
          modalState.isOpen &&
          modalState.action === VIEW_ACTIONS_ENUM.viewRegistrants
        }
        onClose={onCloseModal}
        pkRegAssociation={regAssociation?.pkRegAssociation}
      />
    </AppCard>
  );
};

GroupCouponView.propTypes = {
  filterState: PropTypes.object,
  isLoading: PropTypes.bool,
  modalAction: PropTypes.string,
  regAssociation: PropTypes.object,
  setFilterState: PropTypes.func
};

export default GroupCouponView;
