import { saveToStorage } from '../helpers/storageHelper';
import createDataProvider from './createDataProvider';
import { registrationReducer } from './reducer';

const initialState = {
  regAssociation: { value: null, pkRegAssociation: null },
  legacy: { isLegacyView: false }
};

const changeAssociation = (dispatch) => async (
  { pkRegAssociation, regAssociation },
  skipStorage = false
) => {
  try {
    if (!skipStorage) {
      saveToStorage('pkRegAssociation', pkRegAssociation);
    }
    dispatch({
      type: 'success',
      stateName: 'regAssociation',
      payload: {
        pkRegAssociation: pkRegAssociation?.toString(),
        value: regAssociation
      }
    });
  } catch {
    dispatch({
      type: 'error',
      stateName: 'regAssociation',
      payload: 'Oops something went wrong'
    });
  }
};

const changeLegacySettings = (dispatch) => async (
  { isLegacyView },
  skipStorage = false
) => {
  try {
    if (!skipStorage) {
      saveToStorage('isLegacyView', isLegacyView.toString());
    }
    dispatch({
      type: 'success',
      stateName: 'legacy',
      payload: { isLegacyView }
    });
  } catch {
    dispatch({
      type: 'error',
      stateName: 'legacy',
      payload: 'Oops something went wrong'
    });
  }
};

export const { Provider, Context } = createDataProvider(
  registrationReducer,
  { changeAssociation, changeLegacySettings },
  initialState
);
