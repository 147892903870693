import axios from 'axios';
import sportsheadzApi from '../apis/sportsheadzApi';
import createDataProvider from './createDataProvider';

const initialState = {
  teams: { value: [], loading: false, error: null },
  seasons: { value: [], loading: false, error: null },
  season: { value: null, loading: false, error: null }
};

const sportsheadzWebProvider = (state, action) => {
  switch (action.type) {
    case 'fetching':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          error: null,
          loading: true
        }
      };
    case 'success':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          ...action.payload,
          loading: false
        }
      };
    case 'append':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          value: [...state[action.stateName].value, action.payload],
          loading: false
        }
      };
    case 'replace':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          value: [
            ...state[action.stateName].value.filter(action.payload.filter),
            action.payload.value
          ],
          loading: false
        }
      };
    case 'remove':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          value: state[action.stateName].value.filter(action.payload),
          loading: false
        }
      };
    case 'error':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          error: action.payload,
          loading: false
        }
      };
    default:
      return state;
  }
};

const formatAssociationDomain = (domain) =>
  domain?.endsWith('/')
    ? domain.substring(0, domain.length - 1)
    : domain.trim();

const fetchAssociationTeams = (dispatch) => async (associationDomain) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'teams'
    });

    const response = await axios({
      method: 'get',
      url: `${formatAssociationDomain(associationDomain)}/api/admin/teams/all`
    });

    dispatch({
      type: 'success',
      stateName: 'teams',
      payload: { value: response.data }
    });
  } catch (e) {
    dispatch({
      type: 'error',
      stateName: 'teams',
      payload: 'Oops something went wrong'
    });
  }
};

const fetchAssociationSeasons = (dispatch) => async (associationDomain) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'seasons'
    });

    const response = await axios({
      method: 'get',
      url: `${formatAssociationDomain(
        associationDomain
      )}/api/admin/seasons/list`
    });

    dispatch({
      type: 'success',
      stateName: 'seasons',
      payload: { value: response.data }
    });
  } catch (e) {
    dispatch({
      type: 'error',
      stateName: 'seasons',
      payload: 'Oops something went wrong'
    });
  }
};

const fetchAssociationSeason = (dispatch) => async (
  associationDomain,
  pkSeason
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'season'
    });

    const response = await axios({
      method: 'get',
      url: `${formatAssociationDomain(
        associationDomain
      )}/api/admin/teams/season/${pkSeason}`
    });

    dispatch({
      type: 'success',
      stateName: 'season',
      payload: { value: response.data }
    });
  } catch (e) {
    dispatch({
      type: 'error',
      stateName: 'season',
      payload: 'Oops something went wrong'
    });
  }
};

export const { Provider, Context } = createDataProvider(
  sportsheadzWebProvider,
  {
    fetchAssociationTeams,
    fetchAssociationSeasons,
    fetchAssociationSeason
  },
  initialState
);
