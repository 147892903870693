import React from 'react';
import { Button } from '@mantine/core';
import PropTypes from 'prop-types';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import EvaluationSessionPlayerAnswerAssign from './EvaluationSessionPlayerAnswerAssign';
import { REG_SCOUT_REPORT_QUESTION_TYPE } from '../../../config/constants';

const EVALUATION_SESSION_VIEWS_ENUM = {
  player: 'SCORE_BY_PLAYER',
  criterion: 'SCORE_BY_CRITERION',
  grid: 'SCORING_GRID'
};

const EVALUATION_SESSION_VIEWS = [
  {
    label: 'SCORE BY PLAYER',
    value: EVALUATION_SESSION_VIEWS_ENUM.player
  },
  {
    label: 'SCORE BY CRITERION',
    value: EVALUATION_SESSION_VIEWS_ENUM.criterion
  }
];

const EvaluationSessionEvaluateGridView = ({
  regScoutReports,
  regScoutReportEvaluationSession,
  regScoutReportEvaluations,
  evaluationSessionEntry,
  onUpdateEvaluation,
  onUpdateEvaluationAnswer,
  onUpdateEvaluations,
  currentView,
  onViewChange,
  onSaveChanges,
  hasUnsavedChanges,
  isSaving
}) => {
  const metricQuestions =
    regScoutReports[0]?.regScoutReportQuestions.filter(
      (q) =>
        q.fkRegScoutReportQuestionType === REG_SCOUT_REPORT_QUESTION_TYPE.METRIC
    ) || [];

  return (
    <AppStack
      style={{
        gap: 0,
        padding: 10,
        paddingBottom: 40,
        flex: 1,
        overflow: 'auto'
      }}
    >
      <AppFlexbox style={{ gap: 10, justifyContent: 'end' }}>
        {EVALUATION_SESSION_VIEWS.map((view) => {
          const isSelected = currentView === view.value;
          return (
            <Button
              key={view.value}
              color="dark"
              onClick={() => onViewChange(view.value)}
              size="compact-md"
              style={{ flex: 1, maxWidth: 250 }}
              variant={isSelected ? 'filled' : 'outline'}
            >
              {view.label}
            </Button>
          );
        })}
      </AppFlexbox>
      <EvaluationSessionPlayerAnswerAssign
        attributeQuestions={metricQuestions}
        evaluationSessionEntry={evaluationSessionEntry}
        hasUnsavedChanges={hasUnsavedChanges}
        hideAttributes
        isSaving={isSaving}
        onSaveChanges={onSaveChanges}
        onUpdateEvaluation={onUpdateEvaluation}
        onUpdateEvaluationAnswer={onUpdateEvaluationAnswer}
        onUpdateEvaluations={onUpdateEvaluations}
        regScoutReportEvaluations={regScoutReportEvaluations}
        regScoutReportEvaluationSession={regScoutReportEvaluationSession}
        title="Eval Criteria"
      />
    </AppStack>
  );
};

EvaluationSessionEvaluateGridView.propTypes = {
  currentView: PropTypes.string,
  evaluationSessionEntry: PropTypes.object,
  filter: PropTypes.object,
  hasUnsavedChanges: PropTypes.bool,
  isSaving: PropTypes.bool,
  onSaveChanges: PropTypes.func,
  onUpdateEvaluation: PropTypes.func,
  onUpdateEvaluationAnswer: PropTypes.func,
  onUpdateEvaluations: PropTypes.func,
  onViewChange: PropTypes.func,
  regScoutReportEvaluationSession: PropTypes.object,
  regScoutReportEvaluations: PropTypes.array,
  regScoutReports: PropTypes.array
};

export default EvaluationSessionEvaluateGridView;
