import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Accordion, Button, Checkbox, Loader } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppTitle from '../../common/AppTitle';
import FormSection from '../../common/FormSection';
import AppStack from '../../common/AppStack';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import PaginationSelectList from '../../common/PaginationSelectList';
import { REG_PERMISSION_ENUM } from '../../../config/constants';
import PaginationAccordionList from '../../common/PaginationAccordionList';
import { triggerNotification } from '../../../helpers/notificationHelper';

const MODAL_ASSIGN_ACTIONS_ENUM = {
  templates: 'ASSIGN-TEMPLATES',
  evaluators: 'ASSIGN-EVALUATORS'
};

const EvaluationSessionAssignModal = ({
  isOpen,
  onClose,
  onSaveChanges,
  regScoutReportEvaluationSession,
  action
}) => {
  const navigate = useNavigate();
  const hasFetchedEvaluators = useRef(false);
  const { state, fetchRegAssociationAdministrators } = useContext(
    RegistrationAdminContext
  );
  const [formState, setFormState] = useState({
    fkRegScoutReports: [],
    regScoutReportEvaluationSessionEntries: [],
    pageIndex: 1,
    isLoading: false
  });

  const evaluatorAdmins = state.regAssociationAdmins.value.filter(
    (a) =>
      !a.deleted &&
      a.user &&
      a.regAssociationAdminPermissions.some(
        (p) =>
          (p.fkRegPermission === REG_PERMISSION_ENUM.EVALUATIONS ||
            p.fkRegPermission === REG_PERMISSION_ENUM.SUPER_ADMIN) &&
          (p.regAssociationDivisions.length === 0 ||
            p.regAssociationDivisions.some(
              (d) =>
                d.pkRegAssociationDivision ===
                regScoutReportEvaluationSession?.regAssociationDivision
                  .pkRegAssociationDivision
            ))
      )
  );

  const isLoading =
    (action === MODAL_ASSIGN_ACTIONS_ENUM.evaluators &&
      (!hasFetchedEvaluators.current || state.regAssociationAdmins.loading)) ||
    state.regScoutReports.loading;

  useEffect(() => {
    if (isOpen) {
      setFormState({
        fkRegScoutReports: regScoutReportEvaluationSession.regScoutReportEvaluationSessionTemplates.map(
          (t) => t.fkRegScoutReport
        ),
        regScoutReportEvaluationSessionEntries: regScoutReportEvaluationSession.regScoutReportEvaluationSessionEntries.map(
          (s) => s
        ),
        pageIndex: 1,
        isLoading: false
      });

      if (
        action === MODAL_ASSIGN_ACTIONS_ENUM.evaluators &&
        !hasFetchedEvaluators.current
      ) {
        fetchRegAssociationAdministrators(
          regScoutReportEvaluationSession.regAssociationDivision
            .regAssociationSeason.fkRegAssociation,
          { includeDeleted: true }
        );
        hasFetchedEvaluators.current = true;
      }
    }
  }, [isOpen]);

  const onSelectEvaluators = (fkRegAssociationAdmins) => {
    if (formState.isLoading) return;

    const newKeys = fkRegAssociationAdmins.filter(
      (a) =>
        !formState.regScoutReportEvaluationSessionEntries.some(
          (e) => e.fkRegAssociationAdmin.toString() === a
        )
    );

    setFormState({
      ...formState,
      regScoutReportEvaluationSessionEntries: [
        ...formState.regScoutReportEvaluationSessionEntries.map((e) => ({
          ...e,
          deleted: !fkRegAssociationAdmins.includes(
            e.fkRegAssociationAdmin.toString()
          )
        })),
        ...newKeys.map((k) => ({
          fkRegAssociationAdmin: Number(k),
          canViewPlayerName: true,
          canSelectPlayers: true,
          canEditAttributes: true
        }))
      ]
    });
  };

  const onUpdateAssignedEvaluator = (updatedState) => {
    setFormState({
      ...formState,
      regScoutReportEvaluationSessionEntries: [
        ...formState.regScoutReportEvaluationSessionEntries.filter(
          (e) => e.fkRegAssociationAdmin !== updatedState.fkRegAssociationAdmin
        ),
        updatedState
      ]
    });
  };

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClose}
      title={
        <AppTitle order={2}>
          Assign{' '}
          {action === MODAL_ASSIGN_ACTIONS_ENUM.evaluators
            ? 'Evaluators'
            : 'Template'}
        </AppTitle>
      }
      transitionProps={{
        duration: 0,
        transition: 'none'
      }}
    >
      {isLoading ? (
        <AppStack
          style={{
            padding: 40,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Loader color="dark" size={40} />
        </AppStack>
      ) : (
        <FormSection
          isLoading={formState.isLoading}
          onCancel={onClose}
          onSubmit={(e) => {
            e.stopPropagation();
            setFormState({
              ...formState,
              isLoading: true
            });
            onSaveChanges(
              onClose,
              (error) => {
                setFormState({
                  ...formState,
                  isLoading: false
                });
                triggerNotification(error);
              },
              {
                regScoutReportEvaluationSessionTemplates: formState.fkRegScoutReports.map(
                  (fkRegScoutReport) => ({ fkRegScoutReport })
                ),
                regScoutReportEvaluationSessionEntries: formState.regScoutReportEvaluationSessionEntries.filter(
                  (a) => !a.deleted
                )
              }
            );
          }}
          submitTitle="Save Changes"
        >
          <AppStack style={{ gap: 20 }}>
            {action === MODAL_ASSIGN_ACTIONS_ENUM.evaluators ? (
              <AppStack style={{ gap: 5 }}>
                <AppFlexbox>
                  <AppText weight={500}>Evaluators</AppText>
                </AppFlexbox>
                <PaginationAccordionList
                  items={evaluatorAdmins
                    .sort((a, b) => a.user.name.localeCompare(b.user.name))
                    .map((a) => {
                      const assignedAdmin = formState.regScoutReportEvaluationSessionEntries.find(
                        (e) =>
                          e.fkRegAssociationAdmin === a.pkRegAssociationAdmin &&
                          !e.deleted
                      );
                      return (
                        <Accordion.Item
                          key={a.pkRegAssociationAdmin}
                          value={a.pkRegAssociationAdmin.toString()}
                        >
                          <Accordion.Control>
                            <AppStack style={{ gap: 0 }}>
                              <AppFlexbox style={{ alignItems: 'center' }}>
                                <Checkbox
                                  checked={!!assignedAdmin || false}
                                  disabled={formState.isLoading}
                                  onChange={() => {}}
                                />
                                <AppStack style={{ gap: 0 }}>
                                  <AppText weight={500}>{a.user.name}</AppText>
                                  <AppText
                                    style={{ fontSize: 14, color: 'darkgrey' }}
                                    weight={500}
                                  >
                                    {a.user.email}
                                  </AppText>
                                </AppStack>
                              </AppFlexbox>
                            </AppStack>
                          </Accordion.Control>
                          <Accordion.Panel>
                            <AppStack>
                              <Checkbox
                                checked={
                                  assignedAdmin
                                    ? assignedAdmin.canViewPlayerName
                                    : false
                                }
                                disabled={formState.isLoading}
                                label="Show player names"
                                onChange={() =>
                                  onUpdateAssignedEvaluator({
                                    ...assignedAdmin,
                                    canViewPlayerName: !assignedAdmin.canViewPlayerName
                                  })
                                }
                              />
                              <Checkbox
                                checked={
                                  assignedAdmin
                                    ? assignedAdmin.canSelectPlayers
                                    : false
                                }
                                disabled={formState.isLoading}
                                label="Enable player selection"
                                onChange={() =>
                                  onUpdateAssignedEvaluator({
                                    ...assignedAdmin,
                                    canSelectPlayers: !assignedAdmin.canSelectPlayers
                                  })
                                }
                              />
                              <Checkbox
                                checked={
                                  assignedAdmin
                                    ? assignedAdmin.canEditAttributes
                                    : false
                                }
                                disabled={formState.isLoading}
                                label="Allow editing player attributes"
                                onChange={() =>
                                  onUpdateAssignedEvaluator({
                                    ...assignedAdmin,
                                    canEditAttributes: !assignedAdmin.canEditAttributes
                                  })
                                }
                              />
                            </AppStack>
                          </Accordion.Panel>
                        </Accordion.Item>
                      );
                    })}
                  onChange={onSelectEvaluators}
                  value={formState.regScoutReportEvaluationSessionEntries
                    .filter((e) => !e.deleted)
                    .map((e) => e.fkRegAssociationAdmin.toString())}
                />
              </AppStack>
            ) : (
              <AppStack style={{ gap: 5 }}>
                <AppFlexbox>
                  <AppText weight={500}>Evaluation Templates</AppText>
                </AppFlexbox>
                {state.regScoutReports.length === 0 ? (
                  <AppStack
                    style={{
                      padding: '30px 10px',
                      justifyContent: 'center',
                      border: 'solid 1px dodgerblue'
                    }}
                  >
                    <AppText style={{ fontWeight: 500, textAlign: 'center ' }}>
                      No templates available. Please create one before you
                      continue.
                    </AppText>
                    <Button
                      onClick={() => {
                        navigate('/admin/evaluations/templates', {state: { openModal: true }});
                      }}
                    >
                      Create Template
                    </Button>
                  </AppStack>
                ) : (
                  <PaginationSelectList
                    disabled={formState.isLoading}
                    items={state.regScoutReports.value
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((r) => ({
                        key: r.pkRegScoutReport,
                        label: r.name
                      }))}
                    onChange={(fkRegScoutReports) =>
                      setFormState({
                        ...formState,
                        fkRegScoutReports
                      })
                    }
                    selectedItemKeys={formState.fkRegScoutReports}
                  />
                )}
              </AppStack>
            )}
          </AppStack>
        </FormSection>
      )}
    </ResponsiveModal>
  );
};

EvaluationSessionAssignModal.propTypes = {
  action: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSaveChanges: PropTypes.func,
  regScoutReportEvaluationSession: PropTypes.object
};

export default EvaluationSessionAssignModal;
