import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { TextInput } from '@mantine/core';
import FileUploader from '../../common/FileUploader';
import { downloadBlob, downloadUrlFile } from '../../../helpers/awsHelper';
import { triggerNotification } from '../../../helpers/notificationHelper';
import ResponsiveModal from '../../common/ResponsiveModal';
import FormSection from '../../common/FormSection';
import AppStack from '../../common/AppStack';
import AppTitle from '../../common/AppTitle';

const FileControlModal = ({ file, isDisabled, isOpen, onClose, onConfirm }) => {
  const [fileState, setFileState] = useState({
    file: null,
    url: ''
  });

  useEffect(() => {
    if (isOpen) {
      if (file) {
        if (typeof file === 'string') {
          setFileState({
            file: null,
            url: file
          });
        }
        else {
          setFileState({
            file,
            url: null
          });
        }
      }
    }
  }, [isOpen]);

  const downloadFile = () => {
    if (fileState.file) {
      downloadBlob(fileState.file, fileState.file.name);
    }
    else if (fileState.url) {
      downloadUrlFile(fileState.url);
    }
  };

  return (
    <ResponsiveModal
      onClose={onClose}
      onMouseDown={(e) => e.stopPropagation()}
      opened={isOpen}
      size={600}
      title={<AppTitle>Upload File</AppTitle>}
    >
      <FormSection
        isSubmitHidden={isDisabled}
        onCancel={onClose}
        onSubmit={(e) => {
          e.stopPropagation();
          onConfirm(fileState.file ?? fileState.url);
        }}
        submitTitle="Confirm Upload"
      >
        <AppStack style={{ flex: 1 }}>
          <FileUploader
            height={300}
            onError={triggerNotification}
            onUpload={(uploadedFile) => {
              setFileState({
                file: uploadedFile,
                url: null
              });
            }}
          />
        </AppStack>

        <TextInput
          disabled
          onChange={() => {}}
          placeholder="No file uploaded"
          value={
            fileState.url
              ? fileState.url.split('/').pop()
              : fileState.file?.name ?? ''
          }
        />
      </FormSection>
    </ResponsiveModal>
  );
};

FileControlModal.propTypes = {
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isDisabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func
};

export default FileControlModal;
