import dayjs from 'dayjs';
import { formatUtcDate } from './format';

const getInelligibleRegPersonReason = (
  regPerson,
  divisionForm,
  externalInvite = null
) => {
  const { firstName, lastName, dob, gender } = regPerson;
  const { regAssociationDivision } = divisionForm || {};

  if (
    externalInvite &&
    `${externalInvite.participantFirstName}${externalInvite.participantLastName}`.toLowerCase() !==
      `${firstName}${lastName}`.toLowerCase()
  ) {
    return 'Invite does not match registrant';
  }

  const dobDate = new Date(formatUtcDate(dob));
  if (
    regAssociationDivision?.minDob &&
    dobDate > new Date(formatUtcDate(regAssociationDivision.maxDob))
  ) {
    return ` Too young for this program. Born after ${dayjs(
      formatUtcDate(regAssociationDivision.maxDob)
    ).format('MM/DD/YYYY')}.`;
  }

  if (
    regAssociationDivision?.maxDob &&
    dobDate < new Date(formatUtcDate(regAssociationDivision.minDob))
  ) {
    return `Too old for this program. Born before ${dayjs(
      formatUtcDate(regAssociationDivision.minDob)
    ).format('MM/DD/YYYY')}`;
  }

  if (regAssociationDivision?.fkRegGenderType === 2 && gender !== 'Female') {
    return `The division '${regAssociationDivision.name}' is a girls only division.`;
  }

  if (regAssociationDivision?.fkRegGenderType === 3 && gender !== 'Male') {
    return `The division '${regAssociationDivision.name}' is a boys only division.`;
  }

  return null;
};

const getExpiryDateInfo = (expiresAt) => {
  const expiryDate = formatUtcDate(expiresAt);
  const hasExpired = new Date() >= new Date(expiryDate);
  let expiryTimeHours = dayjs(expiryDate).diff(dayjs(new Date()), 'hour');
  const expiryTimeMinutes = Math.ceil(
    dayjs(expiryDate).diff(dayjs(new Date()), 'minute', true)
  );
  const expiryTimeDays = Math.ceil(expiryTimeHours / 24);
  expiryTimeHours -= Math.floor(expiryTimeHours / 24) * 24;

  return { hasExpired, expiryTimeDays, expiryTimeHours, expiryTimeMinutes };
};

export { getInelligibleRegPersonReason, getExpiryDateInfo };
