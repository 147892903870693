import React, { useContext, useState } from 'react';
import { Plus, User } from 'tabler-icons-react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Button } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import TableView from '../../common/TableView';
import { getEvaluationRatingForSessions } from '../../../helpers/evaluationPlayerHelper';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { useModalState, useSortByFilter } from '../../../helpers/hooks';
import TeamDetailsModal from '../teams/TeamDetailsModal';

const VIEW_ACTIONS_ENUM = { view: 'VIEW' };

const TEAM_ACTIONS = [
  {
    label: 'View',
    value: VIEW_ACTIONS_ENUM.view
  }
];

const TABLE_COLUMNS = [
  {
    label: 'Name',
    value: 'name',
    sortable: true
  },
  {
    label: 'Age Requirements',
    value: 'age',
    sortable: true
  },
  {
    label: 'Overall Rating',
    value: 'overall',
    sortable: true
  }
];

const EvaluationSessionTeamSelect = ({
  regAssociation,
  isLoading,
  selectedPlayers,
  onSelectPlayers,
  userEvaluationSessionEntry,
  regScoutReportEvaluationSession
}) => {
  const navigate = useNavigate();
  const { state, fetchRegAssociationDivisionTeams } = useContext(
    RegistrationAdminContext
  );
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const {
    sortValue,
    isDescendingSort,
    onChangeSortBy,
    customFilterData
  } = useSortByFilter(TABLE_COLUMNS);
  const [filterState, setFilterState] = useState({ search: '' });
  const divisionTeams = state.regAssociationDivisionTeamsSummary.value.filter(
    (t) =>
      t.regAssociationDivision.pkRegAssociationDivision ===
        regScoutReportEvaluationSession?.regAssociationDivision
          .pkRegAssociationDivision && !t.deleted
  );

  const selectedTeams = divisionTeams.filter((t) =>
    t.regAssociationDivisionTeamPersons
      .filter((p) => p.active)
      .every((p) => selectedPlayers.includes(p.regPerson.pkRegPerson))
  );

  const onSelectTeamPlayers = (pkRegAssociationDivisionTeams = []) => {
    const newlyUnselectedTeams = selectedTeams.filter(
      (t) =>
        !pkRegAssociationDivisionTeams.includes(t.pkRegAssociationDivisionTeam)
    );
    const newSelectedTeams = divisionTeams.filter(
      (t) =>
        pkRegAssociationDivisionTeams.includes(
          t.pkRegAssociationDivisionTeam
        ) &&
        !selectedTeams.some(
          (s) =>
            s.pkRegAssociationDivisionTeams === t.pkRegAssociationDivisionTeam
        )
    );

    const unselectedRegPerson = newlyUnselectedTeams.reduce(
      (r, c) => [
        ...r,
        ...c.regAssociationDivisionTeamPersons
          .filter((t) => t.active)
          .map((p) => p.regPerson.pkRegPerson)
      ],
      []
    );
    const newlySelectedRegPerson = newSelectedTeams.reduce(
      (r, c) => [
        ...r,
        ...c.regAssociationDivisionTeamPersons
          .filter((t) => t.active)
          .map((p) => p.regPerson.pkRegPerson)
      ],
      []
    );

    const newlySelectedPlayers = [
      ...new Set([
        ...selectedPlayers.filter((p) => !unselectedRegPerson.includes(p)),
        ...newlySelectedRegPerson
      ])
    ];

    onSelectPlayers(newlySelectedPlayers);
  };

  return (
    <>
      <TableView
        columns={TABLE_COLUMNS}
        emptyActionButton={
          !userEvaluationSessionEntry ? (
            <Button
              leftSection={<Plus />}
              onClick={() =>
                navigate('/admin/league/teams', {
                  state: {
                    openModal: true,
                    pkRegAssociationDivision:
                      regScoutReportEvaluationSession.regAssociationDivision
                        .pkRegAssociationDivision
                  }
                })
              }
            >
              Setup Teams
            </Button>
          ) : null
        }
        emptyMessage="No Teams Available"
        filters={[
          {
            key: 1,
            label: 'Name',
            type: 'text',
            value: filterState.search,
            placeholder: 'Filter by name',
            onChange: (value) => {
              setFilterState((c) => ({
                ...c,
                search: value
              }));
            }
          }
        ]}
        isDescendingSort={isDescendingSort}
        isLoading={
          isLoading ||
          !regScoutReportEvaluationSession ||
          state.regScoutReportEvaluationSession.loading ||
          state.regAssociationDivisionTeamsSummary.loading
        }
        lastUpdated={state.regAssociationDivisionTeamsSummary.lastUpdated}
        onAction={(action, item) => {
          onOpenModal(
            action,
            divisionTeams.find(
              (s) => s.pkRegAssociationDivisionTeam === item.key
            )
          );
        }}
        onChangeSortBy={onChangeSortBy}
        onRefresh={() => {
          fetchRegAssociationDivisionTeams({
            pkRegAssociationDivision:
              regScoutReportEvaluationSession.regAssociationDivision
                .pkRegAssociationDivision,
            summaryOnly: true
          });
        }}
        onSelect={
          !userEvaluationSessionEntry ||
          userEvaluationSessionEntry.canSelectPlayers
            ? onSelectTeamPlayers
            : null
        }
        rows={divisionTeams
          .filter(
            (t) =>
              !filterState.search ||
              filterState.search.toLowerCase().includes(t.name.toLowerCase())
          )
          .map((t) => {
            const overallRating = t.regAssociationDivisionTeamPersons.reduce(
              (r, c) =>
                r +
                  getEvaluationRatingForSessions(
                    {
                      pkRegAssociationDivision:
                        t.regAssociationDivision.pkRegAssociationDivision,
                      pkRegPerson: c.regPerson.pkRegPerson,
                      pkRegScoutReportPerson: null
                    },
                    [regScoutReportEvaluationSession]
                  ).overallRating || 0,
              0
            );

            return {
              key: t.pkRegAssociationDivisionTeam,
              actions: TEAM_ACTIONS,
              columns: [
                {
                  key: 1,
                  label: t.name,
                  weight: 500,
                  subLabel: `${
                    t.regAssociationDivisionTeamPersons.filter((p) => p.active)
                      .length
                  } Players`,
                  onClick: () => onOpenModal(VIEW_ACTIONS_ENUM.edit, t),
                  icon: <User size={32} />
                },
                {
                  key: 3,
                  label: t.regAssociationDivision.isAllAges
                    ? '(All Ages)'
                    : `(${dayjs(new Date()).diff(
                        t.regAssociationDivision.minDob,
                        'year'
                      )} years old and younger)`,
                  sortValue: dayjs(new Date()).diff(
                    t.regAssociationDivision.minDob,
                    'year'
                  ),
                  sortValueType: 'date'
                },
                {
                  key: 4,
                  label: !state.regScoutReportEvaluationSessions.loading
                    ? Math.round(overallRating * 10) / 10
                    : '',
                  sortValueType: 'number'
                }
              ]
            };
          })}
        selectedRows={selectedTeams.map((t) => t.pkRegAssociationDivisionTeam)}
        sortBy={sortValue}
        sortFilter={customFilterData}
      />

      <TeamDetailsModal
        hidePlayerNames={
          userEvaluationSessionEntry &&
          !userEvaluationSessionEntry.canViewPlayerName
        }
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.view
        }
        onClose={onCloseModal}
        regAssociation={regAssociation}
        regAssociationDivisionTeam={modalState.item}
      />
    </>
  );
};

EvaluationSessionTeamSelect.propTypes = {
  isLoading: PropTypes.bool,
  onSelectPlayers: PropTypes.func,
  regAssociation: PropTypes.object,
  regScoutReportEvaluationSession: PropTypes.object,
  selectedPlayers: PropTypes.array,
  userEvaluationSessionEntry: PropTypes.object
};

export default EvaluationSessionTeamSelect;
