import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider } from '@mantine/core';
import { ChevronDown, ChevronUp, Plus } from 'tabler-icons-react';
import AppCard from './AppCard';
import AppStack from './AppStack';
import AppFlexbox from './AppFlexbox';
import AppTitle from './AppTitle';
import { useMediaQueryIndex } from '../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../helpers/styles';
import AppText from './AppText';
import FilterInput from './FilterInput';
import ActionableIcon from './ActionableIcon';
import SupportInfoMenu from './SupportInfoMenu';

const AppCardList = ({
  title,
  buttonPlacement,
  onAction,
  actionText,
  actionIcon,
  isActionDisabled,
  filters,
  filterTitle,
  isLoading,
  helpLinks,
  helpIndexLink,
  children,
  ...rest
}) => {
  const mqIndex = useMediaQueryIndex();
  const headerButton = onAction && buttonPlacement === 'header';
  const [showFilters, setShowFilters] = useState(false);

  return (
    <AppCard {...rest}>
      <AppStack style={{ gap: rs([20, 10], mqIndex), alignSelf: 'stretch' }}>
        {title && (
          <AppFlexbox
            style={{
              justifyContent: 'space-between',
              flexDirection: rs(['column', 'row'], mqIndex),
              flex: 3,
              gap: rs([20, 10], mqIndex),
              alignSelf: 'stretch',
              textAlign: 'start'
            }}
          >
            {(headerButton || helpLinks) && (
              <AppFlexbox
                style={{
                  flex: 1,
                  display: rs(['none', 'none', 'flex'], mqIndex)
                }}
              />
            )}
            <AppFlexbox
              style={{
                flex: 2,
                justifyContent: headerButton
                  ? rs(['center', 'start', 'center'], mqIndex)
                  : 'center'
              }}
            >
              <AppTitle style={{ textAlign: 'center' }}>{title}</AppTitle>
            </AppFlexbox>
            {(headerButton || helpLinks) && (
              <AppFlexbox
                style={{
                  flex: 1,
                  justifyContent: 'flex-end',
                  alignSelf: rs(['stretch', 'center'], mqIndex)
                }}
              >
                {headerButton && (
                  <Button
                    color="blue"
                    disabled={isLoading || isActionDisabled}
                    onClick={onAction}
                    rightSection={actionIcon ?? <Plus />}
                    style={{
                      alignSelf: rs(['stretch', 'end'], mqIndex),
                      flex: rs([1, 'unset'], mqIndex),
                      placeSelf: 'end'
                    }}
                  >
                    {actionText ?? 'Create'}
                  </Button>
                )}
                {helpLinks && (
                  <AppStack>
                    <SupportInfoMenu
                      indexLink={helpIndexLink}
                      links={helpLinks}
                    />
                  </AppStack>
                )}
              </AppFlexbox>
            )}
          </AppFlexbox>
        )}
        {(filters?.length > 0 || (onAction && !headerButton)) && (
          <AppStack style={{ gap: 5 }}>
            {filterTitle && <AppText weight={700}>{filterTitle}</AppText>}

            <AppStack
              style={{
                display: rs(
                  [
                    'flex',
                    'flex',
                    filters?.length > 5 ? 'flex' : 'none',
                    'none'
                  ],
                  mqIndex
                ),
                alignSelf: 'stretch',
                gap: 5,
                marginBottom: 5
              }}
            >
              <AppFlexbox
                style={{
                  alignSelf: 'stretch',
                  justifyContent: 'space-between'
                }}
              >
                <AppText weight={700}>Filter</AppText>
                <ActionableIcon
                  color="dark"
                  onClick={() => setShowFilters(!showFilters)}
                  style={{
                    display: rs(
                      [
                        'flex',
                        'flex',
                        filters?.length > 5 ? 'flex' : 'none',
                        'none'
                      ],
                      mqIndex
                    )
                  }}
                >
                  {showFilters ? <ChevronUp /> : <ChevronDown />}
                </ActionableIcon>
              </AppFlexbox>
              <Divider />
            </AppStack>

            <AppFlexbox
              style={{
                gap: rs([15, 15, 5], mqIndex),
                flex: 1,
                justifyContent: 'space-between',
                flexDirection: rs(
                  [
                    'column',
                    'column',
                    filters?.length > 5 ? 'column' : 'row',
                    'row'
                  ],
                  mqIndex
                ),
                alignItems: 'end'
              }}
            >
              <AppFlexbox
                style={{
                  flex: 1,
                  gap: 5,
                  flexDirection: rs(
                    [
                      'column',
                      'column',
                      filters?.length > 5 ? 'column' : 'row',
                      'row'
                    ],
                    mqIndex
                  ),
                  alignSelf: 'stretch',
                  display: showFilters
                    ? 'flex'
                    : rs(
                        [
                          'none',
                          'none',
                          filters?.length > 5 ? 'none' : 'flex',
                          'flex'
                        ],
                        mqIndex
                      )
                }}
              >
                {filters &&
                  filters.map((filter) => (
                    <AppFlexbox
                      key={filter.key}
                      style={{
                        flex: 1,
                        maxWidth: rs(
                          [
                            'unset',
                            'unset',
                            filters?.length > 5 ? 'unset' : '33%',
                            '33%'
                          ],
                          mqIndex
                        )
                      }}
                    >
                      <FilterInput
                        key={filter.value}
                        disabled={isLoading}
                        style={{ flex: 1 }}
                        {...filter}
                      />
                    </AppFlexbox>
                  ))}
              </AppFlexbox>
              {onAction && !headerButton && (
                <AppFlexbox
                  style={{ alignSelf: 'stretch', justifyContent: 'flex-end' }}
                >
                  <Button
                    color="blue"
                    disabled={isLoading || isActionDisabled}
                    onClick={onAction}
                    rightSection={actionIcon ?? <Plus />}
                    style={{
                      alignSelf: rs(['stretch', 'end'], mqIndex),
                      flex: rs([1, 'unset'], mqIndex),
                      placeSelf: 'end'
                    }}
                  >
                    {actionText ?? 'Create'}
                  </Button>
                </AppFlexbox>
              )}
            </AppFlexbox>
          </AppStack>
        )}
        {children}
      </AppStack>
    </AppCard>
  );
};

AppCardList.propTypes = {
  actionIcon: PropTypes.node,
  actionText: PropTypes.string,
  buttonPlacement: PropTypes.node,
  children: PropTypes.node,
  filterTitle: PropTypes.string,
  filters: PropTypes.array,
  helpIndexLink: PropTypes.object,
  helpLinks: PropTypes.array,
  isActionDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onAction: PropTypes.func,
  title: PropTypes.string
};

export default AppCardList;
