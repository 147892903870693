import React from 'react';
import { Avatar } from '@mantine/core';
import PropTypes from 'prop-types';
import { HsltoString, generateHsl } from '../../helpers/styles';
import { getInitialsFromName } from '../../helpers/format';

const ColoredAvatar = ({ name, uniqueId, ...rest }) => (
  <Avatar
    color={HsltoString(generateHsl(`${name}${uniqueId}`))}
    radius="xl"
    style={{ marginRight: 5 }}
    variant="filled"
    {...rest}
  >
    {getInitialsFromName(`${name}`)}
  </Avatar>
);

ColoredAvatar.propTypes = {
  name: PropTypes.string,
  uniqueId: PropTypes.string
};

export default ColoredAvatar;
