import React, { useContext, useState } from 'react';
import {
  Avatar,
  Burger,
  Button,
  Divider,
  Drawer as MantineDrawer,
  MantineProvider,
  useMantineTheme
} from '@mantine/core';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { Help } from 'tabler-icons-react';
import AppFlexbox from '../../common/AppFlexbox';
import { Context as AuthContext } from '../../../providers/AuthProvider';
import { Context as RegistrationAdminDashboardContext } from '../../../providers/RegistrationAdminDashboardProvider';
import { Context as RegistrationContext } from '../../../providers/RegistrationAdminProvider';
import { links } from './links';
import DrawerLinkSection from './DrawerLinkSection';
import AppText from '../../common/AppText';
import { getInitialsFromName } from '../../../helpers/format';
import SUPPORT_LINKS from './supportLinks';
import FilterInput from '../../common/FilterInput';

const Drawer = ({ adminLinks, textColor, lightMode }) => {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const { state, logout } = useContext(AuthContext);
  const { clearCart } = useContext(RegistrationContext);
  const { state: dashboardState, changeLegacySettings } = useContext(
    RegistrationAdminDashboardContext
  );
  const [openState, setOpenState] = useState(false);

  const logoutUser = () => {
    logout();
    clearCart();
    setOpenState(false);
    navigate('/');
  };

  return (
    <>
      <Burger
        color={textColor}
        onClick={() => setOpenState(!openState)}
        opened={openState}
        title={openState ? 'Close navigation' : 'Open navigation'}
      />
      <MantineDrawer
        onClose={() => setOpenState(!openState)}
        opened={openState}
        position="right"
        size={240}
        styles={{
          body: {
            padding: 0,
            height: '100%',
            backgroundColor: lightMode ? '#FFF' : '#000'
          }
        }}
        withCloseButton={false}
      >
        <AppFlexbox
          style={{ flex: 1, gap: 0, flexDirection: 'column', height: '100%' }}
        >
          {!state.isAuthenticated ? (
            <AppFlexbox
              style={{ padding: '20px 10px', flexDirection: 'column' }}
            >
              <Button
                color="yellow"
                component={Link}
                onClick={() => setOpenState(false)}
                radius="xs"
                size="md"
                to="/auth/login"
                variant="filled"
              >
                Sign In
              </Button>
              <Button
                color="lime"
                component={Link}
                onClick={() => setOpenState(false)}
                radius="xs"
                size="md"
                to="/auth/register"
                variant="filled"
              >
                Join Now
              </Button>
            </AppFlexbox>
          ) : (
            <>
              <AppFlexbox
                component={Link}
                onClick={() => setOpenState(!openState)}
                style={{
                  padding: 20,
                  color: textColor,
                  placeItems: 'center',
                  textDecoration: 'none'
                }}
                to="settings"
              >
                <Avatar
                  radius="xl"
                  src={state.userData.user.avatar}
                  style={{ backgroundColor: theme.colors.yellow[9] }}
                >
                  <AppText>
                    {getInitialsFromName(state.userData.user.name)}
                  </AppText>
                </Avatar>

                <AppFlexbox
                  style={{
                    flex: 1,
                    gap: 0,
                    overflow: 'hidden',
                    flexDirection: 'column'
                  }}
                >
                  <AppText>{state.userData.user.name}</AppText>
                  <AppText>{state.userData.user.email}</AppText>
                </AppFlexbox>
              </AppFlexbox>

              {state.userData.isRegistrationAdmin && adminLinks.length > 0 && (
                <>
                  <Divider />
                  <AppFlexbox style={{}}>
                    <FilterInput
                      dropdownProps={
                        !lightMode
                          ? {
                              style: {
                                '--popover-border-color': '#dee2e6',
                                backgroundColor: '#424242',
                                color: '#FFF'
                              }
                            }
                          : null
                      }
                      inputBaseProps={{
                        styles: {
                          input: {
                            borderRadius: 0,
                            height: 50,
                            flex: 1,
                            color: !lightMode ? '#FFF' : '#000'
                          },
                          wrapper: !lightMode
                            ? {
                                '--input-bd': '#424242',
                                '--input-bg': '#424242'
                              }
                            : {}
                        }
                      }}
                      preventUrlUpdate
                      style={{ height: 50, flex: 1 }}
                      type="association"
                    />
                  </AppFlexbox>
                </>
              )}
            </>
          )}
          <Divider />
          <AppFlexbox
            style={{
              flex: 1,
              flexDirection: 'column',
              gap: 0,
              overflow: 'auto'
            }}
          >
            <AppFlexbox
              style={{
                padding: '20px 10px',
                flexDirection: 'column',
                gap: 5
              }}
            >
              <DrawerLinkSection
                lightMode={lightMode}
                links={links.public}
                onClick={() => setOpenState(false)}
                textColor={textColor}
              />
            </AppFlexbox>
            <Divider />

            {state.isAuthenticated && (
              <>
                <AppFlexbox
                  style={{
                    padding: '20px 10px',
                    flexDirection: 'column',
                    gap: 5
                  }}
                >
                  <DrawerLinkSection
                    lightMode={lightMode}
                    links={links.profile}
                    onClick={() => setOpenState(false)}
                    textColor={textColor}
                  />
                </AppFlexbox>
                {state.userData.isRegistrationAdmin && adminLinks.length > 0 && (
                  <>
                    <Divider />
                    <AppFlexbox
                      style={{
                        padding: '20px 10px',
                        flexDirection: 'column',
                        gap: 5
                      }}
                    >
                      <DrawerLinkSection
                        lightMode={lightMode}
                        links={adminLinks}
                        onClick={() => setOpenState(false)}
                        textColor={textColor}
                      />
                    </AppFlexbox>
                    <Divider />
                    <AppFlexbox
                      style={{
                        padding: '20px 10px',
                        flexDirection: 'column',
                        gap: 5
                      }}
                    >
                      <DrawerLinkSection
                        lightMode={lightMode}
                        links={[
                          {
                            to: SUPPORT_LINKS.index.href,
                            title: SUPPORT_LINKS.index.label,
                            icon: Help,
                            target: '_blank',
                            isSelected: () => false
                          }
                        ]}
                        onClick={() => setOpenState(false)}
                        textColor={textColor}
                      />
                    </AppFlexbox>
                  </>
                )}
              </>
            )}
          </AppFlexbox>

          {state.isAuthenticated && (
            <>
              <Divider style={{ marginTop: 'auto' }} />
              <Button
                onClick={logoutUser}
                radius="xs"
                size="md"
                style={{
                  margin: 20,
                  color: lightMode ? theme.black : theme.white,
                  borderColor: lightMode ? theme.colors.gray[6] : theme.white
                }}
                variant="outline"
              >
                Sign out
              </Button>
            </>
          )}
        </AppFlexbox>
      </MantineDrawer>
    </>
  );
};

Drawer.propTypes = {
  adminLinks: PropTypes.array,
  lightMode: PropTypes.bool,
  textColor: PropTypes.string
};

export default Drawer;
