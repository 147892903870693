import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Button, Badge, Skeleton, ThemeIcon } from '@mantine/core';
import { Users } from 'tabler-icons-react';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const SpecialRequestListitem = ({
  regSpecialRequest,
  onRemove,
  isDisabled
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const mqIndex = useMediaQueryIndex();

  return regSpecialRequest ? (
    <AppFlexbox
      onMouseLeave={() => setIsHovered(false)}
      onMouseOver={() => setIsHovered(true)}
      style={{ padding: 10, gap: 10 }}
    >
      <AppFlexbox style={{ flex: 5, placeItems: 'center' }}>
        <ThemeIcon
          color="blue"
          radius="xl"
          style={{
            placeItems: 'center',
            alignSelf: 'start',
            padding: 3,
            display: rs(['flex', 'none'], mqIndex)
          }}
        >
          <Users />
        </ThemeIcon>
        <AppStack style={{ flex: 1, display: rs(['none', 'flex'], mqIndex) }}>
          <Badge color="blue" style={{ width: 100 }} variant="filled">
            SAME TEAM
          </Badge>
        </AppStack>
        <AppStack style={{ gap: 0, flex: 1 }}>
          <AppText style={{ lineHeight: '16px' }} weight={500}>
            {regSpecialRequest.regFormSubmission1.regPerson.firstName}{' '}
            {regSpecialRequest.regFormSubmission1.regPerson.lastName}
          </AppText>
          <AppText style={{ fontSize: 14, lineHeight: '16px' }}>
            {regSpecialRequest.regFormSubmission1
              .regFormSubmissionRoleAnswers[0]?.role ?? 'Player'}
          </AppText>
        </AppStack>
        <AppStack style={{ gap: 0, flex: 1 }}>
          <AppText style={{ lineHeight: '16px' }} weight={500}>
            {regSpecialRequest.regFormSubmission2.regPerson.firstName}{' '}
            {regSpecialRequest.regFormSubmission2.regPerson.lastName}
          </AppText>
          <AppText style={{ fontSize: 14, lineHeight: '16px' }}>
            {regSpecialRequest.regFormSubmission2
              .regFormSubmissionRoleAnswers[0]?.role ?? 'Player'}
          </AppText>
        </AppStack>
      </AppFlexbox>
      <AppStack style={{ alignSelf: 'right', flex: 1 }}>
        {isHovered && !isDisabled && (
          <Button color="red" onClick={onRemove} size="xs">
            Remove
          </Button>
        )}
      </AppStack>
    </AppFlexbox>
  ) : (
    <AppFlexbox style={{ padding: 10 }}>
      <AppStack style={{ display: rs(['flex', 'none'], mqIndex) }}>
        <Skeleton height={25} radius={25} width={25} />
      </AppStack>
      <AppStack style={{ flex: 1, display: rs(['none', 'flex'], mqIndex) }}>
        <Skeleton height={20} radius={20} width={100} />
      </AppStack>
      <AppStack style={{ gap: 10, flex: 1 }}>
        <Skeleton height={8} width="80%" />
        <Skeleton height={8} width="60%" />
      </AppStack>
      <AppStack style={{ gap: 10, flex: 1 }}>
        <Skeleton height={8} width="80%" />
        <Skeleton height={8} width="60%" />
      </AppStack>
    </AppFlexbox>
  );
};

SpecialRequestListitem.propTypes = {
  isDisabled: PropTypes.bool,
  onRemove: PropTypes.func,
  regSpecialRequest: PropTypes.object
};

export default SpecialRequestListitem;
