import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Divider, TextInput } from '@mantine/core';
import { ArrowsMove, Plus, Trash } from 'tabler-icons-react';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppTitle from '../../common/AppTitle';
import FormSection from '../../common/FormSection';
import { triggerNotification } from '../../../helpers/notificationHelper';
import AppFlexbox from '../../common/AppFlexbox';
import PaginationList from '../../common/PaginationList';
import AppText from '../../common/AppText';
import ActionableIcon from '../../common/ActionableIcon';
import AppStack from '../../common/AppStack';
import DragDropList from '../../common/DragDropList';

const EvaluationTemplateSnippetModal = ({
  isOpen,
  onClose,
  onConfirm,
  regScoutReportQuestions,
  selectedSnippets
}) => {
  const [formState, setFormState] = useState({
    selectedSnippets: [],
    reportSnippets: [],
    inputValue: '',
    showSetOrderView: false,
    pageIndex: 1
  });
  const allSnippetsSelected =
    formState.reportSnippets.length > 0 &&
    formState.reportSnippets.every(
      (s) => !!formState.selectedSnippets.find((f) => f.value === s.value)
    );

  const reportSnippets = regScoutReportQuestions.reduce((r, c) => {
    c.regScoutReportQuestionSnippets.forEach((s) => {
      if (!r.some((f) => f.value === s.value)) {
        r.push(s);
      }
    });
    return r;
  }, []);

  useEffect(() => {
    if (isOpen) {
      setFormState({
        selectedSnippets: selectedSnippets || [],
        reportSnippets: reportSnippets || [],
        inputValue: '',
        showSetOrderView: false,
        pageIndex: 1
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      onClose={onClose}
      opened={isOpen}
      title={<AppTitle order={2}>Question Snippets</AppTitle>}
      transitionProps={{
        duration: 0,
        transition: 'none'
      }}
    >
      {formState.showSetOrderView ? (
        <FormSection
          cancelTitle="Back"
          isSubmitHidden
          onCancel={() =>
            setFormState({ ...formState, showSetOrderView: false })
          }
        >
          <AppStack style={{ gap: 10 }}>
            <DragDropList
              items={formState.selectedSnippets.map((s) => ({
                key: s.value,
                sort: s.sort,
                value: s.value
              }))}
              onUpdate={(newListOrder) =>
                setFormState({
                  ...formState,
                  selectedSnippets: newListOrder
                })
              }
              renderOptions={({ item, index }) => (
                <AppFlexbox
                  style={{
                    padding: 10,
                    border: 'solid 1px lightgrey',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                    height: 55
                  }}
                >
                  <AppFlexbox
                    style={{
                      gap: 5,
                      alignItems: 'center'
                    }}
                  >
                    <ArrowsMove style={{ cursor: 'pointer' }} />
                    <AppText>
                      {index + 1}. {item.value}
                    </AppText>
                  </AppFlexbox>
                </AppFlexbox>
              )}
            />
          </AppStack>
        </FormSection>
      ) : (
        <AppStack style={{ gap: 20, flex: 1 }}>
          <AppFlexbox
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              const existingSnippet = formState.reportSnippets.find(
                (s) => s.value === formState.inputValue.trim()
              );
              if (existingSnippet) {
                triggerNotification('Snippet already added.');
              }
              else {
                setFormState({
                  ...formState,
                  reportSnippets: [
                    ...formState.reportSnippets,
                    {
                      value: formState.inputValue.trim(),
                      sort:
                        formState.reportSnippets.length === 0
                          ? 1
                          : Math.max(
                              ...formState.reportSnippets.map((i) => i.sort)
                            ) + 1
                    }
                  ],
                  inputValue: ''
                });
              }
            }}
            style={{ gap: 5 }}
          >
            <TextInput
              onChange={(e) =>
                setFormState({
                  ...formState,
                  inputValue: e.currentTarget.value
                })
              }
              placeholder="Enter your snippet here"
              style={{ flex: 1 }}
              value={formState.inputValue}
            />
            <Button
              color="blue"
              disabled={!formState.inputValue}
              leftSection={<Plus />}
              type="submit"
            >
              Add Snippet
            </Button>
          </AppFlexbox>
          <Divider label="ASSIGN SNIPPETS" labelPosition="center" />
          <FormSection
            onCancel={onClose}
            onSubmit={(e) => {
              e.stopPropagation();
              onConfirm(formState.selectedSnippets);
            }}
            submitTitle={`Confirm (${formState.selectedSnippets.length})`}
          >
            <AppStack style={{ gap: 5 }}>
              <AppFlexbox
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <AppText style={{ fontWeight: 500 }}>Select Snippets</AppText>
                <Checkbox
                  checked={allSnippetsSelected}
                  label={allSnippetsSelected ? 'Unselect All' : 'Select All'}
                  onChange={() => {
                    if (allSnippetsSelected) {
                      setFormState({
                        ...formState,
                        selectedSnippets: []
                      });
                    }
                    else {
                      setFormState({
                        ...formState,
                        selectedSnippets: formState.reportSnippets
                      });
                    }
                  }}
                  style={{ fontWeight: 500 }}
                />
              </AppFlexbox>
              <PaginationList
                bottomAction={
                  formState.selectedSnippets.length > 0 && (
                    <Button
                      onClick={() =>
                        setFormState({
                          ...formState,
                          showSetOrderView: true
                        })
                      }
                      size="compact-md"
                    >
                      Adjust Order
                    </Button>
                  )
                }
                emptyMessage="No snippets created"
                items={formState.reportSnippets.map((s) => {
                  const isSelected = formState.selectedSnippets.some(
                    (f) => f.value === s.value
                  );
                  return (
                    <AppFlexbox
                      key={s.value}
                      onClick={() =>
                        setFormState({
                          ...formState,
                          selectedSnippets: !isSelected
                            ? [...formState.selectedSnippets, s]
                            : formState.selectedSnippets.filter(
                                (snippet) => snippet.value !== s.value
                              )
                        })
                      }
                      style={{ gap: 10, padding: 10, alignItems: 'center' }}
                    >
                      <AppFlexbox
                        style={{ gap: 10, alignItems: 'center', flex: 1 }}
                      >
                        <Checkbox checked={isSelected} onChange={() => {}} />
                        <AppText>{s.value}</AppText>
                      </AppFlexbox>
                      <ActionableIcon
                        color="red"
                        onClick={(e) => {
                          e.stopPropagation();
                          setFormState({
                            ...formState,
                            reportSnippets: formState.reportSnippets.filter(
                              (snippet) => snippet.value !== s.value
                            ),
                            selectedSnippets: formState.selectedSnippets.filter(
                              (snippet) => snippet.value !== s.value
                            )
                          });
                        }}
                      >
                        <Trash />
                      </ActionableIcon>
                    </AppFlexbox>
                  );
                })}
                itemsPerPage={10}
                onPageChange={(pageIndex) =>
                  setFormState({
                    ...formState,
                    pageIndex
                  })
                }
                pageIndex={formState.pageIndex}
              />
            </AppStack>
          </FormSection>
        </AppStack>
      )}
    </ResponsiveModal>
  );
};

EvaluationTemplateSnippetModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  regScoutReportQuestions: PropTypes.array,
  selectedSnippets: PropTypes.array
};

export default EvaluationTemplateSnippetModal;
