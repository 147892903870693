import React from 'react';
import {
  ArrowsMove,
  Copy,
  DotsVertical,
  Plus,
  Trash
} from 'tabler-icons-react';
import { ActionIcon, Button, Menu } from '@mantine/core';
import PropTypes from 'prop-types';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import ActionableIcon from '../../common/ActionableIcon';
import AppText from '../../common/AppText';
import DragDropList from '../../common/DragDropList';
import MenuContent from '../../common/MenuContent';

const EvaluationTemplateViewDragDropList = ({
  items,
  onAdd,
  itemTypeLabel,
  onRemove,
  onCopy,
  onSelect,
  onUpdateListOrder,
  listGap
}) => (
  <AppStack style={{ gap: 10 }}>
    {items.length === 0 ? (
      <AppStack
        style={{
          border: 'solid 1px lightgrey',
          padding: '30px 10px',
          justifyContent: 'center',
          placeItems: 'center',
          gap: 5
        }}
      >
        <AppText weight={500}>
          Setup your first {itemTypeLabel.toLowerCase()}!
        </AppText>
        {onAdd && (
          <Button leftSection={<Plus />} onClick={onAdd}>
            Add {itemTypeLabel}
          </Button>
        )}
      </AppStack>
    ) : (
      <DragDropList
        items={items}
        listProps={{ gap: listGap ?? 5 }}
        onUpdate={onUpdateListOrder}
        renderOptions={({ item, index }) => (
          <AppFlexbox
            onClick={() => onSelect(item)}
            style={{
              padding: 10,
              border: listGap === 0 ? 'none' : 'solid 1px lightgrey',
              borderTop: 'solid 1px lightgrey',
              alignItems: 'center',
              justifyContent: 'space-between',
              cursor: 'pointer',
              height: 55,
              overflow: 'hidden'
            }}
          >
            <AppFlexbox
              style={{
                gap: 5,
                flex: 1,
                alignItems: 'center',
                overflow: 'hidden'
              }}
            >
              <ArrowsMove style={{ cursor: 'pointer', overflow: 'hidden' }} />
              <AppText style={{ textWrap: 'nowrap' }}>
                {index + 1}. {item.value}
              </AppText>
            </AppFlexbox>
            {item.isSelected && (onCopy || onRemove || item.actions) && (
              <AppFlexbox style={{ gap: 5, display: 'relative' }}>
                {onCopy && (
                  <ActionableIcon
                    color="dark"
                    onClick={(e) => {
                      e.stopPropagation();
                      onCopy(item);
                    }}
                    radius="xl"
                    variant="subtle"
                  >
                    <Copy />
                  </ActionableIcon>
                )}
                {onRemove && (
                  <ActionableIcon
                    color="red"
                    onClick={(e) => {
                      e.stopPropagation();
                      onRemove(item);
                    }}
                    radius="xl"
                    variant="subtle"
                  >
                    <Trash />
                  </ActionableIcon>
                )}

                {item.actions && (
                  <MenuContent
                    color="dark"
                    control={
                      <ActionIcon color="dark" variant="subtle">
                        <DotsVertical />
                      </ActionIcon>
                    }
                    size="compact-sm"
                  >
                    {item.actions.map((a) => (
                      <Menu.Item
                        key={a.value}
                        onClick={(e) => {
                          e.preventDefault();
                          item.onAction(a.value);
                        }}
                      >
                        {a.label}
                      </Menu.Item>
                    ))}
                  </MenuContent>
                )}
              </AppFlexbox>
            )}
          </AppFlexbox>
        )}
      />
    )}
  </AppStack>
);

EvaluationTemplateViewDragDropList.propTypes = {
  itemTypeLabel: PropTypes.string,
  items: PropTypes.array,
  listGap: PropTypes.number,
  onAdd: PropTypes.func,
  onCopy: PropTypes.func,
  onRemove: PropTypes.func,
  onSelect: PropTypes.func,
  onUpdateListOrder: PropTypes.func
};

export default EvaluationTemplateViewDragDropList;
