import React from 'react';
import { Button, Select, Skeleton, Slider, TextInput } from '@mantine/core';
import PropTypes from 'prop-types';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import {
  DEFUALT_EVALUATION_BUTTON_OPTIONS,
  REG_FORM_CONTROL_TYPE_ENUM
} from '../../../config/constants';

const SLIDER_VALUES = [
  { value: 0, label: '0' },
  { value: 0.5 },
  { value: 1, label: '1' },
  { value: 1.5 },
  { value: 2, label: '2' },
  { value: 2.5 },
  { value: 3, label: '3' },
  { value: 3.5 },
  { value: 4, label: '4' },
  { value: 4.5 },
  { value: 5, label: '5' }
];

const EvaluationTemplateQuestionInput = ({
  question,
  evaluation,
  onUpdateEvaluationAnswer,
  questionAnswerValue,
  isSmallScreen
}) => (
  <AppFlexbox
    style={{
      flex: 1,
      gap: 10,
      alignItems: isSmallScreen ? 'stretch' : 'center',
      flexDirection: isSmallScreen ? 'column' : 'row'
    }}
  >
    {!question ? (
      <Skeleton height={42} style={{ maxWidth: 210 }} width="100%" />
    ) : question.fkRegFormControlType ===
      REG_FORM_CONTROL_TYPE_ENUM.TEXT_INPUT ? (
      <TextInput label={question.value} style={{ flex: 1, maxWidth: 300 }} />
    ) : question.fkRegFormControlType === REG_FORM_CONTROL_TYPE_ENUM.SLIDER ? (
      <>
        <TextInput
          disabled={!onUpdateEvaluationAnswer}
          onChange={(e) => {
            if (
              !e.currentTarget.value ||
              SLIDER_VALUES.some((v) =>
                v.value.toString().includes(e.currentTarget.value)
              )
            ) {
              onUpdateEvaluationAnswer(
                evaluation,
                question,
                e.currentTarget.value
              );
            }
          }}
          placeholder="Score"
          size="md"
          value={questionAnswerValue || ''}
        />
        <AppStack style={{ flex: 1 }}>
          <Slider
            color="dark"
            disabled={!onUpdateEvaluationAnswer}
            marks={SLIDER_VALUES}
            max={5}
            onChange={(value) => {
              if (value || questionAnswerValue) {
                onUpdateEvaluationAnswer(
                  evaluation,
                  question,
                  value.toString()
                );
              }
            }}
            size="xl"
            step={0.5}
            value={questionAnswerValue ? Number(questionAnswerValue) : 0}
          />
        </AppStack>
      </>
    ) : question.fkRegFormControlType ===
      REG_FORM_CONTROL_TYPE_ENUM.BUTTON_GROUP ? (
      <Button.Group style={{ maxWidth: 350, flex: 1 }}>
        {(question.regScoutReportQuestionOptions.length === 0
          ? DEFUALT_EVALUATION_BUTTON_OPTIONS
          : question.regScoutReportQuestionOptions
        )
          .sort((a, b) => a.sort - b.sort)
          .map((t) => (
            <Button
              key={t.sort}
              color="dark"
              disabled={!onUpdateEvaluationAnswer}
              onClick={() => {
                onUpdateEvaluationAnswer(evaluation, question, t.value);
              }}
              style={{ flex: 1, minHeight: 35, height: 'unset' }}
              styles={{ label: { whiteSpace: 'pre-wrap' } }}
              variant={questionAnswerValue === t.value ? 'filled' : 'light'}
            >
              {t.value}
            </Button>
          ))}
      </Button.Group>
    ) : (
      <Select
        clearable
        data={(question.regScoutReportQuestionOptions.length === 0
          ? DEFUALT_EVALUATION_BUTTON_OPTIONS
          : question.regScoutReportQuestionOptions
        )
          .sort((a, b) => a.sort - b.sort)
          .map((q) => ({
            label: q.value,
            value: q.value
          }))}
        label={question.value}
        onChange={(value) => {
          if (onUpdateEvaluationAnswer) {
            onUpdateEvaluationAnswer(evaluation, question, value);
          }
        }}
        searchable
        style={{ flex: 1, maxWidth: 350 }}
        value={questionAnswerValue || null}
      />
    )}
  </AppFlexbox>
);

EvaluationTemplateQuestionInput.propTypes = {
  evaluation: PropTypes.object,
  isSmallScreen: PropTypes.bool,
  onUpdateEvaluationAnswer: PropTypes.func,
  question: PropTypes.object,
  questionAnswerValue: PropTypes.string
};

export default EvaluationTemplateQuestionInput;
