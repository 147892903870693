import React from 'react';
import { PropTypes } from 'prop-types';
import PaginationList from '../../common/PaginationList';
import RegistrationListActionModals from './RegistrationListActionModals';
import RegistrationListItem from './RegistrationListItem';
import AppStack from '../../common/AppStack';
import { useModalState } from '../../../helpers/hooks';

const RegistrationListGrouped = ({
  regAssociation,
  isLoading,
  groupedRegFormSubmissions,
  pageIndex,
  onPageChange
}) => {
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();

  return (
    <AppStack>
      <PaginationList
        emptyMessage="No Registrations"
        isLoading={isLoading}
        items={groupedRegFormSubmissions.map((s) => (
          <AppStack
            key={s[0].pkRegFormSubmission}
            style={{ gap: 0, border: 'solid 1px lightgrey' }}
          >
            {s.map((r) => (
              <RegistrationListItem
                key={r.pkRegFormSubmission}
                onAction={(action) => onOpenModal(action, r)}
                regAssociation={regAssociation}
                regFormSubmission={r}
                showStatus
              />
            ))}
          </AppStack>
        ))}
        itemsPerPage={3}
        listProps={{ style: { gap: 20 } }}
        LoadingComponent={RegistrationListItem}
        noBorder={!isLoading}
        onPageChange={onPageChange}
        pageIndex={pageIndex}
      />
      <RegistrationListActionModals
        modalState={modalState}
        onCloseModal={onCloseModal}
        regAssociation={regAssociation}
      />
    </AppStack>
  );
};

RegistrationListGrouped.propTypes = {
  groupedRegFormSubmissions: PropTypes.array,
  isLoading: PropTypes.bool,
  onPageChange: PropTypes.func,
  pageIndex: PropTypes.number,
  regAssociation: PropTypes.object
};

export default RegistrationListGrouped;
