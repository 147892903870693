import React, { useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import RecordPaymentModal from './RecordPaymentModal';
import RegistrationDenyModal from './RegistrationDenyModal';
import RegistrationRefundModal from './RegistrationRefundModal';
import RegistrationChangeDivisionModal from './RegistrationChangeDivisionModal';
import RegistrationReinstateModal from './RegistrationReinstateModal';
import ViewRegistrationFormModal from '../Forms/ViewRegistrationFormModal';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { triggerNotification } from '../../../helpers/notificationHelper';
import RegistrationInvoiceCreateModal from './RegistrationInvoiceCreateModal';
import RegistrationOrderSummaryModal from './RegistrationOrderSummaryModal';
import { REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM } from '../../../config/constants';
import RegistrationChangeOverrideEmailModal from './RegistrationChangeOverrideEmailModal';

const RegistrationListActionModals = ({
  regAssociation,
  modalState,
  onCloseModal
}) => {
  const { updatePurchasedRegFormSubmission } = useContext(RegistrationContext);
  const { fetchUpdatedAdminFinancialRegistration } = useContext(
    RegistrationAdminContext
  );
  const [actionState, setActionState] = useState({
    isOpen: false,
    regFormSubmission: null,
    action: '',
    isLoading: false,
    invoiceRemaining: false
  });

  useEffect(() => {
    if (modalState.isOpen) {
      setActionState({
        isOpen: true,
        regFormSubmission: modalState.item,
        action: modalState.action,
        isLoading: false,
        invoiceRemaining: false
      });
    }
  }, [modalState.isOpen, modalState.action]);

  useEffect(() => {
    if (!actionState.isOpen) {
      onCloseModal();
    }
  }, [actionState.isOpen]);

  const closeActionMenu = () => {
    setActionState({
      ...actionState,
      isOpen: false
    });
  };

  const onRefresh = () => {
    if (actionState.regFormSubmission) {
      fetchUpdatedAdminFinancialRegistration(
        actionState.regFormSubmission?.pkRegFormSubmission,
        null,
        true
      );
    }
  };

  return (
    <>
      <ViewRegistrationFormModal
        isDisabled
        isLoading={actionState.isLoading}
        isOpen={
          actionState.isOpen &&
          actionState.action === REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.view
        }
        isViewable
        isViewerAdmin
        onClose={closeActionMenu}
        onSubmit={(formData, additionalData) => {
          setActionState({
            ...actionState,
            isLoading: true
          });

          const hasRegPersonChanges = Object.entries(
            actionState.regFormSubmission.regPerson
          ).some(
            ([key, value]) =>
              typeof value !== 'object' &&
              actionState.regFormSubmission.regPerson[key] !==
                formData.regPerson[key]
          );
          updatePurchasedRegFormSubmission(
            actionState.regFormSubmission?.pkRegFormSubmission,
            {
              regFormSubmissionAnswers: formData.regFormSubmissionAnswers,
              regPerson: hasRegPersonChanges ? formData.regPerson : null,
              divisionRoles: formData.divisionRoles,
              financialAssistanceId: formData.isFinancialAssistanceEnabled
                ? formData.financialAssistanceId
                : null,
              doNotChargeForChanges: additionalData?.doNotChargeForChanges
            },
            () => {
              triggerNotification(
                'Form updated successfully',
                'Success',
                'green'
              );
              if (additionalData) {
                fetchUpdatedAdminFinancialRegistration(
                  actionState.regFormSubmission?.pkRegFormSubmission
                );
              }

              if (
                additionalData?.increaseCost >= 10 &&
                additionalData?.paymentMethod?.toLowerCase() === 'invoice'
              ) {
                setActionState({
                  ...actionState,
                  invoiceRemaining: true,
                  isLoading: false,
                  action: REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.invoice
                });
              }
              else {
                closeActionMenu();
              }
            },
            (e) => {
              triggerNotification(e);
              setActionState({
                ...actionState,
                isLoading: false
              });
            }
          );
        }}
        pkRegFormSubmission={actionState.regFormSubmission?.pkRegFormSubmission}
      />
      <RecordPaymentModal
        isAdminViewing
        isOpen={
          actionState.isOpen &&
          actionState.action ===
            REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.viewPayments
        }
        onClose={closeActionMenu}
        regAssociation={regAssociation}
        regFormSubmission={actionState.regFormSubmission}
      />
      <RegistrationDenyModal
        isOpen={
          actionState.isOpen &&
          actionState.action === REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.deny
        }
        onClose={closeActionMenu}
        onRefresh={onRefresh}
        regAssociation={regAssociation}
        regFormSubmission={actionState.regFormSubmission}
      />
      <RegistrationReinstateModal
        isOpen={
          actionState.isOpen &&
          actionState.action ===
            REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.reinstate
        }
        onClose={closeActionMenu}
        onReinstate={onRefresh}
        regAssociation={regAssociation}
        regFormSubmission={actionState.regFormSubmission}
      />
      <RegistrationRefundModal
        isOpen={
          actionState.isOpen &&
          actionState.action === REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.refund
        }
        onClose={closeActionMenu}
        onRefresh={onRefresh}
        regAssociation={regAssociation}
        regFormSubmission={actionState.regFormSubmission}
      />
      <RegistrationChangeDivisionModal
        isOpen={
          actionState.isOpen &&
          actionState.action ===
            REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.changeDivision
        }
        onClose={closeActionMenu}
        onRefresh={onRefresh}
        regAssociation={regAssociation}
        regFormSubmission={actionState.regFormSubmission}
      />
      <RegistrationChangeOverrideEmailModal
        isOpen={
          actionState.isOpen &&
          actionState.action ===
            REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.changeOverrideEmail
        }
        onClose={closeActionMenu}
        onRefresh={onRefresh}
        regFormSubmission={actionState.regFormSubmission}
      />
      <RegistrationInvoiceCreateModal
        invoiceRemainingBalance={actionState.invoiceRemaining}
        isOpen={
          actionState.isOpen &&
          actionState.action === REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.invoice
        }
        onClose={closeActionMenu}
        onInvoice={onRefresh}
        regAssociation={regAssociation}
        regFormSubmission={actionState.regFormSubmission}
      />
      <RegistrationOrderSummaryModal
        isOpen={
          actionState.isOpen &&
          actionState.action ===
            REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.orderSummary
        }
        onClose={closeActionMenu}
        regAssociation={regAssociation}
        regFormSubmission={actionState.regFormSubmission}
      />
    </>
  );
};

RegistrationListActionModals.propTypes = {
  modalState: PropTypes.object,
  onCloseModal: PropTypes.func,
  regAssociation: PropTypes.object
};

export default RegistrationListActionModals;
