import React from 'react';
import { Avatar, MultiSelect } from '@mantine/core';
import AppFlexbox from './AppFlexbox';
import AppText from './AppText';

const CustomMultiSelect = ({ ...rest }) => (
  <MultiSelect
    renderOption={({ option }) => (
      <AppFlexbox gap="sm">
        {option.image && <Avatar radius="xl" size={36} src={option.image} />}
        <div>
          <AppText size="sm">{option.label}</AppText>
          {option.description && (
            <AppText size="xs" style={{ color: 'grey' }}>
              {option.description}
            </AppText>
          )}
        </div>
      </AppFlexbox>
    )}
    {...rest}
  />
);

CustomMultiSelect.propTypes = {};

export default CustomMultiSelect;
