import React, { useContext, useEffect } from 'react';
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate
} from 'react-router-dom';
import { ArrowLeft } from 'tabler-icons-react';
import ConfirmAccountView from '../components/content/auth/ConfirmAccountView';
import ForgotPasswordView from '../components/content/auth/ForgotPasswordView';
import LoginView from '../components/content/auth/LoginView';
import RegisterView from '../components/content/auth/RegisterView';
import ResetPasswordView from '../components/content/auth/ResetPasswordView';
import { Context as AuthContext } from '../providers/AuthProvider';
import AppStack from '../components/common/AppStack';
import { getResponsiveStyle as rs } from '../helpers/styles';
import { useMediaQueryIndex } from '../helpers/hooks';
import AppFlexbox from '../components/common/AppFlexbox';
import ActionableIcon from '../components/common/ActionableIcon';
import AppTitle from '../components/common/AppTitle';
import AppText from '../components/common/AppText';

const AuthView = () => {
  const mqIndex = useMediaQueryIndex();
  const reactNavigate = useNavigate();
  const { state, clearErrors } = useContext(AuthContext);
  const { search } = useLocation();
  const redirectUrl = new URLSearchParams(search).get('redirect');

  useEffect(() => {
    if (state.isAuthenticated) {
      clearErrors();
      reactNavigate(redirectUrl ?? '/');
    }
  }, [state.isAuthenticated]);

  useEffect(() => {
    if (state.isWaitingConfirmSignUp) {
      clearErrors();
      reactNavigate(
        redirectUrl
          ? `/auth/confirm-account?redirect=${redirectUrl}`
          : '/auth/confirm-account'
      );
    }
  }, [state.isWaitingConfirmSignUp]);

  const navigate = (view) => {
    clearErrors();
    reactNavigate(redirectUrl ? `${view}?redirect=${redirectUrl}` : view);
  };

  return (
    <AppFlexbox style={{ display: 'flex', flex: 1 }}>
      <AppStack
        style={{
          flex: 1,
          maxWidth: rs(['unset', 'unset', 900], mqIndex),
          padding: 0,
          margin: rs([0, 0, '50px auto'], mqIndex),
          display: 'flex'
        }}
      >
        <AppStack style={{ flex: 1 }}>
          <AppFlexbox
            onClick={() => reactNavigate(-1)}
            style={{
              gap: 10,
              cursor: 'pointer',
              marginLeft: rs([5, 5, 5, 0], mqIndex),
              display: rs(['none', 'none', 'flex'], mqIndex)
            }}
          >
            <ActionableIcon
              color="#000"
              size={30}
              style={{ border: 'solid 1px #000', borderRadius: 20 }}
            >
              <ArrowLeft color="#000" />
            </ActionableIcon>
            <AppText style={{ fontSize: 20 }}>Back</AppText>
          </AppFlexbox>
          <AppStack
            style={{
              maxWidth: rs(['unset', 'unset', 650], mqIndex),
              flex: rs([1, 1, 'unset'], mqIndex),
              alignSelf: 'center',
              width: '100%'
            }}
          >
            <AppTitle
              style={{
                fontSize: 30,
                display: rs(['none', 'none', 'flex'], mqIndex)
              }}
            >
              Secure Authentication
            </AppTitle>
            <Routes>
              <Route element={<LoginView navigate={navigate} />} path="login" />
              <Route
                element={<RegisterView navigate={navigate} />}
                path="register"
              />
              <Route
                element={<ConfirmAccountView navigate={navigate} />}
                path="confirm-account"
              />
              <Route
                element={<ForgotPasswordView navigate={navigate} />}
                path="forgot-password"
              />
              <Route
                element={<ResetPasswordView navigate={navigate} />}
                path="reset-password"
              />
              <Route element={<Navigate replace to="/auth/login" />} path="*" />
            </Routes>
          </AppStack>
        </AppStack>
      </AppStack>
    </AppFlexbox>
  );
};

export default AuthView;
