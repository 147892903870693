import PropTypes from 'prop-types';
import React from 'react';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppTitle from '../../common/AppTitle';
import FormSection from '../../common/FormSection';
import EvaluationSessionSettings from './EvaluationSessionSettings';
import AppStack from '../../common/AppStack';
import { triggerNotification } from '../../../helpers/notificationHelper';

const EvaluationSessionSettingsModal = ({
  isOpen,
  onClose,
  onSaveChanges,
  isLoading,
  isSaving,
  canEdit,
  evaluationSessionName,
  regScoutReportEvaluations,
  regScoutReportEvaluationSession,
  evaluationTemplateMetricQuestions,
  regScoutReports,
  onEvaluatorMenuAction,
  onAssignAction,
  onUpdateEvaluationSession
}) => {
  const onCancel = () => {
    if (evaluationSessionName !== regScoutReportEvaluationSession.name) {
      onUpdateEvaluationSession({ name: regScoutReportEvaluationSession.name });
    }
    onClose();
  };

  return (
    <ResponsiveModal
      onClose={onCancel}
      opened={isOpen}
      size={700}
      title={<AppTitle order={2}>Session Settings</AppTitle>}
      transitionProps={{
        duration: 0,
        transition: 'none'
      }}
    >
      <FormSection
        isLoading={isSaving}
        onCancel={onCancel}
        onSubmit={() => {
          onSaveChanges(() => {
            triggerNotification('Changes Saved', 'Success', 'green');
            onClose();
          });
        }}
        submitTitle="Save Changes"
      >
        <AppStack style={{ gap: 20 }}>
          <EvaluationSessionSettings
            canEdit={canEdit}
            evaluationSessionName={evaluationSessionName}
            evaluationTemplateMetricQuestions={
              evaluationTemplateMetricQuestions
            }
            isLoading={isLoading}
            isSaving={isSaving}
            onAssignAction={onAssignAction}
            onEvaluatorMenuAction={onEvaluatorMenuAction}
            onUpdateEvaluationSession={onUpdateEvaluationSession}
            regScoutReportEvaluations={regScoutReportEvaluations}
            regScoutReportEvaluationSession={regScoutReportEvaluationSession}
            regScoutReports={regScoutReports}
          />
        </AppStack>
      </FormSection>
    </ResponsiveModal>
  );
};

EvaluationSessionSettingsModal.propTypes = {
  canEdit: PropTypes.bool,
  evaluationSessionName: PropTypes.string,
  evaluationTemplateMetricQuestions: PropTypes.array,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  isSaving: PropTypes.bool,
  onAssignAction: PropTypes.func,
  onClose: PropTypes.func,
  onEvaluatorMenuAction: PropTypes.func,
  onSaveChanges: PropTypes.func,
  onUpdateEvaluationSession: PropTypes.func,
  regScoutReportEvaluationSession: PropTypes.object,
  regScoutReportEvaluations: PropTypes.array,
  regScoutReports: PropTypes.array
};

export default EvaluationSessionSettingsModal;
