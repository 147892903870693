const persoanlInformationSectionData = {
  title: 'Personal Information',
  positionOrder: 0,
  isManaged: true,
  regFormControls: [
    {
      title: 'First Name',
      positionColumn: 1,
      positionRow: 1,
      positionSpan: 3,
      type: 'TEXT_INPUT',
      constraints: [
        {
          name: 'required',
          value: 'true'
        }
      ]
    },
    {
      title: 'Middle Name',
      positionColumn: 4,
      positionRow: 1,
      positionSpan: 3,
      type: 'TEXT_INPUT',
      constraints: []
    },
    {
      title: 'Last Name',
      positionColumn: 7,
      positionRow: 1,
      positionSpan: 3,
      type: 'TEXT_INPUT',
      constraints: [
        {
          name: 'required',
          value: 'true'
        }
      ]
    },
    {
      title: 'Date of Birth',
      positionColumn: 1,
      positionRow: 2,
      positionSpan: 3,
      type: 'DATE',
      constraints: [
        {
          name: 'required',
          value: 'true'
        }
      ]
    },
    {
      title: 'Gender',
      positionColumn: 4,
      positionRow: 2,
      positionSpan: 3,
      type: 'DROP_DOWN',
      constraints: [
        {
          name: 'required',
          value: 'true'
        }
      ],
      options: [
        { option: 'Male', positionOrder: 0 },
        { option: 'Female', positionOrder: 1 },
        { option: 'Not Specified', positionOrder: 2 }
      ]
    },
    {
      title: 'Address 1',
      positionColumn: 1,
      positionRow: 3,
      positionSpan: 6,
      type: 'TEXT_INPUT',
      constraints: [
        {
          name: 'required',
          value: 'true'
        }
      ]
    },
    {
      title: 'Address 2',
      positionColumn: 1,
      positionRow: 4,
      positionSpan: 6,
      type: 'TEXT_INPUT',
      constraints: []
    },
    {
      title: 'Country',
      positionColumn: 1,
      positionRow: 5,
      positionSpan: 3,
      type: 'DROP_DOWN',
      constraints: [
        {
          name: 'required',
          value: 'true'
        }
      ],
      options: [
        { option: 'Canada', positionOrder: 0 },
        { option: 'United States', positionOrder: 1 }
      ]
    },
    {
      title: 'Province',
      positionColumn: 4,
      positionRow: 5,
      positionSpan: 3,
      type: 'TEXT_INPUT',
      constraints: [
        {
          name: 'required',
          value: 'true'
        }
      ]
    },
    {
      title: 'City',
      positionColumn: 7,
      positionRow: 5,
      positionSpan: 3,
      type: 'TEXT_INPUT',
      constraints: [
        {
          name: 'required',
          value: 'true'
        }
      ]
    },
    {
      title: 'Postal Code',
      positionColumn: 10,
      positionRow: 5,
      positionSpan: 3,
      type: 'TEXT_INPUT',
      constraints: [
        {
          name: 'required',
          value: 'true'
        }
      ]
    }
  ]
};

export { persoanlInformationSectionData };
