import { PropTypes } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import {
  Checkbox,
  Alert,
  TextInput,
  Textarea,
  Divider,
  Button
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { AlertCircle } from 'tabler-icons-react';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { triggerNotification } from '../../../helpers/notificationHelper';
import SelectReportFormInputs from './SelectReportFormInputs';
import { REG_ASSOCIATION_SAVED_TEMPLATE_INPUT_TYPE } from '../../../config/constants';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppTitle from '../../common/AppTitle';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import FormSection from '../../common/FormSection';
import FormReportFilter from './FormReportFilter';

const CustomReportModal = ({
  isOpen,
  onClose,
  pkRegAssociation,
  regReportTemplate,
  regAssociationSavedTemplate,
  isOneTimeReport,
  generateSavedTemplate,
  showDelete
}) => {
  const navigate = useNavigate();
  const mqIndex = useMediaQueryIndex();
  const {
    state,
    generateAdminRegAssociationReport,
    createAdminRegAssociationSavedTemplate,
    updateAdminRegAssociationSavedTemplate,
    deleteAdminRegAssociationSavedTemplate,
    generateAdminRegAssociationSavedTemplate
  } = useContext(RegistrationAdminContext);
  const [formState, setFormState] = useState({
    uuid: '',
    pkRegAssociationSeason: '',
    pkRegAssociationDivisions: [],
    selectedControls: [],
    regAssociationSavedTemplateFormInputs: [],
    saveReportTemplate: false,
    includeRefundedAndDenied: false,
    includeAddons: false,
    includeMerchPackages: false,
    includeTeams: false,
    includeRemovedFormControls: false,
    isLoading: false,
    error: null
  });

  const selectedRegForm = state.regForms.value.find(
    (f) => f.uuid === formState.uuid
  );

  const isLoadingFormControls =
    state.regFormVersionHistory.loading ||
    state.regFormVersionHistory.value?.regForm?.uuid !== selectedRegForm?.uuid;

  useEffect(() => {
    if (isOpen) {
      if (regAssociationSavedTemplate) {
        setFormState({
          name: regAssociationSavedTemplate.name,
          description: regAssociationSavedTemplate.description,
          uuid: regAssociationSavedTemplate.regForm.uuid,
          pkRegAssociationSeason: '',
          pkRegAssociationDivisions: [],
          selectedControls: regAssociationSavedTemplate.regAssociationSavedTemplateFormInputs.map(
            (i) => ({
              ...i,
              section: i.subValue
            })
          ),
          saveReportTemplate: false,
          includeRefundedAndDenied: false,
          includeAddons: false,
          includeMerchPackages: false,
          includeTeams: false,
          includeRemovedFormControls: false,
          error: null,
          showDelete: false,
          loading: false
        });
      }
      else {
        setFormState({
          name: '',
          description: '',
          uuid: '',
          pkRegAssociationSeason: '',
          pkRegAssociationDivisions: [],
          selectedControls: [],
          saveReportTemplate: false,
          includeRefundedAndDenied: false,
          includeAddons: false,
          includeMerchPackages: false,
          includeTeams: false,
          includeRemovedFormControls: false,
          isLoading: false,
          error: null,
          showDelete: false,
          loading: false
        });
      }
    }
  }, [isOpen]);

  const generateCustomReport = () => {
    generateAdminRegAssociationReport(
      {
        pkRegAssociation,
        pkRegReportTemplate: regReportTemplate.pkRegReportTemplate,
        inputAnswers: [
          {
            valueName: 'pkRegAssociationDivision',
            value: formState.pkRegAssociationDivisions.join()
          },
          {
            valueName: 'pkRegAssociationSeason',
            value: formState.pkRegAssociationSeason
          },
          { valueName: 'uuid', value: formState.uuid },
          {
            valueName: REG_ASSOCIATION_SAVED_TEMPLATE_INPUT_TYPE[1].value,
            value: formState.includeRefundedAndDenied
              ? 'IncludeAllPayments'
              : null
          },
          {
            valueName: 'includeAddons',
            value: formState.includeAddons ? 'yes' : 'no'
          },
          {
            valueName: 'includeMerchPackages',
            value: formState.includeMerchPackages ? 'yes' : 'no'
          },
          {
            valueName: 'includeTeams',
            value: formState.includeTeams ? 'yes' : 'no'
          },
          ...formState.selectedControls
            .filter((i) => formState.includeRemovedFormControls || !i.removed)
            .map((i) => ({
              valueName: i.value,
              subValueName: i.section,
              value: i.type
            }))
        ]
      },
      () =>
        navigate(
          `/admin/reports/${pkRegAssociation}/${
            regReportTemplate.pkRegReportTemplate
          }?r=${new Date().getTime()}`
        ),
      () => {
        setFormState({
          ...formState,
          loading: false
        });
        triggerNotification();
      }
    );
  };

  const onError = (errorMessage) => {
    triggerNotification(errorMessage);
    setFormState({
      ...formState,
      loading: false
    });
  };

  return (
    <ResponsiveModal
      centered
      isLoading={state.regForms.loading}
      isOpen={isOpen}
      onClose={onClose}
      title={
        <AppTitle order={2}>
          {showDelete || formState.showDelete
            ? 'Delete Report'
            : isOneTimeReport || generateSavedTemplate
            ? 'Generate Report'
            : regAssociationSavedTemplate
            ? 'Edit Report'
            : 'Create Report'}
        </AppTitle>
      }
    >
      {!state.regForms.loading &&
        (formState.showDelete || showDelete ? (
          <FormSection
            isLoading={formState.loading}
            onCancel={() => {
              if (formState.showDelete) {
                setFormState({ ...formState, showDelete: false });
              }
              else {
                onClose();
              }
            }}
            onSubmit={() => {
              setFormState({
                ...formState,
                loading: true
              });
              deleteAdminRegAssociationSavedTemplate(
                regAssociationSavedTemplate.pkRegAssociationSavedTemplate,
                onClose,
                onError
              );
            }}
            style={{ gap: 30 }}
            submitColor="red"
            submitTitle="Delete"
          >
            <AppStack style={{ gap: 20 }}>
              <AppText style={{ textAlign: 'center' }}>
                Are you sure you want to delete the following saved template?
              </AppText>
            </AppStack>
          </FormSection>
        ) : (
          <FormSection
            isLoading={formState.isLoading}
            isSubmitDisabled={isLoadingFormControls}
            onCancel={onClose}
            onSubmit={() => {
              if (formState.selectedControls.length === 0) {
                setFormState({
                  ...formState,
                  error: 'Please select one or more form controls.'
                });
              }
              else {
                setFormState({
                  ...formState,
                  isLoading: true
                });
                if (isOneTimeReport && !formState.saveReportTemplate) {
                  generateCustomReport();
                }
                else if (regAssociationSavedTemplate) {
                  if (generateSavedTemplate) {
                    generateAdminRegAssociationSavedTemplate(
                      [
                        regAssociationSavedTemplate.pkRegAssociationSavedTemplate
                      ],
                      {
                        inputAnswers: [
                          {
                            valueName: 'pkRegAssociationDivision',
                            value: formState.pkRegAssociationDivisions.join()
                          },
                          {
                            valueName:
                              REG_ASSOCIATION_SAVED_TEMPLATE_INPUT_TYPE[1]
                                .value,
                            value: formState.includeRefundedAndDenied
                              ? 'IncludeAllPayments'
                              : null
                          },
                          {
                            valueName: 'includeAddons',
                            value: formState.includeAddons ? 'yes' : 'no'
                          },
                          {
                            valueName: 'includeTeams',
                            value: formState.includeTeams ? 'yes' : 'no'
                          }
                        ]
                      },
                      () =>
                        navigate(
                          `/admin/reports/${pkRegAssociation}/${
                            regAssociationSavedTemplate.pkRegAssociationSavedTemplate
                          }?r=${new Date().getTime()}`
                        ),
                      (message) => {
                        setFormState({
                          ...formState,
                          loading: false
                        });
                        triggerNotification(message);
                      }
                    );
                  }
                  else {
                    updateAdminRegAssociationSavedTemplate(
                      regAssociationSavedTemplate.pkRegAssociationSavedTemplate,
                      {
                        name: formState.name,
                        description: formState.description,
                        fkRegForm: selectedRegForm.pkRegForm,
                        regAssociationSavedTemplateFormInputs: formState.selectedControls.map(
                          (i) => ({
                            ...i,
                            subValue: i.section
                          })
                        )
                      },
                      onClose,
                      onError
                    );
                  }
                }
                else {
                  createAdminRegAssociationSavedTemplate(
                    {
                      name: formState.name,
                      description: formState.description,
                      fkRegForm: selectedRegForm.pkRegForm,
                      regAssociationSavedTemplateFormInputs: formState.selectedControls.map(
                        (i) => ({
                          ...i,
                          subValue: i.section
                        })
                      )
                    },
                    () => {
                      if (isOneTimeReport) {
                        generateCustomReport();
                      }
                      else {
                        onClose();
                      }
                    },
                    onError
                  );
                }
              }
            }}
            submitTitle={
              isOneTimeReport || generateSavedTemplate
                ? 'Generate'
                : regAssociationSavedTemplate
                ? 'Save Changes'
                : 'Create Report'
            }
          >
            <AppStack>
              {isOneTimeReport && (
                <Checkbox
                  checked={formState.saveReportTemplate}
                  disabled={formState.isLoading}
                  label="Save this report to use as template for later"
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      saveReportTemplate: e.currentTarget.checked
                    })
                  }
                  style={{ fontWeight: 500 }}
                />
              )}

              {(!isOneTimeReport || formState.saveReportTemplate) && (
                <>
                  <TextInput
                    disabled={formState.isLoading || generateSavedTemplate}
                    label="Name"
                    onChange={(e) =>
                      setFormState({
                        ...formState,
                        name: e.currentTarget.value.substring(0, 255)
                      })
                    }
                    required
                    value={formState.name}
                  />
                  {!generateSavedTemplate && (
                    <Textarea
                      autosize
                      disabled={formState.isLoading}
                      label="Description"
                      minRows={4}
                      onChange={(e) =>
                        setFormState({
                          ...formState,
                          description: e.currentTarget.value.substring(0, 1024)
                        })
                      }
                      value={formState.description}
                    />
                  )}
                </>
              )}

              <FormReportFilter
                disableFormSelect={generateSavedTemplate}
                filterState={formState}
                hideSeasonDivisionSelects={
                  !isOneTimeReport && !generateSavedTemplate
                }
                isLoading={formState.isLoading}
                onDivisionChange={(v) =>
                  setFormState((c) => ({
                    ...c,
                    pkRegAssociationDivisions: v,
                    error: null
                  }))
                }
                onFormChange={(v) =>
                  setFormState((c) => ({
                    ...c,
                    uuid: v,
                    pkRegAssociationSeason: null,
                    pkRegAssociationDivisions: [],
                    includeRoles: false,
                    includeFinancialAssistance: false,
                    formControls: {},
                    error: null
                  }))
                }
                onSeasonChange={(v) =>
                  setFormState((c) => ({
                    ...c,
                    pkRegAssociationSeason: v,
                    pkRegAssociationDivisions: [],
                    error: null
                  }))
                }
                pkRegAssociation={pkRegAssociation}
              />
              {(generateSavedTemplate || isOneTimeReport) && (
                <>
                  <Checkbox
                    checked={formState.includeRefundedAndDenied}
                    disabled={formState.isLoading}
                    label="Include refunded and denied registrations"
                    onChange={(e) =>
                      setFormState({
                        ...formState,
                        includeRefundedAndDenied: e.currentTarget.checked
                      })
                    }
                    style={{ fontWeight: 500, marginTop: 10 }}
                  />
                  <Checkbox
                    checked={formState.includeAddons}
                    disabled={formState.isLoading}
                    label="Include purchased add-on products"
                    onChange={(e) =>
                      setFormState({
                        ...formState,
                        includeAddons: e.currentTarget.checked
                      })
                    }
                    style={{ fontWeight: 500 }}
                  />
                  <Checkbox
                    checked={formState.includeMerchPackages}
                    disabled={formState.isLoading}
                    label="Include purchased merchandise"
                    onChange={(e) =>
                      setFormState({
                        ...formState,
                        includeMerchPackages: e.currentTarget.checked
                      })
                    }
                    style={{ fontWeight: 500 }}
                  />
                  <Checkbox
                    checked={formState.includeTeams}
                    disabled={formState.isLoading}
                    label="Include assigned team name"
                    onChange={(e) =>
                      setFormState({
                        ...formState,
                        includeTeams: e.currentTarget.checked
                      })
                    }
                    style={{ fontWeight: 500 }}
                  />
                </>
              )}

              {formState.uuid && (
                <AppStack style={{ flex: 1, paddingTop: 10 }}>
                  <AppText style={{ textAlign: 'center' }} weight={500}>
                    Select the form controls to include in the report.
                  </AppText>

                  <SelectReportFormInputs
                    disabled={formState.isLoading || generateSavedTemplate}
                    includeRemovedFormControls={
                      formState.includeRemovedFormControls
                    }
                    onSelectControls={(selectedControls) =>
                      setFormState({
                        ...formState,
                        selectedControls
                      })
                    }
                    regForm={selectedRegForm}
                    selectedControls={formState.selectedControls}
                    setIncludeRemovedFormControls={(value) =>
                      setFormState({
                        ...formState,
                        includeRemovedFormControls: value
                      })
                    }
                  />
                </AppStack>
              )}

              {formState.error && (
                <Alert
                  color="red"
                  icon={<AlertCircle size={16} />}
                  radius="xs"
                  style={{ flex: rs([null, null, 1], mqIndex), marginTop: 10 }}
                  variant="filled"
                >
                  {formState.error}
                </Alert>
              )}

              {regAssociationSavedTemplate && !generateSavedTemplate && (
                <AppStack style={{ flex: 1, alignItems: 'center', gap: 30 }}>
                  <Divider style={{ width: '100%' }} />
                  <Button
                    color="red"
                    disabled={formState.isLoading || isLoadingFormControls}
                    onClick={() =>
                      setFormState({
                        ...formState,
                        showDelete: true
                      })
                    }
                    style={{ width: '100%', maxWidth: 250 }}
                  >
                    Delete
                  </Button>
                </AppStack>
              )}
            </AppStack>
          </FormSection>
        ))}
    </ResponsiveModal>
  );
};

CustomReportModal.propTypes = {
  generateSavedTemplate: PropTypes.bool,
  isOneTimeReport: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  pkRegAssociation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  regAssociationSavedTemplate: PropTypes.object,
  regReportTemplate: PropTypes.object,
  showDelete: PropTypes.bool
};

export default CustomReportModal;
