import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { Context as RegistrationDashboardContext } from '../../../providers/RegistrationAdminDashboardProvider';
import AppCard from '../../common/AppCard';
import TableView from '../../common/TableView';
import { useModalState, useSortByFilter } from '../../../helpers/hooks';
import ExpireInviteModal from '../invites/ExpireInviteModal';
import ColoredAvatar from '../../common/ColoredAvatar';
import ResendInviteModal from '../invites/ResendInviteModal';

const INVITE_ACTIONS_ENUM = {
  expire: 'EXPIRE',
  resend: 'RESEND',
  sendReservation: 'APPROVE',
  email: 'EMAIL'
};

const TABLE_COLUMNS = [
  {
    label: 'Name',
    value: 'name',
    sortable: true
  },
  {
    label: 'Division',
    value: 'division',
    sortable: true
  },
  {
    label: 'Form',
    value: 'form',
    sortable: true
  },
  {
    label: 'Status',
    value: 'status',
    sortable: true
  }
];

const LeagueDivisionInvitesView = ({ isLoading, filterState, filters }) => {
  const { state, fetchAdminRegAssociationDivisionFormInvites } = useContext(
    RegistrationAdminContext
  );
  const { state: dashboardState } = useContext(RegistrationDashboardContext);
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const {
    sortValue,
    isDescendingSort,
    onChangeSortBy,
    customFilterData
  } = useSortByFilter(TABLE_COLUMNS);

  const divisionFormInvites =
    state.regAssociationDivisionFormInvites.value.map((i) => ({
      ...i,
      pkInvite: i.pkRegAssociationDivisionFormInvite,
      label: `${i.regFormSubmission.regPerson.firstName} ${i.regFormSubmission.regPerson.lastName}`,
      subLabel: dayjs(i.regFormSubmission.regPerson.dob).format('MM/DD/YYYY'),
      email: i.regFormSubmission.user.email,
      description: i.regAssociationDivisionForm.regAssociationDivision.name,
      subDescription: i.regAssociationDivisionForm.regForm.name,
      inviteCode: i.inviteCode,
      accepted: i.accepted,
      deleted: i.deleted,
      expiresAt: i.expiresAt,
      isExternalInvite: false,
      acceptUrl: `/checkout/invite?invite=${i.pkRegAssociationDivisionFormInvite}`
    })) ?? [];

  const divisionFormExternalInvites =
    state.regAssociationDivisionFormExternalInvites.value.map((i) => ({
      ...i,
      pkInvite: i.pkRegAssociationDivisionFormExternalInvite,
      label: `${i.regPerson?.firstName ?? i.participantFirstName} ${
        i.regPerson?.lastName ?? i.participantLastName
      }`,
      subLabel: i.dateOfBirth
        ? dayjs(i.dateOfBirth).format('MM/DD/YYYY')
        : null,
      email: i.sentTo,
      description: i.regAssociationDivisionForm.regAssociationDivision.name,
      subDescription: i.regAssociationDivisionForm.regForm.name,
      inviteCode: i.inviteCode,
      accepted: i.accepted,
      deleted: i.deleted,
      expiresAt: i.expiresAt,
      isExternalInvite: true,
      acceptUrl: `/checkout/invite?invite=${i.pkRegAssociationDivisionFormInvite}`
    })) ?? [];

  const divisionInvites = [
    ...divisionFormInvites,
    ...divisionFormExternalInvites
  ].sort((a, b) => a.label.localeCompare(b.label));

  return (
    <AppCard style={{ flex: 1, padding: 0, height: '100%' }}>
      <TableView
        columns={TABLE_COLUMNS}
        emptyMessage="No Registrants Available"
        filters={filters}
        isDescendingSort={isDescendingSort}
        isLoading={isLoading}
        lastUpdated={
          state.regAssociationDivisionFormInvites.lastUpdated +
          state.regAssociationDivisionFormExternalInvites.lastUpdated
        }
        onAction={(action, item) => {
          if (action === INVITE_ACTIONS_ENUM.email) {
            window.open(`mailto:${item.email}`);
          }
          else {
            onOpenModal(
              action,
              divisionInvites.find(
                (s) =>
                  s.pkInvite === item.pkInvite &&
                  s.isExternalInvite === item.isExternalInvite
              )
            );
          }
        }}
        onChangeSortBy={onChangeSortBy}
        onRefresh={() =>
          fetchAdminRegAssociationDivisionFormInvites({
            pkRegAssociation:
              dashboardState.regAssociation.value.pkRegAssociation
          })
        }
        rows={divisionInvites
          .filter(
            (i) =>
              (!filterState.pkRegAssociationSeason ||
                i.regAssociationDivisionForm.regAssociationDivision.fkRegAssociationSeason.toString() ===
                  filterState.pkRegAssociationSeason) &&
              (!filterState.pkRegAssociationDivision ||
                i.regAssociationDivisionForm.regAssociationDivision.pkRegAssociationDivision.toString() ===
                  filterState.pkRegAssociationDivision) &&
              (!filterState.regFormUuid ||
                i.regAssociationDivisionForm.regForm.uuid ===
                  filterState.regFormUuid) &&
              (!filterState.regPersonName ||
                i.label
                  .toLowerCase()
                  .includes(filterState.regPersonName.toLowerCase()))
          )
          .map((i) => {
            const hasExpired =
              i.deleted ||
              (i.expiresAt ? new Date() >= new Date(i.expiresAt) : false);

            let expiryTimeHours = i.expiresAt
              ? dayjs(i.expiresAt).diff(dayjs(new Date()), 'hour')
              : 0;
            const expiryTimeMinutes = i.expiresAt
              ? Math.ceil(
                  dayjs(i.expiresAt).diff(dayjs(new Date()), 'minute', true)
                )
              : 0;
            const expiryTimeDays = Math.ceil(expiryTimeHours / 24);
            expiryTimeHours -= Math.floor(expiryTimeHours / 24) * 24;

            return {
              key: `${i.pkInvite}-${i.isExternalInvite}`,
              pkInvite: i.pkInvite,
              email: i.email,
              isExternalInvite: i.isExternalInvite,
              actions: i.accepted
                ? []
                : hasExpired
                ? [
                    { label: 'Resend', value: INVITE_ACTIONS_ENUM.resend },
                    { label: 'Email Contact', value: INVITE_ACTIONS_ENUM.email }
                  ]
                : [
                    { label: 'Expire', value: INVITE_ACTIONS_ENUM.expire },
                    { label: 'Resend', value: INVITE_ACTIONS_ENUM.resend },
                    { label: 'Email Contact', value: INVITE_ACTIONS_ENUM.email }
                  ],
              columns: [
                {
                  key: 1,
                  label: i.label,
                  weight: 500,
                  subLabel: i.email,
                  onSubLabelClick: () => window.open(`mailto:${i.email}`),
                  icon: (
                    <ColoredAvatar
                      name={i.label.toUpperCase()}
                      uniqueId={i.email}
                    />
                  )
                },
                {
                  key: 2,
                  label:
                    i.regAssociationDivisionForm.regAssociationDivision.name,
                  weight: 500,
                  subLabel:
                    i.regAssociationDivisionForm.regAssociationDivision
                      .regAssociationSeason?.name
                },
                {
                  key: 3,
                  label: i.subDescription,
                  showOnMobile: true
                },
                {
                  key: 4,
                  label: i.accepted
                    ? 'Accepted'
                    : hasExpired
                    ? 'Expired'
                    : !i.expiresAt
                    ? 'Invited'
                    : `Expires in ${
                        expiryTimeDays > 1
                          ? `${expiryTimeDays} ${
                              expiryTimeDays === 1 ? 'day' : 'days'
                            }`
                          : expiryTimeHours > 0
                          ? `${expiryTimeHours} ${
                              expiryTimeHours === 1 ? 'hour' : 'hours'
                            }`
                          : expiryTimeMinutes > 0 &&
                            `${expiryTimeMinutes} ${
                              expiryTimeMinutes === 1 ? 'minute' : 'minutes'
                            }`
                      }`,
                  color: i.accepted ? 'green' : hasExpired ? 'red' : 'blue',
                  showOnMobile: true
                }
              ]
            };
          })}
        sortBy={sortValue}
        sortFilter={customFilterData}
        tableTitle="Invites"
      />

      <ExpireInviteModal
        invite={modalState.item}
        isAdminViewing
        isOpen={
          modalState.isOpen && modalState.action === INVITE_ACTIONS_ENUM.expire
        }
        onClose={onCloseModal}
      />

      <ResendInviteModal
        invite={modalState.item}
        isAdminViewing
        isOpen={
          modalState.isOpen && modalState.action === INVITE_ACTIONS_ENUM.resend
        }
        onClose={onCloseModal}
      />
    </AppCard>
  );
};

LeagueDivisionInvitesView.propTypes = {
  filterState: PropTypes.object,
  filters: PropTypes.array,
  isLoading: PropTypes.bool
};

export default LeagueDivisionInvitesView;
