import React from 'react';
import { PropTypes } from 'prop-types';
import { Button, ThemeIcon } from '@mantine/core';
import { Check } from 'tabler-icons-react';
import { useNavigate } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import AppImage from '../../common/AppImage';
import AppText from '../../common/AppText';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import MerchPackageShowcase from '../merch/MerchPackageShowcase';
import { calculateCartTotals } from '../../../helpers/paymentHelper';
import { ANALYTIC_MERCH_PACKAGE_SHOWCASE_LOCATION_ENUM } from '../../../config/constants';

const RegistrationCartSummary = ({
  regAssociation,
  regCart,
  isRegistrationFull,
  onClose,
  hideMerchPackageShowcase
}) => {
  const mqIndex = useMediaQueryIndex();
  const navigate = useNavigate();
  const cartTotals = regCart ? calculateCartTotals(regCart.cart.lineItems) : {};

  return (
    <AppStack>
      <AppFlexbox
        style={{
          alignItems: 'start',
          flexDirection: rs(['column', 'column', 'row'], mqIndex),
          marginTop: 10,
          flex: 1
        }}
      >
        <AppStack
          style={{
            flex: rs(['unset', 'unset', 1], mqIndex),
            alignItems: 'center',
            gap: 5,
            alignSelf: 'center'
          }}
        >
          <AppFlexbox style={{ gap: 15 }}>
            <ThemeIcon color="green" radius="xl">
              <Check />
            </ThemeIcon>
            <AppText style={{ fontSize: 18, textAlign: 'center' }} weight={500}>
              {isRegistrationFull ? 'Added to Waitlist' : 'Added to Cart'}
            </AppText>
          </AppFlexbox>
          <AppImage
            fit="contain"
            height={60}
            src={regAssociation?.logoImageUrl}
            width={60}
          />
        </AppStack>
        <AppStack
          style={{
            flex: 2,
            gap: 10,
            alignSelf: rs(['stretch', 'stretch', 'center'], mqIndex)
          }}
        >
          {!isRegistrationFull && (
            <AppFlexbox
              style={{
                gap: 5,
                alignSelf: rs(['center', 'center', 'start'], mqIndex)
              }}
            >
              <AppText style={{ fontSize: 18 }} weight={500}>
                Cart subtotal
              </AppText>
              <AppText style={{ fontSize: 18 }}>
                (
                {regCart.cart.lineItems.length === 1
                  ? '1 Item'
                  : `${regCart.cart.lineItems.length} Items`}
                ):
              </AppText>
              <AppText color="red" style={{ fontSize: 18 }}>
                <b>
                  <NumberFormat
                    decimalScale={2}
                    displayType="text"
                    fixedDecimalScale
                    prefix="$"
                    thousandSeparator
                    value={cartTotals.subtotalInCents / 100}
                  />{' '}
                  {regCart?.cart.currency?.toUpperCase()}
                </b>
              </AppText>
            </AppFlexbox>
          )}
          <AppStack
            style={{
              flex: 1,
              gap: 20,
              flexWrap: 'nowrap',
              flexDirection: rs(['column', 'row', 'row'], mqIndex),
              alignSelf: 'stretch'
            }}
          >
            <Button
              onClick={() => onClose()}
              style={{ flex: rs(['unset', 1, 'unset'], mqIndex) }}
              variant="outline"
            >
              Keep shopping
            </Button>
            {isRegistrationFull ? (
              <Button
                onClick={() => navigate('/waitlist')}
                style={{ flex: rs(['unset', 1, 1], mqIndex) }}
              >
                View Waitlist
              </Button>
            ) : (
              <Button
                onClick={() => navigate('/checkout/cart')}
                style={{ flex: rs(['unset', 1, 1], mqIndex) }}
              >
                Proceed to checkout (
                {regCart.cart.lineItems.length === 1
                  ? '1 Item'
                  : `${regCart.cart.lineItems.length} Items`}
                )
              </Button>
            )}
          </AppStack>
        </AppStack>
      </AppFlexbox>
      {!hideMerchPackageShowcase && regAssociation.merchEnabled && (
        <MerchPackageShowcase
          analyticCta={
            ANALYTIC_MERCH_PACKAGE_SHOWCASE_LOCATION_ENUM.ORDER_SUMMARY
          }
          extraPadding
          hideNoMerch
          includeUrgencyWarning
          regAssociation={regAssociation}
        />
      )}
    </AppStack>
  );
};

RegistrationCartSummary.propTypes = {
  hideMerchPackageShowcase: PropTypes.bool,
  isRegistrationFull: PropTypes.bool,
  onClose: PropTypes.func,
  regAssociation: PropTypes.object,
  regCart: PropTypes.object
};

export default RegistrationCartSummary;
