import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import Papa from 'papaparse';
import { Alert, Button, Input } from '@mantine/core';
import { Pencil, X } from 'tabler-icons-react';
import FormSection from '../../common/FormSection';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppTitle from '../../common/AppTitle';
import FileUploader from '../../common/FileUploader';
import { triggerNotification } from '../../../helpers/notificationHelper';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import PaginationList from '../../common/PaginationList';
import ActionableIcon from '../../common/ActionableIcon';

const ExternalInviteCsvModal = ({ isOpen, onClose, onImport }) => {
  const [formState, setFormState] = useState({
    showUpload: true,
    data: [],
    isLoading: false
  });

  useEffect(() => {
    if (isOpen) {
      setFormState({ showUpload: true, data: [], isLoading: false });
    }
  }, [isOpen]);

  const parseCsv = (file) => {
    setFormState({ ...formState, isLoading: true });

    Papa.parse(file, {
      complete: (results) => {
        let emailIndex = null;
        let skipRows = 0;
        for (let index = 0; index < results.data.length; index += 1) {
          const foundEmailIndex = results.data[index].findIndex((e) =>
            e.includes('@')
          );
          if (foundEmailIndex >= 0) {
            emailIndex = foundEmailIndex;
            skipRows = index;
            break;
          }
        }

        if (emailIndex == null || emailIndex > 2) {
          triggerNotification('No email column found in CSV file');
          setFormState({
            ...formState,
            isLoading: false
          });
        }
        else {
          const keyTime = new Date().getTime();
          setFormState({
            ...formState,
            showUpload: false,
            data: results.data
              .splice(skipRows)
              .filter((d) => d.length >= 3)
              .map((d, index) => {
                const email = d.splice(emailIndex, 1)[0].trim();
                return {
                  key: keyTime + index,
                  email,
                  firstName: d[0].trim(),
                  lastName: d[1].trim()
                };
              }),
            isLoading: false
          });
        }
      }
    });
  };

  const downloadCsv = () => {
    const csv = Papa.unparse({
      fields: ['email', 'first_name', 'last_name'],
      data: []
    });
    const blob = new Blob([csv]);
    const csvURL = URL.createObjectURL(blob, { type: 'text/plain' });
    const link = document.createElement('a');
    link.setAttribute('href', csvURL);
    link.setAttribute('download', `invite users template.csv`);
    document.body.appendChild(link);
    link.click();
  };

  const onContactChange = (contactData) => {
    setFormState({
      ...formState,
      data: [
        ...formState.data.filter((f) => f.key !== contactData.key),
        contactData
      ]
    });
  };

  return (
    <ResponsiveModal
      onClose={onClose}
      opened={isOpen}
      size={600}
      title={<AppTitle order={2}>Import Contacts</AppTitle>}
    >
      <FormSection
        cancelTitle="Cancel"
        isSubmitDisabled={formState.data.length === 0}
        onCancel={onClose}
        onSubmit={() => {
          onImport(formState.data);
        }}
        submitTitle="Import"
      >
        {(!formState.isLoading && formState.showUpload) ||
        formState.data === 0 ? (
          <>
            <FileUploader
              accept=".csv"
              height={300}
              onError={triggerNotification}
              onUpload={parseCsv}
              validFileTypes={['text/csv']}
            />
            <Alert variant="outline">
              <AppStack style={{ justifyContent: 'center' }}>
                <span>
                  To import contacts, please upload a CSV file with the
                  following columns in this specific order:{' '}
                  <strong>email</strong>, <strong>first_name</strong>,{' '}
                  <strong>last_name</strong>
                </span>
                <Button onClick={downloadCsv}>Download Template</Button>
              </AppStack>
            </Alert>
          </>
        ) : (
          <AppStack style={{ gap: 5 }}>
            <AppFlexbox
              style={{ justifyContent: 'space-between', alignItems: 'center' }}
            >
              <AppText weight={500}>Contacts ({formState.data.length})</AppText>
              <Button
                onClick={() =>
                  setFormState({
                    ...formState,
                    showUpload: true,
                    data: []
                  })
                }
                size="compact-sm"
                variant="outline"
              >
                Clear Contacts
              </Button>
            </AppFlexbox>
            <AppStack style={{ gap: 0 }}>
              <AppFlexbox
                style={{ flex: 1, gap: 0, border: 'solid 1px lightgrey' }}
              >
                <AppStack
                  style={{
                    flex: 1,
                    borderRight: 'solid 1px lightgrey'
                  }}
                >
                  <AppText style={{ margin: '5px 10px' }} weight={500}>
                    Email
                  </AppText>
                </AppStack>
                <AppStack
                  style={{
                    flex: 1,
                    borderRight: 'solid 1px lightgrey'
                  }}
                >
                  <AppText style={{ margin: '5px 10px' }} weight={500}>
                    First Name
                  </AppText>
                </AppStack>
                <AppStack style={{ flex: 1 }}>
                  <AppText style={{ margin: '5px 10px' }} weight={500}>
                    Last Name
                  </AppText>
                </AppStack>
              </AppFlexbox>
              <PaginationList
                items={formState.data
                  .sort((a, b) => a.key - b.key)
                  .map((u) => (
                    <AppFlexbox
                      key={u.key}
                      style={{
                        flex: 1,
                        gap: 0,
                        border: 'solid 1px lightgrey',
                        borderTop: 'none'
                      }}
                    >
                      <AppStack
                        key={u.key}
                        style={{
                          flex: 1,
                          borderRight: 'solid 1px lightgrey'
                        }}
                      >
                        <Input
                          icon={<Pencil />}
                          onChange={(e) =>
                            onContactChange({
                              ...u,
                              email: e.currentTarget.value
                            })
                          }
                          placeholder="sports@sportsheadz.com"
                          radius={0}
                          required
                          style={{ flex: 1 }}
                          styles={{ input: { border: 'none' } }}
                          type="email"
                          value={u.email}
                        />
                      </AppStack>
                      <AppStack
                        style={{
                          flex: 1,
                          borderRight: 'solid 1px lightgrey'
                        }}
                      >
                        <Input
                          icon={<Pencil />}
                          onChange={(e) =>
                            onContactChange({
                              ...u,
                              firstName: e.currentTarget.value
                            })
                          }
                          placeholder="John"
                          radius={0}
                          required
                          style={{ flex: 1 }}
                          styles={{ input: { border: 'none' } }}
                          value={u.firstName}
                        />
                      </AppStack>
                      <AppStack style={{ flex: 1 }}>
                        <Input
                          icon={<Pencil />}
                          onChange={(e) =>
                            onContactChange({
                              ...u,
                              lastName: e.currentTarget.value
                            })
                          }
                          placeholder="Doe"
                          radius={0}
                          required
                          rightSection={
                            <ActionableIcon
                              color="red"
                              onClick={() => {
                                setFormState({
                                  ...formState,
                                  data: [
                                    ...formState.data.filter(
                                      (f) => f.key !== u.key
                                    )
                                  ]
                                });
                              }}
                            >
                              <X />
                            </ActionableIcon>
                          }
                          rightSectionPointerEvents="auto"
                          style={{ flex: 1 }}
                          styles={{ input: { border: 'none' } }}
                          value={u.lastName}
                        />
                      </AppStack>
                    </AppFlexbox>
                  ))}
                itemsPerPage={12}
                noBorder
                onPageChange={(pageIndex) =>
                  setFormState({
                    ...formState,
                    pageIndex
                  })
                }
                pageIndex={formState.pageIndex}
              />
            </AppStack>
          </AppStack>
        )}
      </FormSection>
    </ResponsiveModal>
  );
};

ExternalInviteCsvModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onImport: PropTypes.func
};

export default ExternalInviteCsvModal;
