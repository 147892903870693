import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { List } from '@mantine/core';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { Context as AuthContext } from '../../../providers/AuthProvider';
import InviteCard from './InviteCard';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import { triggerNotification } from '../../../helpers/notificationHelper';

const EvaluatorInviteView = ({ inviteCode }) => {
  const hasFetched = useRef(false);
  const navigate = useNavigate();
  const { state: authState, fetchUserRegAssociationEvaluators } = useContext(
    AuthContext
  );
  const {
    state,
    fetchAdminRegAssociationEvaluatorInvite,
    respondToAdminRegAssociationEvaluatorInvite
  } = useContext(RegistrationAdminContext);
  const [formState, setFormState] = useState({ isLoading: false });
  const regAssociationEvaluation = state.regAssociationEvaluator.value;
  const isLoading =
    !hasFetched.current || state.regAssociationEvaluator.loading;

  useEffect(() => {
    if (inviteCode) {
      fetchAdminRegAssociationEvaluatorInvite(
        inviteCode,
        () => {},
        () => navigate('/invites')
      );
      hasFetched.current = true;
    }
  }, [inviteCode]);

  useEffect(() => {
    if (!isLoading && !regAssociationEvaluation) {
      navigate('/invites');
    }
  }, [isLoading]);

  const respondToInvite = (isAccepted) => {
    setFormState({ isLoading: true });
    respondToAdminRegAssociationEvaluatorInvite(
      inviteCode,
      { isAccepted },
      () => {
        triggerNotification(
          isAccepted ? 'Invite Accepted' : 'Invite Declined',
          'Success',
          'green'
        );
        fetchUserRegAssociationEvaluators(authState.userData.user.pkUser);
        navigate('/admin/evaluations');
      },
      (e) => {
        triggerNotification(e);
        setFormState({ isLoading: false });
      }
    );
  };

  return (
    <InviteCard
      expiresAt={regAssociationEvaluation?.expiresAt}
      isDeleted={regAssociationEvaluation?.deleted}
      isLoading={isLoading || !regAssociationEvaluation}
      isSubmitting={formState.isLoading}
      onAccept={() => respondToInvite(true)}
      onDecline={() => respondToInvite(false)}
    >
      {regAssociationEvaluation && (
        <AppStack style={{ gap: 20 }}>
          <AppText weight={500}>
            You've been invited to be an evaluator for{' '}
            {regAssociationEvaluation.regAssociation.association.name}
          </AppText>
          <AppText>
            The access granted includes the following divisions;
          </AppText>
          <List spacing="sm" withPadding>
            {regAssociationEvaluation.regAssociationDivisions.map((d) => (
              <List.Item key={d.pkRegAssociationDivision}>{d.name}</List.Item>
            ))}
          </List>
        </AppStack>
      )}
    </InviteCard>
  );
};

EvaluatorInviteView.propTypes = { inviteCode: PropTypes.string };

export default EvaluatorInviteView;
