import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TextInput, Title } from '@mantine/core';
import NumberFormat from 'react-number-format';
import FormSection from '../../common/FormSection';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import { Context as AuthContext } from '../../../providers/AuthProvider';
import { triggerNotification } from '../../../helpers/notificationHelper';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../../helpers/styles';

const ContactInformationForm = ({ onClose }) => {
  const mqIndex = useMediaQueryIndex();
  const { state, updateProfile } = useContext(AuthContext);
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    avatar: '',
    isLoading: false
  });
  const { user } = state.userData;

  useEffect(() => {
    if (user) {
      const fullName = user.name.split(' ');
      setFormState({
        lastName: fullName.pop(),
        firstName: fullName.join(' '),
        phone: user.phone,
        avatar: user.avatar,
        isLoading: false
      });
    }
  }, [user]);

  return (
    <FormSection
      isLoading={formState.isLoading}
      onCancel={onClose}
      onSubmit={() => {
        setFormState({
          ...formState,
          isLoading: true
        });
        updateProfile(
          formState,
          () => {
            triggerNotification('Contact info updated!', 'Success', 'green');
            onClose();
          },
          (e) => {
            triggerNotification(e);
            setFormState({
              ...formState,
              isLoading: false
            });
          }
        );
      }}
      submitTitle="Save Changes"
    >
      <AppStack>
        <Title order={3}>Contact Information</Title>
        <AppFlexbox
          style={{ flex: 1, flexDirection: rs(['column', 'row'], mqIndex) }}
        >
          <TextInput
            disabled={formState.isLoading}
            label="First Name"
            onChange={(e) =>
              setFormState({
                ...formState,
                firstName: e.currentTarget.value
              })
            }
            required
            style={{ flex: 1 }}
            value={formState.firstName}
          />
          <TextInput
            disabled={formState.isLoading}
            label="Last Name"
            onChange={(e) =>
              setFormState({
                ...formState,
                lastName: e.currentTarget.value
              })
            }
            required
            style={{ flex: 1 }}
            value={formState.lastName}
          />
        </AppFlexbox>

        <NumberFormat
          customInput={TextInput}
          disabled={formState.isLoading}
          fixedDecimalScale
          format="+1 (###) ###-####"
          label="Phone Number"
          mask="_"
          name="phoneNumber"
          onValueChange={(values) => {
            setFormState({
              ...formState,
              phone: values.value
            });
          }}
          style={{ flex: 1 }}
          styles={{
            label: {
              fontSize: 16,
              fontWeight: 500
            }
          }}
          thousandSeparator
          value={formState.phone}
        />
      </AppStack>
    </FormSection>
  );
};

ContactInformationForm.propTypes = { onClose: PropTypes.func };

export default ContactInformationForm;
