import React, { useState, useContext, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { TextInput } from '@mantine/core';
import { Context as AuthContext } from '../../../providers/AuthProvider';
import SendAuthCode from './SendAuthCode';
import { triggerNotification } from '../../../helpers/notificationHelper';
import AuthCardForm from './AuthCardForm';

const ConfirmAccountView = ({ navigate }) => {
  const {
    state,
    confirmAccount,
    resendVerificationCode,
    clearErrors,
    signin
  } = useContext(AuthContext);
  const [formState, setFormState] = useState({
    email: state.email,
    password: state.password,
    confirmationCode: '',
    showResendCode: !state.email
  });

  useEffect(() => {
    setFormState({
      email: state.email,
      password: state.password,
      confirmationCode: '',
      showResendCode: !state.email
    });
  }, []);

  const onConfirmSuccess = () => {
    triggerNotification('Account confirmed!', 'Success', 'green');
  };

  return formState.showResendCode ? (
    <SendAuthCode
      email={formState.email}
      onCancel={() => {
        clearErrors();
        setFormState({
          ...formState,
          showResendCode: false
        });
      }}
      onSendCode={(email) => {
        resendVerificationCode(email, () => {
          triggerNotification('Confirmation code sent!', 'Success', 'green');
          setFormState({
            ...formState,
            email,
            confirmationCode: '',
            showResendCode: false
          });
        });
      }}
      title="Confirm Your Account"
    />
  ) : (
    <AuthCardForm
      isLoading={state.loading}
      navigate={navigate}
      onSubmit={() => {
        confirmAccount(formState, () => {
          if (state.password) {
            signin(formState, onConfirmSuccess, () => navigate('/auth/login'));
          }
          else {
            navigate('/auth/login');
          }
        });
      }}
      submitSecondaryLink={{
        text: "Didn't get a code?",
        onClick: () =>
          setFormState({
            ...formState,
            showResendCode: true
          })
      }}
      submitText="Confirm"
      title="Confirm Account"
    >
      <TextInput
        disabled={state.loading}
        label="Confirmation Code"
        onChange={(e) =>
          setFormState({
            ...formState,
            confirmationCode: e.target.value
          })
        }
        required
        size="md"
        value={formState.confirmationCode}
      />
    </AuthCardForm>
  );
};

ConfirmAccountView.propTypes = { navigate: PropTypes.func };

export default ConfirmAccountView;
