import React from 'react';
import PropTypes from 'prop-types';
import { BarChart } from '@mantine/charts';

const AnalyticsBarChart = ({
  mainDataLabel,
  compareDataLabel,
  chartData,
  dataKey,
  valueFormatter,
  dataType
}) => {
  const isCurrency = dataType === 'currency';
  const barChartData = chartData.slice(0, 5);

  const longestLabelLength =
    barChartData
      .map((c) => c[dataKey])
      .reduce(
        (acc, cur) => (cur.length > acc ? cur.length : acc),
        isCurrency ? 4.5 : 3
      ) + 3;

  return (
    <BarChart
      data={barChartData}
      dataKey={dataKey}
      h="100%"
      legendProps={{
        iconType: 'plainline',
        verticalAlign: 'bottom',
        align: 'center'
      }}
      orientation="vertical"
      series={
        compareDataLabel
          ? [
              {
                name: mainDataLabel,
                color: 'blue.6'
              },
              {
                name: compareDataLabel,
                color: 'rgba(18, 184, 134, 0.8)',
                strokeDasharray: '5 5'
              }
            ]
          : [{ name: mainDataLabel, color: 'blue.6' }]
      }
      valueFormatter={valueFormatter}
      withLegend
      yAxisProps={{ width: longestLabelLength * 5.5 }}
    />
  );
};

AnalyticsBarChart.propTypes = {
  chartData: PropTypes.array,
  compareDataLabel: PropTypes.string,
  dataKey: PropTypes.string,
  dataType: PropTypes.string,
  mainDataLabel: PropTypes.string,
  valueFormatter: PropTypes.func
};

export default AnalyticsBarChart;
