import React from 'react';
import PropTypes from 'prop-types';
import { NativeSelect } from '@mantine/core';
import classes from '../../styles/nestedStyles.module.css';

const AppNativeSelect = React.forwardRef(({ data, onChange, ...rest }, ref) => (
  <NativeSelect
    ref={ref}
    className={classes.selectNative}
    data={[
      {
        label: '',
        value: ''
      },
      ...data
    ]}
    onChange={(e) => {
      const item = data.find(
        (i) => i.value.toLowerCase() === e.target.value.toLowerCase()
      );
      onChange(item?.value || '');
    }}
    size="md"
    {...rest}
    style={{ flex: 1, ...rest.style }}
  />
));

AppNativeSelect.propTypes = { data: PropTypes.array, onChange: PropTypes.func };

export default AppNativeSelect;
