import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { REG_PERMISSION_ENUM } from '../config/constants';
import { Context as AuthContext } from '../providers/AuthProvider';
import { links } from '../components/content/navigation/links';

const getRegAssociationAdminPermissions = (regAssociationAdmins) =>
  regAssociationAdmins.reduce(
    (r, c) => [
      ...r,
      ...c.regAssociationAdminPermissions.map((p) => p.fkRegPermission)
    ],
    []
  );

const useRegAssociationAdminAccess = () => {
  const { state } = useContext(AuthContext);
  const { pathname } = useLocation();
  const adminView = links.admin.find((p) => p.isSelected(pathname));

  const availableAdminAssociation = adminView
    ? state.regAssociationAdmins.value.filter(
        (a) =>
          a.regAssociationAdminPermissions.some(
            (p) => p.fkRegPermission === REG_PERMISSION_ENUM.SUPER_ADMIN
          ) ||
          a.regAssociationAdminPermissions.some((p) =>
            adminView.permissions.includes(p.fkRegPermission)
          )
      )
    : state.regAssociationAdmins.value;

  const regAssociations = availableAdminAssociation.reduce((r, c) => {
    if (
      r.some((a) => a.pkRegAssociation === c.regAssociation.pkRegAssociation)
    ) {
      return r;
    }
    return [...r, c.regAssociation];
  }, []);

  return {
    hasAccess: availableAdminAssociation.length > 0,
    regAssociations,
    isLoading: state.regAssociationAdmins.loading
  };
};

const useAdminAssociations = (pkRegAssociation = null) => {
  const { state } = useContext(AuthContext);
  const regAssociations = state.regAssociationAdmins.value.reduce((r, c) => {
    if (
      r.some((a) => a.pkRegAssociation === c.regAssociation.pkRegAssociation)
    ) {
      return r;
    }
    return [...r, c.regAssociation];
  }, []);

  return {
    regAssociations,
    regAssociation: !pkRegAssociation
      ? null
      : regAssociations.find(
          (a) => a.pkRegAssociation.toString() === pkRegAssociation.toString()
        )
  };
};

export {
  getRegAssociationAdminPermissions,
  useRegAssociationAdminAccess,
  useAdminAssociations
};
