import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';
import { Select, TextInput, Checkbox } from '@mantine/core';
import { Calendar } from 'tabler-icons-react';
import { DatePickerInput } from '@mantine/dates';

const ReportInput = ({
  label,
  name,
  required,
  type,
  inputSource,
  onChange,
  disabled,
  filter,
  value
}) => {
  useEffect(() => {
    if (inputSource && !value && required) {
      onChange(
        name,
        inputSource.sourceData
          .sort((a, b) => a.name.localeCompare(b.name))[0]
          ?.value.toString()
      );
    }
  }, [inputSource]);

  return type === 'TEXT_INPUT' ? (
    <TextInput
      disabled={disabled}
      label={label}
      name={name}
      onChange={(e) => onChange(name, e.currentTarget.value)}
      required={required}
      value={value}
    />
  ) : type === 'DROP_DOWN' ? (
    <Select
      clearable
      data={
        inputSource
          ? inputSource.sourceData
              .filter(filter ?? (() => true))
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((d) => ({
                label: d.name,
                value: d.value.toString()
              }))
          : []
      }
      disabled={disabled}
      label={label}
      name={name}
      onChange={(v) => onChange(name, v)}
      required={required}
      searchable
      value={value}
    />
  ) : type === 'DATE' ? (
    <DatePickerInput
      clearable
      disabled={disabled}
      label={label}
      leftSection={<Calendar color="#000" size={16} />}
      onChange={(v) => onChange(name, v)}
      required={required}
      value={value}
    />
  ) : (
    type === 'CHECKBOX' && (
      <Checkbox
        checked={value === 'true'}
        disabled={disabled}
        label={label}
        onChange={(e) => onChange(name, e.currentTarget.checked.toString())}
        required={required}
      />
    )
  );
};

ReportInput.propTypes = {
  disabled: PropTypes.bool,
  filter: PropTypes.func,
  inputSource: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string
};

export default ReportInput;
