import React from 'react';
import { Alert, Divider } from '@mantine/core';
import PropTypes from 'prop-types';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';

const EmailSubscriptionInfo = ({
  regAssociation,
  email,
  regEmailUnsubscribe,
  actionButton
}) => {
  const hasResubscribed =
    !regEmailUnsubscribe || regEmailUnsubscribe?.resubscribedDate;
  return (
    <Alert
      color={hasResubscribed ? 'green' : 'dark'}
      title={hasResubscribed ? 'Email Subscribed!' : 'Email Unsubscribed!'}
      variant="outline"
    >
      <AppStack style={{ gap: 15 }}>
        <AppStack style={{ gap: 5 }}>
          {hasResubscribed ? (
            <AppText style={{ fontSize: 14 }} weight={500}>
              The email '<b>{email}</b>' has been subscribed to '
              <b>
                {regAssociation.association.name}
                's
              </b>
              ' mailing list.
            </AppText>
          ) : (
            <>
              <AppText style={{ fontSize: 14 }} weight={500}>
                The email '<b>{email}</b>' has been unsubscribed from '
                <b>
                  {regAssociation.association.name}
                  's
                </b>
                ' mailing list.
              </AppText>
              <AppText style={{ fontSize: 14 }}>
                You were originally subscribed to receive emails because you
                registered for one of '
                <b>
                  {regAssociation.association.name}
                  's
                </b>
                ' programs.
              </AppText>
            </>
          )}
        </AppStack>
        <Divider />
        <AppText
          style={{
            fontSize: 14,
            color: '#313234',
            textAlign: 'center'
          }}
        >
          If you have any questions or concerns, please contact the association
          directly at '
          <b>
            <a
              href={`mailto:${regAssociation.supportEmail}`}
              style={{
                color: 'dodgerblue',
                textDecoration: 'none'
              }}
            >
              {regAssociation.supportEmail}
            </a>
          </b>
          '.
        </AppText>
        {actionButton}
      </AppStack>
    </Alert>
  );
};

EmailSubscriptionInfo.propTypes = {
  actionButton: PropTypes.object,
  email: PropTypes.string,
  regAssociation: PropTypes.object,
  regEmailUnsubscribe: PropTypes.object
};

export default EmailSubscriptionInfo;
