import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Accordion,
  Button,
  Divider,
  Select,
  Switch,
  TextInput
} from '@mantine/core';
import { CircleCheck } from 'tabler-icons-react';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppTitle from '../../common/AppTitle';
import FormSection from '../../common/FormSection';
import AppStack from '../../common/AppStack';
import { triggerNotification } from '../../../helpers/notificationHelper';
import AppFlexbox from '../../common/AppFlexbox';
import PaginationAccordionList from '../../common/PaginationAccordionList';
import AppText from '../../common/AppText';
import SelectCreatable from '../../common/SelectCreatable';
import { REG_FORM_CONTROL_TYPE_ENUM } from '../../../config/constants';
import EvaluationSessionAttributesAccordionAssign from './EvaluationSessionAttributesAccordionAssign';
import ConfirmModal from '../../common/ConfirmModal';

const MODAL_VIEWS_ENUM = {
  PLAYERS: 'PLAYERS',
  ATTRIBUTES: 'ATTRIBUTES'
};

const MODAL_VIEWS = [
  {
    value: MODAL_VIEWS_ENUM.PLAYERS,
    label: 'Players'
  },
  {
    value: MODAL_VIEWS_ENUM.ATTRIBUTES,
    label: 'Attributes'
  }
];

const SLIDER_VALUES = [
  { value: 0, label: '0' },
  { value: 0.5 },
  { value: 1, label: '1' },
  { value: 1.5 },
  { value: 2, label: '2' },
  { value: 2.5 },
  { value: 3, label: '3' },
  { value: 3.5 },
  { value: 4, label: '4' },
  { value: 4.5 },
  { value: 5, label: '5' }
];

const STATIC_ATTRIBUTES = [
  {
    label: 'Jersey Number',
    value: 'jerseyNumber',
    fkRegFormControlType: REG_FORM_CONTROL_TYPE_ENUM.TEXT_INPUT,
    options: []
  },
  {
    label: 'Jersey Color',
    value: 'jerseyColor',
    fkRegFormControlType: REG_FORM_CONTROL_TYPE_ENUM.DROP_DOWN,
    options: [
      { label: 'Red', value: 'Red' },
      { label: 'Orange', value: 'Orange' },
      { label: 'Yellow', value: 'Yellow' },
      { label: 'Green', value: 'Green' },
      { label: 'Blue', value: 'Blue' },
      { label: 'Pink', value: 'Pink' },
      { label: 'Purple', value: 'Purple' }
    ]
  }
];

const EvaluationSessionAttributesModal = ({
  isOpen,
  onClose,
  onSaveChanges,
  hasUnsavedChanges,
  isSaving,
  regScoutReportEvaluations,
  onUpdateEvaluationAnswer,
  onUpdateEvaluationAnswers,
  onUpdateEvaluation,
  onUpdateEvaluations,
  attributeQuestions
}) => {
  const [viewState, setViewState] = useState({
    currentView: MODAL_VIEWS_ENUM.PLAYERS,
    hideCompleted: false,
    hideAssignedPlayers: false,
    hideMatchingAnswerPlayers: false,
    attributeAnswer: null,
    selectedAttribute: '',
    assignAllState: {
      showConfirmation: false,
      value: '',
      onConfirm: () => {}
    },
    pageSearch: '',
    pageIndex: 1,
    pageInnerIndex: 1
  });

  useEffect(() => {
    if (!isOpen) {
      setViewState({
        currentView: MODAL_VIEWS_ENUM.PLAYERS,
        hideCompleted: false,
        hideAssignedPlayers: false,
        hideMatchingAnswerPlayers: false,
        attributeAnswer: null,
        assignAllState: {
          showConfirmation: false,
          value: '',
          onConfirm: () => {}
        },
        pageSearch: '',
        pageIndex: 1,
        pageInnerIndex: 1
      });
    }
  }, [isOpen]);

  const isEvaluationAttributesComplete = (evaluation, questions) =>
    questions.every(
      (q) =>
        !!evaluation.jerseyNumber &&
        !!evaluation.jerseyColor &&
        !!evaluation.regScoutReportQuestionAnswers.find(
          (a) => a.fkRegScoutReportQuestion === q.pkRegScoutReportQuestion
        )?.value
    );

  const isQuestionComplete = (question, evaluations) =>
    evaluations.every(
      (e) =>
        !!e.regScoutReportQuestionAnswers.find(
          (a) =>
            a.fkRegScoutReportQuestion === question.pkRegScoutReportQuestion
        )?.value
    );

  return (
    <ResponsiveModal
      noCloseButton
      onClose={() => {}}
      opened={isOpen}
      size={700}
      title={<AppTitle order={2}>Edit Attributes</AppTitle>}
      transitionProps={{
        duration: 0,
        transition: 'none'
      }}
    >
      <FormSection
        isCancelHidden
        isLoading={isSaving}
        onSubmit={() => {
          if (hasUnsavedChanges) {
            onSaveChanges(() => {
              triggerNotification('Changes Saved', 'Success', 'green');
              onClose();
            });
          }
          else {
            onClose();
          }
        }}
        submitColor={hasUnsavedChanges ? 'blue' : 'dark'}
        submitTitle={hasUnsavedChanges ? 'Save Changes' : 'Close'}
      >
        <AppStack style={{ gap: 20 }}>
          <AppFlexbox style={{ gap: 10 }}>
            {MODAL_VIEWS.map((view) => {
              const isSelected = viewState.currentView === view.value;

              return (
                <Button
                  key={view.value}
                  color="dark"
                  onClick={() =>
                    setViewState({
                      ...viewState,
                      currentView: view.value,
                      pageSearch: '',
                      pageIndex: 1
                    })
                  }
                  size="compact-md"
                  style={{ flex: 1 }}
                  variant={isSelected ? 'filled' : 'outline'}
                >
                  {view.label}
                </Button>
              );
            })}
          </AppFlexbox>

          <Divider />
          <AppStack style={{ gap: 10 }}>
            <Switch
              checked={viewState.hideCompleted}
              label="Hide Completed"
              onChange={() => {
                setViewState({
                  ...viewState,
                  hideCompleted: !viewState.hideCompleted,
                  pageIndex: 1
                });
              }}
              style={{ fontWeight: 500 }}
            />
            {viewState.currentView === MODAL_VIEWS_ENUM.ATTRIBUTES && (
              <>
                <Switch
                  checked={viewState.hideAssignedPlayers}
                  label="Hide Assigned Players"
                  onChange={() => {
                    setViewState({
                      ...viewState,
                      hideAssignedPlayers: !viewState.hideAssignedPlayers,
                      pageIndex: 1
                    });
                  }}
                  style={{ fontWeight: 500 }}
                />
                <Switch
                  checked={viewState.hideMatchingAnswerPlayers}
                  label="Hide Matching Answer Players"
                  onChange={() => {
                    setViewState({
                      ...viewState,
                      hideMatchingAnswerPlayers: !viewState.hideMatchingAnswerPlayers,
                      pageIndex: 1
                    });
                  }}
                  style={{ fontWeight: 500 }}
                />
              </>
            )}

            <TextInput
              onChange={(e) =>
                setViewState({
                  ...viewState,
                  pageSearch: e.currentTarget.value,
                  pageIndex: 1
                })
              }
              placeholder={`Search for ${
                viewState.currentView === MODAL_VIEWS_ENUM.PLAYERS
                  ? 'a player'
                  : 'an attribute'
              }...`}
              style={{ flex: 1 }}
              value={viewState.pageSearch}
            />

            {viewState.currentView === MODAL_VIEWS_ENUM.PLAYERS ? (
              <PaginationAccordionList
                accordionProps={{ multiple: false }}
                emptyMessage="No players found"
                items={regScoutReportEvaluations
                  .filter(
                    (e) =>
                      (!viewState.pageSearch ||
                        `${e.person.firstName}${e.person.lastName}`
                          .replace(' ', '')
                          .toLowerCase()
                          .includes(
                            viewState.pageSearch.replace(' ', '').toLowerCase()
                          )) &&
                      (!viewState.hideCompleted ||
                        !isEvaluationAttributesComplete(e, attributeQuestions))
                  )
                  .sort(
                    (a, b) =>
                      `${a.person.firstName}${a.person.lastName}`.localeCompare(
                        `${b.person.firstName}${b.person.lastName}`
                      ) ||
                      new Date(a.person.createdAt) -
                        new Date(b.person.createdAt)
                  )
                  .map((evaluation) => {
                    const isCompleted = isEvaluationAttributesComplete(
                      evaluation,
                      attributeQuestions
                    );

                    return (
                      <Accordion.Item
                        key={evaluation.key}
                        value={evaluation.key.toString()}
                      >
                        <Accordion.Control>
                          <AppStack style={{ gap: 0 }}>
                            <AppFlexbox
                              style={{ alignItems: 'center', gap: 10 }}
                            >
                              {isCompleted ? (
                                <CircleCheck color="#40C057" size={25} />
                              ) : (
                                <AppStack
                                  style={{
                                    width: 25,
                                    height: 25,
                                    justifyContent: 'center',
                                    placeItems: 'center'
                                  }}
                                >
                                  <AppStack
                                    style={{
                                      backgroundColor: 'lightgrey',
                                      borderRadius: 15,
                                      height: 15,
                                      width: 15
                                    }}
                                  />
                                </AppStack>
                              )}
                              <AppText weight={500}>
                                {evaluation.person.firstName}{' '}
                                {evaluation.person.lastName}
                              </AppText>
                            </AppFlexbox>
                          </AppStack>
                        </Accordion.Control>
                        <Accordion.Panel>
                          <AppStack style={{ gap: 10 }}>
                            <TextInput
                              onChange={(e) => {
                                const { value } = e.currentTarget;
                                if (Number(value)) {
                                  onUpdateEvaluation({
                                    ...evaluation,
                                    jerseyNumber: value
                                  });
                                }
                              }}
                              style={{ flex: 1 }}
                              value={evaluation.jerseyNumber || ''}
                            />

                            <SelectCreatable
                              clearable
                              defaultData={[
                                'Red',
                                'Orange',
                                'Yellow',
                                'Green',
                                'Blue',
                                'Pink',
                                'Purple'
                              ]}
                              onChange={(value) =>
                                onUpdateEvaluation({
                                  ...evaluation,
                                  jerseyColor: value
                                })
                              }
                              placeholder="Select a color"
                              value={evaluation.jerseyColor || ''}
                            />
                            {attributeQuestions.map((question) => {
                              const evaluationAnswer = evaluation.regScoutReportQuestionAnswers.find(
                                (a) =>
                                  a.fkRegScoutReportQuestion ===
                                  question.pkRegScoutReportQuestion
                              );

                              return (
                                <AppStack
                                  key={question.pkRegScoutReportQuestion}
                                >
                                  {question.fkRegFormControlType ===
                                    REG_FORM_CONTROL_TYPE_ENUM.DROP_DOWN ||
                                  question.fkRegFormControlType ===
                                    REG_FORM_CONTROL_TYPE_ENUM.BUTTON_GROUP ? (
                                    <Select
                                      clearable
                                      data={question.regScoutReportQuestionOptions
                                        .sort((a, b) => a.sort - b.sort)
                                        .map((op) => ({
                                          value: op.pkRegScoutReportQuestionOption.toString(),
                                          label: op.value
                                        }))}
                                      onChange={(value) =>
                                        onUpdateEvaluationAnswer(
                                          evaluation,
                                          question,
                                          question.regScoutReportQuestionOptions.find(
                                            (f) =>
                                              f.pkRegScoutReportQuestionOption.toString() ===
                                              value
                                          )?.value || null
                                        )
                                      }
                                      searchable
                                      style={{ flex: 1 }}
                                      value={
                                        question.regScoutReportQuestionOptions
                                          .find(
                                            (f) =>
                                              f.value.trim().toLowerCase() ===
                                              evaluationAnswer?.value
                                                ?.trim()
                                                .toLowerCase()
                                          )
                                          ?.pkRegScoutReportQuestionOption.toString() ||
                                        ''
                                      }
                                    />
                                  ) : (
                                    <TextInput
                                      onChange={(e) => {
                                        if (
                                          !e.currentTarget.value ||
                                          SLIDER_VALUES.some((v) =>
                                            v.value
                                              .toString()
                                              .includes(e.currentTarget.value)
                                          )
                                        ) {
                                          onUpdateEvaluationAnswer(
                                            evaluation,
                                            question,
                                            e.currentTarget.value
                                          );
                                        }
                                      }}
                                      style={{ flex: 1 }}
                                      value={evaluationAnswer?.value || ''}
                                    />
                                  )}
                                </AppStack>
                              );
                            })}
                          </AppStack>
                        </Accordion.Panel>
                      </Accordion.Item>
                    );
                  })}
                itemsPerPage={6}
                onPageChange={(pageIndex) =>
                  setViewState({
                    ...viewState,
                    pageIndex
                  })
                }
                pageIndex={viewState.pageIndex}
              />
            ) : (
              <PaginationAccordionList
                accordionProps={{ multiple: false }}
                emptyMessage="No attributes found"
                items={[
                  ...STATIC_ATTRIBUTES.filter(
                    (a) =>
                      (!viewState.pageSearch ||
                        a.label
                          .replace(' ', '')
                          .toLowerCase()
                          .includes(
                            viewState.pageSearch.replace(' ', '').toLowerCase()
                          )) &&
                      (!viewState.hideCompleted ||
                        !regScoutReportEvaluations.every((e) => e[a.value]))
                  ).map((attribute) => (
                    <EvaluationSessionAttributesAccordionAssign
                      key={attribute.value}
                      assignValue={viewState.attributeAnswer}
                      createableInput
                      fkRegFormControlType={attribute.fkRegFormControlType}
                      getEvaluationAnswer={(e) => e[attribute.value]}
                      isCompleted={regScoutReportEvaluations.every(
                        (e) => e[attribute.value]
                      )}
                      label={attribute.label}
                      onAssign={(evaluation, value) =>
                        onUpdateEvaluation({
                          ...evaluation,
                          [attribute.value]: value
                        })
                      }
                      onAssignAll={(evaluations, value) =>
                        setViewState({
                          ...viewState,
                          assignAllState: {
                            showConfirmation: true,
                            value,
                            onConfirm: () =>
                              onUpdateEvaluations(
                                evaluations.map((e) => ({
                                  ...e,
                                  [attribute.value]: value
                                }))
                              )
                          }
                        })
                      }
                      onAssignValueChange={(value) =>
                        setViewState({
                          ...viewState,
                          attributeAnswer: REG_FORM_CONTROL_TYPE_ENUM.TEXT_INPUT
                            ? value
                            : attribute.options.find((v) => v.value === value)
                                ?.value
                        })
                      }
                      onPageChange={(pageInnerIndex) =>
                        setViewState({
                          ...viewState,
                          pageInnerIndex
                        })
                      }
                      pageIndex={viewState.pageInnerIndex}
                      regScoutReportEvaluations={regScoutReportEvaluations
                        .filter(
                          (e) =>
                            (!viewState.hideAssignedPlayers ||
                              !e[attribute.value]) &&
                            (!viewState.hideMatchingAnswerPlayers ||
                              (e[attribute.value] || null) !==
                                (viewState.attributeAnswer || null))
                        )
                        .sort(
                          (a, b) =>
                            `${a.person.firstName}${a.person.lastName}`.localeCompare(
                              `${b.person.firstName}${b.person.lastName}`
                            ) ||
                            new Date(a.person.createdAt) -
                              new Date(b.person.createdAt)
                        )}
                      regScoutReportQuestionOptions={attribute.options}
                      value={attribute.value}
                    />
                  )),
                  ...attributeQuestions
                    .filter(
                      (q) =>
                        (!viewState.pageSearch ||
                          q.value
                            .replace(' ', '')
                            .toLowerCase()
                            .includes(
                              viewState.pageSearch
                                .replace(' ', '')
                                .toLowerCase()
                            )) &&
                        (!viewState.hideCompleted ||
                          !isQuestionComplete(q, regScoutReportEvaluations))
                    )
                    .sort((a, b) => a.sort - b.sort)
                    .map((question) => (
                      <EvaluationSessionAttributesAccordionAssign
                        key={question.pkRegScoutReportQuestion}
                        assignValue={viewState.attributeAnswer}
                        fkRegFormControlType={question.fkRegFormControlType}
                        getEvaluationAnswer={(e) =>
                          e.regScoutReportQuestionAnswers.find(
                            (a) =>
                              a.fkRegScoutReportQuestion ===
                              question.pkRegScoutReportQuestion
                          )?.value
                        }
                        isCompleted={isQuestionComplete(
                          question,
                          regScoutReportEvaluations
                        )}
                        label={question.value}
                        onAssign={(evaluation, value) =>
                          onUpdateEvaluationAnswer(evaluation, question, value)
                        }
                        onAssignAll={(evaluations, value) =>
                          setViewState({
                            ...viewState,
                            assignAllState: {
                              showConfirmation: true,
                              value,
                              onConfirm: () =>
                                onUpdateEvaluationAnswers(
                                  evaluations,
                                  question,
                                  value
                                )
                            }
                          })
                        }
                        onAssignValueChange={(value) =>
                          setViewState({
                            ...viewState,
                            attributeAnswer:
                              question.fkRegFormControlType ===
                              REG_FORM_CONTROL_TYPE_ENUM.TEXT_INPUT
                                ? value
                                : question.regScoutReportQuestionOptions.find(
                                    (f) =>
                                      f.pkRegScoutReportQuestionOption.toString() ===
                                      value
                                  )?.value
                          })
                        }
                        onPageChange={(pageInnerIndex) =>
                          setViewState({
                            ...viewState,
                            pageInnerIndex
                          })
                        }
                        pageIndex={viewState.pageInnerIndex}
                        regScoutReportEvaluations={regScoutReportEvaluations
                          .filter(
                            (e) =>
                              (!viewState.hideAssignedPlayers ||
                                !e.regScoutReportQuestionAnswers.find(
                                  (a) =>
                                    a.fkRegScoutReportQuestion ===
                                    question.pkRegScoutReportQuestion
                                )?.value) &&
                              (!viewState.hideMatchingAnswerPlayers ||
                                (e.regScoutReportQuestionAnswers.find(
                                  (a) =>
                                    a.fkRegScoutReportQuestion ===
                                    question.pkRegScoutReportQuestion
                                )?.value || null) !==
                                  (viewState.attributeAnswer || null))
                          )
                          .sort(
                            (a, b) =>
                              `${a.person.firstName}${a.person.lastName}`.localeCompare(
                                `${b.person.firstName}${b.person.lastName}`
                              ) ||
                              new Date(a.person.createdAt) -
                                new Date(b.person.createdAt)
                          )}
                        regScoutReportQuestionOptions={
                          question.regScoutReportQuestionOptions
                        }
                        value={question.pkRegScoutReportQuestion.toString()}
                      />
                    ))
                ]}
                itemsPerPage={6}
                onChange={(value) =>
                  setViewState({
                    ...viewState,
                    attributeAnswer: null,
                    selectedAttribute: value
                  })
                }
                onPageChange={(pageIndex) =>
                  setViewState({
                    ...viewState,
                    pageIndex
                  })
                }
                pageIndex={viewState.pageIndex}
                value={viewState.selectedAttribute}
              />
            )}
          </AppStack>
        </AppStack>
      </FormSection>

      <ConfirmModal
        confirmActionColor={!viewState.assignAllState.value ? 'red' : 'blue'}
        isOpen={viewState.assignAllState.showConfirmation}
        message={
          <AppText style={{ textAlign: 'center' }}>
            Are you sure you want to assign all players to{' '}
            <b>'{viewState.assignAllState?.value || 'Not Assigned'}'</b>
          </AppText>
        }
        onCancel={() => {
          setViewState({
            ...viewState,
            assignAllState: {
              ...viewState.assignAllState,
              showConfirmation: false
            }
          });
        }}
        onConfirm={() => {
          viewState.assignAllState.onConfirm();
          setViewState({
            ...viewState,
            assignAllState: {
              ...viewState.assignAllState,
              showConfirmation: false
            }
          });
        }}
        title="Confirm assignment"
      />
    </ResponsiveModal>
  );
};

EvaluationSessionAttributesModal.propTypes = {
  attributeQuestions: PropTypes.array,
  hasUnsavedChanges: PropTypes.bool,
  isOpen: PropTypes.bool,
  isSaving: PropTypes.bool,
  onClose: PropTypes.func,
  onSaveChanges: PropTypes.func,
  onUpdateEvaluation: PropTypes.func,
  onUpdateEvaluationAnswer: PropTypes.func,
  onUpdateEvaluationAnswers: PropTypes.func,
  onUpdateEvaluations: PropTypes.func,
  regScoutReportEvaluations: PropTypes.array
};

export default EvaluationSessionAttributesModal;
