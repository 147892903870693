import React from 'react';
import { PropTypes } from 'prop-types';
import { Button, Checkbox } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import { getInelligibleRegPersonReason } from '../../../helpers/registrationHelper';

const RegisterRegistrantListItem = ({
  regPerson,
  regAssociationDivisionForm,
  externalInvite,
  onEditRegistrant,
  onSelect,
  isSelected,
  isSingleSelect,
  selectDisabled
}) => {
  const { hovered, ref } = useHover();
  const inElligibleReason = getInelligibleRegPersonReason(
    regPerson,
    regAssociationDivisionForm,
    externalInvite
  );

  return (
    <AppFlexbox
      key={regPerson.pkRegPerson}
      ref={ref}
      onClick={() => {
        if (onSelect) {
          onSelect(regPerson);
        }
      }}
      style={{
        gap: 40,
        padding: 20,
        cursor:
          !isSelected && (selectDisabled || !onSelect) ? 'unset' : 'pointer',
        flexWrap: 'nowrap',
        outline:
          !isSelected && (selectDisabled || !onSelect)
            ? 'solid 1px lightgrey'
            : isSelected || (isSingleSelect && hovered)
            ? 'solid 2px #1C7ED6'
            : hovered
            ? 'solid 2px lightgrey'
            : 'solid 1px lightgrey'
      }}
    >
      <AppStack style={{ flex: 1 }}>
        <AppFlexbox
          style={{
            flex: 1,
            alignItems: 'center'
          }}
        >
          <AppFlexbox style={{ flex: 1, alignItems: 'center' }}>
            {onSelect && !isSingleSelect && (
              <Checkbox
                checked={isSelected}
                disabled={!isSelected && selectDisabled}
                onChange={() => {}}
              />
            )}

            <AppStack style={{ gap: 0, flex: 1 }}>
              <AppText style={{ fontSize: 18 }} weight={700}>
                {regPerson.firstName} {regPerson.lastName}
              </AppText>
              <AppText style={{ fontSize: 16 }}>{regPerson.address1}</AppText>
              <AppText style={{ fontSize: 16 }}>
                {regPerson.city}, {regPerson.province} {regPerson.postalCode}
              </AppText>
              <AppText style={{ fontSize: 16 }}>{regPerson.phone}</AppText>
            </AppStack>
          </AppFlexbox>

          <Button
            color="blue"
            onClick={(e) => {
              e.stopPropagation();
              onEditRegistrant(regPerson);
            }}
          >
            Edit
          </Button>
        </AppFlexbox>

        {inElligibleReason && (
          <AppStack style={{ flex: 1, alignItems: 'center' }}>
            <AppText color="red">{inElligibleReason}</AppText>
          </AppStack>
        )}
      </AppStack>
    </AppFlexbox>
  );
};

RegisterRegistrantListItem.propTypes = {
  externalInvite: PropTypes.object,
  isSelected: PropTypes.bool,
  isSingleSelect: PropTypes.bool,
  onEditRegistrant: PropTypes.func,
  onSelect: PropTypes.func,
  regAssociationDivisionForm: PropTypes.object,
  regPerson: PropTypes.object,
  selectDisabled: PropTypes.bool
};

export default RegisterRegistrantListItem;
