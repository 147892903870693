import React from 'react';
import { PropTypes } from 'prop-types';
import { Avatar, Badge } from '@mantine/core';
import dayjs from 'dayjs';
import { formatUtcDate, getInitialsFromName } from '../../../helpers/format';
import ActionableListItem from '../../common/ActionableListItem';
import AppText from '../../common/AppText';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../../helpers/styles';

const RegistrantWaitlistItem = ({ waitlistPerson, onAction, isAdmin }) => {
  const mqIndex = useMediaQueryIndex();
  const activeReservation = waitlistPerson?.waitlistReservations
    .filter((r) => !r.deleted)
    .sort((a, b) => new Date(a.expiryDate) - new Date(b.expiryDate))[0];

  const expiryDate = activeReservation
    ? formatUtcDate(activeReservation.expiryDate)
    : null;
  const hasExpired = activeReservation
    ? activeReservation.deleted || new Date() >= new Date(expiryDate)
    : false;
  let expiryTimeHours = activeReservation
    ? dayjs(expiryDate).diff(dayjs(new Date()), 'hour')
    : 0;
  const expiryTimeMinutes = activeReservation
    ? Math.ceil(dayjs(expiryDate).diff(dayjs(new Date()), 'minute', true))
    : 0;
  const expiryTimeDays = Math.ceil(expiryTimeHours / 24);
  expiryTimeHours -= Math.floor(expiryTimeHours / 24) * 24;

  const adminActions = isAdmin
    ? [
        { label: 'View', value: 'VIEW' },
        { label: 'Email User', value: 'EMAIL' },
        { label: 'Email Waitlist', value: 'BULK_EMAIL' }
      ]
    : [];

  const activeAdminActions = isAdmin
    ? [
        {
          label: activeReservation ? 'Resend Reservation' : 'Send Reservation',
          value: 'APPROVE'
        }
      ]
    : [];

  const userActions =
    !isAdmin && activeReservation && !hasExpired
      ? [{ label: 'Claim Reservation', value: 'CLAIM' }]
      : [];

  const badgeContent = !activeReservation
    ? 'Waiting'
    : hasExpired
    ? 'Expired'
    : `Expires in ${
        expiryTimeDays > 1
          ? `${expiryTimeDays} ${expiryTimeDays === 1 ? 'day' : 'days'}`
          : expiryTimeHours > 0
          ? `${expiryTimeHours} ${expiryTimeHours === 1 ? 'hour' : 'hours'}`
          : expiryTimeMinutes > 0 &&
            `${expiryTimeMinutes} ${
              expiryTimeMinutes === 1 ? 'minute' : 'minutes'
            }`
      }`;

  return waitlistPerson ? (
    <ActionableListItem
      alwaysShowBadge
      badge={
        <Badge
          color={!activeReservation ? 'blue' : hasExpired ? 'red' : 'green'}
          size="lg"
          style={{
            width: rs(['100%', 185], mqIndex),
            textAlign: 'center'
          }}
          variant="filled"
        >
          {badgeContent}
        </Badge>
      }
      description={
        waitlistPerson.regAssociationDivisionWaitlist.regAssociationDivision
          .name
      }
      extraLabels={[
        isAdmin
          ? waitlistPerson.regPerson.user.email
          : `Waitlist Order #${waitlistPerson.order}`
      ]}
      indicator={
        mqIndex > 0 ? (
          <Avatar
            radius="xl"
            style={{
              backgroundColor: '#ced4da',
              alignSelf: 'start',
              margin: rs([0, 0, 'auto 0px'], mqIndex),
              display: rs(['none', 'flex'], mqIndex)
            }}
          >
            <AppText style={{ color: '#333' }}>
              {getInitialsFromName(
                `${waitlistPerson.regPerson.firstName} ${waitlistPerson.regPerson.lastName}`
              )}
            </AppText>
          </Avatar>
        ) : null
      }
      label={`${waitlistPerson.regPerson.firstName} ${waitlistPerson.regPerson.lastName}`}
      menuActions={
        onAction
          ? [
              ...adminActions,
              ...activeAdminActions,
              ...userActions,
              {
                label: 'Remove',
                value: 'REMOVE'
              }
            ]
          : null
      }
      onAction={(action) => {
        if (action === 'CLAIM') {
          onAction(action, activeReservation.uuid);
        }
        else {
          onAction(action);
        }
      }}
      showMenuSpacer
      subDescription={waitlistPerson.regAssociationDivisionForm.regForm.name}
      subLabel={dayjs(formatUtcDate(waitlistPerson.regPerson.dob)).format(
        'MM/DD/YYYY'
      )}
    />
  ) : (
    <ActionableListItem isLoading showIndicator />
  );
};

RegistrantWaitlistItem.propTypes = {
  isAdmin: PropTypes.bool,
  onAction: PropTypes.func,
  waitlistPerson: PropTypes.object
};

export default RegistrantWaitlistItem;
