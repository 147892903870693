import React, { useContext, useEffect, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Alert, Button } from '@mantine/core';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import CustomSelect from '../../common/CustomSelect';
import SpecialRequestListitem from './SpecialRequestListItem';
import { triggerNotification } from '../../../helpers/notificationHelper';
import FormSection from '../../common/FormSection';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import AppTitle from '../../common/AppTitle';
import PaginationList from '../../common/PaginationList';

const SpecialRequestsModalSection = ({
  isHidden,
  pkRegAssociationDivision,
  divisionRegistrants,
  onCancel
}) => {
  const initializedPkRegAssociationDivision = useRef(false);
  const { state, updateAdminRegSpecialRequests } = useContext(
    RegistrationAdminContext
  );
  const [formState, setFormState] = useState({
    regSpecialRequests: [],
    pageIndex: 1,
    showCreate: false,
    newSpecialRequest: {
      regFormSubmission1: null,
      regFormSubmission2: null,
      fkRegSpecialRequestType: 1
    },
    hasChanges: false,
    loading: false
  });
  const divisionRegistrantOptions =
    divisionRegistrants
      ?.sort((a, b) =>
        `${a.regPerson.firstName}${a.regPerson.lastName}`.localeCompare(
          `${b.regPerson.firstName}${b.regPerson.lastName}`
        )
      )
      .map((a) => ({
        value: a.pkRegFormSubmission.toString(),
        label: `${a.regPerson.firstName} ${a.regPerson.lastName}`,
        description: `${a.regPerson.address1}, ${a.regPerson.city}, ${a.regPerson.province}`
      })) || [];

  const divisionSpecialRequests = state.regSpecialRequests.value.filter(
    (s) =>
      s.regFormSubmission1.regAssociationDivisionOverride?.pkRegAssociationDivision.toString() ===
        pkRegAssociationDivision?.toString() ||
      (!s.regFormSubmission1.regAssociationDivisionOverride &&
        s.regFormSubmission1.regAssociationDivisionForm.regAssociationDivision.pkRegAssociationDivision.toString() ===
          pkRegAssociationDivision?.toString())
  );

  useEffect(() => {
    setFormState({
      showCreate: false,
      regSpecialRequests: divisionSpecialRequests,
      newSpecialRequest: {
        regFormSubmission1: null,
        regFormSubmission2: null,
        fkRegSpecialRequestType: 1
      },
      hasChanges: false,
      loading: false
    });
    initializedPkRegAssociationDivision.current = pkRegAssociationDivision;
  }, [pkRegAssociationDivision, state.regSpecialRequests.value]);

  return !isHidden ? (
    formState.showCreate ? (
      <FormSection
        isLoading={formState.loading}
        onCancel={() => setFormState({ ...formState, showCreate: false })}
        onSubmit={() => {
          setFormState({
            ...formState,
            regSpecialRequests: [
              ...formState.regSpecialRequests,
              {
                ...formState.newSpecialRequest,
                pkRegSpecialRequest: `temp-request-${new Date().getTime()}`
              }
            ],
            newSpecialRequest: {
              regFormSubmission1: null,
              regFormSubmission2: null,
              fkRegSpecialRequestType: 1
            },
            hasChanges: true,
            showCreate: false
          });
        }}
        style={{ gap: 30 }}
        submitTitle="Add Request"
      >
        <Alert color="blue" variant="outline">
          <AppText style={{ textAlign: 'center' }}>
            Select two players that have requested to play together.
          </AppText>
        </Alert>
        <AppStack style={{ gap: 5 }}>
          <CustomSelect
            data={divisionRegistrantOptions.filter(
              (r) =>
                r.value !==
                formState.newSpecialRequest.regFormSubmission2?.pkRegFormSubmission.toString()
            )}
            disabled={
              state.regAssociationDivisionRegistrants.loading ||
              formState.loading
            }
            label="Player One"
            onChange={(value) =>
              setFormState({
                ...formState,
                newSpecialRequest: {
                  ...formState.newSpecialRequest,
                  regFormSubmission1: divisionRegistrants.find(
                    (a) => a.pkRegFormSubmission.toString() === value
                  )
                }
              })
            }
            placeholder="Select registrant"
            required
            style={{ flex: 1 }}
            value={
              formState.newSpecialRequest.regFormSubmission1?.pkRegFormSubmission.toString() ??
              ''
            }
          />
          <AppText
            style={{ textAlign: 'center', marginTop: 10, fontSize: 14 }}
            weight={500}
          >
            Has requested to play with;
          </AppText>
          <CustomSelect
            data={divisionRegistrantOptions.filter(
              (r) =>
                r.value !==
                formState.newSpecialRequest.regFormSubmission1?.pkRegFormSubmission.toString()
            )}
            disabled={
              state.regAssociationDivisionRegistrants.loading ||
              formState.loading
            }
            label="Player Two"
            onChange={(value) =>
              setFormState({
                ...formState,
                newSpecialRequest: {
                  ...formState.newSpecialRequest,
                  regFormSubmission2: divisionRegistrants.find(
                    (a) => a.pkRegFormSubmission.toString() === value
                  )
                }
              })
            }
            placeholder="Select registrant"
            required
            style={{ flex: 1 }}
            value={
              formState.newSpecialRequest.regFormSubmission2?.pkRegFormSubmission.toString() ??
              ''
            }
          />
        </AppStack>
      </FormSection>
    ) : (
      <FormSection
        cancelTitle="Back"
        isLoading={formState.loading}
        isSubmitDisabled={!formState.hasChanges}
        onCancel={onCancel}
        onSubmit={() => {
          setFormState({
            ...formState,
            loading: true
          });
          updateAdminRegSpecialRequests(
            pkRegAssociationDivision,
            {
              regSpecialRequests: formState.regSpecialRequests.map((r) => ({
                pkRegSpecialRequest:
                  typeof r.pkRegSpecialRequest === 'string'
                    ? null
                    : r.pkRegSpecialRequest,
                fkRegSpecialRequestType: r.fkRegSpecialRequestType,
                fkRegFormSubmission1: r.regFormSubmission1.pkRegFormSubmission,
                fkRegFormSubmission2: r.regFormSubmission2.pkRegFormSubmission
              }))
            },
            () => {
              setFormState({
                ...formState,
                loading: false,
                hasChanges: false
              });
              triggerNotification('Changes Saved!', 'Success', 'green');
            },
            (e) => {
              triggerNotification(e);
              setFormState({
                ...formState,
                loading: false
              });
            }
          );
        }}
        submitTitle="Save Changes"
      >
        <AppStack style={{ gap: 20 }}>
          <AppFlexbox
            style={{ justifyContent: 'space-between', placeItems: 'center' }}
          >
            <AppTitle order={4}>Special Requests</AppTitle>
            <Button
              disabled={formState.loading}
              onClick={() => setFormState({ ...formState, showCreate: true })}
              type="button"
            >
              Create
            </Button>
          </AppFlexbox>

          <PaginationList
            emptyMessage="No Special Requests"
            isLoading={
              initializedPkRegAssociationDivision.current !==
              pkRegAssociationDivision
            }
            items={formState.regSpecialRequests
              .sort((a, b) =>
                `${a.regFormSubmission1.regPerson.firstName}${a.regFormSubmission1.regPerson.lastName}`.localeCompare(
                  `${b.regFormSubmission1.regPerson.firstName}${b.regFormSubmission1.regPerson.lastName}`
                )
              )
              .map((s) => (
                <SpecialRequestListitem
                  key={s.pkRegSpecialRequest}
                  isDisabled={formState.loading}
                  onRemove={() =>
                    setFormState({
                      ...formState,
                      hasChanges: true,
                      regSpecialRequests: formState.regSpecialRequests.filter(
                        (r) => r.pkRegSpecialRequest !== s.pkRegSpecialRequest
                      )
                    })
                  }
                  regSpecialRequest={s}
                />
              ))}
            itemsPerPage={4}
            LoadingComponent={SpecialRequestListitem}
            onPageChange={(pageIndex) =>
              setFormState({
                ...formState,
                pageIndex
              })
            }
            pageIndex={formState.pageIndex}
          />
        </AppStack>
      </FormSection>
    )
  ) : null;
};

SpecialRequestsModalSection.propTypes = {
  divisionRegistrants: PropTypes.array,
  isHidden: PropTypes.bool,
  onCancel: PropTypes.func,
  pkRegAssociationDivision: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

export default SpecialRequestsModalSection;
