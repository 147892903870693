import {
  Alert,
  Button,
  Checkbox,
  List,
  NumberInput,
  Radio,
  Textarea,
  TextInput
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import {
  AlertCircle,
  Calendar,
  InfoCircle,
  Pencil,
  Plus,
  X
} from 'tabler-icons-react';
import NumberFormat from 'react-number-format';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { triggerNotification } from '../../../helpers/notificationHelper';
import { formatUtcDate } from '../../../helpers/format';
import {
  CURRENCY_TYPE_LIST,
  REG_GENDER_TYPES
} from '../../../config/constants';
import ResponsiveModal from '../../common/ResponsiveModal';
import FormSection from '../../common/FormSection';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import AppTitle from '../../common/AppTitle';
import ActionableIcon from '../../common/ActionableIcon';
import AppRadioGroup from '../../common/AppRadioGroup';
import DeleteItemView from '../../common/DeleteItemView';
import LeagueSeasonEndedNotification from './LeagueSeasonEndedNotification';

const LeagueDivisionModal = ({
  isOpen,
  onClose,
  regAssociation,
  regAssociationDivision,
  regAssociationSeason,
  showDelete,
  createCopy
}) => {
  const mqIndex = useMediaQueryIndex();
  const {
    state,
    fetchUpdatedAdminRegAssociationDivision,
    createAdminRegAssociationDivision,
    updateAdminRegAssociationDivision,
    deleteAdminRegAssociationDivision
  } = useContext(RegistrationAdminContext);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [formState, setFormState] = useState({
    name: '',
    minDob: null,
    maxDob: null,
    registrantLimit: null,
    fkRegAssociationSeason: null,
    fkRegGenderType: null,
    assignRoles: false,
    waitlistEnabled: false,
    waitlistExpireDays: 3,
    roles: [],
    overlappingDivisions: [],
    offlinePaymentsEnabled: false,
    orderEmailsEnabled: false,
    orderEmails: [],
    isInviteOnly: false,
    isAllAges: false,
    errors: {},
    error: '',
    notifyWaitlist: true,
    expireWaitlistReservations: true,
    notifyWaitlistAmount: 1,
    loading: false
  });
  const regSeason =
    regAssociationDivision?.regAssociationSeason || regAssociationSeason;

  const isSeasonUnavailable =
    !regSeason || new Date(formatUtcDate(regSeason.endDate)) <= new Date();

  let maxWaitlistNotifyAmount = 1;
  if (regAssociationDivision) {
    const spotsOpen =
      formState.registrantLimit -
      regAssociationDivision.regFormSubmissionCount -
      regAssociationDivision.regWaitlistReservationSentCount;

    const peopleWithoutReservations =
      regAssociationDivision.regWaitlistPersonCount -
      regAssociationDivision.regWaitlistReservationSentCount;

    maxWaitlistNotifyAmount =
      spotsOpen > peopleWithoutReservations
        ? peopleWithoutReservations
        : spotsOpen;
  }

  const adminRegAssociation = state.regAssociations.value.find(
    (a) =>
      a.pkRegAssociation.toString() ===
      regAssociation?.pkRegAssociation.toString()
  );
  const currency = CURRENCY_TYPE_LIST.find(
    (c) =>
      c.value.toString() ===
      adminRegAssociation?.regAssociationPaymentProvider?.fkCurrencyType?.toString()
  )?.abbr;

  useEffect(() => {
    if (isOpen) {
      setShowDeleteConfirmation(false);
      if (regAssociationDivision) {
        const minDate = regAssociationDivision.isAllAges
          ? null
          : new Date(formatUtcDate(regAssociationDivision.minDob));
        const maxDate = regAssociationDivision.isAllAges
          ? null
          : new Date(formatUtcDate(regAssociationDivision.maxDob));
        setFormState({
          name: createCopy
            ? `${regAssociationDivision.name} - Copy`
            : regAssociationDivision.name,
          minDob: minDate,
          maxDob: maxDate,
          registrantLimit: regAssociationDivision.registrantLimit,
          fkRegAssociationSeason: regSeason?.pkRegAssociationSeason,
          fkRegGenderType:
            regAssociationDivision.fkRegGenderType?.toString() ??
            REG_GENDER_TYPES[0].value,
          assignRoles: regAssociationDivision.roles.length > 0,
          rolesRequired: regAssociationDivision.rolesRequired,
          waitlistEnabled: regAssociationDivision.waitlistEnabled,
          waitlistExpireDays: regAssociationDivision.waitlistExpireDays ?? 3,
          roles:
            regAssociationDivision.roles.length > 0
              ? regAssociationDivision.roles
              : [
                  {
                    pkRegAssociationDivisionRole: `temp-role-${new Date().getTime()}`,
                    name: 'Player',
                    maxCount: 99,
                    price: null,
                    positionOrder: 0
                  }
                ],
          offlinePaymentsEnabled: regAssociationDivision.offlinePaymentsEnabled,
          orderEmailsEnabled: regAssociationDivision.orderEmails?.length > 0,
          orderEmails: regAssociationDivision.orderEmails ?? [],
          isInviteOnly: regAssociationDivision.isInviteOnly,
          isAllAges: regAssociationDivision.isAllAges,
          // eslint-disable-next-line no-use-before-define
          overlappingDivisions: findOverlappingDivisions(minDate, maxDate),
          notifyWaitlist: true,
          expireWaitlistReservations: true,
          notifyWaitlistAmount: 1,
          errors: {},
          error: '',
          loading: false
        });
      }
      else {
        setFormState({
          name: '',
          minDob: null,
          maxDob: null,
          registrantLimit: null,
          fkRegAssociationSeason: regSeason?.pkRegAssociationSeason,
          fkRegGenderType: REG_GENDER_TYPES[0].value,
          overlappingDivisions: [],
          assignRoles: false,
          rolesRequired: false,
          waitlistEnabled: false,
          waitlistExpireDays: 3,
          roles: [
            {
              pkRegAssociationDivisionRole: `temp-role-${new Date().getTime()}`,
              name: 'Player',
              maxCount: null,
              positionOrder: 0
            }
          ],
          offlinePaymentsEnabled: false,
          orderEmailsEnabled: false,
          orderEmails: [],
          isInviteOnly: false,
          isAllAges: false,
          notifyWaitlist: true,
          expireWaitlistReservations: true,
          notifyWaitlistAmount: 1,
          errors: {},
          error: '',
          loading: false
        });
      }
    }
  }, [isOpen]);

  useEffect(() => {
    setFormState({
      ...formState,
      errors: {},
      error: ''
    });
  }, [showDeleteConfirmation]);

  const onError = (error, type) => {
    if (type === 'reservations') {
      fetchUpdatedAdminRegAssociationDivision(
        regAssociationDivision.pkRegAssociationDivision
      );
      onClose();
    }

    triggerNotification(error);
    setFormState({
      ...formState,
      loading: false
    });
  };

  const findOverlappingDivisions = (minDate, maxDate) => {
    if (!minDate || !maxDate) {
      return [];
    }

    return state.regAssociationDivisions.value.filter(
      (d) =>
        d.fkRegAssociationSeason === regSeason.pkRegAssociationSeason &&
        d.pkRegAssociationDivision !==
          regAssociationDivision?.pkRegAssociationDivision &&
        minDate <= new Date(new Date(formatUtcDate(d.maxDob)).toDateString()) &&
        maxDate >= new Date(new Date(formatUtcDate(d.minDob)).toDateString())
    );
  };

  return (
    <ResponsiveModal
      onClose={onClose}
      opened={isOpen}
      size={600}
      title={
        <AppTitle order={2}>
          {!regAssociationDivision
            ? 'Create New Division'
            : createCopy
            ? 'Duplicate Division'
            : showDelete || showDeleteConfirmation
            ? 'Delete Division'
            : 'Edit Division'}
        </AppTitle>
      }
    >
      {isSeasonUnavailable ? (
        <FormSection
          isLoading={formState.loading}
          isSubmitDisabled
          onCancel={onClose}
          onSubmit={() => {}}
          style={{ gap: 30 }}
          submitTitle="Create"
        >
          <LeagueSeasonEndedNotification
            pkRegAssociation={regAssociation?.pkRegAssociation}
            regAssociationSeason={regSeason}
          />
        </FormSection>
      ) : !showDeleteConfirmation && !showDelete ? (
        <FormSection
          isLoading={formState.loading}
          onCancel={onClose}
          onSubmit={() => {
            if (
              !formState.isAllAges &&
              (!formState.minDob ||
                !formState.maxDob ||
                formState.minDob >= formState.maxDob)
            ) {
              const errors = {};
              if (!formState.minDob) {
                errors.minDob = { value: 'Invalid date' };
              }
              if (!formState.maxDob) {
                errors.maxDob = { value: 'Invalid date' };
              }

              setFormState({
                ...formState,
                errors,
                error: 'Invalid date of birth range.'
              });
            }
            else {
              setFormState({
                ...formState,
                errors: {},
                error: '',
                loading: true
              });
              if (regAssociationDivision && !createCopy) {
                updateAdminRegAssociationDivision(
                  regAssociationDivision.pkRegAssociationDivision,
                  {
                    ...formState,
                    minDob: formState.isAllAges ? new Date() : formState.minDob,
                    maxDob: formState.isAllAges ? new Date() : formState.maxDob,
                    orderEmails: formState.orderEmailsEnabled
                      ? formState.orderEmails
                      : [],
                    roles: formState.assignRoles ? formState.roles : []
                  },
                  onClose,
                  onError
                );
              }
              else {
                createAdminRegAssociationDivision(
                  {
                    ...formState,
                    minDob: formState.isAllAges ? new Date() : formState.minDob,
                    maxDob: formState.isAllAges ? new Date() : formState.maxDob,
                    orderEmails: formState.orderEmailsEnabled
                      ? formState.orderEmails
                      : [],
                    roles: formState.assignRoles ? formState.roles : []
                  },
                  onClose,
                  onError
                );
              }
            }
          }}
          style={{ gap: 20, flex: 1 }}
          submitTitle={
            createCopy
              ? 'Duplicate'
              : regAssociationDivision
              ? 'Save Changes'
              : 'Create'
          }
        >
          <TextInput
            disabled={formState.loading}
            label="Division Name"
            name="title"
            onChange={(e) =>
              setFormState({
                ...formState,
                errors: {},
                error: '',
                name: e.currentTarget.value
              })
            }
            placeholder="North Division"
            required
            value={formState.name}
          />
          <Checkbox
            checked={!formState.isAllAges}
            disabled={formState.loading}
            label="Age Restricted"
            onChange={(e) =>
              setFormState({
                ...formState,
                isAllAges: !e.currentTarget.checked
              })
            }
            style={{ marginTop: 5 }}
          />
          {!formState.isAllAges && (
            <AppFlexbox
              style={{ flexDirection: rs(['column', 'row'], mqIndex) }}
            >
              <DatePickerInput
                clearable
                disabled={formState.loading}
                error={formState.errors.minDob?.value}
                label="Minimum Date of Birth"
                leftSection={<Calendar color="#000" size={16} />}
                maxDate={formState.maxDob}
                onChange={(value) =>
                  setFormState({
                    ...formState,
                    errors: {},
                    error: '',
                    minDob: value,
                    overlappingDivisions: findOverlappingDivisions(
                      value,
                      formState.maxDob
                    )
                  })
                }
                placeholder="MM/DD/YYYY"
                required
                style={{ flex: 1, alignSelf: 'stretch' }}
                value={formState.minDob}
                valueFormat="MM/DD/YYYY"
              />
              <DatePickerInput
                clearable
                disabled={formState.loading}
                error={formState.errors.maxDob?.value}
                label="Maximum Date of Birth"
                leftSection={<Calendar color="#000" size={16} />}
                minDate={formState.minDob}
                onChange={(value) =>
                  setFormState({
                    ...formState,
                    errors: {},
                    error: '',
                    maxDob: value,
                    overlappingDivisions: findOverlappingDivisions(
                      formState.minDob,
                      value
                    )
                  })
                }
                placeholder="MM/DD/YYYY"
                required
                style={{ flex: 1, alignSelf: 'stretch' }}
                value={formState.maxDob}
                valueFormat="MM/DD/YYYY"
              />
            </AppFlexbox>
          )}

          <AppFlexbox style={{ flexDirection: 'column', gap: 30 }}>
            <NumberInput
              disabled={formState.loading}
              label="Registration Limit"
              min={0}
              onChange={(value) =>
                setFormState({
                  ...formState,
                  registrantLimit: value || null,
                  waitlistEnabled:
                    value && value >= 0 ? formState.waitlistEnabled : false,
                  errors: {},
                  error: ''
                })
              }
              placeholder="Unlimited"
              radius={0}
              style={{ flex: 1, alignSelf: 'stretch' }}
              value={formState.registrantLimit}
            />
            <Checkbox
              checked={formState.waitlistEnabled}
              disabled={
                formState.loading ||
                formState.isInviteOnly ||
                (!formState.registrantLimit && formState.registrantLimit !== 0)
              }
              label="Enable Waitlist"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  waitlistEnabled: e.currentTarget.checked,
                  errors: {},
                  error: ''
                })
              }
              style={{
                flex: 1,
                justifyContent: 'start',
                alignSelf: 'start'
              }}
              styles={{ label: { fontSize: 14, fontWeight: 500 } }}
            />
          </AppFlexbox>
          {formState.waitlistEnabled && (
            <NumberInput
              disabled={formState.loading}
              label="Waitlist Expire days"
              min={1}
              onChange={(value) =>
                setFormState({
                  ...formState,
                  waitlistExpireDays: value || null,
                  errors: {},
                  error: ''
                })
              }
              radius={0}
              required
              style={{ flex: 1, alignSelf: 'stretch' }}
              value={formState.waitlistExpireDays}
            />
          )}
          <AppRadioGroup
            disabled={formState.loading}
            label="Gender Rule"
            onChange={(value) =>
              setFormState({
                ...formState,
                fkRegGenderType: value
              })
            }
            size="sm"
            spacing="lg"
            style={{
              flex: 1,
              alignSelf: 'stretch'
            }}
            styles={{ label: { fontWeight: 500 } }}
            value={formState.fkRegGenderType}
          >
            {REG_GENDER_TYPES.map((g) => (
              <Radio
                key={g.value}
                disabled={formState.loading}
                label={g.label}
                style={{ width: rs(['100%', 'unset'], mqIndex) }}
                value={g.value}
              />
            ))}
          </AppRadioGroup>

          <AppStack style={{ gap: 10, marginTop: 10 }}>
            <AppFlexbox style={{ alignItems: 'start' }}>
              <AppStack style={{ gap: 0, flex: 1 }}>
                <Checkbox
                  checked={formState.assignRoles}
                  disabled={formState.loading}
                  label="Capture custom roles on forms"
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      assignRoles: e.currentTarget.checked,
                      errors: {},
                      error: ''
                    })
                  }
                  style={{ flex: 1 }}
                  styles={{ label: { fontSize: 14, fontWeight: 500 } }}
                />
                <AppText
                  color="gray"
                  style={{ marginLeft: 32, fontSize: 13 }}
                  weight={200}
                >
                  ex; Goaltender
                </AppText>
              </AppStack>

              {formState.assignRoles && (
                <Button
                  color="blue"
                  disabled={formState.loading}
                  leftSection={<Plus />}
                  onClick={() =>
                    setFormState({
                      ...formState,
                      roles: [
                        ...formState.roles,
                        {
                          pkRegAssociationDivisionRole: `temp-role-${new Date().getTime()}`,
                          name: '',
                          maxCount: null,
                          positionOrder: formState.roles.length
                        }
                      ]
                    })
                  }
                  size="xs"
                >
                  Add Role
                </Button>
              )}
            </AppFlexbox>
            {formState.assignRoles && (
              <>
                <Checkbox
                  checked={formState.rolesRequired}
                  disabled={formState.loading}
                  label="Required Roles"
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      rolesRequired: e.currentTarget.checked,
                      errors: {},
                      error: ''
                    })
                  }
                  style={{ flex: 1 }}
                  styles={{ label: { fontSize: 14, fontWeight: 500 } }}
                />
                <AppStack style={{ gap: 0 }}>
                  <AppFlexbox
                    style={{
                      gap: 0,
                      border: 'solid 1px lightgrey',
                      borderBottom: 'none'
                    }}
                  >
                    <AppFlexbox
                      style={{
                        flex: 1,
                        borderRight: 'solid 1px lightgrey',
                        alignSelf: 'stretch',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <AppText
                        style={{
                          padding: '5px 10px',
                          fontSize: 14
                        }}
                        weight={500}
                      >
                        Role
                      </AppText>
                    </AppFlexbox>

                    <AppFlexbox
                      style={{
                        flex: 1,
                        borderRight: 'solid 1px lightgrey',
                        alignSelf: 'stretch',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <AppText
                        style={{ padding: '5px 10px', fontSize: 14 }}
                        weight={500}
                      >
                        Maximum
                      </AppText>
                    </AppFlexbox>
                    <AppFlexbox
                      style={{
                        flex: 1,
                        alignSelf: 'stretch'
                      }}
                    >
                      <AppText
                        style={{
                          padding: '5px 10px',
                          fontSize: 14,
                          wordBreak: 'normal',
                          alignSelf: 'stretch',
                          display: 'flex',
                          alignItems: 'center'
                        }}
                        weight={500}
                      >
                        Cost Adjustment
                      </AppText>
                    </AppFlexbox>
                  </AppFlexbox>
                  <AppStack
                    style={{
                      gap: 0,
                      border: 'solid 1px lightgrey',
                      borderTop: 'none'
                    }}
                  >
                    {formState.roles
                      .sort((a, b) => a.positionOrder - b.positionOrder)
                      .map((role) => (
                        <AppFlexbox
                          key={role.pkRegAssociationDivisionRole}
                          style={{
                            gap: 0,
                            flex: 1,
                            borderTop: 'solid 1px lightgrey'
                          }}
                        >
                          <TextInput
                            disabled={formState.loading}
                            icon={<Pencil />}
                            onChange={(e) =>
                              setFormState({
                                ...formState,
                                roles: [
                                  ...formState.roles.filter(
                                    (r) =>
                                      r.pkRegAssociationDivisionRole !==
                                      role.pkRegAssociationDivisionRole
                                  ),
                                  { ...role, name: e.currentTarget.value }
                                ],
                                errors: {},
                                error: ''
                              })
                            }
                            placeholder="Player"
                            radius={0}
                            required
                            style={{
                              flex: 1,
                              borderRight: 'solid 1px lightgrey'
                            }}
                            styles={{ input: { border: 'none' } }}
                            value={role.name}
                          />
                          <NumberInput
                            disabled={formState.loading}
                            hideControls
                            icon={<Pencil />}
                            min={0}
                            onChange={(value) =>
                              setFormState({
                                ...formState,
                                roles: [
                                  ...formState.roles.filter(
                                    (r) =>
                                      r.pkRegAssociationDivisionRole !==
                                      role.pkRegAssociationDivisionRole
                                  ),
                                  { ...role, maxCount: value || null }
                                ],
                                errors: {},
                                error: ''
                              })
                            }
                            placeholder="Unlimited"
                            radius={0}
                            style={{
                              flex: 1,
                              borderRight: 'solid 1px lightgrey'
                            }}
                            styles={{ input: { border: 'none' } }}
                            value={role.maxCount}
                          />
                          <NumberFormat
                            customInput={TextInput}
                            decimalScale={2}
                            disabled={formState.loading}
                            fixedDecimalScale
                            icon={<Pencil />}
                            onValueChange={(e) =>
                              setFormState({
                                ...formState,
                                roles: [
                                  ...formState.roles.filter(
                                    (r) =>
                                      r.pkRegAssociationDivisionRole !==
                                      role.pkRegAssociationDivisionRole
                                  ),
                                  { ...role, price: e.floatValue }
                                ],
                                errors: {},
                                error: ''
                              })
                            }
                            placeholder="None"
                            prefix="$"
                            rightSection={
                              formState.roles.length > 1 && (
                                <AppFlexbox style={{ gap: 5 }}>
                                  {currency && (
                                    <AppText
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 500,
                                        color: '#999',
                                        paddingRight: 5
                                      }}
                                    >
                                      {currency}
                                    </AppText>
                                  )}
                                  <ActionableIcon
                                    color="red"
                                    disabled={formState.loading}
                                    onClick={() => {
                                      const optionsCopy = [
                                        ...formState.roles
                                      ].filter(
                                        (f) =>
                                          f.pkRegAssociationDivisionRole !==
                                          role.pkRegAssociationDivisionRole
                                      );
                                      for (const optionCopy of optionsCopy) {
                                        if (
                                          optionCopy.positionOrder >
                                          role.positionOrder
                                        ) {
                                          optionCopy.positionOrder -= 1;
                                        }
                                      }
                                      setFormState({
                                        ...formState,
                                        roles: formState.roles.filter(
                                          (f) =>
                                            f.pkRegAssociationDivisionRole !==
                                            role.pkRegAssociationDivisionRole
                                        )
                                      });
                                    }}
                                  >
                                    <X />
                                  </ActionableIcon>
                                </AppFlexbox>
                              )
                            }
                            style={{ flex: 1 }}
                            styles={{ input: { border: 'none' } }}
                            thousandSeparator
                            value={role.price ?? ''}
                          />
                        </AppFlexbox>
                      ))}
                  </AppStack>
                </AppStack>
              </>
            )}
            <Checkbox
              checked={formState.orderEmailsEnabled}
              disabled={formState.loading}
              label="Enable Order Email"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  orderEmailsEnabled: e.currentTarget.checked,
                  errors: {},
                  error: ''
                })
              }
              style={{ flex: 1, marginTop: 10 }}
              styles={{ label: { fontSize: 14, fontWeight: 500 } }}
            />
            {formState.orderEmailsEnabled && (
              <AppStack>
                <Textarea
                  autosize
                  disabled={formState.loading}
                  label="Order Email"
                  minRows={4}
                  onChange={(e) => {
                    setFormState({
                      ...formState,
                      orderEmails: [e.currentTarget.value.substring(0, 2000)]
                    });
                  }}
                  required
                  value={formState.orderEmails[0] ?? ''}
                />
              </AppStack>
            )}

            <Checkbox
              checked={formState.offlinePaymentsEnabled}
              disabled={formState.loading}
              label="Enable Offline Payments"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  offlinePaymentsEnabled: e.currentTarget.checked,
                  errors: {},
                  error: ''
                })
              }
              style={{ flex: 1, marginTop: 10 }}
              styles={{ label: { fontSize: 14, fontWeight: 500 } }}
            />

            <Checkbox
              checked={formState.isInviteOnly}
              disabled={formState.loading}
              label="Invite Only"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  isInviteOnly: e.currentTarget.checked,
                  waitlistEnabled: false,
                  errors: {},
                  error: ''
                })
              }
              style={{ flex: 1, marginTop: 10 }}
              styles={{ label: { fontSize: 14, fontWeight: 500 } }}
            />
          </AppStack>

          {formState.error && (
            <AppFlexbox style={{ justifyContent: 'center' }}>
              <Alert
                color="red"
                icon={<AlertCircle size={16} />}
                radius="xs"
                style={{ flex: rs([null, null, 1], mqIndex) }}
                variant="filled"
              >
                {formState.error}
              </Alert>
            </AppFlexbox>
          )}
          {formState.overlappingDivisions.length > 0 && (
            <Alert
              icon={<AlertCircle size={30} />}
              styles={{
                wrapper: { alignItems: 'center' },
                icon: { width: 30, height: 25 }
              }}
              title={
                <AppTitle order={4} style={{ height: 30 }}>
                  Warning
                </AppTitle>
              }
              variant="outline"
            >
              <AppStack style={{ gap: 10 }}>
                <AppText>
                  This division's age range overlaps with the following
                  division(s);
                </AppText>
                <List withPadding>
                  {formState.overlappingDivisions.map((d) => (
                    <List.Item key={d.pkRegAssociationDivision}>
                      {d.name}
                    </List.Item>
                  ))}
                </List>
                <AppText>
                  Some registrants may qualify for multiple divisions.
                </AppText>
              </AppStack>
            </Alert>
          )}

          {((!formState.waitlistEnabled &&
            regAssociationDivision?.regWaitlistReservationSentCount > 0) ||
            (regAssociationDivision &&
              formState.waitlistEnabled &&
              regAssociationDivision?.regWaitlistReservationSentCount > 0 &&
              formState.registrantLimit <
                regAssociationDivision.regWaitlistReservationSentCount +
                  regAssociationDivision.regFormSubmissionCount)) && (
            <Alert
              title={
                <AppTitle order={4} style={{ height: 30 }}>
                  Warning
                </AppTitle>
              }
              variant="outline"
            >
              <AppStack style={{ gap: 20 }}>
                {!formState.waitlistEnabled ? (
                  <>
                    <AppText>
                      This division's waitlist has been disabled.
                    </AppText>
                    <AppText>
                      There is currently{' '}
                      {regAssociationDivision.regWaitlistReservationSentCount}{' '}
                      waitlist reservation(s) sent out.
                    </AppText>
                  </>
                ) : (
                  <>
                    <AppText>
                      This division's registration limit is currently less than
                      the number of registrations and waitlist reservations sent
                      out.
                    </AppText>
                    <AppText>
                      There is currently{' '}
                      {regAssociationDivision.regWaitlistReservationSentCount +
                        regAssociationDivision.regFormSubmissionCount -
                        formState.registrantLimit >
                      regAssociationDivision.regWaitlistReservationSentCount
                        ? regAssociationDivision.regWaitlistReservationSentCount
                        : regAssociationDivision.regWaitlistReservationSentCount +
                          regAssociationDivision.regFormSubmissionCount -
                          formState.registrantLimit}{' '}
                      waitlist reservation(s) sent out that exceed the
                      registration limit.
                    </AppText>
                  </>
                )}
                <Checkbox
                  checked={formState.expireWaitlistReservations}
                  disabled={formState.loading}
                  label="Expire waitlist reservations"
                  onChange={(e) => {
                    setFormState({
                      ...formState,
                      expireWaitlistReservations: e.currentTarget.checked
                    });
                  }}
                  styles={{
                    label: {
                      fontSize: 16,
                      fontWeight: 500
                    }
                  }}
                />
                <Alert
                  color={
                    formState.expireWaitlistReservations ? 'blue' : 'yellow'
                  }
                  icon={
                    formState.expireWaitlistReservations ? (
                      <InfoCircle style={{ width: 35, height: 35 }} />
                    ) : (
                      <AlertCircle style={{ width: 35, height: 35 }} />
                    )
                  }
                  styles={{ icon: { marginTop: 15, width: 35 } }}
                  variant="outline"
                >
                  {formState.expireWaitlistReservations ? (
                    <AppStack style={{ gap: 5 }}>
                      <AppText>
                        We will expire any ongoing waitlist reservations
                        {formState.waitlistEnabled
                          ? ' for registrants that exceed the limit.'
                          : '.'}
                      </AppText>
                      <AppText>
                        They will not be removed from the waitlist.
                      </AppText>
                    </AppStack>
                  ) : (
                    <AppStack style={{ gap: 5 }}>
                      <AppText>
                        Waitlist reservations will not be expired.
                      </AppText>
                      <AppText>
                        Any ongoing reservations will be claimable for
                        registrants to join the division.
                      </AppText>
                    </AppStack>
                  )}
                </Alert>
              </AppStack>
            </Alert>
          )}

          {regAssociationDivision &&
            formState.waitlistEnabled &&
            regAssociationDivision.regWaitlistReservationSentCount !==
              regAssociationDivision.regWaitlistPersonCount &&
            formState.registrantLimit >
              regAssociationDivision.regFormSubmissionCount +
                regAssociationDivision.regWaitlistReservationSentCount && (
              <Alert
                styles={{
                  wrapper: { alignItems: 'center' },
                  icon: { width: 30, height: 25 }
                }}
                title={
                  <AppTitle order={4} style={{ height: 30 }}>
                    Warning
                  </AppTitle>
                }
                variant="outline"
              >
                <AppStack style={{ gap: 20 }}>
                  <AppText>
                    This division currently is has{' '}
                    {regAssociationDivision.regWaitlistPersonCount}{' '}
                    {regAssociationDivision.regWaitlistPersonCount === 1
                      ? 'person'
                      : 'people'}{' '}
                    on the waitlist.
                  </AppText>
                  <AppText>
                    There is {maxWaitlistNotifyAmount}{' '}
                    {maxWaitlistNotifyAmount === 1 ? 'person' : 'people'} on the
                    waitlist available to be notified.
                  </AppText>
                  <Checkbox
                    checked={formState.notifyWaitlist}
                    disabled={formState.loading}
                    label="Notify waitlist registrants"
                    onChange={(e) => {
                      setFormState({
                        ...formState,
                        notifyWaitlist: e.currentTarget.checked,
                        notifyWaitlistAmount: e.currentTarget.checked
                          ? maxWaitlistNotifyAmount
                          : formState.notifyWaitlistAmount
                      });
                    }}
                    styles={{
                      label: {
                        fontSize: 16,
                        fontWeight: 500
                      }
                    }}
                  />
                  {formState.notifyWaitlist && (
                    <NumberInput
                      disabled={formState.loading}
                      label="Notify Amount"
                      max={maxWaitlistNotifyAmount}
                      min={1}
                      onChange={(value) =>
                        setFormState({
                          ...formState,
                          notifyWaitlistAmount: value || null,
                          errors: {},
                          error: ''
                        })
                      }
                      radius={0}
                      required
                      style={{ flex: 1, alignSelf: 'stretch' }}
                      value={formState.notifyWaitlistAmount}
                    />
                  )}
                  <Alert
                    color={formState.notifyWaitlist ? 'blue' : 'yellow'}
                    icon={
                      formState.notifyWaitlist ? (
                        <InfoCircle style={{ width: 35, height: 35 }} />
                      ) : (
                        <AlertCircle style={{ width: 35, height: 35 }} />
                      )
                    }
                    styles={{ icon: { marginTop: 15, width: 35 } }}
                    variant="outline"
                  >
                    {formState.notifyWaitlist ? (
                      <AppText>
                        We will notify the next waitlist registrants that they
                        can now register for {regAssociationDivision.name}.
                      </AppText>
                    ) : (
                      <AppText>
                        The next waitlist registrant will not be notified of any
                        open positions.
                      </AppText>
                    )}
                  </Alert>
                </AppStack>
              </Alert>
            )}

          {formState.isInviteOnly && (
            <Alert
              color="yellow"
              styles={{
                wrapper: { alignItems: 'center' },
                icon: { width: 30, height: 25 }
              }}
              title={
                <AppTitle order={4} style={{ height: 30 }}>
                  Invite Only
                </AppTitle>
              }
              variant="outline"
            >
              <AppStack style={{ gap: 10 }}>
                <AppText weight={500}>
                  This division is set to invite only. Registrants will have to
                  be manually invited to join.
                </AppText>
                <AppText weight={500}>
                  Waitlist has been disabled. Registrants will not be able to
                  join through waitlist reservations.
                </AppText>
              </AppStack>
            </Alert>
          )}

          {regAssociationDivision && !createCopy && (
            <Button
              color="red"
              disabled={formState.loading}
              onClick={() => setShowDeleteConfirmation(true)}
              style={{ width: '100%', maxWidth: 250, alignSelf: 'center' }}
            >
              Delete Division
            </Button>
          )}
        </FormSection>
      ) : (
        <DeleteItemView
          isLoading={formState.loading}
          itemLabel="Division"
          onCancel={() => {
            if (showDeleteConfirmation) {
              setShowDeleteConfirmation(false);
            }
            else {
              onClose();
            }
          }}
          onDelete={() => {
            setFormState({
              ...formState,
              errors: {},
              error: '',
              loading: true
            });
            deleteAdminRegAssociationDivision(
              regAssociationDivision.pkRegAssociationDivision,
              onClose,
              onError
            );
          }}
          warnings={[
            'Associated teams, invites, and waitlist registrants.',
            'Any record of financial registrations.'
          ]}
        />
      )}
    </ResponsiveModal>
  );
};

LeagueDivisionModal.propTypes = {
  createCopy: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  regAssociation: PropTypes.object,
  regAssociationDivision: PropTypes.object,
  regAssociationSeason: PropTypes.object,
  showDelete: PropTypes.bool
};

export default LeagueDivisionModal;
