import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Divider,
  Menu,
  ScrollArea,
  Select,
  TextInput
} from '@mantine/core';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import MenuContent from '../../common/MenuContent';
import AppText from '../../common/AppText';

const AnalyticsDivisionSelectMenu = ({
  divisionOptions,
  value,
  onChange,
  isCompare,
  loading
}) => {
  const [formState, setFormState] = useState({
    search: '',
    season: '',
    isMenuOpen: false,
    isSelectOpen: false
  });

  const divisionsFiltered =
    divisionOptions?.filter(
      (s) =>
        (!formState.season || s.seasonName === formState.season) &&
        (!formState.search ||
          s.label.toLowerCase().includes(formState.search.toLowerCase()))
    ) || [];
  const groupedDivisionBySeason = divisionsFiltered
    .reduce((r, c) => {
      const season = r.find((f) => f.season === c.seasonName);
      if (season) {
        season.divisions.push(c);
      }
      else {
        r.push({ season: c.seasonName, divisions: [c] });
      }
      return r;
    }, [])
    .sort((a, b) => a.season.localeCompare(b.season));

  return (
    <MenuContent
      closeOnClickOutside
      closeOnItemClick
      control={
        <Button
          color="dark"
          loading={loading}
          onClick={() => {
            setFormState({
              ...formState,
              isOpen: true
            });
          }}
          radius={8}
          size="compact-sm"
          style={{ border: 'solid 1px #B1B1B1', minWidth: 50 }}
          variant="white"
        >
          <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
              {isCompare && (
                <>
                  <span style={{ color: '#999' }}>Compared to:</span>{' '}
                </>
              )}
              {divisionOptions.find((s) => s.value === value)?.label}
            </AppText>
          </AppFlexbox>
        </Button>
      }
      onChange={(v) => {
        if (!formState.isSelectOpen) {
          setFormState({
            ...formState,
            isMenuOpen: v
          });
        }
      }}
      opened={formState.isMenuOpen}
      position="bottom-start"
      radius="md"
      shadow="xl"
      styles={{ dropdown: { padding: 0 } }}
      width={300}
    >
      <AppStack style={{ gap: 0, flex: 1 }}>
        <Select
          clearable
          data={groupedDivisionBySeason.map((s) => ({
            value: s.season,
            label: s.season
          }))}
          onBlur={() => {
            setFormState({
              ...formState,
              isSelectOpen: false
            });
          }}
          onChange={(v) => {
            setFormState({
              ...formState,
              season: v
            });
          }}
          onFocus={() => {
            setFormState({
              ...formState,
              isSelectOpen: true
            });
          }}
          placeholder="Filter by season..."
          radius={0}
          searchable
          size="sm"
          style={{ flex: 1 }}
          styles={{ input: { border: 'none' } }}
          value={formState.season}
          variant="filled"
        />
        <Divider />
        <TextInput
          onChange={(e) => {
            setFormState({
              ...formState,
              search: e.currentTarget.value
            });
          }}
          placeholder="Search by name..."
          radius={0}
          size="sm"
          style={{ flex: 1 }}
          styles={{ input: { border: 'none' } }}
          value={formState.search}
          variant="filled"
        />
        <Divider />
        <ScrollArea.Autosize mah={325} scrollbarSize={5} type="scroll">
          {groupedDivisionBySeason.map((s) => (
            <React.Fragment key={s.season}>
              <Menu.Label>{s.season}</Menu.Label>
              {s.divisions.map((d) => (
                <Menu.Item
                  key={d.value}
                  onClick={(e) => {
                    e.preventDefault();
                    if (d.value !== value) {
                      onChange(d.value);
                    }
                  }}
                >
                  <AppFlexbox style={{ alignItems: 'center', gap: 12 }}>
                    <Checkbox checked={value === d.value} onChange={() => {}} />
                    <AppText style={{ fontSize: 14 }}>{d.label}</AppText>
                  </AppFlexbox>
                </Menu.Item>
              ))}
            </React.Fragment>
          ))}
        </ScrollArea.Autosize>
      </AppStack>
    </MenuContent>
  );
};

AnalyticsDivisionSelectMenu.propTypes = {
  divisionOptions: PropTypes.array,
  isCompare: PropTypes.bool,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string
};

export default AnalyticsDivisionSelectMenu;
