import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Stack } from '@mantine/core';
import ResponsiveModal from '../../common/ResponsiveModal';
import FormSection from '../../common/FormSection';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import { triggerNotification } from '../../../helpers/notificationHelper';
import SendOrderReceiptModal from '../financials/SendOrderReceiptModal';
import OrderDetailsInfo from './OrderDetailsInfo';
import AppTitle from '../../common/AppTitle';

const OrderDetailsModal = ({ isOpen, onClose, pkRegCart }) => {
  const fetchedPkRegCart = useRef(null);
  const { state, fetchCartOrderHistory } = useContext(RegistrationContext);
  const [receiptModalState, setReceiptModalState] = useState({ isOpen: false });
  const isLoading =
    !fetchedPkRegCart.current || state.regCartOrderHistory.loading;

  useEffect(() => {
    if (isOpen) {
      if (fetchedPkRegCart.current !== pkRegCart) {
        fetchCartOrderHistory(pkRegCart, null, triggerNotification);
        fetchedPkRegCart.current = pkRegCart;
      }
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClose}
      size={1100}
      title={<AppTitle order={2}>Order Summary</AppTitle>}
    >
      <FormSection cancelTitle="Close" isSubmitHidden onCancel={onClose}>
        <Stack style={{ gap: 20 }}>
          <Button
            onClick={() =>
              setReceiptModalState({
                ...receiptModalState,
                isOpen: true
              })
            }
            style={{ maxWidth: 250 }}
            variant="outline"
          >
            Email Receipt
          </Button>

          <OrderDetailsInfo
            hideTitle
            regCart={isLoading ? null : state.regCartOrderHistory.value}
            withBorder
          />
        </Stack>
      </FormSection>

      <SendOrderReceiptModal
        isOpen={receiptModalState.isOpen}
        onClose={() =>
          setReceiptModalState({
            ...receiptModalState,
            isOpen: false
          })
        }
        pkRegCarts={[state.regCartOrderHistory.value?.pkRegCart]}
      />
    </ResponsiveModal>
  );
};

OrderDetailsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  pkRegCart: PropTypes.number
};

export default OrderDetailsModal;
