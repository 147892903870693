import { isDarkText } from './format';
import sportsHeadzLogo from '../images/sportsheadz.png';

const getResponsiveStyle = (styles = [], mediaQueryIndex = 0) =>
  mediaQueryIndex > styles.length - 1
    ? styles[styles.length - 1]
    : styles[mediaQueryIndex];

const hRange = [0, 360];
const sRange = [50, 75];
const lRange = [25, 60];

const normalizeHash = (hash, min, max) =>
  Math.floor((hash % (max - min)) + min);

const generateHsl = (name) => {
  let hash = 0;
  for (let i = 0; i < name.length; i += 1) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  hash = Math.abs(hash);

  const h = normalizeHash(hash, hRange[0], hRange[1]);
  const s = normalizeHash(hash, sRange[0], sRange[1]);
  const l = normalizeHash(hash, lRange[0], lRange[1]);
  return [h, s, l];
};

const HsltoString = (hsl) => `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;

const useRegAssociationBranding = (regAssociation) => {
  const primaryColor = regAssociation?.primaryColor ?? '#000';
  const secondaryColor = regAssociation?.secondaryColor ?? 'dodgerblue';

  const isPrimaryDarkText = isDarkText(primaryColor);
  const isSecondaryDarkText = isDarkText(secondaryColor);
  return {
    headerImage: regAssociation?.logoImageUrl ?? sportsHeadzLogo,
    primaryColor,
    secondaryColor,
    isPrimaryDarkText,
    isSecondaryDarkText,
    primaryTextColor: isDarkText(primaryColor) ? '#000' : '#FFF',
    secondaryTextColor: isDarkText(secondaryColor) ? '#000' : '#FFF',
    lightMode: regAssociation?.lightModeTheme ?? false
  };
};

export {
  getResponsiveStyle,
  generateHsl,
  HsltoString,
  useRegAssociationBranding
};
