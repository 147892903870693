import React, { useState } from 'react';
import PropTypes from 'prop-types';
import UserSettingView from './UserSettingView';
import SettingsModal from './SettingsModal';

const PASSWORD_SETTINGS = [
  {
    label: 'Change Password',
    value: 'change_password'
  }
];

const PasswordAndSecurityView = ({ onBack }) => {
  const [modalState, setModalState] = useState({
    isOpen: false,
    settingView: null
  });

  return (
    <>
      <UserSettingView
        onBack={onBack}
        onSelectSetting={(setting) =>
          setModalState({
            isOpen: true,
            settingView: setting.value
          })
        }
        settings={PASSWORD_SETTINGS}
        title="Password and Security"
      />
      <SettingsModal
        isOpen={modalState.isOpen}
        onClose={() =>
          setModalState({
            ...modalState,
            isOpen: false
          })
        }
        settingView={modalState.settingView}
      />
    </>
  );
};

PasswordAndSecurityView.propTypes = { onBack: PropTypes.func };

export default PasswordAndSecurityView;
