import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Skeleton, TextInput } from '@mantine/core';
import RegistrationsListItem from './AssociationListItem';
import PaginationList from '../../common/PaginationList';
import AppStack from '../../common/AppStack';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import AppTitle from '../../common/AppTitle';
import AppFlexbox from '../../common/AppFlexbox';

const AssociationList = ({ associations, isLoading, title, showFilters }) => {
  const [filterState, setFilterState] = useState({ search: '', pageIndex: 1 });
  const mqIndex = useMediaQueryIndex();
  const sortedAssociations = associations.sort((a, b) =>
    a.association.name.localeCompare(b.association.name)
  );
  const searchFilterParam = filterState.search?.replace(' ', '').toLowerCase();

  return (
    <AppStack style={{ gap: 10 }}>
      {isLoading ? (
        <Skeleton height={30} style={{ width: rs(['75%', 250], mqIndex) }} />
      ) : (
        <AppFlexbox
          style={{
            justifyContent: 'space-between',
            flexDirection: rs(['column', 'row'], mqIndex)
          }}
        >
          <AppTitle order={2}>{title}</AppTitle>
          {showFilters && (
            <TextInput
              onChange={(e) =>
                setFilterState({
                  ...filterState,
                  search: e.currentTarget.value,
                  pageIndex: 1
                })
              }
              placeholder="Search for association..."
              size="sm"
              style={{ flex: 1, maxWidth: rs(['unset', 250], mqIndex) }}
              value={filterState.search}
            />
          )}
        </AppFlexbox>
      )}

      <AppStack style={{ flex: 1 }}>
        <PaginationList
          emptyMessage={`No ${title}`}
          isLoading={isLoading}
          items={sortedAssociations
            .filter(
              (a) =>
                !filterState.search ||
                a.association.name
                  .replace(' ', '')
                  .toLowerCase()
                  .includes(searchFilterParam) ||
                a.association.domain
                  .replace(' ', '')
                  .toLowerCase()
                  .includes(searchFilterParam)
            )
            .map((a) => (
              <RegistrationsListItem
                key={a.pkRegAssociation}
                regAssociation={a}
              />
            ))}
          itemsPerPage={12}
          LoadingComponent={RegistrationsListItem}
          loadingComponentCount={12}
          onPageChange={(pageIndex) =>
            setFilterState({ ...filterState, pageIndex })
          }
          pageIndex={filterState.pageIndex}
        />
      </AppStack>
    </AppStack>
  );
};

AssociationList.propTypes = {
  associations: PropTypes.array,
  isLoading: PropTypes.bool,
  showFilters: PropTypes.bool,
  title: PropTypes.string
};

export default AssociationList;
