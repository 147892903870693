const getSpecialRequestsWarnings = (
  teams,
  specialRequests,
  teamCreator = false
) => {
  const errors = [];
  specialRequests.forEach((specialRequest) => {
    const requestor = specialRequest.regFormSubmission1.regPerson;
    const requestee = specialRequest.regFormSubmission2.regPerson;

    const divisionTeams = teams.filter(
      (t) =>
        t.regAssociationDivision.pkRegAssociationDivision ===
        (specialRequest.regFormSubmission1.regAssociationDivisionOverride
          ?.pkRegAssociationDivision ??
          specialRequest.regFormSubmission1.regAssociationDivisionForm
            .regAssociationDivision.pkRegAssociationDivision)
    );

    const requestorTeam = divisionTeams.find((t) =>
      t.regAssociationDivisionTeamPersons.some(
        (p) => p.regPerson.pkRegPerson === requestor.pkRegPerson && p.active
      )
    );
    const requesteeTeam = divisionTeams.find((t) =>
      t.regAssociationDivisionTeamPersons.some(
        (p) => p.regPerson.pkRegPerson === requestee.pkRegPerson && p.active
      )
    );

    if (requestorTeam || requesteeTeam) {
      switch (specialRequest.regSpecialRequestType) {
        case 1:
          if (requestorTeam && !requesteeTeam) {
            errors.push(
              teamCreator
                ? `${requestor.firstName} ${requestor.lastName} has requested to play others but is already assigned to ${requestorTeam.name}`
                : `${requestor.firstName} ${requestor.lastName} associated with ${requestorTeam.name} has requested to play with ${requestee.firstName} ${requestee.lastName}`
            );
          }
          else if (!requestorTeam && requesteeTeam) {
            errors.push(
              teamCreator
                ? `${requestee.firstName} ${requestee.lastName} has requested to play others but is already assigned to ${requesteeTeam.name}`
                : `${requestor.firstName} ${requestor.lastName} has requested to play with ${requestee.firstName} ${requestee.lastName} associated with ${requesteeTeam.name}`
            );
          }
          else if (
            requestorTeam.pkRegAssociationDivisionTeam !==
            requesteeTeam.pkRegAssociationDivisionTeam
          ) {
            errors.push(
              `${requestor.firstName} ${requestor.lastName} associated with ${requestorTeam.name} has requested to play with ${requestee.firstName} ${requestee.lastName} who is associated with ${requesteeTeam.name}`
            );
          }
          break;
        default:
          break;
      }
    }
  });

  return errors;
};

const getManualTeamSpecialRequestsWarnings = (
  regAssociationDivisionTeamPersons,
  specialRequests
) => {
  const errors = [];
  specialRequests.forEach((specialRequest) => {
    const requestor = specialRequest.regFormSubmission1.regPerson;
    const requestee = specialRequest.regFormSubmission2.regPerson;

    const requestorOnTeam = regAssociationDivisionTeamPersons.find(
      (p) => p.regPerson.pkRegPerson === requestor.pkRegPerson
    );
    const requesteeOnTeam = regAssociationDivisionTeamPersons.find(
      (p) => p.regPerson.pkRegPerson === requestee.pkRegPerson
    );

    if (requestorOnTeam || requesteeOnTeam) {
      switch (specialRequest.regSpecialRequestType) {
        case 1:
          if (!requestorOnTeam || !requesteeOnTeam) {
            errors.push(
              `${requestor.firstName} ${requestor.lastName} has requested to play with ${requestee.firstName} ${requestee.lastName}`
            );
          }
          break;
        default:
          break;
      }
    }
  });

  return errors;
};

const getGenerateTeamsAndPlayerCount = (
  amount,
  unassignedRegPersonCount,
  specialRequests,
  existingTeams,
  amountType = 'TEAMS'
) => {
  const teamCount =
    amountType === 'PLAYERS'
      ? Math.ceil(unassignedRegPersonCount / amount)
      : amount;

  const teams = [];
  for (let i = 0; i < teamCount; i += 1) {
    teams.push({ teamPersons: [] });
  }

  const remainingSpecialRequest = specialRequests.filter(
    (s) =>
      !existingTeams.some((t) =>
        t.regAssociationDivisionTeamPersons.some(
          (p) =>
            p.regPerson.pkRegPerson ===
              s.regFormSubmission1.regPerson.pkRegPerson ||
            p.regPerson.pkRegPerson ===
              s.regFormSubmission2.regPerson.pkRegPerson
        )
      )
  );

  let specialRequestPlayerCount = 0;
  remainingSpecialRequest.forEach((specialRequest) => {
    const requestor = specialRequest.regFormSubmission1.regPerson;
    const requestee = specialRequest.regFormSubmission2.regPerson;

    let requestorTeam = teams.find((t) =>
      t.teamPersons.some((p) => p.pkRegPerson === requestor.pkRegPerson)
    );

    switch (specialRequest.regSpecialRequestType) {
      case 1: {
        if (!requestorTeam) {
          requestorTeam =
            teams.find((t) =>
              t.teamPersons.some((p) => p.pkRegPerson === requestee.pkRegPerson)
            ) ??
            teams.sort(
              (a, b) => a.teamPersons.length - b.teamPersons.length
            )[0];

          requestorTeam.teamPersons.push(requestor);
          specialRequestPlayerCount += 1;
        }

        if (
          !requestorTeam.teamPersons.some(
            (p) => p.pkRegPerson === requestee.pkRegPerson
          )
        ) {
          requestorTeam.teamPersons.push(requestee);
          specialRequestPlayerCount += 1;
        }
        break;
      }
      default:
        break;
    }
  });

  for (
    let i = 0;
    i < unassignedRegPersonCount - specialRequestPlayerCount;
    i += 1
  ) {
    teams
      .sort((a, b) => a.teamPersons.length - b.teamPersons.length)[0]
      .teamPersons.push({});
  }

  const teamResults = [];
  const errorResults = [];
  teams.forEach((team) => {
    const existingTeamResult = teamResults.find(
      (t) => t.playerCount === team.teamPersons.length
    );
    if (!existingTeamResult) {
      teamResults.push({
        playerCount: team.teamPersons.length,
        teamCount: 1
      });
    }
    else {
      existingTeamResult.teamCount += 1;
    }
  });

  const teamsHavePlayerGap = teamResults.some((t) =>
    teamResults.some((s) => s.playerCount - t.playerCount >= 2)
  );
  if (teamsHavePlayerGap) {
    errorResults.push('Unable to create even teams due to special requests.');
  }

  return {
    teams: teamResults.sort((a, b) => b.playerCount - a.playerCount),
    errors: errorResults
  };
};

export {
  getSpecialRequestsWarnings,
  getGenerateTeamsAndPlayerCount,
  getManualTeamSpecialRequestsWarnings
};
