import {
  BrandFacebook,
  BrandInstagram,
  BrandLinkedin,
  BrandTwitter
} from 'tabler-icons-react';

const PRODUCTION = true;
const TESTING = false;
const LOCAL_API = false;

const COGNITO_SETTING_DEV = {
  Auth: {
    identityPoolId: 'us-east-1:5111a894-2c2c-41b1-a29a-f717eaa9aa31',
    region: 'us-east-1',
    userPoolId: 'us-east-1_Z2PFfqRF1',
    userPoolWebClientId: '624bs7emni9leiuspgcrrs9j8s',
    mandatorySignIn: true
  },
  Storage: {
    bucket: 'hockeyheadz-storage',
    region: 'us-east-1'
  }
};

const COGNITO_SETTING_PROD = {
  Auth: {
    identityPoolId: 'us-east-1:93ca516d-e2c4-4ad6-b44c-4b05604c8bd7',
    region: 'us-east-1',
    userPoolId: 'us-east-1_FAoqNjX2j',
    userPoolWebClientId: '4fdno1j1g5jukptomkdifu3gao',
    mandatorySignIn: true
  },
  Storage: {
    bucket: 'hockeyheadz-storage',
    region: 'us-east-1'
  }
};

const S3_PUBLIC_URL = 'https://hockeyheadz-storage.s3.amazonaws.com/public/';

const PASSWORD_CONSTRAINTS = {
  length: 8,
  regexList: {
    capRegex: /\w*[A-Z]/,
    numRegex: /[0-9]/,
    symRegex: /[-!$%^&*()_+|~=`{}[\]:";'<>?,./]/
  }
};

const REG_ASSOCIATION_SOCIAL_TYPES = [
  {
    pkRegAssociationSocialType: 1,
    label: 'FaceBook',
    value: 'FACEBOOK',
    icon: BrandFacebook
  },
  {
    pkRegAssociationSocialType: 2,
    label: 'Twitter',
    value: 'TWITTER',
    icon: BrandTwitter
  },
  {
    pkRegAssociationSocialType: 3,
    label: 'Instagram',
    value: 'INSTAGRAM',
    icon: BrandInstagram
  },
  {
    pkRegAssociationSocialType: 4,
    label: 'LinkedIn',
    value: 'LINKEDIN',
    icon: BrandLinkedin
  }
];

const TEST_CASE_DATA = {
  auth: {
    email: '',
    password: ''
  },
  regAssociation: { pkRegAssociation: 1, pkForm: 29 }
};

const CANADA_PROVINCE_LIST = [
  'Alberta',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland and Labrador',
  'Northwest Territories',
  'Nova Scotia',
  'Nunavut',
  'Ontario',
  'Prince Edward Island',
  'Quebec',
  'Saskatchewan',
  'Yukon'
];

const USA_STATE_LIST = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming'
];

const OFFLINE_PAYMENT_TYPES = [
  {
    value: '1',
    label: 'Cheque'
  },
  {
    value: '2',
    label: 'Cash'
  },
  {
    value: '3',
    label: 'E-Transfer'
  },
  {
    value: '4',
    label: 'Jumpstart'
  },
  {
    value: '5',
    label: 'Refund'
  },
  {
    value: '6',
    label: 'Invoice'
  },
  {
    value: '7',
    label: 'Stripe'
  },
  {
    value: '8',
    label: 'Afterpay'
  },
  {
    value: '9',
    label: 'Forgiveness'
  },
  {
    value: '10',
    label: 'SportsPay'
  },
  {
    value: '11',
    label: 'SportsPay Installments'
  }
];

const REG_GENDER_TYPES = [
  {
    value: '1',
    label: 'All-Inclusive'
  },
  {
    value: '2',
    label: 'Girls Only'
  },
  {
    value: '3',
    label: 'Boys Only'
  }
];

const REG_FORM_SORT_TYPES = [
  {
    value: '1',
    type: 'REG_END_DATE',
    label: 'Registration End Date'
  },
  {
    value: '2',
    type: 'AGE_RESTRICTION',
    label: 'Age Restriction'
  },
  {
    value: '4',
    type: 'FORM_TITLE',
    label: 'Form Title'
  },
  {
    value: '5',
    type: 'COST',
    label: 'Cost'
  },
  {
    value: '6',
    type: 'DIVISION_NAME',
    label: 'Division Name'
  }
];

const REG_ASSOCIATION_SAVED_TEMPLATE_INPUT_TYPE = [
  {
    label: 'Account',
    value: 'account_variable'
  },
  {
    label: 'Payment',
    value: 'payment_variable'
  },
  {
    label: 'Form',
    value: 'form_variable'
  }
];

const PAYMENT_PROVIDERS = {
  stripe: {
    value: 1,
    label: 'Stripe'
  },
  sportspay: {
    value: 2,
    label: 'SportsPay'
  }
};

const PAYMENT_PROVIDER_LIST = [
  {
    label: 'Stripe',
    value: 1
  },
  {
    label: 'SportsPay',
    value: 2
  }
];

const CURRENCY_TYPE_ENUM = {
  CAD: 1,
  USD: 2
};

const CURRENCY_TYPE_LIST = [
  {
    label: 'Canadian Dollar',
    fullLabel: 'Canadian Dollar (CAD $)',
    abbr: 'CAD',
    symbol: '$',
    value: CURRENCY_TYPE_ENUM.CAD
  },
  {
    label: 'US Dollar',
    fullLabel: 'US Dollar (USD $)',
    abbr: 'USD',
    symbol: '$',
    value: CURRENCY_TYPE_ENUM.USD
  }
];

const REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM = {
  view: 'VIEW',
  orderSummary: 'ORDER_SUMMARY',
  emailUser: 'EMAIL',
  refund: 'REFUND',
  changeDivision: 'CHANGE-DIVISION',
  changeOverrideEmail: 'CHANGE-EMAIL-OVERRIDE',
  invoice: 'INVOICE',
  reinstate: 'REINSTATE',
  deny: 'DENY',
  viewPayments: 'RECORD_PAYMENT'
};

const PAYMENT_PROVIDER_LINE_ITEM_TYPES = {
  registration: {
    value: 1,
    label: 'Registration'
  },
  merch: {
    value: 2,
    label: 'Merch'
  }
};

const REG_FORM_CONTROL_TYPE_ENUM = {
  TEXT: 1,
  TEXT_INPUT: 2,
  DROP_DOWN: 3,
  DATE: 4,
  RADIO_GROUP: 5,
  CHECKBOX: 6,
  FILE_DOWNLOAD: 7,
  SIGNATURE: 8,
  FILE_UPLOAD: 9,
  SLIDER: 10,
  BUTTON_GROUP: 11
};

const REG_FORM_CONTROL_TYPES = [
  {
    label: 'Text',
    value: REG_FORM_CONTROL_TYPE_ENUM.TEXT
  },
  {
    label: 'Text Input',
    value: REG_FORM_CONTROL_TYPE_ENUM.TEXT_INPUT
  },
  {
    label: 'Drop-down',
    value: REG_FORM_CONTROL_TYPE_ENUM.DROP_DOWN
  },
  {
    label: 'Date',
    value: REG_FORM_CONTROL_TYPE_ENUM.DATE
  },
  {
    label: 'Radio Group',
    value: REG_FORM_CONTROL_TYPE_ENUM.RADIO_GROUP
  },
  {
    label: 'Checkbox',
    value: REG_FORM_CONTROL_TYPE_ENUM.CHECKBOX
  },
  {
    label: 'File Download',
    value: REG_FORM_CONTROL_TYPE_ENUM.FILE_DOWNLOAD
  },
  {
    label: 'Signature',
    value: REG_FORM_CONTROL_TYPE_ENUM.SIGNATURE
  },
  {
    label: 'File Upload',
    value: REG_FORM_CONTROL_TYPE_ENUM.FILE_UPLOAD
  }
];

const ANALYTIC_MERCH_PACKAGE_SHOWCASE_LOCATION_ENUM = {
  ASSOCIATION_PAGE_HEADER: 'ASSOCIATION_PAGE_HEADER',
  ORDER_SUMMARY: 'ORDER_SUMMARY ',
  CART_FOOTER: 'CART_FOOTER',
  FORM_SUBMISSION_FOOTER: 'FORM_SUBMISSION_FOOTER'
};

const REG_PERMISSION_ENUM = {
  MY_LEAGUE: 1,
  COUPONS: 2,
  FORMS: 3,
  FINANCIALS: 4,
  REPORTS: 5,
  EVALUATIONS: 6,
  BRANDING: 7,
  SUPER_ADMIN: 8
};

const REG_PERMISSION_LIST = [
  {
    label: 'My League',
    value: REG_PERMISSION_ENUM.MY_LEAGUE,
    description: 'Setup and manage registration seasons, divisions, and teams.'
  },
  {
    label: 'Coupons',
    value: REG_PERMISSION_ENUM.COUPONS,
    description: 'Create and manage registration coupons.'
  },
  {
    label: 'Forms',
    value: REG_PERMISSION_ENUM.FORMS,
    description: 'Create and manage registration forms.'
  },
  {
    label: 'Financials',
    value: REG_PERMISSION_ENUM.FINANCIALS,
    description: 'Manage financials including payments and refunds.'
  },
  {
    label: 'Reports',
    value: REG_PERMISSION_ENUM.REPORTS,
    description: 'Generate reports about your association.'
  },
  {
    label: 'Evaluations',
    value: REG_PERMISSION_ENUM.EVALUATIONS,
    description: 'Scout and evaluate registrants.'
  },
  {
    label: 'Branding',
    value: REG_PERMISSION_ENUM.BRANDING,
    description: 'Change the look and feel of your association page.'
  },
  {
    label: 'Super Admin (Full Access Control)',
    value: REG_PERMISSION_ENUM.SUPER_ADMIN,
    description:
      'Complete and unrestricted access to all features and settings.'
  }
];

const REG_SCOUT_REPORT_QUESTION_TYPE = {
  METRIC: 1,
  ATTRIBUTE: 2
};

const DEFUALT_EVALUATION_BUTTON_OPTIONS = [
  { key: 1, value: 'Poor', sort: 1 },
  { key: 2, value: 'Average', sort: 2 },
  { key: 3, value: 'Excellent', sort: 3 }
];

const REG_SCOUT_REPORT_QUESTION_RESTRICTION_CONDITION_ENUM = {
  EQUAL: '1',
  NOT_EQUAL: '2'
};

const REG_SCOUT_REPORT_QUESTION_RESTRICTION_CONDITIONS = [
  {
    value: REG_SCOUT_REPORT_QUESTION_RESTRICTION_CONDITION_ENUM.EQUAL,
    label: 'Equal to'
  },
  {
    value: REG_SCOUT_REPORT_QUESTION_RESTRICTION_CONDITION_ENUM.NOT_EQUAL,
    label: 'Not equal to'
  }
];

const REG_FORM_SUBMISSION_INVOICE_STATUS_ENUM = {
  PENDING: 'PENDING',
  PAID: 'PAID',
  VOID: 'VOID',
  UNCOLLECTIBLE: 'UNCOLLECTIBLE'
};

const REG_FORM_SUBMISSION_INVOICE_STATUS_LIST = [
  {
    label: 'Pending',
    color: 'blue',
    value: REG_FORM_SUBMISSION_INVOICE_STATUS_ENUM.PENDING
  },
  {
    label: 'Approved',
    color: 'green',
    value: REG_FORM_SUBMISSION_INVOICE_STATUS_ENUM.PAID
  },
  {
    label: 'Void',
    color: 'red',
    value: REG_FORM_SUBMISSION_INVOICE_STATUS_ENUM.VOID
  },
  {
    label: 'Uncollectible',
    color: 'orange',
    value: REG_FORM_SUBMISSION_INVOICE_STATUS_ENUM.UNCOLLECTIBLE
  }
];

export {
  PRODUCTION,
  TESTING,
  LOCAL_API,
  COGNITO_SETTING_DEV,
  COGNITO_SETTING_PROD,
  S3_PUBLIC_URL,
  PASSWORD_CONSTRAINTS,
  REG_ASSOCIATION_SOCIAL_TYPES,
  TEST_CASE_DATA,
  CANADA_PROVINCE_LIST,
  USA_STATE_LIST,
  OFFLINE_PAYMENT_TYPES,
  REG_GENDER_TYPES,
  REG_FORM_SORT_TYPES,
  REG_ASSOCIATION_SAVED_TEMPLATE_INPUT_TYPE,
  PAYMENT_PROVIDERS,
  PAYMENT_PROVIDER_LIST,
  CURRENCY_TYPE_ENUM,
  CURRENCY_TYPE_LIST,
  REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM,
  PAYMENT_PROVIDER_LINE_ITEM_TYPES,
  REG_FORM_CONTROL_TYPE_ENUM,
  REG_FORM_CONTROL_TYPES,
  ANALYTIC_MERCH_PACKAGE_SHOWCASE_LOCATION_ENUM,
  REG_PERMISSION_ENUM,
  REG_PERMISSION_LIST,
  REG_SCOUT_REPORT_QUESTION_TYPE,
  DEFUALT_EVALUATION_BUTTON_OPTIONS,
  REG_SCOUT_REPORT_QUESTION_RESTRICTION_CONDITION_ENUM,
  REG_SCOUT_REPORT_QUESTION_RESTRICTION_CONDITIONS,
  REG_FORM_SUBMISSION_INVOICE_STATUS_ENUM,
  REG_FORM_SUBMISSION_INVOICE_STATUS_LIST
};
