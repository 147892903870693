import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Alert, List, Text } from '@mantine/core';
import { AlertCircle } from 'tabler-icons-react';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { Context as AdminContext } from '../../../providers/AuthProvider';
import InviteCard from './InviteCard';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import { triggerNotification } from '../../../helpers/notificationHelper';
import { REG_PERMISSION_LIST } from '../../../config/constants';
import ConfirmModal from '../../common/ConfirmModal';

const AdministratorInviteView = ({ inviteCode }) => {
  const hasFetched = useRef(false);
  const navigate = useNavigate();
  const { state: authState, logout } = useContext(AdminContext);
  const {
    state,
    fetchRegAssociationAdminInvite,
    respondToRegAssociationAdminInvite
  } = useContext(RegistrationAdminContext);
  const [formState, setFormState] = useState({
    showConfirmation: false,
    isLoading: false
  });
  const { value: adminInvite } = state.regAssociationAdminInvite;
  const isLoading =
    !hasFetched.current || state.regAssociationAdminInvite.loading;

  const inviteEmailMismatch =
    adminInvite?.inviteSentTo !== authState.userData.user.email;

  useEffect(() => {
    if (inviteCode) {
      fetchRegAssociationAdminInvite(
        inviteCode,
        () => {},
        () => navigate('/invites')
      );
      hasFetched.current = true;
    }
  }, [inviteCode]);

  useEffect(() => {
    if (!isLoading && !adminInvite) {
      navigate('/invites');
    }
  }, [isLoading]);

  const respondToInvite = (isAccepted) => {
    setFormState({ showConfirmation: false, isLoading: true });
    respondToRegAssociationAdminInvite(
      adminInvite.pkRegAssociationAdmin,
      { isAccepted },
      () => {
        triggerNotification(
          isAccepted
            ? 'Invite accepted, please log back in.'
            : 'Invite Declined',
          'Success',
          'green'
        );
        navigate('/invites');
        if (isAccepted) {
          logout();
        }
      },
      (e) => {
        triggerNotification(e);
        setFormState({ isLoading: false });
      }
    );
  };

  return (
    <InviteCard
      expiresAt={adminInvite?.expiresAt}
      isAccepted={!!adminInvite?.user}
      isDeleted={adminInvite?.deleted}
      isLoading={isLoading || !adminInvite}
      isSubmitting={formState.isLoading}
      onAccept={() => {
        if (inviteEmailMismatch) {
          setFormState({ ...formState, showConfirmation: true });
        }
        else {
          respondToInvite(true);
        }
      }}
      onDecline={() => respondToInvite(false)}
    >
      {adminInvite && (
        <>
          <AppStack style={{ gap: 20 }}>
            {inviteEmailMismatch && (
              <Alert color="yellow" icon={<AlertCircle />} title="Warning">
                <AppStack>
                  <AppText>
                    This invite was sent to <b>{adminInvite.inviteSentTo}</b>{' '}
                    but you are logged in as{' '}
                    <b>{authState.userData.user.email}</b>.
                  </AppText>
                  <AppText style={{ fontWeight: 500 }}>
                    Are you sure you want to accept this invite using this
                    account?
                  </AppText>
                </AppStack>
              </Alert>
            )}
            <AppText weight={500}>
              You've been invited to be an administrator for{' '}
              {adminInvite.regAssociation.association.name}
            </AppText>
            <AppText>
              The access granted includes the following permissions;
            </AppText>
            <List spacing="sm" withPadding>
              {adminInvite.regAssociationAdminPermissions.map((p) => {
                const permission = REG_PERMISSION_LIST.find(
                  (f) => f.value === p.fkRegPermission
                );

                return (
                  <React.Fragment key={p.pkRegAssociationAdminPermission}>
                    <List.Item>{permission.label}</List.Item>
                    {p.regAssociationDivisions.length > 0 && (
                      <List spacing="sm" withPadding>
                        {p.regAssociationDivisions.map((d) => (
                          <List.Item key={d.pkRegAssociationDivision}>
                            <AppStack style={{ gap: 0 }}>
                              <AppText>{d.name}</AppText>
                              <AppText size="sm" style={{ color: 'grey' }}>
                                {d.regAssociationSeason.name}
                              </AppText>
                            </AppStack>
                          </List.Item>
                        ))}
                      </List>
                    )}
                  </React.Fragment>
                );
              })}
            </List>

            <Alert
              icon={<AlertCircle style={{ width: 35, height: 35 }} />}
              variant="outline"
            >
              <Text style={{ fontStyle: 'italic' }} weight={500}>
                Accepted invites may take up to 5 minutes to go into effect
              </Text>
            </Alert>
          </AppStack>
          <ConfirmModal
            confirmActionColor="blue"
            isOpen={formState.showConfirmation}
            message={
              <AppStack>
                <Alert
                  color="yellow"
                  icon={<AlertCircle />}
                  title="Warning"
                  variant="outline"
                >
                  <AppStack>
                    <AppText>
                      This invite was sent to <b>{adminInvite.inviteSentTo}</b>{' '}
                      but you are logged in as{' '}
                      <b>{authState.userData.user.email}</b>.
                    </AppText>
                    <AppText style={{ fontWeight: 500 }}>
                      Are you sure you want to accept this invite using this
                      account?
                    </AppText>
                  </AppStack>
                </Alert>
                <Alert color="yellow" icon={<AlertCircle />} variant="outline">
                  <AppStack>
                    <AppText style={{ fontWeight: 500 }}>
                      You will not be able to accept any other administrator
                      invites using the email <b>{adminInvite.inviteSentTo}</b>.
                      It will all be linked to{' '}
                      <b>{authState.userData.user.email}</b>.
                    </AppText>
                  </AppStack>
                </Alert>
              </AppStack>
            }
            onCancel={() => {
              setFormState({
                ...formState,
                showConfirmation: false
              });
            }}
            onConfirm={() => {
              respondToInvite(true);
            }}
            title="Confirm Acceptance"
          />
        </>
      )}
    </InviteCard>
  );
};

AdministratorInviteView.propTypes = { inviteCode: PropTypes.string };

export default AdministratorInviteView;
