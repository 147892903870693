import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate, Link, useLocation } from 'react-router-dom';
import { Button, Divider } from '@mantine/core';
import { Shield, User } from 'tabler-icons-react';
import HeaderView from '../components/common/HeaderView';
import AppCard from '../components/common/AppCard';
import AppFlexbox from '../components/common/AppFlexbox';
import AppStack from '../components/common/AppStack';
import AppText from '../components/common/AppText';
import PersonalInfoView from '../components/content/userSettings/PersonalInfoView';
import PasswordAndSecurityView from '../components/content/userSettings/PasswordAndSecurityView';
import { useMediaQueryIndex } from '../helpers/hooks';
import { getResponsiveStyle as rs } from '../helpers/styles';

const USER_SETTING_SECTIONS = [
  {
    title: 'Account Settings',
    value: 'account_settings',
    views: [
      {
        label: 'Personal Details',
        icon: <User size={30} />,
        path: '/settings/personal_info'
      },
      {
        label: 'Password and Security',
        icon: <Shield size={30} />,
        path: '/settings/password_and_security'
      }
    ]
  }
];

const UserSettingsView = () => {
  const mqIndex = useMediaQueryIndex();
  const { pathname } = useLocation();
  const [visibleMobileViewPath, setVisibleMobileViewPath] = useState(null);

  useEffect(() => {
    setVisibleMobileViewPath(null);
  }, []);

  return (
    <HeaderView>
      <AppCard style={{ flex: rs([1, 1, 1, 'unset'], mqIndex) }}>
        <AppFlexbox style={{ flex: 1 }}>
          {(!visibleMobileViewPath || mqIndex >= 3) && (
            <AppStack style={{ flex: 1, gap: 0 }}>
              <AppText style={{ fontSize: 20 }} weight={700}>
                SportsHeadz Settings
              </AppText>
              <AppText style={{ fontSize: 14, color: 'grey' }}>
                Manage your profile settings across SportsHeadz services like
                Registration, Brackets and SportsHeadz Mobile.
              </AppText>
              <Divider style={{ margin: '10px 0px' }} />
              {USER_SETTING_SECTIONS.map((section) => (
                <AppStack key={section.value} style={{ gap: 0 }}>
                  <AppText
                    style={{ fontSize: 18, marginBottom: 10 }}
                    weight={500}
                  >
                    {section.title}
                  </AppText>
                  {section.views.map((view) => {
                    const isSelected =
                      pathname.startsWith(view.path) && mqIndex >= 3;

                    return (
                      <Button
                        key={view.path}
                        color="gray"
                        component={Link}
                        justify="start"
                        leftSection={view.icon}
                        onClick={() => setVisibleMobileViewPath(view.path)}
                        size="lg"
                        style={{ color: isSelected ? 'black' : 'grey' }}
                        to={view.path}
                        variant={isSelected ? 'light' : 'subtle'}
                      >
                        {view.label}
                      </Button>
                    );
                  })}
                </AppStack>
              ))}
            </AppStack>
          )}
          <Divider orientation="vertical" visibleFrom="md" />

          <Routes>
            {(visibleMobileViewPath || mqIndex >= 3) && (
              <Route
                element={
                  <AppStack style={{ flex: 2 }}>
                    <Routes>
                      <Route
                        element={
                          <PersonalInfoView
                            onBack={() => setVisibleMobileViewPath(null)}
                          />
                        }
                        path="/personal_info"
                      />
                      <Route
                        element={
                          <PasswordAndSecurityView
                            onBack={() => setVisibleMobileViewPath(null)}
                          />
                        }
                        path="/password_and_security"
                      />
                      <Route
                        element={
                          <Navigate
                            replace
                            to={
                              visibleMobileViewPath || '/settings/personal_info'
                            }
                          />
                        }
                        path="*"
                      />
                    </Routes>
                  </AppStack>
                }
                path="/*"
              />
            )}
            <Route
              element={<Navigate replace to="/settings/personal_info" />}
              path="*"
            />
          </Routes>
        </AppFlexbox>
      </AppCard>
    </HeaderView>
  );
};

export default UserSettingsView;
