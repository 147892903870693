import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Skeleton, Tooltip } from '@mantine/core';
import { Link } from 'react-router-dom';
import { useHover } from '@mantine/hooks';
import AppText from '../../common/AppText';
import AppImage from '../../common/AppImage';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';

const AssociationListItem = ({ regAssociation }) => {
  const { hovered, ref } = useHover();

  return regAssociation ? (
    <AppFlexbox
      ref={ref}
      component={Link}
      style={{
        flex: 1,
        alignItems: 'center',
        padding: hovered ? 9 : 10,
        cursor: 'pointer',
        textDecoration: 'none',
        color: '#000',
        boxSizing: 'border-box',
        flexWrap: 'nowrap',
        border: hovered ? 'solid 1px #ced4da' : 'none'
      }}
      to={`/association/${regAssociation.pkRegAssociation}`}
    >
      <AppImage
        fit="contain"
        height={50}
        src={regAssociation.logoImageUrl}
        style={{ width: 50, height: 50 }}
        width={50}
      />
      <AppFlexbox
        style={{ flex: 1, overflow: 'hidden', justifyContent: 'space-between' }}
      >
        <AppStack
          style={{
            flex: 1,
            gap: 0,
            justifyContent: 'center',
            overflow: 'hidden'
          }}
        >
          <AppText style={{ fontSize: 18, lineHeight: '22px' }} weight={700}>
            {regAssociation.association.name}
          </AppText>
          <AppText
            style={{ fontSize: 16, lineHeight: '22px', whiteSpace: 'nowrap' }}
          >
            {regAssociation.association.domain}
          </AppText>
        </AppStack>
        {regAssociation.sandboxEnabled && (
          <Tooltip
            label="This association is only viewable by you. Turn off sandbox mode to enable public viewing."
            multiline
            width={250}
            withArrow
            withinPortal
          >
            <Badge color="yellow" variant="outline">
              Sandbox
            </Badge>
          </Tooltip>
        )}
      </AppFlexbox>
    </AppFlexbox>
  ) : (
    <AppFlexbox style={{ flex: 1, padding: 10 }}>
      <Skeleton height={50} width={50} />
      <AppStack style={{ flex: 1, gap: 5, justifyContent: 'center' }}>
        <Skeleton height={18} width={200} />
        <Skeleton height={16} width={200} />
      </AppStack>
    </AppFlexbox>
  );
};

AssociationListItem.propTypes = { regAssociation: PropTypes.object };

export default AssociationListItem;
