import React from 'react';
import { PropTypes } from 'prop-types';
import { Skeleton } from '@mantine/core';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import NumberFormat from 'react-number-format';
import { formatUtcDate } from '../../../helpers/format';
import AppCard from '../../common/AppCard';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import OrderListItemProductSummary from './OrderListItemProductSummary';
import { PAYMENT_PROVIDER_LINE_ITEM_TYPES } from '../../../config/constants';

const OrderListItem = ({ regCart }) => {
  const mqIndex = useMediaQueryIndex();

  const isPendingPayment = regCart?.regCartProducts.some(
    (p) => p.regFormSubmission.status === 'PENDING_PAYMENT'
  );
  const hasBeenRefunded = regCart?.cart.lineItems.some(
    (i) => i.refundedAmountInCents > 0
  );

  const totalCost = regCart?.cart.lineItems.reduce(
    (r, c) => r + c.totalInCents / 100,
    0
  );

  return regCart ? (
    <AppCard
      style={{
        padding: 0,
        minWidth: rs([null, null, 600], mqIndex),
        borderRadius: rs([0, 0, 4], mqIndex)
      }}
    >
      <AppStack style={{ gap: 0 }}>
        <AppFlexbox
          style={{
            backgroundColor: '#000',
            color: '#FFF',
            opacity: 0.8,
            padding: '15px 30px',
            justifyContent: 'space-between',
            alignItems: 'start',
            gap: 10,
            flexDirection: rs(['column-reverse', 'row'], mqIndex)
          }}
        >
          <AppFlexbox
            style={{
              gap: rs([10, 40], mqIndex),
              flex: rs([1, 'unset'], mqIndex),
              alignSelf: rs(['stretch', 'unset'], mqIndex),
              justifyContent: 'space-between',
              flexWrap: 'wrap'
            }}
          >
            <AppStack style={{ gap: 0 }}>
              <AppText>ORDER PLACED</AppText>
              <AppText style={{ fontSize: 18 }} weight={700}>
                {regCart?.cart.purchaseDate
                  ? dayjs(formatUtcDate(regCart?.cart.purchaseDate)).format(
                      'MMM D, YYYY'
                    )
                  : 'N/A'}
              </AppText>
            </AppStack>
            <AppStack
              style={{ gap: 0, alignItems: rs(['end', 'start'], mqIndex) }}
            >
              <AppText>TOTAL</AppText>
              <AppFlexbox style={{ gap: 10 }}>
                <AppText style={{ fontSize: 18 }} weight={700}>
                  <NumberFormat
                    decimalScale={2}
                    displayType="text"
                    fixedDecimalScale
                    prefix="$"
                    thousandSeparator
                    value={totalCost}
                  />
                </AppText>
                <AppText style={{ fontSize: 18 }} weight={700}>
                  {regCart.cart.currency?.toUpperCase()}
                </AppText>
              </AppFlexbox>
            </AppStack>
            <AppStack style={{ gap: 0 }}>
              <AppText>STATUS</AppText>
              <AppText style={{ fontSize: 18 }} weight={700}>
                {isPendingPayment
                  ? 'PENDING'
                  : hasBeenRefunded
                  ? 'REFUNDED'
                  : 'COMPLETED'}
              </AppText>
            </AppStack>
          </AppFlexbox>

          <AppStack
            style={{
              gap: 0,
              alignItems: 'end',
              alignSelf: 'stretch',
              justifyContent: 'space-between',
              flexDirection: rs(['row', 'column'], mqIndex)
            }}
          >
            <AppText weight={500}>ORDER # {regCart.pkRegCart}</AppText>
            <AppText
              component={Link}
              style={{
                fontSize: 18,
                textDecoration: 'none',
                color: 'dodgerblue',
                textAlign: 'end'
              }}
              to={`/orders/${regCart.pkRegCart}`}
              weight={700}
            >
              View order details
            </AppText>
          </AppStack>
        </AppFlexbox>

        <AppStack style={{ padding: '15px 30px', gap: 20 }}>
          {regCart.cart.lineItems.map((lineItem) => {
            const regCartProduct = regCart.regCartProducts.find(
              (p) =>
                p.regFormSubmission.pkRegFormSubmission === lineItem.entityId &&
                p.regProduct.pkRegProduct ===
                  p.regFormSubmission.regAssociationDivisionForm.fkRegProduct &&
                lineItem.lineItemType ===
                  PAYMENT_PROVIDER_LINE_ITEM_TYPES.registration.value
            );
            let regCartMerchPackage = null;
            if (!regCartProduct) {
              regCartMerchPackage = regCart.regCartMerchPackages.find(
                (p) =>
                  p.pkRegCartMerchPackage === lineItem.entityId &&
                  lineItem.lineItemType ===
                    PAYMENT_PROVIDER_LINE_ITEM_TYPES.merch.value
              );
            }

            const productInfo = regCartProduct
              ? {
                  image: regCartProduct.regProduct.regAssociation.logoImageUrl,
                  url: `/association/${regCartProduct.regProduct.regAssociation.pkRegAssociation}`,
                  productName:
                    regCartProduct.regFormSubmission.regAssociationDivisionForm
                      .titleOverride ||
                    regCartProduct.regFormSubmission.regAssociationDivisionForm
                      .regForm.name,
                  extraDescriptions: [
                    regCartProduct.regFormSubmission.regAssociationDivisionForm
                      .regAssociationDivision.name,
                    ...(regCartProduct.regFormSubmission
                      .regAssociationDivisionOverride
                      ? [
                          {
                            label: `Moved to: ${regCartProduct.regFormSubmission.regAssociationDivisionOverride.name}`,
                            weight: 500
                          }
                        ]
                      : [])
                  ]
                }
              : {
                  image:
                    regCartMerchPackage.merchPackage.merchPackageImages[0]
                      ?.image,
                  url: `/association/${regCartMerchPackage.regAssociation.pkRegAssociation}`,
                  productName: regCartMerchPackage.merchPackage.name,
                  extraDescriptions: regCartMerchPackage.merchPackage
                    .description
                    ? [regCartMerchPackage.merchPackage.description]
                    : []
                };

            return (
              <OrderListItemProductSummary
                key={`${lineItem.entityId}-${lineItem.lineItemType}`}
                lineItem={lineItem}
                {...productInfo}
              />
            );
          })}

          {regCart.cart.lineItems.length > 5 && (
            <AppText
              component={Link}
              style={{
                fontSize: 20,
                textDecoration: 'none',
                color: 'dodgerblue'
              }}
              to={`/orders/${regCart.pkRegCart}`}
              weight={500}
            >
              See All {regCart.cart.lineItems.length} items
            </AppText>
          )}
        </AppStack>
      </AppStack>
    </AppCard>
  ) : (
    <AppCard
      role="listitem"
      style={{
        padding: 0,
        minWidth: rs([null, null, 600], mqIndex),
        borderRadius: rs([0, 0, 4], mqIndex)
      }}
    >
      <AppStack style={{ gap: 0 }}>
        <AppFlexbox
          style={{
            backgroundColor: '#000',
            color: '#FFF',
            opacity: 0.8,
            padding: '15px 30px',
            justifyContent: 'space-between',
            alignItems: 'start',
            gap: 10,
            flexDirection: rs(['column-reverse', 'row'], mqIndex)
          }}
        >
          <AppFlexbox
            style={{
              gap: rs([10, 40], mqIndex),
              flex: rs([1, 'unset'], mqIndex),
              alignSelf: rs(['stretch', 'unset'], mqIndex),
              justifyContent: 'space-between'
            }}
          >
            <AppStack style={{ gap: 10 }}>
              <Skeleton height={14} width={150} />
              <Skeleton height={16} width={150} />
            </AppStack>
            <AppStack
              style={{ gap: 10, alignItems: rs(['end', 'start'], mqIndex) }}
            >
              <Skeleton height={14} width={90} />

              <Skeleton height={16} width={120} />
            </AppStack>
          </AppFlexbox>

          <AppStack
            style={{
              gap: 10,
              alignItems: 'end',
              alignSelf: 'stretch',
              justifyContent: 'space-between',
              flexDirection: rs(['row', 'column'], mqIndex)
            }}
          >
            <Skeleton height={18} width={150} />
            <Skeleton height={20} width={200} />
          </AppStack>
        </AppFlexbox>

        <AppStack style={{ padding: '15px 30px', gap: 20 }}>
          <AppFlexbox
            style={{
              flexWrap: 'nowrap',
              flexDirection: rs(['column', 'column', 'column', 'row'], mqIndex)
            }}
          >
            <AppFlexbox
              style={{ flexWrap: 'nowrap', flex: 2, alignSelf: 'stretch' }}
            >
              <Skeleton height={100} width={100} />
              <AppStack
                style={{
                  flex: 1,
                  gap: 10,
                  textDecoration: 'none',
                  color: '#000'
                }}
              >
                <Skeleton height={12} width="50%" />
                <Skeleton height={10} width="40%" />
              </AppStack>
            </AppFlexbox>

            <AppStack
              style={{
                flex: 1,
                alignSelf: 'start',
                placeItems: 'end',
                marginLeft: 'auto',
                gap: rs([0, 30], mqIndex),
                flexDirection: rs(
                  ['row-reverse', 'row-reverse', 'row-reverse', 'column'],
                  mqIndex
                ),
                justifyContent: rs(
                  ['space-between', 'space-between', 'space-between', 'inital'],
                  mqIndex
                ),
                width: rs(['100%', '100%', '100%', 'unset'], mqIndex)
              }}
            >
              <AppStack style={{ gap: 0, alignItems: 'end' }}>
                <Skeleton height={20} width={100} />
              </AppStack>
            </AppStack>
          </AppFlexbox>
          <AppFlexbox
            style={{
              flexWrap: 'nowrap',
              flexDirection: rs(['column', 'column', 'column', 'row'], mqIndex)
            }}
          >
            <AppFlexbox
              style={{ flexWrap: 'nowrap', flex: 2, alignSelf: 'stretch' }}
            >
              <Skeleton height={100} width={100} />
              <AppStack
                style={{
                  flex: 1,
                  gap: 10,
                  textDecoration: 'none',
                  color: '#000'
                }}
              >
                <Skeleton height={12} width="50%" />
                <Skeleton height={10} width="40%" />
              </AppStack>
            </AppFlexbox>

            <AppStack
              style={{
                flex: 1,
                alignSelf: 'start',
                placeItems: 'end',
                marginLeft: 'auto',
                gap: rs([0, 30], mqIndex),
                flexDirection: rs(
                  ['row-reverse', 'row-reverse', 'row-reverse', 'column'],
                  mqIndex
                ),
                justifyContent: rs(
                  ['space-between', 'space-between', 'space-between', 'inital'],
                  mqIndex
                ),
                width: rs(['100%', '100%', '100%', 'unset'], mqIndex)
              }}
            >
              <AppStack style={{ gap: 0, alignItems: 'end' }}>
                <Skeleton height={20} width={100} />
              </AppStack>
            </AppStack>
          </AppFlexbox>
        </AppStack>
      </AppStack>
    </AppCard>
  );
};

OrderListItem.propTypes = { regCart: PropTypes.object };

export default OrderListItem;
