import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from '@mantine/core';
import AppStack from './AppStack';
import AppFlexbox from './AppFlexbox';
import AppText from './AppText';

const PaginationList = ({
  isLoading,
  items,
  LoadingComponent,
  loadingComponentCount,
  itemsPerPage,
  emptyMessage,
  pageIndex,
  onPageChange,
  noBorder,
  listProps,
  bottomAction,
  ...rest
}) => {
  const MAX_PAGE_LENGTH = itemsPerPage ?? 10;
  const [pageIndexState, setPageIndexState] = useState(1);
  const index = pageIndex ?? pageIndexState;
  const showPagination = !isLoading && items.length > MAX_PAGE_LENGTH;

  useEffect(() => {
    setPageIndexState(1);
  }, [items]);

  return (
    <AppStack
      {...rest}
      style={{
        flex: 1,
        gap: 10,
        alignSelf: 'stretch',
        ...rest.style
      }}
    >
      <AppStack
        {...listProps}
        style={{
          gap: 0,
          border: noBorder ? null : 'solid 1px #ced4da',
          borderBottom: 'none',
          flex: 1,
          ...listProps?.style
        }}
      >
        {isLoading ? (
          <>
            {[...Array(loadingComponentCount ?? 4)].map((_, i) => (
              <AppStack
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                style={{ borderBottom: noBorder ? null : 'solid 1px #ced4da' }}
              >
                <LoadingComponent />
              </AppStack>
            ))}
          </>
        ) : items.length === 0 ? (
          <AppFlexbox
            style={{
              gap: 20,
              flex: 1,
              padding: '50px 10px',
              borderBottom: noBorder ? null : 'solid 1px #ced4da'
            }}
          >
            <AppText style={{ margin: 'auto' }}>
              {emptyMessage ?? 'No Items available'}
            </AppText>
          </AppFlexbox>
        ) : (
          items
            .slice(
              -MAX_PAGE_LENGTH + index * MAX_PAGE_LENGTH,
              index * MAX_PAGE_LENGTH
            )
            .map((r) => (
              <AppStack
                key={r.key}
                style={{ borderBottom: noBorder ? null : 'solid 1px #ced4da' }}
              >
                {r}
              </AppStack>
            ))
        )}
      </AppStack>
      {(bottomAction || showPagination) && (
        <AppFlexbox
          style={{ justifyContent: bottomAction ? 'space-between' : 'center' }}
        >
          {showPagination && (
            <Pagination
              color="blue"
              onChange={(page) => {
                if (onPageChange) {
                  onPageChange(page);
                }
                else {
                  setPageIndexState(page);
                }
              }}
              total={Math.ceil(items.length / MAX_PAGE_LENGTH)}
              value={index}
            />
          )}

          {bottomAction}
        </AppFlexbox>
      )}
    </AppStack>
  );
};

PaginationList.propTypes = {
  LoadingComponent: PropTypes.any,
  bottomAction: PropTypes.node,
  emptyMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  items: PropTypes.array,
  itemsPerPage: PropTypes.number,
  listProps: PropTypes.object,
  loadingComponentCount: PropTypes.number,
  noBorder: PropTypes.bool,
  onPageChange: PropTypes.func,
  pageIndex: PropTypes.number
};

export default PaginationList;
