import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, Divider } from '@mantine/core';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { InfoCircle, Logout } from 'tabler-icons-react';
import NumberFormat from 'react-number-format';
import { Context as AuthContext } from '../../../providers/AuthProvider';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import { triggerNotification } from '../../../helpers/notificationHelper';
import AppTitle from '../../common/AppTitle';
import HeaderView from '../../common/HeaderView';
import LoadableView from '../../common/LoadableView';
import AppCard from '../../common/AppCard';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import {
  CURRENCY_TYPE_LIST,
  REG_FORM_SUBMISSION_INVOICE_STATUS_ENUM,
  REG_FORM_SUBMISSION_INVOICE_STATUS_LIST
} from '../../../config/constants';

const CheckoutInvoices = () => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const searchParams = new URLSearchParams(search.toLowerCase());
  const secureId = searchParams.get('secureid');
  const [formState, setFormState] = useState({ isLoading: false });
  const [loadingSecureId, setLoadingSecureId] = useState(false);
  const [errorView, setErrorView] = useState(false);
  const { invoiceId } = useParams();
  const { state: authState, logout } = useContext(AuthContext);
  const {
    state,
    fetchRegFormSubmissionInvoice,
    checkoutInvoice,
    fetchCheckoutInvoiceResult
  } = useContext(RegistrationContext);
  const isLoading =
    !formState.hasInitialized ||
    state.regFormSubmissionInvoice.loading ||
    !state.regFormSubmissionInvoice.value ||
    loadingSecureId;
  const invoicePayment = state.regFormSubmissionInvoice.value;

  const currencyAbbr = CURRENCY_TYPE_LIST.find(
    (c) =>
      c.value.toString() ===
      invoicePayment?.regAssociation.regAssociationPaymentProvider?.fkCurrencyType?.toString()
  )?.abbr;

  const incorrectUserSignin =
    authState.userData.user.email !==
    invoicePayment?.regFormSubmission.user.email;

  useEffect(() => {
    if (invoiceId) {
      fetchRegFormSubmissionInvoice(invoiceId, null, (error, type, data) => {
        if (data.status === 404) {
          setErrorView('not_found');
        }
        else {
          setErrorView('error');
        }
      });
      setFormState({ hasInitialized: false, isLoading: false });
    }
  }, [invoiceId]);

  useEffect(() => {
    if (invoicePayment) {
      if (secureId) {
        setLoadingSecureId(true);
        fetchCheckoutInvoiceResult(
          { invoiceId, secureId },
          (result) => {
            triggerNotification('Invoice Paid', 'Success', 'green');
            navigate('/');
          },
          (e) => {
            triggerNotification(e);
            navigate(pathname);
          }
        );
      }
      setFormState({ hasInitialized: true, isLoading: false });
    }
  }, [invoicePayment]);

  return (
    <LoadableView isLoading={!errorView && isLoading}>
      <HeaderView>
        {errorView ? (
          <AppCard style={{ maxWidth: 500, margin: 'auto', width: '100%' }}>
            <AppStack style={{ gap: 15 }}>
              {errorView === 'not_found' ? (
                <>
                  <AppTitle order={3}>Invoice not found</AppTitle>
                  <AppStack style={{ gap: 20 }}>
                    <Alert
                      color="red"
                      icon={<InfoCircle style={{ width: 35, height: 35 }} />}
                      variant="outline"
                    >
                      <AppStack style={{ gap: 8 }}>
                        <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                          The invoice you're trying to pay couldn't be found.
                        </AppText>
                        <AppText style={{ fontSize: 14, color: '#666' }}>
                          Please check the URL and try again, or contact the
                          association that sent you the invoice for further
                          assistance.
                        </AppText>
                      </AppStack>
                    </Alert>
                  </AppStack>
                </>
              ) : (
                <>
                  <AppTitle order={3}>Error loading invoice</AppTitle>
                  <AppStack style={{ gap: 20 }}>
                    <Alert
                      color="red"
                      icon={<InfoCircle style={{ width: 35, height: 35 }} />}
                      variant="outline"
                    >
                      <AppStack style={{ gap: 8 }}>
                        <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                          An error occurred while retrieving the invoice.
                        </AppText>
                        <AppText style={{ fontSize: 14, color: '#666' }}>
                          Please try again later.
                        </AppText>
                      </AppStack>
                    </Alert>
                  </AppStack>
                </>
              )}
            </AppStack>
          </AppCard>
        ) : (
          !isLoading && (
            <AppCard style={{ maxWidth: 500, margin: 'auto', width: '100%' }}>
              {incorrectUserSignin ? (
                <AppStack style={{ gap: 15 }}>
                  <AppTitle order={3}>Incorrect account</AppTitle>
                  <AppStack style={{ gap: 20 }}>
                    <Alert
                      color="red"
                      icon={<InfoCircle style={{ width: 35, height: 35 }} />}
                      variant="outline"
                    >
                      <AppStack style={{ gap: 8 }}>
                        <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                          The invoice you're attempting to pay is linked to a
                          different user account.{' '}
                        </AppText>
                        <AppText style={{ fontSize: 14, color: '#666' }}>
                          Please sign in with the correct account to continue.
                        </AppText>

                        <AppFlexbox style={{ marginTop: 5 }}>
                          <Button
                            color="dark"
                            leftSection={
                              <Logout style={{ transform: 'rotate(180deg)' }} />
                            }
                            onClick={() => {
                              logout();
                            }}
                            variant="filled"
                          >
                            Switch login
                          </Button>
                        </AppFlexbox>
                      </AppStack>
                    </Alert>
                  </AppStack>
                </AppStack>
              ) : invoicePayment.regFormSubmissionInvoice.status !==
                REG_FORM_SUBMISSION_INVOICE_STATUS_ENUM.PENDING ? (
                <AppStack style={{ gap: 15 }}>
                  <AppTitle order={3}>Invoice not available</AppTitle>
                  <AppStack style={{ gap: 20 }}>
                    <Alert
                      color="red"
                      icon={<InfoCircle style={{ width: 35, height: 35 }} />}
                      variant="outline"
                    >
                      <AppStack style={{ gap: 8 }}>
                        <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                          The invoice you're trying to pay is no longer
                          available.
                        </AppText>
                        <AppText style={{ fontSize: 14 }}>
                          Status:{' '}
                          <span
                            style={{
                              fontWeight: 500,
                              color: REG_FORM_SUBMISSION_INVOICE_STATUS_LIST.find(
                                (f) =>
                                  f.value ===
                                  invoicePayment.regFormSubmissionInvoice.status
                              )?.color
                            }}
                          >
                            {
                              REG_FORM_SUBMISSION_INVOICE_STATUS_LIST.find(
                                (f) =>
                                  f.value ===
                                  invoicePayment.regFormSubmissionInvoice.status
                              )?.label
                            }
                          </span>
                        </AppText>
                      </AppStack>
                    </Alert>
                  </AppStack>
                </AppStack>
              ) : (
                <AppStack style={{ gap: 15 }}>
                  <AppTitle order={3}>Invoice Pending</AppTitle>
                  <AppStack style={{ gap: 20 }}>
                    <Alert
                      color="blue"
                      icon={<InfoCircle style={{ width: 35, height: 35 }} />}
                      variant="outline"
                    >
                      <AppText>
                        You have an invoice from{' '}
                        <b>{invoicePayment.regAssociation.association.name}</b>{' '}
                        thats pending payment.
                      </AppText>
                    </Alert>
                    <Divider />

                    <AppFlexbox
                      style={{ flexWrap: 'nowrap', gap: 10, marginTop: 10 }}
                    >
                      <AppText style={{ fontSize: 16 }} weight={500}>
                        {invoicePayment.regFormSubmissionInvoice.description}
                      </AppText>
                      <Divider style={{ flex: 1 }} variant="dotted" />
                      <AppText style={{ fontSize: 16 }}>
                        <NumberFormat
                          decimalScale={2}
                          displayType="text"
                          fixedDecimalScale
                          prefix="$"
                          thousandSeparator
                          value={invoicePayment.amount}
                        />{' '}
                        {currencyAbbr}
                      </AppText>
                    </AppFlexbox>

                    <Divider />

                    <AppFlexbox style={{ justifyContent: 'center' }}>
                      <Button
                        color="blue"
                        loading={formState.isLoading}
                        onClick={() => {
                          setFormState({
                            ...formState,
                            isLoading: true
                          });
                          checkoutInvoice(
                            invoicePayment.regFormSubmissionInvoice
                              .pkRegFormSubmissionInvoice,
                            (sessionUrl) => {
                              if (sessionUrl) {
                                window.location.href = sessionUrl;
                              }
                            },
                            (e) => {
                              triggerNotification(e);
                              setFormState({
                                ...formState,
                                isLoading: false
                              });
                            }
                          );
                        }}
                        style={{ flex: 1, maxWidth: 250 }}
                      >
                        Pay Now
                      </Button>
                    </AppFlexbox>
                  </AppStack>
                </AppStack>
              )}
            </AppCard>
          )
        )}
      </HeaderView>
    </LoadableView>
  );
};

export default CheckoutInvoices;
