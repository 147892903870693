import React, { useEffect, useState } from 'react';
import { Accordion, Button, Divider, Skeleton, Switch } from '@mantine/core';
import PropTypes from 'prop-types';
import { DeviceFloppy } from 'tabler-icons-react';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import EvaluationTemplateQuestion from './EvaluationTemplateQuestion';
import EvaluationSessionEvaluateMenuList from './EvaluationSessionEvaluateMenuList';
import EvaluationSessionEvaluateMenuNav from './EvaluationSessionEvaluateMenuNav';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { validateEvaluationQuestionRestrictions } from '../../../helpers/evaluationPlayerHelper';

const EVALUATION_SESSION_VIEWS_ENUM = {
  player: 'SCORE_BY_PLAYER',
  criterion: 'SCORE_BY_CRITERION',
  grid: 'SCORING_GRID'
};

const EVALUATION_SESSION_VIEWS = [
  {
    label: 'SCORE BY PLAYER',
    value: EVALUATION_SESSION_VIEWS_ENUM.player
  },
  {
    label: 'SCORE BY CRITERION',
    value: EVALUATION_SESSION_VIEWS_ENUM.criterion
  }
];

const EvaluationSessionEvaluatePlayersView = ({
  regAssociationDivision,
  regScoutReports,
  regScoutReportQuestions,
  onSelectEvaluation,
  onSelectQuestion,
  evaluationSessionEntry,
  regScoutReportEvaluations,
  selectedRegScoutReportEvaluation,
  selectedRegScoutReportQuestion,
  onUpdateEvaluationAnswer,
  currentView,
  onViewChange,
  onSaveChanges,
  hasUnsavedChanges,
  isLoading,
  isSaving,
  filter,
  setFilter,
  isSmallScreen
}) => {
  const mqIndex = useMediaQueryIndex();
  const isMobileView = mqIndex === 0;
  const isTabletView = mqIndex <= 1;
  const isMediumView = mqIndex <= 2;
  const [scoringState, setScoringState] = useState({
    expandedReports:
      regScoutReports?.map((r) => r.pkRegScoutReport.toString()) || [],
    expandAll: false
  });

  useEffect(() => {
    if (!isLoading) {
      setScoringState({
        expandedReports: regScoutReports.map((r) =>
          r.pkRegScoutReport.toString()
        ),
        expandAll: false
      });
    }
  }, [isLoading]);

  return !isLoading ? (
    <>
      <AppStack
        style={{
          flex: 1,
          minWidth: 450,
          maxWidth: 600,
          overflow: 'auto',
          padding: 10
        }}
        visibleFrom="md"
      >
        <EvaluationSessionEvaluateMenuNav
          currentView={currentView}
          evaluationSessionEntry={evaluationSessionEntry}
          filter={filter}
          hasUnsavedChanges={hasUnsavedChanges}
          isLoading={isLoading}
          isSaving={isSaving}
          onSaveChanges={onSaveChanges}
          onSelectEvaluation={onSelectEvaluation}
          onSelectQuestion={onSelectQuestion}
          onViewChange={onViewChange}
          regAssociationDivision={regAssociationDivision}
          regScoutReportEvaluations={regScoutReportEvaluations}
          regScoutReportQuestions={regScoutReportQuestions}
          regScoutReports={regScoutReports}
          selectedRegScoutReportEvaluation={selectedRegScoutReportEvaluation}
          selectedRegScoutReportQuestion={selectedRegScoutReportQuestion}
          setFilter={setFilter}
        />
      </AppStack>
      <AppStack
        style={{
          gap: 0,
          padding: 10,
          paddingBottom: 60,
          flex: 3,
          overflow: 'auto'
        }}
      >
        <AppFlexbox style={{ justifyContent: 'space-between' }}>
          <AppText style={{ fontSize: 18, fontWeight: 500 }}>Scoring</AppText>
          <Button
            color="dark"
            onClick={() => onViewChange(EVALUATION_SESSION_VIEWS_ENUM.grid)}
            size="compact-md"
            variant="outline"
            visibleFrom="md"
          >
            SCORING GRID
          </Button>
        </AppFlexbox>

        <AppStack style={{ gap: 10 }}>
          <AppFlexbox>
            <Switch
              checked={scoringState.expandAll}
              label="EXPAND"
              onChange={() => {
                if (scoringState.expandAll) {
                  setScoringState({
                    expandedReports: [],
                    expandAll: false
                  });
                }
                else {
                  setScoringState({
                    expandedReports: regScoutReports.map((r) =>
                      r.pkRegScoutReport.toString()
                    ),
                    expandAll: true
                  });
                }
              }}
            />
          </AppFlexbox>

          {regScoutReports && (
            <Accordion
              defaultValue={regScoutReports.map((r) =>
                r.pkRegScoutReport.toString()
              )}
              multiple
              onChange={(values) =>
                setScoringState({
                  ...scoringState,
                  expandedReports: values
                })
              }
              styles={{
                item: { border: 'none', marginBottom: 10 },
                control: { padding: 0, paddingBottom: 10 },
                label: { padding: 0 },
                content: { padding: 0 }
              }}
              value={scoringState.expandedReports}
            >
              {regScoutReports
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((r, i) => (
                  <Accordion.Item
                    key={r.pkRegScoutReport}
                    value={r.pkRegScoutReport.toString()}
                  >
                    <Accordion.Control>
                      <AppFlexbox
                        style={{
                          justifyContent: 'space-between',
                          minHeight: 30,
                          alignItems: 'center',
                          marginRight: 10
                        }}
                      >
                        <AppText
                          style={{
                            fontSize: 18,
                            fontWeight: 500,
                            textWrap: 'nowrap'
                          }}
                        >
                          {r.name}
                        </AppText>
                        {i === 0 && hasUnsavedChanges && (
                          <Button
                            color="blue"
                            loading={isSaving}
                            onClick={(e) => {
                              e.stopPropagation();
                              onSaveChanges();
                            }}
                            rightSection={!isTabletView && <DeviceFloppy />}
                            size="compact-md"
                            style={{ minWidth: 75 }}
                            variant="filled"
                          >
                            {isMobileView ? 'Save' : 'Save Changes'}
                          </Button>
                        )}
                      </AppFlexbox>
                    </Accordion.Control>
                    <Accordion.Panel>
                      <AppStack style={{ gap: 10 }}>
                        {selectedRegScoutReportEvaluation ? (
                          regScoutReportQuestions
                            .filter(
                              (q) =>
                                q.fkRegScoutReport === r.pkRegScoutReport &&
                                validateEvaluationQuestionRestrictions(
                                  selectedRegScoutReportEvaluation,
                                  q
                                )
                            )
                            .sort((a, b) => a.sort - b.sort)
                            .map((q) => (
                              <EvaluationTemplateQuestion
                                key={q.pkRegScoutReportQuestion}
                                evaluation={selectedRegScoutReportEvaluation}
                                evaluationSessionEntry={evaluationSessionEntry}
                                id={`question-card-${q.pkRegScoutReportQuestion}`}
                                isSelected={
                                  selectedRegScoutReportQuestion?.pkRegScoutReportQuestion ===
                                  q.pkRegScoutReportQuestion
                                }
                                isSmallScreen={isSmallScreen}
                                onUpdateEvaluationAnswer={
                                  onUpdateEvaluationAnswer
                                }
                                question={q}
                              />
                            ))
                        ) : i === 0 ? (
                          <>
                            <AppStack style={{ padding: 30 }}>
                              <AppText
                                style={{
                                  textAlign: 'center',
                                  fontWeight: 500
                                }}
                              >
                                Select a player to begin evaluating
                              </AppText>
                            </AppStack>
                            {regScoutReports.length > 1 && <Divider />}
                          </>
                        ) : (
                          <Divider />
                        )}
                      </AppStack>
                    </Accordion.Panel>
                  </Accordion.Item>
                ))}
            </Accordion>
          )}
        </AppStack>
      </AppStack>
    </>
  ) : (
    <>
      <AppStack
        style={{
          flex: 1,
          minWidth: 400,
          maxWidth: 600,
          overflow: 'auto',
          padding: 10
        }}
        visibleFrom="md"
      >
        <AppFlexbox style={{ gap: 10 }}>
          {EVALUATION_SESSION_VIEWS.map((v) => (
            <Skeleton key={v.value} height={28} width="100%" />
          ))}
        </AppFlexbox>

        <Divider />

        <AppFlexbox style={{ justifyContent: 'space-between' }}>
          <AppStack>
            <Skeleton height={20} width={150} />
            <Skeleton height={20} width={150} />
          </AppStack>

          <AppStack>
            <Skeleton height={20} width={150} />
            <Skeleton height={20} width={150} />
          </AppStack>
        </AppFlexbox>
        <Skeleton height={36} width="100%" />

        <AppFlexbox
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            minHeight: 30
          }}
        >
          <Skeleton height={30} width="25%" />
        </AppFlexbox>

        <EvaluationSessionEvaluateMenuList isLoading />
      </AppStack>
      <AppStack
        style={{
          gap: 0,
          padding: 10,
          paddingBottom: 60,
          flex: 3,
          overflow: 'auto'
        }}
      >
        <AppFlexbox style={{ justifyContent: 'space-between' }}>
          <Skeleton height={20} width={75} />
          <Skeleton height={28} width={110} />
        </AppFlexbox>

        <AppStack style={{ gap: 5 }}>
          <AppFlexbox>
            <Skeleton height={20} width={150} />
          </AppFlexbox>
          <AppFlexbox>
            <Skeleton height={20} width={150} />
          </AppFlexbox>
        </AppStack>
      </AppStack>
    </>
  );
};

EvaluationSessionEvaluatePlayersView.propTypes = {
  currentView: PropTypes.string,
  evaluationSessionEntry: PropTypes.object,
  filter: PropTypes.object,
  hasUnsavedChanges: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSaving: PropTypes.bool,
  isSmallScreen: PropTypes.bool,
  onSaveChanges: PropTypes.func,
  onSelectEvaluation: PropTypes.func,
  onSelectQuestion: PropTypes.func,
  onUpdateEvaluationAnswer: PropTypes.func,
  onViewChange: PropTypes.func,
  regAssociationDivision: PropTypes.object,
  regScoutReportEvaluations: PropTypes.array,
  regScoutReportQuestions: PropTypes.array,
  regScoutReports: PropTypes.array,
  selectedRegScoutReportEvaluation: PropTypes.object,
  selectedRegScoutReportQuestion: PropTypes.object,
  setFilter: PropTypes.func
};

export default EvaluationSessionEvaluatePlayersView;
