import React, { useEffect } from 'react';
import { Badge, Button, Table } from '@mantine/core';
import PropTypes from 'prop-types';
import { X } from 'tabler-icons-react';
import { S3_PUBLIC_URL } from '../../../config/constants';
import ReportTableHeader from './ReportTableHeader';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';

const ReportTable = ({
  reportHeaders,
  reportRows,
  disableSort,
  filterState,
  setFilterState
}) => {
  const distantFutureDate = new Date(8640000000000000);
  const filteredRows = reportRows.filter((f) =>
    filterState.filters.every((e) => e.condition(f.columns[e.key].sortValue))
  );

  useEffect(() => {
    setFilterState({
      filters: [],
      orderByHeaderKey: reportHeaders[0]?.key,
      descendingSort: false,
      // eslint-disable-next-line no-use-before-define
      sortCondition: getSortCondition(reportHeaders[0]?.dataType)
    });
  }, []);

  const sortRowsByDate = (a, b, index = 0, descendingSort = false) => {
    const valueA = a.columns[index].sortValue
      ? new Date(a.columns[index].sortValue)
      : distantFutureDate;
    const valueB = b.columns[index].sortValue
      ? new Date(b.columns[index].sortValue)
      : distantFutureDate;
    return descendingSort ? valueB - valueA : valueA - valueB;
  };

  const sortRowsByNumber = (a, b, index = 0, descendingSort = false) =>
    descendingSort
      ? b.columns[index].sortValue - a.columns[index].sortValue
      : a.columns[index].sortValue - b.columns[index].sortValue;

  const sortRowsByString = (a, b, index = 0, descendingSort = false) => {
    const valueA =
      a.columns[index].sortValue === 'N/A' ? '' : a.columns[index].sortValue;
    const valueB =
      b.columns[index].sortValue === 'N/A' ? '' : b.columns[index].sortValue;
    return descendingSort
      ? valueB.localeCompare(valueA)
      : valueA.localeCompare(valueB);
  };

  const getSortCondition = (dataType, index, descendingSort) =>
    dataType === 'date'
      ? (a, b) => sortRowsByDate(a, b, index, descendingSort)
      : dataType === 'number'
      ? (a, b) => sortRowsByNumber(a, b, index, descendingSort)
      : (a, b) => sortRowsByString(a, b, index, descendingSort);

  const onChangeSortBy = (orderByHeaderKey, descendingSort, dataType) => {
    setFilterState({
      ...filterState,
      orderByHeaderKey,
      descendingSort,
      sortCondition: getSortCondition(
        dataType,
        orderByHeaderKey,
        descendingSort
      )
    });
  };

  return (
    <AppStack
      style={{
        gap: 0,
        flex: 1,
        height: '100%'
      }}
    >
      {filterState.filters.length > 0 && (
        <AppStack
          style={{
            borderTop: 'solid 1px #dee2e6',
            padding: '10px 20px',
            gap: 5
          }}
        >
          <AppFlexbox
            style={{
              flex: 1,
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <AppText weight={500}>Filters</AppText>
            <Button
              onClick={() =>
                setFilterState({
                  ...filterState,
                  filters: []
                })
              }
              size="compact-md"
              variant="outline"
            >
              Clear Filters
            </Button>
          </AppFlexbox>

          <AppFlexbox style={{ gap: 5, overflow: 'auto' }}>
            {filterState.filters.map((f) => (
              <React.Fragment key={f.key}>
                {f.labels.map((label) => (
                  <Badge
                    key={label.value}
                    rightSection={
                      <X
                        onClick={() => {
                          setFilterState({
                            ...filterState,
                            filters: filterState.filters.filter(
                              (filter) => filter.key !== f.key
                            )
                          });
                        }}
                        size={18}
                        style={{ cursor: 'pointer' }}
                      />
                    }
                    size="md"
                    style={{ textTransform: 'none', overflow: 'visible' }}
                    variant="outline"
                  >
                    {label.value}
                  </Badge>
                ))}
              </React.Fragment>
            ))}
          </AppFlexbox>
        </AppStack>
      )}
      <AppStack
        style={{ overflow: 'auto', textWrap: 'nowrap', flex: 1, gap: 0 }}
      >
        <Table
          horizontalSpacing="sm"
          striped={!disableSort}
          verticalSpacing="xs"
        >
          <Table.Thead
            style={{
              position: 'sticky',
              top: 0,
              backgroundColor: '#FFF',
              zIndex: 99,
              boxShadow: 'inset 0 1px 0 #DEE2E6, inset 0 -1px 0 #DEE2E6'
            }}
          >
            <Table.Tr
              style={{
                fontWeight: 'bold',
                color: '#495057',
                borderBottom: 'solid 1px #dee2e6'
              }}
            >
              {!disableSort && (
                <Table.Th style={{ border: 'none', textAlign: 'center' }}>
                  <b>#</b>
                </Table.Th>
              )}

              {reportHeaders.map((h) => (
                <ReportTableHeader
                  key={h.key}
                  filters={filterState.filters}
                  header={h}
                  isOrderByDescending={filterState.descendingSort}
                  isSelected={filterState.orderByHeaderKey === h.key}
                  onChangeSortBy={
                    disableSort
                      ? null
                      : () => {
                          onChangeSortBy(
                            h.key,
                            filterState.orderByHeaderKey === h.key
                              ? !filterState.descendingSort
                              : false,
                            h.dataType
                          );
                        }
                  }
                  onFilterChange={(newFilters) =>
                    setFilterState({
                      ...filterState,
                      filters: newFilters
                    })
                  }
                />
              ))}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {(disableSort
              ? filteredRows
              : filteredRows.sort(filterState.sortCondition || sortRowsByString)
            ).map((r, index) => (
              <React.Fragment key={r.key}>
                {r.topRowSpacing > 0 &&
                  [...Array(r.topRowSpacing)].map((a, i) => (
                    <Table.Tr
                      // eslint-disable-next-line react/no-array-index-key
                      key={i}
                      style={{ borderBottom: 'solid 1px #dee2e6' }}
                    >
                      {reportHeaders.map((h) => (
                        <Table.Td key={h.key} style={{ height: 40 }} />
                      ))}
                    </Table.Tr>
                  ))}
                <Table.Tr style={{ borderBottom: 'solid 1px #dee2e6' }}>
                  {!disableSort && (
                    <Table.Td style={{ textAlign: 'center' }}>
                      {index + 1}
                    </Table.Td>
                  )}
                  {r.columns.map((c, i) => (
                    <Table.Td
                      // eslint-disable-next-line react/no-array-index-key
                      key={i}
                      style={{
                        border: 'none',
                        wordWrap: 'break-word',
                        fontSize: 14,
                        color: c.color ?? '#000',
                        fontWeight: c.fontWeight ?? 'normal'
                      }}
                    >
                      {c.value?.startsWith(S3_PUBLIC_URL) ? (
                        <a href={c.value} rel="noreferrer" target="_blank">
                          Download
                        </a>
                      ) : (
                        !c.hidden && c.value
                      )}
                    </Table.Td>
                  ))}
                </Table.Tr>
              </React.Fragment>
            ))}
          </Table.Tbody>
        </Table>
      </AppStack>
    </AppStack>
  );
};

ReportTable.propTypes = {
  disableSort: PropTypes.bool,
  filterState: PropTypes.object,
  reportHeaders: PropTypes.array,
  reportRows: PropTypes.array,
  setFilterState: PropTypes.func
};

export default ReportTable;
