import React, { useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Alert,
  Button,
  Checkbox,
  Divider,
  Radio,
  TextInput
} from '@mantine/core';
import NumberFormat from 'react-number-format';
import { AlertCircle, InfoCircle } from 'tabler-icons-react';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { OFFLINE_PAYMENT_TYPES } from '../../../config/constants';
import FormSection from '../../common/FormSection';
import { triggerNotification } from '../../../helpers/notificationHelper';
import PaginationList from '../../common/PaginationList';
import RegistrationPaymentInvoiceListItem from './RegistrationPaymentInvoiceListItem';
import { currencyFormat } from '../../../helpers/format';
import { calculateRegFormSubmissionBalanceTotals } from '../../../helpers/paymentHelper';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppTitle from '../../common/AppTitle';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import AppRadioGroup from '../../common/AppRadioGroup';

const FinancialRegistrationTransactionModal = ({
  isOpen,
  onClose,
  regFormSubmissionBalance,
  isRefund
}) => {
  const mqIndex = useMediaQueryIndex();
  const {
    state,
    createAdminRegFormSubmissionPayment,
    fetchUpdatedAdminFinancialRegistration
  } = useContext(RegistrationAdminContext);
  const [formState, setFormState] = useState({
    fkRegFormSubmissionPaymentType: OFFLINE_PAYMENT_TYPES[0].value,
    amount: null,
    note: '',
    fkRegFormSubmissionInvoice: '',
    skipEmailNotice: false,
    showConfirm: false,
    isInvoicePayment: false,
    showInvoiceOptions: false,
    loading: false
  });
  const isForgivenessPayment = formState.fkRegFormSubmissionPaymentType === '9';

  const balanceTotals = regFormSubmissionBalance
    ? calculateRegFormSubmissionBalanceTotals(regFormSubmissionBalance)
    : {};

  const transactionTotal =
    balanceTotals.registrationCost +
    balanceTotals.invoiceCost -
    balanceTotals.invoiceAmountRefunded;
  const totalPayments =
    balanceTotals.registrationAmountPaid +
    balanceTotals.invoiceAmountPaid -
    balanceTotals.totalRefundAmount;
  const currentBalance =
    transactionTotal - totalPayments - balanceTotals.totalForgivenessAmount;

  const registrationRefundableAmount =
    balanceTotals.registrationAmountPaid -
    balanceTotals.registrationAmountRefunded;

  const pendingInvoices =
    regFormSubmissionBalance?.balance.invoices.filter(
      (i) => i.balanceInCents > 0 && i.invoiceStatus === 1
    ) ?? [];
  const selectedInvoicePayment = pendingInvoices.find(
    (i) => i.entityId === formState.fkRegFormSubmissionInvoice
  );
  const afterPaymentAmount =
    currentBalance - (isRefund ? formState.amount * -1 : formState.amount);

  const selectedTransactionBalance = !selectedInvoicePayment
    ? balanceTotals.registrationBalance
    : selectedInvoicePayment.balanceInCents / 100;

  const registrationPaymentItem =
    regFormSubmissionBalance && balanceTotals.registrationBalance > 0
      ? [
          <RegistrationPaymentInvoiceListItem
            key={0}
            amount={balanceTotals.registrationCost}
            createdAt={regFormSubmissionBalance.submittedAt}
            description="Base Registration"
            onAction={() =>
              setFormState({
                ...formState,
                isInvoicePayment: false,
                showInvoiceOptions: false
              })
            }
          />
        ]
      : [];
  const currency = regFormSubmissionBalance?.balance.currency?.toUpperCase();

  useEffect(() => {
    if (isOpen) {
      const defaultToInvoicePayment =
        balanceTotals.registrationBalance === 0 && pendingInvoices.length === 1;

      setFormState({
        fkRegFormSubmissionPaymentType: isRefund
          ? '5'
          : OFFLINE_PAYMENT_TYPES[0].value,
        amount: isRefund ? balanceTotals.registrationBalance : null,
        fkRegFormSubmissionInvoice: defaultToInvoicePayment
          ? pendingInvoices[0].entityId
          : '',
        note: '',
        skipEmailNotice: false,
        showConfirm: false,
        isInvoicePayment: defaultToInvoicePayment,
        showInvoiceOptions:
          pendingInvoices.length > 0 && !defaultToInvoicePayment,
        loading: false
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      onClose={onClose}
      opened={isOpen}
      title={
        <AppTitle order={2}>
          {isRefund ? 'Refund Payment' : 'Record Payment'}
        </AppTitle>
      }
    >
      {formState.showConfirm ? (
        <FormSection
          cancelTitle="Back"
          isLoading={formState.loading}
          onCancel={() =>
            setFormState({
              ...formState,
              showConfirm: false
            })
          }
          onSubmit={() => {
            setFormState({
              ...formState,
              loading: true
            });
            createAdminRegFormSubmissionPayment(
              regFormSubmissionBalance.pkRegFormSubmission,
              {
                ...formState,
                fkRegFormSubmissionInvoice:
                  !isRefund && formState.isInvoicePayment
                    ? formState.fkRegFormSubmissionInvoice
                    : null
              },
              () => {
                fetchUpdatedAdminFinancialRegistration(
                  regFormSubmissionBalance.pkRegFormSubmission
                );
                onClose();
              },
              (message) => {
                setFormState({
                  ...formState,
                  loading: false
                });
                triggerNotification(message);
              }
            );
          }}
          style={{ gap: 30 }}
          submitTitle="Confirm Payment"
        >
          <Alert
            icon={<InfoCircle style={{ width: 35, height: 35 }} />}
            styles={{ icon: { marginTop: 15, width: 35 } }}
            variant="outline"
          >
            <AppStack style={{ gap: 20 }}>
              <AppText style={{ marginTop: 12, fontSize: 16 }}>
                Are you sure you want to apply the following{' '}
                {isRefund ? 'refund' : 'payment'}?
              </AppText>
              <AppStack style={{ gap: 10 }}>
                <AppFlexbox style={{ flexWrap: 'nowrap', gap: 10 }}>
                  <AppText style={{ fontSize: 16 }} weight={700}>
                    Remaining Balance
                  </AppText>
                  <Divider style={{ flex: 1 }} variant="dotted" />
                  <AppText
                    style={{ fontSize: 16, whiteSpace: 'nowrap' }}
                    weight={700}
                  >
                    <NumberFormat
                      decimalScale={2}
                      displayType="text"
                      fixedDecimalScale
                      prefix="$"
                      thousandSeparator
                      value={currentBalance}
                    />{' '}
                    {currency}
                  </AppText>
                </AppFlexbox>
                <Divider />
                <AppFlexbox style={{ flexWrap: 'nowrap', gap: 10 }}>
                  <AppText style={{ fontSize: 16 }}>Payment Amount</AppText>
                  <Divider style={{ flex: 1 }} variant="dotted" />
                  <AppText
                    color={
                      formState.fkRegFormSubmissionPaymentType === '5'
                        ? 'red'
                        : 'green'
                    }
                    style={{ fontSize: 16, whiteSpace: 'nowrap' }}
                  >
                    <NumberFormat
                      decimalScale={2}
                      displayType="text"
                      fixedDecimalScale
                      prefix="$"
                      thousandSeparator
                      value={
                        isRefund ? formState.amount * -1 : formState.amount
                      }
                    />{' '}
                    {currency}
                  </AppText>
                </AppFlexbox>
                <Divider />
                <AppFlexbox style={{ flexWrap: 'nowrap', gap: 10 }}>
                  <AppText style={{ fontSize: 16 }} weight={700}>
                    New Balance
                  </AppText>
                  <Divider style={{ flex: 1 }} variant="dotted" />
                  <AppText
                    style={{ fontSize: 16, whiteSpace: 'nowrap' }}
                    weight={700}
                  >
                    <NumberFormat
                      decimalScale={2}
                      displayType="text"
                      fixedDecimalScale
                      prefix="$"
                      thousandSeparator
                      value={afterPaymentAmount}
                    />{' '}
                    {currency}
                  </AppText>
                </AppFlexbox>
              </AppStack>
            </AppStack>
          </Alert>
          {!isRefund && afterPaymentAmount <= 0 && (
            <AppStack style={{ gap: 20 }}>
              <Divider />
              <AppText>
                This registration will be auto accepted as the payment amount
                fulfills the remaining balance
              </AppText>
              <Checkbox
                checked={!formState.skipEmailNotice}
                disabled={formState.loading}
                label="Notify registrant"
                onChange={(e) => {
                  setFormState({
                    ...formState,
                    skipEmailNotice: !e.currentTarget.checked
                  });
                }}
                styles={{
                  label: {
                    fontSize: 16,
                    fontWeight: 500
                  }
                }}
              />
              <Alert
                color={formState.skipEmailNotice ? 'yellow' : 'blue'}
                icon={
                  formState.skipEmailNotice ? (
                    <AlertCircle style={{ width: 35, height: 35 }} />
                  ) : (
                    <InfoCircle style={{ width: 35, height: 35 }} />
                  )
                }
                styles={{ icon: { marginTop: 15, width: 35 } }}
                variant="outline"
              >
                {formState.skipEmailNotice ? (
                  <AppText>
                    The registrant will not be notified that their registration
                    has been accepted.
                  </AppText>
                ) : (
                  <AppText>
                    We will notify the registrant that their registration has
                    been accepted.
                  </AppText>
                )}
              </Alert>
            </AppStack>
          )}
        </FormSection>
      ) : isRefund ? (
        <FormSection
          cancelTitle="Cancel"
          isLoading={formState.loading}
          onCancel={onClose}
          onSubmit={() => {
            setFormState({
              ...formState,
              showConfirm: true
            });
          }}
          style={{ gap: 30 }}
          submitTitle="Refund"
        >
          <AppStack style={{ gap: 20 }}>
            <AppStack style={{ gap: 0 }}>
              <AppText style={{ fontSize: 14 }} weight={500}>
                Refund Amount
              </AppText>
              <AppText style={{ fontSize: 14 }} weight={400}>
                Total Payments:{' '}
                <NumberFormat
                  decimalScale={2}
                  displayType="text"
                  fixedDecimalScale
                  prefix="$"
                  thousandSeparator
                  value={registrationRefundableAmount}
                />{' '}
                {currency}
              </AppText>
              <NumberFormat
                allowNegative={false}
                customInput={TextInput}
                decimalScale={2}
                disabled={formState.loading}
                fixedDecimalScale
                onValueChange={(e) => {
                  setFormState({
                    ...formState,
                    amount:
                      e.value > registrationRefundableAmount
                        ? registrationRefundableAmount
                        : e.value
                  });
                }}
                placeholder="$0.00"
                prefix="$"
                required
                rightSection={
                  currency && (
                    <AppText
                      style={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: '#999',
                        paddingRight: 5
                      }}
                    >
                      {currency}
                    </AppText>
                  )
                }
                style={{ flex: 1 }}
                thousandSeparator
                value={formState.amount}
              />
            </AppStack>
            <TextInput
              disabled={formState.loading}
              label="Refund details (e.g. check #)"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  note:
                    e.currentTarget.value.length > 255
                      ? e.currentTarget.value.substring(0, 255)
                      : e.currentTarget.value
                })
              }
              placeholder="Add a note here"
              value={formState.note}
            />
          </AppStack>
        </FormSection>
      ) : formState.showInvoiceOptions ? (
        <AppStack style={{ gap: 30 }}>
          <AppStack style={{ gap: 20 }}>
            <AppStack style={{ gap: 5 }}>
              <AppText
                style={{ fontSize: 16, lineHeight: '16px' }}
                weight={500}
              >
                Pending Payments
              </AppText>
              <AppText
                style={{ fontSize: 14, color: '#868e96', lineHeight: '16px' }}
                weight={400}
              >
                Select a transaction to apply a payment to
              </AppText>
            </AppStack>
            <PaginationList
              items={[
                ...registrationPaymentItem,
                ...pendingInvoices.map((p) => (
                  <RegistrationPaymentInvoiceListItem
                    key={p.entityId}
                    amount={p.totalInCents / 100}
                    description={p.description}
                    onAction={() =>
                      setFormState({
                        ...formState,
                        isInvoicePayment: true,
                        showInvoiceOptions: false,
                        fkRegFormSubmissionInvoice: p.entityId
                      })
                    }
                  />
                ))
              ]}
              itemsPerPage={5}
              listProps={{ sx: { gap: 20 } }}
              LoadingComponent={<RegistrationPaymentInvoiceListItem />}
              noBorder
              style={{ gap: 20 }}
            />
          </AppStack>
          <Divider />
          <Button
            color="dark"
            onClick={onClose}
            style={{ alignSelf: 'center', width: '100%', maxWidth: 250 }}
          >
            Cancel
          </Button>
        </AppStack>
      ) : (
        <FormSection
          cancelTitle="Cancel"
          isLoading={formState.loading}
          onCancel={onClose}
          onSubmit={() => {
            setFormState({
              ...formState,
              showConfirm: true
            });
          }}
          style={{ gap: 30 }}
          submitTitle="Apply payment"
        >
          <AppStack style={{ gap: 20 }}>
            {formState.fkRegFormSubmissionInvoice ? (
              <RegistrationPaymentInvoiceListItem
                amount={selectedInvoicePayment.totalInCents / 100}
                description={selectedInvoicePayment.description}
              />
            ) : (
              <RegistrationPaymentInvoiceListItem
                amount={balanceTotals.registrationCost}
                description="Base Registration"
              />
            )}

            <AppRadioGroup
              disabled={formState.loading}
              label="How did they pay?"
              onChange={(value) =>
                setFormState({
                  ...formState,
                  fkRegFormSubmissionPaymentType: value
                })
              }
              size="sm"
              spacing="lg"
              styles={{
                input: { cursor: 'pointer !important' },
                label: { cursor: 'pointer' },
                root: {
                  alignItems: 'start',
                  flexDirection: rs(['column', 'row'], mqIndex)
                }
              }}
              value={formState.fkRegFormSubmissionPaymentType}
            >
              {OFFLINE_PAYMENT_TYPES.filter(
                (p) =>
                  p.value === '1' ||
                  p.value === '2' ||
                  p.value === '3' ||
                  p.value === '4' ||
                  p.value === '9'
              ).map((p) => (
                <Radio
                  key={p.value}
                  disabled={formState.loading}
                  label={p.label}
                  value={p.value}
                />
              ))}
            </AppRadioGroup>
            <AppStack style={{ gap: 5 }}>
              <AppText
                style={{ fontSize: 14, lineHeight: '16px' }}
                weight={500}
              >
                {isForgivenessPayment
                  ? 'How much are you forgiving?'
                  : 'How much did they pay?'}
              </AppText>
              <AppText
                style={{ fontSize: 14, color: '#868e96', lineHeight: '16px' }}
                weight={400}
              >
                {formState.isInvoicePayment
                  ? 'Remaining invoice balance:'
                  : 'Remaining registration balance:'}{' '}
                <NumberFormat
                  decimalScale={2}
                  displayType="text"
                  fixedDecimalScale
                  prefix="$"
                  thousandSeparator
                  value={selectedTransactionBalance}
                />{' '}
                {currency}
              </AppText>
              <NumberFormat
                allowNegative={false}
                customInput={TextInput}
                decimalScale={2}
                disabled={formState.loading}
                fixedDecimalScale
                onValueChange={(e) => {
                  setFormState({
                    ...formState,
                    amount:
                      e.value > selectedTransactionBalance
                        ? selectedTransactionBalance
                        : e.value
                  });
                }}
                placeholder={`${currencyFormat(selectedTransactionBalance)}`}
                prefix="$"
                required
                rightSection={
                  currency && (
                    <AppText
                      style={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: '#999',
                        paddingRight: 5
                      }}
                    >
                      {currency}
                    </AppText>
                  )
                }
                style={{ flex: 1 }}
                thousandSeparator
                value={formState.amount}
              />
            </AppStack>
            <TextInput
              disabled={formState.loading}
              label={
                isForgivenessPayment ? 'Note' : 'Payment details (e.g. check #)'
              }
              onChange={(e) =>
                setFormState({
                  ...formState,
                  note:
                    e.currentTarget.value.length > 255
                      ? e.currentTarget.value.substring(0, 255)
                      : e.currentTarget.value
                })
              }
              placeholder="Add a note here"
              value={formState.note}
            />

            {isForgivenessPayment && (
              <Alert
                color="yellow"
                icon={
                  <InfoCircle style={{ width: 35, height: 35, marginTop: 3 }} />
                }
                variant="outline"
              >
                <AppText>
                  This payment type is used to reduce the customer's outstanding
                  balance without declaring any income. The amount is no longer
                  required to be repaid.
                </AppText>
              </Alert>
            )}
          </AppStack>
        </FormSection>
      )}
    </ResponsiveModal>
  );
};

FinancialRegistrationTransactionModal.propTypes = {
  isOpen: PropTypes.bool,
  isRefund: PropTypes.bool,
  onClose: PropTypes.func,
  regFormSubmissionBalance: PropTypes.object
};
export default FinancialRegistrationTransactionModal;
