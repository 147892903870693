import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import ConfirmModal from '../../common/ConfirmModal';
import { triggerNotification } from '../../../helpers/notificationHelper';

const AdminMerchReportModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const { generateStaffRegistrationReport } = useContext(
    RegistrationAdminContext
  );
  const [formState, setFormState] = useState({
    includeTesting: false,
    isLoading: false
  });

  useEffect(() => {
    if (isOpen) {
      setFormState({
        includeTesting: false,
        isLoading: false
      });
    }
  }, [isOpen]);

  return (
    <ConfirmModal
      confirmActionColor="blue"
      confirmActionText="Generate Report"
      isLoading={formState.isLoading}
      isOpen={isOpen}
      onCancel={onClose}
      onConfirm={() => {
        setFormState({ ...formState, isLoading: true });
        generateStaffRegistrationReport(
          {
            reportGuid: 'MERCH_TRANSACTIONS',
            includeTesting: formState.includeTesting
          },
          () =>
            navigate(`/admin/reports/staff-report?r=${new Date().getTime()}`),
          (e) => {
            triggerNotification(e);
            setFormState({ ...formState, isLoading: false });
          }
        );
      }}
      title="Admin Merch Report"
    >
      <Checkbox
        checked={formState.includeTesting}
        disabled={formState.isLoading}
        label="Include Testing"
        onChange={() =>
          setFormState({
            ...formState,
            includeTesting: !formState.includeTesting
          })
        }
        style={{ marginTop: 20, fontWeight: 500 }}
      />
    </ConfirmModal>
  );
};

AdminMerchReportModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default AdminMerchReportModal;
