import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';

const SpecialRequestWarnings = ({ errors }) => {
  const PAGE_AMOUNT = 5;
  const [displayAmount, setDisplayAmount] = useState(PAGE_AMOUNT);
  const amountLeft = errors.length - displayAmount;

  return (
    <AppStack style={{ gap: 10 }}>
      {errors.slice(0, displayAmount).map((error, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <AppText key={index}>{error}</AppText>
      ))}
      {amountLeft > 0 && (
        <AppStack style={{ alignItems: 'center', margin: 10 }}>
          <AppText
            onClick={() => setDisplayAmount(displayAmount + PAGE_AMOUNT)}
            style={{ cursor: 'pointer' }}
            weight={700}
          >
            {amountLeft} more {amountLeft === 1 ? 'warning' : 'warnings'}...
          </AppText>
        </AppStack>
      )}

      {errors.length > PAGE_AMOUNT && amountLeft <= 0 && (
        <AppStack style={{ alignItems: 'center', margin: 10 }}>
          <AppText
            onClick={() => setDisplayAmount(PAGE_AMOUNT)}
            style={{ cursor: 'pointer' }}
            weight={700}
          >
            Show less...
          </AppText>
        </AppStack>
      )}
    </AppStack>
  );
};

SpecialRequestWarnings.propTypes = { errors: PropTypes.array };

export default SpecialRequestWarnings;
