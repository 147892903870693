import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { User } from 'tabler-icons-react';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { useModalState, useSortByFilter } from '../../../helpers/hooks';
import AppCard from '../../common/AppCard';
import TableView from '../../common/TableView';
import EvaluationPlayerModal from './EvaluationPlayerModal';
import PlayerStatsModal from '../players/PlayerStatsModal';
import { getEvaluationRatingForSessions } from '../../../helpers/evaluationPlayerHelper';

const VIEW_ACTIONS_ENUM = {
  view: 'VIEW',
  create: 'CREATE',
  delete: 'DELETE'
};

const VIEW_ACTIONS = [
  {
    label: 'View',
    value: VIEW_ACTIONS_ENUM.view
  },
  {
    label: 'Edit',
    value: VIEW_ACTIONS_ENUM.create,
    isCustomOnly: true
  },
  {
    label: 'Delete',
    value: VIEW_ACTIONS_ENUM.delete,
    isCustomOnly: true
  }
];

const TABLE_COLUMNS = [
  {
    label: 'Name',
    value: 'name',
    sortable: true
  },
  {
    label: 'Division',
    value: 'division',
    sortable: true
  },
  {
    label: 'Evaluations',
    value: 'evaluations',
    sortable: true
  },
  {
    label: 'Overall Score',
    value: 'overallScore',
    sortable: true
  }
];

const PLAYER_TYPES = [
  {
    label: 'Registrant',
    value: 'registrant'
  },
  {
    label: 'Custom',
    value: 'custom'
  }
];

const EvaluationPlayersListView = ({
  isLoading,
  regAssociation,
  modalAction
}) => {
  const hasLoaded = useRef(false);
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const { state, fetchScoutReportEvaluationPlayers } = useContext(
    RegistrationAdminContext
  );
  const [filterState, setFilterState] = useState({
    fkRegAssociationDivision: null,
    playerType: null,
    search: ''
  });
  const {
    sortValue,
    isDescendingSort,
    onChangeSortBy,
    customFilterData
  } = useSortByFilter(TABLE_COLUMNS);

  const regAssociationDivisionOptions = state.regScoutReportEvaluationPlayers.value.reduce(
    (r, c) => {
      if (
        !r.some(
          (f) =>
            f.value ===
            c.regAssociationDivision.pkRegAssociationDivision.toString()
        )
      ) {
        r.push({
          label: c.regAssociationDivision.name,
          value: c.regAssociationDivision.pkRegAssociationDivision.toString()
        });
      }
      return r;
    },
    []
  );

  useEffect(() => {
    if (regAssociation) {
      if (
        !!state.regScoutReportEvaluationPlayers.filter ||
        !!state.regScoutReportEvaluationPlayers.filter
          ?.pkRegAssociationDivision ||
        state.regScoutReportEvaluationPlayers.filter?.pkRegAssociation !==
          regAssociation.pkRegAssociation
      ) {
        fetchScoutReportEvaluationPlayers(regAssociation.pkRegAssociation);
      }
      hasLoaded.current = true;
    }
  }, [regAssociation]);

  useEffect(() => {
    if (modalAction === VIEW_ACTIONS_ENUM.create) {
      onOpenModal(modalAction);
    }
  }, [modalAction]);

  return (
    <AppCard style={{ flex: 1, padding: 0, height: '100%' }}>
      <TableView
        columns={TABLE_COLUMNS}
        emptyMessage="No Players Available"
        filters={[
          {
            key: 1,
            label: 'Division',
            type: 'select',
            value: filterState.fkRegAssociationDivision,
            placeholder: 'Filter by division',
            data: regAssociationDivisionOptions,
            clearable: true,
            searchable: true,
            onChange: (value) => {
              setFilterState({
                ...filterState,
                fkRegAssociationDivision: value
              });
            }
          },
          {
            key: 2,
            label: 'Player Type',
            type: 'select',
            value: filterState.playerType,
            placeholder: 'Filter by type',
            data: PLAYER_TYPES,
            clearable: true,
            searchable: true,
            onChange: (value) => {
              setFilterState({
                ...filterState,
                playerType: value
              });
            }
          },
          {
            key: 3,
            label: 'Name',
            type: 'text',
            value: filterState.search,
            placeholder: 'Filter by name',
            onChange: (value) => {
              setFilterState((c) => ({
                ...c,
                search: value
              }));
            }
          }
        ]}
        isDescendingSort={isDescendingSort}
        isLoading={!hasLoaded.current || isLoading}
        lastUpdated={state.regScoutReportEvaluationPlayers.lastUpdated}
        onAction={(action, item) => {
          onOpenModal(
            action,
            state.regScoutReportEvaluationPlayers.value.find(
              (f) => f.entityId === item.key
            )
          );
        }}
        onChangeSortBy={onChangeSortBy}
        onRefresh={() =>
          fetchScoutReportEvaluationPlayers(regAssociation.pkRegAssociation)
        }
        rows={state.regScoutReportEvaluationPlayers.value
          .filter(
            (p) =>
              (!filterState.search ||
                `${p.person.firstName} ${p.person.lastName}`
                  .toLowerCase()
                  .includes(filterState.search.toLowerCase())) &&
              (!filterState.fkRegAssociationDivision ||
                p.regAssociationDivision.pkRegAssociationDivision.toString() ===
                  filterState.fkRegAssociationDivision) &&
              (!filterState.playerType ||
                (filterState.playerType === 'custom'
                  ? p.person.isCustomPerson
                  : !p.person.isCustomPerson))
          )
          .map((p) => {
            const overallScore =
              getEvaluationRatingForSessions(
                {
                  pkRegAssociationDivision:
                    p.regAssociationDivision.pkRegAssociationDivision,
                  pkRegPerson: p.person.pkRegPerson,
                  pkRegScoutReportPerson: p.person.pkRegScoutReportPerson
                },
                state.regScoutReportEvaluationSessions.value
              ).overallRating || 0;

            return {
              key: p.entityId,
              actions: VIEW_ACTIONS.filter(
                (f) => !f.isCustomOnly || p.person.isCustomPerson
              ),
              columns: [
                {
                  key: 1,
                  label: `${p.person.firstName} ${p.person.lastName}`,
                  weight: 500,
                  icon: <User color="black" size="30" />,
                  onClick: () => {
                    onOpenModal(VIEW_ACTIONS_ENUM.view, p);
                  }
                },
                {
                  key: 2,
                  label: p.regAssociationDivision.name,
                  showOnMobile: true
                },
                {
                  key: 3,
                  label: p.regScoutReportEvaluations.length,
                  sortValueType: 'number'
                },
                {
                  key: 4,
                  label: overallScore,
                  sortValueType: 'number'
                }
              ]
            };
          })}
        sortBy={sortValue}
        sortFilter={customFilterData}
        tableTitle="Evaluation Players"
      />

      <PlayerStatsModal
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.view
        }
        onClose={onCloseModal}
        player={modalState.item}
        regAssociation={regAssociation}
        regScoutReportEvaluationSessions={
          state.regScoutReportEvaluationSessions.value
        }
      />
      <EvaluationPlayerModal
        isOpen={
          modalState.isOpen && modalState.action !== VIEW_ACTIONS_ENUM.view
        }
        onClose={onCloseModal}
        pkRegAssociation={regAssociation?.pkRegAssociation}
        regScoutReportPerson={modalState.item}
        showDelete={modalState.action === VIEW_ACTIONS_ENUM.delete}
      />
    </AppCard>
  );
};

EvaluationPlayersListView.propTypes = {
  isLoading: PropTypes.bool,
  modalAction: PropTypes.string,
  regAssociation: PropTypes.object
};

export default EvaluationPlayersListView;
