import React from 'react';
import { PropTypes } from 'prop-types';
import { Avatar } from '@mantine/core';
import { getInitialsFromName } from '../../../helpers/format';
import ActionableListItem from '../../common/ActionableListItem';
import AppText from '../../common/AppText';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const AdminListItem = ({ regAssociationAdmin, onAction }) => {
  const mqIndex = useMediaQueryIndex();
  const { regAssociation, user } = regAssociationAdmin || {};

  return regAssociationAdmin ? (
    <ActionableListItem
      indicator={
        <Avatar
          radius="xl"
          style={{
            backgroundColor: '#ced4da',
            display: rs(['none', 'flex'], mqIndex)
          }}
        >
          {user && (
            <AppText style={{ color: '#333' }} weight={700}>
              {getInitialsFromName(user.name)}
            </AppText>
          )}
        </Avatar>
      }
      label={user?.name || 'Invite'}
      menuActions={
        onAction
          ? [
              {
                label: 'Edit',
                value: 'EDIT'
              },
              {
                label: 'Remove',
                value: 'DELETE'
              }
            ]
          : null
      }
      onAction={onAction}
      subLabel={user?.email || regAssociationAdmin.inviteSentTo}
    />
  ) : (
    <ActionableListItem
      hideBadgeLoader
      hideDescriptionLoader
      isLoading
      showIndicator
    />
  );
};

AdminListItem.propTypes = {
  onAction: PropTypes.func,
  regAssociationAdmin: PropTypes.object
};

export default AdminListItem;
