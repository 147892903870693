import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  NumberInput,
  Radio,
  Select,
  TextInput
} from '@mantine/core';
import NumberFormat from 'react-number-format';
import ResponsiveModal from '../../common/ResponsiveModal';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import FormSection from '../../common/FormSection';
import { triggerNotification } from '../../../helpers/notificationHelper';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppTitle from '../../common/AppTitle';
import AppFlexbox from '../../common/AppFlexbox';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import AppRadioGroup from '../../common/AppRadioGroup';
import { CURRENCY_TYPE_LIST } from '../../../config/constants';

const CouponModal = ({
  isOpen,
  onClose,
  coupon,
  fkRegAssociation,
  showExpireView,
  showReinstateView
}) => {
  const mqIndex = useMediaQueryIndex();
  const hasFetchedDivisions = useRef(false);
  const {
    state,
    createAdminRegAssociationCoupon,
    updateAdminRegAssociationCoupon,
    expireAdminRegAssociationCoupon,
    fetchAdminRegAssociationLeagueOptions
  } = useContext(RegistrationAdminContext);
  const [formState, setFormState] = useState({
    code: '',
    discountType: 'FLAT',
    discountAmount: 0,
    limitRedemptions: false,
    maxRedemptions: 1,
    fkRegAssociationSeason: null,
    fkRegAssociationDivision: null,
    fkRegAssociationDivisionForm: null,
    confirmExpire: showExpireView ?? false,
    confirmReinstate: showReinstateView ?? false,
    isLoading: false
  });
  const regAssociation = state.regAssociations.value.find(
    (a) => a.pkRegAssociation.toString() === fkRegAssociation?.toString()
  );
  const currency = CURRENCY_TYPE_LIST.find(
    (c) =>
      c.value.toString() ===
      regAssociation?.regAssociationPaymentProvider?.fkCurrencyType?.toString()
  )?.abbr;

  const isExpired = coupon && !coupon.active;

  const regAssociationSeasonOptions =
    state.regAssociation.value?.regAssociationSeasons
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((s) => ({
        label: s.name,
        value: s.pkRegAssociationSeason.toString()
      })) ?? [];

  const regAssociationDivisionOptions =
    state.regAssociation.value?.regAssociationDivisions
      .filter(
        (d) =>
          d.fkRegAssociationSeason.toString() ===
          formState.fkRegAssociationSeason
      )
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((d) => ({
        label: d.name,
        value: d.pkRegAssociationDivision.toString()
      })) ?? [];

  const regFormOptions =
    state.regAssociation.value?.regAssociationDivisionForms
      .filter(
        (f) =>
          f.active &&
          !f.regForm.deleted &&
          f.fkRegAssociationDivision.toString() ===
            formState.fkRegAssociationDivision
      )
      .sort((a, b) => a.regForm.name.localeCompare(b.regForm.name))
      .map((d) => ({
        label: d.regForm.name,
        value: d.pkRegAssociationDivisionForm.toString()
      })) ?? [];

  const isLoading =
    !hasFetchedDivisions.current || state.regAssociation.loading;

  useEffect(() => {
    if (isOpen) {
      if (coupon) {
        setFormState({
          code: coupon.code,
          discountType: coupon.flatDiscount ? 'FLAT' : 'PERCENT',
          discountAmount: coupon.flatDiscount ?? coupon.percentageDiscount,
          limitRedemptions: !!coupon.maxRedemptions,
          maxRedemptions: coupon.maxRedemptions ?? 1,
          fkRegAssociationSeason: coupon.fkRegAssociationSeason?.toString(),
          fkRegAssociationDivision: coupon.fkRegAssociationDivision?.toString(),
          fkRegAssociationDivisionForm: coupon.fkRegAssociationDivisionForm?.toString(),
          confirmExpire: showExpireView ?? false,
          confirmReinstate: showReinstateView ?? false,
          isLoading: false
        });
      }
      else {
        setFormState({
          code: '',
          discountType: 'FLAT',
          discountAmount: 0,
          limitRedemptions: false,
          maxRedemptions: 1,
          fkRegAssociationSeason: regAssociationSeasonOptions[0]?.value,
          fkRegAssociationDivision: null,
          fkRegAssociationDivisionForm: null,
          confirmExpire: false,
          confirmReinstate: false,
          isLoading: false
        });
      }

      if (
        !hasFetchedDivisions.current ||
        state.regAssociation.value?.pkRegAssociation.toString() !==
          fkRegAssociation?.toString()
      ) {
        fetchAdminRegAssociationLeagueOptions(fkRegAssociation);
        hasFetchedDivisions.current = true;
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (!state.regAssociation.loading && !coupon) {
      setFormState({
        ...formState,
        fkRegAssociationSeason: regAssociationSeasonOptions[0]?.value
      });
    }
  }, [state.regAssociation.value]);

  const onError = (message) => {
    setFormState({
      ...formState,
      isLoading: false
    });
    triggerNotification(message);
  };

  const onSubmitCoupon = ({ reinstate } = {}) => {
    setFormState({
      ...formState,
      isLoading: true
    });
    const couponData = {
      ...formState,
      maxRedemptions: formState.limitRedemptions
        ? formState.maxRedemptions
        : null,
      percentageDiscount:
        formState.discountType === 'FLAT' ? null : formState.discountAmount,
      flatDiscount:
        formState.discountType === 'FLAT' ? formState.discountAmount : null,
      reinstate
    };

    if (coupon) {
      updateAdminRegAssociationCoupon(
        coupon.pkRegAssociationCoupon,
        couponData,
        onClose,
        onError
      );
    }
    else {
      createAdminRegAssociationCoupon(
        fkRegAssociation,
        couponData,
        onClose,
        onError
      );
    }
  };

  return (
    <ResponsiveModal
      isLoading={isLoading}
      isOpen={isOpen}
      onClose={onClose}
      title={
        <AppTitle order={2}>
          {formState.confirmReinstate
            ? 'Reactivate Coupon'
            : coupon
            ? formState.confirmExpire || showExpireView
              ? 'Expire Coupon'
              : 'Edit Coupon'
            : 'Create Coupon'}
        </AppTitle>
      }
    >
      {coupon && formState.confirmReinstate ? (
        <FormSection
          isLoading={formState.isLoading}
          onCancel={() => {
            if (showReinstateView) {
              onClose();
            }
            else {
              setFormState({
                ...formState,
                confirmReinstate: false
              });
            }
          }}
          onSubmit={() => onSubmitCoupon({ reinstate: true })}
          submitTitle="Reactivate Coupon"
        >
          <AppStack style={{ gap: 20, marginTop: 20 }}>
            <AppText style={{ textAlign: 'center' }}>
              Are you sure you want to reinstate <b>{coupon.code}</b>?
            </AppText>
          </AppStack>
        </FormSection>
      ) : coupon && formState.confirmExpire ? (
        <FormSection
          cancelTitle="Cancel"
          isLoading={formState.isLoading}
          onCancel={() => {
            if (showExpireView) {
              onClose();
            }
            else {
              setFormState({
                ...formState,
                confirmExpire: false
              });
            }
          }}
          onSubmit={() => {
            setFormState({
              ...formState,
              isLoading: true
            });
            expireAdminRegAssociationCoupon(
              coupon.pkRegAssociationCoupon,
              onClose,
              onError
            );
          }}
          style={{ marginTop: 20 }}
          submitColor="red"
          submitTitle="Expire Coupon"
        >
          <AppText style={{ textAlign: 'center' }}>
            Are you sure you want to expire <b>{coupon.code}</b>?
          </AppText>
        </FormSection>
      ) : (
        <FormSection
          cancelTitle="Cancel"
          isLoading={formState.isLoading}
          onCancel={onClose}
          onSubmit={() => onSubmitCoupon()}
          submitTitle={coupon ? 'Save Changes' : 'Create'}
        >
          <AppStack style={{ gap: 20 }}>
            <TextInput
              disabled={coupon || formState.isLoading}
              label="Coupon Code"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  code: e.currentTarget.value.toUpperCase().replace(' ', '')
                })
              }
              required
              value={formState.code}
            />

            <AppStack style={{ gap: 15 }}>
              <AppFlexbox>
                <AppRadioGroup
                  label="Discount"
                  onChange={(value) =>
                    setFormState({
                      ...formState,
                      discountType: value
                    })
                  }
                  size="sm"
                  spacing="lg"
                  styles={{
                    input: { cursor: 'pointer !important' },
                    label: { cursor: 'pointer' },
                    root: {
                      alignItems: 'start',
                      flexDirection: rs(['column', 'row'], mqIndex)
                    }
                  }}
                  value={formState.discountType}
                >
                  <Radio
                    disabled={coupon || formState.isLoading}
                    label="Flat Discount"
                    style={{ fontWeight: 500 }}
                    value="FLAT"
                  />
                  <Radio
                    disabled={coupon || formState.isLoading}
                    label="Percentage Discount"
                    style={{ fontWeight: 500 }}
                    value="PERCENT"
                  />
                </AppRadioGroup>
              </AppFlexbox>
              <NumberFormat
                allowNegative={false}
                customInput={TextInput}
                decimalScale={2}
                disabled={coupon || formState.isLoading}
                onValueChange={(e) =>
                  setFormState({
                    ...formState,
                    discountAmount: e.value
                  })
                }
                {...(formState.discountType === 'FLAT'
                  ? {
                      placeholder: '$0.00',
                      prefix: '$',
                      fixedDecimalScale: true,
                      thousandSeparator: true,
                      rightSection: currency && (
                        <AppText
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                            color: '#999',
                            paddingRight: 5
                          }}
                        >
                          {currency}
                        </AppText>
                      )
                    }
                  : { placeholder: '10%', suffix: '%' })}
                required
                style={{ flex: 1 }}
                value={formState.discountAmount}
              />
            </AppStack>

            <AppStack style={{ gap: 5 }}>
              <AppStack style={{ gap: 10 }}>
                <AppText style={{ fontSize: 14 }} weight={500}>
                  Redemption Limit
                </AppText>
                <Checkbox
                  checked={formState.limitRedemptions}
                  disabled={formState.isLoading}
                  label="Enable Redemption Limit"
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      limitRedemptions: e.currentTarget.checked
                    })
                  }
                />
              </AppStack>

              {formState.limitRedemptions && (
                <NumberInput
                  disabled={formState.isLoading}
                  label="Limit"
                  min={1}
                  onChange={(value) =>
                    setFormState({
                      ...formState,
                      maxRedemptions: value || null
                    })
                  }
                  value={formState.maxRedemptions}
                />
              )}
            </AppStack>

            <Select
              clearable
              data={regAssociationSeasonOptions}
              disabled={formState.isLoading}
              label="Season"
              onChange={(value) =>
                setFormState({
                  ...formState,
                  fkRegAssociationSeason: value,
                  fkRegAssociationDivision: null,
                  fkRegAssociationDivisionForm: null
                })
              }
              required
              searchable
              value={formState.fkRegAssociationSeason}
            />
            {formState.fkRegAssociationSeason && (
              <Select
                clearable
                data={regAssociationDivisionOptions}
                disabled={formState.isLoading}
                label="Division"
                onChange={(value) =>
                  setFormState({
                    ...formState,
                    fkRegAssociationDivision: value,
                    fkRegAssociationDivisionForm: null
                  })
                }
                searchable
                value={formState.fkRegAssociationDivision}
              />
            )}
            {formState.fkRegAssociationDivision && (
              <Select
                clearable
                data={regFormOptions}
                disabled={formState.isLoading}
                label="Form"
                onChange={(value) =>
                  setFormState({
                    ...formState,
                    fkRegAssociationDivisionForm: value
                  })
                }
                searchable
                value={formState.fkRegAssociationDivisionForm}
              />
            )}
          </AppStack>
          {coupon && (
            <AppStack style={{ alignItems: 'center' }}>
              <Button
                color={isExpired ? 'blue' : 'red'}
                disabled={formState.isLoading}
                onClick={() =>
                  setFormState({
                    ...formState,
                    confirmReinstate: isExpired,
                    confirmExpire: !isExpired
                  })
                }
                style={{ maxWidth: 250, width: '100%' }}
                variant={isExpired ? 'outline' : 'filled'}
              >
                {isExpired ? 'Reactivate Coupon' : 'Expire Coupon'}
              </Button>
            </AppStack>
          )}
        </FormSection>
      )}
    </ResponsiveModal>
  );
};

CouponModal.propTypes = {
  coupon: PropTypes.object,
  fkRegAssociation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  showExpireView: PropTypes.bool,
  showReinstateView: PropTypes.bool
};

export default CouponModal;
