import React from 'react';
import { PropTypes } from 'prop-types';
import { Avatar, Badge } from '@mantine/core';
import dayjs from 'dayjs';
import { getInitialsFromName } from '../../../helpers/format';
import ActionableListItem from '../../common/ActionableListItem';
import AppText from '../../common/AppText';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const InviteListItem = ({
  label,
  onAction,
  showStatus,
  accepted,
  deleted,
  expiresAt,
  email,
  isAdminViewing,
  ...rest
}) => {
  const mqIndex = useMediaQueryIndex();
  const hasExpired =
    deleted || (expiresAt ? new Date() >= new Date(expiresAt) : false);

  let expiryTimeHours = expiresAt
    ? dayjs(expiresAt).diff(dayjs(new Date()), 'hour')
    : 0;
  const expiryTimeMinutes = expiresAt
    ? Math.ceil(dayjs(expiresAt).diff(dayjs(new Date()), 'minute', true))
    : 0;
  const expiryTimeDays = Math.ceil(expiryTimeHours / 24);
  expiryTimeHours -= Math.floor(expiryTimeHours / 24) * 24;

  const userActions =
    !isAdminViewing && !accepted && !hasExpired
      ? [{ label: 'Accept', value: 'ACCEPT' }]
      : [];

  return label ? (
    <ActionableListItem
      badge={
        showStatus && (
          <Badge
            color={accepted ? 'green' : hasExpired ? 'red' : 'blue'}
            size="lg"
            style={{
              width: rs(['100%', 185], mqIndex),
              textAlign: 'center'
            }}
            variant="filled"
          >
            {accepted
              ? 'Accepted'
              : hasExpired
              ? 'Expired'
              : !expiresAt
              ? 'Invited'
              : `Expires in ${
                  expiryTimeDays > 1
                    ? `${expiryTimeDays} ${
                        expiryTimeDays === 1 ? 'day' : 'days'
                      }`
                    : expiryTimeHours > 0
                    ? `${expiryTimeHours} ${
                        expiryTimeHours === 1 ? 'hour' : 'hours'
                      }`
                    : expiryTimeMinutes > 0 &&
                      `${expiryTimeMinutes} ${
                        expiryTimeMinutes === 1 ? 'minute' : 'minutes'
                      }`
                }`}
          </Badge>
        )
      }
      extraLabels={isAdminViewing && email ? [email] : null}
      indicator={
        mqIndex === 0 ? null : (
          <Avatar radius="xl" style={{ backgroundColor: '#ced4da' }}>
            <AppText style={{ color: '#333' }}>
              {getInitialsFromName(label)}
            </AppText>
          </Avatar>
        )
      }
      label={label}
      menuActions={
        onAction
          ? [
              ...userActions,
              ...(isAdminViewing && !accepted
                ? [
                    {
                      label: 'Email User',
                      value: 'EMAIL'
                    }
                  ]
                : []),
              ...(!hasExpired
                ? [
                    {
                      label: isAdminViewing ? 'Expire' : 'Decline',
                      value: 'EXPIRE'
                    }
                  ]
                : []),
              ...(isAdminViewing && !accepted
                ? [
                    {
                      label: 'Resend',
                      value: 'RESEND'
                    }
                  ]
                : [])
            ]
          : null
      }
      onAction={accepted ? null : onAction}
      showMenuSpacer={showStatus}
      {...rest}
    />
  ) : (
    <ActionableListItem isLoading showIndicator />
  );
};

InviteListItem.propTypes = {
  accepted: PropTypes.bool,
  deleted: PropTypes.bool,
  email: PropTypes.string,
  expiresAt: PropTypes.string,
  firstName: PropTypes.string,
  isAdminViewing: PropTypes.bool,
  isExternalInvite: PropTypes.bool,
  label: PropTypes.string,
  lastName: PropTypes.string,
  onAction: PropTypes.func,
  showStatus: PropTypes.bool
};

export default InviteListItem;
