import React from 'react';
import PropTypes from 'prop-types';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';

const SettingCard = ({ title, description, padding, children }) => (
  <AppFlexbox style={{ gap: 10 }}>
    <AppStack style={{ flex: 1, gap: 10, maxWidth: 325 }} visibleFrom="md">
      <AppText style={{ fontSize: 17, fontWeight: 500 }}>{title}</AppText>
      <AppText style={{ fontSize: 14, color: '#666' }}>{description}</AppText>
    </AppStack>
    <AppStack style={{ flex: 1 }}>
      <AppCard
        radius="md"
        shadow="sm"
        style={{ padding: padding ?? 16 }}
        withBorder
      >
        <AppStack>{children}</AppStack>
      </AppCard>
    </AppStack>
  </AppFlexbox>
);

SettingCard.propTypes = {
  children: PropTypes.node,
  description: PropTypes.string,
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string
};

export default SettingCard;
