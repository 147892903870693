import React, { useState, useContext, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { TextInput } from '@mantine/core';
import { Context as AuthContext } from '../../../providers/AuthProvider';
import { PASSWORD_CONSTRAINTS } from '../../../config/constants';
import { triggerNotification } from '../../../helpers/notificationHelper';
import AuthCardForm from './AuthCardForm';

const ResetPasswordView = ({ navigate }) => {
  const { state, reset, resetPassword } = useContext(AuthContext);
  const [formState, setFormState] = useState({
    email: state.email,
    code: '',
    newPassword: '',
    confirmPassword: '',
    error: ''
  });

  useEffect(() => {
    reset();

    setFormState({
      email: state.email,
      code: '',
      newPassword: '',
      confirmPassword: '',
      error: ''
    });
  }, []);

  const handleInputChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value
    });
  };

  return (
    <AuthCardForm
      error={formState.error}
      isLoading={state.loading}
      navigate={navigate}
      onSubmit={() => {
        if (formState.newPassword !== formState.confirmPassword) {
          setFormState({
            ...formState,
            error: 'Passwords must match.'
          });
        }
        else if (
          formState.confirmPassword.length < PASSWORD_CONSTRAINTS.length
        ) {
          setFormState({
            ...formState,
            error: `Password must be at least ${PASSWORD_CONSTRAINTS.length} characters long`
          });
        }
        else {
          setFormState({
            ...formState,
            error: ''
          });
          resetPassword(formState, () => {
            triggerNotification(
              'Password was successfully reset!',
              'Success',
              'green'
            );
            navigate('login');
          });
        }
      }}
      reverseSubmit
      submitSecondaryLink={{
        text: 'Remember your password?',
        onClick: () => navigate('/auth/login')
      }}
      submitText="Continue"
      title="Reset Password"
    >
      <TextInput
        disabled={state.loading}
        label="Verification Code"
        name="code"
        onChange={handleInputChange}
        required
        size="md"
        style={{ flex: 1 }}
        value={formState.code}
      />
      <TextInput
        disabled={state.loading}
        label="New Password"
        name="newPassword"
        onChange={handleInputChange}
        required
        size="md"
        type="password"
        value={formState.newPassword}
      />

      <TextInput
        disabled={state.loading}
        label="Confirm Password"
        name="confirmPassword"
        onChange={handleInputChange}
        required
        size="md"
        type="password"
        value={formState.confirmPassword}
      />
    </AuthCardForm>
  );
};

ResetPasswordView.propTypes = { navigate: PropTypes.func };

export default ResetPasswordView;
