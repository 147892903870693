import React from 'react';
import PropTypes from 'prop-types';
import { Anchor } from '@mantine/core';
import AppText from './AppText';
import classes from '../../styles/nestedStyles.module.css';

const AppLink = ({ label, color, to, isActive, component, ...rest }) => (
  <Anchor
    component={component}
    to={to}
    {...rest}
    style={{ textDecoration: 'none', ...rest.style }}
  >
    {isActive ? (
      <AppText
        color={color}
        style={{
          padding: 5,
          fontWeight: 'bold',
          borderBottom: 'solid 2px'
        }}
      >
        {label}
      </AppText>
    ) : (
      <AppText
        className={classes['hover-text-bold']}
        color={color}
        style={{ padding: 5 }}
      >
        {label}
      </AppText>
    )}
  </Anchor>
);

AppLink.propTypes = {
  color: PropTypes.string,
  component: PropTypes.any,
  isActive: PropTypes.bool,
  label: PropTypes.string,
  to: PropTypes.string
};

export default AppLink;
