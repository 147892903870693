import React from 'react';
import { LineChart } from '@mantine/charts';
import PropTypes from 'prop-types';
import { currencyFormat } from '../../../helpers/format';

const AnalyticsLineChart = ({
  mainDataLabel,
  compareDataLabel,
  chartData,
  dataKey,
  dataType
}) => {
  const isCurrency = dataType === 'currency';
  const isNumber = dataType === 'number' || isCurrency;

  const longestLabelLength =
    (chartData
      ?.map((c) =>
        (c[compareDataLabel] && c[compareDataLabel] > c[mainDataLabel]
          ? isNumber
            ? Math.floor(c[compareDataLabel]).toFixed(isCurrency ? 2 : 0)
            : c[compareDataLabel]
          : isNumber
          ? Math.floor(c[mainDataLabel]).toFixed(isCurrency ? 2 : 0)
          : c[mainDataLabel]
        ).toString()
      )
      .reduce(
        (acc, cur) => (cur.length > acc ? cur.length : acc),
        isCurrency ? 4.5 : 3
      ) || 3) + 1;

  const getSpacedDates = (dates, maxCount) => {
    const totalDates = dates.length;

    if (totalDates <= maxCount) {
      return dates;
    }

    const spacedDates = [dates[0]];
    const step = (totalDates - 1) / (maxCount - 1);
    for (let i = 1; i < maxCount - 1; i += 1) {
      const index = Math.round(i * step);
      spacedDates.push(dates[index]);
    }

    spacedDates.push(dates[totalDates - 1]);

    return spacedDates;
  };

  return chartData ? (
    <LineChart
      connectNulls
      curveType="linear"
      data={chartData}
      dataKey={dataKey}
      h="100%"
      legendProps={{
        iconType: 'plainline',
        verticalAlign: 'bottom',
        align: 'center'
      }}
      series={
        compareDataLabel
          ? [
              {
                name: mainDataLabel,
                color: 'blue.6'
              },
              {
                name: compareDataLabel,
                color: 'rgba(18, 184, 134, 0.8)',
                strokeDasharray: '5 5'
              }
            ]
          : [{ name: mainDataLabel, color: 'blue.6' }]
      }
      valueFormatter={isCurrency ? currencyFormat : null}
      withDots={false}
      withLegend
      xAxisProps={{
        ticks: getSpacedDates(
          chartData.map((d) => d.date),
          12
        )
      }}
      yAxisProps={{ width: longestLabelLength * 9, allowDecimals: false }}
    />
  ) : (
    <LineChart
      connectNulls
      curveType="linear"
      h="100%"
      legendProps={{
        iconType: 'plainline',
        verticalAlign: 'bottom',
        align: 'center'
      }}
      series={[]}
      withDots={false}
      withLegend
    />
  );
};

AnalyticsLineChart.propTypes = {
  chartData: PropTypes.array,
  compareDataLabel: PropTypes.string,
  dataKey: PropTypes.string,
  dataType: PropTypes.string,
  mainDataLabel: PropTypes.string
};

export default AnalyticsLineChart;
