import { Alert, Button, Checkbox, Input, Radio, Select } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { AlertCircle, Calendar, Pencil, X } from 'tabler-icons-react';
import { triggerNotification } from '../../../helpers/notificationHelper';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import FormSection from '../../common/FormSection';
import PaginationList from '../../common/PaginationList';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppTitle from '../../common/AppTitle';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import ActionableIcon from '../../common/ActionableIcon';
import AppRadioGroup from '../../common/AppRadioGroup';
import ExternalInviteCsvModal from '../invites/ExternalInviteCsvModal';

const LeagueDivisionInviteModal = ({
  isOpen,
  onClose,
  pkRegAssociation,
  pkRegAssociationSeason,
  selectedRegAssociationDivision,
  regAssociationDivisions
}) => {
  const fetchedPkSeason = useRef(null);
  const {
    state,
    fetchAdminRegAssociationRegistrants,
    fetchAdminRegAssociationDivisionFormInvites,
    sendAdminRegAssociationDivisionFormInvites,
    fetchAdminRegForms
  } = useContext(RegistrationAdminContext);
  const [formState, setFormState] = useState({
    pkRegAssociationDivision: '',
    fkRegAssociationDivisionForm: '',
    hasExpiryDate: false,
    expiresAt: null,
    selectedUsers: [],
    externalUsers: [],
    inviteType: 'INTERNAL',
    pageIndex: 1,
    errors: {},
    isLoading: false,
    showConfirm: false,
    showInviteCsvModal: false
  });
  const divisionOptions =
    regAssociationDivisions
      ?.filter(
        (d) =>
          d.pkRegAssociationDivision !==
          selectedRegAssociationDivision?.pkRegAssociationDivision
      )
      .map((d) => ({
        label: d.name,
        value: d.pkRegAssociationDivision.toString()
      })) ?? [];

  const availableDivisionRegistrants = state.regAssociationRegistrants.value
    .filter(
      (r) =>
        r.regAssociationDivisionForm.regAssociationDivision.pkRegAssociationDivision.toString() ===
          formState.pkRegAssociationDivision &&
        (r.status === 'ACCEPTED' || 'PENDING_PAYMENT')
    )
    .sort((a, b) =>
      `${a.regPerson.firstName}${a.regPerson.lastName}`.localeCompare(
        `${b.regPerson.firstName}${b.regPerson.lastName}`
      )
    );
  const availableRegFormOptions = state.regForms.value
    .filter((f) =>
      f.regAssociationDivisionForms.some(
        (df) =>
          df.fkRegAssociationDivision ===
          selectedRegAssociationDivision?.pkRegAssociationDivision
      )
    )
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((f) => ({
      label: f.name,
      value: f.regAssociationDivisionForms
        .find(
          (df) =>
            df.fkRegAssociationDivision ===
            selectedRegAssociationDivision?.pkRegAssociationDivision
        )
        .pkRegAssociationDivisionForm.toString()
    }));

  const existingDivisionForminvites = state.regAssociationDivisionFormInvites.value.filter(
    (i) =>
      i.regAssociationDivisionForm.regAssociationDivision
        .pkRegAssociationDivision ===
        selectedRegAssociationDivision?.pkRegAssociationDivision &&
      !i.deleted &&
      (!i.hasExpiryDate || new Date() < new Date(i.expiresAt))
  );

  const isLoading =
    !fetchedPkSeason.current ||
    state.regForms.loading ||
    state.regAssociationRegistrants.loading ||
    state.regAssociationDivisionFormInvites.loading;

  useEffect(() => {
    if (isOpen) {
      if (fetchedPkSeason.current !== pkRegAssociationSeason) {
        fetchAdminRegAssociationRegistrants(
          pkRegAssociation,
          {
            season: pkRegAssociationSeason,
            division: null
          },
          () => {},
          triggerNotification
        );
        fetchAdminRegForms();
        fetchAdminRegAssociationDivisionFormInvites({ pkRegAssociation });
        fetchedPkSeason.current = pkRegAssociationSeason;
      }
      setFormState({
        pkRegAssociationDivision: divisionOptions[0]?.value,
        fkRegAssociationDivisionForm: availableRegFormOptions[0]?.value,
        hasExpiryDate: false,
        expiresAt: null,
        selectedUsers: [],
        externalUsers: [
          { key: new Date().getTime(), email: '', firstName: '', lastName: '' }
        ],
        inviteType: 'INTERNAL',
        pageIndex: 1,
        errors: {},
        isLoading: false,
        showConfirm: false,
        showInviteCsvModal: false
      });
    }
  }, [isOpen]);

  useEffect(() => {
    setFormState({
      ...formState,
      fkRegAssociationDivisionForm: availableRegFormOptions[0]?.value
    });
  }, [state.regForms.value]);

  const changeExternalUserInput = (index, name, value) => {
    const externalUsers = [...formState.externalUsers];
    externalUsers[index][name] = value;

    if (index === formState.externalUsers.length - 1) {
      externalUsers.push({
        key: new Date().getTime(),
        email: '',
        firstName: '',
        lastName: ''
      });
    }

    setFormState({
      ...formState,
      externalUsers
    });
  };

  return (
    <ResponsiveModal
      isLoading={isLoading}
      isOpen={isOpen}
      onClose={onClose}
      title={<AppTitle order={2}>Invite Users</AppTitle>}
    >
      {!isLoading &&
        (formState.showConfirm ? (
          <FormSection
            cancelTitle="Back"
            isLoading={formState.isLoading}
            onCancel={() =>
              setFormState({
                ...formState,
                showConfirm: false
              })
            }
            onSubmit={() => {
              setFormState({
                ...formState,
                isLoading: true
              });
              sendAdminRegAssociationDivisionFormInvites(
                selectedRegAssociationDivision.pkRegAssociationDivision,
                formState.inviteType === 'EXTERNAL'
                  ? {
                      fkRegAssociationDivisionForm:
                        formState.fkRegAssociationDivisionForm,
                      expiresAt: formState.hasExpiryDate
                        ? formState.expiresAt
                        : null,
                      externalUsers: formState.externalUsers.filter(
                        (u) => u.email
                      )
                    }
                  : {
                      fkRegAssociationDivisionForm:
                        formState.fkRegAssociationDivisionForm,
                      expiresAt: formState.hasExpiryDate
                        ? formState.expiresAt
                        : null,
                      fkRegFormSubmissions: formState.selectedUsers.map(
                        (s) => s.pkRegFormSubmission
                      )
                    },
                () => {
                  triggerNotification('Invites Sent', 'Success', 'green');
                  onClose();
                },
                (message) => {
                  triggerNotification(message);
                  setFormState({
                    ...formState,
                    isLoading: false
                  });
                }
              );
            }}
            style={{ gap: 30 }}
            submitTitle="Invite"
          >
            <Alert variant="outline">
              <AppText>
                Are you sure you want to invite the following people to{' '}
                <b>{selectedRegAssociationDivision?.name}</b>
              </AppText>
            </Alert>
            <PaginationList
              emptyMessage="No users available"
              items={
                formState.inviteType === 'EXTERNAL'
                  ? formState.externalUsers
                      .filter((u) => u.email)
                      .map((r, index) => (
                        <AppStack
                          // eslint-disable-next-line react/no-array-index-key
                          key={index}
                          style={{ flex: 1, gap: 0, padding: 10 }}
                        >
                          <AppText style={{ lineHeight: '16px' }} weight={500}>
                            {r.firstName} {r.lastName}
                          </AppText>
                          <AppText
                            style={{ lineHeight: '16px', fontSize: 14 }}
                            weight={300}
                          >
                            {r.email}
                          </AppText>
                        </AppStack>
                      ))
                  : formState.selectedUsers.map((r) => (
                      <AppStack
                        key={r.pkRegFormSubmission}
                        style={{ flex: 1, gap: 0, padding: 10 }}
                      >
                        <AppText style={{ lineHeight: '16px' }} weight={500}>
                          {r.regPerson.firstName} {r.regPerson.lastName}
                        </AppText>
                        <AppText
                          style={{ lineHeight: '16px', fontSize: 14 }}
                          weight={300}
                        >
                          {r.user.email}
                        </AppText>
                      </AppStack>
                    ))
              }
              itemsPerPage={12}
            />
          </FormSection>
        ) : (
          <FormSection
            cancelTitle="Cancel"
            isLoading={formState.isLoading}
            isSubmitDisabled={
              (formState.inviteType === 'EXTERNAL' &&
                formState.externalUsers.length <= 1) ||
              (formState.inviteType === 'INTERNAL' &&
                formState.selectedUsers.length === 0)
            }
            onCancel={onClose}
            onSubmit={() => {
              if (
                formState.hasExpiryDate &&
                (!formState.expiresAt || formState.expiresAt < new Date())
              ) {
                triggerNotification('Invalid Expiry Date');
                setFormState({
                  ...formState,
                  errors: { expiresAt: { value: 'Invalid date' } }
                });
              }
              else {
                setFormState({
                  ...formState,
                  showConfirm: true
                });
              }
            }}
            style={{ gap: 30 }}
            submitTitle="Continue"
          >
            <AppStack style={{ gap: 20 }}>
              <AppRadioGroup
                onChange={(value) =>
                  setFormState({
                    ...formState,
                    inviteType: value,
                    pageIndex: 1
                  })
                }
                size="sm"
                spacing="lg"
                style={{ flex: 1 }}
                styles={{
                  label: { cursor: 'pointer' },
                  radio: { cursor: 'pointer' }
                }}
                value={formState.inviteType}
              >
                <Radio label="Invite from Submission" value="INTERNAL" />
                <Radio label="Invite by Email" value="EXTERNAL" />
              </AppRadioGroup>
              {formState.inviteType === 'EXTERNAL' ? (
                <AppStack style={{ gap: 5 }}>
                  <AppFlexbox
                    style={{
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <AppText weight={500}>Email Invites</AppText>

                    <Button
                      onClick={() =>
                        setFormState({
                          ...formState,
                          showInviteCsvModal: true
                        })
                      }
                      size="compact-sm"
                      variant="outline"
                    >
                      Import Contacts
                    </Button>
                  </AppFlexbox>
                  <AppStack style={{ gap: 0 }}>
                    <AppFlexbox
                      style={{ flex: 1, gap: 0, border: 'solid 1px lightgrey' }}
                    >
                      <AppStack
                        style={{
                          flex: 1,
                          borderRight: 'solid 1px lightgrey'
                        }}
                      >
                        <AppText style={{ margin: '5px 10px' }} weight={500}>
                          Email
                        </AppText>
                      </AppStack>
                      <AppStack
                        style={{
                          flex: 1,
                          borderRight: 'solid 1px lightgrey'
                        }}
                      >
                        <AppText style={{ margin: '5px 10px' }} weight={500}>
                          First Name
                        </AppText>
                      </AppStack>
                      <AppStack style={{ flex: 1 }}>
                        <AppText style={{ margin: '5px 10px' }} weight={500}>
                          Last Name
                        </AppText>
                      </AppStack>
                    </AppFlexbox>
                    <PaginationList
                      bottomAction={
                        formState.externalUsers.length > 1 && (
                          <Button
                            onClick={() =>
                              setFormState({
                                ...formState,
                                externalUsers: [
                                  {
                                    key: new Date().getTime(),
                                    email: '',
                                    firstName: '',
                                    lastName: ''
                                  }
                                ]
                              })
                            }
                            size="compact-sm"
                            style={{ marginLeft: 'auto' }}
                            variant="outline"
                          >
                            Clear Contacts
                          </Button>
                        )
                      }
                      items={formState.externalUsers
                        .sort((a, b) => a.key - b.key)
                        .map((u, index) => (
                          <AppFlexbox
                            key={u.key}
                            style={{
                              flex: 1,
                              gap: 0,
                              border: 'solid 1px lightgrey',
                              borderTop: 'none'
                            }}
                          >
                            <AppStack
                              // eslint-disable-next-line react/no-array-index-key
                              key={index}
                              style={{
                                flex: 1,
                                borderRight: 'solid 1px lightgrey'
                              }}
                            >
                              <Input
                                icon={<Pencil />}
                                onChange={(e) =>
                                  changeExternalUserInput(
                                    index,
                                    'email',
                                    e.currentTarget.value
                                  )
                                }
                                placeholder="sports@sportsheadz.com"
                                radius={0}
                                required={
                                  index !== formState.externalUsers.length - 1
                                }
                                style={{ flex: 1 }}
                                styles={{ input: { border: 'none' } }}
                                type="email"
                                value={u.email}
                              />
                            </AppStack>
                            <AppStack
                              style={{
                                flex: 1,
                                borderRight: 'solid 1px lightgrey'
                              }}
                            >
                              <Input
                                icon={<Pencil />}
                                onChange={(e) =>
                                  changeExternalUserInput(
                                    index,
                                    'firstName',
                                    e.currentTarget.value
                                  )
                                }
                                placeholder="John"
                                radius={0}
                                required={
                                  index !== formState.externalUsers.length - 1
                                }
                                style={{ flex: 1 }}
                                styles={{ input: { border: 'none' } }}
                                value={u.firstName}
                              />
                            </AppStack>
                            <AppStack style={{ flex: 1 }}>
                              <Input
                                icon={<Pencil />}
                                onChange={(e) =>
                                  changeExternalUserInput(
                                    index,
                                    'lastName',
                                    e.currentTarget.value
                                  )
                                }
                                placeholder="Doe"
                                radius={0}
                                required={
                                  index !== formState.externalUsers.length - 1
                                }
                                rightSection={
                                  index < formState.externalUsers.length - 1 ? (
                                    <ActionableIcon
                                      color="red"
                                      onClick={() => {
                                        setFormState({
                                          ...formState,
                                          externalUsers: [
                                            ...formState.externalUsers.filter(
                                              (f) => f.key !== u.key
                                            )
                                          ]
                                        });
                                      }}
                                    >
                                      <X />
                                    </ActionableIcon>
                                  ) : null
                                }
                                rightSectionPointerEvents="auto"
                                style={{ flex: 1 }}
                                styles={{ input: { border: 'none' } }}
                                value={u.lastName}
                              />
                            </AppStack>
                          </AppFlexbox>
                        ))}
                      itemsPerPage={12}
                      noBorder
                      onPageChange={(pageIndex) =>
                        setFormState({
                          ...formState,
                          pageIndex
                        })
                      }
                      pageIndex={formState.pageIndex}
                    />
                  </AppStack>
                </AppStack>
              ) : (
                <AppStack style={{ gap: 20 }}>
                  <Select
                    data={divisionOptions}
                    description="Select a division to invite users from"
                    label="Division"
                    onChange={(value) =>
                      setFormState({
                        ...formState,
                        pageIndex: 1,
                        pkRegAssociationDivision: value
                      })
                    }
                    value={formState.pkRegAssociationDivision}
                  />
                  <PaginationList
                    emptyMessage="No users available"
                    items={availableDivisionRegistrants.map((r) => {
                      const isAlreadyInvited = existingDivisionForminvites.find(
                        (i) =>
                          i.regFormSubmission.regPerson.pkRegPerson ===
                          r.regPerson.pkRegPerson
                      );

                      return (
                        <AppFlexbox
                          key={r.pkRegFormSubmission}
                          onClick={() => {
                            if (!isAlreadyInvited) {
                              if (
                                formState.selectedUsers.find(
                                  (u) =>
                                    u.regPerson.pkRegPerson ===
                                    r.regPerson.pkRegPerson
                                )
                              ) {
                                setFormState({
                                  ...formState,
                                  selectedUsers: formState.selectedUsers.filter(
                                    (u) =>
                                      u.regPerson.pkRegPerson !==
                                      r.regPerson.pkRegPerson
                                  )
                                });
                              }
                              else {
                                setFormState({
                                  ...formState,
                                  selectedUsers: [...formState.selectedUsers, r]
                                });
                              }
                            }
                          }}
                          style={{
                            padding: 10,
                            cursor: isAlreadyInvited
                              ? 'not-allowed'
                              : 'pointer',
                            placeItems: 'center'
                          }}
                        >
                          <Checkbox
                            checked={
                              isAlreadyInvited ||
                              (formState.selectedUsers.find(
                                (u) =>
                                  u.regPerson.pkRegPerson ===
                                  r.regPerson.pkRegPerson
                              ) ??
                                false)
                            }
                            disabled={isAlreadyInvited}
                            onChange={() => {}}
                          />
                          <AppStack
                            style={{ flex: 1, gap: 0, overflow: 'hidden' }}
                          >
                            <AppText
                              style={{ lineHeight: '16px' }}
                              weight={500}
                            >
                              {r.regPerson.firstName} {r.regPerson.lastName}
                            </AppText>
                            <AppText
                              style={{
                                lineHeight: '16px',
                                fontSize: 14,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                              }}
                              weight={300}
                            >
                              {r.user.email}
                            </AppText>
                          </AppStack>
                          {isAlreadyInvited && (
                            <AppText
                              style={{
                                lineHeight: '16px',
                                fontSize: 14,
                                whiteSpace: 'nowrap'
                              }}
                            >
                              Already invited
                            </AppText>
                          )}
                        </AppFlexbox>
                      );
                    })}
                    onPageChange={(pageIndex) =>
                      setFormState({
                        ...formState,
                        pageIndex
                      })
                    }
                    pageIndex={formState.pageIndex}
                  />
                </AppStack>
              )}
              <AppStack style={{ gap: 20 }}>
                <Select
                  clearable
                  data={availableRegFormOptions}
                  description="Select a form to invite the user to register for"
                  label="Registration Form"
                  onChange={(value) =>
                    setFormState({
                      ...formState,
                      pageIndex: 1,
                      fkRegAssociationDivisionForm: value
                    })
                  }
                  required
                  searchable
                  value={formState.fkRegAssociationDivisionForm}
                />
                <Checkbox
                  checked={formState.hasExpiryDate}
                  label="Invite Expires"
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      hasExpiryDate: e.currentTarget.checked
                    })
                  }
                  style={{ fontWeight: 500 }}
                />
                {formState.hasExpiryDate && (
                  <DatePickerInput
                    clearable
                    error={formState.errors.expiresAt?.value}
                    label="Expires At"
                    leftSection={<Calendar color="#000" size={16} />}
                    minDate={new Date()}
                    onChange={(value) =>
                      setFormState({
                        ...formState,
                        errors: {},
                        expiresAt: value
                      })
                    }
                    placeholder="MM/DD/YYYY"
                    required
                    value={formState.expiresAt}
                    valueFormat="MM/DD/YYYY"
                  />
                )}
              </AppStack>

              {((formState.inviteType === 'INTERNAL' &&
                formState.selectedUsers.length > 0) ||
                (formState.inviteType === 'EXTERNAL' &&
                  formState.externalUsers.length > 1)) && (
                <Alert
                  icon={<AlertCircle size={30} />}
                  styles={{
                    wrapper: { alignItems: 'center' },
                    icon: { width: 30, height: 25 }
                  }}
                  title={
                    <AppTitle order={4} style={{ height: 30 }}>
                      Invites Selected
                    </AppTitle>
                  }
                  variant="outline"
                >
                  <AppStack style={{ gap: 10 }}>
                    {formState.inviteType === 'INTERNAL' ? (
                      <AppText>
                        {formState.selectedUsers.length} registrant(s) selected
                        from{' '}
                        {
                          formState.selectedUsers.reduce((result, current) => {
                            if (
                              result.findIndex(
                                (r) =>
                                  r.regAssociationDivisionForm
                                    .regAssociationDivision
                                    .pkRegAssociationDivision ===
                                  current.regAssociationDivisionForm
                                    .regAssociationDivision
                                    .pkRegAssociationDivision
                              ) === -1
                            ) {
                              result.push(current);
                            }
                            return result;
                          }, []).length
                        }{' '}
                        Division(s)
                      </AppText>
                    ) : (
                      <AppText>
                        {formState.externalUsers.length - 1} registrant(s)
                        entered
                      </AppText>
                    )}
                  </AppStack>
                </Alert>
              )}
            </AppStack>
          </FormSection>
        ))}
      <ExternalInviteCsvModal
        isOpen={formState.showInviteCsvModal}
        onClose={() =>
          setFormState({
            ...formState,
            showInviteCsvModal: false
          })
        }
        onImport={(csvContacts) => {
          setFormState({
            ...formState,
            externalUsers: [
              ...formState.externalUsers.filter(
                (u) => u.email || u.firstName || u.lastName
              ),
              ...csvContacts
            ],
            showInviteCsvModal: false
          });
        }}
      />
    </ResponsiveModal>
  );
};

LeagueDivisionInviteModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  pkRegAssociation: PropTypes.number,
  pkRegAssociationSeason: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  regAssociationDivisions: PropTypes.array,
  selectedRegAssociationDivision: PropTypes.object
};

export default LeagueDivisionInviteModal;
