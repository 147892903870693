import React, { useEffect, useRef, useState } from 'react';
import { Accordion, Button, Skeleton } from '@mantine/core';
import PropTypes from 'prop-types';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import EvaluationSessionEvaluateMenuListItem from './EvaluationSessionEvaluateMenuListItem';

const MENU_LIST_VARIANTS = {
  progress: 'PROGRESS',
  default: 'DEFAULT'
};

const getSubListKey = (id, subId) => `subList-${id}-${subId}`;

const EvaluationSessionEvaluateMenuList = ({
  items,
  onSelectItem,
  onSelectSubItem,
  variant,
  hideCompleted,
  onChangeHideCompleted,
  onClearSearch,
  expandAll,
  isLoading
}) => {
  const hasInitialized = useRef(false);
  const [accordionState, setAccordionState] = useState({
    openKeys:
      items
        ?.filter((i) => expandAll || i.isSelected)
        .map((i) => i.key.toString()) || [],
    openSubListKeys:
      items?.reduce(
        (r, c) => [
          ...r,
          ...c.subSections.map((s) => getSubListKey(c.key, s.key))
        ],
        []
      ) || []
  });
  const menuVariant = MENU_LIST_VARIANTS[variant] || MENU_LIST_VARIANTS.default;

  const filteredItemList = items?.filter(
    (f) =>
      !hideCompleted ||
      f.subSections.some((section) => section.items.some((s) => !s.answerValue))
  );

  const selectedSubSectionKeys = !hasInitialized.current
    ? items?.reduce(
        (r, c) => [
          ...r,
          ...c.subSections.map((s) => getSubListKey(c.key, s.key))
        ],
        []
      ) || []
    : accordionState.openSubListKeys;

  useEffect(() => {
    if (!isLoading) {
      if (expandAll || !hasInitialized.current) {
        setAccordionState({
          openKeys:
            items
              ?.filter((i) => expandAll || i.isSelected)
              .map((i) => i.key.toString()) || [],
          // eslint-disable-next-line no-use-before-define
          openSubListKeys:
            items?.reduce(
              (r, c) => [
                ...r,
                ...c.subSections.map((s) => getSubListKey(c.key, s.key))
              ],
              []
            ) || []
        });
        hasInitialized.current = true;
      }
      else {
        setAccordionState({
          ...accordionState,
          openKeys: []
        });
      }
    }
  }, [expandAll, isLoading]);

  return !isLoading ? (
    <Accordion
      multiple
      onChange={() => {}}
      radius="lg"
      styles={{
        control: { padding: 10 },
        label: { padding: 0 }
      }}
      value={accordionState.openKeys}
      variant="separated"
    >
      {hideCompleted && filteredItemList.length === 0 ? (
        <AppStack style={{ gap: 5, padding: 30, alignItems: 'center' }}>
          <AppText style={{ textAlign: 'center' }} weight={500}>
            All Evaluations Completed
          </AppText>
          <Button
            onClick={() => onChangeHideCompleted(!hideCompleted)}
            size="compact-md"
            style={{ maxWidth: 250, width: '100%' }}
            variant="outline"
          >
            SHOW ALL
          </Button>
        </AppStack>
      ) : filteredItemList.length === 0 ? (
        <AppStack style={{ gap: 5, padding: 30, alignItems: 'center' }}>
          <AppText style={{ textAlign: 'center' }} weight={500}>
            No Evaluations Found
          </AppText>
          <Button
            onClick={onClearSearch}
            size="compact-md"
            style={{ maxWidth: 250, width: '100%' }}
            variant="outline"
          >
            CLEAR SEARCH
          </Button>
        </AppStack>
      ) : (
        filteredItemList.map((i) => (
          <EvaluationSessionEvaluateMenuListItem
            key={i.key}
            avatarLabel={i.avatarLabel}
            circleColor={i.circleColor}
            isOpen={accordionState.openKeys.includes(i.key.toString())}
            isSelected={i.isSelected}
            label={i.label}
            onSelectItem={() => {
              onSelectItem(i);
              const accordionItemValue = i.key.toString();
              setAccordionState((c) => ({
                ...accordionState,
                openKeys: !c.openKeys.includes(accordionItemValue)
                  ? [...c.openKeys, accordionItemValue]
                  : c.openKeys.filter((k) => k !== accordionItemValue)
              }));
            }}
            onSelectSection={(subListKey) => {
              setAccordionState((c) => ({
                ...c,
                openSubListKeys: !c.openSubListKeys.includes(subListKey)
                  ? [...c.openSubListKeys, subListKey]
                  : c.openSubListKeys.filter((k) => k !== subListKey)
              }));
            }}
            onSelectSubItem={(s) => onSelectSubItem(i, s)}
            subLabel={i.subLabel}
            subSections={i.subSections}
            subSectionValue={selectedSubSectionKeys.filter((k) =>
              i.subSections.some((s) => getSubListKey(i.key, s.key) === k)
            )}
            value={i.key.toString()}
            variant={menuVariant}
          />
        ))
      )}
    </Accordion>
  ) : (
    <Accordion
      multiple
      onChange={() => {}}
      radius="lg"
      styles={{
        control: { padding: 10 },
        label: { padding: 0 }
      }}
      value={accordionState.openKeys}
      variant="separated"
    >
      {Array.from(Array(3)).map((x, i) => (
        <Accordion.Item
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          style={{ backgroundColor: '#FFF' }}
          value={i.toString()}
        >
          <Accordion.Control>
            <AppFlexbox
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingRight: 10
              }}
            >
              <AppFlexbox style={{ gap: 10, alignItems: 'center' }}>
                <Skeleton circle height={45} width={45} />

                <Skeleton height={20} width={150} />
              </AppFlexbox>

              <Skeleton height={18} radius="lg" width={35} />
            </AppFlexbox>
          </Accordion.Control>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

EvaluationSessionEvaluateMenuList.propTypes = {
  expandAll: PropTypes.bool,
  hideCompleted: PropTypes.bool,
  isLoading: PropTypes.bool,
  items: PropTypes.array,
  onChangeHideCompleted: PropTypes.func,
  onClearSearch: PropTypes.func,
  onSelectItem: PropTypes.func,
  onSelectSubItem: PropTypes.func,
  variant: PropTypes.string
};

export default EvaluationSessionEvaluateMenuList;
