import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from '@mantine/core';

const MenuContent = ({ control, children, ...rest }) => (
  <Menu position="bottom-end" width={190} withinPortal {...rest}>
    <Menu.Target>{control}</Menu.Target>
    <Menu.Dropdown>{children}</Menu.Dropdown>
  </Menu>
);

MenuContent.propTypes = {
  children: PropTypes.any,
  control: PropTypes.any
};

export default MenuContent;
