import React, { useContext, useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Alert,
  Anchor,
  Button,
  Divider,
  Skeleton,
  useMantineTheme
} from '@mantine/core';
import { Trash } from 'tabler-icons-react';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppCard from '../../common/AppCard';
import AppImage from '../../common/AppImage';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import MerchPackageModal from '../merch/MerchPackageModal';

const CheckoutMerchItem = ({
  lineItem,
  regCartMerchPackage,
  isDisabled,
  isLoading,
  onUpdateMerchPackageVersion,
  withBorder
}) => {
  const mqIndex = useMediaQueryIndex();
  const theme = useMantineTheme();
  const { state, removeMerchPackagesFromCart } = useContext(
    RegistrationContext
  );
  const [modalState, setModalState] = useState({
    isOpen: false,
    action: null
  });
  const firstImage = regCartMerchPackage?.merchPackage.merchPackageImages.sort(
    (a, b) => a.sort - b.sort
  )[0];
  const requiredInputs =
    regCartMerchPackage?.merchPackage.merchPackageInputs.filter(
      (i) => i.isRequired
    ) || [];
  const allRequiredInputsFilled =
    requiredInputs.length === 0 ||
    requiredInputs.every((i) =>
      regCartMerchPackage.regCartMerchPackageInputAnswers.some(
        (a) => a.fkMerchPackageInput === i.pkMerchPackageInput
      )
    );
  const updatedMerchPackageVersion = state.merchPackages.value.find(
    (p) => p.uuid === regCartMerchPackage.merchPackage.uuid
  );
  const currency = lineItem.currency?.toUpperCase();

  return regCartMerchPackage ? (
    <AppCard
      key={regCartMerchPackage.pkRegCartMerchPackage}
      style={{
        padding: 0,
        borderRadius: rs([0, 0, 0, 4], mqIndex),
        border: withBorder ? 'solid 1px lightgrey' : 'none'
      }}
    >
      <MerchPackageModal
        isDisabled={isDisabled}
        isOpen={modalState.isOpen}
        merchPackage={regCartMerchPackage.merchPackage}
        onClose={() =>
          setModalState({
            ...modalState,
            isOpen: false
          })
        }
        regCartMerchPackage={regCartMerchPackage}
      />
      {!isDisabled &&
        !lineItem.purchasedDate &&
        regCartMerchPackage.merchPackage.deleted && (
          <Alert
            color={updatedMerchPackageVersion ? 'yellow' : 'red'}
            style={{ borderRadius: 0 }}
            variant="filled"
          >
            {updatedMerchPackageVersion ? (
              <AppText color="#000" weight={500}>
                Info has changed.{' '}
                <Anchor onClick={onUpdateMerchPackageVersion}>
                  Click here to update your cart.
                </Anchor>
              </AppText>
            ) : (
              <AppText weight={500}>
                Merch package is no longer available.
              </AppText>
            )}
          </Alert>
        )}
      <Divider style={{ display: rs(['flex', 'flex', 'none'], mqIndex) }} />
      <AppStack style={{ gap: 0, flexWrap: 'nowrap' }}>
        <AppFlexbox
          style={{
            flex: 1,
            margin: 30,
            flexWrap: 'nowrap',
            flexDirection: rs(['column', 'column', 'row'], mqIndex)
          }}
        >
          <AppFlexbox
            style={{ flexWrap: 'nowrap', alignSelf: 'stretch', flex: 2 }}
          >
            <AppFlexbox style={{ alignSelf: 'start', flex: 1 }}>
              <AppFlexbox
                component={Link}
                to={`/association/${regCartMerchPackage.fkRegAssociation}`}
              >
                <AppImage
                  fit="contain"
                  height={100}
                  src={firstImage?.image || ''}
                  width={100}
                />
              </AppFlexbox>

              <AppStack
                style={{
                  flex: 1,
                  gap: 0,
                  textDecoration: 'none',
                  justifyContent: 'center',
                  color: '#000'
                }}
              >
                <AppText size="lg">
                  {regCartMerchPackage.merchPackage.name}
                </AppText>
                <AppText
                  style={{
                    fontSize: 14,
                    color: 'grey',
                    textOverflow: 'ellipsis',
                    lineHeight: '16px',
                    display: '-webkit-box',
                    WebkitLineClamp: '2',
                    lineClamp: '2',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    whiteSpace: 'pre-wrap'
                  }}
                >
                  {regCartMerchPackage.merchPackage.description}
                </AppText>

                {lineItem.addOns.length > 0 && (
                  <>
                    <Divider
                      label={
                        <AppText color="dark" weight={700}>
                          Add-ons
                        </AppText>
                      }
                      labelPosition="center"
                      style={{ marginTop: 10 }}
                    />

                    <AppStack style={{ marginTop: 10, gap: 15 }}>
                      {lineItem.addOns.map((addOn) => (
                        <AppFlexbox
                          key={addOn.productName}
                          style={{ gap: 5, justifyContent: 'space-between' }}
                        >
                          <AppText>{addOn.productName}</AppText>
                          <AppFlexbox style={{ gap: 5 }}>
                            <AppText weight={700}>
                              <NumberFormat
                                decimalScale={2}
                                displayType="text"
                                fixedDecimalScale
                                prefix="$"
                                thousandSeparator
                                value={addOn.unitAmountInCents / 100}
                              />{' '}
                              {currency}
                            </AppText>
                          </AppFlexbox>
                        </AppFlexbox>
                      ))}
                    </AppStack>
                  </>
                )}
              </AppStack>
            </AppFlexbox>
          </AppFlexbox>

          <AppStack
            style={{
              flex: 1,
              alignSelf: 'start',
              placeItems: 'end',
              marginLeft: 'auto',
              gap: rs([5, 10], mqIndex),
              flexDirection: rs(
                ['row-reverse', 'row-reverse', 'column'],
                mqIndex
              ),
              justifyContent: rs(
                ['space-between', 'space-between', 'inital'],
                mqIndex
              ),
              width: rs(['100%', '100%', 'unset'], mqIndex)
            }}
          >
            <AppStack style={{ gap: 0, alignItems: 'end' }}>
              <AppFlexbox style={{ gap: 10, flexWrap: 'nowrap' }}>
                <AppText
                  size="xl"
                  style={{
                    textDecoration: lineItem.financialAssistance
                      ? 'line-through'
                      : 'normal'
                  }}
                  weight={700}
                >
                  <NumberFormat
                    decimalScale={2}
                    displayType="text"
                    fixedDecimalScale
                    prefix="$"
                    thousandSeparator
                    value={lineItem.subtotalInCents / 100}
                  />{' '}
                  {currency}
                </AppText>
              </AppFlexbox>
              {lineItem.taxInCents > 0 && (
                <AppFlexbox style={{ gap: 5, flexWrap: 'nowrap' }}>
                  <AppText
                    size="md"
                    style={{
                      textDecoration: lineItem.financialAssistance
                        ? 'line-through'
                        : 'normal'
                    }}
                    weight={500}
                  >
                    +{' '}
                    <NumberFormat
                      decimalScale={2}
                      displayType="text"
                      fixedDecimalScale
                      prefix="$"
                      thousandSeparator
                      value={lineItem.taxInCents / 100}
                    />{' '}
                    @{' '}
                    <NumberFormat
                      displayType="text"
                      fixedDecimalScale
                      suffix="%"
                      thousandSeparator
                      value={lineItem.taxRate * 100}
                    />{' '}
                    TAX
                  </AppText>
                </AppFlexbox>
              )}
              {lineItem.customerPaysServiceFees &&
                lineItem.serviceFeeInCents > 0 && (
                  <AppFlexbox style={{ gap: 5, flexWrap: 'nowrap' }}>
                    <AppText
                      size="md"
                      style={{
                        textDecoration: lineItem.financialAssistance
                          ? 'line-through'
                          : 'normal'
                      }}
                      weight={500}
                    >
                      +{' '}
                      <NumberFormat
                        decimalScale={2}
                        displayType="text"
                        fixedDecimalScale
                        prefix="$"
                        thousandSeparator
                        value={lineItem.serviceFeeInCents / 100}
                      />{' '}
                      Service fee
                    </AppText>
                  </AppFlexbox>
                )}
              {lineItem.financialAssistance && (
                <AppFlexbox style={{ gap: 5, flexWrap: 'nowrap' }}>
                  <AppText size="md" weight={500}>
                    Jumpstart{' '}
                    <NumberFormat
                      decimalScale={2}
                      displayType="text"
                      fixedDecimalScale
                      prefix="$"
                      thousandSeparator
                      value={0.0}
                    />{' '}
                    {currency}
                  </AppText>
                </AppFlexbox>
              )}
            </AppStack>
            {!isDisabled && (
              <AppStack style={{ gap: 20, flex: 1 }}>
                <AppFlexbox
                  onClick={() =>
                    removeMerchPackagesFromCart([
                      regCartMerchPackage.pkRegCartMerchPackage
                    ])
                  }
                  style={{
                    cursor: 'pointer',
                    gap: 10,
                    alignSelf: rs(['start', 'start', 'end'], mqIndex)
                  }}
                >
                  <Trash />
                  <AppText>Remove</AppText>
                </AppFlexbox>
              </AppStack>
            )}
          </AppStack>
        </AppFlexbox>
        <AppFlexbox
          style={{
            backgroundColor: theme.black,
            color: '#FFF',
            opacity: 0.8,
            padding: 30,
            justifyContent: 'space-between'
          }}
        >
          <AppStack style={{ gap: 0 }}>
            <AppText weight={700}>Merch Package</AppText>
            <AppText>{regCartMerchPackage.merchPackage.name}</AppText>
          </AppStack>

          {isDisabled ? (
            <Button
              color="gray"
              disabled={isLoading}
              onClick={() => setModalState({ isOpen: true, action: 'view' })}
              radius="xs"
              size="lg"
              style={{
                color: theme.white,
                borderColor: theme.white,
                width: 200
              }}
              variant="outline"
            >
              View Info
            </Button>
          ) : allRequiredInputsFilled ? (
            <Button
              color="blue"
              disabled={isLoading}
              onClick={() => setModalState({ isOpen: true, action: 'view' })}
              radius="xs"
              size="lg"
              style={{ width: 200 }}
              variant="filled"
            >
              {regCartMerchPackage.merchPackage.merchPackageInputs.length === 0
                ? 'View Info'
                : 'Update Info'}
            </Button>
          ) : (
            <Button
              color="gray"
              disabled={isLoading}
              onClick={() => setModalState({ isOpen: true, action: 'view' })}
              radius="xs"
              size="lg"
              style={{
                color: theme.white,
                borderColor: theme.white,
                animationName: 'pulse',
                animationDuration: '2s',
                animationIterationCount: 'infinite',
                width: 200,
                '@keyframes pulse': { '50%': { borderColor: 'red' } }
              }}
              variant="outline"
            >
              Fill out info
            </Button>
          )}
        </AppFlexbox>
      </AppStack>
    </AppCard>
  ) : (
    <AppCard
      style={{
        padding: 0,
        borderRadius: rs([0, 0, 0, 4], mqIndex),
        border: withBorder ? 'solid 1px lightgrey' : 'none'
      }}
    >
      <Divider style={{ display: rs(['flex', 'flex', 'none'], mqIndex) }} />
      <AppStack style={{ gap: 0, flexWrap: 'nowrap' }}>
        <AppFlexbox
          style={{
            margin: 30,
            flexWrap: 'nowrap',
            flexDirection: rs(['column', 'column', 'column', 'row'], mqIndex)
          }}
        >
          <AppFlexbox
            style={{ flexWrap: 'nowrap', flex: 2, alignSelf: 'stretch' }}
          >
            <Skeleton height={100} width={100} />
            <AppStack
              style={{
                flex: 1,
                gap: 10,
                textDecoration: 'none',
                justifyContent: 'center',
                color: '#000'
              }}
            >
              <Skeleton height={16} width="50%" />
              <Skeleton height={14} width="40%" />
            </AppStack>
          </AppFlexbox>

          <AppStack
            style={{
              flex: 1,
              alignSelf: 'start',
              placeItems: 'end',
              marginLeft: 'auto',
              gap: rs([0, 30], mqIndex),
              flexDirection: rs(
                ['row-reverse', 'row-reverse', 'row-reverse', 'column'],
                mqIndex
              ),
              justifyContent: rs(
                ['space-between', 'space-between', 'space-between', 'inital'],
                mqIndex
              ),
              width: rs(['100%', '100%', '100%', 'unset'], mqIndex)
            }}
          >
            <AppStack style={{ gap: 0, alignItems: 'end' }}>
              <Skeleton height={20} width={100} />
            </AppStack>

            {!isDisabled && (
              <AppFlexbox style={{ cursor: 'pointer', gap: 10 }}>
                <Skeleton height={15} width={80} />
              </AppFlexbox>
            )}
          </AppStack>
        </AppFlexbox>
        <AppFlexbox
          style={{
            backgroundColor: theme.black,
            color: '#FFF',
            opacity: 0.8,
            padding: 30,
            justifyContent: 'space-between'
          }}
        >
          <AppStack style={{ gap: 10 }}>
            <Skeleton height={10} width={100} />
            <Skeleton height={10} width={100} />
          </AppStack>

          <Skeleton height={48} width={200} />
        </AppFlexbox>
      </AppStack>
    </AppCard>
  );
};

CheckoutMerchItem.propTypes = {
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  lineItem: PropTypes.object,
  onUpdateMerchPackageVersion: PropTypes.func,
  regCartMerchPackage: PropTypes.object,
  withBorder: PropTypes.bool
};

export default CheckoutMerchItem;
