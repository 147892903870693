import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@mantine/core';
import AppFlexbox from './AppFlexbox';
import AppText from './AppText';
import PaginationList from './PaginationList';

const PaginationSelectList = ({
  items,
  selectedItemKeys,
  onChange,
  disabled,
  ...rest
}) => {
  const [pageIndexState, setPageIndexState] = useState(1);

  return (
    <PaginationList
      items={items.map((i) => (
        <AppFlexbox
          key={i.key}
          onClick={() => {
            if (!disabled) {
              if (selectedItemKeys.includes(i.key)) {
                onChange(selectedItemKeys.filter((f) => f !== i.key));
              }
              else {
                onChange([...selectedItemKeys, i.key]);
              }
            }
          }}
          style={{
            padding: 10,
            alignItems: 'center',
            cursor: 'pointer'
          }}
        >
          <Checkbox
            checked={selectedItemKeys.includes(i.key)}
            disabled={disabled}
            onChange={() => {}}
          />
          <AppText style={{ fontSize: 14 }} weight={500}>
            {i.label}
          </AppText>
        </AppFlexbox>
      ))}
      itemsPerPage={10}
      onPageChange={setPageIndexState}
      pageIndex={pageIndexState}
      {...rest}
    />
  );
};

PaginationSelectList.propTypes = {
  disabled: PropTypes.bool,
  items: PropTypes.array,
  onChange: PropTypes.func,
  selectedItemKeys: PropTypes.array
};

export default PaginationSelectList;
