import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Alert, Checkbox, Divider, Select, TextInput } from '@mantine/core';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppTitle from '../../common/AppTitle';
import FormSection from '../../common/FormSection';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import PaginationList from '../../common/PaginationList';
import AppText from '../../common/AppText';
import {
  REG_FORM_CONTROL_TYPE_ENUM,
  REG_SCOUT_REPORT_QUESTION_RESTRICTION_CONDITIONS,
  REG_SCOUT_REPORT_QUESTION_TYPE
} from '../../../config/constants';

const EvaluationTemplateQuestionRestrictionModal = ({
  isOpen,
  onClose,
  onConfirm,
  regScoutReportQuestionRestriction,
  regScoutReportQuestion,
  regScoutReportQuestions
}) => {
  const hasInitialized = useRef(false);
  const [formState, setFormState] = useState({
    condition: '',
    conditionValue: '',
    selectedQuestions: [],
    seletedAttributeQuestionKey: null,
    pageIndex: 1
  });
  const evaluationQuestions =
    regScoutReportQuestions.filter(
      (f) =>
        f.fkRegScoutReportQuestionType === REG_SCOUT_REPORT_QUESTION_TYPE.METRIC
    ) || [];

  const attributeQuestions =
    regScoutReportQuestions.filter(
      (f) =>
        f.fkRegScoutReportQuestionType ===
        REG_SCOUT_REPORT_QUESTION_TYPE.ATTRIBUTE
    ) || [];

  const allQuestionsSelected = evaluationQuestions.every((q) =>
    formState.selectedQuestions.includes(q.key)
  );

  const isAttribute =
    regScoutReportQuestion?.fkRegScoutReportQuestionType ===
    REG_SCOUT_REPORT_QUESTION_TYPE.ATTRIBUTE;

  const restrictionAttributeQuestion = isAttribute
    ? regScoutReportQuestion
    : regScoutReportQuestions.find(
        (q) =>
          q.key ===
            regScoutReportQuestionRestriction?.questionConditionRefNumber ||
          q.key === formState.seletedAttributeQuestionKey
      );

  const attributeQuestionOptions =
    restrictionAttributeQuestion?.regScoutReportQuestionOptions.map((q) => ({
      value: q.key.toString(),
      label: q.value.trim()
    })) || [];

  useEffect(() => {
    if (isOpen) {
      if (regScoutReportQuestionRestriction) {
        setFormState({
          condition: regScoutReportQuestionRestriction.condition,
          conditionValue: regScoutReportQuestionRestriction.conditionValue,
          selectedQuestions:
            regScoutReportQuestion.regScoutReportQuestionRestrictions
              .filter(
                (r) =>
                  r.conditionValue ===
                    regScoutReportQuestionRestriction.conditionValue &&
                  r.condition === regScoutReportQuestionRestriction.condition
              )
              .map((r) => r.questionRestrictedRefNumber) || [],
          seletedAttributeQuestionKey: null,
          pageIndex: 1
        });
      }
      else {
        setFormState({
          condition: '',
          conditionValue: '',
          selectedQuestions: [],
          seletedAttributeQuestionKey: null,
          pageIndex: 1
        });
      }
      hasInitialized.current = true;
    }
    else {
      hasInitialized.current = false;
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      onClose={onClose}
      opened={isOpen}
      title={
        <AppTitle order={2}>
          {isAttribute ? 'Restrict Questions' : 'Restrict Question'}
        </AppTitle>
      }
    >
      {!restrictionAttributeQuestion ? (
        <PaginationList
          items={attributeQuestions
            .sort((a, b) => a.sort - b.sort)
            .map((q) => (
              <AppFlexbox
                key={q.key}
                onClick={() =>
                  setFormState({
                    ...formState,
                    seletedAttributeQuestionKey: q.key
                  })
                }
                style={{
                  padding: 10,
                  border: 'solid 1px lightgrey',
                  cursor: 'pointer'
                }}
              >
                <AppText weight={500}>{q.value}</AppText>
              </AppFlexbox>
            ))}
          listProps={{ style: { gap: 10 } }}
          noBorder
        />
      ) : (
        <FormSection
          isSubmitDisabled={
            (formState.selectedQuestions.length === 0 && isAttribute) ||
            !formState.conditionValue ||
            !formState.condition
          }
          onCancel={onClose}
          onSubmit={(e) => {
            e.stopPropagation();
            onConfirm(
              isAttribute
                ? formState.selectedQuestions.map((q) => ({
                    key: new Date().getTime(),
                    questionRestrictedRefNumber: q,
                    questionConditionRefNumber: regScoutReportQuestion.key,
                    condition: formState.condition,
                    conditionValue: formState.conditionValue
                  }))
                : [
                    {
                      key: new Date().getTime(),
                      questionRestrictedRefNumber: regScoutReportQuestion.key,
                      questionConditionRefNumber:
                        restrictionAttributeQuestion.key,
                      condition: formState.condition,
                      conditionValue: formState.conditionValue
                    }
                  ]
            );
          }}
          submitTitle="Confirm"
        >
          <AppStack style={{ gap: 20, minHeight: 300 }}>
            {hasInitialized.current && (
              <>
                <Alert variant="outline">
                  <AppText>
                    Restrict questions to be answered based on the value of
                    another question
                  </AppText>
                </Alert>

                <AppFlexbox>
                  <Select
                    clearable
                    data={REG_SCOUT_REPORT_QUESTION_RESTRICTION_CONDITIONS}
                    label="Condition"
                    onChange={(value) =>
                      setFormState({
                        ...formState,
                        condition: value
                      })
                    }
                    required
                    searchable
                    style={{ flex: 1 }}
                    value={formState.condition}
                  />
                  {restrictionAttributeQuestion?.fkRegFormControlType ===
                  REG_FORM_CONTROL_TYPE_ENUM.TEXT_INPUT ? (
                    <TextInput
                      label="Value"
                      onChange={(e) =>
                        setFormState({
                          ...formState,
                          conditionValue: e.currentTarget.value
                        })
                      }
                      required
                      style={{ flex: 1 }}
                      value={formState.conditionValue}
                    />
                  ) : (
                    <Select
                      clearable
                      data={attributeQuestionOptions}
                      label="Value"
                      onChange={(value) =>
                        setFormState({
                          ...formState,
                          conditionValue:
                            attributeQuestionOptions.find(
                              (a) => a.value === value
                            )?.label || ''
                        })
                      }
                      required
                      searchable
                      style={{ flex: 1 }}
                      value={
                        attributeQuestionOptions.find(
                          (a) => a.label === formState.conditionValue.trim()
                        )?.value
                      }
                    />
                  )}
                </AppFlexbox>

                <Divider
                  label={isAttribute ? 'ASSIGN QUESTIONS' : 'ASSIGNED QUESTION'}
                  labelPosition="center"
                />

                {isAttribute ? (
                  <AppStack style={{ flex: 1, gap: 5 }}>
                    <AppFlexbox style={{ justifyContent: 'space-between' }}>
                      <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                        Questions
                      </AppText>

                      <Checkbox
                        checked={allQuestionsSelected}
                        label={
                          allQuestionsSelected ? 'Unselect all' : 'Select all'
                        }
                        onChange={() => {
                          if (allQuestionsSelected) {
                            setFormState({
                              ...formState,
                              selectedQuestions: []
                            });
                          }
                          else {
                            setFormState({
                              ...formState,
                              selectedQuestions: evaluationQuestions.map(
                                (q) => q.key
                              )
                            });
                          }
                        }}
                        style={{ fontWeight: 500 }}
                      />
                    </AppFlexbox>
                    <PaginationList
                      emptyMessage="No questions available"
                      items={evaluationQuestions
                        .sort((a, b) => a.sort - b.sort)
                        .map((q) => {
                          const isSelected = formState.selectedQuestions.includes(
                            q.key
                          );
                          return (
                            <AppFlexbox
                              key={q.key}
                              onClick={() => {
                                if (isSelected) {
                                  setFormState({
                                    ...formState,
                                    selectedQuestions: formState.selectedQuestions.filter(
                                      (key) => key !== q.key
                                    )
                                  });
                                }
                                else {
                                  setFormState({
                                    ...formState,
                                    selectedQuestions: [
                                      ...formState.selectedQuestions,
                                      q.key
                                    ]
                                  });
                                }
                              }}
                              style={{
                                cursor: 'pointer',
                                padding: 10,
                                gap: 10,
                                alignItems: 'center'
                              }}
                            >
                              <Checkbox
                                checked={isSelected}
                                onChange={() => {}}
                              />
                              <AppText weight={500}>{q.value}</AppText>
                            </AppFlexbox>
                          );
                        })}
                      onPageChange={(pageIndex) => {
                        setFormState({ ...formState, pageIndex });
                      }}
                      pageIndex={formState.pageIndex}
                    />
                  </AppStack>
                ) : (
                  <AppStack style={{ flex: 1, gap: 5 }}>
                    <AppFlexbox style={{ justifyContent: 'space-between' }}>
                      <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                        Question
                      </AppText>
                    </AppFlexbox>
                    <AppFlexbox
                      key={regScoutReportQuestion.key}
                      style={{
                        padding: 10,
                        gap: 10,
                        alignItems: 'center',
                        border: 'solid 1px lightgrey'
                      }}
                    >
                      <AppText weight={500}>
                        {regScoutReportQuestion.value}
                      </AppText>
                    </AppFlexbox>
                  </AppStack>
                )}
              </>
            )}
          </AppStack>
        </FormSection>
      )}
    </ResponsiveModal>
  );
};

EvaluationTemplateQuestionRestrictionModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  regScoutReportQuestion: PropTypes.object,
  regScoutReportQuestionRestriction: PropTypes.object,
  regScoutReportQuestions: PropTypes.array
};

export default EvaluationTemplateQuestionRestrictionModal;
