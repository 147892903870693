import { PropTypes } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Divider } from '@mantine/core';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { triggerNotification } from '../../../helpers/notificationHelper';
import FormSection from '../../common/FormSection';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppTitle from '../../common/AppTitle';
import InviteListItem from './InviteListItem';

const ExpireInviteModal = ({ isOpen, onClose, invite, isAdminViewing }) => {
  const {
    expireAdminRegAssociationDivisionFormInvite,
    expireAdminRegAssociationDivisionFormExternalInvite
  } = useContext(RegistrationAdminContext);
  const {
    expireRegAssociationDivisionFormInvite,
    expireRegAssociationDivisionFormExternalInvite
  } = useContext(RegistrationContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsLoading(false);
    }
  }, [isOpen]);

  const onSuccess = () => {
    onClose();
  };

  const onError = (message) => {
    triggerNotification(message);
    setIsLoading(false);
  };

  return (
    <ResponsiveModal
      onClose={onClose}
      opened={isOpen}
      size={600}
      title={
        <AppTitle order={2}>
          {isAdminViewing ? 'Expire' : 'Decline'} Invite
        </AppTitle>
      }
    >
      <FormSection
        cancelTitle="Cancel"
        isLoading={isLoading}
        onCancel={onClose}
        onSubmit={() => {
          setIsLoading(true);
          if (invite.isExternalInvite) {
            if (isAdminViewing) {
              expireAdminRegAssociationDivisionFormExternalInvite(
                invite.pkInvite,
                { inviteCode: invite.inviteCode },
                onSuccess,
                onError
              );
            }
            else {
              expireRegAssociationDivisionFormExternalInvite(
                invite.pkInvite,
                { inviteCode: invite.inviteCode },
                onSuccess,
                onError
              );
            }
          }
          else if (isAdminViewing) {
            expireAdminRegAssociationDivisionFormInvite(
              invite.pkInvite,
              onSuccess,
              onError
            );
          }
          else {
            expireRegAssociationDivisionFormInvite(
              invite.pkInvite,
              onSuccess,
              onError
            );
          }
        }}
        submitColor="red"
        submitTitle={isAdminViewing ? 'Expire' : 'Decline'}
      >
        <AppStack style={{ gap: 20, marginTop: 10 }}>
          <InviteListItem {...invite} />
          <Divider />
          <AppText style={{ textAlign: 'center', marginTop: 20 }}>
            Are you sure you want to{' '}
            <b>{isAdminViewing ? 'expire' : 'decline'}</b> the selected invite?
          </AppText>
        </AppStack>
      </FormSection>
    </ResponsiveModal>
  );
};

ExpireInviteModal.propTypes = {
  invite: PropTypes.object,
  isAdminViewing: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default ExpireInviteModal;
