import PropTypes from 'prop-types';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { changeListItemSortOrder } from '../../helpers/format';
import AppStack from './AppStack';

const DragDropList = ({
  items,
  renderOptions,
  onUpdate,
  sortSelector,
  listProps
}) => {
  const selector = sortSelector || 'sort';

  return (
    <AppStack>
      <DragDropContext
        onDragEnd={(e) => {
          if (e.source?.index && e.destination?.index) {
            const newListSort = changeListItemSortOrder(
              items,
              e.source.index,
              e.destination.index,
              selector
            );
            onUpdate(newListSort);
          }
        }}
      >
        <Droppable droppableId="droppable">
          {(dropProvided) => (
            <div {...dropProvided.droppableProps} ref={dropProvided.innerRef}>
              <AppStack {...listProps} style={{ gap: 5, ...listProps?.style }}>
                {items
                  .sort((a, b) => a[selector] - b[selector])
                  .map((item, index) => (
                    <Draggable
                      key={item.key}
                      draggableId={item.key.toString()}
                      index={index + 1}
                    >
                      {(dragProvided) => (
                        <div
                          ref={dragProvided.innerRef}
                          {...dragProvided.draggableProps}
                          {...dragProvided.dragHandleProps}
                        >
                          <AppStack style={{ backgroundColor: '#FFF' }}>
                            {renderOptions({ item, index })}
                          </AppStack>
                        </div>
                      )}
                    </Draggable>
                  ))}
              </AppStack>
              {dropProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </AppStack>
  );
};

DragDropList.propTypes = {
  ItemComponent: PropTypes.any,
  items: PropTypes.array,
  listProps: PropTypes.object,
  onUpdate: PropTypes.func,
  renderOptions: PropTypes.func,
  renderedItems: PropTypes.array,
  sortSelector: PropTypes.string
};

export default DragDropList;
