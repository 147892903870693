import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Divider,
  Menu,
  ScrollArea,
  TextInput
} from '@mantine/core';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import MenuContent from '../../common/MenuContent';
import AppText from '../../common/AppText';

const AnalyticsSeasonSelectMenu = ({
  seasonOptions,
  value,
  onChange,
  isCompare,
  loading
}) => {
  const [formState, setFormState] = useState({ search: '' });

  return (
    <MenuContent
      closeOnClickOutside
      closeOnItemClick
      control={
        <Button
          color="dark"
          loading={loading}
          radius={8}
          size="compact-sm"
          style={{ border: 'solid 1px #B1B1B1', minWidth: 50 }}
          variant="white"
        >
          <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
              {isCompare && (
                <>
                  <span style={{ color: '#999' }}>Compared to:</span>{' '}
                </>
              )}
              {seasonOptions.find((s) => s.value === value)?.label}
            </AppText>
          </AppFlexbox>
        </Button>
      }
      position="bottom-start"
      radius="md"
      shadow="xl"
      styles={{ dropdown: { padding: 0 } }}
      width={300}
    >
      <AppStack style={{ gap: 0, flex: 1 }}>
        <TextInput
          onChange={(e) => {
            setFormState({
              ...formState,
              search: e.currentTarget.value
            });
          }}
          placeholder="Search by name..."
          radius={0}
          size="sm"
          style={{ flex: 1 }}
          styles={{ input: { border: 'none' } }}
          value={formState.search}
          variant="filled"
        />
        <Divider />
        <ScrollArea.Autosize mah={250} scrollbarSize={5} type="scroll">
          {seasonOptions
            .filter(
              (s) =>
                !formState.search ||
                s.label.toLowerCase().includes(formState.search.toLowerCase())
            )
            .map((s) => (
              <Menu.Item
                key={s.value}
                onClick={(e) => {
                  e.preventDefault();
                  if (s.value !== value) {
                    onChange(s.value);
                  }
                }}
              >
                <AppFlexbox style={{ alignItems: 'center', gap: 12 }}>
                  <Checkbox checked={value === s.value} onChange={() => {}} />
                  <AppText style={{ fontSize: 14 }}>{s.label}</AppText>
                </AppFlexbox>
              </Menu.Item>
            ))}
        </ScrollArea.Autosize>
      </AppStack>
    </MenuContent>
  );
};

AnalyticsSeasonSelectMenu.propTypes = {
  isCompare: PropTypes.bool,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  seasonOptions: PropTypes.array,
  value: PropTypes.string
};

export default AnalyticsSeasonSelectMenu;
