import React, { useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Divider } from '@mantine/core';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import RegistrationListItem from './RegistrationListItem';
import { triggerNotification } from '../../../helpers/notificationHelper';
import FormSection from '../../common/FormSection';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppTitle from '../../common/AppTitle';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';

const RegistrationInvoiceResendModal = ({
  isOpen,
  onClose,
  regAssociation,
  regFormSubmissionBalance,
  regFormSubmissionInvoice
}) => {
  const {
    resendAdminRegistrationInvoice,
    fetchAdminRegFormSubmissionPayments
  } = useContext(RegistrationAdminContext);
  const [formState, setFormState] = useState({ loading: false });

  useEffect(() => {
    if (isOpen) {
      setFormState({ loading: false });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      onClose={onClose}
      opened={isOpen}
      size={600}
      title={
        <AppTitle order={2}>
          {regFormSubmissionInvoice?.status === 'PAID'
            ? 'Resend receipt'
            : 'Resend invoice'}
        </AppTitle>
      }
    >
      <AppStack style={{ gap: 20 }}>
        <RegistrationListItem
          regAssociation={regAssociation}
          regFormSubmission={regFormSubmissionBalance}
        />
        <FormSection
          cancelTitle="Back"
          isLoading={formState.loading}
          onCancel={onClose}
          onSubmit={() => {
            setFormState({
              ...formState,
              loading: true
            });
            resendAdminRegistrationInvoice(
              regFormSubmissionInvoice.pkRegFormSubmissionInvoice,
              () => {
                if (!regFormSubmissionInvoice.stripeInvoiceId) {
                  fetchAdminRegFormSubmissionPayments(
                    regFormSubmissionBalance.pkRegFormSubmission
                  );
                }
                triggerNotification(
                  regFormSubmissionInvoice.status === 'PAID'
                    ? 'Receipt sent'
                    : 'Invoice sent',
                  'Success',
                  'green'
                );
                onClose();
              },
              (e) => {
                setFormState({
                  ...formState,
                  loading: false
                });
                triggerNotification(e);
              }
            );
          }}
          style={{ gap: 30 }}
          submitTitle="Send"
        >
          <Divider />
          <AppText style={{ textAlign: 'center' }}>
            Are you sure you want to{' '}
            <b>
              {regFormSubmissionInvoice?.stripeInvoiceId ? 'resend' : 'send'}
            </b>{' '}
            the selected registrants{' '}
            {regFormSubmissionInvoice?.status === 'PAID'
              ? 'invoice receipt'
              : 'invoice'}
            ?
          </AppText>
        </FormSection>
      </AppStack>
    </ResponsiveModal>
  );
};

RegistrationInvoiceResendModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  regAssociation: PropTypes.object,
  regFormSubmissionBalance: PropTypes.object,
  regFormSubmissionInvoice: PropTypes.object
};
export default RegistrationInvoiceResendModal;
