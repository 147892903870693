import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from '@mantine/core';
import AppFlexbox from './AppFlexbox';

const AppRadioGroup = React.forwardRef(({ children, ...rest }, ref) => (
  <Radio.Group
    ref={ref}
    size="sm"
    {...rest}
    style={{ flex: 1, ...rest.style }}
    styles={{
      input: { cursor: 'pointer !important' },
      label: { cursor: 'pointer' },
      ...rest.styles
    }}
  >
    <AppFlexbox style={{ marginTop: 5, flexWrap: 'wrap' }}>
      {children}
    </AppFlexbox>
  </Radio.Group>
));

AppRadioGroup.propTypes = { children: PropTypes.node };

export default AppRadioGroup;
