import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  Button,
  Checkbox,
  Skeleton,
  Divider,
  Menu
} from '@mantine/core';
import { ChevronDown, ChevronUp } from 'tabler-icons-react';
import MenuContent from './MenuContent';
import AppFlexbox from './AppFlexbox';
import AppStack from './AppStack';
import AppText from './AppText';
import { useMediaQueryIndex } from '../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../helpers/styles';

const AccordionListItem = ({
  value,
  chevron,
  children,
  isDisabled,
  label,
  subLabel,
  description,
  subDescription,
  isSelected,
  onSelect,
  menuActions,
  onAction
}) => {
  const mqIndex = useMediaQueryIndex();
  const [accordionState, setAccordionState] = useState(false);

  useEffect(() => {
    if (isSelected) {
      setAccordionState(value);
    }
    else {
      setAccordionState(null);
    }
  }, [isSelected]);

  return label || subLabel ? (
    <Accordion
      chevronSize={0}
      onChange={(v) => {
        if ((!onSelect || isSelected) && !isDisabled) {
          setAccordionState(v);
        }
      }}
      radius={0}
      styles={{
        control: { height: rs(['unset', 'unset', 60], mqIndex), padding: 10 },
        content: { padding: 0 },
        label: { padding: 0 },
        root: { padding: 0 },
        contentInner: { padding: 0 }
      }}
      transitionDuration={0}
      value={accordionState}
      variant="contained"
    >
      <Accordion.Item value={value}>
        <Accordion.Control onClick={(e) => e.stopPropagation()}>
          <AppFlexbox
            style={{
              flex: 1,
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: rs(['column', 'row'], mqIndex),
              gap: rs([10, 15], mqIndex)
            }}
          >
            <AppStack
              style={{
                width: '100%',
                alignSelf: 'stretch',
                flex: 2,
                justifyContent: 'space-between',
                flexDirection: rs(['column', 'row'], mqIndex),
                gap: rs([0, 15], mqIndex)
              }}
            >
              <AppFlexbox
                onClick={(e) => {
                  e.stopPropagation();
                  if (!isDisabled) {
                    onSelect();
                  }
                }}
                style={{
                  width: '100%',
                  flex: 1,
                  cursor: onSelect ? 'pointer' : 'cursor',
                  flexWrap: 'nowrap',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  alignItems: 'center'
                }}
              >
                {onSelect && (
                  <Checkbox
                    checked={isSelected}
                    disabled={isDisabled}
                    onChange={(e) => {}}
                    style={{}}
                  />
                )}

                <AppStack
                  style={{
                    gap: 0,
                    flex: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden'
                  }}
                >
                  {label && (
                    <AppText
                      style={{
                        lineHeight: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}
                      weight={500}
                    >
                      {label}
                    </AppText>
                  )}
                  {subLabel && (
                    <AppText
                      style={{
                        lineHeight: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontWeight: 'normal'
                      }}
                    >
                      {subLabel}
                    </AppText>
                  )}
                </AppStack>
              </AppFlexbox>
              {description && (
                <AppFlexbox style={{ alignSelf: 'stretch' }}>
                  <AppStack
                    style={{
                      gap: 0,
                      flex: 1,
                      whiteSpace: 'nowrap'
                    }}
                  >
                    <AppText
                      style={{
                        lineHeight: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}
                      weight={500}
                    >
                      {description}
                    </AppText>
                    {subDescription && (
                      <AppText
                        style={{
                          lineHeight: '20px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          fontWeight: 'normal'
                        }}
                      >
                        {subDescription}
                      </AppText>
                    )}
                  </AppStack>
                </AppFlexbox>
              )}
            </AppStack>
            {isSelected && !isDisabled
              ? chevron || (
                  <Button
                    radius="xl"
                    rightSection={
                      accordionState ? (
                        <ChevronUp color="dodgerblue" />
                      ) : (
                        <ChevronDown color="dodgerblue" />
                      )
                    }
                    size="xs"
                    variant="outline"
                  >
                    {accordionState ? 'Show Less' : 'Show More'}
                  </Button>
                )
              : onAction && (
                  <AppStack
                    style={{
                      alignSelf: 'stretch',
                      flex: rs([1, 'unset'], mqIndex),
                      justifyContent: 'center'
                    }}
                  >
                    {menuActions.length === 1 ? (
                      <Button
                        color="blue"
                        onClick={() => {
                          onAction(menuActions[0].value);
                        }}
                        rightSection={<ChevronDown />}
                        size="xs"
                        style={{ width: rs(['100%', 125], mqIndex) }}
                        variant="outline"
                      >
                        {menuActions[0].label}
                      </Button>
                    ) : (
                      <MenuContent
                        control={
                          <Button
                            color="blue"
                            rightSection={<ChevronDown />}
                            size="xs"
                            style={{ width: rs(['100%', 125], mqIndex) }}
                            variant="outline"
                          >
                            Actions
                          </Button>
                        }
                      >
                        {menuActions.map((a) => (
                          <Menu.Item
                            key={a.value}
                            onClick={(e) => {
                              e.preventDefault();
                              onAction(a.value);
                            }}
                          >
                            {a.label}
                          </Menu.Item>
                        ))}
                      </MenuContent>
                    )}
                  </AppStack>
                )}
          </AppFlexbox>
        </Accordion.Control>
        <Accordion.Panel>
          <Divider />
          {children}
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  ) : (
    <AppFlexbox
      style={{
        flex: 1,
        padding: 10,
        gap: 15,
        height: 60,
        border: 'solid 1px lightgrey'
      }}
    >
      <Skeleton height={20} width={20} />
      <AppStack style={{ gap: 0, flex: 1, justifyContent: 'center' }}>
        <Skeleton height={8} width="30%" />
      </AppStack>
    </AppFlexbox>
  );
};

AccordionListItem.propTypes = {
  chevron: PropTypes.any,
  children: PropTypes.any,
  description: PropTypes.string,
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  label: PropTypes.string,
  menuActions: PropTypes.array,
  onAction: PropTypes.func,
  onSelect: PropTypes.func,
  subDescription: PropTypes.string,
  subLabel: PropTypes.string,
  value: PropTypes.string
};

export default AccordionListItem;
