import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ActionIcon,
  Alert,
  Badge,
  Button,
  Checkbox,
  Loader,
  NumberInput,
  Radio,
  Select,
  TextInput
} from '@mantine/core';
import { Edit } from 'tabler-icons-react';
import { useNavigate } from 'react-router-dom';
import { Context as RegistrationAdminDashboardContext } from '../../../providers/RegistrationAdminDashboardProvider';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import FormSection from '../../common/FormSection';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import PaginationList from '../../common/PaginationList';
import SelectableListItem from '../../common/SelectableListItem';
import GenerateTeamSettings from './GenerateTeamSettings';
import { getEvaluationEntryProgress } from '../../../helpers/evaluationPlayerHelper';
import { triggerNotification } from '../../../helpers/notificationHelper';

const GENERATE_MODAL_VIEW_ENUM = {
  TEAMS: 'TEAMS',
  EVALUATIONS: 'EVALUATIONS',
  ATTRIBUTES: 'ATTRIBUTES'
};

const GENERATE_TYPE_ENUM = {
  NEW: 'NEW',
  EXISTING: 'EXISTING'
};

const TeamsGenerateSection = ({
  onClose,
  onCancel,
  regAssociationDivision,
  divisionRegistrants,
  regAssociationDivisionTeams,
  isLoading
}) => {
  const navigate = useNavigate();
  const { state: dashboardState } = useContext(
    RegistrationAdminDashboardContext
  );
  const {
    state,
    generateAdminRegAssociationDivisionTeams,
    fetchUpdatedAdminRegAssociationDivision,
    fetchRegAssociationDivisionTeams
  } = useContext(RegistrationAdminContext);
  const [formState, setFormState] = useState({
    amount: 0,
    newTeams: [],
    existingTeams: [],
    pkRegScoutReportEvaluationSessions: [],
    regScoutReportEvaluationAttributes: [],
    openAttributeKeys: [],
    generateType: GENERATE_TYPE_ENUM.EXISTING,
    amountType: 'TEAMS',
    pageIndex: 1,
    currentView: GENERATE_MODAL_VIEW_ENUM.TEAMS
  });

  const divisionScoutReportEvaluationSessions = state.regScoutReportEvaluationSessions.value.filter(
    (s) =>
      s.regAssociationDivision.pkRegAssociationDivision.toString() ===
      regAssociationDivision?.pkRegAssociationDivision.toString()
  );

  const selectedEvaluationSessions = divisionScoutReportEvaluationSessions.filter(
    (e) =>
      formState.pkRegScoutReportEvaluationSessions.includes(
        e.pkRegScoutReportEvaluationSession
      )
  );

  const unavailableRegistrants = regAssociationDivisionTeams.reduce((r, c) => {
    if (
      !formState.existingTeams.find(
        (t) => t.pkRegAssociationDivisionTeam === c.pkRegAssociationDivisionTeam
      )
    ) {
      return r.concat(
        c.regAssociationDivisionTeamPersons.filter((f) => f.active)
      );
    }
    return r;
  }, []);

  const availableRegistrants = divisionRegistrants.filter(
    (r) =>
      !unavailableRegistrants.some(
        (a) => a.regPerson.pkRegPerson === r.regPerson.pkRegPerson
      )
  );

  const noEvaluations =
    !formState.skillBasedTeams || selectedEvaluationSessions.length === 0;

  useEffect(() => {
    setFormState({
      amount: 0,
      newTeams: [],
      existingTeams: [],
      pkRegScoutReportEvaluationSessions: [],
      regScoutReportEvaluationAttributes: [],
      openAttributeKeys: [],
      skillBasedTeams: false,
      generateType: GENERATE_TYPE_ENUM.EXISTING,
      amountType: 'TEAMS',
      pageIndex: 1,
      currentView: GENERATE_MODAL_VIEW_ENUM.TEAMS
    });
  }, []);

  const initializeTeams = (amount, amountType) => {
    const teamAmount =
      amountType === 'TEAMS'
        ? amount
        : Math.ceil(regAssociationDivision.unassignedRegPersonCount / amount);

    const teams = [...Array(teamAmount).keys()].map((i) => ({
      key: i,
      name: `Team ${i + 1}`,
      playerCount: 0,
      attributes: []
    }));

    for (
      let i = 0;
      i < regAssociationDivision.unassignedRegPersonCount;
      i += 1
    ) {
      teams.sort((a, b) => a.playerCount - b.playerCount)[0].playerCount += 1;
    }

    setFormState({
      ...formState,
      amountType,
      amount: amount || null,
      newTeams: amount ? teams : []
    });
  };

  const onUpdateTeam = (newTeamState) => {
    if (formState.generateType === GENERATE_TYPE_ENUM.NEW) {
      setFormState({
        ...formState,
        newTeams: [
          ...formState.newTeams.filter((t) => t.key !== newTeamState.key),
          newTeamState
        ]
      });
    }
    else {
      setFormState({
        ...formState,
        existingTeams: [
          ...formState.existingTeams.filter((t) => t.key !== newTeamState.key),
          newTeamState
        ]
      });
    }
  };

  const onGenerateTeams = () => {
    setFormState({
      ...formState,
      isLoading: true
    });
    generateAdminRegAssociationDivisionTeams(
      {
        pkRegAssociationDivision:
          regAssociationDivision.pkRegAssociationDivision,
        pkRegScoutReportEvaluationSessions:
          formState.pkRegScoutReportEvaluationSessions,
        teams:
          formState.generateType === GENERATE_TYPE_ENUM.NEW
            ? formState.newTeams
            : formState.existingTeams,
        attributes: formState.skillBasedTeams
          ? formState.regScoutReportEvaluationAttributes
          : formState.regScoutReportEvaluationAttributes.filter(
              (f) => f.customQuestion && !f.fkRegScoutReportQuestion
            )
      },
      () => {
        fetchUpdatedAdminRegAssociationDivision(
          regAssociationDivision.pkRegAssociationDivision
        );
        fetchRegAssociationDivisionTeams({
          pkRegAssociation: dashboardState.regAssociation.value.pkRegAssociation
        });
        onClose();
      },
      (e) => {
        setFormState({
          ...formState,
          isLoading: false
        });
        triggerNotification(e);
      }
    );
  };

  return formState.currentView === GENERATE_MODAL_VIEW_ENUM.ATTRIBUTES ? (
    <FormSection
      cancelTitle="Back"
      isLoading={formState.isLoading}
      onCancel={() =>
        setFormState({
          ...formState,
          currentView: GENERATE_MODAL_VIEW_ENUM.EVALUATIONS
        })
      }
      onSubmit={() => {
        onGenerateTeams();
      }}
      submitTitle="Generate Teams"
    >
      <AppStack style={{ gap: 30 }}>
        {noEvaluations && (
          <Alert style={{ marginTop: 20 }} variant="outline">
            <AppStack style={{ gap: 10 }}>
              <AppText weight={500}>
                No evaluations selected. Teams will be randomly generated.
              </AppText>
            </AppStack>
          </Alert>
        )}
        <GenerateTeamSettings
          availableRegistrants={availableRegistrants}
          isGenerating={formState.isLoading}
          onOpenAttribute={(keys) => {
            setFormState({
              ...formState,
              openAttributeKeys: keys
            });
          }}
          onSelectAttribute={(attribute) => {
            if (
              formState.regScoutReportEvaluationAttributes.some(
                (a) =>
                  a.fkRegScoutReportQuestion ===
                    attribute.fkRegScoutReportQuestion &&
                  a.customQuestion === attribute.customQuestion
              )
            ) {
              setFormState({
                ...formState,
                regScoutReportEvaluationAttributes: formState.regScoutReportEvaluationAttributes.filter(
                  (a) =>
                    !(
                      a.fkRegScoutReportQuestion ===
                        attribute.fkRegScoutReportQuestion &&
                      a.customQuestion === attribute.customQuestion
                    )
                )
              });
            }
            else {
              setFormState({
                ...formState,
                regScoutReportEvaluationAttributes: [
                  ...formState.regScoutReportEvaluationAttributes,
                  {
                    fkRegScoutReportQuestion:
                      attribute.fkRegScoutReportQuestion,
                    customQuestion: attribute.customQuestion
                  }
                ]
              });
            }
          }}
          onUpdateTeam={onUpdateTeam}
          openAttributeKeys={formState.openAttributeKeys}
          regScoutReportEvaluationSessions={
            formState.skillBasedTeams ? selectedEvaluationSessions : []
          }
          selectedAttributes={formState.regScoutReportEvaluationAttributes}
          teams={
            formState.generateType === GENERATE_TYPE_ENUM.NEW
              ? formState.newTeams
              : formState.existingTeams
          }
        />
      </AppStack>
    </FormSection>
  ) : formState.currentView === GENERATE_MODAL_VIEW_ENUM.EVALUATIONS ? (
    <FormSection
      cancelTitle="Back"
      onCancel={() =>
        setFormState({
          ...formState,
          currentView: GENERATE_MODAL_VIEW_ENUM.TEAMS
        })
      }
      onSubmit={() => {
        setFormState({
          ...formState,
          currentView: GENERATE_MODAL_VIEW_ENUM.ATTRIBUTES
        });
      }}
      submitTitle="Continue"
    >
      <AppStack style={{ gap: 15 }}>
        <Alert variant="outline">
          <AppStack style={{ gap: 10 }}>
            <AppText weight={500}>
              Choose to generate skill based teams using evaluation sessions.
            </AppText>
            <AppText>
              If evaluation sessions are selected, teams will be generated based
              on the evaluation results. Otherwise, teams will be randomly
              generated using any unassigned registrants.
            </AppText>
          </AppStack>
        </Alert>

        <Radio.Group
          label="Select method of generating teams"
          onChange={(value) =>
            setFormState({
              ...formState,
              skillBasedTeams: value === 'skillBasedTeams'
            })
          }
          style={{ fontWeight: 500, marginTop: 10 }}
          value={formState.skillBasedTeams ? 'skillBasedTeams' : 'randomTeams'}
          withAsterisk
        >
          <AppFlexbox style={{ marginTop: 10 }}>
            <Radio label="Generate randomly" value="randomTeams" />
            <Radio
              label="Balance teams with Player Evaluation Session(s)"
              value="skillBasedTeams"
            />
          </AppFlexbox>
        </Radio.Group>

        {formState.skillBasedTeams && (
          <>
            <AppStack style={{ gap: 5 }}>
              <AppText weight={500}>Player Evaluations</AppText>
              {divisionScoutReportEvaluationSessions.length === 0 ? (
                <AppStack
                  style={{
                    padding: '40px 10px',
                    border: 'solid 1px lightgrey',
                    justifyContent: 'center',
                    textAlign: 'center',
                    alignItems: 'center'
                  }}
                >
                  <AppText weight={500}>
                    No completed evaluations exist for the selected division.
                  </AppText>
                  <Button
                    onClick={() => navigate('/admin/evaluations/sessions')}
                    style={{ width: '100%', maxWidth: 250 }}
                  >
                    Create Evaluation
                  </Button>
                </AppStack>
              ) : (
                <PaginationList
                  isLoading={state.regScoutReportEvaluationSessions.loading}
                  items={divisionScoutReportEvaluationSessions
                    .sort(
                      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
                    )
                    .map((s) => {
                      const isSelected = !!formState.pkRegScoutReportEvaluationSessions.find(
                        (e) => e === s.pkRegScoutReportEvaluationSession
                      );

                      const hasCompletedEvaluatorEntry = s.regScoutReportEvaluationSessionEntries.some(
                        (e) => getEvaluationEntryProgress(e, s) >= 100
                      );

                      return (
                        <AppFlexbox
                          key={s.pkRegScoutReportEvaluationSession}
                          onClick={() => {
                            if (hasCompletedEvaluatorEntry) {
                              setFormState({
                                ...formState,
                                pkRegScoutReportEvaluationSessions: isSelected
                                  ? formState.pkRegScoutReportEvaluationSessions.filter(
                                      (f) =>
                                        f !==
                                        s.pkRegScoutReportEvaluationSession
                                    )
                                  : [
                                      ...formState.pkRegScoutReportEvaluationSessions,
                                      s.pkRegScoutReportEvaluationSession
                                    ]
                              });
                            }
                          }}
                          style={{
                            padding: 10,
                            cursor: hasCompletedEvaluatorEntry
                              ? 'pointer'
                              : 'normal',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}
                        >
                          <AppFlexbox
                            style={{
                              gap: 10,
                              alignItems: 'center'
                            }}
                          >
                            <Checkbox
                              checked={isSelected}
                              disabled={!hasCompletedEvaluatorEntry}
                              onChange={() => {}}
                            />
                            <AppStack style={{ gap: 0 }}>
                              <AppText weight={500}>{s.name}</AppText>
                            </AppStack>
                          </AppFlexbox>
                          {!hasCompletedEvaluatorEntry && (
                            <Badge color="yellow" style={{ fontSize: 12 }}>
                              Incomplete
                            </Badge>
                          )}
                        </AppFlexbox>
                      );
                    })}
                  itemsPerPage={5}
                  LoadingComponent={SelectableListItem}
                  onPageChange={(pageIndex) =>
                    setFormState({
                      ...formState,
                      pageIndex
                    })
                  }
                  pageIndex={formState.pageIndex}
                />
              )}
            </AppStack>
            {divisionScoutReportEvaluationSessions.some((s) =>
              s.regScoutReportEvaluationSessionEntries.every(
                (e) => getEvaluationEntryProgress(e, s) <= 100
              )
            ).length > 0 && (
              <Alert color="blue" variant="outline">
                <AppStack style={{ gap: 10 }}>
                  <AppText>
                    Only completed evaluations can be used to generate skill
                    based teams. Please ensure that all evaluations are
                    completed before proceeding.
                  </AppText>
                </AppStack>
              </Alert>
            )}
          </>
        )}
      </AppStack>
    </FormSection>
  ) : (
    <FormSection
      cancelTitle="Back"
      isLoading={formState.isLoading}
      isSubmitDisabled={
        (formState.generateType === GENERATE_TYPE_ENUM.NEW &&
          formState.newTeams.length === 0) ||
        (formState.generateType === GENERATE_TYPE_ENUM.EXISTING &&
          formState.existingTeams.length === 0)
      }
      onCancel={onCancel}
      onSubmit={() => {
        setFormState({
          ...formState,
          currentView: GENERATE_MODAL_VIEW_ENUM.EVALUATIONS
        });
      }}
      submitTitle="Continue"
    >
      <AppStack style={{ gap: 30 }}>
        <Select
          data={
            regAssociationDivision
              ? [
                  {
                    label: regAssociationDivision.name,
                    value: regAssociationDivision.pkRegAssociationDivision.toString()
                  }
                ]
              : []
          }
          disabled
          label="Division"
          onChange={() => {}}
          required
          searchable
          value={
            regAssociationDivision?.pkRegAssociationDivision.toString() ?? ''
          }
        />

        <AppStack style={{ gap: 15 }}>
          {isLoading ? (
            <AppStack
              style={{
                padding: '20px 40px',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Loader color="dark" size={40} />
            </AppStack>
          ) : availableRegistrants.length === 0 ? (
            <AppStack
              style={{
                padding: '40px 20px',
                border: 'solid 1px lightgrey',
                justifyContent: 'center',
                textAlign: 'center'
              }}
            >
              <AppText weight={500}>
                No registrants available to generate teams.
              </AppText>
            </AppStack>
          ) : formState.generateType === GENERATE_TYPE_ENUM.NEW ? (
            <>
              <AppFlexbox style={{ gap: 0, alignItems: 'end' }}>
                <NumberInput
                  disabled={formState.isLoading}
                  hideControls
                  label="Amount"
                  min={0}
                  onChange={(amount) => {
                    initializeTeams(amount, formState.amountType);
                  }}
                  required
                  style={{ flex: 1 }}
                  styles={{
                    input: {
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0
                    }
                  }}
                  value={formState.amount ?? ''}
                />
                <Select
                  data={[
                    { label: 'Teams', value: 'TEAMS' },
                    { label: 'Players', value: 'PLAYERS' }
                  ]}
                  disabled
                  onChange={(amountType) =>
                    initializeTeams(formState.amount, amountType)
                  }
                  required
                  style={{ flex: 1 }}
                  styles={{
                    input: {
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0
                    }
                  }}
                  value={formState.amountType ?? ''}
                />
              </AppFlexbox>

              {formState.amount > 0 && (
                <AppStack style={{ gap: 5 }}>
                  <AppText weight={500}>New Teams</AppText>
                  <PaginationList
                    items={formState.newTeams
                      .sort((a, b) => a.key - b.key)
                      .map((a) => {
                        const isSelected = formState.selectedTeamEdit === a.key;

                        return (
                          <AppFlexbox
                            key={a.key}
                            style={{
                              padding: 10,
                              gap: 5,
                              justifyContent: 'space-between',
                              alignItems: 'center'
                            }}
                          >
                            <AppFlexbox
                              style={{
                                alignItems: 'center',
                                gap: 10,
                                minHeight: 36
                              }}
                            >
                              <ActionIcon
                                color="blue"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setFormState({
                                    ...formState,
                                    selectedTeamEdit: isSelected ? null : a.key
                                  });
                                }}
                                size={25}
                                variant="subtle"
                              >
                                <Edit />
                              </ActionIcon>
                              {isSelected ? (
                                <TextInput
                                  onChange={(e) =>
                                    setFormState({
                                      ...formState,
                                      newTeams: formState.newTeams.map((t) =>
                                        t.key === a.key
                                          ? {
                                              ...t,
                                              name: e.currentTarget.value
                                            }
                                          : t
                                      )
                                    })
                                  }
                                  value={a.name ?? ''}
                                />
                              ) : (
                                <AppText weight={500}>{a.name}</AppText>
                              )}
                            </AppFlexbox>
                          </AppFlexbox>
                        );
                      })}
                    onPageChange={(pageIndex) =>
                      setFormState({ ...formState, pageIndex })
                    }
                    pageIndex={formState.pageIndex}
                  />
                </AppStack>
              )}
            </>
          ) : (
            <AppStack style={{ gap: 5 }}>
              <AppText weight={500}>Existing Teams</AppText>

              <PaginationList
                items={regAssociationDivisionTeams
                  .sort((a, b) => a.name.localeCompare(b.name) || a.key - b.key)
                  .map((a) => {
                    const isSelected = !!formState.existingTeams.find(
                      (t) =>
                        t.regAssociationDivisionTeam
                          .pkRegAssociationDivisionTeam ===
                        a.pkRegAssociationDivisionTeam
                    );
                    return (
                      <AppFlexbox
                        key={a.pkRegAssociationDivisionTeam}
                        onClick={() => {
                          setFormState({
                            ...formState,
                            existingTeams: isSelected
                              ? formState.existingTeams.filter(
                                  (t) =>
                                    t.regAssociationDivisionTeam
                                      .pkRegAssociationDivisionTeam !==
                                    a.pkRegAssociationDivisionTeam
                                )
                              : [
                                  ...formState.existingTeams,
                                  {
                                    key: a.pkRegAssociationDivisionTeam,
                                    regAssociationDivisionTeam: a,
                                    pkRegAssociationDivisionTeam:
                                      a.pkRegAssociationDivisionTeam,
                                    name: a.name,
                                    playerCount: 0,
                                    attributes: []
                                  }
                                ]
                          });
                        }}
                        style={{
                          padding: 10,
                          gap: 5,
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          cursor: 'pointer'
                        }}
                      >
                        <AppFlexbox style={{ alignItems: 'center' }}>
                          <Checkbox checked={isSelected} onChange={() => {}} />
                          <AppText weight={500}>{a.name}</AppText>
                        </AppFlexbox>
                      </AppFlexbox>
                    );
                  })}
                onPageChange={(pageIndex) =>
                  setFormState({ ...formState, pageIndex })
                }
                pageIndex={formState.pageIndex}
              />
            </AppStack>
          )}
        </AppStack>
      </AppStack>
    </FormSection>
  );
};

TeamsGenerateSection.propTypes = {
  divisionRegistrants: PropTypes.array,
  divisionSpecialRequests: PropTypes.array,
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  regAssociationDivision: PropTypes.object,
  regAssociationDivisionTeams: PropTypes.array
};

export default TeamsGenerateSection;
