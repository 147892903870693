import { PropTypes } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Checkbox, Divider } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { Calendar } from 'tabler-icons-react';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { triggerNotification } from '../../../helpers/notificationHelper';
import FormSection from '../../common/FormSection';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppTitle from '../../common/AppTitle';

const ResendInviteModal = ({ isOpen, onClose, invite }) => {
  const {
    sendAdminRegAssociationDivisionFormInvites,
    fetchAdminRegAssociationDivisionFormInvites
  } = useContext(RegistrationAdminContext);
  const [formState, setFormState] = useState({
    hasExpiryDate: false,
    expiresAt: null,
    isLoading: false
  });

  useEffect(() => {
    if (isOpen && invite) {
      setFormState({
        hasExpiryDate: !!invite.expiresAt,
        expiresAt: null,
        isLoading: false
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      onClose={onClose}
      opened={isOpen}
      size={600}
      title={<AppTitle order={2}>Resend Invite</AppTitle>}
    >
      <FormSection
        cancelTitle="Cancel"
        isLoading={formState.isLoading}
        onCancel={onClose}
        onSubmit={() => {
          if (
            formState.hasExpiryDate &&
            (!formState.expiresAt || formState.expiresAt < new Date())
          ) {
            triggerNotification('Invalid Expiry Date');
          }
          else {
            setFormState({
              ...formState,
              isLoading: true
            });
            sendAdminRegAssociationDivisionFormInvites(
              invite.regAssociationDivisionForm.regAssociationDivision
                .pkRegAssociationDivision,
              invite.isExternalInvite
                ? {
                    fkRegAssociationDivisionForm:
                      invite.regAssociationDivisionForm
                        .pkRegAssociationDivisionForm,
                    expiresAt: formState.hasExpiryDate
                      ? formState.expiresAt
                      : null,
                    externalUsers: [
                      {
                        email: invite.email,
                        firstName: invite.participantFirstName,
                        lastName: invite.participantLastName
                      }
                    ]
                  }
                : {
                    fkRegAssociationDivisionForm:
                      invite.regAssociationDivisionForm
                        .pkRegAssociationDivisionForm,
                    expiresAt: formState.hasExpiryDate
                      ? formState.expiresAt
                      : null,
                    fkRegFormSubmissions: [
                      invite.regFormSubmission.pkRegFormSubmission
                    ]
                  },
              () => {
                triggerNotification('Invite Sent', 'Success', 'green');
                fetchAdminRegAssociationDivisionFormInvites({
                  pkRegAssociation:
                    invite.regAssociationDivisionForm.regAssociationDivision
                      .regAssociationSeason.fkRegAssociation
                });
                onClose();
              },
              (message) => {
                triggerNotification(message);
                setFormState({
                  ...formState,
                  isLoading: false
                });
              }
            );
          }
        }}
        submitTitle="Resend"
      >
        {invite && (
          <AppStack>
            <AppText>
              Are you sure you want to resend the invite to{' '}
              <b>{invite.label}</b>?
            </AppText>
            <Divider />

            <Checkbox
              checked={formState.hasExpiryDate}
              disabled={formState.isLoading}
              label="Invite Expires"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  hasExpiryDate: e.currentTarget.checked
                })
              }
              style={{ fontWeight: 500, marginTop: 20 }}
            />
            {formState.hasExpiryDate && (
              <DatePickerInput
                clearable
                disabled={formState.isLoading}
                label="Expires At"
                leftSection={<Calendar color="#000" size={16} />}
                minDate={new Date()}
                onChange={(value) =>
                  setFormState({
                    ...formState,
                    errors: {},
                    expiresAt: value
                  })
                }
                placeholder="MM/DD/YYYY"
                required
                value={formState.expiresAt}
                valueFormat="MM/DD/YYYY"
              />
            )}
          </AppStack>
        )}
      </FormSection>
    </ResponsiveModal>
  );
};

ResendInviteModal.propTypes = {
  invite: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default ResendInviteModal;
