import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Divider,
  Skeleton,
  Tooltip,
  useMantineTheme
} from '@mantine/core';
import NumberFormat from 'react-number-format';
import { currencyFormat } from '../../../helpers/format';
import { triggerNotification } from '../../../helpers/notificationHelper';
import { calculateCartTotals } from '../../../helpers/paymentHelper';
import { PAYMENT_PROVIDERS } from '../../../config/constants';
import AppCard from '../../common/AppCard';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../../helpers/styles';

const CHECKOUT_PAYMENT_TYPES = {
  CARD: 'card',
  AFTERPAY: 'afterpay',
  OFFLINE: 'offline'
};

const CheckoutOrderSummary = ({
  isLoading,
  regCart,
  regAssociation,
  onCheckout,
  isCheckoutSubmitting
}) => {
  const mqIndex = useMediaQueryIndex();
  const theme = useMantineTheme();
  const [paymentState, setPaymentState] = useState({
    paymentType: '',
    subscribeEmail: false
  });

  const showOfflinePaymentButton = regCart?.regCartProducts.some(
    (f) =>
      f.regFormSubmission.regAssociationDivisionForm.regAssociationDivision
        .offlinePaymentsEnabled
  );
  const offlinePaymentsDisabled =
    regCart?.regCartMerchPackages.length > 0 ||
    regCart?.regCartProducts.some(
      (f) =>
        !f.regFormSubmission.regAssociationDivisionForm.regAssociationDivision
          .offlinePaymentsEnabled
    );
  const cartTotals = regCart ? calculateCartTotals(regCart.cart.lineItems) : {};
  const installmentsPercentageFee =
    regCart?.cart.serviceFeeRates?.installmentsPercentageFee ?? null;

  const isStripeCheckout =
    regCart?.cart.paymentProviderType === PAYMENT_PROVIDERS.stripe.value;
  const currency = regCart?.cart.currency?.toUpperCase() ?? 'CAD';

  useEffect(() => {
    if (!isCheckoutSubmitting) {
      setPaymentState({
        paymentType: '',
        subscribeEmail: false
      });
    }
  }, [isCheckoutSubmitting]);

  const submitCheckout = (type) => {
    if (!paymentState.subscribeEmail) {
      document.getElementById('checkout-order-summary-submit').click();
    }
    else {
      setPaymentState({ ...paymentState, paymentType: type });
      onCheckout(type);
    }
  };

  return (
    <AppCard
      style={{
        padding: 0,
        flex: 1,
        borderRadius: rs([0, 0, 0, 4], mqIndex),
        width: '100%'
      }}
    >
      <AppStack style={{ gap: 10, margin: 40 }}>
        <AppText size="lg" weight={700}>
          Order Summary
        </AppText>
        <AppFlexbox
          style={{ justifyContent: 'space-between', flexWrap: 'nowrap' }}
        >
          <AppText size="lg">Subtotal</AppText>
          {isLoading ? (
            <Skeleton height={15} width={65} />
          ) : (
            <AppFlexbox style={{ gap: 10 }}>
              <AppText size="lg">
                <NumberFormat
                  decimalScale={2}
                  displayType="text"
                  fixedDecimalScale
                  prefix="$"
                  thousandSeparator
                  value={cartTotals.subtotalInCents / 100}
                />
              </AppText>
              <AppText size="lg">{currency}</AppText>
            </AppFlexbox>
          )}
        </AppFlexbox>
        {!isLoading && cartTotals.discountInCents > 0 && (
          <AppFlexbox
            style={{
              justifyContent: 'space-between',
              flexWrap: 'nowrap'
            }}
          >
            <AppText size="lg">Discount</AppText>
            <AppFlexbox style={{ gap: 10 }}>
              <AppText size="lg">
                <NumberFormat
                  decimalScale={2}
                  displayType="text"
                  fixedDecimalScale
                  prefix="$"
                  thousandSeparator
                  value={(cartTotals.discountInCents / 100) * -1}
                />
              </AppText>
              <AppText size="lg">{currency}</AppText>
            </AppFlexbox>
          </AppFlexbox>
        )}
        <AppFlexbox
          style={{ justifyContent: 'space-between', flexWrap: 'nowrap' }}
        >
          <AppText size="lg">Taxes</AppText>
          {isLoading ? (
            <Skeleton height={15} width={65} />
          ) : (
            <AppFlexbox style={{ gap: 10 }}>
              <AppText size="lg">
                <NumberFormat
                  decimalScale={2}
                  displayType="text"
                  fixedDecimalScale
                  prefix="$"
                  thousandSeparator
                  value={cartTotals.taxInCents / 100}
                />
              </AppText>
              <AppText size="lg">{currency}</AppText>
            </AppFlexbox>
          )}
        </AppFlexbox>
        {!isLoading && cartTotals.serviceFeeInCents > 0 && (
          <AppFlexbox
            style={{ justifyContent: 'space-between', flexWrap: 'nowrap' }}
          >
            <AppText size="lg">Service Fee</AppText>
            {isLoading ? (
              <Skeleton height={15} width={65} />
            ) : (
              <AppFlexbox style={{ gap: 10 }}>
                <AppText size="lg">
                  <NumberFormat
                    decimalScale={2}
                    displayType="text"
                    fixedDecimalScale
                    prefix="$"
                    thousandSeparator
                    value={cartTotals.serviceFeeInCents / 100}
                  />
                </AppText>
                <AppText size="lg">{currency}</AppText>
              </AppFlexbox>
            )}
          </AppFlexbox>
        )}
      </AppStack>
      <Divider />
      <AppFlexbox
        style={{
          justifyContent: 'space-between',
          flexWrap: 'nowrap',
          margin: 40
        }}
      >
        <AppText size="lg" weight={700}>
          Total
        </AppText>
        {isLoading ? (
          <Skeleton height={15} width={65} />
        ) : (
          <AppFlexbox style={{ gap: 10 }}>
            <AppText size="lg" weight={700}>
              <NumberFormat
                decimalScale={2}
                displayType="text"
                fixedDecimalScale
                prefix="$"
                thousandSeparator
                value={cartTotals.totalInCents / 100}
              />
            </AppText>
            <AppText size="lg" weight={700}>
              {currency}
            </AppText>
          </AppFlexbox>
        )}
      </AppFlexbox>
      <AppStack
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        style={{
          backgroundColor: theme.black,
          color: '#FFF',
          opacity: 0.8,
          padding: 40
        }}
      >
        <Button
          id="checkout-order-summary-submit"
          style={{ display: 'none' }}
          type="submit"
        />
        <AppStack style={{ gap: 30 }}>
          {regAssociation && (
            <Checkbox
              checked={paymentState.subscribeEmail}
              disabled={isCheckoutSubmitting}
              label={`Subscribe to receive emails from ${regAssociation.association.name}`}
              onChange={() =>
                setPaymentState({
                  ...paymentState,
                  subscribeEmail: !paymentState.subscribeEmail
                })
              }
              required
              styles={{ label: { fontWeight: 500 } }}
            />
          )}

          <Button
            disabled={
              isLoading ||
              (isCheckoutSubmitting &&
                paymentState.paymentType !== CHECKOUT_PAYMENT_TYPES.CARD)
            }
            loading={
              isCheckoutSubmitting &&
              paymentState.paymentType === CHECKOUT_PAYMENT_TYPES.CARD
            }
            onClick={() => submitCheckout(CHECKOUT_PAYMENT_TYPES.CARD)}
            size="lg"
            type="button"
          >
            Continue to Checkout
          </Button>

          {regCart &&
            isStripeCheckout &&
            cartTotals.totalInCents > 0 &&
            cartTotals.totalInCents / 100 <= 1500 && (
              <>
                <Divider color="lightgrey" label="OR" labelPosition="center" />

                <AppStack style={{ gap: 10 }}>
                  <Button
                    disabled={
                      isLoading ||
                      (isCheckoutSubmitting &&
                        paymentState.paymentType !==
                          CHECKOUT_PAYMENT_TYPES.AFTERPAY)
                    }
                    loading={
                      isCheckoutSubmitting &&
                      paymentState.paymentType ===
                        CHECKOUT_PAYMENT_TYPES.AFTERPAY
                    }
                    onClick={() =>
                      submitCheckout(CHECKOUT_PAYMENT_TYPES.AFTERPAY)
                    }
                    size="lg"
                    type="button"
                  >
                    4 payments of{' '}
                    {currencyFormat(
                      (cartTotals.totalInCents +
                        regCart.cart.installmentsServiceFeeTotal) /
                        100 /
                        4
                    )}{' '}
                    {currency}*
                  </Button>
                  {installmentsPercentageFee && (
                    <AppText
                      style={{
                        fontSize: 13,
                        color: 'lightgrey',
                        textAlign: 'center'
                      }}
                    >
                      This includes an additional{' '}
                      {installmentsPercentageFee * 100}% fee for installment
                      payments.
                    </AppText>
                  )}
                </AppStack>
              </>
            )}
          {showOfflinePaymentButton && cartTotals.totalInCents > 0 && (
            <>
              <Divider color="lightgrey" label="OR" labelPosition="center" />
              <Tooltip
                disabled={!offlinePaymentsDisabled}
                label="Offline payments disabled because not all items in your cart support them"
                multiline
                style={{ display: 'flex' }}
                width={250}
                withArrow
                withinPortal
              >
                <Button
                  disabled={
                    isLoading ||
                    (isCheckoutSubmitting &&
                      paymentState.paymentType !== 'offline')
                  }
                  loading={
                    isCheckoutSubmitting &&
                    paymentState.paymentType === 'offline'
                  }
                  onClick={() => {
                    if (offlinePaymentsDisabled) {
                      const disabledRegistration = regCart.regCartProducts.find(
                        (p) =>
                          !p.regFormSubmission.regAssociationDivisionForm
                            .regAssociationDivision.offlinePaymentsEnabled
                      );
                      const itemName =
                        disabledRegistration?.regFormSubmission
                          .regAssociationDivisionForm.regForm.name ??
                        regCart.regCartMerchPackages[0]?.merchPackage.name ??
                        '';
                      triggerNotification(
                        `'${itemName}' does not support offline payments.`
                      );
                    }
                    else {
                      submitCheckout(CHECKOUT_PAYMENT_TYPES.OFFLINE);
                    }
                  }}
                  size="lg"
                  type="button"
                >
                  Cash / Cheque Payment
                </Button>
              </Tooltip>
            </>
          )}
        </AppStack>
      </AppStack>
    </AppCard>
  );
};

CheckoutOrderSummary.propTypes = {
  isCheckoutSubmitting: PropTypes.bool,
  isLoading: PropTypes.bool,
  onCheckout: PropTypes.func,
  regAssociation: PropTypes.object,
  regCart: PropTypes.object
};

export default CheckoutOrderSummary;
