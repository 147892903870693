import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Checkbox, NumberInput, Select } from '@mantine/core';
import { AlertCircle } from 'tabler-icons-react';
import PaginationList from '../../common/PaginationList';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import AppTitle from '../../common/AppTitle';
import FormSection from '../../common/FormSection';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const CouponRegistrationAssign = ({
  assignedDivisionForms,
  regAssociationDivisions,
  qualifyPerItem,
  onCancel,
  onAssign
}) => {
  const mqIndex = useMediaQueryIndex();
  const { state } = useContext(RegistrationAdminContext);
  const [formState, setFormState] = useState({
    pkRegAssociationDivision: regAssociationDivisions[0]?.pkRegAssociationDivision.toString(),
    assignedDivisionForms,
    pageIndex: 1
  });

  useEffect(() => {
    if (assignedDivisionForms) {
      setFormState({
        pkRegAssociationDivision: regAssociationDivisions[0]?.pkRegAssociationDivision.toString(),
        assignedDivisionForms,
        pageIndex: 1
      });
    }
  }, [assignedDivisionForms]);

  return (
    <FormSection
      onCancel={onCancel}
      onSubmit={() => onAssign(formState.assignedDivisionForms)}
      submitTitle="Assign"
    >
      <AppStack style={{ gap: 20 }}>
        <Select
          data={regAssociationDivisions.map((d) => ({
            label: d.name,
            value: d.pkRegAssociationDivision.toString()
          }))}
          label="Division"
          onChange={(value) =>
            setFormState({
              ...formState,
              pkRegAssociationDivision: value
            })
          }
          value={formState.pkRegAssociationDivision}
        />
        <AppStack style={{ gap: 5 }}>
          <AppText style={{ fontSize: 14 }} weight={500}>
            Registration Forms
          </AppText>
          <PaginationList
            emptyMessage="No Registrations"
            items={state.regAssociation.value.regAssociationDivisionForms
              .filter(
                (d) =>
                  d.active &&
                  !d.regForm.deleted &&
                  d.regAssociationDivision.pkRegAssociationDivision.toString() ===
                    formState.pkRegAssociationDivision
              )
              .sort((a, b) => a.regForm.name.localeCompare(b.regForm.name))
              .map((d) => {
                const selectedDivisionForm = formState.assignedDivisionForms.find(
                  (s) =>
                    s.regAssociationDivisionForm.regAssociationDivision
                      .pkRegAssociationDivision ===
                      d.regAssociationDivision.pkRegAssociationDivision &&
                    s.regAssociationDivisionForm.regForm.uuid === d.regForm.uuid
                );
                return (
                  <AppFlexbox
                    key={d.pkRegAssociationDivisionForm}
                    style={{
                      flex: 1,
                      padding: 10
                    }}
                  >
                    <AppFlexbox
                      style={{
                        gap: rs([5, 15], mqIndex),
                        flex: 1,
                        justifyContent: 'space-between',
                        flexDirection: rs(['column', 'row'], mqIndex),
                        alignItems: 'stretch'
                      }}
                    >
                      <AppFlexbox
                        onClick={() => {
                          if (selectedDivisionForm) {
                            setFormState({
                              ...formState,
                              assignedDivisionForms: formState.assignedDivisionForms.filter(
                                (s) =>
                                  !(
                                    s.regAssociationDivisionForm
                                      .regAssociationDivision
                                      .pkRegAssociationDivision ===
                                      d.regAssociationDivision
                                        .pkRegAssociationDivision &&
                                    s.regAssociationDivisionForm.regForm
                                      .uuid === d.regForm.uuid
                                  )
                              )
                            });
                          }
                          else {
                            setFormState({
                              ...formState,
                              assignedDivisionForms: [
                                ...formState.assignedDivisionForms,
                                {
                                  regAssociationDivisionForm: d,
                                  minimumQualifiedAmount: 1
                                }
                              ]
                            });
                          }
                        }}
                        style={{
                          cursor: 'center',
                          flex: 1,
                          alignItems: 'center'
                        }}
                      >
                        <Checkbox
                          checked={!!selectedDivisionForm}
                          onChange={() => {}}
                        />
                        <AppStack style={{ gap: 0 }}>
                          <AppText
                            style={{ lineHeight: '16px', fontSize: 16 }}
                            weight={500}
                          >
                            {d.regForm.name}
                          </AppText>
                          <AppText style={{ lineHeight: '16px', fontSize: 14 }}>
                            {d.regAssociationDivision.name}
                          </AppText>
                        </AppStack>
                      </AppFlexbox>

                      {!!selectedDivisionForm && qualifyPerItem && (
                        <NumberInput
                          label="Minimum amount"
                          min={1}
                          onChange={(value) => {
                            setFormState({
                              ...formState,
                              assignedDivisionForms: [
                                ...formState.assignedDivisionForms.filter(
                                  (s) =>
                                    !(
                                      s.regAssociationDivisionForm
                                        .regAssociationDivision
                                        .pkRegAssociationDivision ===
                                        d.regAssociationDivision
                                          .pkRegAssociationDivision &&
                                      s.regAssociationDivisionForm.regForm
                                        .uuid === d.regForm.uuid
                                    )
                                ),
                                {
                                  regAssociationDivisionForm: d,
                                  minimumQualifiedAmount: value || null
                                }
                              ]
                            });
                          }}
                          style={{
                            flex: 1,
                            maxWidth: rs(['unset', 175], mqIndex)
                          }}
                          styles={{ label: { margin: 0 } }}
                          value={
                            selectedDivisionForm?.minimumQualifiedAmount ?? 1
                          }
                        />
                      )}
                    </AppFlexbox>
                  </AppFlexbox>
                );
              })}
            itemsPerPage={10}
            onPageChange={(pageIndex) =>
              setFormState({ ...formState, pageIndex })
            }
            pageIndex={formState.pageIndex}
          />
        </AppStack>
      </AppStack>
      {formState.assignedDivisionForms.length > 0 && (
        <Alert
          icon={<AlertCircle size={30} />}
          styles={{ icon: { width: 25, height: 20 } }}
          title={
            <AppTitle order={5} style={{ height: 30 }}>
              Registrations selected
            </AppTitle>
          }
          variant="outline"
        >
          <AppStack style={{ gap: 10 }}>
            <AppText>
              {formState.assignedDivisionForms.length} form(s) selected from{' '}
              {
                formState.assignedDivisionForms.reduce((result, current) => {
                  if (
                    result.findIndex(
                      (r) =>
                        r.regAssociationDivisionForm.regAssociationDivision
                          .pkRegAssociationDivision ===
                        current.regAssociationDivisionForm
                          .regAssociationDivision.pkRegAssociationDivision
                    ) === -1
                  ) {
                    result.push(current);
                  }
                  return result;
                }, []).length
              }{' '}
              Division(s)
            </AppText>
          </AppStack>
        </Alert>
      )}
    </FormSection>
  );
};

CouponRegistrationAssign.propTypes = {
  assignedDivisionForms: PropTypes.array,
  onAssign: PropTypes.func,
  onCancel: PropTypes.func,
  qualifyPerItem: PropTypes.bool,
  regAssociationDivisions: PropTypes.array
};

export default CouponRegistrationAssign;
