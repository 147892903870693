import Storage from '@aws-amplify/storage';
import { S3_PUBLIC_URL } from '../config/constants';
import { debug } from './logger';

const uploadFileToStorage = async (
  identifier,
  file,
  onSuccess,
  onError,
  config = {}
) => {
  try {
    const extension = file.name.split('.').pop();
    const fileName = `${identifier.replace(' ', '-')}${Math.round(
      new Date().getTime() / 1000
    )}.${extension}`;
    const uploadData = await Storage.put(fileName, file, config);
    debug(uploadData, file.name);
    if (uploadData) {
      onSuccess(`${S3_PUBLIC_URL}${uploadData.key}`, file.name);
    }
  } catch (err) {
    console.error('Error uploading file:', err);
    if (onError) {
      onError();
    }
  }
};

const dataUrlToFile = async (dataUrl, fileName, onSuccess, onError) => {
  try {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    if (onSuccess) {
      onSuccess(new File([blob], fileName, { type: 'image/png' }));
    }
  } catch (err) {
    console.error('Error converting file:', err);
    if (onError) {
      onError();
    }
  }
};

const urlToDataURL = (url, callback) => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.onload = () => {
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.send();
};

const downloadBlob = (blob, fileName) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};

const downloadUrlFile = (fileUrl, fileName) => {
  if (fileName) {
    fetch(fileUrl)
      .then((resp) => resp.blob())
      .then((blob) => {
        downloadBlob(blob, fileName);
      })
      .catch((e) => console.log('Error downloading File'));
  }
  else {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = fileUrl;
    document.body.appendChild(a);
    a.click();
  }
};

const fileToBase64String = (file, callback) => {
  const reader = new FileReader();

  reader.onload = (event) => {
    callback(event.target.result);
  };

  reader.onerror = (error) => {
    console.log('Error: ', error);
  };

  reader.readAsDataURL(file);
};

const deleteFileFromStorage = async (url) => {
  try {
    const result = await Storage.remove(url.replace(S3_PUBLIC_URL, ''));
    debug(result);
  } catch (err) {
    console.error('Error deleting file:', err);
  }
};

export {
  dataUrlToFile,
  deleteFileFromStorage,
  downloadBlob,
  downloadUrlFile,
  urlToDataURL,
  uploadFileToStorage,
  fileToBase64String
};
