import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@mantine/core';

const AppFlexbox = React.forwardRef(({ children, ...rest }, ref) => (
  <Flex
    ref={ref}
    {...rest}
    style={{ gap: 16, flexWrap: 'nowrap', ...rest.style }}
  >
    {children}
  </Flex>
));

AppFlexbox.propTypes = { children: PropTypes.node };

export default AppFlexbox;
