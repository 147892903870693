import React from 'react';
import PropTypes from 'prop-types';
import ResponsiveModal from '../../common/ResponsiveModal';
import ContactInformationForm from './ContactInformationForm';
import ChangePasswordForm from './ChangePasswordForm';
import ChangeAvatarForm from './ChangeAvatarForm';

const SETTING_VIEWS = {
  contactInfo: 'contact_info',
  avatar: 'avatar',
  changePassword: 'change_password'
};

const SettingsModal = ({ isOpen, onClose, settingView }) => (
  <ResponsiveModal isOpen={isOpen} onClose={onClose} size={550}>
    {settingView === SETTING_VIEWS.contactInfo ? (
      <ContactInformationForm onClose={onClose} />
    ) : settingView === SETTING_VIEWS.avatar ? (
      <ChangeAvatarForm onClose={onClose} />
    ) : (
      settingView === SETTING_VIEWS.changePassword && (
        <ChangePasswordForm onClose={onClose} />
      )
    )}
  </ResponsiveModal>
);

SettingsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  settingView: PropTypes.string
};

export default SettingsModal;
