import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Ticket } from 'tabler-icons-react';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { useModalState, useSortByFilter } from '../../../helpers/hooks';
import AppCard from '../../common/AppCard';
import TableView from '../../common/TableView';
import CouponModal from './CouponModal';
import CouponRegistrationsModal from './CouponRegistrationsModal';
import { currencyFormat } from '../../../helpers/format';

const VIEW_ACTIONS_ENUM = {
  create: 'CREATE',
  edit: 'EDIT',
  expire: 'EXPIRE',
  reactivate: 'REACTIVATE',
  viewRegistrants: 'VIEW_REGISTRANTS'
};

const VIEW_ACTIONS = [
  {
    label: 'Edit',
    value: VIEW_ACTIONS_ENUM.edit
  },
  {
    label: 'Expire',
    value: VIEW_ACTIONS_ENUM.expire,
    hideWhenExpired: true
  },
  {
    label: 'Reactivate Coupon',
    value: VIEW_ACTIONS_ENUM.reactivate,
    showWhenExpired: true
  },
  {
    label: 'View Registrants',
    value: VIEW_ACTIONS_ENUM.viewRegistrants
  }
];

const COUPON_COLUMNS = [
  {
    label: 'Coupon Code',
    value: 'code',
    sortable: true
  },
  {
    label: 'Status',
    value: 'status',
    sortable: true
  },
  {
    label: 'Redemption Count',
    value: 'redeemedCount',
    sortable: true
  },
  {
    label: 'Max Redemptions',
    value: 'maxRedemptions',
    sortable: true
  },
  {
    label: 'Flat Discount',
    value: 'flatDiscount',
    sortable: true
  },
  {
    label: 'Percentage Discount',
    value: 'percentageDiscount',
    sortable: true
  },
  {
    label: 'Season',
    value: 'season',
    sortable: true
  },
  {
    label: 'Division',
    value: 'division',
    sortable: true
  },
  {
    label: 'Form',
    value: 'form',
    sortable: true
  }
];

const RegularCouponView = ({
  isLoading,
  regAssociation,
  modalAction,
  filterState,
  setFilterState
}) => {
  const hasInitialized = useRef(false);
  const { state, fetchAdminRegAssociationCoupons } = useContext(
    RegistrationAdminContext
  );
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const {
    sortValue,
    isDescendingSort,
    onChangeSortBy,
    customFilterData
  } = useSortByFilter(COUPON_COLUMNS);

  const filterOptions = state.regAssociationCoupons.value.reduce(
    (r, c) => {
      if (
        c.regAssociationSeason &&
        !r.seasonFilterOptions.find(
          (f) =>
            f.value === c.regAssociationSeason.pkRegAssociationSeason.toString()
        )
      ) {
        r.seasonFilterOptions.push({
          value: c.regAssociationSeason.pkRegAssociationSeason.toString(),
          label: c.regAssociationSeason.name,
          createdAt: c.regAssociationSeason.createdAt
        });
      }
      if (
        c.regAssociationDivision &&
        !r.divisionFilterOptions.find(
          (f) =>
            f.value ===
            c.regAssociationDivision.pkRegAssociationDivision.toString()
        )
      ) {
        r.divisionFilterOptions.push({
          value: c.regAssociationDivision.pkRegAssociationDivision.toString(),
          label: c.regAssociationDivision.name,
          fkRegAssociationSeason: c.regAssociationDivision.fkRegAssociationSeason.toString()
        });
      }
      if (
        c.regAssociationDivisionForm &&
        !r.regFormUuidFilterOptions.find(
          (f) => f.value === c.regAssociationDivisionForm.regForm.uuid
        )
      ) {
        r.regFormUuidFilterOptions.push({
          value: c.regAssociationDivisionForm.regForm.uuid,
          label: c.regAssociationDivisionForm.regForm.name,
          fkRegAssociationSeason: c.regAssociationDivisionForm.regAssociationDivision.fkRegAssociationSeason.toString(),
          fkRegAssociationDivision: c.regAssociationDivisionForm.regAssociationDivision.pkRegAssociationDivision.toString()
        });
      }
      return r;
    },
    {
      seasonFilterOptions: [],
      divisionFilterOptions: [],
      regFormUuidFilterOptions: []
    }
  );

  useEffect(() => {
    if (modalAction === VIEW_ACTIONS_ENUM.create) {
      onOpenModal(modalAction);
    }
  }, [modalAction]);

  useEffect(() => {
    if (!hasInitialized.current && !isLoading) {
      if (!filterState.fkRegAssociationSeason) {
        const pkSeason = filterOptions.seasonFilterOptions.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )[0]?.value;
        setFilterState({
          ...filterState,
          fkRegAssociationSeason: pkSeason
        });
      }

      hasInitialized.current = true;
    }
  }, [isLoading]);

  return (
    <AppCard style={{ flex: 1, padding: 0, height: '100%' }}>
      <TableView
        columns={COUPON_COLUMNS}
        emptyMessage="No Coupons Available"
        filters={[
          {
            key: 1,
            label: 'Season',
            type: 'select',
            value: filterState.fkRegAssociationSeason,
            placeholder: 'Filter by season',
            data: filterOptions.seasonFilterOptions.sort((a, b) =>
              a.label.localeCompare(b.label)
            ),
            clearable: true,
            onChange: (value) => {
              setFilterState({
                ...filterState,
                fkRegAssociationSeason: value,
                fkRegAssociationDivision: null
              });
            }
          },
          {
            key: 2,
            label: 'Division',
            type: 'select',
            value: filterState.fkRegAssociationDivision,
            placeholder: 'Filter by division',
            data: filterOptions.divisionFilterOptions
              .filter(
                (f) =>
                  !filterState.fkRegAssociationSeason ||
                  f.fkRegAssociationSeason ===
                    filterState.fkRegAssociationSeason
              )
              .sort((a, b) => a.label.localeCompare(b.label)),
            clearable: true,
            onChange: (value) => {
              setFilterState({
                ...filterState,
                fkRegAssociationDivision: value,
                regFormUuid: null
              });
            }
          },
          {
            key: 3,
            label: 'Form',
            type: 'select',
            value: filterState.regFormUuid,
            placeholder: 'Filter by form',
            data: filterOptions.regFormUuidFilterOptions
              .filter(
                (f) =>
                  (!filterState.fkRegAssociationSeason ||
                    f.fkRegAssociationSeason ===
                      filterState.fkRegAssociationSeason) &&
                  (!filterState.fkRegAssociationDivision ||
                    f.fkRegAssociationDivision ===
                      filterState.fkRegAssociationDivision)
              )
              .sort((a, b) => a.label.localeCompare(b.label)),
            clearable: true,
            onChange: (value) => {
              setFilterState({
                ...filterState,
                regFormUuid: value
              });
            }
          },
          {
            key: 4,
            label: 'Coupon Name',
            type: 'text',
            value: filterState.search,
            placeholder: 'Filter by coupon name',
            onChange: (value) =>
              setFilterState({
                ...filterState,
                search: value
              })
          }
        ]}
        isDescendingSort={isDescendingSort}
        isLoading={
          !hasInitialized.current && !filterState.fkRegAssociationSeason
        }
        lastUpdated={state.regAssociationCoupons.lastUpdated}
        onAction={(action, item) => {
          onOpenModal(
            action,
            state.regAssociationCoupons.value.find(
              (s) => s.pkRegAssociationCoupon === item.key
            )
          );
        }}
        onChangeSortBy={onChangeSortBy}
        onRefresh={() =>
          fetchAdminRegAssociationCoupons(regAssociation.pkRegAssociation)
        }
        rows={state.regAssociationCoupons.value
          .filter(
            (c) =>
              (!filterState.fkRegAssociationSeason ||
                c.regAssociationSeason?.pkRegAssociationSeason.toString() ===
                  filterState.fkRegAssociationSeason) &&
              (!filterState.fkRegAssociationDivision ||
                c.regAssociationDivision?.pkRegAssociationDivision.toString() ===
                  filterState.fkRegAssociationDivision) &&
              (!filterState.regFormUuid ||
                c.regAssociationDivisionForm?.regForm.uuid ===
                  filterState.regFormUuid) &&
              (!filterState.search ||
                c.code
                  .replace(' ', '')
                  .toLowerCase()
                  .includes(filterState.search.replace(' ', '').toLowerCase()))
          )
          .map((c) => ({
            key: c.pkRegAssociationCoupon,
            actions: VIEW_ACTIONS.filter(
              (v) =>
                (!c.active && !v.hideWhenExpired) ||
                (c.active && !v.showWhenExpired)
            ),
            columns: [
              {
                key: 1,
                label: c.code,
                onClick: () => onOpenModal(VIEW_ACTIONS_ENUM.edit, c),
                weight: 500,
                subLabel: `${c.redeemedCount} ${
                  c.maxRedemptions ? `/ ${c.maxRedemptions}` : ''
                } redeemed`,
                icon: <Ticket color="black" size="30" />
              },
              {
                key: 2,
                label: !c.active ? 'Expired' : 'Active',
                color: !c.active ? 'red' : 'green'
              },
              {
                key: 3,
                label: c.redeemedCount,
                sortValueType: 'number'
              },
              {
                key: 4,
                label: c.maxRedemptions || 'N/A',
                sortValue: c.maxRedemptions ?? 0,
                sortValueType: 'number'
              },
              {
                key: 5,
                label: c.flatDiscount ? currencyFormat(c.flatDiscount) : 'N/A',
                sortValue: c.flatDiscount ?? 0,
                sortValueType: 'number'
              },
              {
                key: 6,
                label: c.percentageDiscount
                  ? `${c.percentageDiscount}%`
                  : 'N/A',
                sortValue: c.percentageDiscount ?? 0,
                sortValueType: 'number'
              },
              {
                key: 7,
                label: c.regAssociationSeason?.name
              },
              {
                key: 8,
                label: c.regAssociationDivision?.name
              },
              {
                key: 9,
                label: c.regAssociationDivisionForm?.regForm.name
              }
            ]
          }))}
        sortBy={sortValue}
        sortFilter={customFilterData}
        tableTitle="Standard Coupons"
      />
      <CouponModal
        coupon={modalState.item}
        fkRegAssociation={regAssociation?.pkRegAssociation}
        isOpen={
          modalState.isOpen &&
          modalState.action !== VIEW_ACTIONS_ENUM.viewRegistrants
        }
        onClose={onCloseModal}
        showExpireView={modalState.action === VIEW_ACTIONS_ENUM.expire}
        showReinstateView={modalState.action === VIEW_ACTIONS_ENUM.reactivate}
      />
      <CouponRegistrationsModal
        coupon={modalState.item}
        isOpen={
          modalState.isOpen &&
          modalState.action === VIEW_ACTIONS_ENUM.viewRegistrants
        }
        onClose={onCloseModal}
      />
    </AppCard>
  );
};

RegularCouponView.propTypes = {
  filterState: PropTypes.object,
  isLoading: PropTypes.bool,
  modalAction: PropTypes.string,
  regAssociation: PropTypes.object,
  setFilterState: PropTypes.func
};

export default RegularCouponView;
