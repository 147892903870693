import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Divider, Select } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { InfoCircle } from 'tabler-icons-react';
import NumberFormat from 'react-number-format';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import FormSection from '../../common/FormSection';
import { triggerNotification } from '../../../helpers/notificationHelper';
import { OFFLINE_PAYMENT_TYPES } from '../../../config/constants';
import { calculateCartTotals } from '../../../helpers/paymentHelper';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import AppTitle from '../../common/AppTitle';
import AppStack from '../../common/AppStack';

const OfflinePaymentModal = ({ isOpen, onClose, regCart }) => {
  const navigate = useNavigate();
  const { checkoutCart, fetchCart } = useContext(RegistrationContext);
  const [formState, setFormState] = useState({
    paymentType: OFFLINE_PAYMENT_TYPES[0].value,
    regCart: null,
    showConfirm: false,
    loading: false,
    showCheckoutSuccess: false
  });
  const cartTotals = regCart ? calculateCartTotals(regCart.cart.lineItems) : {};
  const offlineServiceFeeInCents = regCart
    ? regCart.cart.lineItems.filter(
        (i) => !i.financialAssistance && i.customerPaysServiceFees
      ).length * 300
    : 0;
  const serviceFeeDifference =
    cartTotals.serviceFeeInCents - offlineServiceFeeInCents;

  const associationNames = regCart
    ? [
        ...new Set(
          regCart?.regCartProducts.map(
            (p) => p.regProduct.regAssociation.association.name
          )
        )
      ]
    : [];
  const currency = regCart?.cart.currency?.toUpperCase();

  useEffect(() => {
    if (isOpen) {
      setFormState({
        paymentType: OFFLINE_PAYMENT_TYPES[0].value,
        regCart,
        showConfirm: false,
        loading: false,
        showCheckoutSuccess: false
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      onClose={onClose}
      opened={isOpen}
      size={600}
      title={
        <AppTitle order={2}>
          {formState.showCheckoutSuccess
            ? 'Payment Pending'
            : formState.showConfirm
            ? 'Checkout'
            : 'Select Offline Payment'}
        </AppTitle>
      }
    >
      {formState.showCheckoutSuccess ? (
        <AppStack style={{ gap: 30, marginTop: 10 }}>
          <Alert
            icon={
              <InfoCircle style={{ width: 35, height: 35, marginTop: 5 }} />
            }
            title={<AppTitle order={4}>Payment is now pending</AppTitle>}
            variant="outline"
          >
            <AppText>
              You can view the order status and payment details in your order
              history.
            </AppText>
          </Alert>
          <AppFlexbox style={{ flex: 1, justifyContent: 'center' }}>
            <Button
              onClick={() => {
                navigate(`/orders/${formState.regCart.pkRegCart}`);
              }}
              style={{ width: '100%', maxWidth: 350 }}
            >
              View Order History
            </Button>
          </AppFlexbox>
        </AppStack>
      ) : (
        <FormSection
          cancelTitle="Cancel"
          isLoading={formState.loading}
          onCancel={onClose}
          onSubmit={() => {
            if (formState.showConfirm) {
              setFormState({
                ...formState,
                loading: true
              });
              checkoutCart(
                { paymentType: 'offline' },
                () => {
                  triggerNotification(
                    'Checkout Successful',
                    'Success',
                    'green'
                  );
                  fetchCart();
                  setFormState({
                    ...formState,
                    showCheckoutSuccess: true,
                    loading: false
                  });
                },
                (message) => {
                  triggerNotification(message);
                  setFormState({
                    ...formState,
                    loading: false
                  });
                }
              );
            }
            else {
              setFormState({
                ...formState,
                showConfirm: true
              });
            }
          }}
          style={{ gap: 30 }}
          submitTitle={formState.showConfirm ? 'Finish Checkout' : 'Continue'}
        >
          {formState.showConfirm ? (
            <AppStack>
              {cartTotals.serviceFeeInCents > 0 && (
                <Alert
                  color="blue"
                  icon={
                    <InfoCircle
                      style={{ width: 35, height: 35, marginTop: 5 }}
                    />
                  }
                  style={{ margin: '20px 0px' }}
                  title={<AppTitle order={4}>Service fee adjusted</AppTitle>}
                  variant="outline"
                >
                  <AppText weight={500}>
                    Service fee has been reduced to $3 per fee for offline
                    payments
                  </AppText>
                </Alert>
              )}
              <AppText size="lg" weight={700}>
                Order Summary
              </AppText>
              <AppFlexbox
                style={{ justifyContent: 'space-between', flexWrap: 'nowrap' }}
              >
                <AppText size="lg">Subtotal</AppText>
                <AppFlexbox style={{ gap: 10 }}>
                  <AppText size="lg">
                    <NumberFormat
                      decimalScale={2}
                      displayType="text"
                      fixedDecimalScale
                      prefix="$"
                      thousandSeparator
                      value={cartTotals.subtotalInCents / 100}
                    />
                  </AppText>
                  <AppText size="lg">{currency}</AppText>
                </AppFlexbox>
              </AppFlexbox>
              {cartTotals.discountInCents > 0 && (
                <AppFlexbox
                  style={{
                    justifyContent: 'space-between',
                    flexWrap: 'nowrap'
                  }}
                >
                  <AppText size="lg">Discount</AppText>
                  <AppFlexbox style={{ gap: 10 }}>
                    <AppText size="lg">
                      <NumberFormat
                        decimalScale={2}
                        displayType="text"
                        fixedDecimalScale
                        prefix="$"
                        thousandSeparator
                        value={(cartTotals.discountInCents / 100) * -1}
                      />
                    </AppText>
                    <AppText size="lg">{currency}</AppText>
                  </AppFlexbox>
                </AppFlexbox>
              )}
              <AppFlexbox
                style={{ justifyContent: 'space-between', flexWrap: 'nowrap' }}
              >
                <AppText size="lg">Taxes</AppText>
                <AppFlexbox style={{ gap: 10 }}>
                  <AppText size="lg">
                    <NumberFormat
                      decimalScale={2}
                      displayType="text"
                      fixedDecimalScale
                      prefix="$"
                      thousandSeparator
                      value={cartTotals.taxInCents / 100}
                    />
                  </AppText>
                  <AppText size="lg">{currency}</AppText>
                </AppFlexbox>
              </AppFlexbox>
              {offlineServiceFeeInCents > 0 && (
                <AppFlexbox
                  style={{
                    justifyContent: 'space-between',
                    flexWrap: 'nowrap'
                  }}
                >
                  <AppText size="lg">Service Fee</AppText>
                  <AppFlexbox style={{ gap: 10 }}>
                    <AppText size="lg">
                      <NumberFormat
                        decimalScale={2}
                        displayType="text"
                        fixedDecimalScale
                        prefix="$"
                        thousandSeparator
                        value={offlineServiceFeeInCents / 100}
                      />
                    </AppText>
                    <AppText size="lg">{currency}</AppText>
                  </AppFlexbox>
                </AppFlexbox>
              )}
              <Divider />
              <AppFlexbox
                style={{
                  justifyContent: 'space-between',
                  flexWrap: 'nowrap'
                }}
              >
                <AppText size="lg" weight={700}>
                  Total
                </AppText>
                <AppFlexbox style={{ gap: 10 }}>
                  <AppText size="lg" weight={700}>
                    <NumberFormat
                      decimalScale={2}
                      displayType="text"
                      fixedDecimalScale
                      prefix="$"
                      thousandSeparator
                      value={
                        (cartTotals.totalInCents - serviceFeeDifference) / 100
                      }
                    />
                  </AppText>
                  <AppText size="lg" weight={700}>
                    {currency}
                  </AppText>
                </AppFlexbox>
              </AppFlexbox>

              <Alert
                color="blue"
                icon={
                  <InfoCircle style={{ width: 35, height: 35, marginTop: 3 }} />
                }
                style={{ margin: '20px 0px' }}
                variant="outline"
              >
                <AppText>
                  Please direct your payment to{' '}
                  <b>
                    {associationNames.length > 1
                      ? 'each association'
                      : associationNames[0]}
                  </b>{' '}
                  using any of their accepted methods.
                </AppText>
              </Alert>
            </AppStack>
          ) : (
            <Select
              clearable
              data={OFFLINE_PAYMENT_TYPES.filter(
                (p) =>
                  p.value === '1' ||
                  p.value === '2' ||
                  p.value === '3' ||
                  p.value === '4'
              )}
              label="Payment Type"
              onChange={(value) =>
                setFormState({
                  ...formState,
                  paymentType: value
                })
              }
              required
              searchable
              value={formState.paymentType}
            />
          )}
        </FormSection>
      )}
    </ResponsiveModal>
  );
};

OfflinePaymentModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  regCart: PropTypes.object
};

export default OfflinePaymentModal;
