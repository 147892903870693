import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { AlertCircle, User } from 'tabler-icons-react';
import { useLocation } from 'react-router-dom';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { Context as RegistrationAdminDashboardContext } from '../../../providers/RegistrationAdminDashboardProvider';
import AppCard from '../../common/AppCard';
import TableView from '../../common/TableView';
import { useModalState, useSortByFilter } from '../../../helpers/hooks';
import { getSpecialRequestsWarnings } from '../../../helpers/teamGeneration';
import SendEmailModal from '../../common/SendEmailModal';
import SyncDivisionTeamsModal from './SyncDivisionTeamsModal';
import TeamsModal from '../teams/TeamsModal';
import TeamDetailsModal from '../teams/TeamDetailsModal';
import { getEvaluationRatingForSessions } from '../../../helpers/evaluationPlayerHelper';

const TEAM_ACTIONS_ENUM = {
  view: 'VIEW',
  create: 'CREATE',
  edit: 'EDIT',
  emailUsers: 'EMAIL',
  sync: 'SYNC',
  delete: 'DELETE'
};

const TEAM_ACTIONS = [
  {
    label: 'View',
    value: TEAM_ACTIONS_ENUM.view
  },
  {
    label: 'Edit',
    value: TEAM_ACTIONS_ENUM.edit
  },
  {
    label: 'Email Users',
    value: TEAM_ACTIONS_ENUM.emailUsers
  },
  {
    label: 'Launch Team',
    value: TEAM_ACTIONS_ENUM.sync
  },
  {
    label: 'Delete',
    value: TEAM_ACTIONS_ENUM.delete
  }
];

const TABLE_COLUMNS = [
  {
    label: 'Name',
    value: 'name',
    sortable: true
  },
  {
    label: 'Division',
    value: 'division',
    sortable: true
  },
  {
    label: 'Age Requirements',
    value: 'age',
    sortable: true
  },
  {
    label: 'Overall Rating',
    value: 'overall',
    sortable: true
  },
  {
    label: 'Status',
    value: 'status',
    sortable: true
  }
];

const TEAM_LAUNCH_STATUS = {
  NO_ASSIGNED_MEMBERS: {
    label: 'No assigned members',
    color: 'black'
  },
  READY_TO_LAUNCH: {
    label: 'Ready to launch',
    color: 'yellow'
  },
  PENDING_RELAUNCH: {
    label: 'Pending relaunch',
    color: 'yellow'
  },
  LAUNCHED: {
    label: 'Launched',
    color: 'green'
  }
};

const LeagueTeamsView = ({
  isLoading,
  modalAction,
  filters,
  filterState,
  setFilterState,
  regAssociation
}) => {
  const { state: locationState } = useLocation();
  const { state: dashboardState } = useContext(
    RegistrationAdminDashboardContext
  );
  const {
    state,
    fetchRegAssociationDivisionTeams,
    fetchAdminRegSpecialRequests
  } = useContext(RegistrationAdminContext);
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const {
    sortValue,
    isDescendingSort,
    onChangeSortBy,
    customFilterData
  } = useSortByFilter(TABLE_COLUMNS);

  const divisionTeams = state.regAssociationDivisionTeams.value.filter(
    (t) =>
      (!filterState.pkRegAssociationSeason ||
        t.regAssociationDivision.fkRegAssociationSeason.toString() ===
          filterState?.pkRegAssociationSeason) &&
      (!filterState.pkRegAssociationDivision ||
        t.fkRegAssociationDivision.toString() ===
          filterState?.pkRegAssociationDivision)
  );

  const divisionSpecialRequests = state.regSpecialRequests.value.filter(
    (s) =>
      (!filterState.pkRegAssociationSeason ||
        s.regFormSubmission1.regAssociationDivisionOverride?.regAssociationSeason.pkRegAssociationSeason.toString() ===
          filterState.pkRegAssociationSeason ||
        (!s.regFormSubmission1.regAssociationDivisionOverride &&
          s.regFormSubmission1.regAssociationDivisionForm.regAssociationDivision.regAssociationSeason.pkRegAssociationSeason.toString() ===
            filterState.pkRegAssociationSeason)) &&
      (!filterState.pkRegAssociationDivision ||
        s.regFormSubmission1.regAssociationDivisionOverride?.regAssociationSeason.pkRegAssociationSeason.toString() ===
          filterState.pkRegAssociationDivision ||
        (!s.regFormSubmission1.regAssociationDivisionOverride &&
          s.regFormSubmission1.regAssociationDivisionForm.regAssociationDivision.regAssociationSeason.pkRegAssociationSeason.toString() ===
            filterState.pkRegAssociationDivision))
  );

  const regSpecialRequestErrors = getSpecialRequestsWarnings(
    divisionTeams,
    divisionSpecialRequests
  );

  const divisionsWithUnassignedRegPersons = state.regAssociationDivisions.value.filter(
    (d) =>
      (!filterState.pkRegAssociationSeason ||
        d.fkRegAssociationSeason.toString() ===
          filterState?.pkRegAssociationSeason) &&
      (!filterState.pkRegAssociationDivision ||
        d.pkRegAssociationDivision.toString() ===
          filterState?.pkRegAssociationDivision) &&
      d.unassignedRegPersonCount > 0
  );

  const unassignedRegPersonCount = divisionsWithUnassignedRegPersons.reduce(
    (r, c) => r + c.unassignedRegPersonCount,
    0
  );

  const divisionTeamErrors = [
    ...(unassignedRegPersonCount > 0
      ? [
          {
            key: 0,
            message: `${unassignedRegPersonCount} unassigned registrant(s)`,
            additionalErrors:
              divisionsWithUnassignedRegPersons.length > 0
                ? divisionsWithUnassignedRegPersons.map(
                    (d) => `${d.name} - ${d.unassignedRegPersonCount}`
                  )
                : null,
            color: 'red',
            icon: <AlertCircle />
          }
        ]
      : []),
    ...(regSpecialRequestErrors.length > 0
      ? [
          {
            key: 2,
            message: `${regSpecialRequestErrors.length} special request conditions not met`,
            additionalErrors: regSpecialRequestErrors,
            color: 'red',
            icon: <AlertCircle />
          }
        ]
      : [])
  ];

  useEffect(() => {
    if (modalAction === TEAM_ACTIONS_ENUM.create) {
      onOpenModal(modalAction);
    }
  }, [modalAction]);

  useEffect(() => {
    if (locationState?.openModal && !isLoading) {
      setFilterState({
        ...filterState,
        pkRegAssociationDivision:
          locationState.pkRegAssociationDivision?.toString() ?? null
      });
      onOpenModal(TEAM_ACTIONS_ENUM.create);
    }
  }, [locationState?.openModal, isLoading]);

  return (
    <AppCard style={{ flex: 1, padding: 0, height: '100%' }}>
      <TableView
        alerts={divisionTeamErrors}
        columns={TABLE_COLUMNS}
        emptyMessage="No Teams Available"
        filters={filters}
        isDescendingSort={isDescendingSort}
        isLoading={isLoading}
        lastUpdated={state.regAssociationDivisionTeams.lastUpdated}
        onAction={(action, item) => {
          onOpenModal(
            action,
            state.regAssociationDivisionTeams.value.find(
              (s) => s.pkRegAssociationDivisionTeam === item.key
            )
          );
        }}
        onChangeSortBy={onChangeSortBy}
        onRefresh={() => {
          fetchRegAssociationDivisionTeams({
            pkRegAssociation:
              dashboardState.regAssociation.value.pkRegAssociation
          });
          fetchAdminRegSpecialRequests({
            pkRegAssociation:
              dashboardState.regAssociation.value.pkRegAssociation
          });
        }}
        rows={divisionTeams.map((t) => {
          const activeRegPersonCount = t?.regAssociationDivisionTeamPersons.filter(
            (p) => p.active
          ).length;

          const teamPersons = t.regAssociationDivisionTeamPersons.reduce(
            (r, c) => {
              if (c.active) {
                r.push(c);
              }
              else {
                const existingActive = t.regAssociationDivisionTeamPersons.find(
                  (p) =>
                    p.regPerson.pkRegPerson === c.regPerson.pkRegPerson &&
                    p.active
                );
                if (!existingActive) {
                  r.push(c);
                }
              }
              return r;
            },
            []
          );

          const team = t?.team;

          const teamUpdated =
            team &&
            !teamPersons.every(
              (p) =>
                !!team.members.find(
                  (m) =>
                    m.name ===
                      `${p.regPerson.firstName} ${p.regPerson.lastName}` &&
                    m.remoteUserHandlers.some(
                      (h) => h.pkUser === p.regPerson.fkUserOwner
                    ) &&
                    m.isActive === p.active
                )
            );

          const launchStatus = !team
            ? activeRegPersonCount === 0
              ? TEAM_LAUNCH_STATUS.NO_ASSIGNED_MEMBERS
              : TEAM_LAUNCH_STATUS.READY_TO_LAUNCH
            : teamUpdated
            ? TEAM_LAUNCH_STATUS.PENDING_RELAUNCH
            : TEAM_LAUNCH_STATUS.LAUNCHED;

          const overallRating = t.regAssociationDivisionTeamPersons.reduce(
            (r, c) =>
              r +
                getEvaluationRatingForSessions(
                  {
                    pkRegAssociationDivision:
                      t.regAssociationDivision.pkRegAssociationDivision,
                    pkRegPerson: c.regPerson.pkRegPerson,
                    pkRegScoutReportPerson: null
                  },
                  state.regScoutReportEvaluationSessions.value
                ).overallRating || 0,
            0
          );

          return {
            key: t.pkRegAssociationDivisionTeam,
            actions: TEAM_ACTIONS,
            columns: [
              {
                key: 1,
                label: t.name,
                weight: 500,
                subLabel: `${
                  t.regAssociationDivisionTeamPersons.filter((p) => p.active)
                    .length
                } Players`,
                onClick: () => onOpenModal(TEAM_ACTIONS_ENUM.edit, t),
                icon: <User size={32} />
              },
              {
                key: 2,
                label: t.regAssociationDivision.name,
                weight: 500,
                subLabel: t.regAssociationDivision.regAssociationSeason.name
              },
              {
                key: 3,
                label: t.regAssociationDivision.isAllAges
                  ? '(All Ages)'
                  : `(${dayjs(new Date()).diff(
                      t.regAssociationDivision.minDob,
                      'year'
                    )} years old and younger)`,
                sortValue: dayjs(new Date()).diff(
                  t.regAssociationDivision.minDob,
                  'year'
                ),
                sortValueType: 'date',
                showOnMobile: true
              },
              {
                key: 4,
                label:
                  !state.regScoutReportEvaluationSessions.loading &&
                  overallRating
                    ? Math.round(overallRating * 10) / 10
                    : '',
                sortValueType: 'number',
                showOnMobile: true
              },
              {
                key: 5,
                label: launchStatus.label,
                color: launchStatus.color,
                showOnMobile: true
              }
            ]
          };
        })}
        sortBy={sortValue}
        sortFilter={customFilterData}
        tableTitle="Teams"
      />

      <TeamDetailsModal
        isOpen={
          modalState.isOpen && modalState.action === TEAM_ACTIONS_ENUM.view
        }
        onClose={onCloseModal}
        regAssociation={regAssociation}
        regAssociationDivisionTeam={modalState.item}
      />

      <TeamsModal
        fkRegAssociationDivision={filterState.pkRegAssociationDivision}
        fkRegAssociationSeason={filterState.pkRegAssociationSeason}
        isOpen={
          modalState.isOpen &&
          modalState.action !== TEAM_ACTIONS_ENUM.view &&
          modalState.action !== TEAM_ACTIONS_ENUM.sync &&
          modalState.action !== TEAM_ACTIONS_ENUM.emailUsers
        }
        onClose={onCloseModal}
        regAssociation={dashboardState.regAssociation.value}
        regAssociationDivisionTeam={modalState.item}
        showDelete={modalState.action === TEAM_ACTIONS_ENUM.delete}
      />

      <SendEmailModal
        isOpen={
          modalState.isOpen &&
          modalState.action === TEAM_ACTIONS_ENUM.emailUsers
        }
        onClose={onCloseModal}
        pkRegAssociation={dashboardState.regAssociation.value?.pkRegAssociation}
        pkRegAssociationDivisionTeam={
          modalState.item?.pkRegAssociationDivisionTeam
        }
      />
      <SyncDivisionTeamsModal
        isOpen={
          modalState.isOpen && modalState.action === TEAM_ACTIONS_ENUM.sync
        }
        onClose={onCloseModal}
        regAssociationDivisionTeam={modalState.item}
      />
    </AppCard>
  );
};

LeagueTeamsView.propTypes = {
  filterState: PropTypes.object,
  filters: PropTypes.array,
  isLoading: PropTypes.bool,
  modalAction: PropTypes.string,
  regAssociation: PropTypes.object,
  setFilterState: PropTypes.func
};

export default LeagueTeamsView;
