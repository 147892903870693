import React, { useEffect, useState } from 'react';
import { Skeleton, Tabs, Tooltip } from '@mantine/core';
import PropTypes from 'prop-types';
import AppText from './AppText';

const TabsContent = ({
  tabs,
  activeTab,
  onTabChange,
  children,
  isLoading,
  tabActionButton,
  ...rest
}) => {
  const [activeTabState, setActiveTabState] = useState(
    tabs ? tabs[0]?.value : ''
  );

  useEffect(() => {
    if (tabs && tabs.length > 0) {
      setActiveTabState(tabs[0].value);
    }
  }, tabs);

  return (
    <Tabs
      onChange={(value) => {
        if (onTabChange) {
          onTabChange(value);
        }
        else {
          setActiveTabState(value);
        }
      }}
      value={activeTab ?? activeTabState}
      {...rest}
    >
      <Tabs.List>
        {tabs.map((t) => (
          <Tooltip
            key={t.value}
            disabled={!t.tooltipMessage}
            label={t.tooltipMessage}
            multiline
            w={300}
            withArrow
          >
            <Tabs.Tab disabled={t.disabled} value={t.value}>
              {isLoading ? (
                <Skeleton height={14} width={60} />
              ) : (
                <AppText
                  style={{
                    fontSize: t.fontSize || 14,
                    lineHeight: t.fontSize ? 'unset' : '14px',
                    overflow: 'visible'
                  }}
                  weight={t.fontWeight}
                >
                  {t.label}
                </AppText>
              )}
            </Tabs.Tab>
          </Tooltip>
        ))}

        {tabActionButton}
      </Tabs.List>
      {children}
    </Tabs>
  );
};

TabsContent.propTypes = {
  activeTab: PropTypes.string,
  children: PropTypes.any,
  isLoading: PropTypes.bool,
  onTabChange: PropTypes.func,
  tabActionButton: PropTypes.node,
  tabs: PropTypes.array
};

export default TabsContent;
