import React, { useContext, useEffect, useRef } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Plus } from 'tabler-icons-react';
import AdminView from '../components/common/AdminView';
import LeagueSeasonsView from '../components/content/leagues/LeagueSeasonsView';
import { Context as RegistrationAdminDashboardContext } from '../providers/RegistrationAdminDashboardProvider';
import { Context as RegistrationAdminContext } from '../providers/RegistrationAdminProvider';
import LeagueDivisionsView from '../components/content/leagues/LeagueDivisionsView';
import { useModalState } from '../helpers/hooks';
import LeagueTeamsView from '../components/content/leagues/LeagueTeamsView';
import SUPPORT_LINKS from '../components/content/navigation/supportLinks';
import { useMyLeagueFilterOptions } from '../helpers/leagueHooks';
import TeamBuilderView from '../components/content/teams/TeamBuilderView';

const LEAGUE_VIEW_TABS = [
  {
    to: '/admin/league/seasons',
    title: 'Seasons',
    value: 'seasons',
    index: 0,
    control: {
      label: 'Create Season',
      icon: <Plus />,
      action: 'CREATE'
    },

    indexHelpLink: SUPPORT_LINKS.myLeagueLinks.index,
    helpLinks: [
      SUPPORT_LINKS.myLeagueLinks.createSeason,
      SUPPORT_LINKS.myLeagueLinks.setupDivision,
      SUPPORT_LINKS.myLeagueLinks.waitlists,
      SUPPORT_LINKS.myLeagueLinks.invites,
      SUPPORT_LINKS.myLeagueLinks.emailUsers
    ]
  },
  {
    to: '/admin/league/divisions',
    title: 'Divisions',
    value: 'divisions',
    index: 1,
    control: {
      label: 'Create Division',
      icon: <Plus />,
      action: 'CREATE'
    },
    indexHelpLink: SUPPORT_LINKS.myLeagueLinks.index,
    helpLinks: [
      SUPPORT_LINKS.myLeagueLinks.setupDivision,
      SUPPORT_LINKS.myLeagueLinks.waitlists,
      SUPPORT_LINKS.myLeagueLinks.invites,
      SUPPORT_LINKS.myLeagueLinks.emailUsers
    ]
  },
  {
    to: '/admin/league/teams',
    title: 'Teams',
    value: 'teams',
    index: 2,
    control: {
      label: 'Create / Import',
      icon: <Plus />,
      action: 'CREATE'
    },
    indexHelpLink: SUPPORT_LINKS.myLeagueLinks.index,
    helpLinks: [
      SUPPORT_LINKS.myLeagueLinks.waitlists,
      SUPPORT_LINKS.myLeagueLinks.invites,
      SUPPORT_LINKS.myLeagueLinks.emailUsers
    ]
  }
];

const LeagueView = () => {
  const hasSeasonPkRegAssociation = useRef(null);
  const fetchedTeamPkRegAssociation = useRef(null);
  const { pathname } = useLocation();
  const { state: dashboardState } = useContext(
    RegistrationAdminDashboardContext
  );
  const {
    state,
    fetchAdminRegAssociationSeasons,
    fetchRegAssociationDivisionTeams,
    fetchAdminRegSpecialRequests,
    fetchScoutReportEvaluations
  } = useContext(RegistrationAdminContext);
  const { state: modalState, onOpenModal } = useModalState({
    closeOnOpen: true
  });
  const selectedTab = LEAGUE_VIEW_TABS.find((tab) =>
    pathname.startsWith(tab.to)
  );
  const {
    filters,
    filterState,
    setFilterState,
    onResetFilterState
  } = useMyLeagueFilterOptions(dashboardState.regAssociation.value, {
    includeSeason: selectedTab?.index > 0,
    includeDivision: selectedTab?.index > 1,
    includeRegPerson: selectedTab?.index > 1,
    notRequiredFields: selectedTab?.index > 1
  });
  const isLoading =
    hasSeasonPkRegAssociation.current !==
      dashboardState.regAssociation.value?.pkRegAssociation ||
    state.regAssociationSeasons.loading ||
    state.regAssociationDivisionTeams.loading;

  useEffect(() => {
    if (dashboardState.regAssociation.value) {
      if (
        hasSeasonPkRegAssociation.current !==
        dashboardState.regAssociation.value.pkRegAssociation
      ) {
        onResetFilterState();
        fetchAdminRegAssociationSeasons(
          dashboardState.regAssociation.value.pkRegAssociation
        );
        hasSeasonPkRegAssociation.current =
          dashboardState.regAssociation.value.pkRegAssociation;
      }

      if (
        selectedTab.index === 2 &&
        fetchedTeamPkRegAssociation.current !==
          dashboardState.regAssociation.value.pkRegAssociation
      ) {
        fetchRegAssociationDivisionTeams({
          pkRegAssociation: dashboardState.regAssociation.value.pkRegAssociation
        });
        fetchAdminRegSpecialRequests({
          pkRegAssociation: dashboardState.regAssociation.value.pkRegAssociation
        });
        fetchScoutReportEvaluations(
          dashboardState.regAssociation.value.pkRegAssociation
        );

        fetchedTeamPkRegAssociation.current =
          dashboardState.regAssociation.value.pkRegAssociation;
      }
    }
  }, [dashboardState.regAssociation.value, pathname]);

  return (
    <Routes>
      <Route element={<TeamBuilderView />} path="teams/builder/*" />
      <Route
        element={
          <AdminView
            headerTitle="My League"
            isActionControlDisabled={
              selectedTab?.isControlDisabled &&
              selectedTab?.isControlDisabled(state)
            }
            isViewLoading={isLoading}
            onAction={onOpenModal}
            tabs={LEAGUE_VIEW_TABS}
          >
            <Routes>
              <Route
                element={
                  <LeagueSeasonsView
                    filters={filters}
                    filterState={filterState}
                    isLoading={isLoading}
                    modalAction={modalState.isOpen ? modalState.action : ''}
                    regAssociation={dashboardState.regAssociation.value}
                    setFilterState={setFilterState}
                  />
                }
                path="seasons/*"
              />
              <Route
                element={
                  <LeagueDivisionsView
                    filters={filters}
                    filterState={filterState}
                    isLoading={isLoading}
                    modalAction={modalState.isOpen ? modalState.action : ''}
                    regAssociation={dashboardState.regAssociation.value}
                    setFilterState={setFilterState}
                  />
                }
                path="divisions/*"
              />
              <Route
                element={
                  <LeagueTeamsView
                    filters={filters}
                    filterState={filterState}
                    isLoading={
                      fetchedTeamPkRegAssociation.current !==
                        dashboardState.regAssociation.value?.pkRegAssociation ||
                      isLoading
                    }
                    modalAction={modalState.isOpen ? modalState.action : ''}
                    regAssociation={dashboardState.regAssociation.value}
                    setFilterState={setFilterState}
                  />
                }
                path="teams/*"
              />
              <Route
                element={<Navigate replace to={LEAGUE_VIEW_TABS[0].to} />}
                path="*"
              />
            </Routes>
          </AdminView>
        }
        path="*"
      />
    </Routes>
  );
};

export default LeagueView;
