import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Alert, Button, Card, Checkbox, Divider } from '@mantine/core';
import { AlertCircle, ArrowRight } from 'tabler-icons-react';
import { Link } from 'react-router-dom';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppTitle from '../../common/AppTitle';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import classes from '../../../styles/nestedStyles.module.css';
import { CURRENCY_TYPE_LIST } from '../../../config/constants';
import FormSection from '../../common/FormSection';

const ChangeCurrencyModal = ({
  isOpen,
  onClose,
  onConfirm,
  currencyType,
  confirmLoading,
  sandboxEnabled,
  showRedirectButton
}) => {
  const [fkCurrencyType, setFkCurrencyType] = useState(null);
  const selectedCurrencyLabel = CURRENCY_TYPE_LIST.find(
    (c) => c.value === fkCurrencyType
  )?.fullLabel;

  useEffect(() => {
    if (isOpen) {
      setFkCurrencyType(null);
    }
  }, [isOpen]);

  const onSelectCurrency = (selectedFkCurrencyType) => {
    if (selectedFkCurrencyType === currencyType) {
      onClose();
    }
    else {
      setFkCurrencyType(selectedFkCurrencyType);
    }
  };

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClose}
      title={<AppTitle order={2}>Registration Currency</AppTitle>}
    >
      {fkCurrencyType ? (
        <FormSection
          isLoading={confirmLoading}
          isSubmitDisabled={!sandboxEnabled}
          onCancel={onClose}
          onSubmit={() => {
            onConfirm(fkCurrencyType);
          }}
          submitTitle="Confirm"
        >
          <AppStack style={{ gap: 20 }}>
            {!sandboxEnabled ? (
              <Alert
                color="yellow"
                icon={<AlertCircle />}
                title="Review pricing"
                variant="outline"
              >
                <AppStack>
                  <AppText style={{ fontSize: 14 }}>
                    You must switch to sandbox mode before changing your
                    currency to ensure that your pricing is correct. You can
                    switch back to live mode once you have reviewed and updated
                    your pricing.
                  </AppText>
                  {showRedirectButton && (
                    <AppFlexbox>
                      <Button
                        color="dark"
                        component={Link}
                        rightSection={<ArrowRight size={18} />}
                        size="compact-md"
                        to="/admin/branding"
                        variant="outline"
                      >
                        Go to settings
                      </Button>
                    </AppFlexbox>
                  )}
                </AppStack>
              </Alert>
            ) : (
              <>
                <AppText style={{ marginTop: 20, fontSize: 14 }}>
                  Are you sure you want to change the currency to{' '}
                  <b>{selectedCurrencyLabel}</b>? This will be the currency used
                  for all future transactions.
                </AppText>
                <Alert
                  color="yellow"
                  icon={<AlertCircle />}
                  title="Review pricing"
                  variant="outline"
                >
                  <AppStack>
                    <AppText style={{ fontSize: 14 }}>
                      Please note that changing the currency will require you to
                      review and update any pricing you already have set up.
                    </AppText>

                    <Checkbox
                      label="I acknowledge that pricing needs to be review upon changing."
                      required
                    />
                  </AppStack>
                </Alert>
              </>
            )}
          </AppStack>
        </FormSection>
      ) : (
        <AppStack style={{ gap: 20 }}>
          <AppText>
            Please select the currency you would like to use for registration
            below. This will be the currency used for all future transactions.
          </AppText>

          <Card radius="md" shadow="none" style={{ padding: 0 }} withBorder>
            <AppStack style={{ gap: 0, padding: 0 }}>
              {CURRENCY_TYPE_LIST.map((c, index) => (
                <React.Fragment key={c.value}>
                  {index !== 0 && <Divider />}
                  <AppFlexbox
                    className={classes['hover-action-card']}
                    onClick={() => onSelectCurrency(c.value)}
                    style={{
                      padding: '24px 16px',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <AppText>{c.fullLabel}</AppText>
                    {currencyType?.toString() === c.value.toString() && (
                      <AppText
                        style={{
                          fontSize: 14,
                          fontWeight: 500,
                          color: 'green'
                        }}
                      >
                        Selected
                      </AppText>
                    )}
                  </AppFlexbox>
                </React.Fragment>
              ))}
            </AppStack>
          </Card>

          <AppText style={{ fontSize: 12, color: '#666', textAlign: 'center' }}>
            You can change this at any time in your settings.
          </AppText>
        </AppStack>
      )}
    </ResponsiveModal>
  );
};

ChangeCurrencyModal.propTypes = {
  confirmLoading: PropTypes.bool,
  currencyType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  sandboxEnabled: PropTypes.bool,
  showRedirectButton: PropTypes.bool
};

export default ChangeCurrencyModal;
