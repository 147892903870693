import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Select, TextInput } from '@mantine/core';
import {
  AlertCircle,
  ArrowsHorizontal,
  ArrowsVertical
} from 'tabler-icons-react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import FormSection from '../../common/FormSection';
import AppStack from '../../common/AppStack';
import AppTitle from '../../common/AppTitle';
import SpecialRequestWarnings from '../leagues/SpecialRequestWarnings';
import AppText from '../../common/AppText';
import LeagueTeamPersonListItem from '../leagues/LeagueTeamPersonListItem';
import AppFlexbox from '../../common/AppFlexbox';
import { triggerNotification } from '../../../helpers/notificationHelper';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import classes from '../../../styles/nestedStyles.module.css';
import { getManualTeamSpecialRequestsWarnings } from '../../../helpers/teamGeneration';

const EditTeam = ({
  regAssociationDivision,
  regAssociationDivisionTeam,
  availableRegistrants,
  showDelete,
  isLoading,
  onSaveChanges,
  onCancel,
  onDelete
}) => {
  const mqIndex = useMediaQueryIndex();
  const {
    state,
    createAdminRegAssociationDivisionTeam,
    updateAdminRegAssociationDivisionTeam,
    fetchUpdatedAdminRegAssociationDivision
  } = useContext(RegistrationAdminContext);
  const [formState, setFormState] = useState({
    name: '',
    selectedRegistrant: null,
    assignedRegistrants: [],
    confirmDelete: false,
    isLoading: false
  });
  const division =
    regAssociationDivisionTeam?.regAssociationDivision ||
    regAssociationDivision;
  const assignableRegistrants = availableRegistrants.filter(
    (r) =>
      !formState.assignedRegistrants.some(
        (a) => a.regPerson.pkRegPerson === r.regPerson.pkRegPerson
      )
  );

  const specialRequestErrors = getManualTeamSpecialRequestsWarnings(
    formState.assignedRegistrants,
    state.regSpecialRequests.value
  );

  useEffect(() => {
    setFormState({
      name: regAssociationDivisionTeam?.name || '',
      selectedRegistrant: null,
      assignedRegistrants:
        regAssociationDivisionTeam?.regAssociationDivisionTeamPersons.filter(
          (p) => p.active
        ) || [],
      confirmDelete: showDelete || false,
      isLoading: false
    });
  }, [regAssociationDivisionTeam]);

  const onSuccess = () => {
    fetchUpdatedAdminRegAssociationDivision(division.pkRegAssociationDivision);
    onSaveChanges();
  };

  const onError = (e) => {
    setFormState({
      ...formState,
      isLoading: false
    });
    triggerNotification(e);
  };

  return (
    <FormSection
      cancelTitle={regAssociationDivisionTeam ? 'Cancel' : 'Back'}
      isLoading={formState.isLoading}
      onCancel={onCancel}
      onSubmit={() => {
        setFormState({
          ...formState,
          isLoading: true
        });

        const teamRequestData = {
          name: formState.name,
          fkRegAssociationDivision: division.pkRegAssociationDivision,
          regAssociationDivisionTeamPersons: formState.assignedRegistrants.map(
            (r) => ({
              active: true,
              fkRegPerson: r.regPerson.pkRegPerson,
              role:
                r.role ?? r.regFormSubmissionRoleAnswers[0]?.role ?? 'Player'
            })
          )
        };

        if (regAssociationDivisionTeam) {
          updateAdminRegAssociationDivisionTeam(
            regAssociationDivisionTeam.pkRegAssociationDivisionTeam,
            teamRequestData,
            onSuccess,
            onError
          );
        }
        else {
          createAdminRegAssociationDivisionTeam(
            teamRequestData,
            onSuccess,
            onError
          );
        }
      }}
      style={{ gap: 20 }}
      submitTitle={regAssociationDivisionTeam ? 'Save Changes' : 'Create'}
    >
      <AppFlexbox
        style={{
          gap: rs([10, 10, 70], mqIndex),
          flexDirection: rs(['column', 'column', 'row'], mqIndex),
          alignItems: 'stretch'
        }}
      >
        <TextInput
          disabled={formState.isLoading}
          label="Team Name"
          onChange={(e) =>
            setFormState({
              ...formState,
              name: e.currentTarget.value
            })
          }
          placeholder="Team 1"
          required
          style={{ flex: 1 }}
          value={formState.name}
        />
        <Select
          data={
            division
              ? [
                  {
                    label: division.name,
                    value: division.pkRegAssociationDivision.toString()
                  }
                ]
              : []
          }
          disabled
          label="Division"
          onChange={() => {}}
          required
          style={{ flex: 1 }}
          value={division?.pkRegAssociationDivision.toString() ?? ''}
        />
      </AppFlexbox>
      <DragDropContext
        onDragEnd={(e) => {
          if (e.destination && formState.selectedRegistrant) {
            if (e.destination.droppableId === 'assignedDroppable') {
              const assignedRegPerson = formState.assignedRegistrants.find(
                (p) =>
                  p.regPerson.pkRegPerson ===
                  formState.selectedRegistrant.regPerson.pkRegPerson
              );
              setFormState({
                ...formState,
                selectedRegistrant: null,
                assignedRegistrants: !assignedRegPerson
                  ? [
                      ...formState.assignedRegistrants,
                      formState.selectedRegistrant
                    ]
                  : formState.assignedRegistrants
              });
            }
            else if (e.destination.droppableId === 'availableDroppable') {
              setFormState({
                ...formState,
                selectedRegistrant: null,
                assignedRegistrants: formState.assignedRegistrants.filter(
                  (r) =>
                    r.regPerson.pkRegPerson !==
                    formState.selectedRegistrant.regPerson.pkRegPerson
                )
              });
            }
          }
        }}
      >
        <AppFlexbox
          style={{
            gap: rs([10, 10, 20], mqIndex),
            alignItems: 'stretch',
            display: rs(['block', 'block', 'flex'], mqIndex)
          }}
        >
          <AppStack
            style={{
              flex: 1,
              height: rs([325, 400], mqIndex),
              gap: 4
            }}
          >
            <AppText style={{ fontSize: 14 }} weight={500}>
              Unassigned Registrants
            </AppText>
            <AppStack
              style={{
                flex: 1,
                maxHeight: rs([275, 350], mqIndex),
                border: 'solid 1px #ced4da'
              }}
            >
              <Droppable
                disabled={state.regAssociationDivisionRegistrants.loading}
                droppableId="availableDroppable"
              >
                {(dropProvided) => (
                  <div
                    {...dropProvided.droppableProps}
                    ref={dropProvided.innerRef}
                    style={{ height: '100%' }}
                  >
                    {isLoading ||
                    state.regAssociationDivisionRegistrants.loading ? (
                      <>
                        <AppStack style={{ borderBottom: 'solid 1px #ced4da' }}>
                          <LeagueTeamPersonListItem />
                        </AppStack>
                        <AppStack style={{ borderBottom: 'solid 1px #ced4da' }}>
                          <LeagueTeamPersonListItem />
                        </AppStack>
                        <AppStack style={{ borderBottom: 'solid 1px #ced4da' }}>
                          <LeagueTeamPersonListItem />
                        </AppStack>
                        <AppStack style={{ borderBottom: 'solid 1px #ced4da' }}>
                          <LeagueTeamPersonListItem />
                        </AppStack>
                      </>
                    ) : assignableRegistrants.length > 0 ? (
                      <AppStack
                        style={{
                          gap: 0,
                          height: '100%',
                          overflow: 'auto'
                        }}
                      >
                        {assignableRegistrants.map((r, index) => (
                          <Draggable
                            key={r.regPerson.pkRegPerson}
                            draggableId={r.regPerson.pkRegPerson.toString()}
                            index={index}
                            isDragDisabled={
                              state.regAssociationDivisionRegistrants.loading
                            }
                          >
                            {(dragProvided) => (
                              <div
                                ref={dragProvided.innerRef}
                                {...dragProvided.draggableProps}
                                {...dragProvided.dragHandleProps}
                              >
                                <AppStack
                                  className={classes['hover-text-ListItem']}
                                  onMouseDown={() => {
                                    setFormState({
                                      ...formState,
                                      selectedRegistrant: r
                                    });
                                  }}
                                  onTouchStart={() => {
                                    setFormState({
                                      ...formState,
                                      selectedRegistrant: r
                                    });
                                  }}
                                  style={{
                                    backgroundColor: '#FFF',
                                    cursor: 'pointer',
                                    border:
                                      formState.selectedRegistrant?.regPerson
                                        .pkRegPerson === r.regPerson.pkRegPerson
                                        ? '1px solid #ced4da'
                                        : 'none',
                                    borderBottom: '1px solid #ced4da'
                                  }}
                                >
                                  <LeagueTeamPersonListItem
                                    regAssociationDivisionTeam={
                                      regAssociationDivisionTeam
                                    }
                                    regPerson={r.regPerson}
                                    roles={r.regFormSubmissionRoleAnswers}
                                  />
                                </AppStack>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </AppStack>
                    ) : (
                      <AppStack style={{ padding: 20 }}>
                        <AppText style={{ textAlign: 'center' }}>
                          No Unassigned Registrants
                        </AppText>
                      </AppStack>
                    )}
                    {dropProvided.placeholder}
                  </div>
                )}
              </Droppable>
            </AppStack>
          </AppStack>
          <AppStack
            style={{
              alignSelf: 'center',
              display: rs(['none', 'none', 'flex'], mqIndex)
            }}
          >
            <ArrowsHorizontal color="black" size={35} />
          </AppStack>
          <AppStack
            style={{
              alignItems: 'center',
              display: rs(['flex', 'flex', 'none'], mqIndex)
            }}
          >
            <ArrowsVertical color="black" size={35} />
          </AppStack>
          <AppStack
            style={{
              flex: 1,
              height: rs([325, 400], mqIndex),
              marginTop: rs([15, 15, 0], mqIndex),
              gap: 4
            }}
          >
            <AppText style={{ fontSize: 14 }} weight={500}>
              Assigned Registrants
            </AppText>
            <AppStack
              style={{
                flex: 1,
                maxHeight: rs([275, 350], mqIndex),
                border: 'solid 1px #ced4da'
              }}
            >
              <Droppable droppableId="assignedDroppable">
                {(dropProvided) => (
                  <div
                    {...dropProvided.droppableProps}
                    ref={dropProvided.innerRef}
                    style={{ height: '100%' }}
                  >
                    {formState.assignedRegistrants.length > 0 ? (
                      <AppStack
                        style={{
                          gap: 0,
                          height: '100%',
                          overflow: 'auto'
                        }}
                      >
                        {formState.assignedRegistrants.map((r, index) => (
                          <Draggable
                            key={r.regPerson.pkRegPerson}
                            draggableId={r.regPerson.pkRegPerson.toString()}
                            index={index}
                            isDragDisabled={
                              state.regAssociationDivisionRegistrants.loading
                            }
                          >
                            {(dragProvided) => (
                              <div
                                ref={dragProvided.innerRef}
                                {...dragProvided.draggableProps}
                                {...dragProvided.dragHandleProps}
                              >
                                <AppStack
                                  className={classes['hover-text-ListItem']}
                                  onMouseDown={() => {
                                    setFormState({
                                      ...formState,
                                      selectedRegistrant: r
                                    });
                                  }}
                                  onTouchStart={() => {
                                    setFormState({
                                      ...formState,
                                      selectedRegistrant: r
                                    });
                                  }}
                                  style={{
                                    backgroundColor: '#FFF',
                                    cursor: 'pointer',
                                    border:
                                      formState.selectedRegistrant?.regPerson
                                        .pkRegPerson === r.regPerson.pkRegPerson
                                        ? '1px solid #ced4da'
                                        : 'none',
                                    borderBottom: '1px solid #ced4da'
                                  }}
                                >
                                  <LeagueTeamPersonListItem
                                    regAssociationDivisionTeam={
                                      regAssociationDivisionTeam
                                    }
                                    regPerson={r.regPerson}
                                    roles={
                                      r.role
                                        ? [{ role: r.role }]
                                        : r.regFormSubmissionRoleAnswers
                                    }
                                  />
                                </AppStack>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </AppStack>
                    ) : (
                      <AppStack style={{ padding: 20 }}>
                        <AppText style={{ textAlign: 'center' }}>
                          No Assigned Registrants
                        </AppText>
                      </AppStack>
                    )}
                    {dropProvided.placeholder}
                  </div>
                )}
              </Droppable>
            </AppStack>
          </AppStack>
        </AppFlexbox>
      </DragDropContext>

      {specialRequestErrors.length > 0 && (
        <Alert
          color="red"
          icon={<AlertCircle size={24} />}
          radius="xs"
          style={{ flex: 1 }}
          title={<AppTitle order={5}>Warning</AppTitle>}
          variant="filled"
        >
          <AppStack style={{ gap: 10 }}>
            <SpecialRequestWarnings errors={specialRequestErrors} />
          </AppStack>
        </Alert>
      )}

      {regAssociationDivisionTeam && (
        <Button
          color="red"
          disabled={formState.isLoading}
          onClick={onDelete}
          style={{
            width: '100%',
            maxWidth: 250,
            alignSelf: 'center'
          }}
        >
          Delete Team
        </Button>
      )}
    </FormSection>
  );
};

EditTeam.propTypes = {
  availableRegistrants: PropTypes.array,
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onSaveChanges: PropTypes.func,
  regAssociationDivision: PropTypes.object,
  regAssociationDivisionTeam: PropTypes.object,
  showDelete: PropTypes.bool
};

export default EditTeam;
