import React from 'react';
import PropTypes from 'prop-types';
import { Accordion } from '@mantine/core';
import { ChevronLeft, ChevronRight } from 'tabler-icons-react';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import ActionableIcon from '../../common/ActionableIcon';

const UserSettingView = ({
  title,
  description,
  settings,
  onSelectSetting,
  onBack
}) => (
  <AppStack style={{ gap: 0 }}>
    {onBack && (
      <ActionableIcon
        color="dark"
        hiddenFrom="md"
        onClick={onBack}
        style={{ marginBottom: 5 }}
      >
        <ChevronLeft size={30} />
      </ActionableIcon>
    )}
    <AppText style={{ fontSize: 18 }} weight={700}>
      {title}
    </AppText>
    <AppText style={{ fontSize: 14, color: 'grey' }}>{description}</AppText>

    <AppStack style={{ marginTop: 10 }}>
      <Accordion
        chevron={<ChevronRight />}
        defaultValue=""
        disableChevronRotation
        variant="contained"
      >
        {settings.map((setting) => (
          <Accordion.Item
            key={setting.value}
            onClick={() => onSelectSetting(setting)}
            value={setting.value}
          >
            <Accordion.Control>
              <AppStack style={{ gap: 0 }}>
                <AppText weight={500}>{setting.label}</AppText>
                {setting.description &&
                typeof setting.description === 'string' ? (
                  <AppText style={{ fontSize: 14, color: 'grey' }} weight={400}>
                    {setting.description}
                  </AppText>
                ) : (
                  setting.description
                )}
              </AppStack>
            </Accordion.Control>
          </Accordion.Item>
        ))}
      </Accordion>
    </AppStack>
  </AppStack>
);

UserSettingView.propTypes = {
  settings: PropTypes.array.isRequired,
  description: PropTypes.string,
  onBack: PropTypes.func,
  onSelectSetting: PropTypes.func,
  title: PropTypes.string
};

export default UserSettingView;
