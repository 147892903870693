import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import PaginationList from '../../common/PaginationList';
import AppCardList from '../../common/AppCardList';
import HeaderView from '../../common/HeaderView';
import InviteListItem from './InviteListItem';
import ExpireInviteModal from './ExpireInviteModal';

const InviteList = () => {
  const navigate = useNavigate();
  const { state } = useContext(RegistrationContext);
  const [pageIndex, setPageIndex] = useState(1);
  const [inviteModal, setInviteModal] = useState({
    isOpen: false,
    invite: null,
    isExternalInvite: false,
    showDelete: false
  });
  const isLoading =
    state.regAssociationDivisionFormInvites.loading ||
    state.regAssociationDivisionFormExternalInvites.loading;

  const divisionFormInvites =
    state.regAssociationDivisionFormInvites.value.map((i) => ({
      pkInvite: i.pkRegAssociationDivisionFormInvite,
      label: `${i.regFormSubmission.regPerson.firstName} ${i.regFormSubmission.regPerson.lastName}`,
      subLabel: dayjs(i.regFormSubmission.regPerson.dob).format('MM/DD/YYYY'),
      description: i.regAssociationDivisionForm.regAssociationDivision.name,
      subDescription: i.regAssociationDivisionForm.regForm.name,
      inviteCode: i.inviteCode,
      accepted: i.accepted,
      deleted: i.deleted,
      expiresAt: i.expiresAt,
      isExternalInvite: false,
      acceptUrl: `/checkout/invite?invite=${i.pkRegAssociationDivisionFormInvite}`
    })) ?? [];

  const divisionFormExternalInvites =
    state.regAssociationDivisionFormExternalInvites.value.map((i) => ({
      pkInvite: i.pkRegAssociationDivisionFormExternalInvite,
      label: `${i.regPerson?.firstName ?? i.participantFirstName} ${
        i.regPerson?.lastName ?? i.participantLastName
      }`,
      subLabel: i.dateOfBirth
        ? dayjs(i.dateOfBirth).format('MM/DD/YYYY')
        : null,
      description: i.regAssociationDivisionForm.regAssociationDivision.name,
      subDescription: i.regAssociationDivisionForm.regForm.name,
      inviteCode: i.inviteCode,
      accepted: i.accepted,
      deleted: i.deleted,
      expiresAt: i.expiresAt,
      isExternalInvite: true,
      acceptUrl: `/checkout/invite?code=${i.inviteCode}`
    })) ?? [];

  const invites = [
    ...divisionFormInvites,
    ...divisionFormExternalInvites
  ].sort((a, b) => a.label.localeCompare(b.label));

  useEffect(() => {
    setInviteModal({
      isOpen: false,
      invite: null,
      isExternalInvite: false,
      showDelete: false
    });
  }, []);

  const onInviteAction = (action, invite) => {
    if (action === 'ACCEPT') {
      navigate(invite.acceptUrl);
    }
    else {
      setInviteModal({
        isOpen: true,
        invite,
        isExternalInvite: true,
        showDelete: action === 'EXPIRE'
      });
    }
  };

  return (
    <HeaderView maxViewWidth={1000} viewProps={{ style: { margin: 20 } }}>
      <AppCardList title="My Invites">
        <PaginationList
          emptyMessage="No invites available"
          isLoading={isLoading}
          items={invites.map((i) => (
            <InviteListItem
              key={i.pkInvite}
              onAction={(action) => onInviteAction(action, i)}
              showStatus
              {...i}
            />
          ))}
          itemsPerPage={10}
          LoadingComponent={InviteListItem}
          onPageChange={setPageIndex}
          pageIndex={pageIndex}
        />
      </AppCardList>

      <ExpireInviteModal
        invite={inviteModal.invite}
        isOpen={inviteModal.isOpen}
        onClose={() =>
          setInviteModal({
            ...inviteModal,
            isOpen: false
          })
        }
      />
    </HeaderView>
  );
};

export default InviteList;
