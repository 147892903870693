import React from 'react';
import { Loader, Modal } from '@mantine/core';
import PropTypes from 'prop-types';
import { useMediaQueryIndex } from '../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../helpers/styles';
import AppFlexbox from './AppFlexbox';
import AppTitle from './AppTitle';

const ResponsiveModal = ({
  isOpen,
  onClose,
  title,
  size,
  isLoading,
  children,
  sidePadding,
  noCloseButton,
  ...rest
}) => {
  const mqIndex = useMediaQueryIndex();
  const modalSidePadding = sidePadding ?? 40;
  return (
    <Modal.Root
      centered
      onClose={onClose}
      opened={isOpen}
      styles={{
        header: {
          padding: rs(
            [
              '20px 20px 0px 20px',
              `20px 30px 0px 30px`,
              `20px ${modalSidePadding}px 0px ${modalSidePadding}px`
            ],
            mqIndex
          )
        },
        title: { flex: 1, padding: 0 },
        inner: { padding: 0 },
        body: {
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: rs(
            [
              20,
              `20px 30px 40px 30px`,
              `20px ${modalSidePadding}px 40px ${modalSidePadding}px`
            ],
            mqIndex
          )
        },
        root: { flex: 1 },
        content: {
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          maxWidth: rs(['100%', '100%', size ?? 600], mqIndex),
          minHeight: rs(['100%', '100%', 'unset'], mqIndex)
        }
      }}
      withinPortal
      {...rest}
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          {typeof title === 'string' ? (
            <AppTitle order={2}>{title}</AppTitle>
          ) : (
            title
          )}

          {!noCloseButton && <Modal.CloseButton />}
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <AppFlexbox style={{ padding: 40, margin: 'auto' }}>
              <Loader color="dark" size="xl" />
            </AppFlexbox>
          ) : (
            children
          )}
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};

ResponsiveModal.propTypes = {
  children: PropTypes.any,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  noCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
  sidePadding: PropTypes.number,
  size: PropTypes.number,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default ResponsiveModal;
