import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Checkbox, Radio } from '@mantine/core';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import AppFlexbox from '../../common/AppFlexbox';
import PlayerSkillsCard from './PlayerSkillsCard';
import { useRegAssociationBranding } from '../../../helpers/styles';
import PlayerStatsModal from './PlayerStatsModal';
import { useMediaQueryIndex, useModalState } from '../../../helpers/hooks';

const accordionValue = 'top-players-filter';

const PlayerStatisticsChart = ({
  regAssociation,
  ratedEvaluations,
  regScoutReportEvaluationSession,
  regAssociationDivisionPlayers
}) => {
  const mqIndex = useMediaQueryIndex();
  const isMobileView = mqIndex === 0;
  const isTabletView = mqIndex <= 1;
  const isMediumView = mqIndex <= 2;
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const [chartState, setChartState] = useState({
    showFilters: false,
    ranking: 'strong',
    selectedMetrics: []
  });
  const maxPlayers = isMediumView ? 3 : 4;

  const {
    primaryColor,
    secondaryColor,
    isPrimaryDarkText,
    isSecondaryDarkText
  } = useRegAssociationBranding(regAssociation);

  const metricOptions = ratedEvaluations.reduce((r, c) => {
    c.answerRatings.forEach((a) => {
      if (!r.includes(a.label)) {
        r.push(a.label);
      }
    });
    return r;
  }, []);
  const allMetricsSelected = metricOptions.every((e) =>
    chartState.selectedMetrics.includes(e)
  );

  const filteredEvaluationRatings = ratedEvaluations
    .map((e) => {
      const filteredAnswers =
        chartState.selectedMetrics.length === 0
          ? e.answerRatings
          : e.answerRatings.filter((a) =>
              chartState.selectedMetrics.includes(a.label)
            );

      return {
        ...e,
        answerRatings: filteredAnswers,
        overallRating:
          Math.round(
            (filteredAnswers.reduce((r, c) => r + c.value, 0) /
              filteredAnswers.length) *
              10
          ) / 10
      };
    })
    .filter((e) =>
      chartState.selectedMetrics.every((m) =>
        e.answerRatings.map((a) => a.label).includes(m)
      )
    );

  return (
    <AppCard shadow="sm" style={{ padding: 0 }} withBorder>
      <AppStack style={{ gap: 0 }}>
        <Accordion
          onChange={() => {
            setChartState({
              ...chartState,
              showFilters: !chartState.showFilters
            });
          }}
          styles={{
            control: { padding: 0 },
            label: { padding: 0 },
            content: { padding: 0 },
            chevron: { display: 'none' }
          }}
          value={chartState.showFilters ? accordionValue : ''}
        >
          <Accordion.Item value={accordionValue}>
            <Accordion.Control>
              <AppFlexbox style={{ padding: 10 }}>
                <AppText weight={500}>
                  {chartState.ranking === 'weak' ? 'Weakest' : 'Strongest'}{' '}
                  Players
                </AppText>
              </AppFlexbox>
            </Accordion.Control>

            <Accordion.Panel>
              <AppStack
                style={{
                  padding: '10px 15px',
                  borderTop: 'solid 1px #dee2e6'
                }}
              >
                <AppFlexbox style={{ flex: 1 }}>
                  <AppStack style={{ flex: 1, gap: 5 }}>
                    <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                      Filters:
                    </AppText>
                    <Radio.Group
                      onChange={(v) =>
                        setChartState({
                          ...chartState,
                          ranking: v
                        })
                      }
                      value={chartState.ranking}
                    >
                      <AppFlexbox>
                        <Radio label="Strongest Players" value="strong" />
                        <Radio label="Weakest Players" value="weak" />
                      </AppFlexbox>
                    </Radio.Group>
                  </AppStack>

                  <AppStack style={{ flex: 1, gap: 5 }}>
                    {metricOptions.length === 0 ? (
                      <AppText>No metrics available</AppText>
                    ) : (
                      <>
                        <AppFlexbox style={{ justifyContent: 'space-between' }}>
                          <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                            Metrics:
                          </AppText>

                          <Checkbox
                            checked={allMetricsSelected}
                            label={
                              allMetricsSelected ? 'Unselect All' : 'Select All'
                            }
                            onChange={() =>
                              setChartState({
                                ...chartState,
                                selectedMetrics: allMetricsSelected
                                  ? []
                                  : metricOptions
                              })
                            }
                          />
                        </AppFlexbox>

                        <AppFlexbox style={{ flexWrap: 'wrap' }}>
                          {metricOptions
                            .sort((a, b) => a.localeCompare(b))
                            .map((m) => {
                              const isSelected = chartState.selectedMetrics.includes(
                                m
                              );
                              return (
                                <Checkbox
                                  key={m}
                                  checked={isSelected}
                                  label={m}
                                  onChange={() => {
                                    setChartState({
                                      ...chartState,
                                      selectedMetrics: isSelected
                                        ? chartState.selectedMetrics.filter(
                                            (x) => x !== m
                                          )
                                        : [...chartState.selectedMetrics, m]
                                    });
                                  }}
                                />
                              );
                            })}
                        </AppFlexbox>
                      </>
                    )}
                  </AppStack>
                </AppFlexbox>
              </AppStack>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>

        <AppFlexbox
          style={{
            padding: 15,
            flex: 1,
            flexDirection: isMobileView ? 'column' : 'row',
            overflow: 'auto'
          }}
        >
          {filteredEvaluationRatings
            .sort((a, b) =>
              chartState.ranking === 'weak'
                ? a.overallRating - b.overallRating
                : b.overallRating - a.overallRating
            )
            .slice(0, maxPlayers)
            .map((e) => (
              <AppFlexbox
                key={e.regScoutReportEvaluation.pkRegScoutReportEvaluation}
                style={{ margin: 'auto' }}
              >
                <PlayerSkillsCard
                  actions={[
                    {
                      label: 'View',
                      value: 'view',
                      onClick: () => {
                        const player = regAssociationDivisionPlayers.find(
                          (p) =>
                            p.person.pkRegPerson ===
                              e.regScoutReportEvaluation.person.pkRegPerson &&
                            p.person.pkRegScoutReportPerson ===
                              e.regScoutReportEvaluation.person
                                .pkRegScoutReportPerson
                        );
                        onOpenModal('view', player);
                      }
                    }
                  ]}
                  isPrimaryDarkText={isPrimaryDarkText}
                  isSecondaryDarkText={isSecondaryDarkText}
                  primaryColor={primaryColor}
                  ratedEvaluation={e}
                  secondaryColor={secondaryColor}
                />
              </AppFlexbox>
            ))}
        </AppFlexbox>
      </AppStack>

      <PlayerStatsModal
        isOpen={modalState.isOpen}
        onClose={onCloseModal}
        player={modalState.item}
        regAssociation={regAssociation}
        regScoutReportEvaluationSessions={[regScoutReportEvaluationSession]}
      />
    </AppCard>
  );
};

PlayerStatisticsChart.propTypes = {
  ratedEvaluations: PropTypes.array,
  regAssociation: PropTypes.object,
  regAssociationDivisionPlayers: PropTypes.array,
  regScoutReportEvaluationSession: PropTypes.object
};

export default PlayerStatisticsChart;
