import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { ActionIcon, Button, Menu, Pagination, Table } from '@mantine/core';
import { DotsVertical, Download } from 'tabler-icons-react';
import dayjs from 'dayjs';
import Papa from 'papaparse';
import FormSection from '../../common/FormSection';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppTitle from '../../common/AppTitle';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import {
  getEvaluationEntryProgress,
  getEvaluationRatingForSessions
} from '../../../helpers/evaluationPlayerHelper';
import { REG_SCOUT_REPORT_QUESTION_TYPE } from '../../../config/constants';
import AppFlexbox from '../../common/AppFlexbox';
import MenuContent from '../../common/MenuContent';
import { useMediaQueryIndex, useModalState } from '../../../helpers/hooks';
import PlayerStatsModal from '../players/PlayerStatsModal';

const VIEW_ACTIONS_ENUM = { view: 'VIEW' };

const TeamDetailsModal = ({
  isOpen,
  onClose,
  regAssociationDivisionTeam,
  regAssociation,
  hidePlayerNames
}) => {
  const mqIndex = useMediaQueryIndex();
  const isMobileOrSmaller = mqIndex === 0;
  const { state } = useContext(RegistrationAdminContext);
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const [filterState, setFilterState] = useState({ pageIndex: 1 });
  const maxPerPage = 5;

  const divisionEvaluationSessions = state.regScoutReportEvaluationSessions.value.filter(
    (s) =>
      s.regAssociationDivision.pkRegAssociationDivision ===
        regAssociationDivisionTeam?.regAssociationDivision
          .pkRegAssociationDivision &&
      s?.regScoutReportEvaluationSessionEntries?.some(
        (e) => getEvaluationEntryProgress(e, s) >= 100
      )
  );
  const evaluations = divisionEvaluationSessions.reduce(
    (r, c) => [...r, ...c.regScoutReportEvaluations],
    []
  );

  const evaluationTemplates = divisionEvaluationSessions.reduce(
    (r, c) => [...r, ...c.regScoutReportEvaluationSessionTemplates],
    []
  );
  const evaluationAttributeQuestions = evaluationTemplates.reduce(
    (r, c) => [
      ...r,
      ...c.regScoutReport.regScoutReportQuestions.filter(
        (q) =>
          q.fkRegScoutReportQuestionType ===
          REG_SCOUT_REPORT_QUESTION_TYPE.ATTRIBUTE
      )
    ],
    []
  );

  const teamPlayers =
    regAssociationDivisionTeam?.regAssociationDivisionTeamPersons.filter(
      (p) => p.active
    ) || [];

  useEffect(() => {
    if (isOpen) {
      setFilterState({ pageIndex: 1 });
    }
  }, [isOpen]);

  const onDownloadCsv = () => {
    const csv = Papa.unparse({
      fields: [
        'First Name',
        'Last Name',
        'Gender',
        'Overall Rating',
        ...evaluationAttributeQuestions.map((q) => q.value)
      ],
      data: teamPlayers.map((p) => {
        const overallRating =
          getEvaluationRatingForSessions(
            {
              pkRegAssociationDivision:
                regAssociationDivisionTeam.regAssociationDivision
                  .pkRegAssociationDivision,
              pkRegPerson: p.regPerson.pkRegPerson,
              pkRegScoutReportPerson: null
            },
            state.regScoutReportEvaluationSessions.value
          ).overallRating || 0;
        return [
          p.regPerson.firstName,
          p.regPerson.lastName,
          p.regPerson.gender,
          overallRating,
          evaluationAttributeQuestions.map(
            (q) =>
              evaluations
                .find(
                  (e) =>
                    e.person.pkRegPerson === p.regPerson.pkRegPerson &&
                    e.regScoutReportQuestionAnswers.some(
                      (a) =>
                        a.fkRegScoutReportQuestion ===
                        q.pkRegScoutReportQuestion
                    )
                )
                ?.regScoutReportQuestionAnswers.find(
                  (a) =>
                    a.fkRegScoutReportQuestion === q.pkRegScoutReportQuestion
                )?.value || ''
          )
        ];
      })
    });
    const blob = new Blob([csv]);
    const csvURL = URL.createObjectURL(blob, { type: 'text/plain' });
    const link = document.createElement('a');
    link.setAttribute('href', csvURL);
    link.setAttribute(
      'download',
      `${regAssociationDivisionTeam.regAssociationDivision.name}-${
        regAssociationDivisionTeam.name
      }-${dayjs(new Date()).format('MM/DD/YYYY_h:mma')}.csv`
    );
    document.body.appendChild(link);
    link.click();
  };

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClose}
      size={800}
      title={<AppTitle order={2}>{regAssociationDivisionTeam?.name}</AppTitle>}
    >
      {regAssociationDivisionTeam && (
        <FormSection cancelTitle="Close" isSubmitHidden onCancel={onClose}>
          {teamPlayers.length === 0 ? (
            <AppStack
              style={{
                padding: '40px 20px',
                justifyContent: 'center',
                textAlign: 'center',
                border: 'solid 1px lightgrey'
              }}
            >
              <AppText weight={500}>No team members added.</AppText>
            </AppStack>
          ) : (
            <AppStack style={{ gap: 20 }}>
              <AppStack
                style={{ overflow: 'auto', textWrap: 'nowrap', flex: 1 }}
              >
                <Table
                  striped
                  style={{ borderTop: 'none' }}
                  withRowBorders
                  withTableBorder
                >
                  <Table.Thead
                    style={{
                      position: 'sticky',
                      top: 0,
                      backgroundColor: '#FFF',
                      zIndex: 99,
                      boxShadow: 'inset 0 1px 0 #DEE2E6, inset 0 -1px 0 #DEE2E6'
                    }}
                  >
                    <Table.Tr style={{ border: 'none' }}>
                      <Table.Th>
                        <AppText weight={500}>Name</AppText>
                      </Table.Th>
                      <Table.Th>
                        <AppText weight={500}>Overall Rating</AppText>
                      </Table.Th>
                      <Table.Th>
                        <AppText weight={500}>Gender</AppText>
                      </Table.Th>
                      {evaluationAttributeQuestions.map((q) => (
                        <Table.Th key={q.pkRegScoutReportQuestion}>
                          <AppText weight={500}>{q.value}</AppText>
                        </Table.Th>
                      ))}
                      <Table.Th />
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {teamPlayers
                      .sort((a, b) =>
                        `${a.regPerson.firstName}${a.regPerson.lastName}`.localeCompare(
                          `${b.regPerson.firstName}${b.regPerson.lastName}`
                        )
                      )
                      .slice(
                        -maxPerPage + filterState.pageIndex * maxPerPage,
                        filterState.pageIndex * maxPerPage
                      )
                      .map((r) => {
                        const overallRating =
                          getEvaluationRatingForSessions(
                            {
                              pkRegAssociationDivision:
                                regAssociationDivisionTeam
                                  .regAssociationDivision
                                  .pkRegAssociationDivision,
                              pkRegPerson: r.regPerson.pkRegPerson,
                              pkRegScoutReportPerson: null
                            },
                            state.regScoutReportEvaluationSessions.value
                          ).overallRating || 0;

                        return (
                          <Table.Tr key={r.pkRegAssociationDivisionTeamPerson}>
                            <Table.Td>
                              <AppStack style={{ gap: 0, flex: 1 }}>
                                {!hidePlayerNames && (
                                  <AppText
                                    style={{ textWrap: 'nowrap' }}
                                    weight={500}
                                  >
                                    {r.regPerson.firstName}{' '}
                                    {r.regPerson.lastName}
                                  </AppText>
                                )}
                                <AppText
                                  style={{ color: '#767678', fontSize: 14 }}
                                  weight={500}
                                >
                                  {r.role}
                                </AppText>
                              </AppStack>
                            </Table.Td>
                            <Table.Td>
                              <AppText
                                style={{ textWrap: 'nowrap' }}
                                weight={500}
                              >
                                {Math.round(overallRating * 10) / 10}
                              </AppText>
                            </Table.Td>
                            <Table.Td>
                              <AppText
                                style={{ textWrap: 'nowrap' }}
                                weight={500}
                              >
                                {r.regPerson.gender}
                              </AppText>
                            </Table.Td>
                            {evaluationAttributeQuestions.map((q) => (
                              <Table.Td key={q.pkRegScoutReportQuestion}>
                                <AppText
                                  style={{ textWrap: 'nowrap' }}
                                  weight={500}
                                >
                                  {evaluations
                                    .find(
                                      (e) =>
                                        e.person.pkRegPerson ===
                                          r.regPerson.pkRegPerson &&
                                        e.regScoutReportQuestionAnswers.some(
                                          (a) =>
                                            a.fkRegScoutReportQuestion ===
                                            q.pkRegScoutReportQuestion
                                        )
                                    )
                                    ?.regScoutReportQuestionAnswers.find(
                                      (a) =>
                                        a.fkRegScoutReportQuestion ===
                                        q.pkRegScoutReportQuestion
                                    )?.value || ''}
                                </AppText>
                              </Table.Td>
                            ))}
                            <Table.Td>
                              <AppFlexbox
                                style={{ flex: 1, justifyContent: 'end' }}
                              >
                                <MenuContent
                                  control={
                                    <ActionIcon color="#000" variant="subtle">
                                      <DotsVertical />
                                    </ActionIcon>
                                  }
                                >
                                  <Menu.Item
                                    onClick={(e) => {
                                      e.preventDefault();
                                      onOpenModal(VIEW_ACTIONS_ENUM.view, r);
                                    }}
                                  >
                                    View
                                  </Menu.Item>
                                </MenuContent>
                              </AppFlexbox>
                            </Table.Td>
                          </Table.Tr>
                        );
                      })}
                  </Table.Tbody>
                </Table>
              </AppStack>

              {teamPlayers.length > 0 && (
                <AppFlexbox
                  style={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: isMobileOrSmaller ? 'column' : 'row'
                  }}
                >
                  {teamPlayers.length > maxPerPage && (
                    <Pagination
                      color="blue"
                      onChange={(pageIndex) =>
                        setFilterState({
                          ...filterState,
                          pageIndex
                        })
                      }
                      total={Math.ceil(
                        regAssociationDivisionTeam
                          .regAssociationDivisionTeamPersons.length / maxPerPage
                      )}
                      value={filterState.pageIndex}
                    />
                  )}
                  <Button
                    onClick={onDownloadCsv}
                    rightSection={<Download />}
                    style={{
                      width: '100%',
                      maxWidth: isMobileOrSmaller ? '100%' : 200
                    }}
                    variant="outline"
                  >
                    Download CSV
                  </Button>
                </AppFlexbox>
              )}
            </AppStack>
          )}
        </FormSection>
      )}

      <PlayerStatsModal
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.view
        }
        onClose={onCloseModal}
        regAssociation={regAssociation}
        regAssociationDivisionTeam={regAssociationDivisionTeam}
        regAssociationDivisionTeamPerson={modalState.item}
        regScoutReportEvaluationSessions={divisionEvaluationSessions}
      />
    </ResponsiveModal>
  );
};

TeamDetailsModal.propTypes = {
  hidePlayerNames: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  regAssociation: PropTypes.object,
  regAssociationDivisionTeam: PropTypes.object
};

export default TeamDetailsModal;
