import React from 'react';
import { PropTypes } from 'prop-types';
import { Divider, Skeleton } from '@mantine/core';
import NumberFormat from 'react-number-format';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';

const RecordPaymentPriceItem = ({
  label,
  weight,
  value,
  valueLabel,
  currency,
  color,
  isLoading,
  isDividerHidden
}) => (
  <>
    <AppFlexbox style={{ flexWrap: 'nowrap', gap: 10, marginTop: 15 }}>
      <AppText style={{ fontSize: 16 }} weight={weight ?? 'normal'}>
        {label}
      </AppText>
      <Divider style={{ flex: 1 }} variant="dotted" />
      {isLoading ? (
        <Skeleton height={20} width={90} />
      ) : (
        <AppText style={{ fontSize: 16, color }} weight={weight}>
          <NumberFormat
            decimalScale={2}
            displayType="text"
            fixedDecimalScale
            prefix="$"
            thousandSeparator
            value={value}
          />
          {currency ? ` ${currency}` : ''}
          {valueLabel ? ` ${valueLabel}` : ''}
        </AppText>
      )}
    </AppFlexbox>
    {!isDividerHidden && <Divider />}
  </>
);

RecordPaymentPriceItem.propTypes = {
  color: PropTypes.string,
  currency: PropTypes.string,
  isDividerHidden: PropTypes.bool,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.number,
  valueLabel: PropTypes.string,
  weight: PropTypes.number
};

export default RecordPaymentPriceItem;
