import {
  DEFAULT_REPORT_CONFIGS,
  ECOM_REPORT_ENUM,
  REPORT_CATEGORY_ENUM,
  REPORT_HEADER_ENUM,
  REPORT_TYPE_ENUM
} from './reportsConfig';

const analytics = [
  {
    title: 'Transaction total',
    header: REPORT_HEADER_ENUM.TRANSACTION_TOTAL,
    reportUuid: '',
    type: 'line'
  },
  {
    title: 'Total registrations',
    header: REPORT_HEADER_ENUM.REGISTRATION_COUNT,
    reportUuid: '',
    type: 'line'
  },
  {
    title: 'Average cost',
    header: REPORT_HEADER_ENUM.AVERAGE_COST,
    reportUuid: '',
    type: 'line'
  },
  {
    title: 'Amount settled',
    header: REPORT_HEADER_ENUM.AMOUNT_SETTLED,
    reportUuid: '',
    type: 'line'
  },
  {
    title: 'Service fees',
    header: REPORT_HEADER_ENUM.PLATFORM_FEE,
    reportUuid: '',
    type: 'line'
  },
  {
    title: 'Net total',
    header: REPORT_HEADER_ENUM.NET_TOTAL,
    reportUuid: '',
    type: 'line'
  },
  {
    title: 'Amount refunded',
    header: REPORT_HEADER_ENUM.TOTAL_REFUNDED,
    reportUuid: '',
    type: 'line'
  },
  {
    title: 'Unpaid balance',
    header: REPORT_HEADER_ENUM.UNPAID_BALANCE,
    reportUuid: '',
    type: 'line'
  },
  {
    title: 'Registration age',
    header: REPORT_HEADER_ENUM.REGISTRATION_AGE,
    reportUuid: '',
    type: 'bar',
    dataKey: 'age',
    getOverviewData: (chartData, dataLabel, compareDataLabel) => ({
      total: chartData.reduce((r, c) => r + c[dataLabel], 0),
      compareTotal: chartData.reduce((r, c) => r + c[compareDataLabel], 0)
    }),
    getData: (reportResult, dataLabel, compareDataLabel) => {
      const { headers = [], data = [], compareData } =
        reportResult?.report || {};
      const dataHeaderIndex = headers.findIndex(
        (h) => h.field === REPORT_HEADER_ENUM.REGISTRATION_AGE
      );

      let chartData = [];
      if (dataHeaderIndex) {
        chartData = data
          .map((d) => d[dataHeaderIndex].split(','))
          .flat()
          .filter((d) => !!d)
          .reduce((r, c) => {
            const existingProduct = r.find((a) => a.age === c);
            if (existingProduct) {
              existingProduct[dataLabel] += 1;
            }
            else {
              r.push({
                age: c,
                [dataLabel]: 1
              });
            }
            return r;
          }, []);

        if (compareData) {
          chartData = compareData
            .map((d) => d[dataHeaderIndex].split(','))
            .flat()
            .filter((d) => !!d)
            .reduce((r, c) => {
              const existingProduct = r.find((a) => a.age === c);
              if (existingProduct) {
                existingProduct[compareDataLabel] =
                  (existingProduct[compareDataLabel] || 0) + 1;
              }
              else {
                r.push({
                  age: c,
                  [compareDataLabel]: 1
                });
              }
              return r;
            }, chartData);
        }
      }

      return chartData
        .sort(
          (a, b) =>
            b[dataLabel] - a[dataLabel] ||
            b[compareDataLabel] - a[compareDataLabel] ||
            a.age.localeCompare(b.age)
        )
        .map((m) => ({
          ...m,
          [dataLabel]: m[dataLabel] || 0,
          [compareDataLabel]: m[compareDataLabel] || 0
        }));
    }
  }
];

const reports = [
  {
    title: 'Analytics overview',
    uuid: ECOM_REPORT_ENUM.OVERVIEW,
    fkReportType: REPORT_TYPE_ENUM.SALES,
    fkReportCategory: REPORT_CATEGORY_ENUM.SALES,
    defaultHeaders: [
      REPORT_HEADER_ENUM.TRANSACTION_TOTAL,
      REPORT_HEADER_ENUM.REGISTRATION_COUNT,
      REPORT_HEADER_ENUM.NET_TOTAL,
      REPORT_HEADER_ENUM.AMOUNT_SETTLED,
      REPORT_HEADER_ENUM.UNPAID_BALANCE,
      REPORT_HEADER_ENUM.TOTAL_REFUNDED,
      REPORT_HEADER_ENUM.AVERAGE_COST,
      REPORT_HEADER_ENUM.PLATFORM_FEE,
      REPORT_HEADER_ENUM.REGISTRATION_AGE
    ],
    groupBy: 'period',
    compareEnabled: true,
    isHidden: true
  },
  ...DEFAULT_REPORT_CONFIGS
];

const regAssociationAnalyticsAndReportsConfig = {
  analytics,
  reports
};

export { regAssociationAnalyticsAndReportsConfig };
