import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, Alert } from '@mantine/core';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import { REG_SCOUT_REPORT_QUESTION_TYPE } from '../../../config/constants';
import GenerateTeamAttributeAssignInput from './GenerateTeamAttributeAssign';

const STATIC_ATTRIBUTES = [
  {
    key: 1,
    label: 'Gender',
    value: 'gender',
    getOptions: (regFormSubmissions) =>
      regFormSubmissions.reduce((r, c) => {
        if (!r.some((f) => f.value === c.regPerson.gender)) {
          r.push({ value: c.regPerson.gender });
        }
        return r;
      }, []),
    getAnswers: (regFormSubmissions) =>
      regFormSubmissions.map((e) => ({ value: e.regPerson.gender }))
  }
];

const GenerateTeamSettings = ({
  teams,
  availableRegistrants,
  regScoutReportEvaluationSessions,
  onUpdateTeam,
  selectedAttributes,
  onSelectAttribute,
  onOpenAttribute,
  openAttributeKeys,
  isGenerating
}) => {
  const sessionTemplates = regScoutReportEvaluationSessions.reduce(
    (r, c) => [...r, ...c.regScoutReportEvaluationSessionTemplates],
    []
  );
  const playerEvaluations = regScoutReportEvaluationSessions.reduce(
    (r, c) => [
      ...r,
      ...c.regScoutReportEvaluations.filter(
        (f) =>
          !f.deleted &&
          availableRegistrants.some(
            (s) => s.regPerson.pkRegPerson === f.person.pkRegPerson
          )
      )
    ],
    []
  );

  const playersWithoutEvaluation = availableRegistrants.filter(
    (r) =>
      !playerEvaluations.some(
        (e) => e.person.pkRegPerson === r.regPerson.pkRegPerson && !e.deleted
      )
  );

  const questions = sessionTemplates.reduce(
    (r, c) => [...r, ...c.regScoutReport.regScoutReportQuestions],
    []
  );
  const attributeQuestions = questions.filter(
    (q) =>
      q.fkRegScoutReportQuestionType ===
      REG_SCOUT_REPORT_QUESTION_TYPE.ATTRIBUTE
  );

  const attributeAnswers = playerEvaluations.reduce(
    (r, c) => [
      ...r,
      ...c.regScoutReportQuestionAnswers.filter(
        (f) =>
          f.regScoutReportQuestion?.fkRegScoutReportQuestionType ===
          REG_SCOUT_REPORT_QUESTION_TYPE.ATTRIBUTE
      )
    ],
    []
  );

  return (
    <AppStack style={{ gap: 30 }}>
      <Alert color="blue" variant="outline">
        <AppStack style={{ gap: 5 }}>
          <AppText weight={500}>Assign Attributes</AppText>
          <AppText>
            Assign the number of attributes for each player to be included in
            the team generation process. The minimum and maximum values can be
            adjusted to ensure the teams are balanced.
          </AppText>

          <AppText>
            Leaving the minimum and maximum values blank will balance the
            attributes evenly across all teams.
          </AppText>
        </AppStack>
      </Alert>
      {regScoutReportEvaluationSessions.length > 0 &&
        playersWithoutEvaluation.length > 0 && (
          <Alert color="yellow" variant="outline">
            <AppStack style={{ gap: 5 }}>
              <AppText weight={500}>Players without evaluations</AppText>
              <AppText>
                {playersWithoutEvaluation.length} players have not been
                evaluated. You will not be able to assign attributes for these
                players until they have been evaluated.
              </AppText>
              <AppText>
                They have been given a score of 1 to ensure they are balanced
                when including them in the team generation process.
              </AppText>
            </AppStack>
          </Alert>
        )}

      <AppStack style={{ gap: 5 }}>
        <AppText weight={500}>Teams</AppText>

        <Accordion disabled={isGenerating} multiple variant="contained">
          {teams
            .sort((a, b) => a.name.localeCompare(b.name) || a.key - b.key)
            .map((team) => (
              <Accordion.Item key={team.key} value={team.key.toString()}>
                <Accordion.Control>
                  <AppText style={{ fontWeight: 500 }}>{team.name}</AppText>
                </Accordion.Control>
                <Accordion.Panel>
                  <AppStack>
                    <AppText weight={500}>Player Attributes</AppText>

                    <Accordion
                      disabled={isGenerating}
                      multiple
                      onChange={onOpenAttribute}
                      value={openAttributeKeys}
                      variant="contained"
                    >
                      {STATIC_ATTRIBUTES.map((q) => (
                        <GenerateTeamAttributeAssignInput
                          key={q.value}
                          customQuestion={q.value}
                          disabled={isGenerating}
                          label={q.label}
                          onSelectAttribute={onSelectAttribute}
                          onUpdateTeam={onUpdateTeam}
                          options={q.getOptions(availableRegistrants)}
                          questionAnswers={q.getAnswers(availableRegistrants)}
                          selectedAttributes={selectedAttributes}
                          team={team}
                          teams={teams}
                        />
                      ))}

                      {attributeQuestions
                        .filter(
                          (f) => f.regScoutReportQuestionOptions.length > 0
                        )
                        .map((q) => (
                          <GenerateTeamAttributeAssignInput
                            key={q.pkRegScoutReportQuestion.toString()}
                            disabled={isGenerating}
                            fkRegScoutReportQuestion={q.pkRegScoutReportQuestion.toString()}
                            label={q.value}
                            onSelectAttribute={onSelectAttribute}
                            onUpdateTeam={onUpdateTeam}
                            options={q.regScoutReportQuestionOptions}
                            questionAnswers={attributeAnswers}
                            selectedAttributes={selectedAttributes}
                            team={team}
                            teams={teams}
                          />
                        ))}
                    </Accordion>
                  </AppStack>
                </Accordion.Panel>
              </Accordion.Item>
            ))}
        </Accordion>
      </AppStack>
    </AppStack>
  );
};

GenerateTeamSettings.propTypes = {
  availableRegistrants: PropTypes.array,
  isGenerating: PropTypes.bool,
  onOpenAttribute: PropTypes.func,
  onSelectAttribute: PropTypes.func,
  onUpdateTeam: PropTypes.func,
  openAttributeKeys: PropTypes.array,
  regScoutReportEvaluationSessions: PropTypes.array,
  selectedAttributes: PropTypes.array,
  teams: PropTypes.array
};

export default GenerateTeamSettings;
