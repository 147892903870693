import React, { useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Alert, Checkbox, MultiSelect, Radio } from '@mantine/core';
import { AlertCircle, InfoCircle } from 'tabler-icons-react';
import dayjs from 'dayjs';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { triggerNotification } from '../../../helpers/notificationHelper';
import FormSection from '../../common/FormSection';
import {
  OFFLINE_PAYMENT_TYPES,
  PAYMENT_PROVIDER_LIST
} from '../../../config/constants';
import { currencyFormat } from '../../../helpers/format';
import { isOfflinePayment } from '../../../helpers/paymentHelper';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppTitle from '../../common/AppTitle';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import AppRadioGroup from '../../common/AppRadioGroup';

const RegistrationInvoiceStatusChangeModal = ({
  isOpen,
  onClose,
  regAssociation,
  regFormSubmissionBalance,
  regFormSubmissionInvoice
}) => {
  const mqIndex = useMediaQueryIndex();
  const {
    updateAdminRegistrationInvoiceStatus,
    fetchUpdatedAdminFinancialRegistration
  } = useContext(RegistrationAdminContext);
  const [formState, setFormState] = useState({
    status: '',
    fkRegFormSubmissionPaymentType: '1',
    fkRegFormSubmissionPayments: [],
    loading: false
  });
  const isLoading = !regFormSubmissionBalance || !regFormSubmissionInvoice;
  const remainingInvoiceAmount = 5;
  const avaialbleRegFormSubmissionPayments =
    regFormSubmissionBalance?.regFormSubmissionPayments
      .filter((p) => isOfflinePayment(p) && p.amount <= remainingInvoiceAmount)
      .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)) ?? [];

  const paymentProvider = PAYMENT_PROVIDER_LIST.find(
    (p) =>
      p.value ===
      regAssociation?.regAssociationPaymentProvider?.fkPaymentProvider
  );

  useEffect(() => {
    if (isOpen) {
      setFormState({
        status: '',
        fkRegFormSubmissionPaymentType: '1',
        fkRegFormSubmissionPayments: [],
        loading: false
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      isLoading={isLoading}
      onClose={onClose}
      opened={isOpen}
      size={600}
      title={<AppTitle order={2}>Invoice User</AppTitle>}
    >
      {!isLoading && (
        <FormSection
          isLoading={formState.loading}
          isSubmitDisabled={!formState.invoiceStatus}
          onCancel={onClose}
          onSubmit={() => {
            if (
              formState.invoiceStatus === 'PAID' &&
              formState.invoicePaymentAlreadyRecorded &&
              formState.invoicePayments.length === 0
            ) {
              triggerNotification('Recorded invoice payments required');
            }
            else {
              setFormState({
                ...formState,
                loading: true
              });
              updateAdminRegistrationInvoiceStatus(
                regFormSubmissionInvoice.pkRegFormSubmissionInvoice,
                {
                  status: formState.invoiceStatus,
                  fkRegFormSubmissionPaymentType: !formState.invoicePaymentAlreadyRecorded
                    ? formState.fkRegFormSubmissionPaymentType
                    : null,
                  fkRegFormSubmissionPayments: formState.invoicePaymentAlreadyRecorded
                    ? formState.invoicePayments
                    : null
                },
                () => {
                  fetchUpdatedAdminFinancialRegistration(
                    regFormSubmissionBalance.pkRegFormSubmission
                  );
                  setFormState({
                    ...formState,
                    loading: false
                  });
                  triggerNotification(
                    'Invoice status updated',
                    'Success',
                    'green'
                  );
                  onClose();
                },
                (e) => {
                  triggerNotification(e);
                  setFormState({
                    ...formState,
                    loading: false
                  });
                }
              );
            }
          }}
          style={{ gap: 20 }}
          submitTitle="Update Status"
        >
          <AppStack style={{ gap: 10 }}>
            <AppText style={{ fontSize: 14 }} weight={500}>
              Mark invoice as...
            </AppText>
            <AppStack style={{ gap: 0 }}>
              <Checkbox
                checked={formState.invoiceStatus === 'PAID'}
                disabled={formState.loading}
                label="Paid"
                onChange={() =>
                  setFormState({
                    ...formState,
                    invoiceStatus: 'PAID',
                    invoicePaymentAlreadyRecorded: false,
                    invoicePayments: []
                  })
                }
                radius="xl"
                style={{ fontWeight: 500 }}
              />
              <AppText style={{ marginLeft: 32, fontSize: 14 }} weight={400}>
                Payment was collected outside of {paymentProvider.label}.
              </AppText>
            </AppStack>

            <AppStack style={{ gap: 0 }}>
              <Checkbox
                checked={formState.invoiceStatus === 'VOID'}
                disabled={formState.loading}
                label="Void"
                onChange={() =>
                  setFormState({
                    ...formState,
                    invoiceStatus: 'VOID',
                    invoicePaymentAlreadyRecorded: false,
                    invoicePayments: []
                  })
                }
                radius="xl"
                style={{ fontWeight: 500 }}
              />
              <AppText style={{ marginLeft: 32, fontSize: 14 }} weight={400}>
                This invoice was accidentally finalized or contains a mistake.
              </AppText>
            </AppStack>

            {regFormSubmissionInvoice.status !== 'UNCOLLECTIBLE' && (
              <AppStack style={{ gap: 0 }}>
                <Checkbox
                  checked={formState.invoiceStatus === 'UNCOLLECTIBLE'}
                  disabled={formState.loading}
                  label="Uncollectible"
                  onChange={() =>
                    setFormState({
                      ...formState,
                      invoiceStatus: 'UNCOLLECTIBLE',
                      invoicePaymentAlreadyRecorded: false,
                      invoicePayments: []
                    })
                  }
                  radius="xl"
                  style={{ fontWeight: 500 }}
                />
                <AppText style={{ marginLeft: 32, fontSize: 14 }} weight={400}>
                  Payment of this invoice is not expected. It is still possible
                  to collect payment should your customer attempt to pay.
                </AppText>
              </AppStack>
            )}
          </AppStack>
          {formState.invoiceStatus === 'PAID' && (
            <AppStack style={{ gap: 10 }}>
              {avaialbleRegFormSubmissionPayments.length > 0 && (
                <Checkbox
                  checked={formState.invoicePaymentAlreadyRecorded}
                  disabled={formState.loading}
                  label="Invoice Payment already recorded"
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      invoicePaymentAlreadyRecorded: e.currentTarget.checked
                    })
                  }
                  style={{ fontWeight: 500 }}
                />
              )}

              {formState.invoicePaymentAlreadyRecorded ? (
                <MultiSelect
                  data={avaialbleRegFormSubmissionPayments.map((p) => {
                    const offlinePayment = OFFLINE_PAYMENT_TYPES.find(
                      (t) =>
                        t.value === p.fkRegFormSubmissionPaymentType.toString()
                    );
                    return {
                      label: `${currencyFormat(p.amount)} - ${
                        offlinePayment.label
                      } - ${dayjs(p.createdAt).format('MM/DD/YYYY')}`,
                      value: p.pkRegFormSubmissionPayment.toString()
                    };
                  })}
                  disabled={formState.loading}
                  label="Payments"
                  onChange={(values) =>
                    setFormState({ ...formState, invoicePayments: values })
                  }
                  placeholder="Select invoice payments..."
                  required
                  searchable
                  value={formState.invoicePayments}
                />
              ) : (
                <AppRadioGroup
                  disabled={formState.loading}
                  label="How did they pay?"
                  onChange={(value) =>
                    setFormState({
                      ...formState,
                      fkRegFormSubmissionPaymentType: value
                    })
                  }
                  size="sm"
                  spacing="lg"
                  styles={{
                    input: { cursor: 'pointer !important' },
                    label: { cursor: 'pointer' },
                    root: {
                      alignItems: 'start',
                      flexDirection: rs(['column', 'row'], mqIndex)
                    }
                  }}
                  value={formState.fkRegFormSubmissionPaymentType}
                >
                  {OFFLINE_PAYMENT_TYPES.filter(
                    (p) =>
                      p.value === '1' ||
                      p.value === '2' ||
                      p.value === '3' ||
                      p.value === '4'
                  ).map((p) => (
                    <Radio
                      key={p.value}
                      disabled={formState.loading}
                      label={p.label}
                      value={p.value}
                    />
                  ))}
                </AppRadioGroup>
              )}
            </AppStack>
          )}

          {formState.fkRegFormSubmissionPaymentType === '9' && (
            <Alert
              color="yellow"
              icon={
                <InfoCircle style={{ width: 35, height: 35, marginTop: 3 }} />
              }
              variant="outline"
            >
              <AppText>
                This payment type is used to reduce the customer's outstanding
                balance without declaring any income. The amount is no longer
                required to be repaid.
              </AppText>
            </Alert>
          )}

          {formState.invoiceStatus && (
            <Alert
              color="yellow"
              icon={<AlertCircle />}
              styles={{ icon: { width: 28, height: 28 } }}
              variant="outline"
            >
              <AppText style={{ marginTop: 4, fontSize: 14 }} weight={500}>
                {formState.invoiceStatus === 'UNCOLLECTIBLE' ? (
                  <>
                    This invoice will no longer be open. After marking it as
                    uncollectible, you’ll only be able to change the status to
                    paid or void.
                  </>
                ) : (
                  <>You cannot undo this action.</>
                )}
              </AppText>
            </Alert>
          )}
        </FormSection>
      )}
    </ResponsiveModal>
  );
};

RegistrationInvoiceStatusChangeModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  regAssociation: PropTypes.object,
  regFormSubmissionBalance: PropTypes.object,
  regFormSubmissionInvoice: PropTypes.object
};

export default RegistrationInvoiceStatusChangeModal;
