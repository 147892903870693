import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Checkbox, Radio, TextInput } from '@mantine/core';
import { InfoCircle } from 'tabler-icons-react';
import NumberFormat from 'react-number-format';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import ResponsiveModal from '../../common/ResponsiveModal';
import FormSection from '../../common/FormSection';
import {
  CURRENCY_TYPE_LIST,
  PAYMENT_PROVIDERS
} from '../../../config/constants';
import { triggerNotification } from '../../../helpers/notificationHelper';
import AppTitle from '../../common/AppTitle';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import AppRadioGroup from '../../common/AppRadioGroup';

const FinancialPayeeOnboardingModal = ({ isOpen, onClose, regAssociation }) => {
  const mqIndex = useMediaQueryIndex();
  const { onboardPaymentProvider, fetchAdminRegAssociations } = useContext(
    RegistrationAdminContext
  );
  const [formState, setFormState] = useState({
    regAssociationPaymentProviderType: PAYMENT_PROVIDERS.stripe.value.toString(),
    accountId: '',
    accountPassword: '',
    useDefaultServiceFee: true,
    flatRateFee: null,
    percentageRateFee: null,
    rebasePaymentFees: false,
    alreadyOnboarded: false,
    showConfirmRebaseModal: false,
    showEmailMessage: false,
    isLoading: false
  });
  const alreadyOnboarded =
    formState.alreadyOnboarded ||
    formState.regAssociationPaymentProviderType ===
      PAYMENT_PROVIDERS.sportspay.value.toString();

  const sportspaySelected =
    formState.regAssociationPaymentProviderType ===
    PAYMENT_PROVIDERS.sportspay.value.toString();
  const currency = CURRENCY_TYPE_LIST.find(
    (c) =>
      c.value.toString() ===
      regAssociation?.regAssociationPaymentProvider?.fkCurrencyType?.toString()
  )?.abbr;

  useEffect(() => {
    if (isOpen && regAssociation) {
      const paymentProvider = regAssociation.regAssociationPaymentProvider;
      if (paymentProvider) {
        setFormState({
          regAssociationPaymentProviderType: regAssociation.regAssociationPaymentProvider.fkPaymentProvider.toString(),
          accountId: regAssociation.regAssociationPaymentProvider.accountId,
          flatRateFee: regAssociation.regAssociationPaymentProvider.flatRateFee,
          percentageRateFee:
            regAssociation.regAssociationPaymentProvider.percentageRateFee,
          useDefaultServiceFee:
            !regAssociation.regAssociationPaymentProvider.percentageRateFee &&
            !regAssociation.regAssociationPaymentProvider.flatRateFee,

          accountPassword: '',
          rebasePaymentFees: false,
          alreadyOnboarded: true,
          showConfirmRebaseModal: false,
          showEmailMessage: false,
          isLoading: false
        });
      }
      else {
        setFormState({
          regAssociationPaymentProviderType: PAYMENT_PROVIDERS.stripe.value.toString(),
          accountId: '',
          accountPassword: '',
          flatRateFee: null,
          percentageRateFee: null,
          useDefaultServiceFee: true,
          rebasePaymentFees: false,
          alreadyOnboarded: false,
          showConfirmRebaseModal: false,
          showEmailMessage: false,
          isLoading: false
        });
      }
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClose}
      title={
        <AppTitle order={2}>
          {!regAssociation?.regAssociationPaymentProvider
            ? 'Onboard Payment Provider'
            : 'Update Payment Provider'}
        </AppTitle>
      }
    >
      {formState.showEmailMessage ? (
        <FormSection cancelTitle="Close" isSubmitHidden onCancel={onClose}>
          <Alert
            icon={<InfoCircle />}
            style={{ marginTop: 20 }}
            variant="outline"
          >
            <AppText weight={500}>
              Please follow the onboarding instructions sent to your email
            </AppText>
          </Alert>
        </FormSection>
      ) : (
        <FormSection
          isLoading={formState.isLoading}
          onCancel={onClose}
          onSubmit={() => {
            setFormState({ ...formState, isLoading: true });
            onboardPaymentProvider(
              regAssociation.pkRegAssociation,
              {
                ...formState,
                flatRateFee: !formState.useDefaultServiceFee
                  ? formState.flatRateFee
                  : null,
                percentageRateFee: !formState.useDefaultServiceFee
                  ? formState.percentageRateFee
                  : null,
                rebasePaymentFees: formState.rebasePaymentFees
              },
              (session) => {
                if (session.sessionUrl) {
                  window.location.href = session.sessionUrl;
                }
                else if (session.emailSent) {
                  setFormState({
                    ...formState,
                    showEmailMessage: true,
                    isLoading: false
                  });
                }
                else {
                  triggerNotification(
                    'Payment provider onboarded',
                    'Success',
                    'green'
                  );
                  onClose();
                  fetchAdminRegAssociations();
                }
              },
              (e) => {
                triggerNotification(e);
                setFormState({ ...formState, isLoading: false });
              }
            );
          }}
          style={{ gap: 30 }}
          submitTitle={
            !regAssociation?.regAssociationPaymentProvider
              ? 'Onboard'
              : 'Continue'
          }
        >
          <AppStack style={{ gap: 15 }}>
            {!regAssociation?.regAssociationPaymentProvider && (
              <AppStack style={{ gap: 30 }}>
                <AppRadioGroup
                  label="Payment Provider"
                  onChange={(value) =>
                    setFormState({
                      ...formState,
                      regAssociationPaymentProviderType: value
                    })
                  }
                  required
                  size="sm"
                  spacing="lg"
                  styles={{
                    input: { cursor: 'pointer !important' },
                    label: { cursor: 'pointer' },
                    root: {
                      alignItems: 'start',
                      flexDirection: rs(['column', 'row'], mqIndex)
                    }
                  }}
                  value={formState.regAssociationPaymentProviderType}
                >
                  <Radio
                    disabled={formState.isLoading}
                    label="Stripe"
                    value={PAYMENT_PROVIDERS.stripe.value.toString()}
                  />
                  <Radio
                    disabled={formState.isLoading}
                    label="SportsPay"
                    value={PAYMENT_PROVIDERS.sportspay.value.toString()}
                  />
                </AppRadioGroup>
                <Checkbox
                  checked={alreadyOnboarded}
                  disabled={
                    formState.isLoading ||
                    formState.regAssociationPaymentProviderType ===
                      PAYMENT_PROVIDERS.sportspay.value.toString()
                  }
                  label="Already Onboarded"
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      alreadyOnboarded: e.currentTarget.checked
                    })
                  }
                />
              </AppStack>
            )}
            {alreadyOnboarded && (
              <>
                <TextInput
                  disabled={
                    formState.isLoading ||
                    !!regAssociation?.regAssociationPaymentProvider
                  }
                  label={
                    sportspaySelected ? 'SportsPay TERMID' : 'Stripe Payee'
                  }
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      accountId: e.currentTarget.value
                    })
                  }
                  required
                  value={formState.accountId}
                />
                {sportspaySelected && (
                  <>
                    <TextInput
                      disabled={formState.isLoading}
                      label="Password"
                      onChange={(e) =>
                        setFormState({
                          ...formState,
                          accountPassword: e.currentTarget.value
                        })
                      }
                      required
                      type="password"
                      value={formState.accountPassword}
                    />

                    <Alert variant="outline">
                      <AppStack>
                        <AppStack style={{ gap: 5 }}>
                          <AppText weight={500}>Service fees</AppText>
                          <AppText>
                            Until these values are properly set your reporting
                            may be inaccurate.
                          </AppText>
                        </AppStack>
                        <Checkbox
                          checked={formState.useDefaultServiceFee}
                          disabled={formState.isLoading}
                          label="Use the default service fee of 2.54%"
                          onChange={(e) =>
                            setFormState({
                              ...formState,
                              useDefaultServiceFee: e.currentTarget.checked
                            })
                          }
                          style={{ fontWeight: 500 }}
                        />
                        {!formState.useDefaultServiceFee && (
                          <>
                            <NumberFormat
                              allowNegative={false}
                              customInput={TextInput}
                              decimalScale={2}
                              disabled={formState.isLoading}
                              fixedDecimalScale
                              label="Flat Rate Fee"
                              onValueChange={(e) =>
                                setFormState({
                                  ...formState,
                                  flatRateFee: e.floatValue * 100
                                })
                              }
                              placeholder="$0.00"
                              prefix="$"
                              required
                              rightSection={
                                currency && (
                                  <AppText
                                    style={{
                                      fontSize: 14,
                                      fontWeight: 500,
                                      color: '#999',
                                      paddingRight: 5
                                    }}
                                  >
                                    {currency}
                                  </AppText>
                                )
                              }
                              style={{ flex: 1 }}
                              thousandSeparator
                              value={
                                formState.flatRateFee === null
                                  ? null
                                  : formState.flatRateFee / 100
                              }
                            />
                            <NumberFormat
                              allowNegative={false}
                              customInput={TextInput}
                              decimalScale={2}
                              disabled={formState.isLoading}
                              label="Percentage Rate Fee"
                              onValueChange={(e) =>
                                setFormState({
                                  ...formState,
                                  percentageRateFee: e.floatValue
                                })
                              }
                              placeholder="2.54%"
                              required
                              style={{ flex: 1 }}
                              suffix="%"
                              value={formState.percentageRateFee}
                            />
                          </>
                        )}

                        <Alert color="yellow" variant="outline">
                          <AppStack>
                            <AppText weight={500}>Rebase existing fees</AppText>
                            <AppText>
                              <Checkbox
                                checked={formState.rebasePaymentFees}
                                disabled={formState.isLoading}
                                label="Rebase existing payment service fees"
                                onChange={() => {
                                  if (formState.rebasePaymentFees) {
                                    setFormState({
                                      ...formState,
                                      rebasePaymentFees: false
                                    });
                                  }
                                  else {
                                    setFormState({
                                      ...formState,
                                      showConfirmRebaseModal: true
                                    });
                                  }
                                }}
                                style={{ fontWeight: 500 }}
                              />
                            </AppText>
                          </AppStack>
                        </Alert>
                      </AppStack>
                    </Alert>
                  </>
                )}
              </>
            )}
          </AppStack>
          {!regAssociation?.regAssociationPaymentProvider && (
            <Alert color="blue" icon={<InfoCircle />} variant="outline">
              <AppText style={{ fontSize: 14 }} weight={500}>
                Once onboarded you can not change the payment provider
              </AppText>
            </Alert>
          )}
        </FormSection>
      )}
      <ResponsiveModal
        isOpen={formState.showConfirmRebaseModal}
        onClose={() =>
          setFormState({
            ...formState,
            showConfirmRebaseModal: false
          })
        }
        title={<AppTitle order={2}>Rebase Payment Service Fees</AppTitle>}
      >
        <FormSection
          onCancel={() =>
            setFormState({
              ...formState,
              showConfirmRebaseModal: false,
              rebasePaymentFees: false
            })
          }
          onSubmit={() =>
            setFormState({
              ...formState,
              showConfirmRebaseModal: false,
              rebasePaymentFees: true
            })
          }
          submitTitle="Confirm"
        >
          <AppStack>
            <AppText weight={500}>
              Are you sure you want to rebase the existing payment service fees?
            </AppText>
            <AppText>
              This will update all existing payment service fees to the new
              service fees
            </AppText>
          </AppStack>
        </FormSection>
      </ResponsiveModal>
    </ResponsiveModal>
  );
};

FinancialPayeeOnboardingModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  regAssociation: PropTypes.object
};

export default FinancialPayeeOnboardingModal;
