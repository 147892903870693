import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Menu } from '@mantine/core';
import { ArrowRight, Check } from 'tabler-icons-react';
import { DateInput, DatePicker } from '@mantine/dates';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import MenuContent from '../../common/MenuContent';
import {
  DEFAULT_COMPARE_DATE_OPTIONS,
  DEFAULT_DATE_OPTIONS
} from './analyticsConfig';

const AnalyticsDateSelectMenu = ({
  control,
  datePeriod,
  compareDatePeriod,
  startDate,
  endDate,
  compareEndDate,
  compareStartDate,
  onChange,
  isCompareDateRange
}) => {
  const [formState, setFormState] = useState({
    datePeriod: null,
    startDate: null,
    endDate: null,
    isOpen: false,
    datePickerInput1Open: false,
    datePickerInput2Open: false
  });

  useEffect(() => {
    if (formState.isOpen) {
      if (compareDatePeriod) {
        setFormState({
          ...formState,
          datePeriod: new Date(compareDatePeriod),
          startDate: new Date(compareStartDate),
          endDate: compareEndDate
        });
      }
      else if (datePeriod) {
        setFormState({
          ...formState,
          datePeriod,
          ...datePeriod.getDateRange()
        });
      }
      else {
        setFormState({
          ...formState,
          datePeriod: null,
          startDate: new Date(startDate),
          endDate: new Date(endDate)
        });
      }
    }
  }, [formState.isOpen]);

  return (
    <MenuContent
      closeOnClickOutside
      closeOnItemClick={false}
      control={control}
      onChange={(isOpen) => {
        if (
          !formState.datePickerInput1Open &&
          !formState.datePickerInput2Open
        ) {
          setFormState({
            ...formState,
            isOpen,
            datePickerInput1Open: false,
            datePickerInput2Open: false
          });
        }
      }}
      opened={formState.isOpen}
      position="bottom-start"
      radius="md"
      shadow="xl"
      styles={{ dropdown: { padding: 0 } }}
      width="unset"
    >
      <AppStack style={{ gap: 0 }}>
        <AppFlexbox style={{ gap: 8 }}>
          <AppFlexbox
            style={{
              flex: 1,
              minWidth: 250,
              maxHeight: 365,
              overflow: 'auto',
              scrollbarWidth: 5,
              padding: 8
            }}
          >
            <AppStack style={{ gap: 0, flex: 1 }}>
              {(isCompareDateRange
                ? DEFAULT_COMPARE_DATE_OPTIONS
                : DEFAULT_DATE_OPTIONS
              ).map((op) => {
                const selected = formState.datePeriod?.value === op.value;

                return (
                  <Menu.Item
                    key={op.value}
                    onClick={(e) => {
                      e.preventDefault();
                      if (isCompareDateRange) {
                        const {
                          startDate: start,
                          endDate: end
                        } = op.getDateRange(datePeriod, startDate, endDate);

                        setFormState({
                          ...formState,
                          datePeriod: op,
                          startDate: start,
                          endDate: end
                        });
                      }
                      else {
                        setFormState({
                          ...formState,
                          datePeriod: op,
                          ...op.getDateRange()
                        });
                      }
                    }}
                    rightSection={
                      selected ? <Check color="#067D62" size={18} /> : null
                    }
                  >
                    {op.label}
                  </Menu.Item>
                );
              })}
            </AppStack>
          </AppFlexbox>
          <AppStack style={{ gap: 8, padding: 8, flex: 2 }}>
            <AppFlexbox style={{ alignItems: 'center', gap: 8 }}>
              <DateInput
                onChange={(value) =>
                  setFormState({
                    ...formState,
                    startDate: value,
                    datePeriod: null
                  })
                }
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                popoverProps={{
                  onClose: () => {
                    setFormState({ ...formState, datePickerInput1Open: false });
                  },
                  onOpen: () => {
                    setFormState({ ...formState, datePickerInput1Open: true });
                  }
                }}
                preserveTime={false}
                style={{ flex: 1 }}
                value={formState.startDate}
              />
              <ArrowRight color="#999" size={18} />
              <DateInput
                onChange={(value) =>
                  setFormState({
                    ...formState,
                    endDate: value,
                    datePeriod: null
                  })
                }
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                popoverProps={{
                  onClose: () => {
                    setFormState({ ...formState, datePickerInput2Open: false });
                  },
                  onOpen: () => {
                    setFormState({ ...formState, datePickerInput2Open: true });
                  }
                }}
                preserveTime={false}
                size="sm"
                style={{ flex: 1 }}
                value={formState.endDate}
              />
            </AppFlexbox>
            <AppFlexbox style={{ gap: 16 }}>
              <AppStack style={{ flex: 1, width: '100%' }}>
                <DatePicker
                  numberOfColumns={2}
                  onChange={([start, end]) =>
                    setFormState({
                      ...formState,
                      startDate: start || formState.startDate,
                      endDate: start ? end : formState.startDate,
                      datePeriod: null
                    })
                  }
                  size="sm"
                  type="range"
                  value={[formState.startDate, formState.endDate]}
                />
              </AppStack>
            </AppFlexbox>
          </AppStack>
        </AppFlexbox>
        <AppFlexbox
          style={{
            gap: 8,
            padding: '16px 8px',
            alignItems: 'center',
            justifyContent: 'end',
            borderTop: 'solid 1px #dee2e6'
          }}
        >
          <Button
            color="dark"
            onClick={() =>
              setFormState({
                ...formState,
                isOpen: false
              })
            }
            radius="md"
            size="compact-sm"
            type="button"
            variant="outline"
          >
            Cancel
          </Button>
          <Button
            color="dark"
            disabled={
              formState.datePeriod?.value !== 'noComparison' &&
              (!formState.startDate || !formState.endDate)
            }
            onClick={() => {
              onChange({
                datePeriod: formState.datePeriod,
                startDate: formState.startDate,
                endDate: formState.endDate
              });
              setFormState({
                ...formState,
                isOpen: false
              });
            }}
            radius="md"
            size="compact-sm"
            variant="filled"
          >
            Apply
          </Button>
        </AppFlexbox>
      </AppStack>
    </MenuContent>
  );
};

AnalyticsDateSelectMenu.propTypes = {
  compareDatePeriod: PropTypes.object,
  compareEndDate: PropTypes.object,
  compareStartDate: PropTypes.object,
  control: PropTypes.object,
  datePeriod: PropTypes.object,
  endDate: PropTypes.object,
  isCompareDateRange: PropTypes.bool,
  onChange: PropTypes.func,
  startDate: PropTypes.object
};

export default AnalyticsDateSelectMenu;
