import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Select, Title } from '@mantine/core';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { Context as AuthContext } from '../../../providers/AuthProvider';
import ResponsiveModal from '../../common/ResponsiveModal';
import FormSection from '../../common/FormSection';
import AppStack from '../../common/AppStack';
import TabsContent from '../../common/TabsContent';
import TeamsGenerateSection from './TeamsGenerateSection';
import { formatUtcDate } from '../../../helpers/format';
import AppText from '../../common/AppText';
import SpecialRequestsModalSection from '../leagues/SpecialRequestsModalSection';
import EditTeam from './EditTeam';
import DeleteItemView from '../../common/DeleteItemView';
import { triggerNotification } from '../../../helpers/notificationHelper';
import LeagueTeamsModalImportView from '../leagues/LeagueTeamsModalImportView';
import LeagueSeasonEndedNotification from '../leagues/LeagueSeasonEndedNotification';

const TEAM_MODAL_VIEW_ENUM = {
  GENERATE: 'GENERATE',
  SPECIAL_REQUEST: 'SPECIAL_REQUEST',
  BRACKETS_IMPORT: 'BRACKETS-IMPORT',
  CREATE: 'CREATE',
  EDIT: 'EDIT',
  DELETE: 'DELETE'
};

const TEAM_MODAL_VIEWS = [
  {
    value: TEAM_MODAL_VIEW_ENUM.GENERATE,
    size: 700,
    title: 'Generate Teams',
    label: 'Generate',
    isTab: true
  },
  {
    value: TEAM_MODAL_VIEW_ENUM.CREATE,
    size: 900,
    title: 'Create Team',
    label: 'Create',
    isTab: true,
    disabled: true,
    tooltipMessage:
      'This feature is temporarily unavailable. Please import your teams from your website instead of creating them here.'
  },
  {
    value: TEAM_MODAL_VIEW_ENUM.BRACKETS_IMPORT,
    title: 'Import Teams',
    label: 'Import',
    isTab: true
  },
  {
    value: TEAM_MODAL_VIEW_ENUM.SPECIAL_REQUEST,
    title: 'Special Requests',
    label: 'Special Requests',
    isTab: true
  },
  {
    value: TEAM_MODAL_VIEW_ENUM.EDIT,
    size: 900,
    title: 'Edit Team',
    isTab: false
  },
  {
    value: TEAM_MODAL_VIEW_ENUM.DELETE,
    title: 'Delete Team',
    isTab: false
  }
];

const TeamsModal = ({
  isOpen,
  onClose,
  regAssociation,
  regAssociationDivisionTeam,
  showDelete,
  fkRegAssociationSeason,
  fkRegAssociationDivision,
  noDivisionSelect
}) => {
  const hasLoaded = useRef(false);
  const fetchedPkRegAssociationSeason = useRef(null);
  const {
    state,
    fetchAdminRegAssociationDivisionRegistrants,
    deleteAdminRegAssociationDivisionTeam,
    fetchUpdatedAdminRegAssociationDivision
  } = useContext(RegistrationAdminContext);
  const { state: authState } = useContext(AuthContext);
  const [formState, setFormState] = useState({
    view: TEAM_MODAL_VIEW_ENUM.BRACKETS_IMPORT,
    fkRegAssociationSeason: null,
    fkRegAssociationDivision: null,
    selectedRegAssociationDivision: null,
    isLoading: false
  });
  const selectedView = TEAM_MODAL_VIEWS.find((t) => t.value === formState.view);

  const regAssociationDivisionTeams = state.regAssociationDivisionTeams.value.filter(
    (t) =>
      t.regAssociationDivision.pkRegAssociationDivision.toString() ===
      formState.fkRegAssociationDivision
  );
  const divisionRegistrants = state.regAssociationDivisionRegistrants.value.filter(
    (d) =>
      d.regAssociationDivisionOverride?.pkRegAssociationDivision.toString() ===
        formState.fkRegAssociationDivision ||
      (d.regAssociationDivisionForm.regAssociationDivision.pkRegAssociationDivision.toString() ===
        formState.fkRegAssociationDivision &&
        d.regAssociationDivisionOverride == null)
  );
  const divisionSpecialRequests = state.regSpecialRequests.value.filter(
    (s) =>
      s.regFormSubmission1.regAssociationDivisionOverride?.pkRegAssociationDivision.toString() ===
        formState.fkRegAssociationDivision ||
      (!s.regFormSubmission1.regAssociationDivisionOverride &&
        s.regFormSubmission1.regAssociationDivisionForm.regAssociationDivision.pkRegAssociationDivision.toString() ===
          formState.fkRegAssociationDivision)
  );

  const selectedRegAssociationSeason = state.regAssociationSeasons.value.find(
    (s) =>
      s.pkRegAssociationSeason.toString() === formState.fkRegAssociationSeason
  );
  const hasSeasonEnded = selectedRegAssociationSeason
    ? new Date(formatUtcDate(selectedRegAssociationSeason.endDate)) <=
      new Date()
    : false;

  const unavailableRegistrants = regAssociationDivisionTeams.reduce((r, c) => {
    if (
      c.pkRegAssociationDivisionTeam !==
      regAssociationDivisionTeam?.pkRegAssociationDivisionTeam
    ) {
      return r.concat(
        c.regAssociationDivisionTeamPersons.filter((a) => a.active)
      );
    }
    return r;
  }, []);

  const availableRegistrants = divisionRegistrants.filter(
    (r) =>
      !unavailableRegistrants.some(
        (a) => a.regPerson.pkRegPerson === r.regPerson.pkRegPerson
      )
  );

  const isLoadingRegistrants =
    !formState.selectedRegAssociationDivision ||
    fetchedPkRegAssociationSeason.current !==
      formState.selectedRegAssociationDivision.regAssociationSeason
        .pkRegAssociationSeason ||
    state.regAssociationDivisionRegistrants.loading;

  useEffect(() => {
    if (isOpen) {
      const regAssociationDivision = state.regAssociationDivisions.value.find(
        (d) =>
          d.pkRegAssociationDivision.toString() ===
          fkRegAssociationDivision?.toString()
      );

      const pkRegAssociationSeason =
        regAssociationDivision?.regAssociationSeason.pkRegAssociationSeason.toString() ||
        state.regAssociationSeasons.value
          .filter(
            (f) =>
              !f.deleted &&
              f.fkRegAssociation === regAssociation?.pkRegAssociation
          )
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0]
          ?.pkRegAssociationSeason.toString() ||
        null;
      setFormState({
        view: showDelete
          ? TEAM_MODAL_VIEW_ENUM.DELETE
          : regAssociationDivisionTeam
          ? TEAM_MODAL_VIEW_ENUM.EDIT
          : TEAM_MODAL_VIEW_ENUM.BRACKETS_IMPORT,
        fkRegAssociationSeason:
          fkRegAssociationSeason || pkRegAssociationSeason,
        fkRegAssociationDivision:
          regAssociationDivisionTeam?.regAssociationDivision.pkRegAssociationDivision.toString() ||
          regAssociationDivision?.pkRegAssociationDivision.toString() ||
          null,
        selectedRegAssociationDivision: regAssociationDivisionTeam
          ? regAssociationDivisionTeam.regAssociationDivision
          : regAssociationDivision &&
            new Date(
              formatUtcDate(regAssociationDivision.regAssociationSeason.endDate)
            ) > new Date()
          ? regAssociationDivision
          : null,
        isLoading: false
      });
      hasLoaded.current = true;
    }
    else {
      setFormState({
        ...formState,
        fkRegAssociationSeason: null,
        fkRegAssociationDivision: null,
        selectedRegAssociationDivision: null,
        isLoading: false
      });
      hasLoaded.current = false;
    }
  }, [isOpen]);

  useEffect(() => {
    const pkRegAssociationSeason =
      formState.selectedRegAssociationDivision?.regAssociationSeason
        .pkRegAssociationSeason;
    if (
      pkRegAssociationSeason &&
      fetchedPkRegAssociationSeason.current !== pkRegAssociationSeason
    ) {
      const { pkRegAssociation } = regAssociation;

      fetchAdminRegAssociationDivisionRegistrants(pkRegAssociation, {
        pkRegAssociationSeason
      });
      fetchedPkRegAssociationSeason.current = pkRegAssociationSeason;
    }
  }, [formState.selectedRegAssociationDivision]);

  const onCancel = () => {
    if (noDivisionSelect || regAssociationDivisionTeam) {
      onClose();
    }
    else {
      setFormState({
        ...formState,
        selectedRegAssociationDivision: null
      });
    }
  };

  return (
    <ResponsiveModal
      closeOnClickOutside={formState.view !== TEAM_MODAL_VIEW_ENUM.GENERATE}
      isOpen={isOpen}
      onClose={onClose}
      size={
        regAssociationDivisionTeam &&
        formState.view !== TEAM_MODAL_VIEW_ENUM.DELETE &&
        !showDelete
          ? 900
          : hasSeasonEnded || !formState.selectedRegAssociationDivision
          ? 600
          : selectedView?.size
      }
      title={<Title order={2}>{selectedView?.title}</Title>}
      transitionProps={{
        duration: 0,
        transition: 'none'
      }}
    >
      {(hasLoaded.current || regAssociationDivisionTeam) && (
        <AppStack>
          {!regAssociationDivisionTeam && (
            <TabsContent
              activeTab={formState.view}
              color="blue"
              onTabChange={(view) => {
                if (view !== formState.view) {
                  setFormState({ ...formState, view });
                }
              }}
              tabs={
                authState.userData.isAdmin
                  ? TEAM_MODAL_VIEWS.filter((v) => v.isTab).map((v) => ({
                      ...v,
                      disabled: false,
                      tooltipMessage: null
                    }))
                  : TEAM_MODAL_VIEWS.filter((v) => v.isTab)
              }
            />
          )}

          {!regAssociationDivisionTeam &&
          (hasSeasonEnded || !formState.selectedRegAssociationDivision) ? (
            <FormSection
              isSubmitDisabled={
                !formState.fkRegAssociationDivision || hasSeasonEnded
              }
              onCancel={onClose}
              onSubmit={() => {
                setFormState({
                  ...formState,
                  selectedRegAssociationDivision: state.regAssociationDivisions.value.find(
                    (d) =>
                      d.pkRegAssociationDivision.toString() ===
                      formState.fkRegAssociationDivision
                  )
                });
              }}
              submitTitle="Continue"
            >
              <AppStack style={{ gap: 20 }}>
                <AppText
                  style={{ textAlign: 'center', marginTop: 10 }}
                  weight={500}
                >
                  Please select the division before you continue
                </AppText>
                <Select
                  clearable
                  data={state.regAssociationSeasons.value
                    .filter(
                      (f) =>
                        !f.deleted &&
                        f.fkRegAssociation === regAssociation?.pkRegAssociation
                    )
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((s) => ({
                      value: s.pkRegAssociationSeason.toString(),
                      label: s.name
                    }))}
                  label="Season"
                  onChange={(value) => {
                    setFormState({
                      ...formState,
                      fkRegAssociationSeason: value,
                      fkRegAssociationDivision: null
                    });
                  }}
                  placeholder="Select Season"
                  searchable
                  value={formState.fkRegAssociationSeason}
                />
                {hasSeasonEnded ? (
                  <LeagueSeasonEndedNotification
                    pkRegAssociation={regAssociation?.pkRegAssociation}
                    regAssociationSeason={selectedRegAssociationSeason}
                  />
                ) : (
                  <Select
                    clearable
                    data={state.regAssociationDivisions.value
                      .filter(
                        (f) =>
                          !f.deleted &&
                          f.regAssociationSeason?.fkRegAssociation ===
                            regAssociation?.pkRegAssociation &&
                          (!formState.fkRegAssociationSeason ||
                            formState.fkRegAssociationSeason ===
                              f.fkRegAssociationSeason.toString())
                      )
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((s) => ({
                        value: s.pkRegAssociationDivision.toString(),
                        label: s.name
                      }))}
                    label="Division"
                    onChange={(value) => {
                      const regAssociationDivision = state.regAssociationDivisions.value.find(
                        (d) => d.pkRegAssociationDivision.toString() === value
                      );
                      setFormState({
                        ...formState,
                        fkRegAssociationDivision: value,
                        fkRegAssociationSeason: regAssociationDivision?.fkRegAssociationSeason.toString()
                      });
                    }}
                    placeholder="Select Division"
                    required
                    searchable
                    value={formState.fkRegAssociationDivision}
                  />
                )}
              </AppStack>
            </FormSection>
          ) : !regAssociationDivisionTeam &&
            formState.view === TEAM_MODAL_VIEW_ENUM.GENERATE ? (
            <TeamsGenerateSection
              divisionRegistrants={divisionRegistrants}
              divisionSpecialRequests={divisionSpecialRequests}
              isLoading={isLoadingRegistrants}
              onCancel={onCancel}
              onClose={onClose}
              regAssociationDivision={formState.selectedRegAssociationDivision}
              regAssociationDivisionTeams={regAssociationDivisionTeams}
            />
          ) : !regAssociationDivisionTeam &&
            formState.view === TEAM_MODAL_VIEW_ENUM.SPECIAL_REQUEST ? (
            <SpecialRequestsModalSection
              divisionRegistrants={divisionRegistrants}
              onCancel={onCancel}
              pkRegAssociationDivision={
                formState.selectedRegAssociationDivision
                  .pkRegAssociationDivision
              }
            />
          ) : !regAssociationDivisionTeam &&
            formState.view === TEAM_MODAL_VIEW_ENUM.BRACKETS_IMPORT ? (
            <LeagueTeamsModalImportView
              onCancel={onCancel}
              onClose={onClose}
              regAssociation={regAssociation}
            />
          ) : regAssociationDivisionTeam &&
            (formState.view === TEAM_MODAL_VIEW_ENUM.DELETE || showDelete) ? (
            <DeleteItemView
              isLoading={formState.isLoading}
              itemLabel="Team"
              onCancel={() => {
                if (showDelete) {
                  onClose();
                }
                else {
                  setFormState({
                    ...formState,
                    view: TEAM_MODAL_VIEW_ENUM.EDIT
                  });
                }
              }}
              onDelete={() => {
                setFormState({
                  ...formState,
                  isLoading: true
                });
                deleteAdminRegAssociationDivisionTeam(
                  regAssociationDivisionTeam.pkRegAssociationDivisionTeam,
                  () => {
                    fetchUpdatedAdminRegAssociationDivision(
                      regAssociationDivisionTeam.regAssociationDivision
                        .pkRegAssociationDivision
                    );
                    onClose();
                  },
                  (e) => {
                    setFormState({
                      ...formState,
                      isLoading: false
                    });
                    triggerNotification(e);
                  }
                );
              }}
              warnings={[
                'Any reports, schedules, and other data associated with this team will be deleted.'
              ]}
            />
          ) : (
            <EditTeam
              availableRegistrants={availableRegistrants}
              divisionSpecialRequests={divisionSpecialRequests}
              isLoading={
                fetchedPkRegAssociationSeason.current !==
                formState.selectedRegAssociationDivision?.regAssociationSeason
                  .pkRegAssociationSeason
              }
              onCancel={onCancel}
              onDelete={() =>
                setFormState({
                  ...formState,
                  view: TEAM_MODAL_VIEW_ENUM.DELETE
                })
              }
              onSaveChanges={onClose}
              regAssociationDivision={formState.selectedRegAssociationDivision}
              regAssociationDivisionTeam={regAssociationDivisionTeam}
              showDelete={showDelete}
            />
          )}
        </AppStack>
      )}
    </ResponsiveModal>
  );
};

TeamsModal.propTypes = {
  fkRegAssociationDivision: PropTypes.string,
  fkRegAssociationSeason: PropTypes.string,
  isOpen: PropTypes.bool,
  noDivisionSelect: PropTypes.bool,
  onClose: PropTypes.func,
  regAssociation: PropTypes.object,
  regAssociationDivisionTeam: PropTypes.object,
  showDelete: PropTypes.bool
};

export default TeamsModal;
