import React from 'react';
import { PropTypes } from 'prop-types';

import {
  Combobox,
  Input,
  InputBase,
  ScrollArea,
  useCombobox
} from '@mantine/core';
import CustomSelectItem from './CustomSelectItem';

const CustomSelect = ({
  data,
  value,
  onChange,
  placeholder,
  disabled,
  searchValue,
  onlyShowSearchValue,
  ...rest
}) => {
  const combobox = useCombobox({onDropdownClose: () => combobox.resetSelectedOption()
  });
  const selectedItem = data.find((d) => d.value === value);

  return (
    <Combobox
      disabled={disabled}
      onOptionSubmit={(v) => {
        onChange(v);
        combobox.closeDropdown();
      }}
      store={combobox}
      withinPortal
      {...rest}
      style={{ minWidth: 0, ...rest.style }}
    >
      <Combobox.Target>
        <InputBase
          component="button"
          disabled={disabled}
          onClick={() => combobox.toggleDropdown()}
          pointer
          rightSection={<Combobox.Chevron />}
          rightSectionPointerEvents="none"
          type="button"
          {...rest.inputBaseProps}
        >
          {selectedItem && !onlyShowSearchValue ? (
            <CustomSelectItem noWrap {...selectedItem} description="" />
          ) : searchValue ? (
            <Input.Placeholder>{searchValue}</Input.Placeholder>
          ) : (
            placeholder && <Input.Placeholder>{placeholder}</Input.Placeholder>
          )}
        </InputBase>
      </Combobox.Target>

      <Combobox.Dropdown {...rest.dropdownProps}>
        <Combobox.Options>
          <ScrollArea.Autosize mah={250} scrollbarSize={5} type="scroll">
            {data.map((d) => (
              <Combobox.Option key={d.value} value={d.value}>
                <CustomSelectItem {...d} />
              </Combobox.Option>
            ))}
          </ScrollArea.Autosize>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

CustomSelect.propTypes = {
  data: PropTypes.array,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onlyShowSearchValue: PropTypes.bool,
  placeholder: PropTypes.string,
  searchValue: PropTypes.any,
  value: PropTypes.string
};

export default CustomSelect;
