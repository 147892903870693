import React from 'react';
import { Edit, Plus, Trash } from 'tabler-icons-react';
import { Button } from '@mantine/core';
import PropTypes from 'prop-types';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import ActionableIcon from '../../common/ActionableIcon';
import AppText from '../../common/AppText';
import {
  REG_SCOUT_REPORT_QUESTION_RESTRICTION_CONDITIONS,
  REG_SCOUT_REPORT_QUESTION_TYPE
} from '../../../config/constants';

const EvaluationTemplateQuestionRestrictions = ({
  regScoutReportQuestion,
  onAddRestriction,
  onEditRestriction,
  onRemoveRestriction
}) => {
  const isAttribute =
    regScoutReportQuestion?.fkRegScoutReportQuestionType ===
    REG_SCOUT_REPORT_QUESTION_TYPE.ATTRIBUTE;

  const questionRestrictions = regScoutReportQuestion.regScoutReportQuestionRestrictions.reduce(
    (r, c) => {
      const restriction = r.find(
        (f) =>
          f.condition === c.condition && f.conditionValue === c.conditionValue
      );
      if (restriction) {
        return [
          ...r.filter(
            (f) =>
              f.condition !== c.condition &&
              f.conditionValue !== c.conditionValue
          ),
          {
            ...restriction,
            questionCount: restriction.questionCount + 1
          }
        ];
      }

      return [
        ...r,
        {
          ...c,
          questionCount: 1
        }
      ];
    },
    []
  );

  return (
    <AppStack style={{ flex: 1, gap: 5 }}>
      <AppFlexbox style={{ justifyContent: 'space-between' }}>
        <AppText style={{ fontSize: 14 }} weight={500}>
          Restricted Questions
        </AppText>

        <Button
          color="blue"
          leftSection={<Plus size={20} />}
          onClick={onAddRestriction}
          size="compact-xs"
          variant="outline"
        >
          Add Restrictions
        </Button>
      </AppFlexbox>

      {questionRestrictions.length === 0 ? (
        <AppStack
          style={{
            flex: 1,
            gap: 10,
            padding: 30,
            border: 'solid 1px lightgrey',
            textAlign: 'center',
            justifyContent: 'center'
          }}
        >
          <AppText>No questions restricted</AppText>
        </AppStack>
      ) : (
        <AppStack style={{ gap: 5 }}>
          {questionRestrictions
            .sort(
              (a, b) =>
                a.questionConditionRefNumber - b.questionConditionRefNumber ||
                a.questionRestrictedRefNumber - b.questionRestrictedRefNumber
            )
            .map((r) => (
              <AppFlexbox
                key={r.key}
                style={{
                  justifyContent: 'space-between',
                  padding: 10,
                  alignItems: 'center',
                  border: 'solid 1px lightgrey'
                }}
              >
                {isAttribute ? (
                  <AppStack style={{ gap: 0 }}>
                    <AppText>
                      {
                        REG_SCOUT_REPORT_QUESTION_RESTRICTION_CONDITIONS.find(
                          (c) => c.value === r.condition
                        )?.label
                      }{' '}
                      - <b>{r.conditionValue}</b>
                    </AppText>
                    <AppText color="grey" style={{ fontSize: 14 }}>
                      {r.questionCount} question(s) assigned
                    </AppText>
                  </AppStack>
                ) : (
                  <AppStack style={{ gap: 0 }}>
                    <AppText>
                      {
                        REG_SCOUT_REPORT_QUESTION_RESTRICTION_CONDITIONS.find(
                          (c) => c.value === r.condition
                        )?.label
                      }{' '}
                      - <b>{r.conditionValue}</b>
                    </AppText>
                  </AppStack>
                )}

                <AppFlexbox>
                  <ActionableIcon
                    color="dark"
                    onClick={() => onEditRestriction(r)}
                  >
                    <Edit />
                  </ActionableIcon>
                  <ActionableIcon
                    color="red"
                    onClick={() => onRemoveRestriction(r)}
                  >
                    <Trash />
                  </ActionableIcon>
                </AppFlexbox>
              </AppFlexbox>
            ))}
        </AppStack>
      )}
    </AppStack>
  );
};

EvaluationTemplateQuestionRestrictions.propTypes = {
  onAddRestriction: PropTypes.func,
  onEditRestriction: PropTypes.func,
  onRemoveRestriction: PropTypes.func,
  regScoutReportQuestion: PropTypes.object
};

export default EvaluationTemplateQuestionRestrictions;
