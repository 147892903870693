import React, { useContext, useEffect, useRef } from 'react';
import {
  BrandFacebook,
  BrandInstagram,
  BrandLinkedin,
  BrandTwitter
} from 'tabler-icons-react';
import { Link, useLocation } from 'react-router-dom';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import { Context as AuthContext } from '../../../providers/AuthProvider';
import { isDarkText } from '../../../helpers/format';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import ActionableIcon from '../../common/ActionableIcon';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../../helpers/styles';

const Footer = () => {
  const mqIndex = useMediaQueryIndex();
  const hasFetchedAssociation = useRef(false);
  const { state: regState } = useContext(RegistrationContext);
  const { state: authState } = useContext(AuthContext);
  const { pathname } = useLocation();

  let primaryColor;
  let supportEmail;
  if (
    /(\/association\/)\d+/.test(pathname) &&
    hasFetchedAssociation.current &&
    !regState.regAssociation.loading &&
    regState.regAssociation.value?.primaryColor &&
    (!regState.regAssociation.value?.sandboxEnabled ||
      authState.userData?.isRegistrationAdmin)
  ) {
    primaryColor = regState.regAssociation.value.primaryColor;
    supportEmail = regState.regAssociation.value.supportEmail;
  }
  const darkText = isDarkText(primaryColor);
  const headerTextColor = darkText ? '#000' : 'lightgrey';

  useEffect(() => {
    hasFetchedAssociation.current = false;
  }, [pathname]);

  useEffect(() => {
    if (regState.regAssociation.loading) {
      hasFetchedAssociation.current = true;
    }
  }, [regState.regAssociation.loading]);

  return (
    <AppFlexbox
      style={{
        flex: 1,
        flexDirection: 'column',
        position: 'static',
        maxWidth: '100%',
        gap: 30,
        padding: 60,
        color: darkText ? '#000' : '#FFF',
        backgroundColor: primaryColor ?? '#000'
      }}
    >
      <AppFlexbox
        style={{
          flex: 1,
          flexDirection: rs(['column', 'column', 'row'], mqIndex),
          width: rs(['100%', '100%', 'unset'], mqIndex)
        }}
      >
        <AppFlexbox
          style={{
            flex: 2,
            gap: 20,
            alignItems: 'start',
            padding: 0,
            maxWidth: 800,
            flexDirection: rs(['column', 'row'], mqIndex),
            alignContent: 'start',
            textWrap: 'nowrap'
          }}
        >
          <AppFlexbox style={{ flexDirection: 'column', flex: 1 }}>
            <AppText color={headerTextColor} weight={500}>
              My Account
            </AppText>
            {authState.isAuthenticated ? (
              authState.userData.isRegistrationAdmin ? (
                <>
                  <AppText component={Link} to="/reports">
                    Reports
                  </AppText>
                  <AppText component={Link} to="/financials">
                    Financials
                  </AppText>
                  <AppText component={Link} to="/admin">
                    My League
                  </AppText>
                </>
              ) : (
                <>
                  <AppText component={Link} to="/participants">
                    Participants
                  </AppText>
                  <AppText component={Link} to="/orders">
                    Order status
                  </AppText>
                </>
              )
            ) : (
              <>
                <AppText component={Link} to="/auth/login">
                  Sign in
                </AppText>
                <AppText component={Link} to="/auth/register">
                  Register
                </AppText>
              </>
            )}
          </AppFlexbox>

          <AppFlexbox
            style={{
              flex: 1,
              marginTop: rs([20, 0], mqIndex)
            }}
          >
            <AppFlexbox style={{ flexDirection: 'column' }}>
              <AppText color={headerTextColor} weight={500}>
                Help
              </AppText>
              <AppText
                component="a"
                href={
                  supportEmail
                    ? `mailto:${supportEmail}`
                    : 'https://www.sportsheadz.com/contact'
                }
                target={supportEmail ? null : '_blank'}
              >
                Contact Support
              </AppText>
            </AppFlexbox>
          </AppFlexbox>

          <AppFlexbox style={{ flex: 1 }}>
            <AppFlexbox
              style={{
                flexDirection: 'column',
                marginTop: rs([20, 0], mqIndex)
              }}
            >
              <AppText color={headerTextColor} weight={500}>
                About
              </AppText>
              <AppText
                component="a"
                href="https://www.sportsheadz.com/about-us"
                target="_blank"
              >
                Our Story
              </AppText>
            </AppFlexbox>
          </AppFlexbox>

          <AppFlexbox style={{ flex: 1 }}>
            <AppFlexbox
              style={{
                flexDirection: 'column',
                marginTop: rs([20, 0], mqIndex)
              }}
            >
              <AppText color={headerTextColor} weight={500}>
                Legal
              </AppText>
              <AppText
                component="a"
                href="https://www.sportsheadz.com/privacy-policy"
                target="_blank"
              >
                Privacy Policy
              </AppText>
            </AppFlexbox>
          </AppFlexbox>
        </AppFlexbox>
        <AppFlexbox
          style={{
            flex: 1,
            justifyContent: rs(['start', 'start', 'flex-end'], mqIndex),
            marginTop: rs([20, 20, 0], mqIndex)
          }}
        >
          <AppFlexbox style={{ flexDirection: 'column' }}>
            <AppText color={headerTextColor} weight={500}>
              Follow SportsHeadz
            </AppText>
            <AppFlexbox>
              <ActionableIcon
                component="a"
                href="https://www.facebook.com/SportsHeadzCanada/"
                size={35}
                style={{
                  borderRadius: 50,
                  color: '#000'
                }}
                target="_blank"
                variant="white"
              >
                <BrandFacebook size={25} />
              </ActionableIcon>

              <ActionableIcon
                component="a"
                href="https://www.instagram.com/sportsheadzcanada/"
                size={35}
                style={{
                  borderRadius: 50,
                  color: '#000'
                }}
                target="_blank"
                variant="white"
              >
                <BrandInstagram size={24} />
              </ActionableIcon>

              <ActionableIcon
                component="a"
                href="https://twitter.com/sportsheadzcan"
                size={35}
                style={{
                  borderRadius: 50,
                  color: '#000'
                }}
                target="_blank"
                variant="white"
              >
                <BrandTwitter size={25} />
              </ActionableIcon>

              <ActionableIcon
                component="a"
                href="https://ca.linkedin.com/company/sportsheadz"
                size={35}
                style={{
                  borderRadius: 50,
                  color: '#000'
                }}
                target="_blank"
                variant="white"
              >
                <BrandLinkedin size={25} />
              </ActionableIcon>
            </AppFlexbox>
          </AppFlexbox>
        </AppFlexbox>
      </AppFlexbox>
      <AppText color={headerTextColor} style={{ fontSize: 12 }} weight={500}>
        © SportsHeadz Inc. All Rights Reserved
      </AppText>
    </AppFlexbox>
  );
};

export default Footer;
