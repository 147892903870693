import React from 'react';
import { PropTypes } from 'prop-types';
import { Button, Divider } from '@mantine/core';
import dayjs from 'dayjs';
import NumberFormat from 'react-number-format';
import ResponsiveModal from '../../common/ResponsiveModal';
import {
  CURRENCY_TYPE_LIST,
  REG_GENDER_TYPES
} from '../../../config/constants';
import AppTitle from '../../common/AppTitle';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import AssociationCustomButtom from './AssociationCustomButtom';

const RegistrationInfoModal = ({
  isOpen,
  onClose,
  regAssociationDivisionForm,
  registrationsAvailableCount,
  daysOfPlayString,
  onSelectRegForm,
  selectRegFormButtonText,
  primaryColor,
  primaryTextColor,
  secondaryColor,
  secondaryTextColor,
  isDisabled
}) => {
  const mqIndex = useMediaQueryIndex();
  const {
    regAssociationDivision: division,
    regForm: form
  } = regAssociationDivisionForm;
  const currencyAbbr = CURRENCY_TYPE_LIST.find(
    (c) =>
      c.value.toString() ===
      regAssociationDivisionForm.regProduct.regAssociation?.regAssociationPaymentProvider?.fkCurrencyType?.toString()
  )?.abbr;

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClose}
      title={<AppTitle order={2}>Registration Info</AppTitle>}
    >
      {regAssociationDivisionForm && (
        <AppStack style={{ flex: 1, gap: 20 }}>
          <Divider />
          <AppStack
            style={{
              flex: 1,
              gap: 10,
              justifyContent: 'space-between'
            }}
          >
            <AppStack style={{ gap: 0 }}>
              <AppFlexbox
                style={{
                  alignItems: 'start',
                  flexDirection: rs(['column', 'row'], mqIndex),
                  gap: 10
                }}
              >
                <AppStack style={{ flex: 1, gap: 0 }}>
                  <AppFlexbox style={{ gap: 5 }}>
                    <AppTitle order={4}>
                      {regAssociationDivisionForm.titleOverride
                        ? regAssociationDivisionForm.titleOverride
                        : form.name}
                    </AppTitle>
                  </AppFlexbox>
                  <AppFlexbox style={{ gap: 5 }}>
                    <AppText style={{ overflow: 'visible ' }} weight={500}>
                      Division:{' '}
                    </AppText>
                    <AppText>{division.name}</AppText>
                  </AppFlexbox>

                  <AppStack
                    style={{
                      flex: 1,
                      gap: 0,
                      display: rs(['flex', 'flex', 'none'], mqIndex)
                    }}
                  >
                    <AppFlexbox style={{ gap: 5 }}>
                      <AppText style={{ overflow: 'visible ' }} weight={500}>
                        Days of Play:{' '}
                      </AppText>
                      <AppText>{daysOfPlayString}</AppText>
                    </AppFlexbox>
                    <AppFlexbox style={{ gap: 5 }}>
                      <AppText style={{ overflow: 'visible ' }} weight={500}>
                        Gender:{' '}
                      </AppText>
                      <AppText>
                        {
                          REG_GENDER_TYPES.find(
                            (t) =>
                              t.value === division.fkRegGenderType.toString()
                          )?.label
                        }{' '}
                        {division.isAllAges
                          ? '(All Ages)'
                          : `(${dayjs(new Date()).diff(
                              division.minDob,
                              'year'
                            )} years old and younger)`}
                      </AppText>
                    </AppFlexbox>
                  </AppStack>
                </AppStack>

                <AppStack
                  style={{
                    gap: 0,
                    placeItems: rs(['start', 'end'], mqIndex)
                  }}
                >
                  <AppTitle order={4}>
                    <NumberFormat
                      decimalScale={2}
                      displayType="text"
                      fixedDecimalScale
                      prefix="$"
                      thousandSeparator
                      value={regAssociationDivisionForm.regProduct.price}
                    />{' '}
                    {currencyAbbr}
                  </AppTitle>
                  <AppText color="dodgerblue" weight={500}>
                    {!onSelectRegForm
                      ? 'Closed'
                      : division.registrantLimit == null
                      ? 'Unlimited'
                      : registrationsAvailableCount <= 0
                      ? 'Full'
                      : `${registrationsAvailableCount} remaining`}
                  </AppText>
                </AppStack>
              </AppFlexbox>

              <AppStack
                style={{
                  flex: 1,
                  gap: 0,
                  display: rs(['none', 'none', 'flex'], mqIndex)
                }}
              >
                <AppFlexbox style={{ gap: 5 }}>
                  <AppText weight={500}>Gender: </AppText>
                  <AppText>
                    {
                      REG_GENDER_TYPES.find(
                        (t) => t.value === division.fkRegGenderType.toString()
                      )?.label
                    }{' '}
                    {division.isAllAges
                      ? '(All Ages)'
                      : `(${dayjs(new Date()).diff(
                          division.minDob,
                          'year'
                        )} years old and younger)`}
                  </AppText>
                </AppFlexbox>
                <AppFlexbox style={{ gap: 5 }}>
                  <AppText weight={500}>Days of Play: </AppText>
                  <AppText>{daysOfPlayString}</AppText>
                </AppFlexbox>
              </AppStack>
            </AppStack>

            <AppStack style={{ flex: 1, gap: 5 }}>
              <AppText weight={500}>Additional Info</AppText>
              {!regAssociationDivisionForm.additionalInfo ? (
                <AppStack
                  style={{
                    textAlign: 'center',
                    justifyContent: 'center',
                    padding: '20px 0px',
                    flex: 1
                  }}
                >
                  <AppText weight={500}>No additional info provided</AppText>
                </AppStack>
              ) : regAssociationDivisionForm.isAdditionalInfoHyperlink ? (
                <AppStack style={{ flex: 1, padding: '20px 0px' }}>
                  <Button
                    color="dark"
                    component="a"
                    href={regAssociationDivisionForm.additionalInfo}
                    style={{ margin: 'auto' }}
                    target="_blank"
                    variant="outline"
                  >
                    View Website
                  </Button>
                </AppStack>
              ) : (
                <AppText style={{ whiteSpace: 'pre-line' }}>
                  {regAssociationDivisionForm.additionalInfo}
                </AppText>
              )}
            </AppStack>
          </AppStack>
          <Divider style={{ marginBottom: 10 }} />
          <AppStack style={{ gap: 20 }}>
            <AppFlexbox style={{ justifyContent: 'center' }}>
              <Button
                color="dark"
                onClick={onClose}
                radius="xs"
                style={{
                  flex: 1,
                  maxWidth: rs(['unset', 'unset', 250], mqIndex),
                  display: rs(['none', 'inline-block'], mqIndex)
                }}
              >
                Close
              </Button>

              {onSelectRegForm && (
                <AssociationCustomButtom
                  disabled={isDisabled}
                  onClick={() => {
                    onClose();
                    onSelectRegForm(regAssociationDivisionForm);
                  }}
                  primaryColor={primaryColor}
                  primaryTextColor={primaryTextColor}
                  secondaryColor={secondaryColor}
                  secondaryTextColor={secondaryTextColor}
                  style={{
                    flex: 1,
                    maxWidth: rs(['unset', 'unset', 250], mqIndex)
                  }}
                >
                  {selectRegFormButtonText}
                </AssociationCustomButtom>
              )}
            </AppFlexbox>
            <AppFlexbox
              style={{
                flex: 1,
                justifyContent: 'flex-end',
                display: rs(['flex', 'none'], mqIndex)
              }}
            >
              <Button
                color="dark"
                onClick={onClose}
                radius="xs"
                style={{ flex: 1 }}
              >
                Close
              </Button>
            </AppFlexbox>
          </AppStack>
        </AppStack>
      )}
    </ResponsiveModal>
  );
};

RegistrationInfoModal.propTypes = {
  daysOfPlayString: PropTypes.string,
  isDisabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSelectRegForm: PropTypes.func,
  primaryColor: PropTypes.string,
  primaryTextColor: PropTypes.string,
  regAssociationDivisionForm: PropTypes.object,
  registrationsAvailableCount: PropTypes.number,
  secondaryColor: PropTypes.string,
  secondaryTextColor: PropTypes.string,
  selectRegFormButtonText: PropTypes.string
};

export default RegistrationInfoModal;
