import React from 'react';
import PropTypes from 'prop-types';
import { Title } from '@mantine/core';

const AppTitle = ({ order, children, ...rest }) => (
  <Title order={order} {...rest}>
    {children}
  </Title>
);

AppTitle.propTypes = {
  children: PropTypes.node,
  order: PropTypes.number
};

export default AppTitle;
