import React from 'react';
import PropTypes from 'prop-types';
import { AlertCircle } from 'tabler-icons-react';
import { Button } from '@mantine/core';
import AppStack from './AppStack';
import AppFlexbox from './AppFlexbox';
import AppText from './AppText';

const AlertAction = ({ message, icon, actionLabel, onAction }) => (
  <AppStack
    style={{
      gap: 20,
      padding: '30px 20px',
      placeItems: 'center',
      border: 'solid 1px lightgrey'
    }}
  >
    <AppFlexbox
      style={{
        gap: 20,
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {icon || <AlertCircle color="red" size={45} />}
      <AppText style={{ textAlign: 'center' }}>{message}</AppText>
    </AppFlexbox>
    <Button
      color="blue"
      onClick={onAction}
      style={{ maxWidth: 250, width: '100%' }}
    >
      {actionLabel}
    </Button>
  </AppStack>
);

AlertAction.propTypes = {
  actionLabel: PropTypes.string,
  icon: PropTypes.node,
  message: PropTypes.string,
  onAction: PropTypes.func
};

export default AlertAction;
