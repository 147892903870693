import React, { useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import ReportTableView from './ReportTableView';

const AssociationReportTableView = () => {
  const navigate = useNavigate();
  const { state } = useContext(RegistrationAdminContext);
  const reportResult = state.regReportResult.value;
  const { pkRegAssociation, pkRegReport } = useParams();

  const reportGenerated =
    reportResult &&
    reportResult.pkRegAssociation.toString() === pkRegAssociation &&
    (reportResult.pkRegReportTemplate?.toString() === pkRegReport ||
      reportResult.pkRegAssociationSavedTemplate?.toString() === pkRegReport);

  const isLoading =
    state.regAssociations.loading || state.regReportResult.loading;

  useEffect(() => {
    if (!isLoading) {
      const adminAssociation = state.regAssociations.value.find(
        (a) => a.pkRegAssociation.toString() === pkRegAssociation
      );
      if (!adminAssociation || !reportResult) {
        navigate('/admin/reports');
      }
    }
  }, [isLoading, pkRegAssociation, pkRegReport]);

  return (
    <ReportTableView
      isLoading={isLoading || !reportGenerated}
      reportResult={reportResult}
    />
  );
};

AssociationReportTableView.propTypes = {};

export default AssociationReportTableView;
