import React, { useContext, useEffect, useRef, useState } from 'react';
import { ActionIcon, Button, Menu, Select, Skeleton } from '@mantine/core';
import { Dots, Download } from 'tabler-icons-react';
import dayjs from 'dayjs';
import Papa from 'papaparse';
import { useNavigate } from 'react-router-dom';
import { Context as AuthContext } from '../../../providers/AuthProvider';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import AppCard from '../../common/AppCard';
import HeaderView from '../../common/HeaderView';
import CustomSelect from '../../common/CustomSelect';
import PaginationList from '../../common/PaginationList';
import MenuContent from '../../common/MenuContent';
import { useMediaQueryIndex, useModalState } from '../../../helpers/hooks';
import MailingSubscribeModal from './MailingSubscribeModal';
import { formatUtcDate, singularPluralFormat } from '../../../helpers/format';
import EmailMailingsModal from './EmailMailingsModal';
import TabsContent from '../../common/TabsContent';
import ViewEmailRecipientsModal from './ViewEmailRecipientsModal';
import AppImage from '../../common/AppImage';

const MENU_ACTIONS_ENUM = {
  UNSUBSCRIBE: 'UNSUBSCRIBE',
  SUBSCRIBE: 'SUBSCRIBE',
  VIEW: 'VIEW',
  VIEW_EMAIL: 'VIEW_EMAIL'
};

const MENU_ACTIONS = [
  {
    label: 'View',
    value: MENU_ACTIONS_ENUM.VIEW,
    isVisible: () => true
  },
  {
    label: 'Manage Subscriptions',
    value: MENU_ACTIONS_ENUM.UNSUBSCRIBE,
    isVisible: (emails, regEmailUnsubscribes) => emails.length > 1
  },
  {
    label: 'Unsubscribe',
    value: MENU_ACTIONS_ENUM.UNSUBSCRIBE,
    isVisible: (emails, regEmailUnsubscribes) =>
      emails.length === 1 && regEmailUnsubscribes.length === 0
  },
  {
    label: 'Resubscribe',
    value: MENU_ACTIONS_ENUM.SUBSCRIBE,
    isVisible: (emails, regEmailUnsubscribes) =>
      emails.length === 1 && regEmailUnsubscribes.length === 1
  }
];

const REGISTRATION_TABS_ENUM = {
  SUBSCRIBED: 'SUBSCRIBED',
  UNSUBSCRIBED: 'UNSUBSCRIBED'
};

const REGISTRATION_TABS = [
  {
    label: 'Subscribed',
    value: REGISTRATION_TABS_ENUM.SUBSCRIBED
  },
  {
    label: 'Unsubscribed',
    value: REGISTRATION_TABS_ENUM.UNSUBSCRIBED
  }
];

const ADMIN_TABS_ENUM = {
  REGISTRATION: 'REGISTRATION',
  EMAILS: 'EMAILS',
  UNSUBSCRIBED: 'UNSUBSCRIBE'
};

const ADMIN_TABS = [
  {
    label: 'My Registrations',
    value: ADMIN_TABS_ENUM.REGISTRATION
  },
  {
    label: 'Sent Emails',
    value: ADMIN_TABS_ENUM.EMAILS
  },
  {
    label: 'Unsubscribed',
    value: ADMIN_TABS_ENUM.UNSUBSCRIBED
  }
];

const UserCommunicationView = () => {
  const mqIndex = useMediaQueryIndex();
  const isMobileView = mqIndex === 0;
  const isTabletViewOrSmaller = mqIndex <= 1;
  const navigate = useNavigate();
  const fetchedPkRegAssociation = useRef(null);
  const hasFetched = useRef(false);
  const hasInitialized = useRef(false);
  const { state: authState } = useContext(AuthContext);
  const { state, fetchRegistrationEmails } = useContext(RegistrationContext);
  const {
    state: adminState,
    fetchAdminRegAssociationEmailUnsubscribe,
    fetchAdminRegAssociationSentEmails
  } = useContext(RegistrationAdminContext);
  const { value: submissionEmails, loading } = state.regFormSubmissionEmails;
  const [viewState, setViewState] = useState({
    pkRegAssociation: null,
    pageIndex: 1,
    activeTab: ADMIN_TABS_ENUM.REGISTRATION,
    registrationsView: REGISTRATION_TABS_ENUM.SUBSCRIBED,
    isLoading: false
  });
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const isLoading = !hasFetched.current || loading;
  const regAssociations = submissionEmails
    .reduce((r, c) => {
      c.regAssociations.forEach((a) => {
        if (!r.some((s) => s.pkRegAssociation === a.pkRegAssociation)) {
          r.push(a);
        }
      });
      return r;
    }, [])
    .sort(
      (a, b) =>
        a.association.name.localeCompare(b.association.name) ||
        new Date(a.createdAt) - new Date(b.createdAt)
    );
  const isAdminView = viewState.activeTab !== ADMIN_TABS_ENUM.REGISTRATION;

  const regAssociationOptions = isAdminView
    ? adminState.regAssociations.value
        .map((a) => ({
          image: a.logoImageUrl,
          value: a.pkRegAssociation.toString(),
          label: a.association.name
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    : regAssociations
        .map((a) => ({
          image: a.logoImageUrl,
          value: a.pkRegAssociation.toString(),
          label: a.association.name
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

  const associationSubmissionEmails = submissionEmails.filter((s) =>
    s.regAssociations.some(
      (a) => a.pkRegAssociation.toString() === viewState.pkRegAssociation
    )
  );

  const submissionEmailInfo = submissionEmails.reduce(
    (r, c) => ({
      regEmailUnsubscribes: [
        ...r.regEmailUnsubscribes,
        ...c.regEmailUnsubscribes
      ],
      regFormSubmissions: [...r.regFormSubmissions, ...c.regFormSubmissions]
    }),
    {
      regEmailUnsubscribes: [],
      regFormSubmissions: []
    }
  );

  const subscribedAssociations = regAssociations.filter((a) =>
    submissionEmails
      .filter((e) =>
        e.regAssociations.some((s) => s.pkRegAssociation === a.pkRegAssociation)
      )
      .some(
        (e) =>
          !e.regEmailUnsubscribes.some(
            (f) => f.regAssociation.pkRegAssociation === a.pkRegAssociation
          )
      )
  );

  const unsubscribedAssociations = regAssociations.filter((a) =>
    submissionEmailInfo.regEmailUnsubscribes.some(
      (f) => f.regAssociation.pkRegAssociation === a.pkRegAssociation
    )
  );

  useEffect(() => {
    if (!isLoading && !viewState.pkRegAssociation) {
      setViewState({
        ...viewState,
        pkRegAssociation: regAssociationOptions[0]?.value,
        isLoading: false
      });
      hasInitialized.current = true;
    }
  }, [isLoading]);

  useEffect(() => {
    fetchRegistrationEmails();
    if (authState.userData.isRegistrationAdmin) {
      fetchAdminRegAssociationSentEmails();
    }
    hasFetched.current = true;
  }, []);

  useEffect(() => {
    if (viewState.activeTab !== ADMIN_TABS_ENUM.REGISTRATION) {
      const existingAssociation = regAssociationOptions.find(
        (f) => f.value === viewState.pkRegAssociation
      );
      const pkRegAssociation =
        existingAssociation?.value ?? regAssociationOptions[0]?.value;
      setViewState({
        ...viewState,
        pkRegAssociation,
        pageIndex: 1
      });

      if (fetchedPkRegAssociation.current !== pkRegAssociation) {
        fetchAdminRegAssociationEmailUnsubscribe(pkRegAssociation);
        fetchedPkRegAssociation.current = pkRegAssociation;
      }
    }
  }, [viewState.activeTab, viewState.pkRegAssociation]);

  const onDownloadUnsubscribeList = () => {
    const csv = Papa.unparse({
      fields: ['Email', 'Date'],
      data: [
        ...adminState.regEmailUnsubscribe.value.map((e) => [
          e.email,
          dayjs(formatUtcDate(e.createdAt)).format('MMM D, YYYY')
        ])
      ]
    });
    const blob = new Blob([csv]);
    const csvURL = URL.createObjectURL(blob, { type: 'text/plain' });
    const link = document.createElement('a');
    link.setAttribute('href', csvURL);
    link.setAttribute('download', `unsubscribed_emails_${Date.now()}.csv`);
    document.body.appendChild(link);
    link.click();
  };

  return (
    <HeaderView maxViewWidth={1000} viewProps={{ style: { paddingTop: 0 } }}>
      {hasInitialized.current ? (
        <AppStack style={{ gap: 5, padding: 5 }}>
          <AppFlexbox
            style={{
              alignItems: isMobileView ? 'start' : 'end',
              justifyContent: 'space-between',
              minHeight: isMobileView ? 'unset' : 60,

              gap: 0,
              flexDirection: isMobileView ? 'column' : 'row'
            }}
          >
            <AppText style={{ fontSize: 26 }} weight={700}>
              Communication
            </AppText>
            {viewState.activeTab === ADMIN_TABS_ENUM.UNSUBSCRIBED &&
              regAssociationOptions.length > 1 && (
                <AppFlexbox
                  style={{
                    flex: 1,
                    width: '100%',
                    maxWidth: isMobileView ? 'unset' : 300
                  }}
                >
                  <CustomSelect
                    data={regAssociationOptions}
                    disabled={regAssociationOptions.length === 0}
                    label="Association"
                    onChange={(v) => {
                      if (v) {
                        setViewState({
                          ...viewState,
                          pkRegAssociation: v,
                          pageIndex: 1
                        });
                      }
                    }}
                    style={{ flex: 1 }}
                    value={viewState.pkRegAssociation}
                  />
                </AppFlexbox>
              )}
          </AppFlexbox>
          <AppCard style={{ padding: 5 }}>
            <AppStack style={{ gap: 10 }}>
              {authState.userData.isRegistrationAdmin && (
                <>
                  <TabsContent
                    activeTab={viewState.activeTab}
                    color="blue"
                    onTabChange={(value) =>
                      setViewState({
                        ...viewState,
                        activeTab: value
                      })
                    }
                    tabActionButton={
                      viewState.activeTab === ADMIN_TABS_ENUM.UNSUBSCRIBED && (
                        <Button
                          disabled={
                            !fetchedPkRegAssociation.current ||
                            adminState.regEmailUnsubscribe.loading ||
                            adminState.regEmailUnsubscribe.value.length === 0
                          }
                          leftSection={<Download size={20} />}
                          onClick={onDownloadUnsubscribeList}
                          size="compact-md"
                          style={{ marginLeft: 'auto' }}
                          variant="outline"
                        >
                          Download
                        </Button>
                      )
                    }
                    tabs={ADMIN_TABS}
                    variant="outline"
                    visibleFrom="xs"
                  />
                  <AppStack hiddenFrom="xs" style={{ gap: 10 }}>
                    <Select
                      data={ADMIN_TABS}
                      label="View"
                      onChange={(value) =>
                        setViewState({
                          ...viewState,
                          activeTab: value
                        })
                      }
                      value={viewState.activeTab}
                    />
                    {viewState.activeTab === ADMIN_TABS_ENUM.UNSUBSCRIBED && (
                      <Button
                        disabled={
                          !fetchedPkRegAssociation.current ||
                          adminState.regEmailUnsubscribe.loading ||
                          adminState.regEmailUnsubscribe.value.length === 0
                        }
                        leftSection={<Download size={20} />}
                        onClick={onDownloadUnsubscribeList}
                        size="compact-md"
                        style={{ width: '100%' }}
                        variant="outline"
                      >
                        Download CSV
                      </Button>
                    )}
                  </AppStack>
                </>
              )}

              {viewState.activeTab === ADMIN_TABS_ENUM.EMAILS ? (
                !adminState.sesEmailSessions.loading ? (
                  <PaginationList
                    emptyMessage="No unsubscribed emails found."
                    items={adminState.sesEmailSessions.value
                      .sort(
                        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                      )
                      .map((s) => (
                        <AppFlexbox
                          key={s.pkSesEmailSession}
                          style={{
                            padding: 10,
                            alignItems: 'center',
                            overflow: 'hidden'
                          }}
                        >
                          <AppStack
                            style={{ flex: 1, gap: 0, overflow: 'hidden' }}
                          >
                            <AppText weight={500}>{s.subject}</AppText>
                            <AppText>
                              {s.sesEmails.filter((e) => e.sent).length}/
                              {s.sesEmails.length} emails sent
                            </AppText>
                            <AppText>
                              {dayjs(formatUtcDate(s.createdAt)).format(
                                'MM/DD/YYYY h:mm a'
                              )}
                            </AppText>
                          </AppStack>
                          <MenuContent
                            control={
                              <ActionIcon color="lightgrey" variant="subtle">
                                <Dots color="#000" />
                              </ActionIcon>
                            }
                          >
                            {[
                              {
                                label: 'View',
                                value: MENU_ACTIONS_ENUM.VIEW_EMAIL
                              }
                            ].map((a) => (
                              <Menu.Item
                                key={a.value}
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  onOpenModal(a.value, s);
                                }}
                              >
                                {a.label}
                              </Menu.Item>
                            ))}
                          </MenuContent>
                        </AppFlexbox>
                      ))}
                  />
                ) : (
                  <PaginationList
                    items={[
                      ...Array.from(Array(4)).map((x, i) => (
                        <AppFlexbox
                          // eslint-disable-next-line react/no-array-index-key
                          key={i}
                          style={{
                            padding: 10,
                            alignItems: 'center',
                            overflow: 'hidden'
                          }}
                        >
                          <AppStack
                            style={{ flex: 1, gap: 5, overflow: 'hidden' }}
                          >
                            <Skeleton height={22} width={300} />
                            <Skeleton height={22} width={200} />
                            <Skeleton height={22} width={150} />
                          </AppStack>
                          <Skeleton height={26} width={26} />
                        </AppFlexbox>
                      ))
                    ]}
                  />
                )
              ) : viewState.activeTab === ADMIN_TABS_ENUM.UNSUBSCRIBED ? (
                fetchedPkRegAssociation.current &&
                !adminState.regEmailUnsubscribe.loading ? (
                  <PaginationList
                    emptyMessage="No unsubscribed emails found."
                    items={adminState.regEmailUnsubscribe.value
                      .sort((a, b) => a.email.localeCompare(b.email))
                      .map((e) => (
                        <AppFlexbox
                          key={e.pkRegEmailUnsubscribe}
                          style={{
                            padding: 10,
                            alignItems: 'center',
                            overflow: 'hidden'
                          }}
                        >
                          <AppStack
                            style={{ flex: 1, gap: 0, overflow: 'hidden' }}
                          >
                            <AppText weight={500}>{e.email}</AppText>
                            <AppText style={{ color: 'red' }}>
                              Unsubscribed
                            </AppText>
                            <AppText>
                              {dayjs(formatUtcDate(e.createdAt)).format(
                                'MMM D, YYYY'
                              )}
                            </AppText>
                          </AppStack>
                        </AppFlexbox>
                      ))}
                  />
                ) : (
                  <PaginationList
                    items={[
                      ...Array.from(Array(4)).map((x, i) => (
                        <AppFlexbox
                          // eslint-disable-next-line react/no-array-index-key
                          key={i}
                          style={{ padding: 10, alignItems: 'center' }}
                        >
                          <AppStack style={{ flex: 1, gap: 5 }}>
                            <Skeleton height={22} width={300} />
                            <Skeleton height={22} width={200} />
                            <Skeleton height={22} width={150} />
                          </AppStack>
                        </AppFlexbox>
                      ))
                    ]}
                  />
                )
              ) : associationSubmissionEmails.length === 0 ? (
                <AppFlexbox
                  style={{
                    gap: 20,
                    flex: 1,
                    padding: '50px 10px',
                    border: 'solid 1px #ced4da',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <AppStack style={{ gap: 5 }}>
                    <AppText style={{ fontWeight: 500 }}>
                      No registrations found.
                    </AppText>
                    <Button
                      color="dark"
                      onClick={() => {
                        navigate('/leagues');
                      }}
                      style={{ width: '100%', maxWidth: 300 }}
                    >
                      Register Now!
                    </Button>
                  </AppStack>
                </AppFlexbox>
              ) : (
                <AppStack style={{ gap: 5 }}>
                  <AppFlexbox
                    style={{
                      gap: 10,
                      justifyContent: 'space-between',
                      alignItems: 'end'
                    }}
                  >
                    <AppText
                      style={{ fontSize: 20 }}
                      visibleFrom="sm"
                      weight={500}
                    >
                      {viewState.registrationsView ===
                      REGISTRATION_TABS_ENUM.UNSUBSCRIBED
                        ? 'Unsubscribed'
                        : 'Subscribed'}{' '}
                      Emails
                    </AppText>
                    <Button.Group
                      style={{
                        flex: 1,
                        maxWidth: isTabletViewOrSmaller ? 'unset' : 400
                      }}
                    >
                      {REGISTRATION_TABS.map((t) => {
                        const isSelected =
                          viewState.registrationsView === t.value;
                        return (
                          <Button
                            key={t.value}
                            color="dark"
                            onClick={() =>
                              setViewState({
                                ...viewState,
                                registrationsView: t.value
                              })
                            }
                            style={{ flex: 1 }}
                            variant={isSelected ? 'filled' : 'light'}
                          >
                            {t.label} (
                            {t.value === REGISTRATION_TABS_ENUM.SUBSCRIBED
                              ? subscribedAssociations.length
                              : unsubscribedAssociations.length}
                            )
                          </Button>
                        );
                      })}
                    </Button.Group>
                  </AppFlexbox>

                  <PaginationList
                    emptyMessage="No emails unsubscribed!"
                    items={(viewState.registrationsView ===
                    REGISTRATION_TABS_ENUM.UNSUBSCRIBED
                      ? unsubscribedAssociations
                      : subscribedAssociations
                    ).map((a) => {
                      const attachedEmails = submissionEmails.filter((e) =>
                        e.regAssociations.some(
                          (s) => s.pkRegAssociation === a.pkRegAssociation
                        )
                      );

                      const registrations = submissionEmailInfo.regFormSubmissions.filter(
                        (s) =>
                          s.regAssociationDivisionForm.regForm
                            .fkRegAssociation === a.pkRegAssociation
                      );

                      const unsubscribeEntries = submissionEmailInfo.regEmailUnsubscribes.filter(
                        (f) =>
                          f.regAssociation.pkRegAssociation ===
                          a.pkRegAssociation
                      );

                      return (
                        <AppFlexbox
                          key={a.pkRegAssociation}
                          style={{
                            padding: 10,
                            alignItems: 'center',
                            overflow: 'hidden',
                            justifyContent: 'space-between'
                          }}
                        >
                          <AppFlexbox style={{ gap: 10 }}>
                            <AppImage
                              fit="contain"
                              height={50}
                              src={a.logoImageUrl}
                              style={{ width: 50, height: 50 }}
                              width={50}
                            />
                            <AppStack
                              style={{ flex: 1, gap: 0, overflow: 'hidden' }}
                            >
                              <AppText weight={500}>
                                {a.association.name}
                              </AppText>
                              <AppText>
                                {attachedEmails.length === 1
                                  ? attachedEmails[0].email
                                  : `${attachedEmails.length} Emails`}
                              </AppText>
                              <AppText>
                                {singularPluralFormat(
                                  registrations.length,
                                  'Registration',
                                  'Registrations'
                                )}
                              </AppText>
                              <AppText
                                style={{
                                  color:
                                    viewState.registrationsView ===
                                    REGISTRATION_TABS_ENUM.UNSUBSCRIBED
                                      ? 'red'
                                      : 'green'
                                }}
                              >
                                {viewState.registrationsView ===
                                REGISTRATION_TABS_ENUM.UNSUBSCRIBED
                                  ? 'Unsubscribed'
                                  : 'Subscribed'}
                              </AppText>
                            </AppStack>
                          </AppFlexbox>

                          <MenuContent
                            control={
                              <ActionIcon color="lightgrey" variant="subtle">
                                <Dots color="#000" />
                              </ActionIcon>
                            }
                          >
                            {MENU_ACTIONS.filter((f) =>
                              f.isVisible(attachedEmails, unsubscribeEntries)
                            ).map((m) => (
                              <Menu.Item
                                key={m.value}
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  onOpenModal(m.value, a);
                                }}
                              >
                                {m.label}
                              </Menu.Item>
                            ))}
                          </MenuContent>
                        </AppFlexbox>
                      );
                    })}
                    onPageChange={(pageIndex) =>
                      setViewState({
                        ...viewState,
                        pageIndex
                      })
                    }
                    pageIndex={viewState.pageIndex}
                  />
                </AppStack>
              )}
            </AppStack>
          </AppCard>
        </AppStack>
      ) : (
        <AppStack style={{ gap: 5, padding: 5 }}>
          <AppFlexbox
            style={{
              alignItems: isMobileView ? 'start' : 'end',
              justifyContent: 'space-between',
              gap: 10,
              minHeight: isMobileView ? 'unset' : 60,

              flexDirection: isMobileView ? 'column' : 'row'
            }}
          >
            <Skeleton height={40} width={200} />
          </AppFlexbox>
          <AppCard style={{ padding: 5 }}>
            <AppStack style={{ gap: 10 }}>
              {authState.userData.isRegistrationAdmin && (
                <>
                  <TabsContent
                    activeTab={viewState.activeTab}
                    color="blue"
                    isLoading
                    onTabChange={() => {}}
                    tabs={ADMIN_TABS}
                    variant="outline"
                    visibleFrom="xs"
                  />
                  <AppStack hiddenFrom="xs" style={{ gap: 10 }}>
                    <AppStack style={{ gap: 2, flex: 1 }}>
                      <Skeleton height={22} width={100} />
                      <Skeleton height={36} width="100%" />
                    </AppStack>
                  </AppStack>
                </>
              )}
              <PaginationList
                items={[
                  ...Array.from(Array(1)).map((x, i) => (
                    <AppFlexbox
                      // eslint-disable-next-line react/no-array-index-key
                      key={i}
                      style={{
                        padding: 10,
                        alignItems: 'center',
                        overflow: 'hidden'
                      }}
                    >
                      <AppStack style={{ flex: 1, gap: 5, overflow: 'hidden' }}>
                        <Skeleton height={22} width={300} />
                        <Skeleton height={22} width={200} />
                        <Skeleton height={22} width={150} />
                      </AppStack>
                      <Skeleton height={26} width={26} />
                    </AppFlexbox>
                  ))
                ]}
              />
            </AppStack>
          </AppCard>
        </AppStack>
      )}

      <EmailMailingsModal
        isOpen={
          modalState.isOpen && modalState.action === MENU_ACTIONS_ENUM.VIEW
        }
        onClose={onCloseModal}
        regAssociation={
          modalState.action === MENU_ACTIONS_ENUM.VIEW ? modalState.item : null
        }
        regFormSubmissionEmails={submissionEmails}
      />

      <MailingSubscribeModal
        isOpen={
          modalState.isOpen &&
          (modalState.action === MENU_ACTIONS_ENUM.SUBSCRIBE ||
            modalState.action === MENU_ACTIONS_ENUM.UNSUBSCRIBE)
        }
        onClose={onCloseModal}
        regAssociation={
          modalState.action === MENU_ACTIONS_ENUM.SUBSCRIBE ||
          modalState.action === MENU_ACTIONS_ENUM.UNSUBSCRIBE
            ? modalState.item
            : null
        }
        regFormSubmissionEmails={submissionEmails}
      />

      <ViewEmailRecipientsModal
        isOpen={
          modalState.isOpen &&
          modalState.action === MENU_ACTIONS_ENUM.VIEW_EMAIL
        }
        onClose={onCloseModal}
        sesEmailSession={
          modalState.action === MENU_ACTIONS_ENUM.VIEW_EMAIL
            ? modalState.item
            : null
        }
      />
    </HeaderView>
  );
};

export default UserCommunicationView;
