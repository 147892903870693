import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { Context as RegistrationAdminDashboardContext } from '../../../providers/RegistrationAdminDashboardProvider';
import CustomSelect from '../../common/CustomSelect';

const AssociationSelectInput = ({
  label,
  value,
  onChange,
  autoPopulate,
  placeholder,
  data,
  preventUrlUpdate,
  disabled,
  ...rest
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { state } = useContext(RegistrationAdminContext);
  const { state: dashboardState, changeAssociation } = useContext(
    RegistrationAdminDashboardContext
  );
  const pkRegAssociationParam =
    searchParams.get('association') ||
    dashboardState.regAssociation.pkRegAssociation;
  const associationOptions = (data ?? state.regAssociations.value)
    .sort((a, b) => a.association.name.localeCompare(b.association.name))
    .map((a) => ({
      image: a.logoImageUrl,
      value: a.pkRegAssociation.toString(),
      label: a.association.name
    }));

  const selectValue = onChange
    ? value
    : dashboardState.regAssociation.value?.pkRegAssociation.toString();

  useEffect(() => {
    const pkRegAssociation =
      pkRegAssociationParam &&
      state.regAssociations.value.find(
        (a) => a.pkRegAssociation.toString() === pkRegAssociationParam
      )
        ? pkRegAssociationParam
        : associationOptions[0]?.value;
    if (!value && autoPopulate && pkRegAssociation) {
      if (onChange) {
        onChange(pkRegAssociation);
      }
      else {
        const regAssociation = state.regAssociations.value.find(
          (a) => a.pkRegAssociation.toString() === pkRegAssociation
        );
        changeAssociation({ pkRegAssociation, regAssociation });
      }
    }
  }, [pkRegAssociationParam, state.regAssociations.value]);

  return (
    <CustomSelect
      data={associationOptions}
      disabled={disabled || state.regAssociations.loading}
      label={label}
      onChange={(v) => {
        if (!preventUrlUpdate) {
          searchParams.set('association', v);
          setSearchParams(searchParams);
        }
        if (onChange) {
          onChange(v);
        }
        else {
          const regAssociation = state.regAssociations.value.find(
            (a) => a.pkRegAssociation.toString() === v
          );
          changeAssociation({
            pkRegAssociation: regAssociation?.pkRegAssociation,
            regAssociation
          });
        }
      }}
      placeholder={placeholder ?? 'Filter by association'}
      value={selectValue}
      {...rest}
    />
  );
};

AssociationSelectInput.propTypes = {
  autoPopulate: PropTypes.bool,
  data: PropTypes.array,
  disabled: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  preventUrlUpdate: PropTypes.bool,
  value: PropTypes.string
};

export default AssociationSelectInput;
