import React, { useContext, useEffect, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Alert, Checkbox, Divider, Select, TextInput } from '@mantine/core';
import NumberFormat from 'react-number-format';
import { InfoCircle } from 'tabler-icons-react';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import RegistrationListItem from './RegistrationListItem';
import { triggerNotification } from '../../../helpers/notificationHelper';
import FormSection from '../../common/FormSection';
import RefundTransactionOverview from './RefundTransactionOverview';
import { currencyFormat } from '../../../helpers/format';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppTitle from '../../common/AppTitle';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';

const RegistrationInvoiceRefundModal = ({
  isOpen,
  onClose,
  regAssociation,
  regFormSubmissionBalance,
  regFormSubmissionInvoice
}) => {
  const hasFetchedTransactionDetails = useRef(false);
  const {
    state,
    fetchAdminRegFormSubmissionInvoiceTransactionDetails,
    creditAdminRegistrationInvoice,
    fetchUpdatedAdminFinancialRegistration
  } = useContext(RegistrationAdminContext);
  const [formState, setFormState] = useState({
    fkRegFormSubmissionPaymentType: '',
    creditReason: '',
    creditAmount: 0,
    partialRefund: false,
    showConfirm: '',
    loading: false
  });
  const isLoading =
    !hasFetchedTransactionDetails.current ||
    !regFormSubmissionInvoice ||
    state.regFormSubmissionInvoiceTransactionDetails.loading ||
    !state.regFormSubmissionInvoiceTransactionDetails.value;
  const transactionDetails =
    state.regFormSubmissionInvoiceTransactionDetails.value;
  const invoiceBalance = regFormSubmissionBalance?.balance.invoices.find(
    (i) => i.entityId === regFormSubmissionInvoice?.pkRegFormSubmissionInvoice
  );
  const totals = invoiceBalance
    ? {
        totalCost: invoiceBalance.totalInCents / 100,
        amountPaid: invoiceBalance.amountPaidInCents / 100,
        refundedAmount: invoiceBalance.refundedAmountInCents / 100,
        availableAmount:
          regFormSubmissionInvoice.status !== 'PAID'
            ? (invoiceBalance.totalInCents -
                invoiceBalance.amountPaidInCents +
                invoiceBalance.refundedAmountInCents) /
              100
            : (invoiceBalance.amountPaidInCents -
                (transactionDetails?.paymentProviderTransaction
                  ? transactionDetails.paymentProviderTransaction
                      .serviceFeeInCents
                  : 0) -
                invoiceBalance.refundedAmountInCents) /
              100
      }
    : {};
  const currency = invoiceBalance?.currency?.toUpperCase();

  useEffect(() => {
    if (isOpen && regFormSubmissionInvoice) {
      fetchAdminRegFormSubmissionInvoiceTransactionDetails(
        regFormSubmissionInvoice.pkRegFormSubmissionInvoice,
        null,
        () => triggerNotification('Problem getting invoice details.')
      );
      hasFetchedTransactionDetails.current = true;

      setFormState({
        fkRegFormSubmissionPaymentType: '',
        creditReason: '',
        creditAmount: totals.availableAmount,
        partialRefund: false,
        showConfirm: '',
        loading: false
      });
    }
  }, [isOpen, regFormSubmissionInvoice]);

  useEffect(() => {
    if (transactionDetails) {
      setFormState({
        fkRegFormSubmissionPaymentType: '',
        creditReason: '',
        creditAmount: totals.availableAmount,
        partialRefund: false,
        showConfirm: '',
        loading: false
      });
    }
  }, [transactionDetails]);

  return (
    <ResponsiveModal
      isLoading={isLoading}
      onClose={onClose}
      opened={isOpen}
      size={600}
      title={
        <AppTitle order={2}>
          {regFormSubmissionInvoice?.status === 'PAID'
            ? 'Refund invoice'
            : 'Issue credit'}
        </AppTitle>
      }
    >
      {!isLoading &&
        (formState.showConfirm ? (
          <AppStack style={{ gap: 20 }}>
            <RegistrationListItem
              regAssociation={regAssociation}
              regFormSubmission={regFormSubmissionBalance}
            />
            <FormSection
              cancelTitle="Back"
              isLoading={formState.loading}
              onCancel={() => {
                setFormState({
                  ...formState,
                  showConfirm: false
                });
              }}
              onSubmit={() => {
                setFormState({
                  ...formState,
                  loading: true
                });
                creditAdminRegistrationInvoice(
                  regFormSubmissionInvoice.pkRegFormSubmissionInvoice,
                  {
                    creditAmount:
                      regFormSubmissionInvoice.status === 'PAID' &&
                      !formState.partialRefund
                        ? null
                        : formState.creditAmount,
                    creditReason: formState.creditReason,
                    isOfflineRefund: regFormSubmissionInvoice.wasPaidOffline
                  },
                  () => {
                    fetchUpdatedAdminFinancialRegistration(
                      regFormSubmissionBalance.pkRegFormSubmission
                    );
                    triggerNotification(
                      regFormSubmissionInvoice.status === 'PAID'
                        ? 'Invoice Refunded'
                        : 'Invoice Credited',
                      'Success',
                      'green'
                    );
                    onClose();
                  },
                  (e) => {
                    triggerNotification(e);
                    setFormState({
                      ...formState,
                      loading: false
                    });
                  }
                );
              }}
              style={{ gap: 30 }}
              submitColor={
                regFormSubmissionInvoice.status === 'PAID' ? 'red' : 'blue'
              }
              submitTitle={
                regFormSubmissionInvoice.status === 'PAID' ? 'Refund' : 'Credit'
              }
            >
              {regFormSubmissionInvoice.status !== 'PAID' ? (
                <Alert
                  icon={<InfoCircle style={{ width: 35, height: 35 }} />}
                  styles={{ icon: { marginTop: 15, width: 35 } }}
                  variant="outline"
                >
                  <AppStack style={{ gap: 20 }}>
                    <AppText style={{ marginTop: 12, fontSize: 16 }}>
                      Are you sure you want to apply the following <b>credit</b>
                      ?
                    </AppText>
                    <AppStack style={{ gap: 10 }}>
                      <AppFlexbox style={{ flexWrap: 'nowrap', gap: 10 }}>
                        <AppText style={{ fontSize: 16 }} weight={700}>
                          Invoice Amount
                        </AppText>
                        <Divider style={{ flex: 1 }} variant="dotted" />
                        <AppText
                          style={{ fontSize: 16, whiteSpace: 'nowrap' }}
                          weight={700}
                        >
                          <NumberFormat
                            decimalScale={2}
                            displayType="text"
                            fixedDecimalScale
                            prefix="$"
                            thousandSeparator
                            value={totals.totalCost}
                          />{' '}
                          {currency}
                        </AppText>
                      </AppFlexbox>
                      <Divider />
                      <AppFlexbox style={{ flexWrap: 'nowrap', gap: 10 }}>
                        <AppText style={{ fontSize: 16 }}>
                          Credit Amount
                        </AppText>
                        <Divider style={{ flex: 1 }} variant="dotted" />
                        <AppText
                          color={
                            formState.fkRegFormSubmissionPaymentType === '5'
                              ? 'red'
                              : 'green'
                          }
                          style={{ fontSize: 16, whiteSpace: 'nowrap' }}
                        >
                          <NumberFormat
                            decimalScale={2}
                            displayType="text"
                            fixedDecimalScale
                            prefix="$"
                            thousandSeparator
                            value={formState.creditAmount}
                          />{' '}
                          {currency}
                        </AppText>
                      </AppFlexbox>
                      <Divider />
                      <AppFlexbox style={{ flexWrap: 'nowrap', gap: 10 }}>
                        <AppText style={{ fontSize: 16 }} weight={700}>
                          New Invoice Amount
                        </AppText>
                        <Divider style={{ flex: 1 }} variant="dotted" />
                        <AppText
                          style={{ fontSize: 16, whiteSpace: 'nowrap' }}
                          weight={700}
                        >
                          <NumberFormat
                            decimalScale={2}
                            displayType="text"
                            fixedDecimalScale
                            prefix="$"
                            thousandSeparator
                            value={totals.totalCost - formState.creditAmount}
                          />{' '}
                          {currency}
                        </AppText>
                      </AppFlexbox>
                    </AppStack>
                  </AppStack>
                </Alert>
              ) : (
                <>
                  <Divider />
                  <AppText style={{ textAlign: 'center' }}>
                    Are you sure you want to <b>Refund</b> the selected
                    registration?
                  </AppText>
                </>
              )}
            </FormSection>
          </AppStack>
        ) : (
          <FormSection
            isLoading={formState.loading}
            onCancel={onClose}
            onSubmit={() => {
              setFormState({
                ...formState,
                showConfirm: true
              });
            }}
            style={{ gap: 30 }}
            submitColor={
              regFormSubmissionInvoice.status === 'PAID' ? 'red' : 'blue'
            }
            submitTitle={
              regFormSubmissionInvoice.status === 'PAID' ? 'Refund' : 'Credit'
            }
          >
            {regFormSubmissionInvoice.status === 'PAID' &&
              (transactionDetails.paymentProviderTransaction ? (
                <RefundTransactionOverview
                  amountRefunded={totals.refundedAmount}
                  currency={currency}
                  isInvoicePayment
                  serviceFee={
                    transactionDetails.paymentProviderTransaction
                      .serviceFeeInCents / 100
                  }
                  transactionTotal={
                    transactionDetails.paymentProviderTransaction
                      .amountInCents / 100
                  }
                />
              ) : (
                <RefundTransactionOverview
                  amountRefunded={totals.refundedAmount}
                  currency={currency}
                  isInvoicePayment
                  serviceFee={0}
                  transactionTotal={totals.totalCost}
                />
              ))}

            <AppStack style={{ gap: 20 }}>
              <Select
                clearable
                data={[
                  {
                    label: 'Duplicate',
                    value: 'duplicate'
                  },
                  {
                    label: 'Fraudulent',
                    value: 'fraudulent'
                  },
                  {
                    label: 'Order Change',
                    value: 'order_change'
                  },
                  {
                    label: 'Product Unsatisfactory',
                    value: 'product_unsatisfactory'
                  },
                  {
                    label: 'Other',
                    value: ''
                  }
                ]}
                label="Reason"
                onChange={(value) =>
                  setFormState({
                    ...formState,
                    creditReason: value
                  })
                }
                placeholder="Select a reason..."
                required
                searchable
                value={formState.creditReason}
              />
              {regFormSubmissionInvoice.status === 'PAID' && (
                <Checkbox
                  checked={formState.partialRefund}
                  disabled={formState.loading}
                  label="Partial Refund"
                  onChange={(e) => {
                    setFormState({
                      ...formState,
                      partialRefund: e.currentTarget.checked
                    });
                  }}
                  styles={{ label: { fontWeight: 500 } }}
                />
              )}
              {(regFormSubmissionInvoice.status !== 'PAID' ||
                formState.partialRefund) && (
                <AppStack style={{ gap: 0 }}>
                  <AppFlexbox style={{ gap: 5 }}>
                    <AppText style={{ fontSize: 14 }} weight={500}>
                      {regFormSubmissionInvoice.status === 'PAID'
                        ? 'Refund Amount'
                        : 'Credit Amount'}
                    </AppText>
                    <AppText
                      style={{ color: '#f03e3e', fontSize: 14 }}
                      weight={500}
                    >
                      *
                    </AppText>
                  </AppFlexbox>

                  <AppText style={{ fontSize: 14 }} weight={400}>
                    Invoice Total:{' '}
                    <NumberFormat
                      decimalScale={2}
                      displayType="text"
                      fixedDecimalScale
                      prefix="$"
                      thousandSeparator
                      value={totals.availableAmount}
                    />{' '}
                    {currency}
                  </AppText>
                  <NumberFormat
                    allowNegative={false}
                    customInput={TextInput}
                    decimalScale={2}
                    disabled={formState.loading}
                    fixedDecimalScale
                    onValueChange={(e) => {
                      setFormState({
                        ...formState,
                        creditAmount:
                          e.value > totals.availableAmount
                            ? totals.availableAmount
                            : e.value
                      });
                    }}
                    placeholder={`${currencyFormat(totals.availableAmount)}`}
                    prefix="$"
                    required
                    rightSection={
                      currency && (
                        <AppText
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                            color: '#999',
                            paddingRight: 5
                          }}
                        >
                          {currency}
                        </AppText>
                      )
                    }
                    style={{ flex: 1 }}
                    thousandSeparator
                    value={formState.creditAmount}
                  />
                </AppStack>
              )}
            </AppStack>
          </FormSection>
        ))}
    </ResponsiveModal>
  );
};

RegistrationInvoiceRefundModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  regAssociation: PropTypes.object,
  regFormSubmissionBalance: PropTypes.object,
  regFormSubmissionInvoice: PropTypes.object
};

export default RegistrationInvoiceRefundModal;
