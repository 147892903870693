import React, { useContext, useEffect } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import CheckoutCart from '../components/content/checkout/CheckoutCart';
import { Context as RegistrationContext } from '../providers/RegistrationProvider';
import CheckoutWaitlist from '../components/content/checkout/CheckoutWaitlist';
import CheckoutInvite from '../components/content/checkout/CheckoutInvite';
import CheckoutExternalInvite from '../components/content/checkout/CheckoutExternalInvite';
import CheckoutInvoices from '../components/content/checkout/CheckoutInvoices';

const CheckoutView = () => {
  const { search } = useLocation();
  const { fetchCart } = useContext(RegistrationContext);
  const urlCodeParam = new URLSearchParams(search).get('code');

  useEffect(() => {
    fetchCart();
  }, []);

  return (
    <Routes>
      <Route element={<CheckoutCart />} path="/cart" />
      <Route element={<CheckoutWaitlist />} path="/:uuid" />
      <Route
        element={
          urlCodeParam ? (
            <CheckoutExternalInvite inviteCode={urlCodeParam} />
          ) : (
            <CheckoutInvite />
          )
        }
        path="/invite"
      />
      <Route element={<CheckoutInvoices />} path="/invoices/:invoiceId" />
      <Route element={<Navigate replace to="/checkout/cart" />} path="*" />
    </Routes>
  );
};

export default CheckoutView;
