import React, { useContext, useEffect, useRef } from 'react';
import { Button, useMantineTheme } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { ShoppingCart } from 'tabler-icons-react';
import { Context as AuthContext } from '../../../providers/AuthProvider';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import sportsHeadzLogo from '../../../images/sportsheadz.png';
import { links } from './links';
import AppFlexbox from '../../common/AppFlexbox';
import AppLink from '../../common/AppLink';
import AppImage from '../../common/AppImage';
import ActionableIcon from '../../common/ActionableIcon';
import Drawer from './Drawer';
import { getRegAssociationAdminPermissions } from '../../../helpers/adminHelper';
import { REG_PERMISSION_ENUM } from '../../../config/constants';
import HeaderAccountNavigationMenu from './HeaderAccountNavigationMenu';

const Header = () => {
  const theme = useMantineTheme();
  const hasFetchedAssociation = useRef(false);
  const { state } = useContext(AuthContext);
  const { state: regState } = useContext(RegistrationContext);
  const { pathname } = useLocation();
  let headerImage = sportsHeadzLogo;
  let lightMode = false;
  if (
    /(\/association\/)\d+/.test(pathname) &&
    !pathname.includes('branding') &&
    hasFetchedAssociation.current &&
    !regState.regAssociation.loading &&
    (!regState.regAssociation.value?.sandboxEnabled ||
      state.userData?.isRegistrationAdmin)
  ) {
    headerImage =
      regState.regAssociation.value?.logoImageUrl ?? sportsHeadzLogo;
    lightMode = regState.regAssociation.value?.lightModeTheme ?? false;
  }
  const textColor = lightMode ? '#000' : '#FFF';

  const adminPermissions = getRegAssociationAdminPermissions(
    state.regAssociationAdmins.value
  );
  const adminLinks = links.admin.filter(
    (link) =>
      adminPermissions.includes(REG_PERMISSION_ENUM.SUPER_ADMIN) ||
      link.permissions.some((p) => adminPermissions.includes(p))
  );

  useEffect(() => {
    hasFetchedAssociation.current = false;
  }, [pathname]);

  useEffect(() => {
    if (regState.regAssociation.loading) {
      hasFetchedAssociation.current = true;
    }
  }, [regState.regAssociation.loading]);

  return (
    <AppFlexbox
      style={{
        flex: 1,
        height: '100%',
        padding: '0 30px',
        borderColor: lightMode ? theme.colors.gray[5] : theme.colors.dark[7],
        boxShadow: `0 2px 2px 0 ${
          lightMode ? theme.colors.gray[5] : theme.colors.dark[7]
        }`,
        justifyContent: 'space-between',
        placeItems: 'center',
        backgroundColor: lightMode ? theme.white : theme.black
      }}
    >
      <AppFlexbox style={{ flex: 1, margin: 'auto 0' }} visibleFrom="sm">
        {links.public.map((link) => (
          <AppLink
            key={link.to}
            color={textColor}
            component={Link}
            isActive={link.isSelected(pathname)}
            label={link.title}
            to={link.to}
          />
        ))}
      </AppFlexbox>

      <AppFlexbox component={Link} to="/">
        <AppImage
          alt="SportsHeadz Main Logo"
          fit="contain"
          height={80}
          src={headerImage}
          style={{ minWidth: 'unset', maxWidth: 125 }}
          width="100%"
        />
      </AppFlexbox>

      <AppFlexbox
        style={{
          flex: 1,
          justifyContent: 'flex-end',
          placeItems: 'center',
          gap: 25
        }}
      >
        {state.isAuthenticated && (
          <ActionableIcon
            component={Link}
            indicatorValue={
              !regState.regCart.value ||
              regState.regCart.value.cart.lineItems.length === 0
                ? null
                : regState.regCart.value.cart.lineItems.length
            }
            to={state.isAuthenticated ? '/checkout/cart' : '/auth/login'}
          >
            <ShoppingCart color={textColor} size={30} />
          </ActionableIcon>
        )}
        <AppFlexbox visibleFrom="sm">
          {state.isAuthenticated ? (
            <HeaderAccountNavigationMenu
              adminLinks={adminLinks}
              lightMode={lightMode}
            />
          ) : (
            <Button
              component={Link}
              radius="xs"
              size="md"
              style={{
                color: lightMode ? theme.black : theme.white,
                borderColor: lightMode ? theme.colors.gray[4] : theme.white
              }}
              to="/auth/login"
              variant="outline"
            >
              Sign In
            </Button>
          )}
        </AppFlexbox>
        <AppFlexbox hiddenFrom="sm">
          <Drawer
            adminLinks={adminLinks}
            lightMode={lightMode}
            textColor={textColor}
          />
        </AppFlexbox>
      </AppFlexbox>
    </AppFlexbox>
  );
};

export default Header;
