import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import FormSection from '../../common/FormSection';
import { triggerNotification } from '../../../helpers/notificationHelper';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppTitle from '../../common/AppTitle';

const SyncDivisionTeamsModal = ({
  isOpen,
  onClose,
  regAssociationSeason,
  regAssociationDivision,
  regAssociationDivisionTeam
}) => {
  const {
    syncRegAssociationSeasonTeams,
    fetchRegAssociationDivisionTeams
  } = useContext(RegistrationAdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const division =
    regAssociationDivision ||
    regAssociationDivisionTeam?.regAssociationDivision;

  useEffect(() => {
    if (isOpen) {
      setIsLoading(false);
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      centered
      onClose={onClose}
      opened={isOpen}
      title={
        <AppTitle order={2}>
          {regAssociationDivisionTeam ? 'Launch Team' : 'Launch Team(s)'}
        </AppTitle>
      }
    >
      <FormSection
        isLoading={isLoading}
        onCancel={onClose}
        onSubmit={() => {
          setIsLoading(true);
          if (division) {
            syncRegAssociationSeasonTeams(
              division.fkRegAssociationSeason,
              {
                pkRegAssociationDivisionTeam:
                  regAssociationDivisionTeam?.pkRegAssociationDivisionTeam,
                pkRegAssociationDivisions: [division.pkRegAssociationDivision]
              },
              () => {
                if (regAssociationDivisionTeam && division) {
                  fetchRegAssociationDivisionTeams({
                    pkRegAssociation:
                      division.regAssociationSeason.fkRegAssociation
                  });
                }

                onClose();
              },
              (e) => {
                triggerNotification(e);
                setIsLoading(false);
              }
            );
          }
          else {
            syncRegAssociationSeasonTeams(
              regAssociationSeason.pkRegAssociationSeason,
              {},
              onClose,
              (e) => {
                triggerNotification(e);
                setIsLoading(false);
              }
            );
          }
        }}
        submitTitle="Launch"
      >
        <AppStack style={{ gap: 10, marginTop: 20 }}>
          {regAssociationDivisionTeam ? (
            <AppText style={{ textAlign: 'center' }}>
              Are you sure you want to launch the selected team?
            </AppText>
          ) : (
            <AppText style={{ textAlign: 'center' }}>
              Are you sure you want to launch all the teams for this{' '}
              {regAssociationDivision ? 'division' : 'season'}?
            </AppText>
          )}
        </AppStack>
      </FormSection>
    </ResponsiveModal>
  );
};

SyncDivisionTeamsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  regAssociationDivision: PropTypes.object,
  regAssociationDivisionTeam: PropTypes.object,
  regAssociationSeason: PropTypes.object
};

export default SyncDivisionTeamsModal;
