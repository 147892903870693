import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import {
  Button,
  Menu,
  Skeleton,
  Table,
  Tooltip,
  UnstyledButton
} from '@mantine/core';
import dayjs from 'dayjs';
import NumberFormat from 'react-number-format';
import { ChevronDown, InfoCircle } from 'tabler-icons-react';
import { Context as AuthContext } from '../../../providers/AuthProvider';
import {
  CURRENCY_TYPE_LIST,
  OFFLINE_PAYMENT_TYPES
} from '../../../config/constants';
import { formatUtcDate } from '../../../helpers/format';
import MenuContent from '../../common/MenuContent';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';

const FinancialRegistrationTransactionListItem = ({
  onAction,
  isActionable,
  isRefundable,
  regFormSubmissionPayment,
  paidOnline,
  chargeId
}) => {
  const { state: authState } = useContext(AuthContext);
  const offlinePayment = OFFLINE_PAYMENT_TYPES.find(
    (t) =>
      t.value ===
      regFormSubmissionPayment?.fkRegFormSubmissionPaymentType.toString()
  );
  const invoicePayments =
    regFormSubmissionPayment?.regFormSubmissionInvoice?.regFormSubmissionPayments.filter(
      (p) => p.fkRegFormSubmissionPaymentType !== 6
    ) ?? [];
  const hasInvoicePayments = invoicePayments.length > 0;

  const invoiceAmountPaid = hasInvoicePayments
    ? invoicePayments.reduce((partialSum, p) => partialSum + p.amount, 0)
    : 0;
  const hasInvoiceRefundPayment = invoicePayments.find(
    (p) => p.fkRegFormSubmissionPaymentType === 5
  );
  const isPartialRefund = hasInvoiceRefundPayment && invoiceAmountPaid > 0;
  const currency = CURRENCY_TYPE_LIST.find(
    (c) =>
      c.value.toString() ===
      regFormSubmissionPayment?.fkCurrencyType?.toString()
  )?.abbr;

  return regFormSubmissionPayment ? (
    <Table.Tr key={regFormSubmissionPayment.pkRegFormSubmissionPayment}>
      <Table.Td>
        {offlinePayment.value === '6' ? (
          <Tooltip
            disabled={!hasInvoicePayments}
            label="View Payments"
            withArrow
            withinPortal
          >
            <UnstyledButton disabled={!hasInvoicePayments}>
              <AppFlexbox
                onClick={() => {
                  if (hasInvoicePayments) {
                    onAction('INVOICE-PAYMENTS');
                  }
                }}
                style={{ gap: 5, alignItems: 'center' }}
              >
                <AppText
                  color={
                    hasInvoiceRefundPayment ||
                    regFormSubmissionPayment.regFormSubmissionInvoice.status ===
                      'VOID'
                      ? 'red'
                      : regFormSubmissionPayment.regFormSubmissionInvoice
                          .status === 'PAID'
                      ? 'green'
                      : regFormSubmissionPayment.regFormSubmissionInvoice
                          .status === 'UNCOLLECTIBLE'
                      ? 'yellow'
                      : 'black'
                  }
                  style={{ whiteSpace: 'nowrap', wordBreak: 'normal' }}
                >
                  {isPartialRefund
                    ? 'Partial Refund'
                    : hasInvoiceRefundPayment
                    ? 'Refunded'
                    : `${regFormSubmissionPayment.regFormSubmissionInvoice.status.charAt(
                        0
                      )}${regFormSubmissionPayment.regFormSubmissionInvoice.status
                        .slice(1)
                        .toLowerCase()}`}
                </AppText>
                {hasInvoicePayments && (
                  <InfoCircle color="dodgerblue" size={16} />
                )}
              </AppFlexbox>
            </UnstyledButton>
          </Tooltip>
        ) : (
          <AppText
            color={offlinePayment.value === '5' ? 'red' : 'green'}
            style={{ whiteSpace: 'nowrap', wordBreak: 'normal' }}
          >
            {offlinePayment.value === '5' ? 'Refund' : 'Paid'}
          </AppText>
        )}
      </Table.Td>
      <Table.Td>
        <AppText style={{ whiteSpace: 'nowrap', wordBreak: 'normal' }}>
          {dayjs(formatUtcDate(regFormSubmissionPayment.createdAt)).format(
            'MM/DD/YYYY'
          )}
        </AppText>
      </Table.Td>
      <Table.Td>
        <AppText style={{ wordBreak: 'normal' }}>
          {regFormSubmissionPayment.regFormSubmissionInvoice
            ? regFormSubmissionPayment.regFormSubmissionInvoice
                .stripeInvoiceId ??
              regFormSubmissionPayment.regFormSubmissionInvoice.description
            : regFormSubmissionPayment.note ?? chargeId}
        </AppText>
      </Table.Td>
      <Table.Td>
        <AppText style={{ whiteSpace: 'nowrap', wordBreak: 'normal' }}>
          {offlinePayment.label}
        </AppText>
      </Table.Td>
      <Table.Td>
        <AppText style={{ whiteSpace: 'nowrap', wordBreak: 'normal' }}>
          {(regFormSubmissionPayment.regFormSubmissionInvoice
            ?.stripeInvoiceId &&
            offlinePayment.value === '6') ||
          (offlinePayment.value === '5' && paidOnline) ||
          offlinePayment.value === '7' ||
          offlinePayment.value === '8' ||
          offlinePayment.value === '10' ||
          offlinePayment.value === '11'
            ? 'Card'
            : offlinePayment.value === '4'
            ? 'Assistance'
            : 'Offline'}
        </AppText>
      </Table.Td>
      <Table.Td style={{ borderRight: 'solid 1px #ced4da' }}>
        <AppText
          color={
            offlinePayment.value === '6' &&
            regFormSubmissionPayment.regFormSubmissionInvoice.status !== 'PAID'
              ? '#000'
              : regFormSubmissionPayment.amount < 0 ||
                hasInvoiceRefundPayment ||
                offlinePayment.value === '5'
              ? 'red'
              : 'green'
          }
          style={{
            textAlign: 'end',
            whiteSpace: 'nowrap',
            wordBreak: 'normal',
            textDecoration:
              offlinePayment.value === '6' &&
              regFormSubmissionPayment.regFormSubmissionInvoice.status ===
                'VOID'
                ? 'line-through'
                : 'none'
          }}
        >
          <NumberFormat
            decimalScale={2}
            displayType="text"
            fixedDecimalScale
            prefix="$"
            thousandSeparator
            value={regFormSubmissionPayment.amount}
          />{' '}
          {currency}
        </AppText>
      </Table.Td>
      {isActionable && (
        <Table.Td style={{ textAlign: 'center' }}>
          {offlinePayment.value === '6' &&
          regFormSubmissionPayment.regFormSubmissionInvoice?.status !==
            'VOID' ? (
            <MenuContent
              control={
                <Button
                  color="blue"
                  rightSection={<ChevronDown />}
                  size="xs"
                  style={{ width: '100%' }}
                  variant="outline"
                >
                  Actions
                </Button>
              }
            >
              {hasInvoicePayments && (
                <Menu.Item onClick={() => onAction('INVOICE-PAYMENTS')}>
                  View Payments
                </Menu.Item>
              )}

              {regFormSubmissionPayment.regFormSubmissionInvoice?.status !==
                'PAID' &&
                regFormSubmissionPayment.regFormSubmissionInvoice?.status !==
                  'VOID' && (
                  <Menu.Item onClick={() => onAction('INVOICE-STATUS')}>
                    Update Status
                  </Menu.Item>
                )}

              {(!hasInvoiceRefundPayment || isPartialRefund) &&
                regFormSubmissionPayment.amount > 0 && (
                  <Menu.Item onClick={() => onAction('INVOICE-CREDIT')}>
                    {regFormSubmissionPayment.regFormSubmissionInvoice
                      ?.status === 'PAID'
                      ? 'Refund'
                      : 'Issue Credit'}
                  </Menu.Item>
                )}

              {(regFormSubmissionPayment.regFormSubmissionInvoice
                ?.stripeInvoiceId ||
                regFormSubmissionPayment.regFormSubmissionInvoice?.status !==
                  'PAID') && (
                <Menu.Item onClick={() => onAction('INVOICE-RESEND')}>
                  {regFormSubmissionPayment.regFormSubmissionInvoice?.status ===
                  'PAID'
                    ? 'Resend Receipt'
                    : regFormSubmissionPayment.regFormSubmissionInvoice
                        ?.stripeInvoiceId
                    ? 'Resend Invoice'
                    : 'Send Invoice'}
                </Menu.Item>
              )}

              {authState.userData?.isAdmin &&
                regFormSubmissionPayment.regFormSubmissionInvoice &&
                regFormSubmissionPayment.regFormSubmissionInvoice
                  .isAdditionalCharge && (
                  <Menu.Item onClick={() => onAction('REBASE')}>
                    Rebase to current cost
                  </Menu.Item>
                )}
            </MenuContent>
          ) : (
            isRefundable &&
            offlinePayment.value !== '5' &&
            offlinePayment.value !== '7' &&
            !regFormSubmissionPayment.regFormSubmissionInvoice && (
              <Button
                color="red"
                onClick={() => onAction('REFUND')}
                size="xs"
                style={{ width: '100%' }}
                variant="outline"
              >
                Refund
              </Button>
            )
          )}
        </Table.Td>
      )}
    </Table.Tr>
  ) : (
    <Table.Tr>
      <Table.Td>
        <Skeleton height={10} width={60} />
      </Table.Td>
      <Table.Td>
        <Skeleton height={10} width={100} />
      </Table.Td>
      <Table.Td>
        <Skeleton height={10} width={125} />
      </Table.Td>
      <Table.Td>
        <Skeleton height={10} width={80} />
      </Table.Td>
      <Table.Td>
        <Skeleton height={10} width={80} />
      </Table.Td>
      <Table.Td style={{ borderRight: 'solid 1px #ced4da' }}>
        <Skeleton height={10} style={{ alignSelf: 'end' }} width={80} />
      </Table.Td>
      {isActionable && (
        <Table.Td style={{ textAlign: 'center' }}>
          <Skeleton height={24} width={60} />
        </Table.Td>
      )}
    </Table.Tr>
  );
};

FinancialRegistrationTransactionListItem.propTypes = {
  chargeId: PropTypes.string,
  isActionable: PropTypes.bool,
  isRefundable: PropTypes.bool,
  onAction: PropTypes.func,
  paidOnline: PropTypes.bool,
  regFormSubmissionPayment: PropTypes.object
};

export default FinancialRegistrationTransactionListItem;
