import React from 'react';
import PropTypes from 'prop-types';
import { Button, Menu, Skeleton } from '@mantine/core';
import { ChevronDown } from 'tabler-icons-react';
import { useHover } from '@mantine/hooks';
import MenuContent from './MenuContent';
import AppFlexbox from './AppFlexbox';
import AppStack from './AppStack';
import AppText from './AppText';
import AppImage from './AppImage';
import { useMediaQueryIndex } from '../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../helpers/styles';

const ActionableListItem = ({
  label,
  subLabel,
  subLabelColor,
  extraLabels,
  showImage,
  showIndicator,
  image,
  description,
  subDescription,
  extraDescription,
  isLargeDescription,
  descriptionTextAlign,
  badge,
  menuActions,
  buttonColor,
  buttonVariant,
  showMenuSpacer,
  onAction,
  onClick,
  indicator,
  reverseMiddleColumns,
  centerBadge,
  hideBadgeLoader,
  hideDescriptionLoader,
  hideButtonLoader,
  alwaysShowBadge,
  isLoading
}) => {
  const { hovered, ref } = useHover();
  const mqIndex = useMediaQueryIndex();

  const renderTextInfo = ({ text, weight, color, wrapText }) => (
    <AppText
      style={{
        color,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineHeight: '20px',
        fontSize: 14,
        ...(wrapText
          ? {
              display: '-webkit-box',
              WebkitLineClamp: '2',
              lineClamp: '2',
              WebkitBoxOrient: 'vertical'
            }
          : {})
      }}
      weight={weight ?? 'normal'}
    >
      {text}
    </AppText>
  );

  return !isLoading ? (
    <AppFlexbox
      ref={ref}
      onClick={onClick}
      style={{
        flex: 1,
        padding: onClick && hovered ? 9 : 10,
        gap: 10,
        cursor: onClick ? 'pointer' : 'unset',
        border: onClick && hovered ? 'solid 1px #b9bfc4' : 'none'
      }}
    >
      {showImage && (
        <AppImage
          fit="contain"
          height={50}
          src={image}
          style={{
            alignSelf: 'start',
            margin: rs([0, 0, 'auto 0px'], mqIndex),
            display: rs(['none', 'block'], mqIndex)
          }}
          width={50}
        />
      )}
      {indicator && (
        <AppFlexbox
          style={{ paddingRight: rs([5, 0], mqIndex), placeItems: 'center' }}
        >
          {indicator}
        </AppFlexbox>
      )}
      <AppFlexbox
        style={{
          flex: 1,
          alignSelf: 'stretch',
          alignItems: 'center',
          flexDirection: rs(
            ['column', badge ? 'column' : 'row', 'row'],
            mqIndex
          ),
          justifyContent: 'space-between',
          gap: rs([0, 5, 15], mqIndex),
          overflow: 'hidden'
        }}
      >
        <AppFlexbox
          style={{
            flex: rs(
              [
                'unset',
                badge ? 'unset' : isLargeDescription ? 3 : 2,
                isLargeDescription ? 3 : 2
              ],
              mqIndex
            ),
            alignSelf: 'stretch',
            justifyContent: 'center',
            overflow: 'hidden',
            gap: rs([0, 5, 15], mqIndex),
            flexDirection: rs(['column', 'row'], mqIndex)
          }}
        >
          <AppStack
            style={{
              flex: rs(['unset', 1], mqIndex),
              gap: 0,
              justifyContent: 'center',
              overflow: 'hidden',
              whiteSpace: 'nowrap'
            }}
          >
            {label && renderTextInfo({ text: label, weight: 500 })}
            {subLabel &&
              renderTextInfo({ text: subLabel, color: subLabelColor })}
            {extraLabels &&
              extraLabels.length > 0 &&
              extraLabels.map((e, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={i}>
                  {typeof e === 'string' ? renderTextInfo({ text: e }) : e}
                </React.Fragment>
              ))}
          </AppStack>

          {reverseMiddleColumns ? (
            <AppStack
              style={{
                flex: rs(['unset', 1], mqIndex),
                textAlign: 'center',
                justifyContent: 'center',
                alignSelf: 'stretch',
                display: alwaysShowBadge
                  ? 'flex'
                  : rs(['none', 'flex'], mqIndex),
                width: rs(['100%', '100%', 'unset'], mqIndex),
                placeItems: rs(
                  [
                    'start',
                    badge ? 'end' : descriptionTextAlign ?? 'start',
                    descriptionTextAlign ?? 'start'
                  ],
                  mqIndex
                )
              }}
            >
              {badge}
            </AppStack>
          ) : (
            description && (
              <AppStack
                style={{
                  flex: rs(['unset', isLargeDescription ? 2 : 1], mqIndex),
                  gap: 0,
                  justifyContent: 'center',
                  overflow: 'hidden',
                  placeItems: rs(
                    [
                      'start',
                      badge ? 'end' : descriptionTextAlign ?? 'start',
                      descriptionTextAlign ?? 'start'
                    ],
                    mqIndex
                  )
                }}
              >
                {description &&
                  renderTextInfo({
                    text: description,
                    weight: 500,
                    wrapText: true
                  })}
                {subDescription && renderTextInfo({ text: subDescription })}
                {extraDescription &&
                  extraDescription.length > 0 &&
                  extraDescription.map((e, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <React.Fragment key={i}>
                      {typeof e === 'string' ? renderTextInfo({ text: e }) : e}
                    </React.Fragment>
                  ))}
              </AppStack>
            )
          )}
        </AppFlexbox>

        <AppFlexbox
          style={{
            flex:
              (reverseMiddleColumns && (!description || !onAction)) ||
              (!reverseMiddleColumns && !badge)
                ? 'unset'
                : 2,
            alignSelf: 'stretch',
            justifyContent: 'center',
            gap: rs([5, 5, 15], mqIndex),
            flexDirection: rs(['column', 'row'], mqIndex)
          }}
        >
          {!reverseMiddleColumns ? (
            <AppStack
              style={{
                flex: rs(['unset', 1], mqIndex),
                textAlign: 'center',
                justifyContent: 'center',
                alignSelf: 'stretch',
                display: alwaysShowBadge
                  ? 'flex'
                  : rs(['none', 'flex'], mqIndex),
                width: rs(['100%', '100%', 'unset'], mqIndex),
                placeItems: rs(
                  ['start', 'start', descriptionTextAlign ?? 'start'],
                  mqIndex
                )
              }}
            >
              {badge}
            </AppStack>
          ) : (
            description && (
              <AppStack
                style={{
                  flex: rs(['unset', isLargeDescription ? 2 : 1], mqIndex),
                  gap: 0,
                  justifyContent: 'center',
                  overflow: 'hidden',
                  placeItems: rs(
                    ['start', 'start', descriptionTextAlign ?? 'start'],
                    mqIndex
                  )
                }}
              >
                {description &&
                  renderTextInfo({
                    text: description,
                    weight: 500,
                    wrapText: true
                  })}
                {subDescription && renderTextInfo({ text: subDescription })}
                {extraDescription &&
                  extraDescription.length > 0 &&
                  extraDescription.map((e, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <React.Fragment key={i}>
                      {typeof e === 'string' ? renderTextInfo({ text: e }) : e}
                    </React.Fragment>
                  ))}
              </AppStack>
            )
          )}

          {onAction && menuActions.length > 0 ? (
            <AppStack
              style={{
                flex: rs(['unset', 1], mqIndex),
                alignSelf: 'stretch',
                justifyContent: 'center',
                placeItems: 'end',
                textAlign: 'end'
              }}
            >
              {menuActions.length === 1 ? (
                <Button
                  color={buttonColor ?? 'blue'}
                  onClick={(e) => {
                    e.stopPropagation();
                    onAction(menuActions[0].value);
                  }}
                  size="compact-sm"
                  style={{ minWidth: rs(['100%', 125], mqIndex) }}
                  variant={buttonVariant ?? 'outline'}
                >
                  {menuActions[0].label}
                </Button>
              ) : (
                <MenuContent
                  control={
                    <Button
                      color="blue"
                      onClick={(e) => e.stopPropagation()}
                      rightSection={<ChevronDown />}
                      size="compact-sm"
                      style={{ width: rs(['100%', 125], mqIndex) }}
                      variant="outline"
                    >
                      Actions
                    </Button>
                  }
                >
                  {menuActions.map((a) => (
                    <Menu.Item
                      key={a.value}
                      onClick={(e) => {
                        e.stopPropagation();
                        onAction(a.value);
                      }}
                    >
                      {a.label}
                    </Menu.Item>
                  ))}
                </MenuContent>
              )}
            </AppStack>
          ) : (
            showMenuSpacer && (
              <AppStack
                style={{
                  display: rs(['none', 'flex'], mqIndex),
                  width: rs(['100%', 125], mqIndex)
                }}
              />
            )
          )}
        </AppFlexbox>
      </AppFlexbox>
    </AppFlexbox>
  ) : (
    <AppFlexbox
      ref={ref}
      onClick={onClick}
      style={{
        flex: 1,
        padding: onClick && hovered ? 9 : 10,
        gap: 10,
        cursor: onClick ? 'pointer' : 'unset',
        border: onClick && hovered ? 'solid 1px #b9bfc4' : 'none'
      }}
    >
      {showImage && (
        <Skeleton
          style={{
            height: rs([25, 50], mqIndex),
            width: rs([25, 50], mqIndex),
            borderRadius: rs([25, 0], mqIndex),
            alignSelf: 'start',
            margin: rs([0, 0, 'auto 0px'], mqIndex)
          }}
        />
      )}
      {showIndicator && (
        <Skeleton
          style={{
            display: rs(['none', 'flex'], mqIndex),
            height: rs([25, 50], mqIndex),
            width: rs([25, 50], mqIndex),
            borderRadius: rs([25, 50], mqIndex),
            alignSelf: 'start',
            margin: rs([0, 0, 'auto 0px'], mqIndex)
          }}
        />
      )}
      <AppFlexbox
        style={{
          flex: 1,
          alignSelf: 'stretch',
          alignItems: 'center',
          flexDirection: rs(
            ['column', !hideBadgeLoader ? 'column' : 'row', 'row'],
            mqIndex
          ),
          justifyContent: 'space-between',
          gap: rs([5, 5, 15], mqIndex),
          overflow: 'hidden'
        }}
      >
        <AppFlexbox
          style={{
            flex: isLargeDescription ? 3 : 2,
            alignSelf: 'stretch',
            justifyContent: 'center',
            overflow: 'hidden',
            gap: rs([5, 5, 15], mqIndex),
            flexDirection: rs(['column', 'row'], mqIndex)
          }}
        >
          <AppStack
            style={{
              flex: rs(['unset', 1], mqIndex),
              gap: 5,
              justifyContent: 'center',
              overflow: 'hidden',
              whiteSpace: 'nowrap'
            }}
          >
            <Skeleton height={10} width="40%" />
            <Skeleton height={9} style={{ width: '50%' }} />
          </AppStack>

          {reverseMiddleColumns
            ? !hideBadgeLoader && (
                <AppStack
                  style={{
                    flex: rs(['unset', 1], mqIndex),
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignSelf: 'stretch',
                    display: alwaysShowBadge
                      ? 'flex'
                      : rs(['none', 'flex'], mqIndex),
                    width: rs(['100%', '100%', 'unset'], mqIndex),
                    placeItems: rs(
                      [
                        'start',
                        'end',
                        descriptionTextAlign ?? centerBadge ? 'center' : 'start'
                      ],
                      mqIndex
                    )
                  }}
                >
                  <Skeleton
                    height={25}
                    radius="xl"
                    style={{
                      width: 150,
                      display: rs(['none', 'block'], mqIndex)
                    }}
                  />
                </AppStack>
              )
            : !hideDescriptionLoader && (
                <AppStack
                  style={{
                    flex: rs(['unset', isLargeDescription ? 2 : 1], mqIndex),
                    gap: 5,
                    justifyContent: 'center',
                    overflow: 'hidden',
                    placeItems: rs(
                      [
                        'start',
                        !hideBadgeLoader
                          ? 'end'
                          : descriptionTextAlign ?? 'start',
                        descriptionTextAlign ?? 'start'
                      ],
                      mqIndex
                    )
                  }}
                >
                  <Skeleton height={10} width="60%" />
                  <Skeleton height={9} style={{ width: '50%' }} />
                </AppStack>
              )}
        </AppFlexbox>

        <AppFlexbox
          style={{
            flex:
              (reverseMiddleColumns && hideDescriptionLoader) ||
              (!reverseMiddleColumns && hideBadgeLoader)
                ? 'unset'
                : 2,
            alignSelf: 'stretch',
            justifyContent: 'center',
            gap: rs([5, 5, 15], mqIndex),
            flexDirection: rs(['column', 'row'], mqIndex)
          }}
        >
          {!reverseMiddleColumns
            ? !hideBadgeLoader && (
                <AppStack
                  style={{
                    flex: rs(['unset', 1], mqIndex),
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignSelf: 'stretch',
                    display: alwaysShowBadge
                      ? 'flex'
                      : rs(['none', 'flex'], mqIndex),
                    width: rs(['100%', '100%', 'unset'], mqIndex),
                    placeItems: rs(
                      [
                        'start',
                        'start',
                        descriptionTextAlign ?? centerBadge ? 'center' : 'start'
                      ],
                      mqIndex
                    )
                  }}
                >
                  <Skeleton
                    height={25}
                    radius="xl"
                    style={{
                      width: 150,
                      display: rs(['none', 'block'], mqIndex)
                    }}
                  />
                </AppStack>
              )
            : !hideDescriptionLoader && (
                <AppStack
                  style={{
                    flex: rs(['unset', isLargeDescription ? 2 : 1], mqIndex),
                    gap: 5,
                    justifyContent: 'center',
                    overflow: 'hidden',
                    placeItems: rs(
                      ['start', 'start', descriptionTextAlign ?? 'start'],
                      mqIndex
                    )
                  }}
                >
                  <Skeleton height={10} width="60%" />
                  <Skeleton height={9} style={{ width: '50%' }} />
                </AppStack>
              )}

          {!hideButtonLoader && (
            <AppStack
              style={{
                flex: rs(['unset', 1], mqIndex),
                alignSelf: 'stretch',
                justifyContent: 'center',
                placeItems: 'end',
                textAlign: 'end'
              }}
            >
              <Skeleton
                height={25}
                style={{ width: rs(['100%', 125], mqIndex) }}
              />
            </AppStack>
          )}
        </AppFlexbox>
      </AppFlexbox>
    </AppFlexbox>
  );
};

ActionableListItem.propTypes = {
  alwaysShowBadge: PropTypes.bool,
  badge: PropTypes.node,
  buttonColor: PropTypes.string,
  buttonVariant: PropTypes.string,
  centerBadge: PropTypes.bool,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  descriptionTextAlign: PropTypes.string,
  extraDescription: PropTypes.array,
  extraLabels: PropTypes.array,
  hideBadgeLoader: PropTypes.bool,
  hideBadgeOnTablet: PropTypes.bool,
  hideButtonLoader: PropTypes.bool,
  hideDescriptionLoader: PropTypes.bool,
  image: PropTypes.string,
  indicator: PropTypes.any,
  isLargeDescription: PropTypes.bool,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  menuActions: PropTypes.array,
  onAction: PropTypes.func,
  onClick: PropTypes.func,
  preventTabletWrap: PropTypes.bool,
  reverseMiddleColumns: PropTypes.bool,
  showImage: PropTypes.bool,
  showIndicator: PropTypes.bool,
  showMenuSpacer: PropTypes.bool,
  subDescription: PropTypes.string,
  subLabel: PropTypes.string,
  subLabelColor: PropTypes.string
};

export default ActionableListItem;
