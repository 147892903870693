import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ResponsiveModal from '../../common/ResponsiveModal';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import RegistrationListGrouped from '../financials/RegistrationListGrouped';
import AppTitle from '../../common/AppTitle';

const GroupCouponRegistrationsModal = ({
  isOpen,
  onClose,
  groupCoupon,
  pkRegAssociation
}) => {
  const { state, fetchAdminFinancialRegistrations } = useContext(
    RegistrationAdminContext
  );
  const [pageIndex, setPageIndex] = useState(1);
  const [fetchedGroupCouponPk, setFetchedGroupCouponPk] = useState(null);
  const regAssociation = state.regAssociations.value.find(
    (a) => a.pkRegAssociation.toString() === pkRegAssociation?.toString()
  );
  const isLoading =
    !fetchedGroupCouponPk ||
    fetchedGroupCouponPk !== groupCoupon?.pkRegAssociationGroupCoupon ||
    state.regFormSubmissions.loading;

  useEffect(() => {
    if (
      isOpen &&
      groupCoupon &&
      groupCoupon.pkRegAssociationGroupCoupon !== fetchedGroupCouponPk
    ) {
      fetchAdminFinancialRegistrations(regAssociation.pkRegAssociation, {groupCoupon: groupCoupon.pkRegAssociationGroupCoupon});
      setFetchedGroupCouponPk(groupCoupon.pkRegAssociationGroupCoupon);
    }
  }, [isOpen]);

  const submissionsGroupedByCoupon = state.regFormSubmissions.value
    .reduce((result, current) => {
      const existingIndex = result.findIndex((r) =>
        r.some(
          (s) =>
            s.regCartGroupCouponFormSubmissions[0]?.fkRegCart ===
              current.regCartGroupCouponFormSubmissions[0]?.fkRegCart &&
            s.regCartGroupCouponFormSubmissions[0]?.regAssociationGroupCoupon
              .pkRegAssociationGroupCoupon ===
              current.regCartGroupCouponFormSubmissions[0]
                ?.regAssociationGroupCoupon.pkRegAssociationGroupCoupon
        )
      );
      if (existingIndex !== -1) {
        const newResult = [...result];
        newResult[existingIndex] = [...result[existingIndex], current];
        return newResult;
      }
      return [...result, [current]];
    }, [])
    .sort(
      (a, b) =>
        new Date(b[0].submittedAt) - new Date(a[0].submittedAt) ||
        `${a[0].regPerson.firstName}${a[0].regPerson.lastName}`.localeCompare(
          `${b[0].regPerson.firstName}${b[0].regPerson.lastName}`
        )
    );

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClose}
      size={1100}
      title={<AppTitle order={2}>Redeemed Coupon registrations</AppTitle>}
    >
      <RegistrationListGrouped
        groupedRegFormSubmissions={submissionsGroupedByCoupon}
        isLoading={isLoading}
        onPageChange={setPageIndex}
        pageIndex={pageIndex}
        regAssociation={regAssociation}
      />
    </ResponsiveModal>
  );
};

GroupCouponRegistrationsModal.propTypes = {
  groupCoupon: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  pkRegAssociation: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default GroupCouponRegistrationsModal;
