import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Pagination } from '@mantine/core';
import AppStack from './AppStack';
import AppFlexbox from './AppFlexbox';
import AppText from './AppText';

const PaginationAccordionList = ({
  isLoading,
  items,
  LoadingComponent,
  loadingComponentCount,
  itemsPerPage,
  emptyMessage,
  pageIndex,
  onPageChange,
  listProps,
  bottomAction,
  value,
  onChange,
  accordionProps,
  ...rest
}) => {
  const MAX_PAGE_LENGTH = itemsPerPage ?? 10;
  const [pageIndexState, setPageIndexState] = useState(1);
  const index = pageIndex ?? pageIndexState;
  const showPagination = !isLoading && items.length > MAX_PAGE_LENGTH;

  useEffect(() => {
    setPageIndexState(1);
  }, [items]);

  return (
    <AppStack
      {...rest}
      style={{
        flex: 1,
        gap: 10,
        alignSelf: 'stretch',
        ...rest.style
      }}
    >
      {items.length === 0 ? (
        <AppStack
          style={{
            padding: '40px 20px',
            justifyContent: 'center',
            border: 'solid 1px lightgrey'
          }}
        >
          <AppText style={{ textAlign: 'center' }}>
            {emptyMessage || 'No items available'}
          </AppText>
        </AppStack>
      ) : (
        <>
          <Accordion
            multiple
            onChange={onChange}
            styles={{
              control: { padding: 10 },
              label: { padding: 0 }
            }}
            value={value}
            variant="contained"
            {...accordionProps}
          >
            {items
              .slice(
                -MAX_PAGE_LENGTH + index * MAX_PAGE_LENGTH,
                index * MAX_PAGE_LENGTH
              )
              .map((i) => i)}
          </Accordion>
          {(bottomAction || showPagination) && (
            <AppFlexbox
              style={{justifyContent: bottomAction ? 'space-between' : 'center'}}
            >
              {showPagination && (
                <Pagination
                  color="blue"
                  onChange={(page) => {
                    if (onPageChange) {
                      onPageChange(page);
                    }
                    else {
                      setPageIndexState(page);
                    }
                  }}
                  total={Math.ceil(items.length / MAX_PAGE_LENGTH)}
                  value={index}
                />
              )}

              {bottomAction}
            </AppFlexbox>
          )}
        </>
      )}
    </AppStack>
  );
};

PaginationAccordionList.propTypes = {
  LoadingComponent: PropTypes.any,
  accordionProps: PropTypes.object,
  bottomAction: PropTypes.node,
  emptyMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  items: PropTypes.array,
  itemsPerPage: PropTypes.number,
  listProps: PropTypes.object,
  loadingComponentCount: PropTypes.number,
  onChange: PropTypes.func,
  onPageChange: PropTypes.func,
  pageIndex: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

export default PaginationAccordionList;
