import React from 'react';
import { useMantineTheme } from '@mantine/core';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import classes from '../../../styles/nestedStyles.module.css';

const DrawerLinkSection = ({ textColor, lightMode, links, onClick }) => {
  const theme = useMantineTheme();
  const { pathname } = useLocation();

  return (
    <>
      {links.map((link) => (
        <AppFlexbox
          key={link.to}
          className={
            lightMode
              ? classes['hover-button-light-mode']
              : classes['hover-button-dark-mode']
          }
          component={Link}
          onClick={onClick}
          style={{
            padding: 10,
            borderRadius: theme.radius.sm,
            textDecoration: 'none',
            color: textColor,
            placeItems: 'center',
            gap: 25
          }}
          target={link.target}
          to={link.to}
        >
          <link.icon size={30} style={{ fontWeight: 'bold' }} />
          <AppText
            size="md"
            style={{ whiteSpace: 'normal' }}
            weight={link.isSelected(pathname) ? 'bold' : 'normal'}
          >
            {link.title}
          </AppText>
        </AppFlexbox>
      ))}
    </>
  );
};

DrawerLinkSection.propTypes = {
  lightMode: PropTypes.bool,
  links: PropTypes.array,
  onClick: PropTypes.func,
  textColor: PropTypes.string
};

export default DrawerLinkSection;
