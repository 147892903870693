import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Select, TextInput } from '@mantine/core';
import NumberFormat from 'react-number-format';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import FormSection from '../../common/FormSection';
import { triggerNotification } from '../../../helpers/notificationHelper';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import AppTitle from '../../common/AppTitle';
import ResponsiveModal from '../../common/ResponsiveModal';

const EvaluationPlayerModal = ({
  pkRegAssociation,
  fkRegAssociationDivision,
  regScoutReportPerson,
  showDelete,
  isOpen,
  onClose
}) => {
  const mqIndex = useMediaQueryIndex();
  const {
    state,
    updateRegScoutReportPerson,
    createRegScoutReportPerson,
    deleteRegScoutReportPerson
  } = useContext(RegistrationAdminContext);
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    gender: '',
    role: '',
    fkRegAssociationDivision: null,
    showDelete: false,
    isLoading: false
  });

  useEffect(() => {
    if (isOpen) {
      if (regScoutReportPerson) {
        setFormState({
          firstName: regScoutReportPerson.person.firstName,
          lastName: regScoutReportPerson.person.lastName,
          email: regScoutReportPerson.person.email,
          phone: regScoutReportPerson.person.phone,
          gender: regScoutReportPerson.person.gender,
          role: regScoutReportPerson.role,
          fkRegAssociationDivision:
            regScoutReportPerson.fkRegAssociationDivision,
          showDelete,
          isLoading: false
        });
      }
      else {
        setFormState({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          gender: '',
          role: '',
          fkRegAssociationDivision,
          showDelete: false,
          isLoading: false
        });
      }
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      onClose={onClose}
      opened={isOpen}
      title={
        <AppTitle order={2}>
          {showDelete || formState.showDelete
            ? 'Delete Player'
            : regScoutReportPerson
            ? 'Edit Player'
            : 'Create Player'}
        </AppTitle>
      }
    >
      {showDelete || formState.showDelete ? (
        <FormSection
          cancelTitle="Cancel"
          isLoading={formState.isLoading}
          onCancel={() => {
            if (showDelete) {
              onClose();
            }
            else {
              setFormState({
                ...formState,
                showDelete: false
              });
            }
          }}
          onSubmit={(e) => {
            e.stopPropagation();
            setFormState({
              ...formState,
              isLoading: true
            });
            deleteRegScoutReportPerson(
              regScoutReportPerson.pkRegScoutReportPerson,
              onClose,
              (errorMessage) => {
                setFormState({
                  ...formState,
                  isLoading: false
                });
                triggerNotification(errorMessage);
              }
            );
          }}
          style={{ flex: 1 }}
          submitColor="red"
          submitTitle="Delete"
        >
          <Divider />
          <AppText style={{ textAlign: 'center' }}>
            Are you sure you want to delete{' '}
            <b>
              {formState.firstName} {formState.lastName}
            </b>
            ?
          </AppText>
        </FormSection>
      ) : (
        <FormSection
          isLoading={formState.isLoading}
          onCancel={onClose}
          onSubmit={(e) => {
            e.stopPropagation();
            setFormState({
              ...formState,
              isLoading: true
            });
            if (regScoutReportPerson) {
              updateRegScoutReportPerson(
                regScoutReportPerson.pkRegScoutReportPerson,
                formState,
                onClose,
                (errorMessage) => {
                  setFormState({
                    ...formState,
                    isLoading: false
                  });
                  triggerNotification(errorMessage);
                }
              );
            }
            else {
              createRegScoutReportPerson(formState, onClose, (errorMessage) => {
                setFormState({
                  ...formState,
                  isLoading: false
                });
                triggerNotification(errorMessage);
              });
            }
          }}
          style={{ flex: 1 }}
          submitTitle={regScoutReportPerson ? 'Save Changes' : 'Create'}
        >
          <AppStack style={{ gap: 10, justifyContent: 'center' }}>
            <AppFlexbox
              style={{
                flex: 1,
                flexDirection: rs(['column', 'row'], mqIndex),
                alignItems: 'stretch'
              }}
            >
              <TextInput
                disabled={formState.isLoading}
                label="First Name"
                onChange={(e) => {
                  setFormState({
                    ...formState,
                    firstName: e.currentTarget.value
                  });
                }}
                required
                style={{ flex: 1 }}
                value={formState.firstName || ''}
              />
              <TextInput
                disabled={formState.isLoading}
                label="Last Name"
                onChange={(e) => {
                  setFormState({
                    ...formState,
                    lastName: e.currentTarget.value
                  });
                }}
                required
                style={{ flex: 1 }}
                value={formState.lastName || ''}
              />
            </AppFlexbox>
            {!regScoutReportPerson && !fkRegAssociationDivision && (
              <Select
                clearable
                data={state.regAssociationDivisions.value
                  .filter(
                    (d) =>
                      d.regAssociationSeason.fkRegAssociation ===
                      pkRegAssociation
                  )
                  .map((r) => ({
                    label: r.name,
                    description: r.regAssociationSeason.name,
                    value: r.pkRegAssociationDivision.toString()
                  }))
                  .sort((a, b) => a.label.localeCompare(b.label))}
                disabled={formState.isLoading}
                label="Division"
                onChange={(value) =>
                  setFormState({
                    ...formState,
                    fkRegAssociationDivision: value
                  })
                }
                renderOption={({ option }) => (
                  <AppFlexbox gap="sm">
                    <div>
                      <AppText size="sm">{option.label}</AppText>
                      {option.description && (
                        <AppText size="xs" style={{ color: 'grey' }}>
                          {option.description}
                        </AppText>
                      )}
                    </div>
                  </AppFlexbox>
                )}
                required
                searchable
                value={formState.fkRegAssociationDivision}
              />
            )}
            <TextInput
              disabled={formState.isLoading}
              label="Email"
              onChange={(e) => {
                setFormState({
                  ...formState,
                  email: e.currentTarget.value
                });
              }}
              style={{ flex: 1 }}
              type="email"
              value={formState.email || ''}
            />
            <NumberFormat
              customInput={TextInput}
              disabled={formState.isLoading}
              fixedDecimalScale
              format="+1 (###) ###-####"
              label="Phone Number"
              mask="_"
              name="phoneNumber"
              onValueChange={(values) => {
                setFormState({
                  ...formState,
                  phone: values.value
                });
              }}
              style={{ flex: 1 }}
              styles={{
                label: {
                  fontSize: 14,
                  fontWeight: 500
                }
              }}
              thousandSeparator
              value={formState.phone || ''}
            />
            <AppFlexbox
              style={{
                flex: 1,
                flexDirection: rs(['column', 'row'], mqIndex),
                alignItems: 'stretch'
              }}
            >
              <TextInput
                disabled={formState.isLoading}
                label="Role"
                onChange={(e) => {
                  setFormState({
                    ...formState,
                    role: e.currentTarget.value
                  });
                }}
                style={{ flex: 1 }}
                value={formState.role || ''}
              />
              <Select
                clearable
                data={[
                  { label: 'Male', value: 'Male' },
                  { label: 'Female', value: 'Female' },
                  { label: 'Not Specified', value: 'Not Specified' }
                ]}
                label="Gender"
                onChange={(value) =>
                  setFormState({
                    ...formState,
                    gender: value
                  })
                }
                searchable
                style={{ flex: 1 }}
                value={formState.gender ?? null}
              />
            </AppFlexbox>
          </AppStack>

          {regScoutReportPerson && (
            <>
              <Divider />
              <Button
                color="red"
                disabled={formState.isLoading}
                onClick={() => {
                  setFormState({
                    ...formState,
                    showDelete: true
                  });
                }}
                style={{ alignSelf: 'center', width: '100%', maxWidth: 250 }}
              >
                Delete
              </Button>
            </>
          )}
        </FormSection>
      )}
    </ResponsiveModal>
  );
};

EvaluationPlayerModal.propTypes = {
  fkRegAssociationDivision: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  pkRegAssociation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  regScoutReportPerson: PropTypes.object,
  showDelete: PropTypes.bool
};

export default EvaluationPlayerModal;
