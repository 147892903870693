import { Button, Pagination, Table } from '@mantine/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Plus } from 'tabler-icons-react';
import FinancialRegistrationTransactionListItem from './FinancialRegistrationTransactionListItem';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const FinancialRegistrationTransactionList = ({
  payments,
  isLoading,
  isAdminViewing,
  isPendingPayment,
  chargeId,
  isRefundable,
  onAction
}) => {
  const mqIndex = useMediaQueryIndex();
  const MAX_PAGE_LENGTH = 5;
  const [pageIndex, setPageIndex] = useState(1);
  const hasInvoices =
    !isLoading && payments.some((p) => p.fkRegFormSubmissionPaymentType === 6);
  const showAdminActions =
    isAdminViewing &&
    ((payments.length > 0 && isPendingPayment) || hasInvoices);

  const paidOnline = payments.some(
    (p) =>
      !p.regFormSubmissionInvoice &&
      (p.fkRegFormSubmissionPaymentType === 7 ||
        p.fkRegFormSubmissionPaymentType === 8 ||
        p.fkRegFormSubmissionPaymentType === 10 ||
        p.fkRegFormSubmissionPaymentType === 11)
  );

  return (
    <AppStack style={{ gap: 10 }}>
      <AppFlexbox style={{ flex: 1, justifyContent: 'space-between' }}>
        <AppText weight={500}>Transaction Log</AppText>
      </AppFlexbox>

      <AppStack>
        <AppStack style={{ overflow: 'auto', flex: 1, gap: 0 }}>
          <Table
            horizontalSpacing="md"
            style={{ border: 'solid 1px #ced4da' }}
            verticalSpacing="md"
          >
            <Table.Thead>
              <Table.Tr>
                <Table.Th>
                  <AppText style={{ whiteSpace: 'nowrap' }}>Status</AppText>
                </Table.Th>
                <Table.Th>
                  <AppText style={{ whiteSpace: 'nowrap' }}>Date</AppText>
                </Table.Th>
                <Table.Th>
                  <AppText style={{ whiteSpace: 'nowrap' }}>
                    Transaction ID
                  </AppText>
                </Table.Th>
                <Table.Th>
                  <AppText style={{ whiteSpace: 'nowrap' }}>Type</AppText>
                </Table.Th>
                <Table.Th>
                  <AppText style={{ whiteSpace: 'nowrap' }}>
                    Description
                  </AppText>
                </Table.Th>
                <Table.Th style={{ borderRight: 'solid 1px #ced4da' }}>
                  <AppText style={{ whiteSpace: 'nowrap', textAlign: 'end' }}>
                    Gross Amount
                  </AppText>
                </Table.Th>
                {showAdminActions && (
                  <Table.Th>
                    <AppText
                      style={{ textAlign: 'center', whiteSpace: 'nowrap' }}
                    >
                      Admin
                    </AppText>
                  </Table.Th>
                )}
              </Table.Tr>
            </Table.Thead>

            <Table.Tbody>
              {isLoading ? (
                <>
                  <FinancialRegistrationTransactionListItem />
                  <FinancialRegistrationTransactionListItem />
                </>
              ) : payments.length === 0 ? (
                <Table.Tr>
                  <Table.Td
                    colSpan="6"
                    style={{ padding: 50, textAlign: 'center' }}
                  >
                    <AppText weight={500}>- No Payments Received -</AppText>
                  </Table.Td>
                </Table.Tr>
              ) : (
                payments
                  ?.sort(
                    (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
                  )
                  .slice(
                    -MAX_PAGE_LENGTH + pageIndex * MAX_PAGE_LENGTH,
                    pageIndex * MAX_PAGE_LENGTH
                  )
                  .map((p) => (
                    <FinancialRegistrationTransactionListItem
                      key={p.pkRegFormSubmissionPayment}
                      chargeId={chargeId}
                      isActionable={showAdminActions}
                      isInvoiceActionsAvailable={hasInvoices}
                      isRefundable={
                        p.amount > 0 && isPendingPayment && isRefundable
                      }
                      onAction={(action) => onAction(p, action)}
                      paidOnline={paidOnline}
                      regFormSubmissionPayment={p}
                    />
                  ))
              )}
            </Table.Tbody>
          </Table>
        </AppStack>
        <AppFlexbox
          style={{
            justifyContent:
              isAdminViewing && isPendingPayment
                ? rs(['center', 'center', 'space-between'], mqIndex)
                : 'center'
          }}
        >
          <AppStack>
            {!isLoading && payments.length > MAX_PAGE_LENGTH && (
              <Pagination
                color="blue"
                onChange={setPageIndex}
                total={Math.ceil(payments.length / MAX_PAGE_LENGTH)}
                value={pageIndex}
              />
            )}
          </AppStack>
          {isAdminViewing && isPendingPayment && (
            <Button
              color="green"
              disabled={isLoading}
              leftSection={<Plus />}
              onClick={() => onAction(null, 'APPLY-PAYMENT')}
              style={{ flex: rs([1, 'unset', 'unset'], mqIndex) }}
            >
              Apply Payment
            </Button>
          )}
        </AppFlexbox>
      </AppStack>
    </AppStack>
  );
};

FinancialRegistrationTransactionList.propTypes = {
  chargeId: PropTypes.string,
  isAdminViewing: PropTypes.bool,
  isLoading: PropTypes.bool,
  isPendingPayment: PropTypes.bool,
  isRefundable: PropTypes.bool,
  onAction: PropTypes.func,
  payments: PropTypes.array
};

export default FinancialRegistrationTransactionList;
