import React, { useContext, useEffect, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Alert, Checkbox, Divider, TextInput } from '@mantine/core';
import { AlertCircle, InfoCircle } from 'tabler-icons-react';
import NumberFormat from 'react-number-format';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import RegistrationListItem from './RegistrationListItem';
import { triggerNotification } from '../../../helpers/notificationHelper';
import { currencyFormat } from '../../../helpers/format';
import RefundTransactionOverview from './RefundTransactionOverview';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppTitle from '../../common/AppTitle';
import FormSection from '../../common/FormSection';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';

const RegistrationRefundModal = ({
  regAssociation,
  isOpen,
  onClose,
  onRefresh,
  regFormSubmission
}) => {
  const hasInitialized = useRef(false);
  const {
    state,
    refundFinancialRegistration,
    fetchAdminRegFormSubmissionPaymentTransactionDetails
  } = useContext(RegistrationAdminContext);
  const [formState, setFormState] = useState({
    partialRefundAmount: 0,
    partialRefund: false,
    deactivateRegPerson: true,
    excludeGroupCouponAmount: false,
    skipWaitlist: false,
    loading: false,
    showConfirm: false,
    showRefundWithoutTransferConfirmation: false,
    refundWithoutTransfer: false,
    refundWithoutPlatformFee: false
  });
  const transactionDetails =
    state.regFormSubmissionPaymentTransactionDetails.value;
  const lineItem = transactionDetails?.cart.lineItems.find(
    (i) => i.entityId === regFormSubmission?.pkRegFormSubmission
  );

  const isLoading =
    !regFormSubmission ||
    !hasInitialized.current ||
    !lineItem ||
    state.regFormSubmissionPaymentTransactionDetails.loading;
  const hasRefundableInvoices =
    lineItem && lineItem.invoices.some((i) => !i.isRefunded);
  const serviceFeeInCents =
    lineItem && lineItem.amountPaidInCents > 0
      ? transactionDetails.paymentProviderTransaction &&
        transactionDetails.cart.lineItems.length === 1
        ? transactionDetails.paymentProviderTransaction.serviceFeeInCents
        : lineItem.refundServiceFee
      : 0;
  const groupCoupon = lineItem
    ? lineItem.coupons.find((c) => c.isGroupCoupon)
    : null;
  const lineItemsUsingGroupCoupon =
    transactionDetails?.cart.lineItems.filter(
      (i) =>
        i.entityId !== lineItem?.entityId &&
        i.coupons.some((c) => c.entityId === groupCoupon?.entityId)
    ) || [];
  const totalCouponAmountSavedInCents = lineItemsUsingGroupCoupon
    .map((i) => i.coupons.find((c) => c.entityId === groupCoupon?.entityId))
    .reduce((r, c) => r + c.amountSavedInCents, 0);

  const previouslyQualifiedForGroupCoupon =
    groupCoupon &&
    lineItemsUsingGroupCoupon.length >= groupCoupon.minimumQualifiedAmount;

  const maxRefundAmount = lineItem
    ? (lineItem.amountPaidInCents -
        lineItem.refundedAmountInCents -
        serviceFeeInCents -
        (formState.excludeGroupCouponAmount
          ? totalCouponAmountSavedInCents
          : 0)) /
      100
    : 0;

  const maxRefundAmountWithoutPlatformFee = lineItem
    ? maxRefundAmount - lineItem.platformFeeInCents / 100
    : 0;
  const currency = lineItem?.currency?.toUpperCase();

  useEffect(() => {
    if (isOpen && regFormSubmission) {
      fetchAdminRegFormSubmissionPaymentTransactionDetails(
        regFormSubmission.pkRegFormSubmission,
        null,
        triggerNotification
      );
      setFormState({
        partialRefundAmount: 0,
        partialRefund: false,
        deactivateRegPerson: true,
        excludeGroupCouponAmount: false,
        skipWaitlist: false,
        loading: false,
        showConfirm: false,
        showRefundWithoutTransferConfirmation: false,
        refundWithoutTransfer: false,
        refundWithoutPlatformFee: false
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (lineItem && !hasInitialized.current) {
      setFormState({
        partialRefundAmount: maxRefundAmount,
        partialRefund: false,
        deactivateRegPerson: true,
        excludeGroupCouponAmount: false,
        skipWaitlist: false,
        loading: false,
        showConfirm: false,
        showRefundWithoutTransferConfirmation: false,
        refundWithoutTransfer: false,
        refundWithoutPlatformFee: false
      });
      hasInitialized.current = true;
    }
  }, [lineItem]);

  useEffect(() => {
    if (formState.partialRefundAmount > maxRefundAmount) {
      setFormState((c) => ({
        ...c,
        partialRefundAmount: maxRefundAmount
      }));
    }
  }, [maxRefundAmount]);

  return (
    <ResponsiveModal
      isLoading={isLoading}
      onClose={onClose}
      opened={isOpen}
      size={600}
      title={<AppTitle order={2}>Refund Registration</AppTitle>}
    >
      {!isLoading && (
        <AppStack style={{ gap: 20 }}>
          {transactionDetails.paymentProviderTransaction && (
            <RefundTransactionOverview
              amountRefunded={lineItem.refundedAmountInCents / 100}
              currency={currency}
              serviceFee={serviceFeeInCents / 100}
              transactionTotal={lineItem.amountPaidInCents / 100}
            />
          )}
          <RegistrationListItem
            regAssociation={regAssociation}
            regFormSubmission={regFormSubmission}
          />
          {formState.showConfirm ? (
            <FormSection
              cancelTitle={
                formState.showRefundWithoutTransferConfirmation
                  ? 'Back'
                  : 'Cancel'
              }
              hideDivider
              isLoading={formState.loading}
              isSubmitDisabled={
                formState.showRefundWithoutTransferConfirmation &&
                !formState.refundWithoutTransfer &&
                !formState.refundWithoutPlatformFee
              }
              onCancel={() =>
                setFormState({
                  ...formState,
                  showConfirm: false
                })
              }
              onSubmit={() => {
                setFormState({
                  ...formState,
                  loading: true
                });

                const partialRefundAmount =
                  !formState.deactivateRegPerson &&
                  formState.partialRefundAmount >
                    maxRefundAmountWithoutPlatformFee
                    ? maxRefundAmountWithoutPlatformFee
                    : formState.partialRefundAmount;

                refundFinancialRegistration(
                  regFormSubmission.pkRegFormSubmission,
                  {
                    skipWaitlist: formState.skipWaitlist,
                    partialRefundAmount:
                      formState.showRefundWithoutTransferConfirmation &&
                      formState.refundWithoutPlatformFee
                        ? Number(maxRefundAmountWithoutPlatformFee).toFixed(2)
                        : formState.partialRefund &&
                          !!formState.partialRefundAmount
                        ? Number(partialRefundAmount).toFixed(2)
                        : null,
                    deactivateRegPerson:
                      formState.showRefundWithoutTransferConfirmation ||
                      (formState.partialRefund
                        ? formState.deactivateRegPerson
                        : false),
                    excludeGroupCouponAmount:
                      formState.excludeGroupCouponAmount,
                    refundWithoutTransferReverse:
                      formState.showRefundWithoutTransferConfirmation &&
                      formState.refundWithoutTransfer
                  },
                  () => {
                    triggerNotification(
                      'Registration has been refunded',
                      'Success',
                      'green'
                    );
                    if (onRefresh) {
                      onRefresh();
                    }
                    onClose();
                  },
                  (error, errorType) => {
                    setFormState({
                      ...formState,
                      loading: false
                    });
                    if (errorType === 'old-transfer') {
                      setFormState({
                        ...formState,
                        isLoading: false,
                        showRefundWithoutTransferConfirmation: true,
                        refundWithoutTransfer: false
                      });
                    }
                    else if (errorType === 'reservations') {
                      triggerNotification(
                        'Registration has been refunded',
                        'Success',
                        'green'
                      );
                      if (onRefresh) {
                        onRefresh();
                      }

                      onClose();
                    }
                    else {
                      setFormState({
                        ...formState,
                        loading: false
                      });
                    }
                    triggerNotification(error);
                  }
                );
              }}
              submitColor="red"
              submitTitle="Refund"
            >
              <AppStack style={{ gap: 20 }}>
                <Divider />
                {formState.showRefundWithoutTransferConfirmation ? (
                  <Alert color="red" variant="outline">
                    <AppStack>
                      <AppText>
                        This submission has a platform fee transfer that is
                        greater than 30 days old and cannot be recovered. How
                        would you like to proceed?
                      </AppText>
                      <AppText style={{ fontWeight: 500 }}>
                        Please select one of the following options;
                      </AppText>

                      <AppStack style={{ gap: 5 }}>
                        <AppText style={{ fontWeight: 500, fontSize: 14 }}>
                          Option 1:
                        </AppText>
                        <Checkbox
                          checked={formState.refundWithoutTransfer}
                          label={`Refund full amount (you will cover the platform fee of ${currencyFormat(
                            lineItem.platformFeeInCents / 100
                          )} ${currency})`}
                          onChange={(e) =>
                            setFormState({
                              ...formState,
                              refundWithoutTransfer: e.currentTarget.checked,
                              refundWithoutPlatformFee: false
                            })
                          }
                          style={{ fontWeight: 500 }}
                        />
                      </AppStack>

                      <AppStack style={{ gap: 5 }}>
                        <AppText style={{ fontWeight: 500, fontSize: 14 }}>
                          Option 2:
                        </AppText>
                        <Checkbox
                          checked={formState.refundWithoutPlatformFee}
                          label={`Refund total minus platform fee (${currencyFormat(
                            maxRefundAmountWithoutPlatformFee
                          )} ${currency})`}
                          onChange={(e) =>
                            setFormState({
                              ...formState,
                              refundWithoutPlatformFee: e.currentTarget.checked,
                              refundWithoutTransfer: false
                            })
                          }
                          style={{ fontWeight: 500 }}
                        />
                      </AppStack>
                    </AppStack>
                  </Alert>
                ) : (
                  <>
                    {!formState.deactivateRegPerson &&
                      formState.partialRefundAmount >
                        maxRefundAmountWithoutPlatformFee && (
                        <Alert
                          color="yellow"
                          icon={<AlertCircle />}
                          styles={{ icon: { width: 28, height: 28 } }}
                          variant="outline"
                        >
                          <AppStack style={{ gap: 10 }}>
                            <AppText
                              style={{ marginTop: 4, fontSize: 14 }}
                              weight={500}
                            >
                              Refund amount has been reduced by $3 to cover
                              registration service Fee while keeping the
                              registrant active. They will receive a refund of{' '}
                              <NumberFormat
                                decimalScale={2}
                                displayType="text"
                                fixedDecimalScale
                                prefix="$"
                                thousandSeparator
                                value={maxRefundAmountWithoutPlatformFee}
                              />{' '}
                              {currency}
                            </AppText>
                            <AppText style={{ fontSize: 14, color: 'grey' }}>
                              If you want to refund the full amount, please
                              deactivate the registrant from the division.
                            </AppText>
                          </AppStack>
                        </Alert>
                      )}
                    <AppText style={{ textAlign: 'center' }}>
                      Are you sure you want to <b>Refund</b> the selected
                      registration?
                    </AppText>
                  </>
                )}

                <Divider />
              </AppStack>
            </FormSection>
          ) : (
            <FormSection
              isLoading={formState.loading}
              onCancel={onClose}
              onSubmit={() =>
                setFormState({
                  ...formState,
                  showConfirm: true
                })
              }
              submitColor="red"
              submitTitle="Refund"
            >
              <AppStack style={{ gap: 20 }}>
                {groupCoupon && previouslyQualifiedForGroupCoupon && (
                  <Alert
                    color="yellow"
                    icon={<InfoCircle style={{ width: 35, height: 35 }} />}
                    style={{ marginBottom: 10 }}
                    title={
                      <AppText style={{ color: '#000' }} weight={500}>
                        This registration was used in part of a group coupon.
                      </AppText>
                    }
                    variant="outline"
                  >
                    <AppStack style={{ gap: 20 }}>
                      {lineItemsUsingGroupCoupon.length - 1 <
                      groupCoupon.minimumQualifiedAmount ? (
                        <>
                          <AppText>
                            If you refund this submission the registrant will no
                            longer qualify for this group coupon.
                          </AppText>
                          <Checkbox
                            checked={formState.excludeGroupCouponAmount}
                            label={
                              <AppText style={{ fontSize: 14 }} weight={500}>
                                Decrease refund amount by{' '}
                                <NumberFormat
                                  decimalScale={2}
                                  displayType="text"
                                  fixedDecimalScale
                                  prefix="$"
                                  thousandSeparator
                                  value={totalCouponAmountSavedInCents / 100}
                                />{' '}
                                {currency}
                              </AppText>
                            }
                            onChange={(e) =>
                              setFormState({
                                ...formState,
                                excludeGroupCouponAmount:
                                  e.currentTarget.checked
                              })
                            }
                          />

                          {formState.excludeGroupCouponAmount &&
                            maxRefundAmount < 0 && (
                              <Alert
                                color="red"
                                icon={
                                  <AlertCircle
                                    style={{ width: 35, height: 35 }}
                                  />
                                }
                                title="Warning"
                                variant="outline"
                              >
                                <AppStack style={{ gap: 20, marginTop: 10 }}>
                                  <AppText>
                                    The full coupon amount cannot be recovered
                                    from refunding this registration.
                                  </AppText>
                                  <AppText weight={500}>
                                    You may need to send the registrant an
                                    additional invoice for{' '}
                                    <NumberFormat
                                      decimalScale={2}
                                      displayType="text"
                                      fixedDecimalScale
                                      prefix="$"
                                      thousandSeparator
                                      value={maxRefundAmount * -1}
                                    />{' '}
                                    {currency}
                                  </AppText>
                                </AppStack>
                              </Alert>
                            )}
                        </>
                      ) : (
                        <>
                          <AppText>
                            The registrant still qualifies with{' '}
                            {lineItemsUsingGroupCoupon.length - 1} other
                            registration(s).
                          </AppText>
                          {!groupCoupon.percentageAmount &&
                            groupCoupon.unitAmountInCents > 0 && (
                              <>
                                <Divider />
                                <AppStack style={{ gap: 10 }}>
                                  <AppText weight={500}>
                                    <NumberFormat
                                      decimalScale={2}
                                      displayType="text"
                                      fixedDecimalScale
                                      prefix="$"
                                      thousandSeparator
                                      value={
                                        groupCoupon.unitAmountInCents / 100
                                      }
                                    />{' '}
                                    {currency} was saved on this registration
                                    from the use of this group coupon.
                                  </AppText>
                                  <AppText>
                                    If you want to carry over the saved coupon
                                    amount you will need to refund through a
                                    seperate registration.
                                  </AppText>
                                  {regFormSubmission.regAssociationDivisionForm
                                    .regProduct.taxRate > 0 && (
                                    <AppText
                                      style={{
                                        fontSize: 14,
                                        color: 'grey',
                                        fontStyle: 'italic'
                                      }}
                                    >
                                      ** Additional taxes may need to be
                                      calculated **
                                    </AppText>
                                  )}
                                </AppStack>
                              </>
                            )}
                        </>
                      )}
                    </AppStack>
                  </Alert>
                )}

                {maxRefundAmount > 0 && (
                  <>
                    <Checkbox
                      checked={formState.partialRefund}
                      disabled={formState.loading}
                      label="Partial Refund"
                      onChange={(e) => {
                        setFormState({
                          ...formState,
                          partialRefund: e.currentTarget.checked,
                          partialRefundAmount: maxRefundAmount
                        });
                      }}
                      styles={{ label: { fontWeight: 500 } }}
                    />
                    {formState.partialRefund && (
                      <>
                        <NumberFormat
                          allowNegative={false}
                          customInput={TextInput}
                          decimalScale={2}
                          disabled={formState.loading}
                          fixedDecimalScale
                          label="Refund Amount"
                          name="refundAmount"
                          onValueChange={(values) =>
                            setFormState({
                              ...formState,
                              partialRefundAmount:
                                values.floatValue > maxRefundAmount
                                  ? maxRefundAmount
                                  : values.floatValue
                            })
                          }
                          placeholder={`${currencyFormat(maxRefundAmount)}`}
                          prefix="$"
                          rightSection={
                            <AppText
                              style={{
                                fontSize: 14,
                                fontWeight: 500,
                                color: '#999',
                                paddingRight: 5
                              }}
                            >
                              {currency}
                            </AppText>
                          }
                          style={{ flex: 1 }}
                          thousandSeparator
                          value={formState.partialRefundAmount}
                        />
                        <Checkbox
                          checked={formState.deactivateRegPerson}
                          disabled={formState.loading}
                          label="Also remove registrant from division"
                          onChange={(e) =>
                            setFormState({
                              ...formState,
                              deactivateRegPerson: e.currentTarget.checked,
                              partialRefundAmount:
                                formState.partialRefundAmount > maxRefundAmount
                                  ? maxRefundAmount
                                  : formState.partialRefundAmount
                            })
                          }
                          styles={{ label: { fontWeight: 500 } }}
                        />
                        {formState.deactivateRegPerson && (
                          <Alert
                            color="yellow"
                            icon={<AlertCircle />}
                            styles={{ icon: { width: 28, height: 28 } }}
                            variant="outline"
                          >
                            <AppText
                              style={{ marginTop: 4, fontSize: 14 }}
                              weight={500}
                            >
                              You cannot undo this action.
                            </AppText>
                          </Alert>
                        )}
                      </>
                    )}
                  </>
                )}

                {hasRefundableInvoices && (
                  <Alert
                    color="yellow"
                    icon={<AlertCircle />}
                    styles={{ icon: { width: 28, height: 28 } }}
                    variant="outline"
                  >
                    <AppText
                      style={{ marginTop: 4, fontSize: 14 }}
                      weight={500}
                    >
                      {regFormSubmission.paidRegFormSubmissionInvoiceCount}{' '}
                      Invoice(s) will have to be refunded separately
                    </AppText>
                  </Alert>
                )}

                {regFormSubmission.regAssociationDivisionForm
                  .regAssociationDivision.waitlistEnabled && (
                  <>
                    <Divider />
                    <Checkbox
                      checked={!formState.skipWaitlist}
                      label="Notify waitlist registrant"
                      onChange={(e) => {
                        setFormState({
                          ...formState,
                          skipWaitlist: !e.currentTarget.checked
                        });
                      }}
                      styles={{
                        label: {
                          fontSize: 16,
                          fontWeight: 500
                        }
                      }}
                    />
                    <Alert
                      color={formState.skipWaitlist ? 'yellow' : 'blue'}
                      icon={
                        formState.skipWaitlist ? (
                          <AlertCircle style={{ width: 35, height: 35 }} />
                        ) : (
                          <InfoCircle style={{ width: 35, height: 35 }} />
                        )
                      }
                      styles={{ icon: { marginTop: 15, width: 35 } }}
                      variant="outline"
                    >
                      {formState.skipWaitlist ? (
                        <AppText>
                          The next waitlist registrant will not be notified of
                          any open positions.
                        </AppText>
                      ) : (
                        <AppText>
                          We will notify the next waitlist registrant that they
                          can now register for{' '}
                          {
                            regFormSubmission.regAssociationDivisionForm
                              .regAssociationDivision.name
                          }
                          .
                        </AppText>
                      )}
                    </Alert>
                  </>
                )}
              </AppStack>
            </FormSection>
          )}
        </AppStack>
      )}
    </ResponsiveModal>
  );
};

RegistrationRefundModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onRefresh: PropTypes.func,
  regAssociation: PropTypes.object,
  regFormSubmission: PropTypes.object
};
export default RegistrationRefundModal;
