const DEFAULT_DATE_OPTIONS_ENUM = {
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  LAST_7_DAYS: 'last7',
  LAST_30_DAYS: 'last30',
  LAST_90_DAYS: 'last90',
  LAST_365_DAYS: 'last365',
  LAST_MONTH: 'lastmonth',
  LAST_12_MONTHS: 'last12Months',
  LAST_YEAR: 'lastyear',
  WEEK_TO_DATE: 'weektodate',
  MONTH_TO_DATE: 'monthtodate',
  QUARTER_TO_DATE: 'quartertodate',
  YEAR_TO_DATE: 'yeartodate'
};

const DEFAULT_DATE_OPTIONS = [
  {
    label: 'Today',
    value: DEFAULT_DATE_OPTIONS_ENUM.TODAY,
    start: 'today',
    end: 'today',
    getDateRange: () => ({
      startDate: new Date(),
      endDate: new Date()
    }),
    getCompareDateRange: () => {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return { startDate: yesterday, endDate: yesterday };
    }
  },
  {
    label: 'Yesterday',
    value: DEFAULT_DATE_OPTIONS_ENUM.YESTERDAY,
    start: 'yesterday',
    end: 'yesterday',
    getDateRange: () => {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return { startDate: yesterday, endDate: yesterday };
    },
    getCompareDateRange: () => {
      const twoDaysAgo = new Date();
      twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
      return { startDate: twoDaysAgo, endDate: twoDaysAgo };
    }
  },
  {
    label: 'Last 7 days',
    value: DEFAULT_DATE_OPTIONS_ENUM.LAST_7_DAYS,
    start: '-7d',
    end: '-1d',
    isDefault: true,
    getDateRange: () => {
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 7);
      const endDate = new Date();
      endDate.setDate(endDate.getDate() - 1);
      return { startDate, endDate };
    },
    getCompareDateRange: () => {
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 14);
      const endDate = new Date();
      endDate.setDate(endDate.getDate() - 8);
      return { startDate, endDate };
    }
  },
  {
    label: 'Last 30 days',
    value: DEFAULT_DATE_OPTIONS_ENUM.LAST_30_DAYS,
    start: '-30d',
    end: '-1d',
    getDateRange: () => {
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 30);
      const endDate = new Date();
      endDate.setDate(endDate.getDate() - 1);
      return { startDate, endDate };
    },
    getCompareDateRange: () => {
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 60);
      const endDate = new Date();
      endDate.setDate(endDate.getDate() - 31);
      return { startDate, endDate };
    }
  },
  {
    label: 'Last 90 days',
    value: DEFAULT_DATE_OPTIONS_ENUM.LAST_90_DAYS,
    start: '-90d',
    end: '-1d',
    getDateRange: () => {
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 90);
      const endDate = new Date();
      endDate.setDate(endDate.getDate() - 1);
      return { startDate, endDate };
    },
    getCompareDateRange: () => {
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 180);
      const endDate = new Date();
      endDate.setDate(endDate.getDate() - 91);
      return { startDate, endDate };
    }
  },
  {
    label: 'Last 365 days',
    value: DEFAULT_DATE_OPTIONS_ENUM.LAST_365_DAYS,
    start: '-365d',
    end: '-1d',
    getDateRange: () => {
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 365);
      const endDate = new Date();
      endDate.setDate(endDate.getDate() - 1);
      return { startDate, endDate };
    },
    getCompareDateRange: () => {
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 730);
      const endDate = new Date();
      endDate.setDate(endDate.getDate() - 366);
      return { startDate, endDate };
    }
  },
  {
    label: 'Last month',
    value: DEFAULT_DATE_OPTIONS_ENUM.LAST_MONTH,
    start: '-1m',
    end: '-1m',
    getDateRange: () => {
      const startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 1);
      startDate.setDate(1);

      const endDate = new Date(startDate);
      endDate.setMonth(endDate.getMonth() + 1);
      endDate.setDate(0);

      return { startDate, endDate };
    },
    getCompareDateRange: () => {
      const startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 2);
      startDate.setDate(1);

      const endDate = new Date(startDate);
      endDate.setMonth(endDate.getMonth() + 1);
      endDate.setDate(0);

      return { startDate, endDate };
    }
  },
  {
    label: 'Last 12 months',
    value: DEFAULT_DATE_OPTIONS_ENUM.LAST_12_MONTHS,
    start: '-12m',
    end: '-1m',
    getDateRange: () => {
      const startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 12);
      startDate.setDate(1);

      const endDate = new Date();
      endDate.setDate(0);

      return { startDate, endDate };
    },
    getCompareDateRange: () => {
      const startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 24);
      startDate.setDate(1);

      const endDate = new Date();
      endDate.setMonth(endDate.getMonth() - 12);
      endDate.setDate(0);

      return { startDate, endDate };
    }
  },
  {
    label: 'Last year',
    value: DEFAULT_DATE_OPTIONS_ENUM.LAST_YEAR,
    start: '-1y',
    end: '-1y',
    getDateRange: () => {
      const startDate = new Date();
      startDate.setFullYear(startDate.getFullYear() - 1);
      startDate.setMonth(0);
      startDate.setDate(1);

      const endDate = new Date(startDate);
      endDate.setFullYear(endDate.getFullYear() + 1);
      endDate.setDate(0);

      return { startDate, endDate };
    },
    getCompareDateRange: () => {
      const startDate = new Date();
      startDate.setFullYear(startDate.getFullYear() - 2);
      startDate.setMonth(0);
      startDate.setDate(1);

      const endDate = new Date(startDate);
      endDate.setFullYear(endDate.getFullYear() + 1);
      endDate.setDate(0);

      return { startDate, endDate };
    }
  },
  {
    label: 'Week to date',
    value: DEFAULT_DATE_OPTIONS_ENUM.WEEK_TO_DATE,
    start: '0w',
    end: 'today',
    getDateRange: () => {
      const today = new Date();
      const dayOfWeek = today.getDay();
      const diffToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
      const startDate = new Date(today);
      startDate.setDate(today.getDate() - diffToMonday);
      const endDate = today;

      return { startDate, endDate };
    },
    getCompareDateRange: () => {
      const today = new Date();
      const dayOfWeek = today.getDay();
      const diffToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
      const startDate = new Date(today);
      startDate.setDate(today.getDate() - diffToMonday - 7);
      const endDate = new Date(today);
      endDate.setDate(today.getDate() - 7);

      return { startDate, endDate };
    }
  },
  {
    label: 'Month to date',
    value: DEFAULT_DATE_OPTIONS_ENUM.MONTH_TO_DATE,
    start: '0m',
    end: 'today',
    getDateRange: () => {
      const today = new Date();
      const startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      const endDate = today;

      return { startDate, endDate };
    },
    getCompareDateRange: () => {
      const today = new Date();
      const startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      const endDate = new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        today.getDate()
      );
      return { startDate, endDate };
    }
  },
  {
    label: 'Quarter to date',
    value: DEFAULT_DATE_OPTIONS_ENUM.QUARTER_TO_DATE,
    start: '0q',
    end: 'today',
    getDateRange: () => {
      const today = new Date();
      const currentMonth = today.getMonth();
      const currentQuarterStartMonth = Math.floor(currentMonth / 3) * 3;
      const startDate = new Date(
        today.getFullYear(),
        currentQuarterStartMonth,
        1
      );
      const endDate = today;

      return { startDate, endDate };
    },
    getCompareDateRange: () => {
      const today = new Date();
      const currentMonth = today.getMonth();
      const lastQuarterStartMonth = currentMonth - (currentMonth % 3) - 3;

      const startDate = new Date(today.getFullYear(), lastQuarterStartMonth, 1);
      const endDate = new Date(
        today.getFullYear(),
        lastQuarterStartMonth + (today.getMonth() % 3),
        today.getDate()
      );

      return { startDate, endDate };
    }
  },
  {
    label: 'Year to date',
    value: DEFAULT_DATE_OPTIONS_ENUM.YEAR_TO_DATE,
    start: '0y',
    end: 'today',
    getDateRange: () => {
      const today = new Date();
      const startDate = new Date(today.getFullYear(), 0, 1);
      const endDate = today;

      return { startDate, endDate };
    },
    getCompareDateRange: () => {
      const today = new Date();
      const startDate = new Date(today.getFullYear() - 1, 0, 1);
      const endDate = new Date(
        today.getFullYear() - 1,
        today.getMonth(),
        today.getDate()
      );

      return { startDate, endDate };
    }
  }
];

const DEFAULT_COMPARE_DATE_OPTIONS = [
  {
    label: 'No comparison',
    value: 'noComparison',
    getDateRange: (datePeriod, startDate, endDate) => ({
      startDate: null,
      endDate: null
    })
  },
  {
    label: 'Previous period',
    value: 'previousPeriod',
    isDefault: true,
    getDateRange: (datePeriod, startDate, endDate) => {
      if (datePeriod) {
        return datePeriod.getCompareDateRange();
      }

      const dayDifference = (endDate - startDate) / (1000 * 60 * 60 * 24);

      const compareEndDate = new Date(startDate);
      compareEndDate.setDate(compareEndDate.getDate() - 1);

      const compareStartDate = new Date(compareEndDate);
      compareStartDate.setDate(compareEndDate.getDate() - dayDifference);
      return { startDate: compareStartDate, endDate: compareEndDate };
    }
  },
  {
    label: 'Previous year',
    value: 'previousYear',
    getDateRange: (datePeriod, startDate, endDate) => {
      const start = new Date(startDate);
      const end = new Date(endDate);

      const previousYearStart = new Date(
        start.getFullYear() - 1,
        start.getMonth(),
        start.getDate()
      );
      const previousYearEnd = new Date(
        end.getFullYear() - 1,
        end.getMonth(),
        end.getDate()
      );

      return { startDate: previousYearStart, endDate: previousYearEnd };
    }
  }
];

export {
  DEFAULT_DATE_OPTIONS_ENUM,
  DEFAULT_DATE_OPTIONS,
  DEFAULT_COMPARE_DATE_OPTIONS
};
