import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Papa from 'papaparse';
import { Alert, Button, Checkbox, TextInput } from '@mantine/core';
import { Download } from 'tabler-icons-react';
import ResponsiveModal from '../../common/ResponsiveModal';
import FormSection from '../../common/FormSection';
import ConfirmModal from '../../common/ConfirmModal';
import { validateEvaluationQuestionRestrictions } from '../../../helpers/evaluationPlayerHelper';
import {
  REG_FORM_CONTROL_TYPE_ENUM,
  REG_SCOUT_REPORT_QUESTION_TYPE
} from '../../../config/constants';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import FileUploader from '../../common/FileUploader';
import { triggerNotification } from '../../../helpers/notificationHelper';

const defaultAttributes = [
  { value: 'Jersey Number', dataValue: 'jerseyNumber' },
  { value: 'Jersey Color', dataValue: 'jerseyColor' }
];

const EvaluationSessionEntryUploadModal = ({
  isOpen,
  onClose,
  showDownload,
  regScoutReportEvaluationSession,
  regScoutReportEvaluations,
  regScoutReportQuestions,
  regScoutReportEvaluationSessionEntry,
  onSaveEvaluationEntry,
  onUpdateEvaluations
}) => {
  const [formState, setFormState] = useState({
    file: null,
    uploadedRegScoutReportEvaluations: [],
    showConfirm: false,
    showDownload: false,
    downloadAllQuestions: false,
    isLoading: false
  });

  useEffect(() => {
    if (isOpen) {
      setFormState({
        file: null,
        uploadedRegScoutReportEvaluations: [],
        showConfirm: false,
        showDownload: false,
        downloadAllQuestions: false,
        isLoading: false
      });
    }
  }, [isOpen]);

  const onFinishDownload = () => {
    if (formState.showDownload) {
      setFormState({ ...formState, showDownload: false });
    }
    else {
      onClose();
    }
  };

  const onDownloadEntry = () => {
    const evalQuestions = regScoutReportQuestions
      .filter(
        (q) =>
          formState.downloadAllQuestions ||
          q.fkRegScoutReportQuestionType ===
            (regScoutReportEvaluationSessionEntry
              ? REG_SCOUT_REPORT_QUESTION_TYPE.METRIC
              : REG_SCOUT_REPORT_QUESTION_TYPE.ATTRIBUTE)
      )
      .sort(
        (a, b) =>
          a.fkRegScoutReportQuestionType - b.fkRegScoutReportQuestionType ||
          a.sort - b.sort
      );
    const defaultEvalAttributes =
      formState.downloadAllQuestions || !regScoutReportEvaluationSessionEntry
        ? defaultAttributes
        : [];

    const fields = [
      'Player',
      ...defaultEvalAttributes.map((q) => q.value),
      ...evalQuestions.map((q) => q.value)
    ];

    const data = regScoutReportEvaluations.map((e) => [
      `${e.jerseyNumber ? `${e.jerseyNumber} - ` : ''}${e.person.firstName} ${
        e.person.lastName
      }`.trim(),
      ...defaultEvalAttributes.map((a) => e[a.dataValue] ?? ''),
      ...evalQuestions.map((q) => {
        const isQuestionAllowed = validateEvaluationQuestionRestrictions(e, q);
        const answer = e.regScoutReportQuestionAnswers.find(
          (a) =>
            a.fkRegScoutReportQuestion === q.pkRegScoutReportQuestion &&
            a.fkRegScoutReportEvaluationSessionEntry ===
              (q.fkRegScoutReportQuestionType ===
              REG_SCOUT_REPORT_QUESTION_TYPE.ATTRIBUTE
                ? null
                : regScoutReportEvaluationSessionEntry?.pkRegScoutReportEvaluationSessionEntry)
        );
        return !isQuestionAllowed ? 'N/A' : answer ? answer.value : '';
      })
    ]);

    const csv = Papa.unparse({
      fields,
      data
    });
    const blob = new Blob([csv]);
    const csvURL = URL.createObjectURL(blob, { type: 'text/plain' });
    const link = document.createElement('a');
    link.setAttribute('href', csvURL);
    link.setAttribute(
      'download',
      `${regScoutReportEvaluationSession.name}${
        regScoutReportEvaluationSessionEntry
          ? `-${regScoutReportEvaluationSessionEntry.regAssociationAdmin.user.name}`
          : ''
      }-${dayjs(new Date()).format('MM/DD/YYYY_h:mma')}.csv`
    );
    document.body.appendChild(link);
    link.click();
    onFinishDownload();
  };

  const onUploadCsv = (file) => {
    setFormState({ ...formState, isLoading: true });

    Papa.parse(file, {
      complete: (results) => {
        const evaluations = results.data.splice(1).reduce((r, c) => {
          const evaluation = regScoutReportEvaluations.find(
            (e) =>
              `${e.jerseyNumber ? `${e.jerseyNumber} - ` : ''}${
                e.person.firstName
              } ${e.person.lastName}` === c[0].trim()
          );

          if (evaluation) {
            const updatedEvaluationAnswers = results.data[0]
              .map((q, index) => {
                const defaultAttribute = defaultAttributes.find(
                  (a) => a.value.toLowerCase().trim() === q.toLowerCase().trim()
                );
                if (defaultAttribute) {
                  return {
                    customQuestion: defaultAttribute.value,
                    fkRegScoutReportQuestion: null,
                    fkRegScoutReportEvaluationSessionEntry: null,
                    value: c[index]?.trim()
                  };
                }

                const question = regScoutReportQuestions.find(
                  (f) => f.value.toLowerCase().trim() === q.toLowerCase().trim()
                );
                if (question) {
                  const answer = c[index]?.trim() ?? '';

                  const answerOptionValue = question.regScoutReportQuestionOptions.find(
                    (op) =>
                      op.value.toLowerCase() === answer.toLowerCase() ||
                      op.sort.toString() === answer
                  )?.value;

                  return {
                    customQuestion: null,
                    fkRegScoutReportQuestion: question.pkRegScoutReportQuestion,
                    fkRegScoutReportEvaluationSessionEntry:
                      regScoutReportEvaluationSessionEntry?.pkRegScoutReportEvaluationSessionEntry ??
                      null,
                    value:
                      answer.toLowerCase() === 'n/a'
                        ? ''
                        : question.fkRegFormControlType ===
                            REG_FORM_CONTROL_TYPE_ENUM.BUTTON_GROUP ||
                          question.fkRegFormControlType ===
                            REG_FORM_CONTROL_TYPE_ENUM.DROP_DOWN
                        ? answerOptionValue
                        : answer
                  };
                }

                return null;
              })
              .filter((a) => !!a);

            r.push({
              ...evaluation,
              regScoutReportQuestionAnswers: updatedEvaluationAnswers
            });
          }
          return r;
        }, []);

        setFormState({
          ...formState,
          file,
          uploadedRegScoutReportEvaluations: evaluations,
          isLoading: false
        });
      }
    });
  };

  const onConfirmUpload = () => {
    if (regScoutReportEvaluationSessionEntry) {
      setFormState({
        ...formState,
        isLoading: true
      });
      onSaveEvaluationEntry(
        regScoutReportEvaluationSessionEntry.pkRegScoutReportEvaluationSessionEntry,
        () => {
          triggerNotification('Entry Updated!', 'success', 'green');
          onClose();
        },
        (e) => {
          triggerNotification(e);
          setFormState({
            ...formState,
            isLoading: false
          });
        },
        { uploadedEvaluations: formState.uploadedRegScoutReportEvaluations }
      );
    }
    else {
      onUpdateEvaluations(
        regScoutReportEvaluations.map((e) => {
          const uploadedEvaluation = formState.uploadedRegScoutReportEvaluations.find(
            (f) =>
              f.person.entityId === e.person.entityId &&
              f.person.isCustomPerson === e.person.isCustomPerson
          );

          const uploadedAttributeQuestions =
            uploadedEvaluation?.regScoutReportQuestionAnswers.filter(
              (a) =>
                !defaultAttributes.some(
                  (f) =>
                    f.value.toLowerCase().trim() ===
                    a.customQuestion?.toLowerCase().trim()
                )
            ) ?? [];

          const uploadedDefaultQuestions =
            uploadedEvaluation?.regScoutReportQuestionAnswers.filter((a) =>
              defaultAttributes.some(
                (f) =>
                  f.value.toLowerCase().trim() ===
                  a.customQuestion?.toLowerCase().trim()
              )
            ) ?? [];

          const questionAnswers = [
            ...e.regScoutReportQuestionAnswers.map((q) => {
              const uploadedAnswer = uploadedAttributeQuestions.find(
                (a) =>
                  a.customQuestion === q.customQuestion &&
                  a.fkRegScoutReportQuestion === q.fkRegScoutReportQuestion &&
                  a.fkRegScoutReportEvaluationSessionEntry === null
              );

              return {
                ...q,
                value: uploadedAnswer ? uploadedAnswer.value : q.value
              };
            }),
            ...uploadedAttributeQuestions
              .filter(
                (a) =>
                  !e.regScoutReportQuestionAnswers.some(
                    (s) =>
                      a.customQuestion === s.customQuestion &&
                      a.fkRegScoutReportQuestion ===
                        s.fkRegScoutReportQuestion &&
                      a.fkRegScoutReportEvaluationSessionEntry === null
                  )
              )
              .map((a) => ({
                customQuestion: a.customQuestion,
                fkRegScoutReportEvaluation: e.pkRegScoutReportEvaluation,
                fkRegScoutReportQuestion: null,
                value: a.value,
                fkRegScoutReportEvaluationSessionEntry: null
              }))
          ];

          const updateEvaluation = {
            ...e,
            regScoutReportQuestionAnswers: questionAnswers
          };

          uploadedDefaultQuestions.forEach((q) => {
            const defaultAttribute = defaultAttributes.find(
              (f) =>
                f.value.toLowerCase().trim() ===
                q.customQuestion?.toLowerCase().trim()
            );
            updateEvaluation[defaultAttribute.dataValue] = q.value;
          });

          return updateEvaluation;
        })
      );
      onClose();
    }
  };

  return (
    <>
      <ResponsiveModal
        isOpen={isOpen && !showDownload}
        onClose={onClose}
        title={`Upload Evaluation${
          regScoutReportEvaluationSessionEntry ? ' Entry' : ''
        }`}
      >
        <FormSection
          isLoading={formState.isLoading}
          isSubmitDisabled={!formState.file}
          onCancel={onClose}
          onSubmit={() =>
            setFormState({
              ...formState,
              showConfirm: true
            })
          }
          submitTitle="Upload"
        >
          <AppStack style={{ gap: 20 }}>
            <Alert color="blue" variant="outline">
              <AppStack style={{ gap: 10 }}>
                <AppText style={{ fontSize: 14 }}>
                  {regScoutReportEvaluationSessionEntry
                    ? `Upload a CSV file with the evaluation entry for ${regScoutReportEvaluationSessionEntry?.regAssociationAdmin.user.name}.`
                    : ''}
                  The file should contain the player's name and the evaluation
                  answers for each question. The first row should contain the
                  column headers. The first column should contain the player's
                  name. The remaining columns should contain the evaluation
                  answers for each question.
                </AppText>

                <AppText style={{ fontSize: 14 }}>
                  If a question is not applicable to a player, the answer should
                  be "N/A". If a question is not answered, the answer should be
                  left blank.
                </AppText>

                <AppStack
                  style={{ alignItems: 'center', textAlign: 'center', gap: 5 }}
                >
                  <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                    Download the current{' '}
                    {regScoutReportEvaluationSessionEntry
                      ? 'evaluation entry'
                      : 'evaluation'}{' '}
                    to get started.
                  </AppText>
                  <Button
                    color="dark"
                    onClick={() =>
                      setFormState({
                        ...formState,
                        showDownload: true
                      })
                    }
                    rightSection={<Download />}
                    variant="outline"
                  >
                    Download{' '}
                    {regScoutReportEvaluationSessionEntry ? 'Entry' : ''}
                  </Button>
                </AppStack>
              </AppStack>
            </Alert>

            <FileUploader
              accept=".csv"
              height={300}
              onError={triggerNotification}
              onUpload={onUploadCsv}
              validFileTypes={['text/csv']}
            />

            <TextInput
              disabled
              label="File"
              onChange={() => {}}
              style={{ flex: 1 }}
              value={formState.file?.name || ''}
            />

            <Alert color="yellow" variant="outline">
              <AppStack style={{ fontSize: 14, fontWeight: 500, gap: 10 }}>
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  This action cannot be undone.
                </AppText>
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  This will overwrite any existing answers for this{' '}
                  {regScoutReportEvaluationSessionEntry
                    ? 'evaluation entry.'
                    : 'evaluation.'}
                </AppText>
              </AppStack>
            </Alert>
          </AppStack>
        </FormSection>
      </ResponsiveModal>

      <ConfirmModal
        confirmActionColor="red"
        isLoading={formState.isLoading}
        isOpen={isOpen && formState.showConfirm}
        message={
          <AppStack style={{ gap: 20 }}>
            {regScoutReportEvaluationSessionEntry ? (
              <AppText style={{ fontSize: 16, textAlign: 'center' }}>
                Are you sure you want to upload the evaluation entry for{' '}
                <b>
                  {
                    regScoutReportEvaluationSessionEntry?.regAssociationAdmin
                      .user.name
                  }
                </b>
                ?
              </AppText>
            ) : (
              <AppText style={{ fontSize: 16, textAlign: 'center' }}>
                Are you sure you want to upload the evaluation attributes?
              </AppText>
            )}
            <Alert color="yellow" variant="outline">
              <AppStack style={{ fontSize: 14, fontWeight: 500, gap: 10 }}>
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  This action cannot be undone.
                </AppText>
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  This will overwrite any existing answers for this{' '}
                  {regScoutReportEvaluationSessionEntry
                    ? 'evaluation entry.'
                    : 'evaluation.'}
                </AppText>
              </AppStack>
            </Alert>
          </AppStack>
        }
        onCancel={() => {
          setFormState({ ...formState, showConfirm: false });
        }}
        onConfirm={onConfirmUpload}
        title="Confirm Evaluation Entry"
      />

      <ConfirmModal
        confirmActionColor="blue"
        confirmActionText="Download"
        isOpen={showDownload || formState.showDownload}
        message={`Are you sure you want to download the evaluation${
          regScoutReportEvaluationSessionEntry
            ? ` entry for ${regScoutReportEvaluationSessionEntry?.regAssociationAdmin.user.name}`
            : ''
        }?`}
        onCancel={onFinishDownload}
        onConfirm={onDownloadEntry}
        title="Download Evaluation Entry"
      >
        {regScoutReportEvaluationSessionEntry && (
          <Checkbox
            checked={formState.downloadAllQuestions}
            label="Include player attributes"
            onChange={() =>
              setFormState({
                ...formState,
                downloadAllQuestions: !formState.downloadAllQuestions
              })
            }
            style={{ fontWeight: 500 }}
          />
        )}
      </ConfirmModal>
    </>
  );
};

EvaluationSessionEntryUploadModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSaveEvaluationEntry: PropTypes.func,
  onUpdateEvaluations: PropTypes.func,
  regScoutReportEvaluationSession: PropTypes.object,
  regScoutReportEvaluationSessionEntry: PropTypes.object,
  regScoutReportEvaluations: PropTypes.array,
  regScoutReportQuestions: PropTypes.array,
  showDownload: PropTypes.bool
};

export default EvaluationSessionEntryUploadModal;
