import React from 'react';
import PropTypes from 'prop-types';
import { REG_ASSOCIATION_SOCIAL_TYPES } from '../../../config/constants';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppCard from '../../common/AppCard';
import AppText from '../../common/AppText';
import ActionableIcon from '../../common/ActionableIcon';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const AssociationSocials = ({
  socials,
  regAssociation,
  primaryColor,
  primaryTextColor
}) => {
  const mqIndex = useMediaQueryIndex();
  return (
    <>
      <AppCard
        hiddenFrom="md"
        shadow="lg"
        style={{ padding: 0, borderRadius: 0 }}
      >
        <AppFlexbox
          style={{
            flex: 1,
            justifyContent: rs(['center', 'space-between'], mqIndex),
            padding: 10,
            gap: 0,
            alignItems: 'center'
          }}
        >
          <AppText visibleFrom="xs" weight={700}>
            Follow {regAssociation.association.name}
          </AppText>

          <AppFlexbox
            style={{
              flex: 1,
              justifyContent: rs(['center', 'flex-end'], mqIndex),
              flexWrap: 'nowrap'
            }}
          >
            {socials.map((s) => {
              const socialType = REG_ASSOCIATION_SOCIAL_TYPES.find(
                (r) =>
                  r.pkRegAssociationSocialType === s.fkRegAssociationSocialType
              );

              return (
                <ActionableIcon
                  key={s.fkRegAssociationSocialType}
                  color="dark"
                  component="a"
                  href={s.value}
                  radius="xl"
                  size={45}
                  style={{ padding: 5 }}
                  target="_blank"
                  variant="outline"
                >
                  {socialType?.icon && <socialType.icon />}
                </ActionableIcon>
              );
            })}
          </AppFlexbox>
        </AppFlexbox>
      </AppCard>

      <AppCard shadow="lg" style={{ padding: 0 }} visibleFrom="md">
        <AppStack style={{ flex: 1, gap: 0 }}>
          <AppStack
            style={{
              padding: 10,
              backgroundColor: primaryColor,
              color: primaryTextColor,
              borderBottom: 'solid 1px lightgrey'
            }}
          >
            <AppText style={{ textAlign: 'center' }} weight={500}>
              Social Media
            </AppText>
          </AppStack>

          <AppFlexbox
            style={{
              flex: 1,
              justifyContent: 'center',
              padding: 15
            }}
          >
            {socials.map((s) => {
              const socialType = REG_ASSOCIATION_SOCIAL_TYPES.find(
                (r) =>
                  r.pkRegAssociationSocialType === s.fkRegAssociationSocialType
              );

              return (
                <ActionableIcon
                  key={s.fkRegAssociationSocialType}
                  color="dark"
                  component="a"
                  href={s.value}
                  radius="xl"
                  size={45}
                  style={{ padding: 5 }}
                  target="_blank"
                  variant="outline"
                >
                  {socialType?.icon && <socialType.icon />}
                </ActionableIcon>
              );
            })}
          </AppFlexbox>
        </AppStack>
      </AppCard>
    </>
  );
};

AssociationSocials.propTypes = {
  primaryColor: PropTypes.string,
  primaryTextColor: PropTypes.string,
  regAssociation: PropTypes.object,
  socials: PropTypes.array
};

export default AssociationSocials;
