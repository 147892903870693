import { PropTypes } from 'prop-types';
import React, { useContext, useEffect, useRef } from 'react';
import { Select, MultiSelect } from '@mantine/core';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import AppStack from '../../common/AppStack';

const FormReportFilter = ({
  isLoading,
  filterState,
  pkRegAssociation,
  onFormChange,
  onSeasonChange,
  onDivisionChange,
  hideSeasonDivisionSelects,
  disableFormSelect
}) => {
  const multiSelectRef = useRef(null);
  const { state } = useContext(RegistrationAdminContext);

  const regFormOptions = state.regForms.value
    .filter(
      (f) => f.fkRegAssociation.toString() === pkRegAssociation?.toString()
    )
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((f) => ({ label: f.name, value: f.uuid }));
  const selectedRegForm = state.regForms.value.find(
    (f) => f.uuid === filterState.uuid
  );
  const divisionForms =
    selectedRegForm?.regAssociationDivisionForms.filter(
      (f) => !f.deleted && !f.regAssociationDivision.deleted
    ) ?? [];

  const availablePkSeasons =
    divisionForms.map((f) => f.regAssociationDivision.fkRegAssociationSeason) ??
    [];

  const seasonOptions =
    state.regAssociation.value?.regAssociationSeasons
      ?.filter((s) => availablePkSeasons.includes(s.pkRegAssociationSeason))
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((s) => ({
        label: s.name,
        value: s.pkRegAssociationSeason.toString()
      })) ?? [];

  useEffect(() => {
    if (!filterState.uuid) {
      onFormChange(regFormOptions[0]?.value ?? '');
    }
  }, [state.regForms.value]);

  return (
    <AppStack>
      <Select
        clearable
        data={regFormOptions}
        disabled={isLoading || disableFormSelect}
        label="Form"
        onChange={onFormChange}
        required
        searchable
        value={filterState.uuid}
      />

      {!hideSeasonDivisionSelects && (
        <>
          <Select
            clearable
            data={seasonOptions}
            disabled={isLoading}
            label="Season"
            onChange={onSeasonChange}
            placeholder="All Seasons"
            searchable
            value={filterState.pkRegAssociationSeason}
          />
          <MultiSelect
            ref={multiSelectRef}
            clearable
            data={
              divisionForms
                .filter(
                  (f) =>
                    !filterState.pkRegAssociationSeason ||
                    filterState.pkRegAssociationSeason.toString() ===
                      f.regAssociationDivision.fkRegAssociationSeason.toString()
                )
                .map((f) => ({
                  label: f.regAssociationDivision.name,
                  value: f.regAssociationDivision.pkRegAssociationDivision.toString()
                })) ?? []
            }
            disabled={isLoading}
            label="Division"
            onChange={(values) => {
              onDivisionChange(values);
              multiSelectRef.current?.blur();
            }}
            placeholder="All Divisions"
            searchable
            value={filterState.pkRegAssociationDivisions}
          />
        </>
      )}
    </AppStack>
  );
};

FormReportFilter.propTypes = {
  disableFormSelect: PropTypes.bool,
  filterState: PropTypes.object,
  hideSeasonDivisionSelects: PropTypes.bool,
  isLoading: PropTypes.bool,
  onDivisionChange: PropTypes.func,
  onFormChange: PropTypes.func,
  onSeasonChange: PropTypes.func,
  pkRegAssociation: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default FormReportFilter;
