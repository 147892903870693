import { Box, Divider, Tooltip } from '@mantine/core';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { AlertCircle, Check, Plus, Settings } from 'tabler-icons-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import FormControlModal from './FormControlModal';
import FormSectionModal from './FormSectionModal';
import { calculateOpenPosition } from '../../../helpers/formBuilderHelper';
import AppCard from '../../common/AppCard';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import ActionableIcon from '../../common/ActionableIcon';

const FormBuilderMenu = ({
  sections,
  selectedPkRegFormControl,
  selectedPkRegFormSection,
  onSelectControl,
  onSelectSection,
  onChangeSectionData,
  isFormSectionModalOpen,
  onCloseFormModal,
  onDocumentUpload,
  lowestCostDivisionForm,
  largestAddOnDiscountAmount
}) => {
  const mqIndex = useMediaQueryIndex();
  const [sectionModalState, setSectionModalState] = useState({
    isOpen: false,
    selectedSection: null
  });
  const [controlModalState, setControlModalState] = useState({
    isOpen: false,
    selectedControl: null
  });
  const sectionControls =
    sections
      .find((s) => s.pkRegFormSection === selectedPkRegFormSection)
      ?.regFormControls.sort((a, b) =>
        a.positionRow === b.positionRow
          ? a.positionColumn - b.positionColumn
          : a.positionRow - b.positionRow
      ) ?? [];

  const lowestPosibleRegistrationCost =
    lowestCostDivisionForm.regProduct.price -
      (largestAddOnDiscountAmount ?? 0) <
    0
      ? 0
      : lowestCostDivisionForm.regProduct.price -
        (largestAddOnDiscountAmount ?? 0);

  useEffect(() => {
    if (isFormSectionModalOpen) {
      setSectionModalState({
        isOpen: true,
        selectedSection: null
      });
    }
  }, [isFormSectionModalOpen]);

  return (
    <AppCard
      radius={0}
      shadow="md"
      style={{
        display: 'flex',
        flex: 1,
        padding: 0,
        maxWidth: rs(['unset', 'unset', 400], mqIndex),
        zIndex: 10,
        overflow: 'unset',
        position: 'realative'
      }}
    >
      <AppStack
        style={{
          gap: 0,
          top: 170,
          position: rs(['unset', 'unset', 'sticky'], mqIndex),
          maxHeight: rs(['unset', 'unset', 'calc(100vh - 170px)'], mqIndex),
          overflowY: 'auto'
        }}
      >
        <AppStack style={{ gap: 0 }}>
          <AppStack style={{ margin: 20 }}>
            <AppFlexbox style={{ justifyContent: 'space-between' }}>
              <AppText weight={700}>Sections</AppText>
              <ActionableIcon
                color="green"
                onClick={() =>
                  setSectionModalState({
                    ...sectionModalState,
                    isOpen: true,
                    selectedSection: null
                  })
                }
              >
                <Plus />
              </ActionableIcon>
            </AppFlexbox>
            <AppStack>
              <DragDropContext
                onDragEnd={(e) => {
                  if (e.source.index === e.destination.index) {
                    return;
                  }

                  const sectionsCopy = [
                    ...sections
                      .sort((a, b) => a.positionOrder - b.positionOrder)
                      .map((s, index) => ({
                        ...s,
                        positionOrder: index
                      }))
                  ];
                  const unmanagedSectionsCount = sections.filter(
                    (s) => s.isManaged
                  ).length;
                  const oldIndex = e.source.index + unmanagedSectionsCount;
                  const newIndex = e.destination.index + unmanagedSectionsCount;

                  const isGreaterIndex = newIndex > oldIndex;
                  for (const element of sectionsCopy) {
                    if (element.positionOrder === oldIndex) {
                      element.positionOrder = newIndex;
                    }
                    else if (
                      isGreaterIndex &&
                      element.positionOrder >= oldIndex &&
                      element.positionOrder <= newIndex
                    ) {
                      element.positionOrder -= 1;
                    }
                    else if (
                      element.positionOrder <= oldIndex &&
                      element.positionOrder >= newIndex
                    ) {
                      element.positionOrder += 1;
                    }
                  }
                  onChangeSectionData(sectionsCopy);
                }}
              >
                <Droppable droppableId="droppable">
                  {(dropProvided) => (
                    <div
                      {...dropProvided.droppableProps}
                      ref={dropProvided.innerRef}
                    >
                      {sections
                        .filter((f) => !f.isManaged)
                        .sort((a, b) => a.positionOrder - b.positionOrder)
                        .map((s, index) => {
                          const isSelected =
                            s.pkRegFormSection === selectedPkRegFormSection;
                          return (
                            <Draggable
                              key={s.pkRegFormSection}
                              draggableId={s.pkRegFormSection.toString()}
                              index={index}
                            >
                              {(dragProvided) => (
                                <div
                                  ref={dragProvided.innerRef}
                                  {...dragProvided.draggableProps}
                                  {...dragProvided.dragHandleProps}
                                >
                                  <AppFlexbox
                                    key={s.pkRegFormSection}
                                    onClick={() => onSelectSection(s)}
                                    style={{
                                      minHeight: 50,
                                      padding: isSelected
                                        ? '9px 19px'
                                        : '10px 20px',
                                      cursor: 'pointer',
                                      fontWeight: isSelected ? 700 : 'normal',
                                      boxSizing: 'border-box',
                                      border: isSelected
                                        ? 'solid 1px lightgrey'
                                        : null,
                                      '&:hover': {
                                        border: 'solid 1px lightgrey',
                                        '& > button': { display: 'flex' },
                                        padding: '9px 19px'
                                      }
                                    }}
                                  >
                                    {isSelected ? (
                                      <Check color="green" />
                                    ) : (
                                      <Box style={{ width: 24 }} />
                                    )}
                                    <AppFlexbox style={{ gap: 10 }}>
                                      <AppText>{s.title}</AppText>

                                      {s.regFormControls.some(
                                        (c) =>
                                          c.product?.price < 0 ||
                                          c.options.some(
                                            (p) => p.product?.price < 0
                                          )
                                      ) && (
                                        <Tooltip
                                          color="orange"
                                          label={
                                            <AppStack>
                                              <AppText>
                                                This section contains controls
                                                that allow reducing the total
                                                cost of the registration.
                                              </AppText>
                                              <AppText weight={500}>
                                                The lowest total cost is $
                                                {lowestPosibleRegistrationCost}{' '}
                                                for the division '
                                                {
                                                  lowestCostDivisionForm
                                                    .regAssociationDivision.name
                                                }
                                                '
                                              </AppText>
                                            </AppStack>
                                          }
                                          multiline
                                          style={{ width: 250 }}
                                          withArrow
                                          withinPortal
                                        >
                                          <AppStack>
                                            <AlertCircle
                                              color="orange"
                                              size={22}
                                              style={{ marginTop: 3 }}
                                            />
                                          </AppStack>
                                        </Tooltip>
                                      )}
                                    </AppFlexbox>
                                    <ActionableIcon
                                      color="dark"
                                      onClick={() =>
                                        setSectionModalState({
                                          ...sectionModalState,
                                          isOpen: true,
                                          selectedSection: s
                                        })
                                      }
                                      style={{
                                        marginLeft: 'auto',
                                        display: isSelected ? 'flex' : 'none'
                                      }}
                                    >
                                      <Settings />
                                    </ActionableIcon>
                                  </AppFlexbox>
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                      {dropProvided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </AppStack>
          </AppStack>
          <Divider />
          <AppStack style={{ margin: 20 }}>
            <AppFlexbox style={{ justifyContent: 'space-between' }}>
              <AppText weight={700}>Controls</AppText>
              {selectedPkRegFormSection && (
                <ActionableIcon
                  color="green"
                  onClick={() =>
                    setControlModalState({
                      ...controlModalState,
                      isOpen: true,
                      selectedControl: null
                    })
                  }
                >
                  <Plus />
                </ActionableIcon>
              )}
            </AppFlexbox>
            <AppStack>
              {sectionControls.map((c) => {
                const isSelected =
                  c.pkRegFormControl === selectedPkRegFormControl;

                return (
                  <AppFlexbox
                    key={c.pkRegFormControl}
                    onClick={() => onSelectControl(c)}
                    style={{
                      minHeight: 50,
                      padding: isSelected ? '9px 19px' : '10px 20px',
                      cursor: 'pointer',
                      border: isSelected ? 'solid 1px lightgrey' : null,
                      '&:hover': {
                        border: 'solid 1px lightgrey',
                        '& > button': { display: 'flex' },
                        padding: '9px 19px'
                      },
                      boxSizing: 'border-box',
                      overflowWrap: 'anywhere',
                      flexWrap: 'nowrap'
                    }}
                  >
                    <AppFlexbox style={{ gap: 10 }}>
                      <AppText>
                        {c.type === 'TEXT' ? 'Text Message' : c.title}
                      </AppText>
                      {(c.product?.price < 0 ||
                        c.options.some((p) => p.product?.price < 0)) && (
                        <Tooltip
                          color="orange"
                          label={
                            <AppStack>
                              <AppText>
                                This control allows discount products that
                                reduce the total cost of the registration.
                              </AppText>
                              <AppText weight={500}>
                                The lowest total cost is $
                                {lowestPosibleRegistrationCost} for the division
                                '
                                {
                                  lowestCostDivisionForm.regAssociationDivision
                                    .name
                                }
                                '
                              </AppText>
                            </AppStack>
                          }
                          multiline
                          style={{ width: 250 }}
                          withArrow
                          withinPortal
                        >
                          <AppStack>
                            <AlertCircle
                              color="orange"
                              size={22}
                              style={{ marginTop: 3 }}
                            />
                          </AppStack>
                        </Tooltip>
                      )}
                    </AppFlexbox>

                    <ActionableIcon
                      color="dark"
                      onClick={() =>
                        setControlModalState({
                          ...controlModalState,
                          isOpen: true,
                          selectedControl: c
                        })
                      }
                      style={{
                        marginLeft: 'auto',
                        display: isSelected ? 'flex' : 'none'
                      }}
                    >
                      <Settings />
                    </ActionableIcon>
                  </AppFlexbox>
                );
              })}
            </AppStack>
          </AppStack>
        </AppStack>
      </AppStack>
      <FormSectionModal
        isOpen={sectionModalState.isOpen}
        onAdd={(data) => {
          const newSection = {
            ...data,
            pkRegFormSection: `temp-section-${new Date().getTime()}`,
            positionOrder: sections.length,
            regFormControls: []
          };
          onChangeSectionData([...sections, newSection]);
          onSelectSection(newSection);
        }}
        onClose={() => {
          setSectionModalState({
            ...sectionModalState,
            isOpen: false
          });
          onCloseFormModal();
        }}
        onRemove={(pkRegFormSection) => {
          onSelectSection(
            sections.find(
              (s) => !s.isManaged && s.pkRegFormSection !== pkRegFormSection
            )
          );
          onChangeSectionData([
            ...sections.filter((s) => s.pkRegFormSection !== pkRegFormSection)
          ]);
        }}
        onUpdate={(pkRegFormSection, data) => {
          const updateSection = sections.find(
            (s) => s.pkRegFormSection === pkRegFormSection
          );
          onChangeSectionData([
            ...sections.filter((s) => s.pkRegFormSection !== pkRegFormSection),
            {
              ...updateSection,
              ...data
            }
          ]);
        }}
        section={sectionModalState.selectedSection}
      />
      <FormControlModal
        control={controlModalState.selectedControl}
        isOpen={controlModalState.isOpen}
        lowestCostDivisionForm={lowestCostDivisionForm}
        lowestPosibleRegistrationCost={lowestPosibleRegistrationCost}
        onAdd={(pkRegFormSection, data) => {
          const updatedSection = sections.find(
            (s) => s.pkRegFormSection === pkRegFormSection
          );

          const openPosition = calculateOpenPosition(updatedSection, data);
          updatedSection.regFormControls.push({
            ...data,
            pkRegFormControl: `temp-control-${new Date().getTime()}`,
            positionRow: openPosition.positionRow,
            positionColumn: openPosition.positionColumn,
            positionSpan: openPosition.positionSpan
          });
          onChangeSectionData([
            ...sections.filter((s) => s.pkRegFormSection !== pkRegFormSection),
            updatedSection
          ]);
        }}
        onClose={() =>
          setControlModalState({
            ...controlModalState,
            isOpen: false
          })
        }
        onDocumentUpload={onDocumentUpload}
        onRemove={(pkRegFormControl) => {
          const updateSection = sections.find(
            (s) =>
              s.regFormControls.findIndex(
                (c) => c.pkRegFormControl === pkRegFormControl
              ) !== -1
          );
          updateSection.regFormControls = updateSection.regFormControls.filter(
            (c) => c.pkRegFormControl !== pkRegFormControl
          );

          onSelectControl(null);
          onChangeSectionData([
            ...sections.filter(
              (s) => s.pkRegFormSection !== updateSection.pkRegFormSection
            ),
            updateSection
          ]);
        }}
        onUpdate={(pkRegFormControl, data) => {
          const updateSection = sections.find(
            (s) =>
              s.regFormControls.findIndex(
                (c) => c.pkRegFormControl === pkRegFormControl
              ) !== -1
          );
          const updateControl = updateSection.regFormControls.find(
            (c) => c.pkRegFormControl === pkRegFormControl
          );
          onChangeSectionData([
            ...sections.filter(
              (s) => s.pkRegFormSection !== updateSection.pkRegFormSection
            ),
            {
              ...updateSection,
              regFormControls: [
                ...updateSection.regFormControls.filter(
                  (c) => c.pkRegFormControl !== pkRegFormControl
                ),
                { ...updateControl, ...data }
              ]
            }
          ]);
        }}
        pkRegFormSection={selectedPkRegFormSection}
      />
    </AppCard>
  );
};

FormBuilderMenu.propTypes = {
  isFormSectionModalOpen: PropTypes.bool,
  largestAddOnDiscountAmount: PropTypes.number,
  lowestCostDivisionForm: PropTypes.object,
  onChangeSectionData: PropTypes.func,
  onCloseFormModal: PropTypes.func,
  onDocumentUpload: PropTypes.func,
  onSelectControl: PropTypes.func,
  onSelectSection: PropTypes.func,
  sections: PropTypes.array,
  selectedPkRegFormControl: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  selectedPkRegFormSection: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

export default FormBuilderMenu;
