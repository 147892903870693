import React from 'react';
import { PropTypes } from 'prop-types';
import { Alert } from '@mantine/core';
import { InfoCircle } from 'tabler-icons-react';

const HelpBlurb = ({ title, icon, children, ...rest }) => (
  <Alert
    color="blue"
    icon={icon || <InfoCircle />}
    styles={{ root: { backgroundColor: 'rgba(208, 235, 255,0.2)' } }}
    title={title}
    variant="outline"
    {...rest}
  >
    {children}
  </Alert>
);

HelpBlurb.propTypes = {
  children: PropTypes.any,
  icon: PropTypes.node,
  title: PropTypes.string
};

export default HelpBlurb;
