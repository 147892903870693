import React, { useContext, useEffect, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Alert, Checkbox, List, MultiSelect, Select } from '@mantine/core';
import { ArrowBigDownLine, InfoCircle } from 'tabler-icons-react';
import { Context as SportsheadzWebContext } from '../../../providers/SportsheadzWebProvider';
import FormSection from '../../common/FormSection';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { triggerNotification } from '../../../helpers/notificationHelper';
import PaginationList from '../../common/PaginationList';
import LeagueTeamsModalImportTeamListItem from './LeagueTeamsModalImportTeamListItem';
import { formatUrl } from '../../../helpers/format';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';

const LeagueTeamsModalImportView = ({
  isHidden,
  onCancel,
  onClose,
  regAssociation
}) => {
  const { state, fetchAssociationTeams, fetchAssociationSeasons } = useContext(
    SportsheadzWebContext
  );
  const {
    state: regState,
    importRegAssociationDivisionTeams,
    fetchRegAssociationDivisionTeams
  } = useContext(RegistrationAdminContext);
  const [formState, setFormState] = useState({
    fkSeason: null,
    teams: [],
    fkRegAssociationSeason: null,
    fkRegAssociationDivision: null,
    isLoading: false,
    pageIndex: 1,
    showMappingView: false
  });
  const hasFetchedTeams = useRef(false);
  const associationUrl = regAssociation
    ? formatUrl(regAssociation.association.domain)
    : '';

  const associationSeasonOptions = state.seasons.value
    .sort((a, b) => a.Name.localeCompare(b.Name))
    .map((s) => ({
      value: s.ID.toString(),
      label: s.Name
    }));

  const associationSeasonTeams = state.teams.value
    .sort((a, b) => a.Name.localeCompare(b.Name))
    .filter(
      (t) =>
        t.App_ID &&
        (!formState.fkSeason || t.SeasonID.toString() === formState.fkSeason)
    );

  const regAssociationSeasonOptions = regState.regAssociationSeasons.value
    .filter((s) => s.fkRegAssociation === regAssociation?.pkRegAssociation)
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((a) => ({
      value: a.pkRegAssociationSeason.toString(),
      label: a.name
    }));

  const regAssociationDivisionOptions = regState.regAssociationDivisions.value
    .filter(
      (d) =>
        d.fkRegAssociationSeason.toString() === formState.fkRegAssociationSeason
    )
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((a) => ({
      value: a.pkRegAssociationDivision.toString(),
      label: a.name
    }));

  const allTeamsSelected =
    !(!hasFetchedTeams.current || state.teams.loading) &&
    !associationSeasonTeams.some(
      (t) => !formState.teams.some((team) => team.ID === t.ID)
    );

  const alreadyImportedTeams = regState.regAssociationDivisionTeamsSummary.value.filter(
    (t) =>
      formState.teams.some(
        (s) => s.App_ID === t.fkTeam && s.FullName === t.name
      )
  );

  useEffect(() => {
    if (!isHidden) {
      setFormState({
        ...formState,
        isLoading: false
      });
      if (!hasFetchedTeams.current) {
        fetchAssociationTeams(associationUrl);
        fetchAssociationSeasons(associationUrl);
        fetchRegAssociationDivisionTeams({
          pkRegAssociation: regAssociation.pkRegAssociation,
          summaryOnly: true
        });
        hasFetchedTeams.current = true;
      }
    }
  }, [isHidden]);

  const onSelectAll = () => {
    const teamIds = associationSeasonTeams.map((t) => t.ID);
    setFormState({
      ...formState,
      teams: [
        ...formState.teams.filter((t) => !teamIds.includes(t.ID)),
        ...associationSeasonTeams
      ]
    });
  };

  const onUnselectAll = () => {
    const teamIds = associationSeasonTeams.map((t) => t.ID);
    setFormState({
      ...formState,
      teams: [...formState.teams.filter((t) => !teamIds.includes(t.ID))]
    });
  };

  return (
    !isHidden &&
    (formState.showMappingView ? (
      <FormSection
        cancelTitle="Back"
        isLoading={formState.isLoading}
        onCancel={() =>
          setFormState({
            ...formState,
            showMappingView: false
          })
        }
        onSubmit={() => {
          setFormState({
            ...formState,
            isLoading: true
          });
          importRegAssociationDivisionTeams(
            formState.fkRegAssociationDivision,
            {
              teams: formState.teams.map((t) => ({
                pkTeam: t.App_ID,
                name: t.FullName
              }))
            },
            onClose,
            (message) => {
              triggerNotification(message);
              setFormState({
                ...formState,
                isLoading: false
              });
            }
          );
        }}
        style={{ gap: 30 }}
        submitTitle="Import"
      >
        <AppStack style={{ gap: 20 }}>
          <Alert variant="outline">
            <AppFlexbox style={{ gap: 10, justifyContent: 'center' }}>
              <InfoCircle color="dodgerblue" size={30} />
              <AppText style={{ textAlign: 'center' }} weight={500}>
                Select the season and division to import the teams to
              </AppText>
            </AppFlexbox>
          </Alert>
          <MultiSelect
            data={formState.teams.map((t) => ({
              value: t.ID.toString(),
              label: t.FullName
            }))}
            disabled
            label="Teams"
            value={formState.teams.map((t) => t.ID.toString())}
          />
          <AppStack style={{ gap: 0 }}>
            <AppStack style={{ alignItems: 'center' }}>
              <ArrowBigDownLine height={45} width={45} />
            </AppStack>
            <AppStack style={{ gap: 10 }}>
              <Select
                clearable
                data={regAssociationSeasonOptions}
                disabled={formState.isLoading}
                label="Season"
                onChange={(value) =>
                  setFormState({
                    ...formState,
                    fkRegAssociationSeason: value
                  })
                }
                required
                searchable
                value={formState.fkRegAssociationSeason}
              />
              <Select
                clearable
                data={regAssociationDivisionOptions}
                disabled={formState.isLoading}
                label="Division"
                onChange={(value) =>
                  setFormState({
                    ...formState,
                    fkRegAssociationDivision: value
                  })
                }
                required
                searchable
                value={formState.fkRegAssociationDivision}
              />
            </AppStack>
          </AppStack>
        </AppStack>
      </FormSection>
    ) : (
      <FormSection
        cancelTitle="Back"
        isLoading={formState.isSending}
        isSubmitDisabled={formState.teams.length === 0}
        onCancel={onCancel}
        onSubmit={() => {
          if (formState.teams.length > 0) {
            setFormState({
              ...formState,
              showMappingView: true
            });
          }
        }}
        style={{ gap: 30 }}
        submitTitle="Select"
      >
        <Select
          clearable
          data={associationSeasonOptions}
          label="Season"
          onChange={(value) =>
            setFormState({
              ...formState,
              fkSeason: value
            })
          }
          placeholder="Filter by season"
          searchable
          value={formState.fkSeason ?? ''}
        />

        <AppStack style={{ gap: 10 }}>
          <AppFlexbox style={{ flex: 1, justifyContent: 'space-between' }}>
            <AppText weight={500}>Teams</AppText>
            <Checkbox
              checked={associationSeasonTeams.length > 0 && allTeamsSelected}
              disabled={
                associationSeasonTeams.length === 0 ||
                !hasFetchedTeams.current ||
                state.teams.loading
              }
              label={
                associationSeasonTeams.length > 0 && allTeamsSelected
                  ? 'Unselect All'
                  : 'Select All'
              }
              onChange={() => {
                if (allTeamsSelected) {
                  onUnselectAll();
                }
                else {
                  onSelectAll();
                }
              }}
              style={{ fontWeight: 500 }}
            />
          </AppFlexbox>
          <AppStack style={{ gap: 0 }}>
            <PaginationList
              emptyMessage="No teams available"
              isLoading={!hasFetchedTeams.current || state.teams.loading}
              items={associationSeasonTeams.map((team) => {
                const isSelected =
                  formState.teams.findIndex((t) => t.ID === team.ID) !== -1;
                return (
                  <LeagueTeamsModalImportTeamListItem
                    key={team.ID}
                    isSelected={isSelected}
                    onSelect={() => {
                      if (isSelected) {
                        setFormState({
                          ...formState,
                          teams: [
                            ...formState.teams.filter((t) => t.ID !== team.ID)
                          ]
                        });
                      }
                      else {
                        setFormState({
                          ...formState,
                          teams: [...formState.teams, team]
                        });
                      }
                    }}
                    team={team}
                  />
                );
              })}
              itemsPerPage={10}
              LoadingComponent={LeagueTeamsModalImportTeamListItem}
              onPageChange={(index) =>
                setFormState({
                  ...formState,
                  pageIndex: index
                })
              }
              pageIndex={formState.pageIndex}
            />
          </AppStack>
        </AppStack>
        {alreadyImportedTeams.length > 0 && (
          <Alert color="yellow" variant="outline">
            <AppText weight={500}>
              The following teams have already been imported to different
              divisions;
            </AppText>
            <List withPadding>
              {alreadyImportedTeams.map((t) => (
                <List.Item key={t.pkRegAssociationDivisionTeam}>
                  {t.name} - {t.regAssociationDivision.name}
                </List.Item>
              ))}
            </List>
          </Alert>
        )}
      </FormSection>
    ))
  );
};

LeagueTeamsModalImportView.propTypes = {
  isHidden: PropTypes.bool,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  regAssociation: PropTypes.object
};

export default LeagueTeamsModalImportView;
