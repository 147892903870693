import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Menu, Tooltip } from '@mantine/core';
import { AlertCircle, Calendar } from 'tabler-icons-react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import AppFlexbox from '../../common/AppFlexbox';
import AnalyticsDateSelectMenu from './AnalyticsDateSelectMenu';
import AppText from '../../common/AppText';
import {
  DEFAULT_COMPARE_DATE_OPTIONS,
  DEFAULT_DATE_OPTIONS
} from './analyticsConfig';
import AppStack from '../../common/AppStack';
import MenuContent from '../../common/MenuContent';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import AnalyticsSeasonSelectMenu from './AnalyticsSeasonSelectMenu';
import AnalyticsDivisionSelectMenu from './AnalyticsDivisionSelectMenu';

const REPORT_PERIOD_OPTIONS = [
  {
    value: 'hour',
    label: 'Hourly'
  },
  {
    value: 'day',
    label: 'Daily'
  },
  {
    value: 'week',
    label: 'Weekly',
    isVisible: (daysBetween) => daysBetween >= 6
  },
  {
    value: 'month',
    label: 'Monthly',
    isVisible: (daysBetween) => daysBetween >= 27
  },
  {
    value: 'quarter',
    label: 'Quarterly',
    isVisible: (daysBetween) => daysBetween >= 85
  },
  {
    value: 'year',
    label: 'Yearly',
    isVisible: (daysBetween) => daysBetween >= 364
  }
];

const COMPARE_BY_ENUM = {
  PURCHASE_DATE: 'purchase_date',
  SEASON: 'season',
  DIVISION: 'division'
};

const COMPARE_BY_OPTIONS = [
  {
    label: 'Purchase date',
    value: COMPARE_BY_ENUM.PURCHASE_DATE
  },
  {
    label: 'Season',
    value: COMPARE_BY_ENUM.SEASON
  },
  {
    label: 'Division',
    value: COMPARE_BY_ENUM.DIVISION
  }
];

const getDatePeriodNumber = (str) => {
  const match = str.match(/^-?\d+/);
  return match ? Number(match[0]) : 0;
};

const periodHandlers = {
  d: (date, num) => date.setDate(date.getDate() + num),
  w: (date, num) => date.setDate(date.getDate() + num * 7),
  m: (date, num) => date.setMonth(date.getMonth() + num),
  y: (date, num) => date.setFullYear(date.getFullYear() + num),
  q: (date, num) => date.setMonth(date.getMonth() + num * 3)
};

const getDateParamValue = (param) => {
  const lowerParam = param.toLowerCase();
  const period = lowerParam.slice(-1); // Get the last character
  const num = getDatePeriodNumber(param);

  const baseDate = new Date();
  if (periodHandlers[period]) {
    periodHandlers[period](baseDate, num);
    return baseDate;
  }
  return dayjs(param);
};

const getDefaultPeriodInterval = (startDate, endDate) => {
  const daysBetween = dayjs(endDate).diff(dayjs(startDate), 'day');

  if (daysBetween <= 2) {
    return 'hour';
  }
  if (daysBetween <= 30) {
    return 'day';
  }
  return 'month';
};

const getDateOnly = (date) => {
  if (!date) {
    return null;
  }
  const d = new Date(date);
  return new Date(d.getFullYear(), d.getMonth(), d.getDate());
};

const areDatesEqual = (date1, date2) => {
  const normalizeDate = (date) =>
    new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();
  return normalizeDate(new Date(date1)) === normalizeDate(new Date(date2));
};

const AnalyticsFilter = ({
  entityId,
  uuid,
  onFilterChange,
  updateParamsOnFilterChange,
  includeCompareDateRange,
  includePeriod,
  defaultDateOption,
  includeCompareBy
}) => {
  const hasFetched = useRef(false);
  const navigate = useNavigate();
  const { state } = useContext(RegistrationAdminContext);
  const { search, pathname } = useLocation();
  const searchParams = new URLSearchParams(search);
  const startParam = searchParams.get('start');
  const endParam = searchParams.get('end');
  const periodParam = searchParams.get('period');

  // Find initial default date period
  const defaultDatePeriodInitial =
    startParam && endParam
      ? DEFAULT_DATE_OPTIONS.find(
          (f) => f.start === startParam && f.end === endParam
        )
      : DEFAULT_DATE_OPTIONS.find((f) => f.value === defaultDateOption) ||
        DEFAULT_DATE_OPTIONS.find((f) => f.isDefault);

  // Set start and end dates based on the initial default or parameters
  const {
    startDate: startDateInitial,
    endDate: endDateInitial
  } = defaultDatePeriodInitial
    ? defaultDatePeriodInitial.getDateRange()
    : {
        startDate: getDateParamValue(startParam),
        endDate: getDateParamValue(endParam)
      };

  // Find initial compare date period and set compare start and end dates
  const defaultCompareDatePeriodInitial = DEFAULT_COMPARE_DATE_OPTIONS.find(
    (f) => f.isDefault
  );
  const {
    startDate: compareStartDateInitial,
    endDate: compareEndDateInitial
  } = defaultDatePeriodInitial
    ? defaultDatePeriodInitial.getCompareDateRange()
    : defaultCompareDatePeriodInitial.getDateRange(
        defaultDatePeriodInitial,
        startDateInitial,
        endDateInitial
      );

  // Initialize filter state
  const [filterState, setFilterState] = useState({
    datePeriod: defaultDatePeriodInitial,
    compareDatePeriod: defaultCompareDatePeriodInitial,
    startDate: startDateInitial ? getDateOnly(startDateInitial) : null,
    endDate: endDateInitial ? getDateOnly(endDateInitial) : null,
    compareStartDate: compareStartDateInitial
      ? getDateOnly(compareStartDateInitial)
      : null,
    compareEndDate: compareEndDateInitial
      ? getDateOnly(compareEndDateInitial)
      : null,
    periodInterval:
      periodParam?.toLowerCase() ??
      getDefaultPeriodInterval(startDateInitial, endDateInitial),
    compareBy: COMPARE_BY_OPTIONS[0].value,
    options: [],
    label: null,
    compareLabel: null
  });
  const daysBetween = dayjs(filterState.endDate).diff(
    dayjs(filterState.startDate),
    'day'
  );
  const periodIntervalOptions = REPORT_PERIOD_OPTIONS.filter((op) =>
    op.isVisible ? op.isVisible(daysBetween) : true
  );

  const seasonOptions =
    state.regAssociation.value?.regAssociationSeasons
      ?.sort((a, b) => a.name.localeCompare(b.name))
      .map((s) => ({
        label: s.name,
        value: s.pkRegAssociationSeason.toString(),
        endDate: s.endDate
      })) ?? [];
  const sortedSeasonsByEndDate = seasonOptions.sort(
    (a, b) => new Date(b.endDate) - new Date(a.endDate)
  );
  const selectedSeason = filterState.options.find(
    (op) => op.valueName === 'season'
  );
  const selectedCompareSeason = filterState.options.find(
    (op) => op.valueName === 'compareSeason'
  );

  const divisonOptions =
    state.regAssociation.value?.regAssociationDivisions
      ?.sort((a, b) => a.name.localeCompare(b.name))
      .map((d) => ({
        label: d.name,
        value: d.pkRegAssociationDivision.toString(),
        endDate: d.regAssociationSeason.endDate,
        seasonName: d.regAssociationSeason.name
      })) ?? [];
  const sortedDivisionsByEndDate = divisonOptions.sort(
    (a, b) => new Date(b.endDate) - new Date(a.endDate)
  );
  const selectedDivision = filterState.options.find(
    (op) => op.valueName === 'division'
  );
  const selectedCompareDivision = filterState.options.find(
    (op) => op.valueName === 'compareDivision'
  );

  useEffect(() => {
    if (entityId) {
      if (updateParamsOnFilterChange && hasFetched.current) {
        const newSearchParams = new URLSearchParams(search);
        newSearchParams.set(
          'start',
          filterState.datePeriod
            ? filterState.datePeriod.start
            : dayjs(filterState.startDate).format('YYYY-MM-DD')
        );
        newSearchParams.set(
          'end',
          filterState.datePeriod
            ? filterState.datePeriod.end
            : dayjs(filterState.endDate).format('YYYY-MM-DD')
        );
        if (includePeriod) {
          newSearchParams.set('period', filterState.periodInterval);
        }
        newSearchParams.set(
          'compareBy',
          filterState.compareBy
            ? filterState.compareBy
            : COMPARE_BY_OPTIONS[0]?.value
        );

        navigate(`${pathname}?${newSearchParams.toString()}`, {replace: true});
      }

      onFilterChange(filterState);
      hasFetched.current = true;
    }
  }, [
    entityId,
    uuid,
    filterState.startDate,
    filterState.endDate,
    filterState.compareStartDate,
    filterState.compareEndDate,
    filterState.periodInterval,
    filterState.compareBy,
    filterState.options
  ]);

  useEffect(() => {
    if (state.regAssociation.value) {
      // eslint-disable-next-line no-use-before-define
      initializeCompareValues(filterState.compareBy);
    }
  }, [state.regAssociation.value, filterState.compareBy]);

  const initializeCompareValues = (compareBy) => {
    if (compareBy === 'season') {
      const existingSeason = filterState.options.find(
        (op) => op.valueName === 'season'
      );
      if (
        !existingSeason?.value ||
        !sortedSeasonsByEndDate.find((s) => s.value === existingSeason.value)
      ) {
        const compareSeason =
          sortedSeasonsByEndDate.filter(
            (s) => new Date(s.endDate) < new Date()
          )[0] || sortedSeasonsByEndDate[0];
        setFilterState((c) => ({
          ...c,
          compareBy,
          label: sortedSeasonsByEndDate[0]?.label,
          compareLabel: compareSeason?.label,
          options: [
            {
              valueName: 'season',
              value: sortedSeasonsByEndDate[0]?.value
            },
            {
              valueName: 'compareSeason',
              value: compareSeason?.value
            }
          ]
        }));
      }
    }
    else if (compareBy === 'division') {
      const existingDivision = filterState.options.find(
        (op) => op.valueName === 'division'
      );
      if (
        !existingDivision?.value ||
        !sortedDivisionsByEndDate.find(
          (s) => s.value === existingDivision.value
        )
      ) {
        const compareDivision =
          sortedDivisionsByEndDate.filter(
            (s) => new Date(s.endDate) < new Date()
          )[0] || sortedDivisionsByEndDate[0];

        setFilterState((c) => ({
          ...c,
          compareBy,
          label: sortedDivisionsByEndDate[0]?.label,
          compareLabel: compareDivision?.label,
          options: [
            {
              valueName: 'division',
              value: sortedDivisionsByEndDate[0]?.value
            },
            {
              valueName: 'compareDivision',
              value: compareDivision?.value
            }
          ]
        }));
      }
    }
    else if (filterState.compareBy !== COMPARE_BY_ENUM.PURCHASE_DATE) {
      setFilterState((c) => ({
        ...c,
        compareBy,
        label: null,
        compareLabel: null,
        options: []
      }));
    }
  };

  return (
    <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
      {filterState.compareBy === COMPARE_BY_ENUM.SEASON ? (
        <>
          <AnalyticsSeasonSelectMenu
            loading={state.regAssociation.loading}
            onChange={(value) => {
              setFilterState({
                ...filterState,
                label: seasonOptions.find((s) => s.value === value)?.label,
                options: filterState.options.map((o) =>
                  o.valueName === 'season' ? { ...o, value } : o
                )
              });
            }}
            seasonOptions={seasonOptions}
            value={selectedSeason?.value}
          />

          <AnalyticsSeasonSelectMenu
            isCompare
            loading={state.regAssociation.loading}
            onChange={(value) => {
              setFilterState({
                ...filterState,
                compareLabel: seasonOptions.find((s) => s.value === value)
                  ?.label,
                options: filterState.options.map((o) =>
                  o.valueName === 'compareSeason' ? { ...o, value } : o
                )
              });
            }}
            seasonOptions={seasonOptions}
            value={selectedCompareSeason?.value}
          />
        </>
      ) : filterState.compareBy === COMPARE_BY_ENUM.DIVISION ? (
        <>
          <AnalyticsDivisionSelectMenu
            divisionOptions={divisonOptions}
            loading={state.regAssociation.loading}
            onChange={(value) => {
              setFilterState({
                ...filterState,
                label: divisonOptions.find((d) => d.value === value)?.label,
                options: filterState.options.map((o) =>
                  o.valueName === 'division' ? { ...o, value } : o
                )
              });
            }}
            value={selectedDivision?.value}
          />

          <AnalyticsDivisionSelectMenu
            divisionOptions={divisonOptions}
            isCompare
            loading={state.regAssociation.loading}
            onChange={(value) => {
              setFilterState({
                ...filterState,
                compareLabel: divisonOptions.find((d) => d.value === value)
                  ?.label,
                options: filterState.options.map((o) =>
                  o.valueName === 'compareDivision' ? { ...o, value } : o
                )
              });
            }}
            value={selectedCompareDivision?.value}
          />
        </>
      ) : (
        <>
          <AnalyticsDateSelectMenu
            control={
              <Button
                color="dark"
                radius={8}
                size="compact-sm"
                style={{ border: 'solid 1px #B1B1B1' }}
                variant="white"
              >
                <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                  <Calendar size={18} />
                  <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                    {filterState.datePeriod?.label ??
                      (areDatesEqual(filterState.startDate, filterState.endDate)
                        ? dayjs(filterState.startDate).format('MMM D, YYYY')
                        : `${dayjs(filterState.startDate).format(
                            'MMM D, YYYY'
                          )} - ${dayjs(filterState.endDate).format(
                            'MMM D, YYYY'
                          )}`)}
                  </AppText>
                </AppFlexbox>
              </Button>
            }
            datePeriod={filterState.datePeriod}
            endDate={filterState.endDate}
            onChange={({ datePeriod, startDate, endDate }) => {
              const {
                startDate: compareStartDate,
                endDate: compareEndDate
              } = filterState.compareDatePeriod?.getDateRange(
                datePeriod,
                startDate,
                endDate
              ) ?? {
                startDate: filterState.compareStartDate,
                endDate: filterState.compareEndDate
              };

              const newStart = getDateOnly(startDate);
              const newEnd = getDateOnly(endDate);
              setFilterState({
                ...filterState,
                datePeriod,
                startDate: newStart,
                endDate: newEnd,
                compareStartDate: getDateOnly(compareStartDate),
                compareEndDate: getDateOnly(compareEndDate),
                periodInterval: getDefaultPeriodInterval(newStart, newEnd)
              });
            }}
            startDate={filterState.startDate}
          />
          {includeCompareDateRange && (
            <AnalyticsDateSelectMenu
              compareDatePeriod={filterState.compareDatePeriod}
              compareEndDate={filterState.compareEndDate}
              compareStartDate={filterState.compareStartDate}
              control={
                <Button
                  color="dark"
                  radius={8}
                  size="compact-sm"
                  style={{ border: 'solid 1px #B1B1B1' }}
                  variant="white"
                >
                  <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                    <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                      <span style={{ color: '#999' }}>Compared to:</span>{' '}
                      {filterState.compareDatePeriod?.value === 'noComparison'
                        ? 'No comparison'
                        : areDatesEqual(
                            filterState.compareStartDate,
                            filterState.compareEndDate
                          )
                        ? dayjs(filterState.compareStartDate).format(
                            'MMM D, YYYY'
                          )
                        : `${dayjs(filterState.compareStartDate).format(
                            'MMM D, YYYY'
                          )} - ${dayjs(filterState.compareEndDate).format(
                            'MMM D, YYYY'
                          )}`}
                    </AppText>
                  </AppFlexbox>
                </Button>
              }
              datePeriod={filterState.datePeriod}
              endDate={filterState.endDate}
              isCompareDateRange
              onChange={({ datePeriod, startDate, endDate }) => {
                setFilterState({
                  ...filterState,
                  compareDatePeriod: datePeriod,
                  compareStartDate: getDateOnly(startDate),
                  compareEndDate: getDateOnly(endDate)
                });
              }}
              startDate={filterState.startDate}
            />
          )}
        </>
      )}

      {includeCompareBy && (
        <MenuContent
          closeOnClickOutside
          closeOnItemClick
          control={
            <Button
              color="dark"
              radius={8}
              size="compact-sm"
              style={{ border: 'solid 1px #B1B1B1' }}
              variant="white"
            >
              <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  <span style={{ color: '#999' }}>Compare by:</span>{' '}
                  {
                    COMPARE_BY_OPTIONS.find(
                      (g) => g.value === filterState.compareBy
                    )?.label
                  }
                </AppText>
              </AppFlexbox>
            </Button>
          }
          position="bottom-start"
          radius="md"
          shadow="xl"
          styles={{ dropdown: { padding: 0 } }}
          width="unset"
        >
          <AppStack style={{ gap: 0, flex: 1 }}>
            {COMPARE_BY_OPTIONS.map((g) => (
              <Menu.Item
                key={g.value}
                onClick={(e) => {
                  e.preventDefault();
                  initializeCompareValues(g.value);
                }}
              >
                <AppFlexbox style={{ alignItems: 'center', gap: 12 }}>
                  <Checkbox
                    checked={filterState.compareBy === g.value}
                    onChange={() => {}}
                  />
                  <AppText style={{ fontSize: 14 }}>{g.label}</AppText>
                </AppFlexbox>
              </Menu.Item>
            ))}
          </AppStack>
        </MenuContent>
      )}

      {includePeriod && (
        <MenuContent
          closeOnClickOutside
          closeOnItemClick
          control={
            <Button
              color="dark"
              radius={8}
              size="compact-sm"
              style={{ border: 'solid 1px #B1B1B1' }}
              variant="white"
            >
              <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  {REPORT_PERIOD_OPTIONS.find(
                    (op) => op.value === filterState.periodInterval
                  )?.label ?? 'Period'}{' '}
                </AppText>
              </AppFlexbox>
            </Button>
          }
          position="bottom-start"
          radius="md"
          shadow="xl"
          styles={{ dropdown: { padding: 0 } }}
          width="unset"
        >
          <AppStack style={{ gap: 0, flex: 1 }}>
            {periodIntervalOptions.map((op) => (
              <Menu.Item
                key={op.value}
                onClick={(e) => {
                  e.preventDefault();
                  setFilterState({
                    ...filterState,
                    periodInterval: op.value
                  });
                }}
              >
                <AppFlexbox style={{ alignItems: 'center', gap: 12 }}>
                  <Checkbox
                    checked={filterState.periodInterval === op.value}
                    onChange={() => {}}
                  />
                  <AppText style={{ fontSize: 14 }}>{op.label}</AppText>
                </AppFlexbox>
              </Menu.Item>
            ))}
          </AppStack>
        </MenuContent>
      )}

      {filterState.compareBy !== COMPARE_BY_ENUM.SEASON &&
        filterState.compareBy !== COMPARE_BY_ENUM.DIVISION && (
          <Tooltip
            label={
              <AppStack>
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  The data is grouped based on the registration date rather than
                  the transaction date.
                </AppText>
                <AppText style={{ fontSize: 14 }}>
                  As a result, new transactions will be reflected on the date
                  when the registration was placed.
                </AppText>
              </AppStack>
            }
            multiline
            w={250}
            withArrow
          >
            <AppFlexbox>
              <AlertCircle color="dodgerblue" size={22} />
            </AppFlexbox>
          </Tooltip>
        )}
    </AppFlexbox>
  );
};

AnalyticsFilter.propTypes = {
  defaultDateOption: PropTypes.string,
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  includeCompareBy: PropTypes.bool,
  includeCompareDateRange: PropTypes.bool,
  includePeriod: PropTypes.bool,
  onFilterChange: PropTypes.func,
  updateParamsOnFilterChange: PropTypes.bool,
  uuid: PropTypes.string
};

export default AnalyticsFilter;
