import React from 'react';
import { CircleCheck } from 'tabler-icons-react';
import { Accordion, Badge, RingProgress } from '@mantine/core';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import ColoredAvatar from '../../common/ColoredAvatar';

const MENU_LIST_VARIANTS = {
  progress: 'PROGRESS',
  default: 'DEFAULT'
};

const getSubListKey = (id, subId) => `subList-${id}-${subId}`;

const isRenderSameAsBefore = (prevProps, nextProps) =>
  prevProps.value === nextProps.value &&
  prevProps.isOpen === nextProps.isOpen &&
  prevProps.label === nextProps.label &&
  prevProps.subLabel === nextProps.subLabel &&
  prevProps.variant === nextProps.variant &&
  prevProps.isSelected === nextProps.isSelected &&
  prevProps.subSectionValue.length === nextProps.subSectionValue.length &&
  prevProps.subSectionValue.every((v) =>
    nextProps.subSectionValue.includes(v)
  ) &&
  prevProps.subSections.length === nextProps.subSections.length &&
  prevProps.subSections.every(
    (e, index) =>
      nextProps.subSections.findIndex((s) => s.key === e.key) === index &&
      nextProps.subSections.some(
        (s) =>
          s.key === e.key &&
          s.label === e.label &&
          s.items.length === e.items.length &&
          e.items.every(
            (i, sIndex) =>
              s.items.findIndex((si) => si.key === i.key) === sIndex &&
              s.items.some(
                (si) =>
                  si.key === i.key &&
                  si.answerValue === i.answerValue &&
                  si.label === i.label &&
                  si.isSelected === i.isSelected
              )
          )
      )
  );

const EvaluationSessionEvaluateMenuListItem = React.memo(
  ({
    value,
    isSelected,
    onSelectItem,
    onSelectSection,
    onSelectSubItem,
    subSections,
    variant,
    label,
    avatarLabel,
    subLabel,
    subSectionValue,
    circleColor,
    isOpen
  }) => {
    const subSectionItems = subSections.reduce(
      (r, c) => [...r, ...c.items],
      []
    );
    const completedPercentage =
      (subSectionItems.filter((f) => !!f.answerValue).length /
        subSectionItems.length) *
      100;
    const isCompleted = completedPercentage >= 100;

    return (
      <Accordion.Item
        onClick={onSelectItem}
        style={{
          outline: isSelected
            ? isCompleted
              ? 'solid 3px #40C057'
              : 'solid 3px #FCC419'
            : 'none',
          backgroundColor: '#FFF'
        }}
        value={value}
      >
        <Accordion.Control>
          <AppFlexbox
            style={{
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingRight: 10,
              overflow: 'hidden'
            }}
          >
            <AppFlexbox
              style={{ gap: 10, alignItems: 'center', overflow: 'hidden' }}
            >
              {variant === MENU_LIST_VARIANTS.progress ? (
                <RingProgress
                  label={
                    <AppText
                      style={{ textAlign: 'center', fontSize: 12 }}
                      weight={700}
                    >
                      <NumberFormat
                        allowNegative={false}
                        decimalScale={0}
                        displayType="text"
                        fixedDecimalScale
                        suffix="%"
                        value={completedPercentage}
                      />
                    </AppText>
                  }
                  sections={[
                    {
                      value: completedPercentage,
                      color: isCompleted ? '#40C057' : '#FCC419'
                    }
                  ]}
                  size={50}
                  thickness={3}
                />
              ) : (
                <AppStack
                  style={{
                    border:
                      completedPercentage >= 100
                        ? 'solid 3px #40C057'
                        : 'solid 3px #FCC419',
                    borderRadius: 100,
                    padding: 1
                  }}
                >
                  <ColoredAvatar
                    name={avatarLabel}
                    style={{ padding: 0 }}
                    uniqueId={subLabel}
                  />
                </AppStack>
              )}

              {circleColor && (
                <AppStack
                  style={{
                    border: 'solid 1px #e2e8f0',
                    backgroundColor: circleColor,
                    height: 20,
                    width: 20,
                    borderRadius: 18
                  }}
                />
              )}

              <AppText
                style={{ fontSize: 16, flex: 1, textWrap: 'nowrap' }}
                weight={500}
              >
                {label}
              </AppText>
            </AppFlexbox>

            {isCompleted ? (
              <CircleCheck color="#40C057" size={25} />
            ) : (
              <Badge
                color="gray"
                style={{ overflow: 'visible' }}
                variant="light"
              >
                <NumberFormat
                  allowNegative={false}
                  decimalScale={0}
                  displayType="text"
                  fixedDecimalScale
                  suffix="%"
                  value={completedPercentage}
                />
              </Badge>
            )}
          </AppFlexbox>
        </Accordion.Control>
        <Accordion.Panel>
          <Accordion
            defaultValue={subSectionValue}
            multiple
            onChange={() => {}}
            styles={{
              item: { border: 'none' },
              control: { padding: 5 },
              label: { padding: 0 },
              content: { paddingBottom: 0 }
            }}
            value={subSectionValue}
          >
            {subSections.map((subSection) => {
              const subListKey = getSubListKey(value, subSection.key);

              const sectionCompletedPercentage =
                (subSection.items.filter((s) => !!s.answerValue).length /
                  subSection.items.length) *
                100;
              const isSectionCompleted = completedPercentage >= 100;

              return (
                <Accordion.Item
                  key={subListKey}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onSelectSection(subListKey);
                  }}
                  value={subListKey}
                >
                  <Accordion.Control>
                    <AppFlexbox
                      style={{
                        gap: 10,
                        alignItems: 'center',
                        overflow: 'hidden'
                      }}
                    >
                      {isSectionCompleted ? (
                        <CircleCheck color="#40C057" size={25} />
                      ) : (
                        <Badge
                          color="gray"
                          style={{ overflow: 'visible' }}
                          variant="light"
                        >
                          <NumberFormat
                            allowNegative={false}
                            decimalScale={0}
                            displayType="text"
                            fixedDecimalScale
                            suffix="%"
                            value={sectionCompletedPercentage}
                          />
                        </Badge>
                      )}
                      <AppText
                        style={{ fontSize: 16, flex: 1, textWrap: 'nowrap' }}
                        weight={500}
                      >
                        {subSection.label}
                      </AppText>
                    </AppFlexbox>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <AppStack style={{ gap: 0 }}>
                      {subSection.items.map((s) => (
                        <AppFlexbox
                          key={s.key}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onSelectSubItem(s);
                          }}
                          style={{
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '5px 10px',
                            cursor: 'pointer',
                            backgroundColor:
                              isSelected && s.isSelected ? '#ffe066' : 'unset',
                            overflow: 'hidden'
                          }}
                        >
                          <AppFlexbox
                            style={{ alignItems: 'center', overflow: 'hidden' }}
                          >
                            {s?.answerValue ? (
                              <CircleCheck color="#40C057" size={25} />
                            ) : (
                              <AppStack
                                style={{
                                  width: 25,
                                  height: 25,
                                  justifyContent: 'center',
                                  placeItems: 'center'
                                }}
                              >
                                <AppStack
                                  style={{
                                    backgroundColor: 'lightgrey',
                                    borderRadius: 15,
                                    height: 15,
                                    width: 15
                                  }}
                                />
                              </AppStack>
                            )}
                            {s.circleColor && (
                              <AppStack
                                style={{
                                  border: 'solid 1px #e2e8f0',
                                  backgroundColor: s.circleColor,
                                  height: 20,
                                  width: 20,
                                  borderRadius: 18
                                }}
                              />
                            )}
                            <AppText
                              style={{ flex: 1, textWrap: 'nowrap' }}
                              weight={500}
                            >
                              {s.label}
                            </AppText>
                          </AppFlexbox>
                          {s.answerValue && (
                            <AppText
                              style={{
                                overflow: 'unset',
                                whiteSpace: 'nowrap'
                              }}
                            >
                              {s.answerValue}
                            </AppText>
                          )}
                        </AppFlexbox>
                      ))}
                    </AppStack>
                  </Accordion.Panel>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </Accordion.Panel>
      </Accordion.Item>
    );
  },
  isRenderSameAsBefore
);

EvaluationSessionEvaluateMenuListItem.propTypes = {
  avatarLabel: PropTypes.string,
  circleColor: PropTypes.string,
  isOpen: PropTypes.bool,
  isSelected: PropTypes.bool,
  label: PropTypes.string,
  onSelectItem: PropTypes.func,
  onSelectSection: PropTypes.func,
  onSelectSubItem: PropTypes.func,
  subLabel: PropTypes.string,
  subSectionValue: PropTypes.array,
  subSections: PropTypes.array,
  value: PropTypes.string,
  variant: PropTypes.string
};

export default EvaluationSessionEvaluateMenuListItem;
