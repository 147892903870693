import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Divider, TextInput } from '@mantine/core';
import { Check, X } from 'tabler-icons-react';
import dayjs from 'dayjs';
import ResponsiveModal from '../../common/ResponsiveModal';
import FormSection from '../../common/FormSection';
import AppTitle from '../../common/AppTitle';
import PaginationList from '../../common/PaginationList';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import { formatUtcDate } from '../../../helpers/format';

const ViewEmailRecipientsModal = ({ isOpen, onClose, sesEmailSession }) => {
  const [filterState, setFilterState] = useState({
    email: '',
    isLoading: false
  });

  useEffect(() => {
    if (isOpen) {
      setFilterState({
        email: '',
        isLoading: false
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      centered
      onClose={onClose}
      opened={isOpen}
      size={600}
      title={<AppTitle order={2}>View Email Recipients</AppTitle>}
    >
      {sesEmailSession && (
        <FormSection cancelTitle="Close" isSubmitHidden onCancel={onClose}>
          <AppStack style={{ gap: 20 }}>
            <AppStack style={{ gap: 0 }}>
              <AppText style={{ fontSize: 18 }} weight={500}>
                Subject: {sesEmailSession.subject}
              </AppText>
              <AppText style={{ fontSize: 14 }}>
                Sent:{' '}
                {dayjs(formatUtcDate(sesEmailSession.createdAt)).format(
                  'MM/DD/YYYY h:mm a'
                )}
              </AppText>
            </AppStack>

            <Divider />

            <AppStack style={{ gap: 5 }}>
              <AppFlexbox
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <AppText style={{ fontSize: 18 }} weight={500}>
                  To Emails:
                </AppText>
                <TextInput
                  onChange={(e) =>
                    setFilterState({
                      ...filterState,
                      email: e.target.value
                    })
                  }
                  placeholder="Search by email..."
                  style={{ flex: 1, maxWidth: 250 }}
                  value={filterState.email}
                />
              </AppFlexbox>

              <PaginationList
                items={[
                  ...sesEmailSession.sesEmails
                    .filter(
                      (f) =>
                        !filterState.email ||
                        f.recipient.includes(filterState.email.toLowerCase())
                    )
                    .map((e) => (
                      <AppFlexbox
                        key={e.pkSesEmail}
                        style={{
                          gap: 5,
                          padding: 5,
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                          {e?.sent ? (
                            <Check color="green" />
                          ) : (
                            <X color="red" />
                          )}
                          <AppText weight={500}>{e.recipient}</AppText>
                        </AppFlexbox>

                        <AppText
                          style={{ color: e.sent ? 'green' : 'red' }}
                          weight={500}
                        >
                          {e.sent ? 'Sent' : 'Not sent'}
                        </AppText>
                      </AppFlexbox>
                    ))
                ]}
              />
            </AppStack>
          </AppStack>
        </FormSection>
      )}
    </ResponsiveModal>
  );
};

ViewEmailRecipientsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  sesEmailSession: PropTypes.object
};

export default ViewEmailRecipientsModal;
