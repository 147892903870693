import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Divider } from '@mantine/core';
import { AlertCircle, InfoCircle } from 'tabler-icons-react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import FormSection from '../../common/FormSection';
import { formatUtcDate } from '../../../helpers/format';
import LoadableView from '../../common/LoadableView';
import HeaderView from '../../common/HeaderView';
import AppCard from '../../common/AppCard';
import AppTitle from '../../common/AppTitle';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';

const InviteCard = ({
  isLoading,
  isSubmitting,
  onAccept,
  onDecline,
  isDeleted,
  expiresAt,
  children
}) => {
  const navigate = useNavigate();
  const [formState, setFormState] = useState({ showConfirmCancel: false });

  const hasExpired =
    isDeleted ||
    (expiresAt ? new Date() >= new Date(formatUtcDate(expiresAt)) : false);
  let expiryTimeHours = expiresAt
    ? dayjs(formatUtcDate(expiresAt)).diff(dayjs(new Date()), 'hour')
    : 0;
  const expiryTimeMinutes = expiresAt
    ? Math.ceil(
        dayjs(formatUtcDate(expiresAt)).diff(dayjs(new Date()), 'minute', true)
      )
    : 0;
  const expiryTimeDays = Math.ceil(expiryTimeHours / 24);
  expiryTimeHours -= Math.floor(expiryTimeHours / 24) * 24;

  return (
    <LoadableView isLoading={isLoading}>
      {!isLoading && (
        <HeaderView maxViewWidth={550}>
          <AppCard style={{ width: '100%', margin: 'auto' }}>
            {formState.showConfirmCancel ? (
              <FormSection
                isLoading={isSubmitting}
                onCancel={() =>
                  setFormState({
                    ...formState,
                    showConfirmCancel: false
                  })
                }
                onSubmit={onDecline}
                style={{ gap: 30, margin: 10 }}
                submitColor="red"
                submitTitle="Decline"
              >
                <AppTitle order={3}>Decline Invite</AppTitle>
                <Alert
                  color="red"
                  icon={<AlertCircle style={{ width: 35, height: 35 }} />}
                  variant="outline"
                >
                  <AppText>
                    Are you sure you want to <b>decline</b> the invite?
                  </AppText>
                </Alert>
              </FormSection>
            ) : hasExpired ? (
              <AppStack style={{ gap: 30, margin: 10 }}>
                <AppTitle order={3}>Invite Expired</AppTitle>

                <Alert
                  color="red"
                  icon={<AlertCircle style={{ marginTop: 3 }} />}
                  variant="outline"
                >
                  <AppStack style={{ gap: 10 }}>
                    <AppText weight={500}>The invite has expired.</AppText>
                    <AppText>You will have be invited again.</AppText>
                  </AppStack>
                </Alert>
                <Divider />
                <AppFlexbox style={{ justifyContent: 'center' }}>
                  <Button
                    color="dark"
                    onClick={() => navigate('/leagues')}
                    style={{
                      flex: 1,
                      maxWidth: 250
                    }}
                  >
                    View Leagues & Clubs
                  </Button>
                </AppFlexbox>
              </AppStack>
            ) : (
              <FormSection
                cancelColor="red"
                cancelTitle="Decline"
                isLoading={isSubmitting}
                onCancel={() =>
                  setFormState({
                    ...formState,
                    showConfirmCancel: true
                  })
                }
                onSubmit={onAccept}
                style={{ gap: 30, margin: 10 }}
                submitTitle="Accept"
              >
                <AppTitle order={3}>Invite Available</AppTitle>
                <Alert
                  color="blue"
                  icon={<InfoCircle style={{ width: 35, height: 35 }} />}
                  variant="outline"
                >
                  <AppText weight={500}>
                    You have a pending invite requiring action!
                  </AppText>
                </Alert>
                <Divider />
                {children}

                {expiresAt && (
                  <>
                    <Divider />
                    <Alert
                      color="blue"
                      icon={<InfoCircle style={{ width: 35, height: 35 }} />}
                      variant="outline"
                    >
                      <AppText>
                        This invite will expire in{' '}
                        {expiryTimeDays > 1 ? (
                          <b>
                            {expiryTimeDays}{' '}
                            {expiryTimeDays === 1 ? 'day.' : 'days.'}{' '}
                          </b>
                        ) : expiryTimeHours > 0 ? (
                          <b>
                            {expiryTimeHours}{' '}
                            {expiryTimeHours === 1 ? 'hour.' : 'hours.'}{' '}
                          </b>
                        ) : (
                          expiryTimeMinutes > 0 && (
                            <b>
                              {expiryTimeMinutes}{' '}
                              {expiryTimeMinutes === 1 ? 'minute.' : 'minutes.'}{' '}
                            </b>
                          )
                        )}
                      </AppText>
                    </Alert>
                  </>
                )}
              </FormSection>
            )}
          </AppCard>
        </HeaderView>
      )}
    </LoadableView>
  );
};
InviteCard.propTypes = {
  children: PropTypes.any,
  expiresAt: PropTypes.string,
  isDeleted: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onAccept: PropTypes.func,
  onDecline: PropTypes.func
};

export default InviteCard;
