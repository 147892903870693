import React from 'react';
import { PropTypes } from 'prop-types';
import { Divider, Progress } from '@mantine/core';
import ResponsiveModal from '../../common/ResponsiveModal';
import FinancialRegistrationTransactionList from './FinancialRegistrationTransactionList';
import AppTitle from '../../common/AppTitle';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import RecordPaymentPriceItem from './RecordPaymentPriceItem';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const ViewInvoicePaymentsModal = ({
  isOpen,
  onClose,
  regFormSubmissionInvoice
}) => {
  const mqIndex = useMediaQueryIndex();
  const invoicePayment =
    regFormSubmissionInvoice?.regFormSubmissionPayments.find(
      (p) => p.fkRegFormSubmissionPaymentType === 6
    ) ?? null;
  const viewablePayments =
    regFormSubmissionInvoice?.regFormSubmissionPayments.filter(
      (p) => p.fkRegFormSubmissionPaymentType !== 6
    ) ?? [];

  const amountPaid = viewablePayments
    .filter((p) => p.fkRegFormSubmissionPaymentType !== 5)
    .reduce((r, c) => r + c.amount, 0);
  const amountRefunded =
    viewablePayments
      .filter((p) => p.fkRegFormSubmissionPaymentType === 5)
      .reduce((r, c) => r + c.amount, 0) * -1;
  const isPartialRefund = amountRefunded > 0 && amountPaid - amountRefunded > 0;

  const transactionTotal = invoicePayment
    ? invoicePayment.amount - amountRefunded
    : 0;
  const totalPayments = amountPaid - amountRefunded;

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClose}
      size={1100}
      title={<AppTitle order={3}>Invoice Payments</AppTitle>}
    >
      {regFormSubmissionInvoice && (
        <AppStack style={{ gap: 20 }}>
          <Divider />
          <AppFlexbox
            style={{
              gap: rs([20, 20, 40], mqIndex),
              alignItems: 'start',
              flexDirection: rs(['column', 'column', 'row'], mqIndex)
            }}
          >
            <AppStack style={{ flex: 1, gap: 5, alignSelf: 'stretch' }}>
              <AppText size="lg" style={{ color: 'dodgerblue' }} weight={700}>
                Payment Status
              </AppText>
              <Divider size={2} />

              {amountPaid - amountRefunded >= invoicePayment.amount ? (
                <AppStack style={{ gap: 0 }}>
                  <AppText style={{ fontSize: 16 }} weight={500}>
                    Paid in full (payments)
                  </AppText>
                  <Progress value={100} />
                </AppStack>
              ) : regFormSubmissionInvoice.status === 'VOID' ? (
                <AppStack style={{ gap: 0 }}>
                  <AppText style={{ fontSize: 16 }} weight={500}>
                    Void
                  </AppText>
                  <Progress value={0} />
                </AppStack>
              ) : regFormSubmissionInvoice.status === 'UNCOLLECTIBLE' ? (
                <AppStack style={{ gap: 0 }}>
                  <AppText style={{ fontSize: 16 }} weight={500}>
                    Uncollectible
                  </AppText>
                  <Progress value={0} />
                </AppStack>
              ) : amountRefunded > 0 && isPartialRefund ? (
                <AppStack style={{ gap: 0 }}>
                  <AppText style={{ fontSize: 16 }} weight={500}>
                    Partial Refund
                  </AppText>
                  <Progress value={0} />
                </AppStack>
              ) : amountRefunded > 0 &&
                amountPaid - amountRefunded < invoicePayment.amount ? (
                <AppStack style={{ gap: 0 }}>
                  <AppText style={{ fontSize: 16 }} weight={500}>
                    Refunded
                  </AppText>
                  <Progress value={0} />
                </AppStack>
              ) : (
                <AppStack style={{ gap: 0 }}>
                  <AppText style={{ fontSize: 16 }} weight={500}>
                    Waiting for payment
                  </AppText>
                  <Progress value={(totalPayments / transactionTotal) * 100} />
                </AppStack>
              )}
            </AppStack>
            <AppStack style={{ flex: 1, gap: 5, alignSelf: 'stretch' }}>
              <AppText
                style={{ color: 'dodgerblue', fontSize: 18 }}
                weight={700}
              >
                Overview
              </AppText>
              <Divider size={2} />

              <RecordPaymentPriceItem
                label="Invoice Total"
                value={invoicePayment.amount}
              />

              {amountRefunded > 0 && (
                <RecordPaymentPriceItem
                  label="Invoice Refunds"
                  value={amountRefunded}
                />
              )}

              <RecordPaymentPriceItem
                label="Transaction Total"
                value={transactionTotal}
                weight={700}
              />

              <RecordPaymentPriceItem
                color="#37b24d"
                label="Total Payments"
                value={totalPayments}
              />

              <RecordPaymentPriceItem
                isDividerHidden
                label="Balance"
                value={transactionTotal - totalPayments}
                weight={700}
              />
            </AppStack>
          </AppFlexbox>
          <FinancialRegistrationTransactionList payments={viewablePayments} />
        </AppStack>
      )}
    </ResponsiveModal>
  );
};

ViewInvoicePaymentsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  regFormSubmissionInvoice: PropTypes.object
};
export default ViewInvoicePaymentsModal;
