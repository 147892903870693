import React, { useContext, useEffect, useState } from 'react';
import ParticipantListItem from './ParticipantListItem';
import ParticipantModal from './ParticipantModal';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import PaginationList from '../../common/PaginationList';
import HeaderView from '../../common/HeaderView';
import AppCardList from '../../common/AppCardList';

const ParticipantList = () => {
  const { state } = useContext(RegistrationContext);
  const [participantModalState, setParticipantModalState] = useState({
    isOpen: false,
    participant: null,
    showDelete: false
  });
  const [pageIndex, setPageIndex] = useState(1);
  const isLoading = state.regRegistrants.loading;

  useEffect(() => {
    setParticipantModalState({
      isOpen: false,
      participant: null,
      showDelete: false
    });
  }, []);

  return (
    <HeaderView maxViewWidth={1000}>
      <AppCardList
        buttonPlacement="header"
        onAction={() =>
          setParticipantModalState({
            isOpen: true,
            participant: null,
            showDelete: false
          })
        }
        title="My Participants"
      >
        <PaginationList
          emptyMessage="No Participants"
          isLoading={isLoading}
          items={state.regRegistrants.value
            .sort((a, b) =>
              `${a.firstName}${a.lastName}`.localeCompare(
                `${b.firstName}${b.lastName}`
              )
            )
            .map((p) => (
              <ParticipantListItem
                key={p.pkRegPerson}
                onAction={(action) =>
                  setParticipantModalState({
                    isOpen: true,
                    participant: p,
                    showDelete: action === 'DELETE'
                  })
                }
                participant={p}
              />
            ))}
          itemsPerPage={10}
          LoadingComponent={ParticipantListItem}
          onPageChange={setPageIndex}
          pageIndex={pageIndex}
        />
      </AppCardList>

      <ParticipantModal
        isOpen={participantModalState.isOpen}
        onClose={() =>
          setParticipantModalState({
            ...participantModalState,
            isOpen: false
          })
        }
        participant={participantModalState.participant}
        showDelete={participantModalState.showDelete}
      />
    </HeaderView>
  );
};

ParticipantList.propTypes = {};

export default ParticipantList;
