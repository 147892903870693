import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Divider, Progress, Skeleton, Title } from '@mantine/core';
import dayjs from 'dayjs';
import ResponsiveModal from '../../common/ResponsiveModal';
import FormSection from '../../common/FormSection';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { triggerNotification } from '../../../helpers/notificationHelper';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import RecordPaymentPriceItem from '../financials/RecordPaymentPriceItem';
import { currencyFormat, formatUtcDate } from '../../../helpers/format';
import {
  CURRENCY_TYPE_LIST,
  OFFLINE_PAYMENT_TYPES
} from '../../../config/constants';
import MerchPackageTransactionList from './MerchPackageTransactionList';

const MerchPackageOrderPaymentsModal = ({
  isOpen,
  onClose,
  regCartMerchPackage
}) => {
  const fetchedPkRegCartMerchPackage = useRef(null);
  const { state, fetchRegCartMerchPackageBalance } = useContext(
    RegistrationAdminContext
  );
  const isLoading =
    !fetchedPkRegCartMerchPackage.current ||
    state.regCartMerchPackageBalance.loading;

  const { balance, merchPackage, regCartMerchPackagePayments } =
    state.regCartMerchPackageBalance.value || {};

  const isPaid = balance?.balanceInCents <= 0;
  const isRefunded = balance?.refundedAmountInCents > 0;
  const currency = balance?.currency?.toUpperCase();

  useEffect(() => {
    if (
      isOpen &&
      fetchedPkRegCartMerchPackage.current !==
        regCartMerchPackage.pkRegCartMerchPackage
    ) {
      fetchRegCartMerchPackageBalance(
        regCartMerchPackage.pkRegCartMerchPackage,
        null,
        triggerNotification
      );
      fetchedPkRegCartMerchPackage.current =
        regCartMerchPackage.pkRegCartMerchPackage;
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClose}
      size={1100}
      title={<Title order={2}>Merch Order Payments</Title>}
    >
      <FormSection
        cancelTitle="Close"
        isSubmitHidden
        onCancel={onClose}
        style={{ gap: 20 }}
      >
        <AppStack style={{ gap: 20 }}>
          <Divider />
          <AppFlexbox
            style={{
              gap: 40,
              alignItems: 'start',
              flexDirection: 'row'
            }}
          >
            <AppStack style={{ flex: 1, gap: 5, alignSelf: 'stretch' }}>
              <AppText size="lg" style={{ color: 'dodgerblue' }} weight={700}>
                Payment Status
              </AppText>
              <Divider size={2} />

              {isLoading ? (
                <AppStack style={{ gap: 5 }}>
                  <Skeleton height={12} width="40%" />
                  <Skeleton height={8} width="100%" />
                </AppStack>
              ) : isPaid ? (
                <AppStack style={{ gap: 0 }}>
                  <AppText style={{ fontSize: 16 }} weight={500}>
                    Paid in full (payments)
                  </AppText>
                  <Progress value={100} />
                </AppStack>
              ) : isRefunded ? (
                <AppStack style={{ gap: 0 }}>
                  <AppText style={{ fontSize: 16 }} weight={500}>
                    Refunded
                  </AppText>
                  <Progress value={0} />
                </AppStack>
              ) : (
                <AppStack style={{ gap: 0 }}>
                  <AppText style={{ fontSize: 16 }} weight={500}>
                    Waiting for payment
                  </AppText>
                  <Progress
                    value={
                      (balance.amountPaidInCents / balance.totalInCents) * 100
                    }
                  />
                </AppStack>
              )}
            </AppStack>
            <AppStack style={{ flex: 1, gap: 5, alignSelf: 'stretch' }}>
              <AppText
                style={{ color: 'dodgerblue', fontSize: 18 }}
                weight={700}
              >
                Overview
              </AppText>
              <Divider size={2} />
              {isLoading ? (
                <>
                  <RecordPaymentPriceItem isLoading label="Subtotal" />
                  <RecordPaymentPriceItem isLoading label="Transaction Total" />
                  <RecordPaymentPriceItem isLoading label="Total Payments" />
                  <RecordPaymentPriceItem isLoading label="Balance" />
                </>
              ) : (
                <>
                  <RecordPaymentPriceItem
                    currency={currency}
                    label="Subtotal"
                    value={balance.subtotalInCents / 100}
                  />

                  <RecordPaymentPriceItem
                    currency={currency}
                    label="Transaction Total"
                    value={balance.totalInCents / 100}
                    weight={700}
                  />

                  <RecordPaymentPriceItem
                    color="#37b24d"
                    currency={currency}
                    label="Total Payments"
                    value={balance.amountPaidInCents / 100}
                  />

                  {balance.refundedAmountInCents > 0 && (
                    <RecordPaymentPriceItem
                      color="red"
                      currency={currency}
                      label="Refunded Amount"
                      value={balance.refundedAmountInCents / 100}
                    />
                  )}

                  <RecordPaymentPriceItem
                    currency={currency}
                    isDividerHidden
                    label="Balance"
                    value={
                      (balance.totalInCents -
                        balance.amountPaidInCents +
                        balance.refundedAmountInCents) /
                      100
                    }
                    weight={700}
                  />
                </>
              )}
            </AppStack>
          </AppFlexbox>
          <MerchPackageTransactionList
            isLoading={isLoading}
            payments={
              regCartMerchPackagePayments?.map((p) => {
                const statusInfo =
                  p.fkRegFormSubmissionPaymentType === 5
                    ? {
                        label: 'Refunded',
                        color: 'red'
                      }
                    : {
                        label: 'Paid',
                        color: 'green'
                      };

                const paymentCurrency = CURRENCY_TYPE_LIST.find(
                  (c) => c.value.toString() === p.fkCurrencyType?.toString()
                )?.abbr;

                return {
                  key: p.pkRegCartMerchPackagePayment,
                  rows: [
                    {
                      key: 1,
                      label: statusInfo.label,
                      color: statusInfo.color,
                      weight: 500
                    },
                    {
                      key: 2,
                      label: dayjs(formatUtcDate(p.createdAt)).format(
                        'MMM D, YYYY'
                      )
                    },
                    { key: 3, label: 'Card' },
                    {
                      key: 4,
                      label: OFFLINE_PAYMENT_TYPES.find(
                        (t) =>
                          t.value ===
                          p.fkRegFormSubmissionPaymentType.toString()
                      )?.label
                    },
                    {
                      key: 5,
                      label: `${currencyFormat(
                        p.amount / 100
                      )} ${paymentCurrency}`,
                      textAlign: 'end',
                      color:
                        p.fkRegFormSubmissionPaymentType === 5 ? 'red' : 'green'
                    }
                  ]
                };
              }) ?? []
            }
          />
        </AppStack>
      </FormSection>
    </ResponsiveModal>
  );
};

MerchPackageOrderPaymentsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  regCartMerchPackage: PropTypes.object
};

export default MerchPackageOrderPaymentsModal;
