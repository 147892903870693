import React, { useContext, useEffect, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Alert, Divider, Select } from '@mantine/core';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import RegistrationListItem from './RegistrationListItem';
import { triggerNotification } from '../../../helpers/notificationHelper';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppTitle from '../../common/AppTitle';
import FormSection from '../../common/FormSection';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';

const RegistrationChangeDivisionModal = ({
  regAssociation,
  isOpen,
  onClose,
  onRefresh,
  regFormSubmission
}) => {
  const hasLoadedDivisions = useRef(false);
  const {
    state,
    changeRegistrationDivision,
    fetchAdminRegAssociationDivisions
  } = useContext(RegistrationAdminContext);
  const [formState, setFormState] = useState({
    pkRegAssociationDivision: null,
    pkRegAssociationSeason: null,
    skipEmailNotice: false,
    loading: false
  });

  const regAssociationDivisions =
    !state.regAssociationDivisions.loading &&
    state.regAssociationDivisions.type === 'form-divisions'
      ? state.regAssociationDivisions.value.filter(
          (d) =>
            !d.deleted &&
            d.regAssociationSeason.fkRegAssociation ===
              regAssociation?.pkRegAssociation
        )
      : [];

  const seasonOptions = regAssociationDivisions
    .reduce((result, current) => {
      if (
        result.findIndex(
          (r) =>
            r.pkRegAssociationSeason ===
            current.regAssociationSeason.pkRegAssociationSeason
        ) === -1
      ) {
        result.push(current.regAssociationSeason);
      }
      return result;
    }, [])
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((s) => ({
      label: s.name,
      value: s.pkRegAssociationSeason.toString()
    }));

  const filteredAssociationDivisions = regAssociationDivisions
    .filter(
      (d) =>
        !d.deleted &&
        d.regAssociationSeason.pkRegAssociationSeason.toString() ===
          formState.pkRegAssociationSeason &&
        d.pkRegAssociationDivision !==
          (regFormSubmission.regAssociationDivisionOverride
            ?.pkRegAssociationDivision ??
            regFormSubmission.regAssociationDivisionForm
              .fkRegAssociationDivision)
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  const selectedDivision = filteredAssociationDivisions.find(
    (d) =>
      d.pkRegAssociationDivision.toString() ===
      formState.pkRegAssociationDivision
  );
  const isLoading =
    !regFormSubmission ||
    state.regAssociationDivisions.loading ||
    state.regAssociationDivisions.type !== 'form-divisions';

  useEffect(() => {
    if (isOpen) {
      setFormState({
        pkRegAssociation: regAssociation?.pkRegAssociation,
        pkRegAssociationDivision: filteredAssociationDivisions[0]?.pkRegAssociationDivision.toString(),
        pkRegAssociationSeason:
          regFormSubmission?.regAssociationDivisionOverride?.fkRegAssociationSeason.toString() ??
          regFormSubmission?.regAssociationDivisionForm.regAssociationDivision.fkRegAssociationSeason.toString(),
        loading: false,
        showConfirm: false
      });

      if (
        regAssociation &&
        formState.pkRegAssociation !== regAssociation.pkRegAssociation
      ) {
        hasLoadedDivisions.current = false;
        fetchAdminRegAssociationDivisions(() => {
          hasLoadedDivisions.current = true;
        });
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (
      hasLoadedDivisions.current &&
      !state.regAssociationDivisions.loading &&
      state.regAssociationDivisions.type !== 'form-divisions'
    ) {
      hasLoadedDivisions.current = false;
      fetchAdminRegAssociationDivisions(() => {
        hasLoadedDivisions.current = true;
      });
    }
  }, [state.regAssociationDivisions.type]);

  useEffect(() => {
    if (!state.regAssociationDivisions.loading) {
      setFormState({
        ...formState,
        pkRegAssociationDivision: filteredAssociationDivisions[0]?.pkRegAssociationDivision.toString()
      });
    }
  }, [state.regAssociationDivisions.value]);

  useEffect(() => {
    if (formState.pkRegAssociationSeason) {
      setFormState({
        ...formState,
        pkRegAssociationDivision: filteredAssociationDivisions[0]?.pkRegAssociationDivision.toString()
      });
    }
  }, [formState.pkRegAssociationSeason]);
  return (
    <ResponsiveModal
      isLoading={isLoading}
      onClose={onClose}
      opened={isOpen}
      size={600}
      title={<AppTitle order={2}>Change Division</AppTitle>}
    >
      {!isLoading && (
        <AppStack style={{ gap: 20 }}>
          <RegistrationListItem
            regAssociation={regAssociation}
            regFormSubmission={regFormSubmission}
          />

          {formState.showConfirm ? (
            <FormSection
              hideDivider
              isLoading={formState.loading}
              onCancel={() =>
                setFormState({
                  ...formState,
                  showConfirm: false
                })
              }
              onSubmit={() => {
                setFormState({
                  ...formState,
                  loading: true
                });
                changeRegistrationDivision(
                  regFormSubmission.pkRegFormSubmission,
                  formState.pkRegAssociationDivision,
                  () => {
                    triggerNotification(
                      'Division has been changed',
                      'Success',
                      'green'
                    );
                    if (onRefresh) {
                      onRefresh();
                    }
                    onClose();
                  },
                  (message) => {
                    setFormState({
                      ...formState,
                      loading: false
                    });
                    triggerNotification(message);
                  }
                );
              }}
              submitTitle="Change Division"
            >
              <AppStack style={{ gap: 20 }}>
                <Divider />
                <AppText style={{ textAlign: 'center' }}>
                  Are you sure you want to assign the selected registrant to{' '}
                  <b>{selectedDivision?.name}</b>
                </AppText>
                <Divider />
              </AppStack>
            </FormSection>
          ) : (
            <FormSection
              isLoading={formState.loading}
              onCancel={onClose}
              onSubmit={() =>
                setFormState({
                  ...formState,
                  showConfirm: true
                })
              }
              submitTitle="Change Division"
            >
              <AppStack style={{ gap: 20 }}>
                <Alert color="blue" variant="outline">
                  <AppText style={{ textAlign: 'center' }}>
                    Select a division to assign{' '}
                    {regFormSubmission.regPerson.firstName}{' '}
                    {regFormSubmission.regPerson.lastName}
                  </AppText>
                </Alert>
                <Select
                  clearable
                  data={seasonOptions}
                  label="Season"
                  onChange={(value) =>
                    setFormState({
                      ...formState,
                      pkRegAssociationSeason: value
                    })
                  }
                  required
                  searchable
                  value={formState.pkRegAssociationSeason}
                />
                <Select
                  clearable
                  data={filteredAssociationDivisions.map((d) => ({
                    label: d.name,
                    value: d.pkRegAssociationDivision.toString()
                  }))}
                  label="Division"
                  onChange={(value) =>
                    setFormState({
                      ...formState,
                      pkRegAssociationDivision: value
                    })
                  }
                  required
                  searchable
                  value={formState.pkRegAssociationDivision}
                />
              </AppStack>
            </FormSection>
          )}
        </AppStack>
      )}
    </ResponsiveModal>
  );
};

RegistrationChangeDivisionModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onRefresh: PropTypes.func,
  regAssociation: PropTypes.object,
  regFormSubmission: PropTypes.object
};
export default RegistrationChangeDivisionModal;
