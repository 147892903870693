import React from 'react';
import PropTypes from 'prop-types';
import { ActionIcon, Menu } from '@mantine/core';
import { DotsVertical } from 'tabler-icons-react';
import { RadarChart } from '@mantine/charts';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import AppFlexbox from '../../common/AppFlexbox';
import MenuContent from '../../common/MenuContent';

const PlayerSkillsCard = ({
  ratedEvaluation,
  primaryColor,
  secondaryColor,
  isPrimaryDarkText,
  isSecondaryDarkText,
  actions
}) => {
  const {
    regScoutReportEvaluation,
    overallRating,
    answerRatings
  } = ratedEvaluation;

  return (
    <AppCard
      shadow="sm"
      style={{ padding: 0, flex: 1, minWidth: 300 }}
      withBorder
    >
      <AppStack style={{ gap: 0 }}>
        <AppFlexbox
          style={{
            justifyContent: 'space-between',
            padding: 10,
            alignItems: 'center',
            gap: 5,
            backgroundColor: primaryColor,
            color: isPrimaryDarkText ? '#000' : '#FFF'
          }}
        >
          <AppText weight={500}>
            {regScoutReportEvaluation.person.firstName}{' '}
            {regScoutReportEvaluation.person.lastName}
          </AppText>

          {actions && actions.length > 0 && (
            <MenuContent
              control={
                <ActionIcon
                  color={isPrimaryDarkText ? '#000' : '#FFF'}
                  variant="subtle"
                >
                  <DotsVertical />
                </ActionIcon>
              }
            >
              {actions.map((a) => (
                <Menu.Item
                  key={a.value}
                  onClick={(e) => {
                    e.preventDefault();
                    a.onClick();
                  }}
                >
                  {a.label}
                </Menu.Item>
              ))}
            </MenuContent>
          )}
        </AppFlexbox>
        <AppFlexbox
          style={{
            justifyContent: 'center',
            padding: 10,
            borderBottom: 'solid 1px lightgrey',
            backgroundColor: secondaryColor,
            color: isSecondaryDarkText ? '#000' : '#FFF'
          }}
        >
          <AppText weight={700}>
            {Math.round(overallRating * 10) / 10} Overall
          </AppText>
        </AppFlexbox>
        {answerRatings.length < 3 ? (
          <AppStack
            style={{
              padding: 10,
              height: 200,
              justifyContent: 'center',
              textAlign: 'center'
            }}
          >
            <AppText>Please select at least 3 metrics</AppText>
          </AppStack>
        ) : (
          <RadarChart
            data={answerRatings.slice(0, 5)}
            dataKey="label"
            h={200}
            polarRadiusAxisProps={{ domain: [1, 5] }}
            radarChartProps={{}}
            series={[
              {
                name: 'value',
                strokeColor: primaryColor,
                color: primaryColor,
                opacity: 0.2
              }
            ]}
            ticks={[1, 2, 3, 4, 5]}
            withPolarAngleAxis
            withPolarGrid
            withPolarRadiusAxis
          />
        )}
      </AppStack>
    </AppCard>
  );
};

PlayerSkillsCard.propTypes = {
  actions: PropTypes.array,
  isPrimaryDarkText: PropTypes.bool,
  isSecondaryDarkText: PropTypes.bool,
  primaryColor: PropTypes.string,
  ratedEvaluation: PropTypes.object,
  secondaryColor: PropTypes.string
};

export default PlayerSkillsCard;
