import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Checkbox, Radio, Select } from '@mantine/core';
import { RadarChart } from '@mantine/charts';
import { Legend } from 'recharts';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import AppFlexbox from '../../common/AppFlexbox';

const accordionValue = 'top-players-filter';

const PlayerCompareChart = ({ regAssociation, ratedEvaluations }) => {
  const [chartState, setChartState] = useState({
    showFilters: false,
    player1: '',
    player2: '',
    selectedMetrics: []
  });

  const metricOptions = ratedEvaluations.reduce((r, c) => {
    c.answerRatings.forEach((a) => {
      if (!r.includes(a.label)) {
        r.push(a.label);
      }
    });
    return r;
  }, []);
  const allMetricsSelected = metricOptions.every((e) =>
    chartState.selectedMetrics.includes(e)
  );

  const playerSelectOptions = ratedEvaluations.map((e) => ({
    value: e.regScoutReportEvaluation.pkRegScoutReportEvaluation.toString(),
    label: `${e.regScoutReportEvaluation.person.firstName} ${e.regScoutReportEvaluation.person.lastName}`
  }));

  const filteredEvaluations = ratedEvaluations
    .map((e) => {
      const filteredAnswers =
        chartState.selectedMetrics.length === 0
          ? e.answerRatings
          : e.answerRatings.filter((a) =>
              chartState.selectedMetrics.includes(a.label)
            );

      return {
        ...e,
        answerRatings: filteredAnswers,
        overallRating:
          Math.round(
            (filteredAnswers.reduce((r, c) => r + c.value, 0) /
              filteredAnswers.length) *
              10
          ) / 10
      };
    })
    .filter((e) =>
      chartState.selectedMetrics.every((m) =>
        e.answerRatings.map((a) => a.label).includes(m)
      )
    );

  const selectedPlayer1 = filteredEvaluations.find(
    (e) =>
      e.regScoutReportEvaluation.pkRegScoutReportEvaluation.toString() ===
      chartState.player1
  );

  const selectedPlayer2 = filteredEvaluations.find(
    (e) =>
      e.regScoutReportEvaluation.pkRegScoutReportEvaluation.toString() ===
      chartState.player2
  );

  const chartData =
    selectedPlayer1?.answerRatings.map((a, i) => ({
      label: a.label,
      player1: a.value,
      player2: selectedPlayer2?.answerRatings[i]?.value || null
    })) ||
    selectedPlayer2?.answerRatings.map((a, i) => ({
      label: a.label,
      player1: null,
      player2: selectedPlayer2?.answerRatings[i].value
    })) ||
    [];

  return (
    <AppCard shadow="sm" style={{ padding: 0 }} withBorder>
      <AppStack style={{ gap: 0 }}>
        <Accordion
          onChange={() => {
            setChartState({
              ...chartState,
              showFilters: !chartState.showFilters
            });
          }}
          styles={{
            control: { padding: 0 },
            label: { padding: 0 },
            content: { padding: 0 },
            chevron: { display: 'none' }
          }}
          value={chartState.showFilters ? accordionValue : ''}
        >
          <Accordion.Item value={accordionValue}>
            <Accordion.Control>
              <AppFlexbox style={{ padding: 10 }}>
                <AppText weight={500}>Compare Players</AppText>
              </AppFlexbox>
            </Accordion.Control>

            <Accordion.Panel>
              <AppStack
                style={{
                  padding: '10px 15px',
                  borderTop: 'solid 1px #dee2e6'
                }}
              >
                <AppFlexbox style={{ flex: 1 }}>
                  <AppStack style={{ flex: 1, gap: 5 }}>
                    <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                      Filters:
                    </AppText>
                    <Radio.Group
                      onChange={(v) =>
                        setChartState({
                          ...chartState,
                          ranking: v
                        })
                      }
                      value={chartState.ranking}
                    >
                      <AppFlexbox>
                        <Radio label="Top Players" value="top" />
                        <Radio label="Worst Players" value="worst" />
                      </AppFlexbox>
                    </Radio.Group>
                  </AppStack>

                  <AppStack style={{ flex: 1, gap: 5 }}>
                    {metricOptions.length === 0 ? (
                      <AppText>No metrics available</AppText>
                    ) : (
                      <>
                        <AppFlexbox style={{ justifyContent: 'space-between' }}>
                          <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                            Metrics:
                          </AppText>

                          <Checkbox
                            checked={allMetricsSelected}
                            label={
                              allMetricsSelected ? 'Unselect All' : 'Select All'
                            }
                            onChange={() =>
                              setChartState({
                                ...chartState,
                                selectedMetrics: allMetricsSelected
                                  ? []
                                  : metricOptions
                              })
                            }
                          />
                        </AppFlexbox>

                        <AppFlexbox style={{ flexWrap: 'wrap' }}>
                          {metricOptions
                            .sort((a, b) => a.localeCompare(b))
                            .map((m) => {
                              const isSelected = chartState.selectedMetrics.includes(
                                m
                              );
                              return (
                                <Checkbox
                                  key={m}
                                  checked={isSelected}
                                  label={m}
                                  onChange={() => {
                                    setChartState({
                                      ...chartState,
                                      selectedMetrics: isSelected
                                        ? chartState.selectedMetrics.filter(
                                            (x) => x !== m
                                          )
                                        : [...chartState.selectedMetrics, m]
                                    });
                                  }}
                                />
                              );
                            })}
                        </AppFlexbox>
                      </>
                    )}
                  </AppStack>
                </AppFlexbox>
              </AppStack>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>

        <AppStack>
          <AppFlexbox
            style={{ justifyContent: 'space-evenly', padding: 15, flex: 1 }}
          >
            <Select
              clearable
              data={playerSelectOptions.filter(
                (f) => f.value !== chartState.player2
              )}
              label="Player 1"
              onChange={(v) =>
                setChartState({
                  ...chartState,
                  player1: v
                })
              }
              searchable
              style={{ flex: 1, maxWidth: 300 }}
              value={chartState.player1}
            />
            <Select
              clearable
              data={playerSelectOptions.filter(
                (f) => f.value !== chartState.player1
              )}
              label="Player 2"
              onChange={(v) =>
                setChartState({
                  ...chartState,
                  player2: v
                })
              }
              searchable
              style={{ flex: 1, maxWidth: 300 }}
              value={chartState.player2}
            />
          </AppFlexbox>
          {!selectedPlayer1 && !selectedPlayer2 ? (
            <AppStack
              style={{
                height: 300,
                justifyContent: 'center',
                textAlign: 'center'
              }}
            >
              <AppText weight={500}>Select a player to start comparing</AppText>
            </AppStack>
          ) : (
            <RadarChart
              data={chartData}
              dataKey="label"
              h={300}
              polarRadiusAxisProps={{ domain: [1, 5] }}
              radarChartProps={{}}
              series={[
                { name: 'player1', color: 'lime.4', opacity: 0.1 },
                { name: 'player2', color: 'cyan.4', opacity: 0.1 }
              ]}
              ticks={[1, 2, 3, 4, 5]}
              width={300}
              withPolarAngleAxis
              withPolarGrid
              withPolarRadiusAxis
            >
              <Legend />
            </RadarChart>
          )}
        </AppStack>
      </AppStack>
    </AppCard>
  );
};

PlayerCompareChart.propTypes = {
  ratedEvaluations: PropTypes.array,
  regAssociation: PropTypes.object
};

export default PlayerCompareChart;
