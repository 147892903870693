import React, { useContext, useEffect, useRef, useState } from 'react';
import { Divider } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import { triggerNotification } from '../../../helpers/notificationHelper';
import RegisterFormModal from '../associations/RegisterFormModal';
import InviteCard from '../invites/InviteCard';
import InviteListItem from '../invites/InviteListItem';
import RegistrationSignupListItem from '../associations/RegistrationSignupListItem';

const CheckoutExternalInvite = ({ inviteCode }) => {
  const hasFetchedInvite = useRef(false);
  const navigate = useNavigate();
  const {
    state,
    fetchRegAssociationDivisionFormExternalInvite,
    fetchRegistrants,
    addProductsToCart,
    expireRegAssociationDivisionFormExternalInvite
  } = useContext(RegistrationContext);
  const [formState, setFormState] = useState({
    showConfirmCancel: false,
    isLoading: false
  });
  const [selectedDivisionFormState, setSelectedDivisionFormState] = useState({
    isOpen: false,
    divisionForm: null
  });
  const externalInvite = state.regAssociationDivisionFormExternalInvite.value;

  const isLoading =
    !hasFetchedInvite.current ||
    state.regAssociationDivisionFormExternalInvite.loading ||
    !externalInvite;

  const invite = externalInvite
    ? {
        label: `${
          externalInvite.regPerson?.firstName ??
          externalInvite.participantFirstName
        } ${
          externalInvite.regPerson?.lastName ??
          externalInvite.participantLastName
        }`,
        subLabel: externalInvite.dateOfBirth
          ? dayjs(externalInvite.dateOfBirth).format('MM/DD/YYYY')
          : null,
        description:
          externalInvite.regAssociationDivisionForm.regAssociationDivision.name,
        subDescription: externalInvite.regAssociationDivisionForm.regForm.name,
        accepted: externalInvite.accepted,
        deleted: externalInvite.deleted,
        expiresAt: externalInvite.expiresAt
      }
    : {};

  useEffect(() => {
    if (inviteCode) {
      fetchRegAssociationDivisionFormExternalInvite(inviteCode);
      fetchRegistrants();
      hasFetchedInvite.current = true;
    }
  }, [inviteCode]);

  useEffect(() => {
    if (state.regAssociationDivisionFormExternalInvite.error) {
      navigate('/checkout');
    }
  }, [state.regAssociationDivisionFormExternalInvite.error]);

  return (
    <>
      <InviteCard
        expiresAt={externalInvite?.expiresAt}
        isDeleted={externalInvite?.deleted}
        isLoading={isLoading}
        isSubmitting={formState.isLoading}
        onAccept={() => {
          if (!externalInvite.regPerson) {
            setSelectedDivisionFormState({
              isOpen: true,
              divisionForm: externalInvite.regAssociationDivisionForm
            });
          }
          else {
            setFormState({
              ...formState,
              isLoading: true
            });
            addProductsToCart(
              {
                regCartProducts: [
                  {
                    fkRegAssociationDivisionForm:
                      externalInvite.regAssociationDivisionForm
                        .pkRegAssociationDivisionForm,
                    inviteCode: externalInvite.inviteCode,
                    fkRegPerson: externalInvite.regPerson.pkRegPerson
                  }
                ]
              },
              () => {
                triggerNotification('Item added to cart', 'Success', 'green');
                navigate('/checkout');
              },
              (message) => {
                triggerNotification(message);
                setFormState({
                  ...formState,
                  isLoading: false
                });
              }
            );
          }
        }}
        onDecline={() => {
          setFormState({
            ...formState,
            isLoading: true
          });
          expireRegAssociationDivisionFormExternalInvite(
            externalInvite.pkRegAssociationDivisionFormExternalInvite,
            { inviteCode: externalInvite.inviteCode },
            () => {
              triggerNotification(
                'Invite successfully denied',
                'Success',
                'green'
              );
              navigate('/');
            },
            (message) => {
              triggerNotification(message);
              setFormState({
                ...formState,
                isLoading: false
              });
            }
          );
        }}
      >
        {!isLoading && (
          <>
            <InviteListItem label={invite.label} {...invite} />
            <Divider />
            <RegistrationSignupListItem
              hideButton
              regAssociationDivisionForm={
                externalInvite.regAssociationDivisionForm
              }
            />
          </>
        )}
      </InviteCard>
      <RegisterFormModal
        externalInvite={externalInvite}
        isOpen={selectedDivisionFormState.isOpen}
        onClose={() =>
          setSelectedDivisionFormState({
            ...selectedDivisionFormState,
            isOpen: false
          })
        }
        regAssociationDivisionForm={selectedDivisionFormState.divisionForm}
      />
    </>
  );
};

CheckoutExternalInvite.propTypes = { inviteCode: PropTypes.string };

export default CheckoutExternalInvite;
