import React, { useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { TextInput } from '@mantine/core';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppTitle from '../../common/AppTitle';
import FormSection from '../../common/FormSection';
import AppStack from '../../common/AppStack';
import { triggerNotification } from '../../../helpers/notificationHelper';

const RegistrationChangeOverrideEmailModal = ({
  isOpen,
  onClose,
  onRefresh,
  regFormSubmission
}) => {
  const { changeRegistrationOverrideEmail } = useContext(
    RegistrationAdminContext
  );
  const [formState, setFormState] = useState({
    overrideEmail: null,
    isLoading: false
  });

  useEffect(() => {
    if (isOpen) {
      setFormState({
        overrideEmail:
          regFormSubmission.overrideEmail ??
          regFormSubmission?.user.email ??
          '',
        isLoading: false
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      onClose={onClose}
      opened={isOpen}
      size={600}
      title={<AppTitle order={2}>Change Contact Email</AppTitle>}
    >
      <FormSection
        isLoading={formState.isLoading}
        onCancel={onClose}
        onSubmit={() => {
          setFormState({
            ...formState,
            isLoading: true
          });

          changeRegistrationOverrideEmail(
            regFormSubmission.pkRegFormSubmission,
            { overrideEmail: formState.overrideEmail },
            () => {
              triggerNotification('Email updated!', 'Success', 'green');
              if (onRefresh) {
                onRefresh();
              }
              onClose();
            },
            (message) => {
              setFormState({
                ...formState,
                isLoading: false
              });
              triggerNotification(message);
            }
          );
        }}
        submitTitle="Change Email"
      >
        <AppStack>
          <TextInput
            label="Email"
            onChange={(e) =>
              setFormState({
                ...formState,
                overrideEmail: e.currentTarget.value
              })
            }
            placeholder={regFormSubmission?.user.email}
            type="email"
            value={formState.overrideEmail}
          />
        </AppStack>
      </FormSection>
    </ResponsiveModal>
  );
};

RegistrationChangeOverrideEmailModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onRefresh: PropTypes.func,
  regFormSubmission: PropTypes.object
};
export default RegistrationChangeOverrideEmailModal;
