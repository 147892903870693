import React, { useContext, useEffect, useRef, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Plus } from 'tabler-icons-react';
import { Context as RegistrationAdminContext } from '../providers/RegistrationAdminProvider';
import { Context as RegistrationAdminDashboardContext } from '../providers/RegistrationAdminDashboardProvider';
import RegularCouponView from '../components/content/coupons/RegularCouponView';
import GroupCouponView from '../components/content/coupons/GroupCouponView';
import AdminView from '../components/common/AdminView';
import { useModalState } from '../helpers/hooks';
import SUPPORT_LINKS from '../components/content/navigation/supportLinks';

const VIEW_TABS = [
  {
    to: '/admin/coupons/standard',
    title: 'Standard',
    value: 'standard',
    index: 0,
    control: {
      label: 'Create Coupon',
      icon: <Plus />,
      action: 'CREATE'
    },
    indexHelpLink: SUPPORT_LINKS.couponLinks.index,
    helpLinks: [SUPPORT_LINKS.couponLinks.regularCoupons]
  },
  {
    to: '/admin/coupons/group',
    title: 'Group',
    value: 'group',
    index: 1,
    control: {
      label: 'Create Coupon',
      icon: <Plus />,
      action: 'CREATE'
    },
    indexHelpLink: SUPPORT_LINKS.couponLinks.index,
    helpLinks: [SUPPORT_LINKS.couponLinks.groupCoupons]
  }
];

const CouponsView = () => {
  const hasFetched = useRef(false);
  const { state: modalState, onOpenModal } = useModalState({
    closeOnOpen: true
  });
  const { state: dashboardState } = useContext(
    RegistrationAdminDashboardContext
  );
  const { state, fetchAdminRegAssociationCoupons } = useContext(
    RegistrationAdminContext
  );
  const [filterState, setFilterState] = useState({
    fkRegAssociationSeason: '',
    fkRegAssociationDivision: '',
    regFormUuid: '',
    search: ''
  });

  const isLoading =
    !hasFetched.current ||
    state.regAssociationCoupons.loading ||
    state.regAssociationGroupCoupons.loading;
  const regAssociation = dashboardState.regAssociation.value;

  useEffect(() => {
    if (regAssociation) {
      fetchAdminRegAssociationCoupons(regAssociation.pkRegAssociation);
      hasFetched.current = true;
    }
  }, [regAssociation]);

  return (
    <AdminView
      headerTitle="Coupons"
      isViewLoading={isLoading}
      onAction={onOpenModal}
      tabs={VIEW_TABS}
    >
      <Routes>
        <Route
          element={
            <RegularCouponView
              filterState={filterState}
              isLoading={isLoading}
              modalAction={modalState.isOpen ? modalState.action : ''}
              regAssociation={regAssociation}
              setFilterState={setFilterState}
            />
          }
          path="/standard"
        />
        <Route
          element={
            <GroupCouponView
              filterState={filterState}
              isLoading={isLoading}
              modalAction={modalState.isOpen ? modalState.action : ''}
              regAssociation={regAssociation}
              setFilterState={setFilterState}
            />
          }
          path="/group"
        />
        <Route element={<Navigate replace to={VIEW_TABS[0].to} />} path="*" />
      </Routes>
    </AdminView>
  );
};

export default CouponsView;
