import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, Checkbox, Select } from '@mantine/core';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';

const GenerateTeamAttributeAssign = ({
  team,
  teams,
  label,
  fkRegScoutReportQuestion,
  customQuestion,
  disabled,
  options,
  questionAnswers,
  selectedAttributes,
  onSelectAttribute,
  onUpdateTeam
}) => {
  const isSelected = selectedAttributes.some(
    (a) =>
      a.fkRegScoutReportQuestion === fkRegScoutReportQuestion ||
      a.customQuestion === customQuestion
  );

  const updateTeamAttributes = (attributeOption, minimum, maximum) => {
    onUpdateTeam({
      ...team,
      attributes: [
        ...team.attributes.filter(
          (f) =>
            !(
              (f.fkRegScoutReportQuestion === fkRegScoutReportQuestion ||
                f.customQuestion === customQuestion) &&
              f.value === attributeOption.value
            )
        ),
        {
          customQuestion,
          fkRegScoutReportQuestion: attributeOption.fkRegScoutReportQuestion,
          value: attributeOption.value,
          minimum,
          maximum
        }
      ]
    });
  };

  return (
    <Accordion.Item
      value={`${team.key}-${fkRegScoutReportQuestion || customQuestion}`}
    >
      <Accordion.Control>
        <AppStack style={{ display: 'inline-block' }}>
          <AppFlexbox
            onClick={(e) => {
              e.stopPropagation();
              onSelectAttribute({
                fkRegScoutReportQuestion,
                customQuestion
              });
            }}
          >
            <Checkbox
              checked={isSelected}
              disabled={disabled}
              onChange={() => {}}
            />
            <AppText style={{ fontWeight: 500 }}>{label}</AppText>
          </AppFlexbox>
        </AppStack>
      </Accordion.Control>
      <Accordion.Panel>
        <AppStack style={{ gap: 10 }}>
          {options.map((op) => {
            const optionAnswers = questionAnswers.filter(
              (f) => f.value === op.value
            );

            const teamAttribute = team.attributes.find(
              (f) =>
                f.value === op.value &&
                f.fkRegScoutReportQuestion === op.fkRegScoutReportQuestion
            );

            const alreadyAssignedAmount = teams
              .filter((t) => t.key !== team.key)
              .reduce((r, c) => {
                const attribute = c.attributes.find(
                  (f) =>
                    f.value === op.value &&
                    f.fkRegScoutReportQuestion === op.fkRegScoutReportQuestion
                );

                if (attribute) {
                  return r + (attribute.minimum ?? 0);
                }

                return r;
              }, 0);

            return (
              <AppStack
                key={op.pkRegScoutReportQuestionOption || op.value}
                style={{ gap: 5 }}
              >
                <AppText
                  style={{
                    fontSize: 14,
                    fontWeight: 500
                  }}
                >
                  {op.value}
                </AppText>
                <AppFlexbox style={{ gap: 0 }}>
                  <Select
                    clearable
                    data={optionAnswers
                      .slice(0, optionAnswers.length - alreadyAssignedAmount)
                      .map((m, i) => ({
                        label: (i + 1).toString(),
                        value: (i + 1).toString()
                      }))}
                    disabled={disabled}
                    onChange={(v) => {
                      updateTeamAttributes(
                        op,
                        v ? Number(v) : null,
                        teamAttribute?.maximum ?? null
                      );
                    }}
                    placeholder="Balanced"
                    rightSection={
                      <AppText
                        style={{
                          fontSize: 14,
                          weight: 500,
                          width: 50
                        }}
                      >
                        MIN
                      </AppText>
                    }
                    searchable
                    style={{
                      flex: 1,
                      height: 36
                    }}
                    value={teamAttribute?.minimum?.toString() ?? null}
                  />
                  <Select
                    clearable
                    data={[
                      {
                        label: '0',
                        value: '0'
                      },
                      ...optionAnswers.map((m, i) => ({
                        label: (i + 1).toString(),
                        value: (i + 1).toString()
                      }))
                    ]}
                    disabled={disabled}
                    onChange={(v) => {
                      updateTeamAttributes(
                        op,
                        teamAttribute?.minimum ?? null,
                        v ? Number(v) : null
                      );
                    }}
                    placeholder="Balanced"
                    rightSection={
                      <AppText
                        style={{
                          fontSize: 14,
                          weight: 500,
                          width: 50
                        }}
                      >
                        MAX
                      </AppText>
                    }
                    searchable
                    style={{ flex: 1 }}
                    value={teamAttribute?.maximum?.toString() ?? null}
                  />
                </AppFlexbox>
              </AppStack>
            );
          })}
        </AppStack>
      </Accordion.Panel>
    </Accordion.Item>
  );
};

GenerateTeamAttributeAssign.propTypes = {
  customQuestion: PropTypes.string,
  disabled: PropTypes.bool,
  fkRegScoutReportQuestion: PropTypes.string,
  label: PropTypes.string,
  onSelectAttribute: PropTypes.func,
  onUpdateTeam: PropTypes.func,
  options: PropTypes.array,
  questionAnswers: PropTypes.array,
  selectedAttributes: PropTypes.array,
  team: PropTypes.object,
  teams: PropTypes.array
};

export default GenerateTeamAttributeAssign;
