import React, { useContext, useEffect, useRef } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Mail } from 'tabler-icons-react';
import { Context as RegistrationAdminContext } from '../providers/RegistrationAdminProvider';
import { Context as RegistrationAdminDashboardContext } from '../providers/RegistrationAdminDashboardProvider';
import AdminView from '../components/common/AdminView';
import FinancialPayees from '../components/content/financials/FinancialPayees';
import FinancialRegistrations from '../components/content/financials/FinancialRegistrations';
import SUPPORT_LINKS from '../components/content/navigation/supportLinks';
import LeagueDivisionWaitlistView from '../components/content/leagues/LeagueDivisionWaitlistView';
import LeagueDivisionInvitesView from '../components/content/leagues/LeagueDivisionInvitesView';
import { useModalState } from '../helpers/hooks';
import MerchOrders from '../components/content/merch/MerchOrders';
import { useMyLeagueFilterOptions } from '../helpers/leagueHooks';

const VIEW_TABS = [
  {
    to: '/admin/financials/registrations',
    title: 'Registrations',
    value: 'registrations',
    index: 0,
    indexHelpLink: SUPPORT_LINKS.financialLinks.index,
    helpLinks: [
      SUPPORT_LINKS.financialLinks.reconciliation,
      SUPPORT_LINKS.financialLinks.payouts,
      SUPPORT_LINKS.financialLinks.registrations
    ]
  },
  {
    to: '/admin/financials/waitlist',
    title: 'Waitlist',
    value: 'waitlist',
    index: 1,
    control: {
      label: 'Email Waitlist',
      icon: <Mail />,
      action: 'BULK_EMAIL'
    },
    isControlDisabled: (state) =>
      state.regWaitlistRegistrants.value.length === 0
  },
  {
    to: '/admin/financials/invites',
    title: 'Invites',
    value: 'invites',
    index: 2,
    indexHelpLink: SUPPORT_LINKS.myLeagueLinks.index,
    helpLinks: [
      SUPPORT_LINKS.myLeagueLinks.waitlists,
      SUPPORT_LINKS.myLeagueLinks.invites,
      SUPPORT_LINKS.myLeagueLinks.emailUsers
    ]
  },
  {
    to: '/admin/financials/merchandise',
    title: 'Merchandise',
    value: 'merchandise',
    index: 3
  },
  {
    to: '/admin/financials/payee',
    title: 'Payee',
    value: 'payee',
    index: 4,
    indexHelpLink: SUPPORT_LINKS.financialLinks.index,
    helpLinks: [SUPPORT_LINKS.financialLinks.paymentProviders]
  }
];

const FinancialsView = () => {
  const fetchedPkRegAssociation = useRef(false);
  const fetchedWaitlistPkRegAssociation = useRef(null);
  const fetchedInvitePkRegAssociation = useRef(null);
  const { pathname } = useLocation();
  const { state: dashboardState } = useContext(
    RegistrationAdminDashboardContext
  );
  const {
    state,
    fetchAdminRegAssociationLeagueOptions,
    fetchAdminWaitlistRegistrants,
    fetchAdminRegAssociationDivisionFormInvites
  } = useContext(RegistrationAdminContext);
  const { state: modalState, onOpenModal } = useModalState({
    closeOnOpen: true
  });
  const { filters, filterState, onResetFilterState } = useMyLeagueFilterOptions(
    dashboardState.regAssociation.value,
    {
      includeSeason: true,
      includeDivision: true,
      includeRegPerson: true,
      includeRegForm: true,
      useMyLeagueOptions: true,
      notRequiredFields: true
    }
  );
  const selectedTab = VIEW_TABS.find((tab) => pathname.startsWith(tab.to));
  const isLoading =
    !fetchedPkRegAssociation.current || state.regFormSubmissions.loading;
  const regAssociation = dashboardState.regAssociation.value;

  useEffect(() => {
    if (regAssociation) {
      if (fetchedPkRegAssociation.current !== regAssociation.pkRegAssociation) {
        fetchAdminRegAssociationLeagueOptions(regAssociation.pkRegAssociation);
        onResetFilterState();
        fetchedPkRegAssociation.current = regAssociation.pkRegAssociation;
      }

      switch (selectedTab?.index) {
        case 1:
          if (
            fetchedWaitlistPkRegAssociation.current !==
            regAssociation.pkRegAssociation
          ) {
            fetchAdminWaitlistRegistrants({
              pkRegAssociation: regAssociation.pkRegAssociation
            });
            fetchedWaitlistPkRegAssociation.current =
              regAssociation.pkRegAssociation;
          }
          break;
        case 2:
          if (
            fetchedInvitePkRegAssociation.current !==
            regAssociation.pkRegAssociation
          ) {
            fetchAdminRegAssociationDivisionFormInvites({
              pkRegAssociation:
                dashboardState.regAssociation.value.pkRegAssociation
            });
            fetchedInvitePkRegAssociation.current =
              regAssociation.pkRegAssociation;
          }
          break;
        default:
          break;
      }
    }
  }, [regAssociation, pathname]);

  return (
    <AdminView
      headerTitle="Form Submissions"
      isActionControlDisabled={
        selectedTab?.isControlDisabled && selectedTab?.isControlDisabled(state)
      }
      isViewLoading={isLoading}
      onAction={onOpenModal}
      tabs={VIEW_TABS}
    >
      <Routes>
        <Route
          element={
            <FinancialRegistrations
              isLoading={isLoading}
              regAssociation={regAssociation}
            />
          }
          path="/registrations"
        />
        <Route
          element={
            <LeagueDivisionWaitlistView
              filters={filters}
              filterState={filterState}
              isLoading={
                !regAssociation ||
                fetchedWaitlistPkRegAssociation.current !==
                  regAssociation.pkRegAssociation ||
                isLoading ||
                state.regWaitlistRegistrants.loading
              }
              modalAction={modalState.isOpen ? modalState.action : ''}
            />
          }
          path="waitlist/*"
        />
        <Route
          element={
            <LeagueDivisionInvitesView
              filters={filters}
              filterState={filterState}
              isLoading={
                !regAssociation ||
                fetchedInvitePkRegAssociation.current !==
                  regAssociation.pkRegAssociation ||
                isLoading ||
                state.regAssociationDivisionFormInvites.loading
              }
            />
          }
          path="invites/*"
        />
        <Route
          element={<MerchOrders regAssociation={regAssociation} />}
          path="merchandise/*"
        />
        <Route
          element={<FinancialPayees regAssociation={regAssociation} />}
          path="/payee"
        />
        <Route element={<Navigate replace to={VIEW_TABS[0].to} />} path="*" />
      </Routes>
    </AdminView>
  );
};

export default FinancialsView;
