import React, { useRef, useState } from 'react';
import { Button } from '@mantine/core';
import PropTypes from 'prop-types';
import { FileUpload } from 'tabler-icons-react';
import AppStack from './AppStack';
import AppTitle from './AppTitle';

const FileUploader = ({
  height,
  maxFileSize,
  onUpload,
  onError,
  accept,
  validFileTypes,
  ...rest
}) => {
  const fileInputRef = useRef(null);
  const dragRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);

  const validateFileUpload = (file) => {
    const fileSize = file.size / 1024 / 1024;
    if (validFileTypes && !validFileTypes.find((t) => file.type === t)) {
      onError(`Invalid File Type`);
    }
    else if (fileSize > maxFileSize ?? 50) {
      onError(`File exceeds maximum size of ${maxFileSize}MB`);
    }
    else {
      onUpload(file);
    }
  };

  const onDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target !== dragRef.current) {
      setIsDragging(true);
    }
  };

  const onDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target === dragRef.current) {
      setIsDragging(false);
    }
  };

  const onDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    validateFileUpload(e.dataTransfer.files[0]);
    setIsDragging(false);
  };

  const onInputChange = (e) => {
    e.preventDefault();
    e.stopPropagation();

    validateFileUpload(e.target.files[0]);
  };

  return (
    <AppStack
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDrop={onDrop}
      {...rest}
      style={{
        position: 'relative',
        boxSizing: 'border-box',
        border: isDragging ? 'dotted 2px dodgerblue' : 'dotted 1px lightgrey',
        flex: 1,
        minHeight: height,
        backgroundColor: isDragging ? '#ced4da' : 'none',
        ...rest.sx
      }}
    >
      {isDragging && (
        <AppStack
          ref={dragRef}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 9
          }}
        />
      )}

      <AppStack
        style={{
          alignItems: 'center',
          margin: 'auto',
          gap: 10
        }}
      >
        <FileUpload color="#999" size={40} />
        <Button
          disabled={isDragging}
          onClick={() => fileInputRef.current.click()}
        >
          Browse
        </Button>
        <AppTitle order={4} style={{ textAlign: 'center', color: '#999' }}>
          Or drag a file here
        </AppTitle>
      </AppStack>

      <input
        ref={fileInputRef}
        accept={accept}
        data-testid="fileInput"
        hidden
        onChange={onInputChange}
        type="file"
      />
    </AppStack>
  );
};

FileUploader.propTypes = {
  accept: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxFileSize: PropTypes.number,
  onError: PropTypes.func,
  onUpload: PropTypes.func,
  validFileTypes: PropTypes.array
};

export default FileUploader;
