import React, { useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Button,
  Checkbox,
  Loader,
  RingProgress,
  Textarea,
  TextInput
} from '@mantine/core';
import {
  AlertCircle,
  Check,
  ClipboardCopy,
  ExternalLink,
  Pencil,
  Send,
  X
} from 'tabler-icons-react';
import FormSection from './FormSection';
import PaginationList from './PaginationList';
import { Context as RegistrationAdminContext } from '../../providers/RegistrationAdminProvider';
import { triggerNotification } from '../../helpers/notificationHelper';
import ResponsiveModal from './ResponsiveModal';
import AppFlexbox from './AppFlexbox';
import AppTitle from './AppTitle';
import AppStack from './AppStack';
import AppText from './AppText';
import { getResponsiveStyle as rs } from '../../helpers/styles';
import { useMediaQueryIndex } from '../../helpers/hooks';
import ConfirmModal from './ConfirmModal';
import { usePrompt } from '../../helpers/prompt';

const SendEmailModal = ({
  isOpen,
  onClose,
  emailList,
  isLoading,
  pkRegAssociation,
  pkRegAssociationSeason,
  pkRegAssociationDivision,
  pkRegAssociationDivisionTeam
}) => {
  const mqIndex = useMediaQueryIndex();
  const {
    state,
    fetchAdminRegAssociationRegistrants,
    fetchAdminRegAssociationEmailUnsubscribe,
    sendAdminRegistrationEmail
  } = useContext(RegistrationAdminContext);
  const [resultPageIndex, setResultPageIndex] = useState(1);
  const [formState, setFormState] = useState({
    emailList: [],
    selectedEmails: [],
    confirmedSelectedEmails: [],
    showEmailSelect: false,
    subject: '',
    body: '',
    sendCopy: true,
    emailSearch: '',
    isLoading: false,
    sendingState: {
      isSending: false,
      cancelSending: false,
      showCancelConfirm: false,
      sendIndex: 0,
      sesEmailSession: null
    }
  });

  const sentEmails =
    formState.sendingState.sesEmailSession?.sesEmails.filter((e) =>
      formState.confirmedSelectedEmails.includes(e.recipient)
    ) ?? [];

  const availableSelectedEmails = formState.confirmedSelectedEmails.filter(
    (e) => !state.regEmailUnsubscribe.value.some((s) => s.email === e)
  );

  usePrompt(
    'Are you sure you want to cancel sending this email?',
    formState.sendingState.isSending
  );

  useEffect(() => {
    if (isOpen) {
      if (
        pkRegAssociation &&
        (pkRegAssociationSeason ||
          pkRegAssociationDivision ||
          pkRegAssociationDivisionTeam)
      ) {
        setResultPageIndex(1);
        setFormState({
          emailList: [],
          selectedEmails: [],
          confirmedSelectedEmails: [],
          showEmailSelect: false,
          subject: '',
          body: '',
          sendCopy: true,
          isLoading: true,
          sendingState: {
            isSending: false,
            cancelSending: false,
            showCancelConfirm: false,
            sendIndex: 0,
            sesEmailSession: null
          }
        });
        fetchAdminRegAssociationEmailUnsubscribe(pkRegAssociation);
        fetchAdminRegAssociationRegistrants(
          pkRegAssociation,
          {
            season: pkRegAssociationSeason,
            division: pkRegAssociationDivision,
            divisionTeam: pkRegAssociationDivisionTeam
          },
          (data) => {
            const userEmails = [
              ...new Set(data.map((d) => d.overrideEmail ?? d.user.email))
            ];
            setFormState({
              emailList: userEmails,
              selectedEmails: userEmails,
              confirmedSelectedEmails: userEmails,
              showEmailSelect: false,
              subject: '',
              body: '',
              sendCopy: true,
              isLoading: false,
              sendingState: {
                isSending: false,
                cancelSending: false,
                showCancelConfirm: false,
                sendIndex: 0,
                sesEmailSession: null
              }
            });
          },
          triggerNotification
        );
      }
      else {
        setResultPageIndex(1);
        setFormState({
          emailList: emailList ?? [],
          selectedEmails: emailList ?? [],
          confirmedSelectedEmails: emailList ?? [],
          showEmailSelect: false,
          subject: '',
          body: '',
          sendCopy: true,
          isLoading: false,
          sendingState: {
            isSending: false,
            cancelSending: false,
            showCancelConfirm: false,
            sendIndex: 0,
            sesEmailSession: null
          }
        });
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (formState.sendingState.isSending) {
      if (formState.sendingState.cancelSending) {
        setFormState((c) => ({
          ...c,
          sendingState: {
            ...c.sendingState,
            isSending: false
          }
        }));
        triggerNotification('Email Cancelled', 'Success', 'green');
      }
      else {
        // eslint-disable-next-line no-use-before-define
        sendEmails();
      }
    }
  }, [formState.sendingState.isSending, formState.sendingState.sendIndex]);

  const sendEmails = () => {
    const remainingEmails = formState.sendingState.sesEmailSession
      ? formState.confirmedSelectedEmails.filter(
          (e) =>
            !formState.sendingState.sesEmailSession.sesEmails.some(
              (se) => se.recipient === e
            )
        )
      : formState.confirmedSelectedEmails;

    if (remainingEmails.length === 0) {
      setFormState((c) => ({
        ...c,
        sendingState: {
          ...c.sendingState,
          isSending: false
        }
      }));
      triggerNotification('Email sent successfully', 'Success', 'green');
    }
    else {
      sendAdminRegistrationEmail(
        pkRegAssociation,
        {
          pkSesEmailSession:
            formState.sendingState.sesEmailSession?.pkSesEmailSession,
          subject: formState.subject,
          body: formState.body,
          sendCopy: formState.sendCopy,
          emails: remainingEmails.slice(0, 25)
        },
        (sesEmailSession) => {
          setFormState((c) => ({
            ...c,
            sendingState: {
              ...c.sendingState,
              sendIndex: c.sendingState.sendIndex + 1,
              sesEmailSession
            }
          }));
        },
        (message) => {
          setFormState({
            ...formState,
            sendingState: {
              ...formState.sendingState,
              isSending: false
            }
          });
          triggerNotification(message);
        }
      );
    }
  };

  return (
    <ResponsiveModal
      isLoading={isLoading || formState.isLoading}
      onClose={() => {
        if (formState.sendingState.isSending) {
          setFormState({
            ...formState,
            showCancelConfirm: true
          });
        }
        else {
          onClose();
        }
      }}
      opened={isOpen}
      size={600}
      title={<AppTitle order={2}>Email Users</AppTitle>}
    >
      {formState.sendingState.isSending ||
      formState.sendingState.sesEmailSession ? (
        <AppStack>
          <FormSection
            cancelColor={formState.sendingState.isSending ? 'red' : 'dark'}
            cancelTitle={formState.sendingState.isSending ? 'Cancel' : 'Close'}
            isSubmitHidden
            onCancel={() => {
              if (formState.sendingState.isSending) {
                setFormState({
                  ...formState,
                  sendingState: {
                    ...formState.sendingState,
                    showCancelConfirm: true
                  }
                });
              }
              else {
                onClose();
              }
            }}
          >
            <AppStack style={{ gap: 20 }}>
              <AppFlexbox style={{ justifyContent: 'center' }}>
                <RingProgress
                  label={
                    <AppStack
                      style={{
                        gap: 5,
                        textAlign: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: formState.sendingState.isSending
                          ? 'dodgerblue'
                          : formState.sendingState.cancelSending
                          ? 'red'
                          : 'green',
                        fontWeight: 700
                      }}
                    >
                      <AppText style={{ fontSize: 24 }}>
                        {!formState.sendingState.sesEmailSession
                          ? '0%'
                          : `${
                              (sentEmails.length /
                                formState.confirmedSelectedEmails.length) *
                              100
                            }%`}
                      </AppText>
                      <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                        {formState.sendingState.isSending ? (
                          <>
                            <Send />
                            <AppText style={{ fontSize: 22 }}>
                              {formState.sendingState.cancelSending
                                ? 'Cancelling...'
                                : 'Sending...'}
                            </AppText>
                          </>
                        ) : formState.sendingState.cancelSending ? (
                          <AppText style={{ fontSize: 22 }}>Cancelled</AppText>
                        ) : (
                          <AppText style={{ fontSize: 22 }}>Sent</AppText>
                        )}
                      </AppFlexbox>
                    </AppStack>
                  }
                  sections={[
                    {
                      value: !formState.sendingState.sesEmailSession
                        ? 0
                        : (sentEmails.length /
                            formState.confirmedSelectedEmails.length) *
                          100,
                      color: 'blue'
                    }
                  ]}
                  size={250}
                />
              </AppFlexbox>

              <AppStack style={{ gap: 5 }}>
                <AppFlexbox>
                  <AppText style={{ fontWeight: 500 }}>
                    Emails Sent ({sentEmails.length}/
                    {formState.confirmedSelectedEmails.length})
                  </AppText>
                </AppFlexbox>
                <PaginationList
                  items={formState.confirmedSelectedEmails.map((e) => {
                    const sentEmail = formState.sendingState.sesEmailSession?.sesEmails.find(
                      (se) => se.recipient === e
                    );
                    return (
                      <AppFlexbox
                        key={e}
                        style={{
                          gap: 5,
                          padding: 5,
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          overflow: 'hidden'
                        }}
                      >
                        <AppFlexbox
                          style={{
                            gap: 5,
                            alignItems: 'center',
                            overflow: 'hidden'
                          }}
                        >
                          {!sentEmail && formState.sendingState.isSending ? (
                            <Loader color="dark" size={24} />
                          ) : sentEmail?.sent ? (
                            <Check color="green" />
                          ) : (
                            <X color="red" />
                          )}
                          <AppText weight={500}>{e}</AppText>
                        </AppFlexbox>

                        <AppFlexbox>
                          {sentEmail ? (
                            <AppText
                              style={{color: sentEmail.sent ? 'green' : 'red'}}
                              weight={500}
                            >
                              {sentEmail.sent ? 'Sent' : 'Not sent'}
                            </AppText>
                          ) : (
                            !formState.sendingState.isSending && (
                              <AppText style={{ color: 'red' }} weight={500}>
                                Cancelled
                              </AppText>
                            )
                          )}
                        </AppFlexbox>
                      </AppFlexbox>
                    );
                  })}
                  onPageChange={setResultPageIndex}
                  pageIndex={resultPageIndex}
                />
              </AppStack>
            </AppStack>
          </FormSection>
        </AppStack>
      ) : formState.emailList.length === 0 ? (
        <AppStack
          style={{
            gap: 20,
            padding: '30px 20px',
            placeItems: 'center',
            border: 'solid 1px lightgrey'
          }}
        >
          <AppFlexbox style={{ gap: 10, justifyContent: 'center' }}>
            <AlertCircle color="red" size={45} />
            <AppText style={{ alignSelf: 'center' }}>
              No registrants available.
            </AppText>
          </AppFlexbox>
          <Button
            color="dark"
            onClick={onClose}
            style={{ maxWidth: 250, width: '100%' }}
          >
            Close
          </Button>
        </AppStack>
      ) : (
        <FormSection
          cancelTitle="Cancel"
          isSubmitDisabled={availableSelectedEmails.length === 0}
          onCancel={onClose}
          onSubmit={() => {
            setFormState({
              ...formState,
              confirmedSelectedEmails: formState.confirmedSelectedEmails.filter(
                (e) =>
                  !state.regEmailUnsubscribe.value.some((s) => s.email === e)
              ),
              sendingState: {
                isSending: true,
                cancelSending: false,
                showCancelConfirm: false,
                sesEmailSession: null,
                sendIndex: 0
              }
            });
          }}
          style={{ gap: 30 }}
          submitTitle="Send"
        >
          <AppStack style={{ gap: 20 }}>
            <AppFlexbox
              style={{
                justifyContent: 'space-between',
                flexDirection: rs(['column', 'row'], mqIndex),
                gap: 30,
                paddingTop: 10
              }}
            >
              <Checkbox
                checked={formState.sendCopy}
                label="Send a copy to my email"
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    sendCopy: e.currentTarget.checked
                  })
                }
              />
              <Button
                leftSection={<Pencil />}
                onClick={() =>
                  setFormState({
                    ...formState,
                    showEmailSelect: true,
                    emailSearch: ''
                  })
                }
                size="compact-md"
                style={{
                  width: rs(['100%', 'unset'], mqIndex),
                  maxWidth: rs(['unset', '250'], mqIndex)
                }}
              >
                Edit Recipients
              </Button>
            </AppFlexbox>
            <TextInput
              label="Subject"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  subject: e.currentTarget.value
                })
              }
              required
              value={formState.subject}
            />
            <Textarea
              autosize
              label="Body"
              minRows={12}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  body: e.currentTarget.value
                })
              }
              required
              value={formState.body}
            />

            <AppFlexbox
              style={{
                justifyContent: 'space-between',
                flexDirection: rs(['column-reverse', 'row'], mqIndex),
                gap: 30,
                paddingTop: 10
              }}
            >
              <Button
                leftSection={<ExternalLink />}
                onClick={() => window.open('mailto:', '_blank')}
                size="compact-md"
                style={{
                  width: rs(['100%', 'unset'], mqIndex),
                  maxWidth: rs(['unset', '250'], mqIndex)
                }}
                variant="outline"
              >
                Open In Local Client
              </Button>
              <Button
                leftSection={<ClipboardCopy />}
                onClick={() => {
                  const userEmails = [...new Set(formState.selectedEmails)];
                  navigator.clipboard.writeText(userEmails.join(';'));
                  triggerNotification(
                    'Email list copied to clipboard',
                    'Success',
                    'green'
                  );
                }}
                size="compact-md"
                style={{
                  width: rs(['100%', 'unset'], mqIndex),
                  maxWidth: rs(['unset', '250'], mqIndex)
                }}
                variant="outline"
              >
                Copy Recipient List
              </Button>
            </AppFlexbox>
          </AppStack>
        </FormSection>
      )}
      <ResponsiveModal
        onClose={() =>
          setFormState({
            ...formState,
            showEmailSelect: false
          })
        }
        opened={formState.showEmailSelect}
        size={600}
        title={<AppTitle order={2}>Edit Recipients</AppTitle>}
      >
        <FormSection
          cancelTitle="Cancel"
          onCancel={() => {
            setFormState({
              ...formState,
              showEmailSelect: false,
              selectedEmails: formState.confirmedSelectedEmails
            });
          }}
          onSubmit={() => {
            if (
              formState.selectedEmails.filter(
                (e) =>
                  !state.regEmailUnsubscribe.value.some((s) => s.email === e)
              ).length === 0
            ) {
              triggerNotification(
                'Please select at least one registrant email.'
              );
            }
            else {
              setFormState({
                ...formState,
                showEmailSelect: false,
                confirmedSelectedEmails: formState.selectedEmails
              });
            }
          }}
          style={{ gap: 30 }}
          submitTitle="Confirm"
        >
          <AppStack style={{ gap: 10 }}>
            <TextInput
              onChange={(e) =>
                setFormState({
                  ...formState,
                  emailSearch: e.currentTarget.value,
                  registrantPageIndex: 1
                })
              }
              placeholder="Filter by email"
              value={formState.emailSearch}
            />
            <PaginationList
              emptyMessage="No recipients"
              items={formState.emailList
                .filter(
                  (email) =>
                    !formState.emailSearch ||
                    email
                      .toLowerCase()
                      .includes(formState.emailSearch.toLowerCase())
                )
                .sort((a, b) => a.localeCompare(b))
                .map((email) => {
                  const hasUnsubscribed = state.regEmailUnsubscribe.value.find(
                    (e) => e.email === email
                  );

                  return (
                    <AppFlexbox
                      key={email}
                      style={{
                        padding: '10px 15px',
                        justifyContent: 'end',
                        alignItems: 'center',
                        overflow: 'hidden'
                      }}
                    >
                      <AppFlexbox
                        onClick={() => {
                          if (
                            formState.selectedEmails.find((e) => e === email)
                          ) {
                            setFormState({
                              ...formState,
                              selectedEmails: formState.selectedEmails.filter(
                                (e) => e !== email
                              )
                            });
                          }
                          else {
                            setFormState({
                              ...formState,
                              selectedEmails: [
                                ...formState.selectedEmails,
                                email
                              ]
                            });
                          }
                        }}
                        style={{
                          overflow: 'hidden',
                          gap: 10,
                          flex: 1,
                          cursor: 'pointer'
                        }}
                      >
                        <Checkbox
                          checked={
                            !hasUnsubscribed &&
                            !!formState.selectedEmails.find((e) => e === email)
                          }
                          disabled={hasUnsubscribed}
                          onChange={() => {}}
                        />
                        <AppText style={{ fontSize: 14 }}>{email}</AppText>
                      </AppFlexbox>
                      {hasUnsubscribed && (
                        <AppText style={{ color: 'red', fontSize: 14 }}>
                          Unsubscribed
                        </AppText>
                      )}
                    </AppFlexbox>
                  );
                })}
              itemsPerPage={10}
              onPageChange={(index) =>
                setFormState({
                  ...formState,
                  registrantPageIndex: index
                })
              }
              pageIndex={formState.registrantPageIndex}
            />
          </AppStack>
        </FormSection>
      </ResponsiveModal>
      <ConfirmModal
        cancelActionText="Back"
        confirmActionColor="red"
        confirmActionText="Stop Sending"
        isOpen={formState.sendingState.showCancelConfirm}
        message={
          <AppStack style={{ gap: 10, textAlign: 'center' }}>
            <AppText weight={500}>
              Are you sure you want to stop sending this email?
            </AppText>
            <AppText>Any emails already sent will not be affected.</AppText>
          </AppStack>
        }
        onCancel={() =>
          setFormState((c) => ({
            ...c,
            sendingState: {
              ...c.sendingState,
              showCancelConfirm: false
            }
          }))
        }
        onConfirm={() => {
          setFormState((c) => ({
            ...c,
            sendingState: {
              ...c.sendingState,
              cancelSending: true,
              showCancelConfirm: false
            }
          }));
        }}
        title="Cancel Email"
      />
    </ResponsiveModal>
  );
};

SendEmailModal.propTypes = {
  emailList: PropTypes.array,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  pkRegAssociation: PropTypes.number,
  pkRegAssociationDivision: PropTypes.number,
  pkRegAssociationDivisionTeam: PropTypes.number,
  pkRegAssociationSeason: PropTypes.number
};

export default SendEmailModal;
