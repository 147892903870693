import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Users } from 'tabler-icons-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import AppCard from '../../common/AppCard';
import TableView from '../../common/TableView';
import { REG_GENDER_TYPES } from '../../../config/constants';
import LeagueDivisionModal from './LeagueDivisionModal';
import SpecialRequestModal from './SpecialRequestsModal';
import LeagueDivisionInviteModal from './LeagueDivisionInviteModal';
import { useModalState, useSortByFilter } from '../../../helpers/hooks';
import SendEmailModal from '../../common/SendEmailModal';
import SyncDivisionTeamsModal from './SyncDivisionTeamsModal';

const DIVISION_ACTIONS_ENUM = {
  create: 'CREATE',
  edit: 'EDIT',
  duplicate: 'DUPLICATE',
  emailUsers: 'EMAIL',
  inviteUsers: 'INITE-USERS',
  specialRequests: 'SPECIAL-REQUESTS',
  sync: 'SYNC',
  delete: 'DELETE'
};

const DIVISION_ACTIONS = [
  {
    label: 'Edit',
    value: DIVISION_ACTIONS_ENUM.edit
  },
  {
    label: 'Duplicate',
    value: DIVISION_ACTIONS_ENUM.duplicate
  },
  {
    label: 'Invite Users',
    value: DIVISION_ACTIONS_ENUM.inviteUsers,
    isInviteOnly: true
  },
  {
    label: 'Email Users',
    value: DIVISION_ACTIONS_ENUM.emailUsers
  },
  {
    label: 'Special Requests',
    value: DIVISION_ACTIONS_ENUM.specialRequests
  },
  {
    label: 'Launch Team(s)',
    value: DIVISION_ACTIONS_ENUM.sync
  },
  {
    label: 'Delete',
    value: DIVISION_ACTIONS_ENUM.delete
  }
];

const TABLE_COLUMNS = [
  {
    label: 'Name',
    value: 'name',
    sortable: true
  },
  {
    label: 'Age Requirements',
    value: 'age',
    sortable: true
  },
  {
    label: 'Gender',
    value: 'gender',
    sortable: true
  },
  {
    label: 'Roles',
    value: 'roles',
    sortable: true
  },
  {
    label: 'Order Email',
    value: 'orderEmail',
    sortable: true
  },
  {
    label: 'Waitlist Enabled',
    value: 'waitlistEnabled',
    sortable: true
  },
  {
    label: 'Invite Only',
    value: 'inviteOnly',
    sortable: true
  }
];

const LeagueDivisionsView = ({
  isLoading,
  regAssociation,
  modalAction,
  filterState,
  setFilterState,
  filters
}) => {
  const { state: locationState } = useLocation();
  const navigate = useNavigate();
  const { state, fetchAdminRegAssociationSeasons } = useContext(
    RegistrationAdminContext
  );
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const {
    sortValue,
    isDescendingSort,
    onChangeSortBy,
    customFilterData
  } = useSortByFilter(TABLE_COLUMNS);
  const regAssociationSeason = state.regAssociationSeasons.value.find(
    (s) =>
      s.pkRegAssociationSeason.toString() ===
      filterState.pkRegAssociationSeason?.toString()
  );
  const regAssociationDivisions = state.regAssociationDivisions.value
    .filter(
      (a) =>
        a.fkRegAssociationSeason.toString() ===
        filterState?.pkRegAssociationSeason
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    if (modalAction === DIVISION_ACTIONS_ENUM.create) {
      onOpenModal(modalAction);
    }
  }, [modalAction]);

  useEffect(() => {
    if (locationState?.openModal && !isLoading) {
      onOpenModal(DIVISION_ACTIONS.create);
    }
  }, [locationState?.openModal, isLoading]);

  return (
    <AppCard style={{ flex: 1, padding: 0, height: '100%' }}>
      <TableView
        columns={TABLE_COLUMNS}
        emptyMessage="No Divisions Available"
        filters={filters}
        isDescendingSort={isDescendingSort}
        isLoading={isLoading}
        lastUpdated={state.regAssociationDivisions.lastUpdated}
        onAction={(action, item) => {
          onOpenModal(
            action,
            state.regAssociationDivisions.value.find(
              (s) => s.pkRegAssociationDivision === item.key
            )
          );
        }}
        onChangeSortBy={onChangeSortBy}
        onRefresh={() =>
          fetchAdminRegAssociationSeasons(regAssociation.pkRegAssociation)
        }
        rows={regAssociationDivisions.map((d) => ({
          key: d.pkRegAssociationDivision,
          actions: DIVISION_ACTIONS.filter(
            (f) => !f.isInviteOnly || (d.isInviteOnly && f.isInviteOnly)
          ),
          columns: [
            {
              key: 1,
              label: d.name,
              weight: 500,
              onClick: () => onOpenModal(DIVISION_ACTIONS_ENUM.edit, d),
              subLabel: `${
                d.regFormSubmissionCount - d.unassignedRegPersonCount
              }/${d.regFormSubmissionCount} Assigned`,
              onSubLabelClick: () => {
                setFilterState({
                  ...filterState,
                  pkRegAssociationDivision: d.pkRegAssociationDivision.toString()
                });
                navigate('/admin/league/teams');
              },
              icon: <Users size={30} />
            },
            {
              key: 2,
              label: d.isAllAges
                ? '(All Ages)'
                : `(${dayjs(new Date()).diff(
                    d.minDob,
                    'year'
                  )} years old and younger)`,
              sortValue: dayjs(new Date()).diff(d.minDob, 'year'),
              sortValueType: 'date',
              showOnMobile: true
            },
            {
              key: 3,
              label: REG_GENDER_TYPES.find(
                (t) => t.value === d.fkRegGenderType.toString()
              )?.label
            },
            { key: 4, label: d.roles.map((r) => r.name).join(', ') },
            { key: 5, label: d.hasOrderEmails ? 'Yes' : 'No' },
            {
              key: 6,
              label: d.waitlistEnabled ? 'Yes' : 'No',
              onClick: d.waitlistEnabled
                ? () => {
                    setFilterState({
                      ...filterState,
                      pkRegAssociationDivision: d.pkRegAssociationDivision.toString()
                    });
                    navigate('/admin/league/waitlist');
                  }
                : null
            },
            {
              key: 7,
              label: d.isInviteOnly ? 'Yes' : 'No',
              onClick: d.isInviteOnly
                ? () => {
                    setFilterState({
                      ...filterState,
                      pkRegAssociationDivision: d.pkRegAssociationDivision.toString()
                    });
                    navigate('/admin/league/invites');
                  }
                : null
            }
          ]
        }))}
        sortBy={sortValue}
        sortFilter={customFilterData}
        tableTitle="Divisions"
      />

      <LeagueDivisionModal
        createCopy={modalState.action === DIVISION_ACTIONS_ENUM.duplicate}
        isOpen={
          modalState.isOpen &&
          modalState.action !== DIVISION_ACTIONS_ENUM.specialRequests &&
          modalState.action !== DIVISION_ACTIONS_ENUM.sync &&
          modalState.action !== DIVISION_ACTIONS_ENUM.emailUsers &&
          modalState.action !== DIVISION_ACTIONS_ENUM.inviteUsers
        }
        onClose={onCloseModal}
        regAssociation={regAssociation}
        regAssociationDivision={modalState.item}
        regAssociationSeason={regAssociationSeason}
        showDelete={modalState.action === 'DELETE'}
      />
      <SpecialRequestModal
        isOpen={
          modalState.isOpen &&
          modalState.action === DIVISION_ACTIONS_ENUM.specialRequests
        }
        onClose={onCloseModal}
        regAssociationDivision={modalState.item}
      />

      <LeagueDivisionInviteModal
        isOpen={
          modalState.isOpen &&
          modalState.action === DIVISION_ACTIONS_ENUM.inviteUsers
        }
        onClose={onCloseModal}
        pkRegAssociation={regAssociation?.pkRegAssociation}
        pkRegAssociationSeason={filterState?.pkRegAssociationSeason}
        regAssociationDivisions={regAssociationDivisions}
        selectedRegAssociationDivision={modalState.item}
      />

      <SendEmailModal
        isOpen={
          modalState.isOpen &&
          modalState.action === DIVISION_ACTIONS_ENUM.emailUsers
        }
        onClose={onCloseModal}
        pkRegAssociation={regAssociation?.pkRegAssociation}
        pkRegAssociationDivision={modalState.item?.pkRegAssociationDivision}
      />
      <SyncDivisionTeamsModal
        isOpen={
          modalState.isOpen && modalState.action === DIVISION_ACTIONS_ENUM.sync
        }
        onClose={onCloseModal}
        regAssociationDivision={modalState.item}
      />
    </AppCard>
  );
};

LeagueDivisionsView.propTypes = {
  filterState: PropTypes.object,
  filters: PropTypes.array,
  isLoading: PropTypes.bool,
  modalAction: PropTypes.string,
  regAssociation: PropTypes.object,
  setFilterState: PropTypes.func
};

export default LeagueDivisionsView;
