// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.SoDecJZJekD9VXGoBRAv:hover, .SoDecJZJekD9VXGoBRAv[data-hovered] {
    font-weight: 500;
    background-color: #1A1B1E;
}

.rPZZLCjsjLz1jbmG24Ac:hover, .HUkwQ3eUCSKsxB3dGtu9[data-hovered] {
    font-weight: 500;
    background-color: #FFF;
}

.wkptMKovF3DMMPcSLksQ:hover { opacity: 0.7 }

.bppvzWg1MyGcDqexjWBn:hover { border-color:#1C7ED6 !important; }


.BPpNYG3BBPhMoH_kEKNn:hover {
    border: 1px solid #ced4da;
}

.uNd_P_xBfp3Hi9UeTpeL:hover { font-weight: 700; }


.alsXWypGk7Ae2WTv858X option { font-size: 18px }

.RcAQV6LOJGDWLP0YUS2M:hover {
    text-decoration: underline;
    cursor: pointer;
}

.yDiAPFplaQm8y2M_5FCe svg {
    width: 50px !important;
    height: 50px !important;
}

.KmH51Y5MC2WaFtDRtQMG:hover {
    font-weight: 500;
    background-color: #dee2e6 !important;
    cursor: pointer;
}

.KmH51Y5MC2WaFtDRtQMG:active {
    background-color: #c8cbcf !important;
}
`, "",{"version":3,"sources":["webpack://./src/styles/nestedStyles.module.css"],"names":[],"mappings":";AACA;IACI,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,sBAAsB;AAC1B;;AAEA,8BAA+B,aAAa;;AAE5C,8BAA2B,+BAA+B,EAAE;;;AAG5D;IACI,yBAAyB;AAC7B;;AAEA,8BAAyB,gBAAgB,EAAE;;;AAG3C,+BAAuB,gBAAgB;;AAEvC;IACI,0BAA0B;IAC1B,eAAe;AACnB;;AAEA;IACI,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,oCAAoC;IACpC,eAAe;AACnB;;AAEA;IACI,oCAAoC;AACxC","sourcesContent":["\r\n.hover-button-dark-mode:hover, .hover-button-dark-mode[data-hovered] {\r\n    font-weight: 500;\r\n    background-color: #1A1B1E;\r\n}\r\n\r\n.hover-button-dark-light:hover, .hover-button-light-mode[data-hovered] {\r\n    font-weight: 500;\r\n    background-color: #FFF;\r\n}\r\n\r\n.hover-clickable-image:hover { opacity: 0.7 }\r\n\r\n.hover-action-blue:hover { border-color:#1C7ED6 !important; }\r\n\r\n\r\n.hover-text-ListItem:hover {\r\n    border: 1px solid #ced4da;\r\n}\r\n\r\n.hover-text-bold:hover { font-weight: 700; }\r\n\r\n\r\n.selectNative option { font-size: 18px }\r\n\r\n.hover-text-action:hover {\r\n    text-decoration: underline;\r\n    cursor: pointer;\r\n}\r\n\r\n.mantine-Carousel-control-large svg {\r\n    width: 50px !important;\r\n    height: 50px !important;\r\n}\r\n\r\n.hover-action-card:hover {\r\n    font-weight: 500;\r\n    background-color: #dee2e6 !important;\r\n    cursor: pointer;\r\n}\r\n\r\n.hover-action-card:active {\r\n    background-color: #c8cbcf !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hover-button-dark-mode": `SoDecJZJekD9VXGoBRAv`,
	"hover-button-dark-light": `rPZZLCjsjLz1jbmG24Ac`,
	"hover-button-light-mode": `HUkwQ3eUCSKsxB3dGtu9`,
	"hover-clickable-image": `wkptMKovF3DMMPcSLksQ`,
	"hover-action-blue": `bppvzWg1MyGcDqexjWBn`,
	"hover-text-ListItem": `BPpNYG3BBPhMoH_kEKNn`,
	"hover-text-bold": `uNd_P_xBfp3Hi9UeTpeL`,
	"selectNative": `alsXWypGk7Ae2WTv858X`,
	"hover-text-action": `RcAQV6LOJGDWLP0YUS2M`,
	"mantine-Carousel-control-large": `yDiAPFplaQm8y2M_5FCe`,
	"hover-action-card": `KmH51Y5MC2WaFtDRtQMG`
};
export default ___CSS_LOADER_EXPORT___;
