import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Carousel } from '@mantine/carousel';
import { useLocation, useNavigate } from 'react-router-dom';
import { Divider } from '@mantine/core';
import AppStack from '../../common/AppStack';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import { Context as AuthContext } from '../../../providers/AuthProvider';
import MerchPackageShowcaseCard from './MerchPackageShowcaseCard';
import MerchPackageModal from './MerchPackageModal';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import classes from '../../../styles/nestedStyles.module.css';
import AppText from '../../common/AppText';

const MerchPackageShowcase = ({
  regAssociation,
  regAssociationOptions,
  isLoading,
  analyticCta,
  includeUrgencyWarning,
  extraPadding,
  hideNoMerch
}) => {
  const mqIndex = useMediaQueryIndex();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { state: authState } = useContext(AuthContext);
  const { state } = useContext(RegistrationContext);
  const [modalState, setModalState] = useState({ isOpen: false, item: null });
  const loading = state.merchPackages.loading || isLoading;
  const carouselEnabled =
    !loading && state.merchPackages.value.length > rs([1, 2, 3], mqIndex);

  return (
    (!hideNoMerch || state.merchPackages.value.length > 0) && (
      <>
        {includeUrgencyWarning && (
          <>
            <Divider hiddenFrom="xs" style={{ marginTop: 20 }} />

            <Divider
              label="DON'T FORGET YOUR TEAM MERCH"
              labelPosition="center"
              style={{ marginTop: 20, marginBottom: 20 }}
              styles={{
                label: {
                  fontSize: 26,
                  fontWeight: 700,
                  whiteSpace: 'pre-wrap',
                  textAlign: 'center'
                }
              }}
            />

            <Divider hiddenFrom="xs" style={{ marginBottom: 20 }} />
          </>
        )}

        {loading || state.merchPackages.value.length > 0 ? (
          <AppStack
            style={{
              gap: 5,
              padding: carouselEnabled
                ? extraPadding
                  ? rs(['0px 50px', '0px 65px', '0px 90px'], mqIndex)
                  : rs(['0px 40px', '0px 55px', '0px 70px'], mqIndex)
                : 0
            }}
          >
            <Carousel
              align="start"
              classNames={{control: classes['mantine-Carousel-control-large']}}
              controlSize={rs([40, 60, 80], mqIndex)}
              draggable={carouselEnabled}
              height={300}
              loop
              slideGap="md"
              slideSize={rs(['100%', '50%', '33.333333%'], mqIndex)}
              slidesToScroll={rs([1, 2, 3], mqIndex)}
              styles={{
                controls: {
                  left: rs([-75, -90, -105], mqIndex),
                  right: rs([-75, -90, -105], mqIndex)
                }
              }}
              withControls={carouselEnabled}
            >
              {loading ? (
                <>
                  <Carousel.Slide>
                    <MerchPackageShowcaseCard />
                  </Carousel.Slide>
                  <Carousel.Slide>
                    <MerchPackageShowcaseCard />
                  </Carousel.Slide>
                  <Carousel.Slide>
                    <MerchPackageShowcaseCard />
                  </Carousel.Slide>
                </>
              ) : (
                state.merchPackages.value
                  .sort((a, b) => a.price - b.price)
                  .map((p) => (
                    <Carousel.Slide key={p.pkMerchPackage}>
                      <MerchPackageShowcaseCard
                        merchPackage={p}
                        onClick={() =>
                          !authState.isAuthenticated
                            ? navigate(`/auth/login?redirect=${pathname}`)
                            : setModalState({ isOpen: true, item: p })
                        }
                      />
                    </Carousel.Slide>
                  ))
              )}
            </Carousel>

            <MerchPackageModal
              analyticCta={analyticCta}
              isOpen={modalState.isOpen}
              merchPackage={modalState.item}
              onClose={() => setModalState({ ...modalState, isOpen: false })}
              regAssociation={regAssociation}
              regAssociationOptions={regAssociationOptions}
            />
          </AppStack>
        ) : (
          <AppStack style={{ padding: 40 }}>
            <AppText
              style={{ textAlign: 'center', fontSize: 22, color: 'grey' }}
              weight={500}
            >
              No Merchandise Available. Please check back soon!
            </AppText>
          </AppStack>
        )}
      </>
    )
  );
};

MerchPackageShowcase.propTypes = {
  analyticCta: PropTypes.string,
  extraPadding: PropTypes.bool,
  hideNoMerch: PropTypes.bool,
  includeUrgencyWarning: PropTypes.bool,
  isLoading: PropTypes.bool,
  regAssociation: PropTypes.object,
  regAssociationOptions: PropTypes.array
};

export default MerchPackageShowcase;
