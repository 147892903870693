const REPORT_HEADER_ENUM = {
  // SUBMISSION
  FORM_NAME: 'form_name',
  TRANSACTION_TOTAL: 'transaction_total',
  TRANSACTION_COUNT: 'transaction_count',
  REGISTRATION_COUNT: 'registration_count',
  TOTAL_PAYMENTS: 'total_payments',
  TRANSFER_AMOUNT: 'transfer_amount',
  TRANSFER_COUNT: 'transfer_count',
  AMOUNT_SETTLED: 'amount_settled',
  AVERAGE_COST: 'average_cost',
  PLATFORM_FEE: 'platform_fees',
  NET_TOTAL: 'net_total',
  UNPAID_BALANCE: 'unpaid_balance',
  OVERPAID_BALANCE: 'overpaid_balance',
  REMAINING_BALANCE: 'remaining_balance',
  TOTAL_REFUNDED: 'total_refunded',
  REFUND_DATE: 'refund_date',
  CURRENCY: 'currency',
  PAYMENT_TYPE: 'payment_type',
  COUPON: 'coupon',
  FINANCIAL_ASSISTANCE_PROVIDER: 'financial_assistance_provider',
  FINANCIAL_ASSISTANCE_ID: 'financial_assistance_id',
  FINANCIAL_ASSISTANCE_AMOUNT: 'financial_assistance_amount',
  REGISTRATION_DATE: 'registration_date',
  REGISTRATION_STATUS: 'registration_status',
  MERCH_PURCHASED: 'merch_purchased',
  MERCH_PROFIT: 'merch_profit',
  PURCHASED_PRODUCTS: 'purchased_products',

  // PLAYER
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  ROLE: 'role',
  GENDER: 'gender',
  BIRTH_DATE: 'birth_date',
  EMAIL: 'email',
  PHONE: 'phone',
  ADDRESS: 'address',
  CITY: 'city',
  PROVINCE: 'province',
  POSTAL_CODE: 'postal_code',
  COUNTRY: 'country',
  REGISTRATION_AGE: 'registration_age',

  // PARENT
  CONTACT_NAME: 'contact_name',
  CONTACT_PHONE: 'contact_phone',
  CONTACT_EMAIL: 'contact_email',

  // SEASON
  SEASON_NAME: 'season_name',
  SEASON_END_DATE: 'season_end_date',

  // DIVISION
  DIVISION_NAME: 'division_name',
  MIN_DOB: 'min_dob',
  MAX_DOB: 'max_dob',

  // MERCH
  MERCH_PACKAGE: 'merch_package',
  WHOLESALE_COST: 'wholesale_cost',

  // TEAM
  TEAM_NAME: 'team_name',

  // ADMIN
  SPORTSHEADZ_SPLIT: 'sportsheadz_split',
  SPORTSHEADZ_AMOUNT_RECEIVED: 'sportsheadz_amount_received',
  SPORTSHEADZ_AMOUNT_REFUNDED: 'sportsheadz_amount_refunded',
  SPORTSHEADZ_NET_TOTAL: 'sportsheadz_net_total',
  CALL_TO_ACTION: 'call_to_action'
};

const REPORT_HEADER_LIST = [
  // ADMIN
  {
    value: REPORT_HEADER_ENUM.SPORTSHEADZ_SPLIT,
    label: 'SportsHeadz split',
    category: 'Admin',
    allowedViews: ['admin']
  },
  {
    value: REPORT_HEADER_ENUM.SPORTSHEADZ_AMOUNT_RECEIVED,
    label: 'SportsHeadz amount received',
    category: 'Admin',
    allowedViews: ['admin']
  },
  {
    value: REPORT_HEADER_ENUM.SPORTSHEADZ_AMOUNT_REFUNDED,
    label: 'SportsHeadz amount refunded',
    category: 'Admin',
    allowedViews: ['admin']
  },
  {
    value: REPORT_HEADER_ENUM.SPORTSHEADZ_NET_TOTAL,
    label: 'SportsHeadz net total',
    category: 'Admin',
    allowedViews: ['admin']
  },
  {
    value: REPORT_HEADER_ENUM.CALL_TO_ACTION,
    label: 'Call to action',
    category: 'Admin',
    allowedViews: ['admin']
  },

  // SUBMISSION
  {
    value: REPORT_HEADER_ENUM.FORM_NAME,
    label: 'Form name',
    category: 'Registration'
  },
  {
    value: REPORT_HEADER_ENUM.TRANSACTION_TOTAL,
    label: 'Transaction total',
    category: 'Registration'
  },
  {
    value: REPORT_HEADER_ENUM.AVERAGE_COST,
    label: 'Average cost',
    category: 'Registration'
  },
  {
    value: REPORT_HEADER_ENUM.TRANSACTION_COUNT,
    label: 'Transaction count',
    category: 'Registration'
  },
  {
    value: REPORT_HEADER_ENUM.TOTAL_PAYMENTS,
    label: 'Total payments',
    category: 'Registration'
  },
  {
    value: REPORT_HEADER_ENUM.TRANSFER_AMOUNT,
    label: 'Transfer amount',
    category: 'Registration'
  },
  {
    value: REPORT_HEADER_ENUM.AMOUNT_SETTLED,
    label: 'Amount settled',
    category: 'Registration'
  },
  {
    value: REPORT_HEADER_ENUM.PLATFORM_FEE,
    label: 'Platform fee',
    category: 'Registration'
  },
  {
    value: REPORT_HEADER_ENUM.NET_TOTAL,
    label: 'Net total',
    category: 'Registration'
  },
  {
    value: REPORT_HEADER_ENUM.UNPAID_BALANCE,
    label: 'Unpaid balance',
    category: 'Registration'
  },
  {
    value: REPORT_HEADER_ENUM.OVERPAID_BALANCE,
    label: 'Overpaid balance',
    category: 'Registration'
  },
  {
    value: REPORT_HEADER_ENUM.REMAINING_BALANCE,
    label: 'Remaining balance',
    category: 'Registration'
  },
  {
    value: REPORT_HEADER_ENUM.TOTAL_REFUNDED,
    label: 'Total refunded',
    category: 'Registration'
  },
  {
    value: REPORT_HEADER_ENUM.REFUND_DATE,
    label: 'Refund date',
    category: 'Registration'
  },
  {
    value: REPORT_HEADER_ENUM.CURRENCY,
    label: 'Currency',
    category: 'Registration'
  },
  {
    value: REPORT_HEADER_ENUM.PAYMENT_TYPE,
    label: 'Payment type',
    category: 'Registration'
  },
  {
    value: REPORT_HEADER_ENUM.COUPON,
    label: 'Coupon',
    category: 'Registration'
  },
  {
    value: REPORT_HEADER_ENUM.FINANCIAL_ASSISTANCE_PROVIDER,
    label: 'Financial assistance provider',
    category: 'Registration'
  },
  {
    value: REPORT_HEADER_ENUM.FINANCIAL_ASSISTANCE_ID,
    label: 'Financial assistance ID',
    category: 'Registration'
  },
  {
    value: REPORT_HEADER_ENUM.FINANCIAL_ASSISTANCE_AMOUNT,
    label: 'Financial assistance amount',
    category: 'Registration'
  },
  {
    value: REPORT_HEADER_ENUM.REGISTRATION_DATE,
    label: 'Registration date',
    category: 'Registration'
  },
  {
    value: REPORT_HEADER_ENUM.REGISTRATION_STATUS,
    label: 'Registration status',
    category: 'Registration'
  },
  {
    value: REPORT_HEADER_ENUM.MERCH_PURCHASED,
    label: 'Merch purchased',
    category: 'Registration'
  },
  {
    value: REPORT_HEADER_ENUM.MERCH_PROFIT,
    label: 'Merch profit',
    category: 'Registration'
  },
  {
    value: REPORT_HEADER_ENUM.PURCHASED_PRODUCTS,
    label: 'Purchased products',
    category: 'Registration'
  },

  // PLAYER
  {
    value: REPORT_HEADER_ENUM.FIRST_NAME,
    label: 'First name',
    category: 'Player'
  },
  {
    value: REPORT_HEADER_ENUM.LAST_NAME,
    label: 'Last name',
    category: 'Player'
  },
  {
    value: REPORT_HEADER_ENUM.ROLE,
    label: 'Role',
    category: 'Player'
  },
  {
    value: REPORT_HEADER_ENUM.GENDER,
    label: 'Gender',
    category: 'Player'
  },
  {
    value: REPORT_HEADER_ENUM.BIRTH_DATE,
    label: 'Birth date',
    category: 'Player'
  },
  {
    value: REPORT_HEADER_ENUM.EMAIL,
    label: 'Email',
    category: 'Player'
  },
  {
    value: REPORT_HEADER_ENUM.PHONE,
    label: 'Phone',
    category: 'Player'
  },
  {
    value: REPORT_HEADER_ENUM.ADDRESS,
    label: 'Address',
    category: 'Player'
  },
  {
    value: REPORT_HEADER_ENUM.CITY,
    label: 'City',
    category: 'Player'
  },
  {
    value: REPORT_HEADER_ENUM.PROVINCE,
    label: 'Province',
    category: 'Player'
  },
  {
    value: REPORT_HEADER_ENUM.POSTAL_CODE,
    label: 'Postal code',
    category: 'Player'
  },
  {
    value: REPORT_HEADER_ENUM.COUNTRY,
    label: 'Country',
    category: 'Player'
  },
  {
    value: REPORT_HEADER_ENUM.REGISTRATION_AGE,
    label: 'Registration age',
    category: 'Player'
  },

  // PARENT
  {
    value: REPORT_HEADER_ENUM.CONTACT_NAME,
    label: 'Contact name',
    category: 'Parent'
  },
  {
    value: REPORT_HEADER_ENUM.CONTACT_PHONE,
    label: 'Contact phone',
    category: 'Parent'
  },
  {
    value: REPORT_HEADER_ENUM.CONTACT_EMAIL,
    label: 'Contact email',
    category: 'Parent'
  },

  // SEASON
  {
    value: REPORT_HEADER_ENUM.SEASON_NAME,
    label: 'Season name',
    category: 'Season'
  },
  {
    value: REPORT_HEADER_ENUM.SEASON_END_DATE,
    label: 'Season end date',
    category: 'Season'
  },

  // DIVISION
  {
    value: REPORT_HEADER_ENUM.DIVISION_NAME,
    label: 'Division name',
    category: 'Division'
  },
  {
    value: REPORT_HEADER_ENUM.MIN_DOB,
    label: 'Min DOB',
    category: 'Division'
  },
  {
    value: REPORT_HEADER_ENUM.MAX_DOB,
    label: 'Max DOB',
    category: 'Division'
  },

  // MERCH
  {
    value: REPORT_HEADER_ENUM.MERCH_PACKAGE,
    label: 'Merch package',
    category: 'Merch'
  },
  {
    value: REPORT_HEADER_ENUM.WHOLESALE_COST,
    label: 'Wholesale cost',
    category: 'Merch'
  },

  // TEAM
  {
    value: REPORT_HEADER_ENUM.TEAM_NAME,
    label: 'Team name',
    category: 'Team'
  }
];

const REPORT_CATEGORY_ENUM = { SALES: '1' };

const REPORT_CATEGORY_LIST = [
  {
    label: 'Sales',
    value: REPORT_CATEGORY_ENUM.SALES
  }
];

const REPORT_TYPE_ENUM = {
  SALES: '1',
  CUSTOMERS: '2',
  ORDERS: '3'
};

const REPORT_TYPE_CONFIG = [
  {
    value: REPORT_TYPE_ENUM.SALES,
    label: 'Sales',
    chartable: true,
    headers: []
  }
];

const ECOM_REPORT_ENUM = {
  // OVERVIEW
  OVERVIEW: 'overview'
};

const DEFAULT_REPORT_CONFIGS = [];

export {
  REPORT_HEADER_ENUM,
  REPORT_HEADER_LIST,
  REPORT_CATEGORY_ENUM,
  REPORT_CATEGORY_LIST,
  REPORT_TYPE_ENUM,
  REPORT_TYPE_CONFIG,
  ECOM_REPORT_ENUM,
  DEFAULT_REPORT_CONFIGS
};
