import React from 'react';
import { PropTypes } from 'prop-types';
import { Avatar } from '@mantine/core';
import dayjs from 'dayjs';
import { formatUtcDate, getInitialsFromName } from '../../../helpers/format';
import ActionableListItem from '../../common/ActionableListItem';
import AppText from '../../common/AppText';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const ParticipantListItem = ({ participant, onAction }) => {
  const mqIndex = useMediaQueryIndex();

  return participant ? (
    <ActionableListItem
      description={`${participant.city}, ${participant.province}`}
      indicator={
        <Avatar
          radius="xl"
          style={{
            backgroundColor: '#ced4da',
            display: rs(['none', 'flex'], mqIndex)
          }}
        >
          <AppText style={{ color: '#333' }} weight={700}>
            {getInitialsFromName(
              `${participant.firstName} ${participant.lastName}`
            )}
          </AppText>
        </Avatar>
      }
      label={`${participant.firstName} ${participant.lastName}`}
      menuActions={
        onAction
          ? [
              {
                label: 'Edit',
                value: 'EDIT'
              },
              {
                label: 'Delete',
                value: 'DELETE'
              }
            ]
          : null
      }
      onAction={onAction}
      subDescription={participant.country}
      subLabel={dayjs(formatUtcDate(participant.dob)).format('MM/DD/YYYY')}
    />
  ) : (
    <ActionableListItem hideBadgeLoader isLoading showIndicator />
  );
};

ParticipantListItem.propTypes = {
  onAction: PropTypes.func,
  participant: PropTypes.object
};

export default ParticipantListItem;
