import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import SettingsView from '../components/content/settings/SettingsView';

const BrandingView = () => (
  <Routes>
    <Route element={<SettingsView />} path="/" />
    <Route element={<SettingsView />} path=":pkRegAssociation/*" />
    <Route element={<Navigate replace to="/admin/branding" />} path="*" />
  </Routes>
);

export default BrandingView;
