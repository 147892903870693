import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ParticipantForm from './ParticipantsForm';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppTitle from '../../common/AppTitle';

const ParticipantModal = ({
  isOpen,
  participant,
  showDelete,
  onClose,
  readOnly
}) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showOverrideConfirm, setShowOverrideConfirm] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setShowDeleteConfirmation(showDelete);
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      onClose={onClose}
      opened={isOpen}
      size={showDelete || showDeleteConfirmation ? 600 : 800}
      title={
        <AppTitle order={2}>
          {readOnly
            ? 'View Participant'
            : showDelete || showDeleteConfirmation
            ? 'Delete Participant'
            : showOverrideConfirm
            ? 'Update Participant'
            : participant
            ? 'Edit Participant'
            : 'Create Participant'}
        </AppTitle>
      }
    >
      <ParticipantForm
        hideEdit={showDelete}
        onCancel={onClose}
        participant={participant}
        readOnly={readOnly}
        setShowDelete={(isShown) => setShowDeleteConfirmation(isShown)}
        setShowOverride={(isShown) => setShowOverrideConfirm(isShown)}
        showDelete={showDeleteConfirmation}
      />
    </ResponsiveModal>
  );
};

ParticipantModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  participant: PropTypes.object,
  readOnly: PropTypes.bool,
  showDelete: PropTypes.bool
};

export default ParticipantModal;
