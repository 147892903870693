import React from 'react';
import { PropTypes } from 'prop-types';
import { Skeleton, Checkbox } from '@mantine/core';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';

const LeagueTeamsModalImportTeamListItem = ({
  team,
  isSelected,
  isDisabled,
  onSelect
}) =>
  team ? (
    <AppFlexbox
      onClick={onSelect}
      style={{
        padding: 10,
        flex: 1,
        cursor: 'pointer'
      }}
    >
      <Checkbox
        checked={isSelected}
        disabled={isDisabled}
        onChange={() => {}}
      />
      <AppText weight={500}>{team.FullName}</AppText>
    </AppFlexbox>
  ) : (
    <AppFlexbox
      style={{
        padding: 10,
        flex: 1
      }}
    >
      <Skeleton height={20} width={20} />
      <Skeleton height={8} width="20%" />
    </AppFlexbox>
  );

LeagueTeamsModalImportTeamListItem.propTypes = {
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
  team: PropTypes.object
};

export default LeagueTeamsModalImportTeamListItem;
