import React, { useEffect, useState } from 'react';
import {
  Button,
  Pagination,
  Select,
  Table,
  Text,
  TextInput,
  Tooltip
} from '@mantine/core';
import PropTypes from 'prop-types';
import { ArrowDown, ArrowUp, DeviceFloppy, Download } from 'tabler-icons-react';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppTitle from '../../common/AppTitle';
import AppCard from '../../common/AppCard';
import {
  REG_FORM_CONTROL_TYPE_ENUM,
  REG_SCOUT_REPORT_QUESTION_TYPE
} from '../../../config/constants';
import SelectCreatable from '../../common/SelectCreatable';
import { validateEvaluationQuestionRestrictions } from '../../../helpers/evaluationPlayerHelper';
import EvaluationSessionEntryUploadModal from './EvaluationSessionEntryUploadModal';

const MAX_PAGE_LENGTH = 25;

const DEFAULT_COLUMNS = [
  {
    value: 'name',
    label: 'Name',
    getSortValue: (e) => `${e.person.firstName}${e.person.lastName}`
  },
  {
    value: 'jerseyNumber',
    label: 'Jersey Number',
    attribute: true,
    sortType: 'number',
    getSortValue: (e) => e.jerseyNumber || -1
  },
  {
    value: 'jerseyColor',
    label: 'Jersey Color',
    attribute: true,
    getSortValue: (e) => e.jerseyColor || ''
  },
  {
    value: 'gender',
    label: 'Gender',
    attribute: true,
    getSortValue: (e) => e.person.gender || ''
  }
];

const SLIDER_VALUES = [
  { value: 0, label: '0' },
  { value: 0.5 },
  { value: 1, label: '1' },
  { value: 1.5 },
  { value: 2, label: '2' },
  { value: 2.5 },
  { value: 3, label: '3' },
  { value: 3.5 },
  { value: 4, label: '4' },
  { value: 4.5 },
  { value: 5, label: '5' }
];

const EvaluationSessionPlayerAnswerAssign = ({
  title,
  regScoutReportEvaluationSession,
  regScoutReportEvaluations,
  attributeQuestions,
  evaluationSessionEntry,
  onUpdateEvaluation,
  onUpdateEvaluationAnswer,
  onUpdateEvaluations,
  onSaveChanges,
  hasUnsavedChanges,
  isSaving,
  hideAttributes
}) => {
  const [formState, setFormState] = useState({
    selectedEvaluationKey: null,
    selectedInput: null,
    sortBy: DEFAULT_COLUMNS[0].value,
    isDescendingSort: false,
    pageIndex: 1,
    showCsvModal: false
  });

  const columns = [
    ...DEFAULT_COLUMNS.filter((f) => !hideAttributes || !f.attribute),
    ...attributeQuestions.map((q) => ({
      key: q.pkRegScoutReportQuestion,
      label: q.value,
      value: q.pkRegScoutReportQuestion.toString(),
      getSortValue: (e) =>
        e.regScoutReportQuestionAnswers.find(
          (f) => f.fkRegScoutReportQuestion === q.pkRegScoutReportQuestion
        )?.value || ''
    }))
  ];

  useEffect(() => {
    setFormState({
      selectedEvaluationKey: null,
      selectedInput: null,
      sortBy: DEFAULT_COLUMNS[0].value,
      isDescendingSort: false,
      pageIndex: 1,
      showCsvModal: false
    });
  }, []);

  const defaultSort = (a, b) =>
    `${a.person.firstName}${a.person.lastName}`.localeCompare(
      `${b.person.firstName}${b.person.lastName}`
    ) || new Date(a.person.createdAt) - new Date(b.person.createdAt);

  const sortRows = (a, b) => {
    const sortColumn =
      columns.find((v) => v.value === formState.sortBy) || columns[0];
    switch (sortColumn.sortType) {
      case 'number':
        return formState.isDescendingSort
          ? sortColumn.getSortValue(b) - sortColumn.getSortValue(a) ||
              defaultSort(b, a)
          : sortColumn.getSortValue(a) - sortColumn.getSortValue(b) ||
              defaultSort(a, b);
      case 'text':
      default:
        return formState.isDescendingSort
          ? sortColumn
              .getSortValue(b)
              .localeCompare(sortColumn.getSortValue(a)) || defaultSort(b, a)
          : sortColumn
              .getSortValue(a)
              .localeCompare(sortColumn.getSortValue(b)) || defaultSort(a, b);
    }
  };

  return (
    <AppStack style={{ margin: 20, marginTop: 10, gap: 5, flex: 1 }}>
      <AppFlexbox style={{ justifyContent: 'space-between' }}>
        <AppTitle order={3}>{title}</AppTitle>
        <AppFlexbox style={{ gap: 10 }}>
          <Button
            color="dark"
            disabled={isSaving}
            onClick={() =>
              setFormState({
                ...formState,
                showCsvModal: true
              })
            }
            rightSection={<Download />}
            size="compact-md"
            type="button"
            variant="outline"
          >
            Download/Upload CSV
          </Button>
          {hasUnsavedChanges && (
            <Button
              loading={isSaving}
              onClick={onSaveChanges}
              rightSection={<DeviceFloppy />}
              size="compact-md"
              type="button"
            >
              Save Changes
            </Button>
          )}
        </AppFlexbox>
      </AppFlexbox>

      <AppCard
        style={{
          flex: 1,
          overflow: 'auto',
          padding: 0,
          border: 'solid 1px #dee2e6'
        }}
      >
        <Table
          highlightOnHover
          style={{
            borderBottom: 'solid 1px #dee2e6',
            borderSpacing: 0,
            borderCollapse: 'collapse',
            tableLayout: 'fixed',
            width: '100%'
          }}
          withColumnBorders
        >
          <Table.Thead>
            <Table.Tr>
              {columns.map((c) => {
                const isOrderBySelected = formState.sortBy === c.value;

                return (
                  <Table.Th key={c.value}>
                    <AppFlexbox
                      onClick={() => {
                        setFormState({
                          ...formState,
                          sortBy: c.value,
                          isDescendingSort: isOrderBySelected
                            ? !formState.isDescendingSort
                            : false
                        });
                      }}
                      style={{
                        cursor: 'pointer',
                        gap: 10,
                        alignItems: 'center',
                        overflow: 'hidden'
                      }}
                    >
                      {c.label}
                      {isOrderBySelected &&
                        (formState.isDescendingSort ? (
                          <ArrowDown color="dodgerblue" size={20} />
                        ) : (
                          <ArrowUp color="dodgerblue" size={20} />
                        ))}
                    </AppFlexbox>
                  </Table.Th>
                );
              })}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {regScoutReportEvaluations
              .sort(sortRows)
              .slice(
                -MAX_PAGE_LENGTH + formState.pageIndex * MAX_PAGE_LENGTH,
                formState.pageIndex * MAX_PAGE_LENGTH
              )
              .map((evaluation) => {
                const isPlayerSelected =
                  formState.selectedEvaluationKey === evaluation.key;
                return (
                  <Table.Tr key={evaluation.key}>
                    <Table.Td>
                      {evaluation.person.firstName} {evaluation.person.lastName}
                    </Table.Td>
                    {!hideAttributes && (
                      <>
                        <Table.Td
                          onClick={() => {
                            if (
                              !isPlayerSelected ||
                              formState.selectedInput !== 'jerseyNumber'
                            ) {
                              setFormState({
                                ...formState,
                                selectedEvaluationKey: evaluation.key,
                                selectedInput: 'jerseyNumber'
                              });
                            }
                          }}
                          style={{ padding: 0 }}
                        >
                          {isPlayerSelected &&
                          formState.selectedInput === 'jerseyNumber' ? (
                            <TextInput
                              onChange={(e) => {
                                const { value } = e.currentTarget;
                                if (!value || Number(value)) {
                                  onUpdateEvaluation({
                                    ...evaluation,
                                    jerseyNumber: value
                                  });
                                }
                              }}
                              style={{ flex: 1 }}
                              value={evaluation.jerseyNumber || ''}
                            />
                          ) : (
                            <Text style={{ padding: '0px 10px' }}>
                              {evaluation.jerseyNumber}
                            </Text>
                          )}
                        </Table.Td>
                        <Table.Td
                          onClick={() => {
                            if (
                              !isPlayerSelected ||
                              formState.selectedInput !== 'jerseyColor'
                            ) {
                              setFormState({
                                ...formState,
                                selectedEvaluationKey: evaluation.key,
                                selectedInput: 'jerseyColor'
                              });
                            }
                          }}
                          style={{ padding: 0 }}
                        >
                          {isPlayerSelected &&
                          formState.selectedInput === 'jerseyColor' ? (
                            <SelectCreatable
                              defaultData={[
                                'Red',
                                'Orange',
                                'Yellow',
                                'Green',
                                'Blue',
                                'Pink',
                                'Purple'
                              ]}
                              onChange={(value) =>
                                onUpdateEvaluation({
                                  ...evaluation,
                                  jerseyColor: value
                                })
                              }
                              placeholder="Select a color"
                              value={evaluation.jerseyColor}
                            />
                          ) : (
                            <Text style={{ padding: '0px 10px' }}>
                              {evaluation.jerseyColor}
                            </Text>
                          )}
                        </Table.Td>
                        <Table.Td
                          onClick={() => {
                            if (
                              !isPlayerSelected ||
                              formState.selectedInput !== 'gender'
                            ) {
                              setFormState({
                                ...formState,
                                selectedEvaluationKey: evaluation.key,
                                selectedInput: 'gender'
                              });
                            }
                          }}
                          style={{
                            padding: 0,
                            cursor: 'not-allowed',
                            backgroundColor: '#f2f2f2'
                          }}
                        >
                          <Text style={{ padding: '0px 10px' }}>
                            {evaluation.person.gender}
                          </Text>
                        </Table.Td>
                      </>
                    )}

                    {attributeQuestions.map((question) => {
                      const hasAccessToQuestion = validateEvaluationQuestionRestrictions(
                        evaluation,
                        question
                      );

                      const isAttributeSelected =
                        isPlayerSelected &&
                        formState.selectedInput ===
                          question.pkRegScoutReportQuestion;

                      const isAttribute =
                        question.fkRegScoutReportQuestionType ===
                        REG_SCOUT_REPORT_QUESTION_TYPE.ATTRIBUTE;

                      const evaluationAnswer = evaluation.regScoutReportQuestionAnswers.find(
                        (a) =>
                          a.fkRegScoutReportQuestion ===
                            question.pkRegScoutReportQuestion &&
                          (isAttribute ||
                            a.fkRegScoutReportEvaluationSessionEntry ===
                              evaluationSessionEntry.pkRegScoutReportEvaluationSessionEntry)
                      );

                      const answerLabel = question.regScoutReportQuestionOptions.find(
                        (f) =>
                          f.pkRegScoutReportQuestionOption.toString() ===
                          evaluationAnswer?.value
                      )?.value;

                      return (
                        <Tooltip
                          key={question.pkRegScoutReportQuestion}
                          disabled={hasAccessToQuestion}
                          label="This question has restrictions applied that make it unavailable for the selected player."
                          multiline
                          w={220}
                          withArrow
                          withinPortal
                        >
                          <Table.Td
                            onClick={() => {
                              if (
                                hasAccessToQuestion &&
                                (!isPlayerSelected ||
                                  formState.selectedInput !==
                                    question.pkRegScoutReportQuestion)
                              ) {
                                setFormState({
                                  ...formState,
                                  selectedEvaluationKey: evaluation.key,
                                  selectedInput:
                                    question.pkRegScoutReportQuestion
                                });
                              }
                            }}
                            style={{
                              padding: 0,
                              cursor: hasAccessToQuestion
                                ? 'pointer'
                                : 'not-allowed',
                              backgroundColor: hasAccessToQuestion
                                ? 'none'
                                : '#f2f2f2'
                            }}
                          >
                            <AppFlexbox style={{ flex: 1 }}>
                              {hasAccessToQuestion && isAttributeSelected ? (
                                question.fkRegFormControlType ===
                                  REG_FORM_CONTROL_TYPE_ENUM.DROP_DOWN ||
                                question.fkRegFormControlType ===
                                  REG_FORM_CONTROL_TYPE_ENUM.BUTTON_GROUP ? (
                                  <Select
                                    clearable
                                    data={question.regScoutReportQuestionOptions
                                      .sort((a, b) => a.sort - b.sort)
                                      .map((op) => ({
                                        value: op.pkRegScoutReportQuestionOption.toString(),
                                        label: op.value
                                      }))}
                                    onChange={(value) =>
                                      onUpdateEvaluationAnswer(
                                        evaluation,
                                        question,
                                        question.regScoutReportQuestionOptions.find(
                                          (f) =>
                                            f.pkRegScoutReportQuestionOption.toString() ===
                                            value
                                        )?.value || null
                                      )
                                    }
                                    searchable
                                    style={{ flex: 1 }}
                                    value={
                                      question.regScoutReportQuestionOptions
                                        .find(
                                          (f) =>
                                            f.value.trim().toLowerCase() ===
                                            evaluationAnswer?.value
                                              ?.trim()
                                              .toLowerCase()
                                        )
                                        ?.pkRegScoutReportQuestionOption.toString() ||
                                      ''
                                    }
                                  />
                                ) : (
                                  <TextInput
                                    onChange={(e) => {
                                      if (
                                        !e.currentTarget.value ||
                                        SLIDER_VALUES.some((v) =>
                                          v.value
                                            .toString()
                                            .includes(e.currentTarget.value)
                                        )
                                      ) {
                                        onUpdateEvaluationAnswer(
                                          evaluation,
                                          question,
                                          e.currentTarget.value
                                        );
                                      }
                                    }}
                                    style={{ flex: 1 }}
                                    value={evaluationAnswer?.value ?? ''}
                                  />
                                )
                              ) : (
                                <Text style={{ padding: '0px 10px' }}>
                                  {hasAccessToQuestion
                                    ? answerLabel || evaluationAnswer?.value
                                    : ''}
                                </Text>
                              )}
                            </AppFlexbox>
                          </Table.Td>
                        </Tooltip>
                      );
                    })}
                  </Table.Tr>
                );
              })}
          </Table.Tbody>
        </Table>
        {regScoutReportEvaluations.length > MAX_PAGE_LENGTH && (
          <AppFlexbox style={{ justifyContent: 'center', padding: 20 }}>
            <Pagination
              color="blue"
              onChange={(pageIndex) => {
                setFormState({
                  ...formState,
                  pageIndex
                });
              }}
              total={Math.ceil(
                regScoutReportEvaluations.length / MAX_PAGE_LENGTH
              )}
              value={formState.pageIndex}
            />
          </AppFlexbox>
        )}
      </AppCard>

      <EvaluationSessionEntryUploadModal
        isOpen={formState.showCsvModal}
        onClose={() =>
          setFormState({
            ...formState,
            showCsvModal: false
          })
        }
        onSaveEvaluationEntry={() => {}}
        onUpdateEvaluations={onUpdateEvaluations}
        regScoutReportEvaluations={regScoutReportEvaluations}
        regScoutReportEvaluationSession={regScoutReportEvaluationSession}
        regScoutReportQuestions={attributeQuestions}
      />
    </AppStack>
  );
};

EvaluationSessionPlayerAnswerAssign.propTypes = {
  attributeQuestions: PropTypes.array,
  evaluationSessionEntry: PropTypes.object,
  hasUnsavedChanges: PropTypes.bool,
  hideAttributes: PropTypes.bool,
  isSaving: PropTypes.bool,
  onSaveChanges: PropTypes.func,
  onUpdateEvaluation: PropTypes.func,
  onUpdateEvaluationAnswer: PropTypes.func,
  onUpdateEvaluations: PropTypes.func,
  regScoutReportEvaluationSession: PropTypes.object,
  regScoutReportEvaluations: PropTypes.array,
  title: PropTypes.string
};

export default EvaluationSessionPlayerAnswerAssign;
