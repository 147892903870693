import React from 'react';
import PropTypes from 'prop-types';
import AppFlexbox from './AppFlexbox';

const AppStack = React.forwardRef(({ children, ...rest }, ref) => (
  <AppFlexbox
    ref={ref}
    {...rest}
    style={{ flexDirection: 'column', ...rest.style }}
  >
    {children}
  </AppFlexbox>
));

AppStack.propTypes = { children: PropTypes.node };

export default AppStack;
