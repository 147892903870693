import React from 'react';
import { PropTypes } from 'prop-types';
import { Badge, Divider } from '@mantine/core';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { Ticket } from 'tabler-icons-react';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import AppImage from '../../common/AppImage';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../../helpers/styles';

const OrderListItemProductSummary = ({
  lineItem,
  image,
  url,
  productName,
  extraDescriptions
}) => {
  const mqIndex = useMediaQueryIndex();
  const currency = lineItem.currency?.toUpperCase();

  return (
    <AppFlexbox
      key={lineItem.entityId}
      style={{
        flex: 1,
        flexWrap: 'nowrap',
        flexDirection: rs(['column', 'column', 'column', 'row'], mqIndex),
        gap: 0
      }}
    >
      <AppFlexbox style={{ flexWrap: 'nowrap', alignSelf: 'stretch', flex: 2 }}>
        <AppImage fit="contain" height={100} src={image} width={100} />
        <AppStack
          component={url ? Link : null}
          style={{
            flex: 1,
            gap: 0,
            textDecoration: 'none',
            color: '#000'
          }}
          to={url}
        >
          <AppText size="lg" weight={700}>
            {productName}
          </AppText>
          {extraDescriptions.map((e) =>
            typeof e === 'string' ? (
              <AppText
                key={e}
                style={{
                  fontSize: 14,
                  color: 'grey',
                  textOverflow: 'ellipsis',
                  lineHeight: '16px',
                  display: '-webkit-box',
                  WebkitLineClamp: '2',
                  lineClamp: '2',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  whiteSpace: 'pre-wrap'
                }}
              >
                {e}
              </AppText>
            ) : (
              <AppText
                key={e.label}
                style={{
                  fontSize: 14,
                  color: 'grey',
                  textOverflow: 'ellipsis',
                  lineHeight: '16px',
                  display: '-webkit-box',
                  WebkitLineClamp: '2',
                  lineClamp: '2',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  whiteSpace: 'pre-wrap'
                }}
                weight={e.weight}
              >
                {e.label}
              </AppText>
            )
          )}

          {lineItem.addOns.length > 0 && (
            <>
              <Divider
                label={
                  <AppText color="dark" weight={700}>
                    Add-ons
                  </AppText>
                }
                labelPosition="center"
              />

              <AppStack style={{ gap: 5 }}>
                {lineItem.addOns.map((addon) => (
                  <AppFlexbox
                    key={addon.productName}
                    style={{
                      gap: 5,
                      justifyContent: 'space-between'
                    }}
                  >
                    <AppText>{addon.productName}</AppText>
                    <AppFlexbox style={{ gap: 5 }}>
                      <AppText weight={700}>
                        <NumberFormat
                          decimalScale={2}
                          displayType="text"
                          fixedDecimalScale
                          prefix="$"
                          thousandSeparator
                          value={addon.unitAmountInCents / 100}
                        />
                      </AppText>
                      <AppText weight={700}>{currency}</AppText>
                    </AppFlexbox>
                  </AppFlexbox>
                ))}
              </AppStack>
            </>
          )}
        </AppStack>
      </AppFlexbox>

      <AppStack
        style={{
          flex: 1,
          alignSelf: 'start',
          placeItems: 'end',
          marginLeft: 'auto',
          gap: rs([0, 20], mqIndex),
          flexDirection: rs(
            ['row-reverse', 'row-reverse', 'row-reverse', 'column'],
            mqIndex
          ),
          justifyContent: rs(
            ['space-between', 'space-between', 'space-between', 'inital'],
            mqIndex
          ),
          width: rs(['100%', '100%', '100%', 'unset'], mqIndex)
        }}
      >
        <AppStack style={{ gap: 0, alignItems: 'end' }}>
          <AppFlexbox style={{ gap: 10, flexWrap: 'nowrap' }}>
            <AppText
              size="lg"
              style={{
                textDecoration:
                  lineItem.financialAssistance || lineItem.coupons.length > 0
                    ? 'line-through'
                    : 'normal'
              }}
              weight={700}
            >
              <NumberFormat
                decimalScale={2}
                displayType="text"
                fixedDecimalScale
                prefix="$"
                thousandSeparator
                value={lineItem.subtotalInCents / 100}
              />{' '}
              {currency}
            </AppText>
          </AppFlexbox>
          {lineItem.coupons.length > 0 && (
            <AppStack style={{ gap: 5 }}>
              {lineItem.coupons.map((c) => (
                <AppFlexbox
                  key={`${c.couponName}-${c.unitAmountInCents}`}
                  style={{ gap: 5, flexWrap: 'nowrap' }}
                >
                  <Badge
                    color="gray"
                    leftSection={<Ticket size={16} style={{ paddingTop: 5 }} />}
                    variant="outline"
                  >
                    {c.couponName}
                  </Badge>
                  <AppText size="md" weight={500}>
                    <NumberFormat
                      decimalScale={2}
                      displayType="text"
                      fixedDecimalScale
                      prefix="$"
                      thousandSeparator
                      value={
                        c.unitAmountInCents
                          ? (c.unitAmountInCents / 100) * -1
                          : ((lineItem.subtotalInCents *
                              (c.percentageAmount / 100)) /
                              100) *
                            -1
                      }
                    />{' '}
                    {currency}
                  </AppText>
                </AppFlexbox>
              ))}
            </AppStack>
          )}

          {lineItem.taxInCents > 0 && (
            <AppFlexbox style={{ gap: 5, flexWrap: 'nowrap' }}>
              <AppText size="md" weight={500}>
                +{' '}
                <NumberFormat
                  decimalScale={2}
                  displayType="text"
                  fixedDecimalScale
                  prefix="$"
                  thousandSeparator
                  value={lineItem.taxInCents / 100}
                />{' '}
                @{' '}
                <NumberFormat
                  displayType="text"
                  fixedDecimalScale
                  suffix="%"
                  thousandSeparator
                  value={lineItem.taxRate}
                />{' '}
                TAX
              </AppText>
            </AppFlexbox>
          )}

          {lineItem.customerPaysServiceFees && lineItem.serviceFeeInCents > 0 && (
            <AppFlexbox style={{ gap: 5, flexWrap: 'nowrap' }}>
              <AppText size="md" weight={500}>
                +{' '}
                <NumberFormat
                  decimalScale={2}
                  displayType="text"
                  fixedDecimalScale
                  prefix="$"
                  thousandSeparator
                  value={lineItem.serviceFeeInCents / 100}
                />{' '}
                Service fee
              </AppText>
            </AppFlexbox>
          )}

          {lineItem.financialAssistance && (
            <AppFlexbox style={{ gap: 5, flexWrap: 'nowrap' }}>
              <AppText size="md" weight={500}>
                Jumpstart{' '}
                <NumberFormat
                  decimalScale={2}
                  displayType="text"
                  fixedDecimalScale
                  prefix="$"
                  thousandSeparator
                  value={0.0}
                />{' '}
                {currency}
              </AppText>
            </AppFlexbox>
          )}
        </AppStack>
      </AppStack>
    </AppFlexbox>
  );
};

OrderListItemProductSummary.propTypes = {
  lineItem: PropTypes.object.isRequired,
  productName: PropTypes.string.isRequired,
  extraDescriptions: PropTypes.array,
  image: PropTypes.string,
  url: PropTypes.string
};

export default OrderListItemProductSummary;
