import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Divider,
  MultiSelect,
  NumberInput,
  Table,
  TextInput
} from '@mantine/core';
import PropTypes from 'prop-types';
import { ArrowDown, ArrowUp, Calendar, Filter } from 'tabler-icons-react';
import { useHover } from '@mantine/hooks';
import { DatePickerInput } from '@mantine/dates';
import dayjs from 'dayjs';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import ActionableIcon from '../../common/ActionableIcon';
import MenuContent from '../../common/MenuContent';
import AppStack from '../../common/AppStack';

const ReportTableHeader = ({
  header,
  onChangeSortBy,
  isSelected,
  isOrderByDescending,
  filters,
  onFilterChange
}) => {
  const selectEqualToRef = useRef(null);
  const selectNotEqualToRef = useRef(null);
  const { hovered, ref } = useHover();
  const [formState, setFormState] = useState({
    filterValueA: null,
    filterValueB: null,
    filterValueC: null,
    showFilter: false,
    preventMenuClose: false,
    openInputModal: null
  });
  const selectedFilter = filters.find((f) => f.key === header.key);
  const headerSelectData = header.selectOptions.reduce((r, c) => {
    const splitValues = c.split(',').map((v) => v.trim());
    return [...new Set([...r, ...splitValues])];
  }, []);

  useEffect(() => {
    setFormState({
      filterValueA: selectedFilter?.value ?? null,
      filterValueB: selectedFilter?.valueB ?? null,
      filterValueC: selectedFilter?.valueC ?? null,
      showFilter: false,
      preventMenuClose: false,
      openInputModal: null
    });
  }, [selectedFilter]);

  const getFilterLabels = () => {
    switch (header.dataType) {
      case 'date':
        return [
          {
            dataControl: 'value',
            value: `${header.value}${
              formState.filterValueA
                ? ` - After ${dayjs(formState.filterValueA).format(
                    'MM/DD/YYYY'
                  )}`
                : ''
            }${
              formState.filterValueB
                ? ` - Before ${dayjs(formState.filterValueB).format(
                    'MM/DD/YYYY'
                  )}`
                : ''
            }`
          }
        ];
      case 'number':
        if (formState.filterValueC) {
          return [`${header.value} - Equal to ${formState.filterValueC}`];
        }
        return [
          {
            dataControl: 'value',
            value: `${header.value}${
              formState.filterValueA
                ? `- Greater Than ${formState.filterValueA}`
                : ''
            }${
              formState.filterValueB
                ? ` - Less Than ${formState.filterValueB}`
                : ''
            }`
          }
        ];
      default: {
        const equalAssociationSplit =
          typeof formState.filterValueA === 'string'
            ? formState.filterValueA?.split(',') ?? []
            : formState.filterValueA ?? [];
        const notEqualAssociationSplit =
          typeof formState.filterValueB === 'string'
            ? formState.filterValueB?.split(',') ?? []
            : formState.filterValueB ?? [];

        return [
          ...(equalAssociationSplit.length > 0
            ? [
                {
                  dataControl: 'value',
                  value: `${header.value} - Equal to ${
                    equalAssociationSplit[0]
                  }${
                    equalAssociationSplit.length > 1
                      ? ` and ${equalAssociationSplit.length - 1} more`
                      : ''
                  }`
                }
              ]
            : []),
          ...(notEqualAssociationSplit.length > 0
            ? [
                {
                  dataControl: 'valueB',
                  value: `${header.value} - Not equal to ${
                    notEqualAssociationSplit[0]
                  }${
                    notEqualAssociationSplit.length > 1
                      ? ` and ${notEqualAssociationSplit.length - 1} more`
                      : ''
                  }`
                }
              ]
            : [])
        ];
      }
    }
  };

  const getFilterCondition = () => {
    switch (header.inputType) {
      case 'select':
        return (v) =>
          (!formState.filterValueA ||
            (v &&
              v
                .split(',')
                .some((s) => formState.filterValueA.includes(s.trim())))) &&
          (!formState.filterValueB ||
            !v ||
            v
              .split(',')
              .every((s) => !formState.filterValueB.includes(s.trim())));
      case 'date':
        return (v) =>
          (!formState.filterValueA || new Date(v) >= formState.filterValueA) &&
          (!formState.filterValueB || new Date(v) <= formState.filterValueB);
      case 'number':
        if (formState.filterValueC) {
          return (v) =>
            !formState.filterValueC || Number(v) === formState.filterValueC;
        }
        return (v) =>
          (!formState.filterValueA || v >= formState.filterValueA) &&
          (!formState.filterValueB || v <= formState.filterValueB);
      default:
        return (v) =>
          v.toLowerCase().includes(formState.filterValueA.toLowerCase());
    }
  };

  return (
    <Table.Th style={{ border: 'none' }}>
      <AppFlexbox
        ref={ref}
        style={{
          alignItems: 'center',
          minWidth: 200,
          justifyContent: 'space-between',
          position: 'relative'
        }}
      >
        <AppFlexbox
          onClick={onChangeSortBy}
          style={{
            cursor: onChangeSortBy ? 'pointer' : '',
            gap: 5,
            alignItems: 'center',
            flex: 1
          }}
        >
          {!header.hidden && (
            <AppText
              style={{
                fontSize: 14,
                color: header.color ?? '#000',
                fontWeight: header.fontWeight ?? '700'
              }}
            >
              {header.value}
            </AppText>
          )}
          {isSelected &&
            onChangeSortBy &&
            (isOrderByDescending ? (
              <ArrowDown color="dodgerblue" size={20} />
            ) : (
              <ArrowUp color="dodgerblue" size={20} />
            ))}
        </AppFlexbox>
        {onChangeSortBy && (formState.showFilter || hovered) && (
          <AppFlexbox
            style={{
              alignSelf: 'end',
              alignItems: 'center'
            }}
          >
            <MenuContent
              control={
                <AppStack>
                  <ActionableIcon size={20}>
                    <Filter size={20} />
                  </ActionableIcon>
                </AppStack>
              }
              onChange={(showFilter) => {
                if (!formState.preventMenuClose) {
                  setFormState({ ...formState, showFilter });
                }
              }}
              opened={formState.showFilter || formState.preventMenuClose}
              position="bottom"
              width={300}
            >
              <AppStack style={{ gap: 5, fontWeight: 'normal' }}>
                <AppText
                  style={{
                    padding: 5,
                    color: '#000',
                    fontSize: 18,
                    lineHeight: '18px'
                  }}
                  weight={500}
                >
                  Filter {header.value}
                </AppText>
                <Divider />
                <AppStack style={{ gap: 5, padding: 5 }}>
                  {header.dataType === 'date' ? (
                    <>
                      <DatePickerInput
                        clearable
                        label="After"
                        leftSection={<Calendar color="#000" size={16} />}
                        maxDate={formState.filterValueB}
                        onChange={(value) =>
                          setFormState({
                            ...formState,
                            filterValueA: value,
                            preventMenuClose: false,
                            openInputModal: null
                          })
                        }
                        onClick={() =>
                          setFormState({
                            ...formState,
                            preventMenuClose: true,
                            openInputModal: 'after'
                          })
                        }
                        placeholder="MM/DD/YYYY"
                        popoverProps={{
                          opened: formState.openInputModal === 'after',
                          onClose: () => {
                            setFormState({
                              ...formState,
                              preventMenuClose: false,
                              openInputModal: null
                            });
                          }
                        }}
                        value={formState.filterValueA}
                        valueFormat="MM/DD/YYYY"
                      />
                      <DatePickerInput
                        clearable
                        label="Before"
                        leftSection={<Calendar color="#000" size={16} />}
                        minDate={formState.filterValueA}
                        onChange={(value) =>
                          setFormState({
                            ...formState,
                            filterValueB: value,
                            preventMenuClose: false,
                            openInputModal: null
                          })
                        }
                        onClick={() =>
                          setFormState({
                            ...formState,
                            preventMenuClose: true,
                            openInputModal: 'before'
                          })
                        }
                        placeholder="MM/DD/YYYY"
                        popoverProps={{
                          opened: formState.openInputModal === 'before',
                          onClose: () => {
                            setFormState({
                              ...formState,
                              preventMenuClose: false,
                              openInputModal: null
                            });
                          }
                        }}
                        value={formState.filterValueB}
                        valueFormat="MM/DD/YYYY"
                      />
                    </>
                  ) : header.dataType === 'number' ? (
                    <>
                      <NumberInput
                        label="Greater Than"
                        onChange={(value) =>
                          setFormState({
                            ...formState,
                            filterValueA: value
                          })
                        }
                        value={formState.filterValueA ?? ''}
                      />
                      <NumberInput
                        label="Less Than"
                        onChange={(value) =>
                          setFormState({
                            ...formState,
                            filterValueB: value
                          })
                        }
                        value={formState.filterValueB ?? ''}
                      />
                      <Divider label="OR" style={{ marginTop: 5 }} />
                      <NumberInput
                        label="Equal To"
                        onChange={(value) =>
                          setFormState({
                            ...formState,
                            filterValueC: value
                          })
                        }
                        value={formState.filterValueC ?? ''}
                      />
                    </>
                  ) : header.inputType === 'select' ? (
                    <AppStack style={{ gap: 20 }}>
                      <MultiSelect
                        ref={selectEqualToRef}
                        data={headerSelectData
                          .sort((a, b) => a.localeCompare(b))
                          .map((v) => ({
                            label: v,
                            value: v
                          }))}
                        label="Equal To"
                        onBlur={() =>
                          setFormState({
                            ...formState,
                            preventMenuClose: false
                          })
                        }
                        onChange={(value) => {
                          selectEqualToRef.current?.blur();
                          setFormState({
                            ...formState,
                            filterValueA: value
                          });
                        }}
                        onFocus={() =>
                          setFormState({
                            ...formState,
                            preventMenuClose: true
                          })
                        }
                        placeholder={`Filter ${header.value}...`}
                        value={formState.filterValueA ?? []}
                      />

                      <MultiSelect
                        ref={selectNotEqualToRef}
                        data={headerSelectData
                          .sort((a, b) => a.localeCompare(b))
                          .map((v) => ({
                            label: v,
                            value: v
                          }))}
                        label="Not Equal To"
                        onBlur={() =>
                          setFormState({
                            ...formState,
                            preventMenuClose: false
                          })
                        }
                        onChange={(value) => {
                          selectNotEqualToRef.current?.blur();
                          setFormState({
                            ...formState,
                            filterValueB: value
                          });
                        }}
                        onFocus={() =>
                          setFormState({
                            ...formState,
                            preventMenuClose: true
                          })
                        }
                        placeholder={`Filter ${header.value}...`}
                        value={formState.filterValueB ?? []}
                      />
                    </AppStack>
                  ) : (
                    <TextInput
                      onChange={(e) =>
                        setFormState({
                          ...formState,
                          filterValueA: e.currentTarget.value
                        })
                      }
                      placeholder={`Search ${header.value}...`}
                      value={formState.filterValueA ?? ''}
                    />
                  )}
                </AppStack>
                <Divider />
                <AppFlexbox
                  style={{ justifyContent: 'end', padding: 5, gap: 5 }}
                >
                  <Button
                    disabled={!selectedFilter}
                    onClick={() =>
                      onFilterChange([
                        ...filters.filter((f) => f.key !== header.key)
                      ])
                    }
                    radius="xl"
                    size="compact-md"
                    variant="outline"
                  >
                    Clear
                  </Button>
                  <Button
                    disabled={
                      !formState.filterValueA &&
                      !formState.filterValueB &&
                      !formState.filterValueC
                    }
                    onClick={() =>
                      onFilterChange([
                        ...filters.filter((f) => f.key !== header.key),
                        {
                          key: header.key,
                          labels: getFilterLabels(),
                          value: formState.filterValueA,
                          valueB: formState.filterValueB,
                          valueC: formState.filterValueC,
                          condition: getFilterCondition()
                        }
                      ])
                    }
                    radius="xl"
                    size="compact-md"
                  >
                    Apply
                  </Button>
                </AppFlexbox>
              </AppStack>
            </MenuContent>
          </AppFlexbox>
        )}
      </AppFlexbox>
    </Table.Th>
  );
};

ReportTableHeader.propTypes = {
  filters: PropTypes.array,
  header: PropTypes.object,
  isOrderByDescending: PropTypes.bool,
  isSelected: PropTypes.bool,
  onChangeSortBy: PropTypes.func,
  onFilterChange: PropTypes.func
};

export default ReportTableHeader;
