import React from 'react';
import { PropTypes } from 'prop-types';
import { Alert, Button, Divider } from '@mantine/core';
import { AlertCircle } from 'tabler-icons-react';
import AppStack from './AppStack';
import AppFlexbox from './AppFlexbox';
import { useMediaQueryIndex } from '../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../helpers/styles';

const FormSection = ({
  onSubmit,
  onCancel,
  cancelTitle,
  cancelColor,
  submitTitle,
  submitColor,
  submitVariant,
  children,
  isLoading,
  isSubmitHidden,
  isSubmitDisabled,
  isCancelHidden,
  hideDivider,
  error,
  ...rest
}) => {
  const mqIndex = useMediaQueryIndex();

  return (
    <AppStack
      component="form"
      onSubmit={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onSubmit(e);
      }}
      {...rest}
      style={{ gap: 40, flex: 1, ...rest.style }}
    >
      {children}
      {(!isSubmitHidden || !isCancelHidden) && (
        <>
          {error && (
            <Alert
              aria-label="formError"
              color="red"
              icon={<AlertCircle size={16} />}
              radius="xs"
              role="dialog"
              variant="filled"
            >
              {error}
            </Alert>
          )}
          <AppStack
            style={{
              gap: rest?.style?.gap || 40,
              marginTop: isCancelHidden ? 'auto' : 'none'
            }}
          >
            {!hideDivider && <Divider />}
            <AppFlexbox style={{ justifyContent: 'center' }}>
              {!isCancelHidden && (
                <Button
                  color={cancelColor ?? 'dark'}
                  disabled={isLoading}
                  onClick={onCancel}
                  style={{
                    flex: 1,
                    maxWidth: 250,
                    display: rs(['none', 'none', 'inline-block'], mqIndex)
                  }}
                  type="button"
                >
                  {cancelTitle ?? 'Cancel'}
                </Button>
              )}
              {!isSubmitHidden && (
                <Button
                  color={submitColor ?? 'blue'}
                  disabled={isSubmitDisabled}
                  loading={isLoading}
                  style={{
                    flex: 1,
                    maxWidth: rs(['unset', 250], mqIndex)
                  }}
                  type="submit"
                  variant={submitVariant ?? 'filled'}
                >
                  {submitTitle ?? 'Submit'}
                </Button>
              )}
            </AppFlexbox>
          </AppStack>
          {!isCancelHidden && (
            <AppStack
              style={{
                gap: 40,
                flex: 1,
                justifyContent: 'flex-end',
                display: rs(['flex', 'flex', 'none'], mqIndex)
              }}
            >
              <Divider />
              <AppFlexbox style={{ justifyContent: 'center' }}>
                <Button
                  color={cancelColor ?? 'dark'}
                  disabled={isLoading}
                  onClick={onCancel}
                  style={{
                    flex: 1,
                    maxWidth: rs(['unset', 250], mqIndex)
                  }}
                  type="button"
                >
                  {cancelTitle ?? 'Cancel'}
                </Button>
              </AppFlexbox>
            </AppStack>
          )}
        </>
      )}
    </AppStack>
  );
};

FormSection.propTypes = {
  cancelColor: PropTypes.string,
  cancelTitle: PropTypes.string,
  children: PropTypes.any,
  error: PropTypes.string,
  hideDivider: PropTypes.bool,
  isCancelHidden: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSubmitDisabled: PropTypes.bool,
  isSubmitHidden: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  submitColor: PropTypes.string,
  submitTitle: PropTypes.string,
  submitVariant: PropTypes.string
};

export default FormSection;
