import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { Avatar } from '@mantine/core';
import dayjs from 'dayjs';
import {
  formatUtcDate,
  getInitialsFromName,
  toTitleCase
} from '../../../helpers/format';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import ActionableListItem from '../../common/ActionableListItem';
import AppText from '../../common/AppText';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import { getEvaluationRatingForSessions } from '../../../helpers/evaluationPlayerHelper';

const LeagueTeamPersonListItem = ({
  regPerson,
  roles,
  regAssociationDivisionTeam
}) => {
  const { state } = useContext(RegistrationAdminContext);
  const mqIndex = useMediaQueryIndex();

  const overallRating =
    regPerson && regAssociationDivisionTeam
      ? getEvaluationRatingForSessions(
          {
            pkRegAssociationDivision:
              regAssociationDivisionTeam.regAssociationDivision
                .pkRegAssociationDivision,
            pkRegPerson: regPerson.pkRegPerson,
            pkRegScoutReportPerson: null
          },
          state.regScoutReportEvaluationSessions.value
        ).overallRating || 0
      : 0;

  const renderTextInfo = ({ text, weight, color, wrapText }) => (
    <AppText
      style={{
        color,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineHeight: '20px',
        fontSize: 14,
        ...(wrapText
          ? {
              display: '-webkit-box',
              WebkitLineClamp: '2',
              lineClamp: '2',
              WebkitBoxOrient: 'vertical'
            }
          : {})
      }}
      weight={weight ?? 'normal'}
    >
      {text}
    </AppText>
  );

  return regPerson ? (
    <AppFlexbox style={{ alignItems: 'center', gap: 5, padding: 10, flex: 1 }}>
      <AppFlexbox style={{ alignItems: 'center', gap: 10, flex: 3 }}>
        <Avatar
          radius="xl"
          style={{
            backgroundColor: '#ced4da',
            display: rs(['none', 'flex'], mqIndex)
          }}
        >
          <AppText style={{ color: '#333' }}>
            {getInitialsFromName(
              `${regPerson.firstName} ${regPerson.lastName}`
            )}
          </AppText>
        </Avatar>
        <AppStack style={{ gap: 0, flex: 2 }}>
          {renderTextInfo({
            text: `${regPerson.firstName} ${regPerson.lastName}`,
            weight: 500
          })}
          {renderTextInfo({
            text: dayjs(formatUtcDate(regPerson.dob)).format('MM/DD/YYYY'),
            weight: 500,
            color: 'darkgrey',
            wrapText: true
          })}
        </AppStack>
      </AppFlexbox>
      <AppFlexbox style={{ alignItems: 'center', gap: 10, flex: 2 }}>
        <AppStack style={{ gap: 0, flex: 1 }}>
          {renderTextInfo({
            text: roles.length > 0 ? toTitleCase(roles[0].role) : 'Player',
            weight: 500
          })}
          {renderTextInfo({
            text: regPerson.gender,
            weight: 500,
            color: 'darkgrey',
            wrapText: true
          })}
        </AppStack>
        <AppStack style={{ gap: 0 }}>
          {renderTextInfo({
            text: overallRating ? Math.round(overallRating * 10) / 10 : '',
            weight: 500
          })}
        </AppStack>
      </AppFlexbox>
    </AppFlexbox>
  ) : (
    <ActionableListItem
      hideBadgeLoader
      hideButtonLoader
      isLoading
      showIndicator
    />
  );
};

LeagueTeamPersonListItem.propTypes = {
  regAssociationDivisionTeam: PropTypes.object,
  regPerson: PropTypes.object,
  roles: PropTypes.array
};

export default LeagueTeamPersonListItem;
