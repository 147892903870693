import React from 'react';
import PropTypes from 'prop-types';
import { RadarChart } from '@mantine/charts';
import NumberFormat from 'react-number-format';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import AppFlexbox from '../../common/AppFlexbox';

const PlayerStatsCard = ({
  ratedEvaluation,
  primaryColor,
  secondaryColor,
  primaryTextColor,
  secondaryTextColor,
  pageIndex,
  maxPerPage
}) => {
  const index = pageIndex ?? 1;
  const maxStatsPerPage = maxPerPage ?? 5;
  const {
    regScoutReportEvaluation,
    overallRating,
    answerRatings
  } = ratedEvaluation;

  return (
    <AppCard
      shadow="sm"
      style={{
        padding: 0,
        width: '100%',
        border: `solid 2px ${primaryColor}`,
        position: 'relative'
      }}
    >
      <AppStack
        style={{
          backgroundColor: secondaryColor,
          opacity: 0.15,
          width: '100%',
          height: '100%',
          padding: 10,
          position: 'absolute',
          zIndex: 1
        }}
      />
      <AppStack
        style={{
          flex: 1,
          zIndex: 2,
          gap: 0
        }}
      >
        <AppFlexbox style={{ padding: 10 }}>
          <AppText style={{ fontSize: 36, lineHeight: '36px' }} weight={500}>
            {Math.round(overallRating * 10) / 10}
          </AppText>
        </AppFlexbox>
        <RadarChart
          data={answerRatings.slice(
            -maxStatsPerPage + index * maxStatsPerPage,
            index * maxStatsPerPage
          )}
          dataKey="label"
          h={200}
          polarRadiusAxisProps={{ domain: [1, 5] }}
          radarChartProps={{}}
          series={[
            {
              name: 'value',
              strokeColor: primaryColor,
              color: primaryColor,
              opacity: 0.2
            }
          ]}
          ticks={[1, 2, 3, 4, 5]}
          width={300}
          withPolarAngleAxis
          withPolarGrid
          withPolarRadiusAxis
        />

        <AppFlexbox
          style={{
            padding: '5px 10px',
            justifyContent: 'center',
            textAlign: 'center',
            backgroundColor: primaryColor,
            color: primaryTextColor
          }}
        >
          <AppText style={{ fontSize: 24 }} weight={500}>
            {regScoutReportEvaluation.person.firstName}{' '}
            {regScoutReportEvaluation.person.lastName}
          </AppText>
        </AppFlexbox>

        <AppFlexbox style={{ padding: 10, justifyContent: 'center' }}>
          {answerRatings
            .slice(
              -maxStatsPerPage + index * maxStatsPerPage,
              index * maxStatsPerPage
            )
            .map((r) => (
              <AppStack
                key={r.label}
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  gap: 0
                }}
              >
                <AppText
                  style={{ fontSize: 20, lineHeight: '24px' }}
                  weight={500}
                >
                  {r.abbreviation}
                </AppText>

                <AppText
                  style={{ fontSize: 20, lineHeight: '24px' }}
                  weight={500}
                >
                  <NumberFormat
                    decimalScale={1}
                    displayType="text"
                    fixedDecimalScale
                    thousandSeparator
                    value={r.value}
                  />
                </AppText>
              </AppStack>
            ))}
        </AppFlexbox>
      </AppStack>
    </AppCard>
  );
};

PlayerStatsCard.propTypes = {
  maxPerPage: PropTypes.number,
  pageIndex: PropTypes.number,
  primaryColor: PropTypes.string,
  primaryTextColor: PropTypes.string,
  ratedEvaluation: PropTypes.object,
  secondaryColor: PropTypes.string,
  secondaryTextColor: PropTypes.string
};

export default PlayerStatsCard;
