import React, { useContext, useEffect, useRef, useState } from 'react';
import { Divider } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import { triggerNotification } from '../../../helpers/notificationHelper';
import InviteCard from '../invites/InviteCard';
import InviteListItem from '../invites/InviteListItem';
import RegistrationSignupListItem from '../associations/RegistrationSignupListItem';

const CheckoutInvite = () => {
  const { search } = useLocation();
  const pkRegAssociationDivisionFormInvite = new URLSearchParams(search).get(
    'invite'
  );
  const navigate = useNavigate();
  const {
    state,
    fetchRegAssociationDivisionFormInvites,
    addProductsToCart,
    expireRegAssociationDivisionFormInvite
  } = useContext(RegistrationContext);
  const hasFetchedInvites = useRef(false);
  const hasInitializedInvites = useRef(false);
  const [formState, setFormState] = useState({
    pkRegAssociationDivisionFormInvite: '',
    showConfirmCancel: false,
    isLoading: false
  });
  const selectedDivisionFormInvite = state.regAssociationDivisionFormInvites.value.find(
    (i) =>
      i.pkRegAssociationDivisionFormInvite.toString() ===
      formState.pkRegAssociationDivisionFormInvite
  );

  const inviteOptions = state.regAssociationDivisionFormInvites.value
    .filter((i) => !i.deleted && !i.accepted)
    .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
    .map((i) => ({
      label: `${i.regAssociationDivisionForm.regAssociationDivision.name} - ${i.regFormSubmission.regPerson.firstName} ${i.regFormSubmission.regPerson.lastName}`,
      value: i.pkRegAssociationDivisionFormInvite.toString()
    }));

  const isLoading =
    !hasFetchedInvites.current ||
    !hasInitializedInvites.current ||
    state.regAssociationDivisionFormInvites.loading;

  const invite = selectedDivisionFormInvite
    ? {
        pkInvite: selectedDivisionFormInvite.pkRegAssociationDivisionFormInvite,
        label: `${selectedDivisionFormInvite.regFormSubmission.regPerson.firstName} ${selectedDivisionFormInvite.regFormSubmission.regPerson.lastName}`,
        subLabel: dayjs(
          selectedDivisionFormInvite.regFormSubmission.regPerson.dob
        ).format('MM/DD/YYYY'),
        description:
          selectedDivisionFormInvite.regAssociationDivisionForm
            .regAssociationDivision.name,
        subDescription:
          selectedDivisionFormInvite.regAssociationDivisionForm.regForm.name,
        inviteCode: selectedDivisionFormInvite.inviteCode,
        accepted: selectedDivisionFormInvite.accepted,
        deleted: selectedDivisionFormInvite.deleted,
        expiresAt: selectedDivisionFormInvite.expiresAt
      }
    : {};

  useEffect(() => {
    fetchRegAssociationDivisionFormInvites();
  }, []);

  useEffect(() => {
    if (state.regAssociationDivisionFormInvites.loading) {
      hasFetchedInvites.current = true;
    }
    else if (hasFetchedInvites.current) {
      if (inviteOptions.length === 0) {
        navigate('/checkout/cart');
      }

      const urlSelectedInvite = inviteOptions.find(
        (i) => i.value === pkRegAssociationDivisionFormInvite
      );
      setFormState({
        pkRegAssociationDivisionFormInvite:
          urlSelectedInvite?.value ?? inviteOptions[0]?.value,
        showConfirmCancel: false,
        isLoading: false
      });
      hasInitializedInvites.current = true;
    }
  }, [state.regAssociationDivisionFormInvites.loading]);

  return (
    <InviteCard
      expiresAt={selectedDivisionFormInvite?.expiresAt}
      isDeleted={selectedDivisionFormInvite?.deleted}
      isLoading={isLoading}
      isSubmitting={formState.isLoading}
      onAccept={() => {
        setFormState({
          ...formState,
          isLoading: true
        });
        addProductsToCart(
          {
            regCartProducts: [
              {
                fkRegAssociationDivisionForm:
                  selectedDivisionFormInvite.regAssociationDivisionForm
                    .pkRegAssociationDivisionForm,
                fkRegPerson:
                  selectedDivisionFormInvite.regFormSubmission.regPerson
                    .pkRegPerson
              }
            ]
          },
          () => {
            triggerNotification('Item added to cart', 'Success', 'green');
            navigate('/checkout');
          },
          (message) => {
            triggerNotification(message);
            setFormState({
              ...formState,
              isLoading: false
            });
          }
        );
      }}
      onDecline={() => {
        setFormState({
          ...formState,
          isLoading: true
        });
        expireRegAssociationDivisionFormInvite(
          selectedDivisionFormInvite.pkRegAssociationDivisionFormInvite,
          () => {
            triggerNotification(
              'Invite successfully denied',
              'Success',
              'green'
            );
            navigate('/');
          },
          (message) => {
            triggerNotification(message);
            setFormState({
              ...formState,
              isLoading: false
            });
          }
        );
      }}
    >
      {selectedDivisionFormInvite && (
        <>
          <InviteListItem label={invite.label} {...invite} />
          <Divider />
          <RegistrationSignupListItem
            hideButton
            regAssociationDivisionForm={
              selectedDivisionFormInvite.regAssociationDivisionForm
            }
          />
        </>
      )}
    </InviteCard>
  );
};

export default CheckoutInvite;
