import {
  Basket,
  Cash,
  Checklist,
  FileAnalytics,
  FileText,
  Home,
  Mail,
  Notebook,
  Notes,
  Ticket,
  Tournament,
  Users,
  World
} from 'tabler-icons-react';
import { REG_PERMISSION_ENUM } from '../../../config/constants';

export const links = {
  public: [
    {
      to: '/',
      title: 'Home',
      icon: Home,
      isSelected: (pathname) => pathname === '/'
    },
    {
      to: '/leagues',
      title: 'Leagues & Clubs',
      icon: Notebook,
      isSelected: (pathname) =>
        pathname === '/leagues' ||
        (pathname.startsWith('/association') && !pathname.includes('/branding'))
    }
  ],
  profile: [
    {
      to: '/orders',
      title: 'My Orders',
      icon: Basket,
      isSelected: (pathname) => pathname === '/orders'
    },
    {
      to: '/waitlist',
      title: 'My Waitlist',
      icon: Notes,
      isSelected: (pathname) => pathname === '/waitlist'
    },
    {
      to: '/invites',
      title: 'My Invites',
      icon: Mail,
      isSelected: (pathname) => pathname === '/invites'
    },
    {
      to: '/participants',
      title: 'Participants',
      icon: Users,
      isSelected: (pathname) => pathname === '/participants'
    },
    {
      to: '/communication',
      title: 'Communication',
      icon: Mail,
      isSelected: (pathname) => pathname === '/communication'
    }
  ],
  admin: [
    {
      to: '/admin/league/seasons',
      title: 'My League',
      icon: Tournament,
      permissions: [REG_PERMISSION_ENUM.MY_LEAGUE],
      isSelected: (pathname) => pathname.startsWith('/admin/league')
    },
    {
      to: '/admin/coupons/standard',
      title: 'Coupons',
      icon: Ticket,
      permissions: [REG_PERMISSION_ENUM.COUPONS],
      isSelected: (pathname) => pathname.startsWith('/admin/coupons')
    },
    {
      to: '/admin/forms',
      title: 'Forms',
      icon: FileText,
      permissions: [REG_PERMISSION_ENUM.FORMS],
      isSelected: (pathname) => pathname.startsWith('/admin/forms')
    },
    {
      to: '/admin/financials/registrations',
      title: 'Form Submissions',
      icon: Cash,
      permissions: [REG_PERMISSION_ENUM.FINANCIALS],
      isSelected: (pathname) => pathname.startsWith('/admin/financials')
    },
    {
      to: '/admin/analytics',
      title: 'Analytics',
      icon: FileAnalytics,
      permissions: [REG_PERMISSION_ENUM.REPORTS],
      isSelected: (pathname) => pathname.startsWith('/admin/analytics')
    },
    {
      to: '/admin/reports/templates',
      title: 'Reports',
      icon: FileAnalytics,
      permissions: [REG_PERMISSION_ENUM.REPORTS],
      isSelected: (pathname) => pathname.startsWith('/admin/reports')
    },
    {
      to: '/admin/evaluations/sessions',
      title: 'Scouting & Evaluation',
      evaluatorAccess: true,
      icon: Checklist,
      permissions: [REG_PERMISSION_ENUM.EVALUATIONS],
      isSelected: (pathname) => pathname.startsWith('/admin/evaluations')
    },
    {
      to: '/admin/branding',
      title: 'Branding',
      icon: World,
      permissions: [REG_PERMISSION_ENUM.BRANDING],
      isSelected: (pathname) => pathname.includes('/admin/branding')
    }
  ]
};
