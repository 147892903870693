import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Papa from 'papaparse';
import { Alert, Button, List, Stack, TextInput } from '@mantine/core';
import { Download } from 'tabler-icons-react';
import ResponsiveModal from '../../common/ResponsiveModal';
import FormSection from '../../common/FormSection';
import ConfirmModal from '../../common/ConfirmModal';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import FileUploader from '../../common/FileUploader';
import { triggerNotification } from '../../../helpers/notificationHelper';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';

const playerAttributes = [
  { value: 'First Name', dataValue: 'firstName', required: true },
  { value: 'Last Name', dataValue: 'lastName', required: true },
  { value: 'Email', dataValue: 'email' },
  { value: 'Phone Number', dataValue: 'phone' },
  { value: 'Role', dataValue: 'Role' },
  {
    value: 'Gender',
    dataValue: 'gender',
    options: ['Male', 'Female', 'Not Specified']
  }
];

const EvaluationPlayerUploadModal = ({
  isOpen,
  onClose,
  pkRegAssociationDivision
}) => {
  const { createRegScoutReportPersons } = useContext(RegistrationAdminContext);
  const [formState, setFormState] = useState({
    file: null,
    customPlayers: [],
    missingHeaders: [],
    showConfirm: false,
    showDownload: false,
    downloadAllQuestions: false,
    isLoading: false
  });

  useEffect(() => {
    if (isOpen) {
      setFormState({
        file: null,
        customPlayers: [],
        missingHeaders: [],
        showConfirm: false,
        showDownload: false,
        downloadAllQuestions: false,
        isLoading: false
      });
    }
  }, [isOpen]);

  const onFinishDownload = () => {
    if (formState.showDownload) {
      setFormState({ ...formState, showDownload: false });
    }
    else {
      onClose();
    }
  };

  const onDownload = () => {
    const csv = Papa.unparse({ fields: playerAttributes.map((a) => a.value) });
    const blob = new Blob([csv]);
    const csvURL = URL.createObjectURL(blob, { type: 'text/plain' });
    const link = document.createElement('a');
    link.setAttribute('href', csvURL);
    link.setAttribute(
      'download',
      `Evaluation Players Template-${dayjs(new Date()).format(
        'MM/DD/YYYY_h:mma'
      )}.csv`
    );
    document.body.appendChild(link);
    link.click();
    onFinishDownload();
  };

  const onUploadCsv = (file) => {
    setFormState({ ...formState, isLoading: true });

    Papa.parse(file, {
      complete: (results) => {
        const headers = playerAttributes.map((a) => ({
          ...a,
          columnIndex: results.data[0].findIndex(
            (h) =>
              h.replace(' ', '').toLowerCase() ===
              a.value.replace(' ', '').toLowerCase()
          )
        }));
        const foundHeaders = headers.filter((h) => h.columnIndex !== -1);
        const missingHeaders = headers.filter((h) => h.columnIndex === -1);
        const missingRequiredHeader = missingHeaders.find((h) => h.required);

        if (missingRequiredHeader) {
          triggerNotification(
            `The uploaded file is missing the required column: ${missingRequiredHeader.value}`
          );
        }
        else if (foundHeaders.length === 0) {
          triggerNotification(
            'The uploaded file does not contain any valid columns.'
          );
        }
        else {
          const customPlayers = [];
          results.data.splice(1).forEach((row) => {
            const player = {};
            foundHeaders.forEach((h) => {
              if (h.options) {
                const foundOptions = h.options.find(
                  (f) => f.toLowerCase() === row[h.columnIndex].toLowerCase()
                );
                player[h.dataValue] = foundOptions ?? null;
              }
              else {
                player[h.dataValue] = row[h.columnIndex];
              }

              if (h.required && !player[h.dataValue]) {
                triggerNotification(
                  `The uploaded file is missing the required player information for column: ${h.value}`
                );
              }
            });
            customPlayers.push(player);
          });
          setFormState({
            ...formState,
            file,
            customPlayers,
            missingHeaders,
            isLoading: false
          });
        }
      }
    });
  };

  const onConfirm = () => {
    setFormState({
      ...formState,
      isLoading: true
    });
    createRegScoutReportPersons(
      formState.customPlayers.map((c) => ({
        ...c,
        fkRegAssociationDivision: pkRegAssociationDivision
      })),
      onClose,
      (e) => {
        triggerNotification(e);
        setFormState({
          ...formState,
          isLoading: false
        });
      }
    );
  };

  return (
    <>
      <ResponsiveModal isOpen={isOpen} onClose={onClose} title="Upload Players">
        <FormSection
          isLoading={formState.isLoading}
          isSubmitDisabled={!formState.file}
          onCancel={onClose}
          onSubmit={() =>
            setFormState({
              ...formState,
              showConfirm: true
            })
          }
          submitTitle="Upload"
        >
          <AppStack style={{ gap: 20 }}>
            <Alert color="blue" variant="outline">
              <AppStack style={{ gap: 10 }}>
                <AppText style={{ fontSize: 14 }}>
                  Upload a CSV file with the following columns to import players
                  for evaluation;{' '}
                  <b>
                    First Name, Last Name, Email, Phone Number, Role, Gender
                  </b>
                </AppText>

                <AppStack
                  style={{ alignItems: 'center', textAlign: 'center', gap: 5 }}
                >
                  <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                    Download the template to get started.
                  </AppText>
                  <Button
                    color="dark"
                    onClick={() =>
                      setFormState({
                        ...formState,
                        showDownload: true
                      })
                    }
                    rightSection={<Download />}
                    variant="outline"
                  >
                    Download Template
                  </Button>
                </AppStack>
              </AppStack>
            </Alert>

            <FileUploader
              accept=".csv"
              height={300}
              onError={triggerNotification}
              onUpload={onUploadCsv}
              validFileTypes={['text/csv']}
            />

            <TextInput
              disabled
              label="File"
              onChange={() => {}}
              style={{ flex: 1 }}
              value={formState.file?.name || ''}
            />
          </AppStack>
        </FormSection>
      </ResponsiveModal>

      <ConfirmModal
        confirmActionColor="blue"
        isLoading={formState.isLoading}
        isOpen={isOpen && formState.showConfirm}
        message={`Are you sure you want to import ${formState.customPlayers.length} player(s)?`}
        onCancel={() => {
          setFormState({ ...formState, showConfirm: false });
        }}
        onConfirm={onConfirm}
        title="Confirm Players"
      >
        {formState.missingHeaders.length > 0 && (
          <Alert
            color="yellow"
            style={{ marginTop: 20 }}
            title="Missing Columns"
          >
            <Stack style={{ gap: 5 }}>
              <AppText>The following column(s) could not be found;</AppText>
              <List withPadding>
                {formState.missingHeaders.map((h) => (
                  <List.Item key={h.value}>
                    <AppText color="grey">{h.value}</AppText>
                  </List.Item>
                ))}
              </List>
            </Stack>
          </Alert>
        )}
      </ConfirmModal>

      <ConfirmModal
        confirmActionColor="blue"
        confirmActionText="Download"
        isOpen={formState.showDownload}
        message="Are you sure you want to download the template for importing players?"
        onCancel={onFinishDownload}
        onConfirm={onDownload}
        title="Download Template"
      />
    </>
  );
};

EvaluationPlayerUploadModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  pkRegAssociationDivision: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

export default EvaluationPlayerUploadModal;
