import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowLeft,
  ArrowRight,
  ChevronDown,
  DeviceFloppy,
  Edit,
  Plus,
  Settings,
  Upload,
  User
} from 'tabler-icons-react';
import { Button, Divider, Loader, Menu, Skeleton, Tabs } from '@mantine/core';
import {
  useMediaQueryIndex,
  useModalState,
  useSortByFilter
} from '../../../helpers/hooks';
import AppCard from '../../common/AppCard';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import ActionableIcon from '../../common/ActionableIcon';
import AppText from '../../common/AppText';
import EvaluationSessionPlayerSelect from './EvaluationSessionPlayerSelect';
import {
  REG_PERMISSION_ENUM,
  REG_SCOUT_REPORT_QUESTION_TYPE
} from '../../../config/constants';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { Context as AuthContext } from '../../../providers/AuthProvider';
import EvaluationSessionPlayerAnswerAssign from './EvaluationSessionPlayerAnswerAssign';
import { usePrompt } from '../../../helpers/prompt';
import { useEvaluationSessionState } from '../../../helpers/evaluationHelper';
import EvaluationSessionAssignModal from './EvaluationSessionAssignModal';
import { triggerNotification } from '../../../helpers/notificationHelper';
import MenuContent from '../../common/MenuContent';
import ConfirmModal from '../../common/ConfirmModal';
import EvaluationSessionEvaluatorSelectModal from './EvaluationSessionEvaluatorSelectModal';
import EvaluationSessionSettingsModal from './EvaluationSessionSettingsModal';
import EvaluationSessionSettings from './EvaluationSessionSettings';
import EvaluationSessionAttributesModal from './EvaluationSessionAttributesModal';
import EvaluationSessionStatisticsView from './EvaluationSessionStatisticsView';
import EvaluationSessionTeamSelect from './EvaluationSessionTeamSelect';
import EvaluationSessionEntryUploadModal from './EvaluationSessionEntryUploadModal';
import EvaluationPlayerUploadModal from './EvaluationPlayerUploadModal';

const EVALUATOR_MENU_ACTIONS_ENUM = {
  view: 'VIEW',
  upload: 'UPLOAD',
  uploadPlayers: 'UPLOAD-PLAYERS',
  download: 'DOWNLOAD',
  clearAnswers: 'CLEAR_ANSWERS'
};

const VIEW_TABS_ENUM = {
  teams: 'TEAMS',
  divisionPlayers: 'DIVISION_PLAYERS',
  customPlayers: 'CUSTOM_PLAYERS',
  stats: 'STATS',
  attributes: 'ATTRIBUTES'
};

const VIEW_TABS = [
  {
    label: 'Teams',
    value: VIEW_TABS_ENUM.teams,
    fontSize: '16px',
    fontWeight: 500
  },
  {
    label: 'Division Players',
    value: VIEW_TABS_ENUM.divisionPlayers,
    fontSize: '16px',
    fontWeight: 500
  },
  {
    label: 'Custom Players',
    value: VIEW_TABS_ENUM.customPlayers,
    fontSize: '16px',
    fontWeight: 500,
    tabActions: [
      {
        label: 'Upload',
        value: EVALUATOR_MENU_ACTIONS_ENUM.uploadPlayers,
        color: 'dark',
        variant: 'outline',
        icon: <Upload />
      },
      {
        label: 'Create Player',
        value: 'CREATE',
        icon: <Plus />
      }
    ]
  },
  {
    label: 'Stats',
    value: VIEW_TABS_ENUM.stats,
    fontSize: '16px',
    fontWeight: 500
  }
];

const TABLE_COLUMNS = [
  {
    label: 'Name',
    value: 'name',
    sortable: true
  },
  {
    label: 'Default Role',
    value: 'role',
    sortable: true
  }
];

const EvaluationSessionView = () => {
  const mqIndex = useMediaQueryIndex();
  const isMobileView = mqIndex === 0;
  const isTabletView = mqIndex <= 1;
  const navigate = useNavigate();
  const { pkRegScoutReportEvaluationSession } = useParams();
  const { state: authState } = useContext(AuthContext);
  const {
    fetchScoutReportEvaluationPlayers,
    fetchRegAssociationDivisionTeams
  } = useContext(RegistrationAdminContext);
  const [activeView, setActiveView] = useState(VIEW_TABS_ENUM.teams);
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const {
    sortValue,
    isDescendingSort,
    onChangeSortBy,
    customFilterData
  } = useSortByFilter(TABLE_COLUMNS);
  const {
    isLoading,
    isSaving,
    isPlayersLoading,
    hasUnsavedChanges,
    regScoutReportEvaluationSession,
    evaluationSessionName,
    regScoutReports,
    regScoutReportQuestions,
    regAssociationDivisionPlayers,
    regScoutReportEvaluations,
    assignedRegScoutReportEvaluations,
    userEvaluationSessionEntry,
    onUpdateEvaluationSession,
    onSelectPlayers,
    onUpdateEvaluation,
    onUpdateEvaluations,
    onUpdateEvaluationAnswer,
    onUpdateEvaluationAnswers,
    onSaveEvaluationEntry,
    onSaveChanges
  } = useEvaluationSessionState(pkRegScoutReportEvaluationSession, {
    fetchOptions: true
  });
  const selectedTab =
    VIEW_TABS.find((tab) => tab.value === activeView) || VIEW_TABS[0];

  const regAssociationAdmin = authState.regAssociationAdmins.value.find(
    (a) =>
      a.regAssociation.pkRegAssociation ===
      regScoutReportEvaluationSession?.regAssociationDivision
        .regAssociationSeason.fkRegAssociation
  );
  const isAssociationSuperAdmin = regAssociationAdmin?.regAssociationAdminPermissions.some(
    (p) => p.fkRegPermission === REG_PERMISSION_ENUM.SUPER_ADMIN
  );

  const evaluationTemplateAttributeQuestions =
    regScoutReportQuestions.filter(
      (q) =>
        q.fkRegScoutReportQuestionType ===
        REG_SCOUT_REPORT_QUESTION_TYPE.ATTRIBUTE
    ) || [];

  const evaluationTemplateMetricQuestions =
    regScoutReportQuestions.filter(
      (q) =>
        q.fkRegScoutReportQuestionType === REG_SCOUT_REPORT_QUESTION_TYPE.METRIC
    ) || [];

  const isCustomPersonView = activeView === VIEW_TABS_ENUM.customPlayers;

  const regAssociation = regScoutReports[0]?.regAssociation;

  usePrompt(
    'Are you sure you want to leave without saving?',
    !isSaving && hasUnsavedChanges
  );

  useEffect(() => {
    if (regScoutReportEvaluationSession?.regAssociationDivision) {
      fetchRegAssociationDivisionTeams({
        pkRegAssociationDivision:
          regScoutReportEvaluationSession.regAssociationDivision
            .pkRegAssociationDivision,
        summaryOnly: true
      });
    }
  }, [
    regScoutReportEvaluationSession?.regAssociationDivision
      .pkRegAssociationDivision
  ]);

  useEffect(() => {
    if (modalState.isOpen && modalState.action === 'CREATE') {
      onCloseModal();
    }
  }, [modalState.isOpen]);

  const onRefreshDivisionPlayersTable = () => {
    fetchScoutReportEvaluationPlayers(
      regScoutReportEvaluationSession.regAssociationDivision
        .regAssociationSeason.fkRegAssociation,
      {
        pkRegAssociationDivision:
          regScoutReportEvaluationSession.regAssociationDivision
            .pkRegAssociationDivision
      }
    );
  };

  const onSaveSession = () => {
    onSaveChanges(() => {
      setActiveView(VIEW_TABS_ENUM.teams);
      triggerNotification('Changes Saved', 'Success', 'green');
    });
  };

  const continueToEvaluation = (
    pkRegScoutReportEvaluationSessionEntry = null
  ) => {
    if (regScoutReports.length === 0) {
      triggerNotification('Please assign a template before you continue.');
      onOpenModal('ASSIGN-TEMPLATES');
    }
    else if (
      regScoutReportEvaluationSession.regScoutReportEvaluationSessionEntries
        .length === 0
    ) {
      triggerNotification('Please assign an evaluator before you continue.');
      onOpenModal('ASSIGN-EVALUATORS');
    }
    else if (evaluationTemplateMetricQuestions.length === 0) {
      triggerNotification(
        'Please assign a template with at least one metric before you continue.'
      );
      onOpenModal('ASSIGN-TEMPLATES');
    }
    else if (pkRegScoutReportEvaluationSessionEntry) {
      navigate(
        `/admin/evaluations/sessions/${regScoutReportEvaluationSession.pkRegScoutReportEvaluationSession}/evaluate/${pkRegScoutReportEvaluationSessionEntry}`
      );
    }
    else if (
      (isTabletView && isAssociationSuperAdmin) ||
      !regScoutReportEvaluationSession.regScoutReportEvaluationSessionEntries.some(
        (e) =>
          e.regAssociationAdmin.user.pkUser === authState.userData.user.pkUser
      )
    ) {
      onOpenModal('SELECT-EVALUATOR');
    }
    else {
      navigate(
        `/admin/evaluations/sessions/${regScoutReportEvaluationSession.pkRegScoutReportEvaluationSession}/evaluate`
      );
    }
  };

  const onEvaluatorMenuAction = (action, evaluator) => {
    switch (action) {
      case EVALUATOR_MENU_ACTIONS_ENUM.view:
        continueToEvaluation(evaluator.pkRegScoutReportEvaluationSessionEntry);
        break;
      case EVALUATOR_MENU_ACTIONS_ENUM.clearAnswers:
      case EVALUATOR_MENU_ACTIONS_ENUM.download:
      case EVALUATOR_MENU_ACTIONS_ENUM.upload:
        onOpenModal(action, evaluator);
        break;
      default:
        break;
    }
  };

  return (
    <AppStack
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        onSaveSession();
      }}
      style={{
        flex: 1,
        gap: isTabletView ? 0 : 5,
        backgroundColor: '#f7f7f7',
        overflow: 'hidden',
        maxHeight: 'calc(100vh - 90px)'
      }}
    >
      <AppStack style={{ gap: 0 }}>
        <AppCard
          style={{
            padding: 0,
            minHeight: 80
          }}
        >
          <AppFlexbox
            style={{
              flex: 1,
              gap: 10,
              justifyContent: 'space-between',
              alignItems: isMobileView ? 'stretch' : 'center',
              padding: isTabletView ? 10 : '10px 20px',
              flexDirection: isMobileView ? 'column' : 'row'
            }}
          >
            {isLoading ? (
              <>
                <AppFlexbox style={{ gap: 15, alignItems: 'center' }}>
                  <Skeleton circle height={55} width={55} />
                  <AppStack style={{ gap: 5 }}>
                    <Skeleton height={24} width={200} />
                    <Skeleton height={16} width={150} />
                  </AppStack>
                </AppFlexbox>

                <AppFlexbox style={{ gap: 15, alignItems: 'center' }}>
                  <Skeleton height={34} visibleFrom="xs" width={150} />
                  <Skeleton height={28} hiddenFrom="xs" width="100%" />
                </AppFlexbox>
              </>
            ) : (
              <>
                <AppFlexbox
                  style={{
                    gap: 15,
                    flex: 1,
                    alignItems: 'center'
                  }}
                >
                  <ActionableIcon
                    color="dark"
                    onClick={() => navigate(`/admin/evaluations/sessions`)}
                    radius={100}
                    size={50}
                    variant="filled"
                  >
                    <ArrowLeft size={35} />
                  </ActionableIcon>
                  <AppStack
                    style={{
                      flex: 1,
                      justifyContent: 'center',
                      gap: 0,
                      overflow: 'hidden'
                    }}
                  >
                    <AppText
                      style={{
                        fontWeight: 700,
                        fontSize: 18,
                        display: '-webkit-box',
                        WebkitLineClamp: '2',
                        lineClamp: '2',
                        WebkitBoxOrient: 'vertical'
                      }}
                    >
                      {evaluationSessionName}
                    </AppText>
                    <AppText style={{ color: 'darkgrey' }}>
                      {
                        regScoutReportEvaluationSession.regAssociationDivision
                          .name
                      }
                    </AppText>
                  </AppStack>
                </AppFlexbox>

                <AppFlexbox
                  style={{ justifyContent: 'center' }}
                  visibleFrom="md"
                >
                  {isPlayersLoading ? (
                    <Loader color="dark" size={30} />
                  ) : (
                    <AppText weight={500}>
                      {assignedRegScoutReportEvaluations.length} of{' '}
                      {regAssociationDivisionPlayers.length} Selected
                    </AppText>
                  )}
                </AppFlexbox>
                <AppFlexbox
                  style={{
                    gap: 15,
                    flex: 1,
                    alignItems: isMobileView ? 'stretch' : 'center',
                    justifyContent: 'end'
                  }}
                >
                  {!isTabletView && activeView === VIEW_TABS_ENUM.attributes ? (
                    <>
                      <Button
                        color="dark"
                        disabled={isSaving}
                        hiddenFrom="md"
                        onClick={() =>
                          setActiveView(VIEW_TABS_ENUM.divisionPlayers)
                        }
                        rightSection={<User />}
                        type="button"
                        variant="outline"
                      >
                        Assigned Players
                      </Button>
                      <Button
                        loading={isSaving}
                        rightSection={<DeviceFloppy />}
                        style={{ flex: isMobileView ? 1 : 'unset' }}
                        type="submit"
                      >
                        Save Changes
                      </Button>
                    </>
                  ) : (
                    <>
                      {!isTabletView &&
                        (isAssociationSuperAdmin ||
                          userEvaluationSessionEntry?.canEditAttributes) && (
                          <Button
                            disabled={
                              assignedRegScoutReportEvaluations.length === 0 ||
                              isSaving
                            }
                            onClick={() =>
                              setActiveView(VIEW_TABS_ENUM.attributes)
                            }
                            rightSection={<Edit />}
                            type="button"
                            variant="outline"
                            visibleFrom="md"
                          >
                            Player Attributes
                          </Button>
                        )}

                      {hasUnsavedChanges ? (
                        <>
                          <Button
                            key="-assign-buttoattributen"
                            loading={isSaving}
                            rightSection={<DeviceFloppy />}
                            type="submit"
                            visibleFrom="md"
                          >
                            Save Changes
                          </Button>
                          <Button
                            hiddenFrom="md"
                            loading={isSaving}
                            rightSection={<DeviceFloppy />}
                            size={isMobileView ? 'compact-md' : 'sm'}
                            style={{ flex: isMobileView ? 1 : 'unset' }}
                            type="submit"
                          >
                            Save Changes{' '}
                            {!isPlayersLoading
                              ? `(${assignedRegScoutReportEvaluations.length}/${regAssociationDivisionPlayers.length})`
                              : ''}
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            color="dark"
                            disabled={
                              assignedRegScoutReportEvaluations.length === 0
                            }
                            onClick={() => continueToEvaluation()}
                            rightSection={<ArrowRight />}
                            type="button"
                            visibleFrom="md"
                          >
                            {regScoutReportEvaluationSession
                              .regScoutReportEvaluations.length === 0
                              ? 'Create Session'
                              : 'Start Session'}
                          </Button>
                          <Button
                            color="dark"
                            disabled={
                              assignedRegScoutReportEvaluations.length === 0
                            }
                            hiddenFrom="md"
                            onClick={() => continueToEvaluation()}
                            rightSection={<ArrowRight />}
                            size={isMobileView ? 'compact-md' : 'sm'}
                            style={{ flex: isMobileView ? 1 : 'unset' }}
                            type="button"
                          >
                            {regScoutReportEvaluationSession
                              .regScoutReportEvaluations.length === 0
                              ? 'Create Session'
                              : 'Start Session'}{' '}
                            {!isPlayersLoading
                              ? `(${assignedRegScoutReportEvaluations.length}/${regAssociationDivisionPlayers.length})`
                              : ''}
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </AppFlexbox>
              </>
            )}
          </AppFlexbox>
        </AppCard>
        <AppStack hiddenFrom="md" style={{ gap: 0 }}>
          <Divider />
          <AppFlexbox style={{ gap: 0 }}>
            <AppCard
              onClick={() => {
                if (!isLoading) {
                  onOpenModal('SESSION-SETTINGS');
                }
              }}
              style={{ flex: 1, padding: 0, cursor: 'pointer' }}
            >
              <AppFlexbox
                style={{
                  padding: 5,
                  gap: 5,
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {isLoading ? (
                  <Skeleton height={20} width={120} />
                ) : (
                  <>
                    <Settings color="#000" size={20} />
                    <AppText style={{ fontSize: 14 }} weight={500}>
                      Settings
                    </AppText>
                  </>
                )}
              </AppFlexbox>
            </AppCard>
            <Divider orientation="vertical" />
            <AppCard
              onClick={() => {
                if (!isLoading) {
                  onOpenModal('SESSION-ATTRIBUTES');
                }
              }}
              style={{ flex: 1, padding: 0, cursor: 'pointer' }}
            >
              <AppFlexbox
                style={{
                  padding: 5,
                  gap: 5,
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {isLoading ? (
                  <Skeleton height={20} width={120} />
                ) : (
                  <>
                    <Edit color="#000" size={20} />
                    <AppText style={{ fontSize: 14 }} weight={500}>
                      Attributes
                    </AppText>
                  </>
                )}
              </AppFlexbox>
            </AppCard>
          </AppFlexbox>
          <Divider />
        </AppStack>
      </AppStack>
      <AppFlexbox
        style={{
          flex: 1,
          gap: 5,
          overflow: 'hidden'
        }}
      >
        {!isTabletView && activeView === VIEW_TABS_ENUM.attributes ? (
          <EvaluationSessionPlayerAnswerAssign
            attributeQuestions={evaluationTemplateAttributeQuestions}
            hasUnsavedChanges={hasUnsavedChanges}
            isSaving={isSaving}
            onSaveChanges={onSaveSession}
            onUpdateEvaluation={onUpdateEvaluation}
            onUpdateEvaluationAnswer={onUpdateEvaluationAnswer}
            onUpdateEvaluations={onUpdateEvaluations}
            regScoutReportEvaluations={assignedRegScoutReportEvaluations}
            regScoutReportEvaluationSession={regScoutReportEvaluationSession}
            title="Player Attributes"
          />
        ) : (
          <>
            <AppCard
              style={{
                flex: 1,
                minWidth: 300,
                maxWidth: 450,
                overflow: 'auto'
              }}
              visibleFrom="md"
            >
              <AppStack style={{ gap: 20 }}>
                <EvaluationSessionSettings
                  canEdit={isAssociationSuperAdmin}
                  evaluationSessionName={evaluationSessionName}
                  evaluationTemplateMetricQuestions={
                    evaluationTemplateMetricQuestions
                  }
                  isLoading={isLoading}
                  isSaving={isSaving}
                  onAssignAction={onOpenModal}
                  onEvaluatorMenuAction={onEvaluatorMenuAction}
                  onUpdateEvaluationSession={onUpdateEvaluationSession}
                  regScoutReportEvaluations={regScoutReportEvaluations}
                  regScoutReportEvaluationSession={
                    regScoutReportEvaluationSession
                  }
                  regScoutReports={regScoutReports}
                />
              </AppStack>
            </AppCard>
            <AppStack
              style={{
                gap: 0,
                flex: 3,
                overflow: 'hidden'
              }}
            >
              <AppFlexbox style={{ justifyContent: 'space-between' }}>
                <AppFlexbox
                  hiddenFrom="sm"
                  style={{
                    justifyContent: 'space-between',
                    padding: '0px 5px',
                    flex: 1,
                    flexDirection: isMobileView ? 'column' : 'row',
                    alignItems: 'start',
                    gap: 10
                  }}
                >
                  {isLoading ? (
                    <Skeleton height={30} width={135} />
                  ) : (
                    <>
                      <MenuContent
                        control={
                          <Button
                            rightSection={<ChevronDown />}
                            style={{
                              paddingLeft: 2,
                              paddingRight: 2
                            }}
                            variant="subtle"
                          >
                            {selectedTab?.label}
                          </Button>
                        }
                      >
                        {VIEW_TABS.map((tab) => (
                          <Menu.Item
                            key={tab.value}
                            onClick={() => setActiveView(tab.value)}
                          >
                            {tab.label}
                          </Menu.Item>
                        ))}
                      </MenuContent>

                      {selectedTab?.tabActions && isAssociationSuperAdmin && (
                        <AppFlexbox
                          style={{
                            marginLeft: 'auto',
                            alignSelf: 'center',
                            gap: 10,
                            width: isMobileView ? '100%' : 'unset',
                            marginBottom: isMobileView ? 10 : 0
                          }}
                        >
                          {selectedTab.tabActions.map((action) => (
                            <Button
                              key={action.value}
                              color={action.color}
                              disabled={isPlayersLoading}
                              leftSection={action.icon}
                              onClick={() => onOpenModal(action.value)}
                              size="compact-md"
                              style={{ width: isMobileView ? '100%' : 'unset' }}
                              variant={action.variant}
                            >
                              {action.label}
                            </Button>
                          ))}
                        </AppFlexbox>
                      )}
                    </>
                  )}
                </AppFlexbox>

                <Tabs
                  onChange={(activeTab) => {
                    if (!isLoading) {
                      setActiveView(activeTab);
                    }
                  }}
                  style={{ flex: 1 }}
                  value={activeView}
                  variant="outline"
                  visibleFrom="sm"
                >
                  <Tabs.List>
                    {VIEW_TABS.map((tab) => {
                      const isSelected = activeView === tab.value;
                      return (
                        <Tabs.Tab
                          key={tab.value}
                          style={{...(isSelected ? { background: '#FFF' } : {})}}
                          value={tab.value}
                        >
                          {isLoading ? (
                            <Skeleton height={18} width={75} />
                          ) : (
                            <AppText style={{ fontSize: 14 }} weight={500}>
                              {tab.label}
                            </AppText>
                          )}
                        </Tabs.Tab>
                      );
                    })}
                    {selectedTab?.tabActions && isAssociationSuperAdmin && (
                      <AppFlexbox
                        style={{
                          marginLeft: 'auto',
                          alignSelf: 'center',
                          marginRight: 5,
                          gap: 10
                        }}
                      >
                        {selectedTab.tabActions.map((action) => (
                          <Button
                            key={action.value}
                            color={action.color}
                            disabled={isPlayersLoading}
                            onClick={() => onOpenModal(action.value)}
                            rightSection={action.icon}
                            size="compact-md"
                            variant={action.variant}
                          >
                            {action.label}
                          </Button>
                        ))}
                      </AppFlexbox>
                    )}
                  </Tabs.List>
                </Tabs>
              </AppFlexbox>
              <AppCard style={{ flex: 1, padding: 0, overflow: 'auto' }}>
                {activeView === VIEW_TABS_ENUM.stats ? (
                  <EvaluationSessionStatisticsView
                    continueToEvaluation={continueToEvaluation}
                    regAssociation={regAssociation}
                    regAssociationDivisionPlayers={
                      regAssociationDivisionPlayers
                    }
                    regScoutReportEvaluations={
                      assignedRegScoutReportEvaluations
                    }
                    regScoutReportEvaluationSession={
                      regScoutReportEvaluationSession
                    }
                    regScoutReportQuestions={regScoutReportQuestions}
                  />
                ) : activeView === VIEW_TABS_ENUM.teams ? (
                  <EvaluationSessionTeamSelect
                    isLoading={isPlayersLoading}
                    onSelectPlayers={onSelectPlayers}
                    regAssociation={regAssociation}
                    regScoutReportEvaluationSession={
                      regScoutReportEvaluationSession
                    }
                    selectedPlayers={assignedRegScoutReportEvaluations
                      .filter((e) => !e.person.isCustomPerson)
                      .map((e) => e.person.entityId)}
                    userEvaluationSessionEntry={userEvaluationSessionEntry}
                  />
                ) : (
                  <EvaluationSessionPlayerSelect
                    attributes={evaluationTemplateAttributeQuestions}
                    fkRegAssociationDivision={
                      regScoutReportEvaluationSession?.regAssociationDivision
                        .pkRegAssociationDivision
                    }
                    isCustomPerson={isCustomPersonView}
                    isDescendingSort={isDescendingSort}
                    isLoading={isPlayersLoading}
                    modalAction={modalState.isOpen ? modalState.action : ''}
                    onChangeSortBy={onChangeSortBy}
                    onRefresh={onRefreshDivisionPlayersTable}
                    onSelectPlayers={(keys) =>
                      onSelectPlayers(keys, isCustomPersonView)
                    }
                    players={regAssociationDivisionPlayers.filter((p) =>
                      isCustomPersonView
                        ? p.person.isCustomPerson
                        : !p.person.isCustomPerson
                    )}
                    regAssociation={regAssociation}
                    regScoutReportEvaluations={regScoutReportEvaluations}
                    regScoutReportEvaluationSession={
                      regScoutReportEvaluationSession
                    }
                    regScoutReportQuestions={regScoutReportQuestions}
                    regScoutReports={regScoutReports}
                    selectedPlayers={assignedRegScoutReportEvaluations
                      .filter((e) =>
                        isCustomPersonView
                          ? e.person.isCustomPerson
                          : !e.person.isCustomPerson
                      )
                      .map((e) => e.person.entityId)}
                    sortFilter={customFilterData}
                    sortValue={sortValue}
                    userEvaluationSessionEntry={userEvaluationSessionEntry}
                  />
                )}
              </AppCard>
            </AppStack>
          </>
        )}
      </AppFlexbox>

      <ConfirmModal
        confirmActionColor="red"
        confirmationValue={regScoutReportEvaluationSession?.name}
        isLoading={isSaving}
        isOpen={
          modalState.isOpen &&
          modalState.action === EVALUATOR_MENU_ACTIONS_ENUM.clearAnswers
        }
        message="Are you sure you want to clear the evalutions answers?"
        onCancel={onCloseModal}
        onConfirm={() => {
          onSaveEvaluationEntry(
            modalState.item.pkRegScoutReportEvaluationSessionEntry,
            () => {
              triggerNotification('Answers cleared.', 'success', 'green');
              onCloseModal();
            },
            null,
            { clearAnswers: true }
          );
        }}
        title="Clear Answers"
        warningListTitle="This will clear all answers for the evaluator."
        warnings={[
          'This action cannot be undone.',
          'All answers will be permanently deleted.'
        ]}
      />

      <EvaluationSessionEvaluatorSelectModal
        evaluationTemplateMetricQuestions={evaluationTemplateMetricQuestions}
        isOpen={modalState.isOpen && modalState.action === 'SELECT-EVALUATOR'}
        onClose={onCloseModal}
        onSaveChanges={onSaveChanges}
        regScoutReportEvaluations={regScoutReportEvaluations}
        regScoutReportEvaluationSession={regScoutReportEvaluationSession}
        regScoutReportEvaluationSessionEntries={
          regScoutReportEvaluationSession?.regScoutReportEvaluationSessionEntries
        }
        regScoutReports={regScoutReports}
      />

      <EvaluationSessionAssignModal
        action={modalState.action}
        isOpen={
          modalState.isOpen &&
          (modalState.action === 'ASSIGN-TEMPLATES' ||
            modalState.action === 'ASSIGN-EVALUATORS')
        }
        onClose={onCloseModal}
        onSaveChanges={onSaveChanges}
        regScoutReportEvaluationSession={regScoutReportEvaluationSession}
      />

      <EvaluationSessionSettingsModal
        canEdit={isAssociationSuperAdmin}
        evaluationSessionName={evaluationSessionName}
        evaluationTemplateMetricQuestions={evaluationTemplateMetricQuestions}
        isLoading={isLoading}
        isOpen={modalState.isOpen && modalState.action === 'SESSION-SETTINGS'}
        isSaving={isSaving}
        onAssignAction={onOpenModal}
        onClose={onCloseModal}
        onEvaluatorMenuAction={onEvaluatorMenuAction}
        onSaveChanges={onSaveChanges}
        onUpdateEvaluationSession={onUpdateEvaluationSession}
        regScoutReportEvaluations={regScoutReportEvaluations}
        regScoutReportEvaluationSession={regScoutReportEvaluationSession}
        regScoutReports={regScoutReports}
      />

      <EvaluationSessionAttributesModal
        attributeQuestions={evaluationTemplateAttributeQuestions}
        hasUnsavedChanges={hasUnsavedChanges}
        isOpen={modalState.isOpen && modalState.action === 'SESSION-ATTRIBUTES'}
        isSaving={isSaving}
        onClose={onCloseModal}
        onSaveChanges={onSaveChanges}
        onUpdateEvaluation={onUpdateEvaluation}
        onUpdateEvaluationAnswer={onUpdateEvaluationAnswer}
        onUpdateEvaluationAnswers={onUpdateEvaluationAnswers}
        onUpdateEvaluations={onUpdateEvaluations}
        regScoutReportEvaluations={assignedRegScoutReportEvaluations}
      />

      <EvaluationSessionEntryUploadModal
        isOpen={
          modalState.isOpen &&
          modalState.action === EVALUATOR_MENU_ACTIONS_ENUM.upload
        }
        onClose={onCloseModal}
        onSaveEvaluationEntry={onSaveEvaluationEntry}
        onUpdateEvaluations={onUpdateEvaluations}
        regScoutReportEvaluations={regScoutReportEvaluations}
        regScoutReportEvaluationSession={regScoutReportEvaluationSession}
        regScoutReportEvaluationSessionEntry={
          modalState.action === EVALUATOR_MENU_ACTIONS_ENUM.download ||
          modalState.action === EVALUATOR_MENU_ACTIONS_ENUM.upload
            ? modalState.item
            : null
        }
        regScoutReportQuestions={regScoutReportQuestions}
        showDownload={
          modalState.isOpen &&
          modalState.action === EVALUATOR_MENU_ACTIONS_ENUM.download
        }
      />

      <EvaluationPlayerUploadModal
        isOpen={
          modalState.isOpen &&
          modalState.action === EVALUATOR_MENU_ACTIONS_ENUM.uploadPlayers
        }
        onClose={onCloseModal}
        pkRegAssociationDivision={
          regScoutReportEvaluationSession?.regAssociationDivision
            .pkRegAssociationDivision
        }
      />
    </AppStack>
  );
};

EvaluationSessionView.propTypes = {};

export default EvaluationSessionView;
