import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Checkbox, TextInput } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppTitle from '../../common/AppTitle';
import FormSection from '../../common/FormSection';
import AppStack from '../../common/AppStack';
import {
  REG_PERMISSION_ENUM,
  REG_PERMISSION_LIST
} from '../../../config/constants';
import { triggerNotification } from '../../../helpers/notificationHelper';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import AccordionListItem from '../../common/AccordionListItem';
import PaginationList from '../../common/PaginationList';
import AppText from '../../common/AppText';
import AdminListItem from './AdminListItem';
import AppFlexbox from '../../common/AppFlexbox';
import CustomMultiSelect from '../../common/CustomMultiSelect';

const AdminModal = ({
  isOpen,
  onClose,
  regAssociation,
  regAssociationAdmin,
  requiredPermissions,
  title
}) => {
  const minInviteExpireDate = new Date();
  minInviteExpireDate.setDate(minInviteExpireDate.getDate() + 1);
  const {
    state,
    updateRegAssociationAdministrator,
    createRegAssociationAdministrator
  } = useContext(RegistrationAdminContext);
  const [formState, setFormState] = useState({
    sentTo: '',
    resendInvite: false,
    inviteExpires: false,
    expiresAt: minInviteExpireDate,
    permissions: [],
    pageIndex: 1,
    isLoading: false
  });
  const isSuperAdminSelected = !!formState.permissions.find(
    (p) => p.fkRegPermission === REG_PERMISSION_ENUM.SUPER_ADMIN
  );
  const allPermissionsSelected = REG_PERMISSION_LIST.every(
    (p) => !!formState.permissions.find((v) => v.fkRegPermission === p.value)
  );
  const regAssociationDivisions = state.regAssociationDivisions.value
    .filter(
      (d) =>
        d.regAssociationSeason?.fkRegAssociation ===
        regAssociation?.pkRegAssociation
    )
    .map((d) => ({
      label: d.name,
      description: d.regAssociationSeason?.name,
      value: d.pkRegAssociationDivision.toString()
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  useEffect(() => {
    if (isOpen) {
      setFormState({
        sentTo: regAssociationAdmin?.inviteSentTo || '',
        resendInvite: false,
        inviteExpires: !!regAssociationAdmin?.inviteExpiresAt,
        expiresAt: regAssociationAdmin?.inviteExpiresAt
          ? new Date(regAssociationAdmin.inviteExpiresAt)
          : minInviteExpireDate,
        permissions: regAssociationAdmin
          ? regAssociationAdmin?.regAssociationAdminPermissions.map((p) => ({
              fkRegPermission: p.fkRegPermission,
              regAssociationDivisions: p.regAssociationDivisions.map((m) =>
                m.pkRegAssociationDivision.toString()
              )
            }))
          : requiredPermissions
          ? requiredPermissions.map((p) => ({
              fkRegPermission: p,
              regAssociationDivisions: []
            }))
          : [],
        pageIndex: 1,
        isLoading: false
      });
    }
  }, [isOpen]);

  const onError = (e) => {
    setFormState({ ...formState, isLoading: false });
    triggerNotification(e);
  };

  const onPermissionChange = (newPermissions) => {
    setFormState({
      ...formState,
      permissions: newPermissions
    });
  };

  return (
    <ResponsiveModal
      onClose={onClose}
      opened={isOpen}
      size={750}
      title={
        <AppTitle order={2}>
          {title ||
            (regAssociationAdmin?.user
              ? 'Edit Administrator'
              : regAssociationAdmin
              ? 'Edit Invite'
              : 'Invite Administrator')}
        </AppTitle>
      }
    >
      <FormSection
        isLoading={formState.isLoading}
        onCancel={onClose}
        onSubmit={() => {
          if (formState.permissions.length === 0) {
            triggerNotification('Please select at least one permission');
          }
          else {
            setFormState({
              ...formState,
              isLoading: true
            });

            const formData = {
              ...formState,
              expiresAt: formState.inviteExpires ? formState.expiresAt : null
            };
            if (regAssociationAdmin) {
              updateRegAssociationAdministrator(
                [
                  {
                    ...formData,
                    pkRegAssociationAdmin:
                      regAssociationAdmin.pkRegAssociationAdmin
                  }
                ],
                (data) => {
                  if (data.some((d) => !d.user) && formData.resendInvite) {
                    triggerNotification('Invite Sent', 'Success', 'green');
                  }
                  else {
                    triggerNotification(
                      'Administrator Updated',
                      'Success',
                      'green'
                    );
                  }
                  onClose();
                },
                onError
              );
            }
            else {
              createRegAssociationAdministrator(
                [
                  {
                    ...formData,
                    fkRegAssociation: regAssociation.pkRegAssociation
                  }
                ],
                (data) => {
                  if (data.some((d) => !d.user)) {
                    triggerNotification('Invite Sent', 'Success', 'green');
                  }
                  else {
                    triggerNotification(
                      'Administrator Updated',
                      'Success',
                      'green'
                    );
                  }
                  onClose();
                },
                onError
              );
            }
          }
        }}
        submitTitle={regAssociationAdmin ? 'Save Changes' : 'Send Invite'}
      >
        <AppStack style={{ gap: 20 }}>
          {regAssociationAdmin ? (
            <>
              <AdminListItem regAssociationAdmin={regAssociationAdmin} />
              {!regAssociationAdmin.user && (
                <Checkbox
                  checked={formState.resendInvite}
                  disabled={formState.isLoading}
                  label="Resend invite"
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      resendInvite: e.currentTarget.checked
                    })
                  }
                  style={{ fontWeight: 500 }}
                />
              )}
            </>
          ) : (
            <TextInput
              disabled={formState.isLoading}
              label="Email"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  sentTo: e.currentTarget.value
                })
              }
              required
              type="email"
              value={formState.sentTo}
            />
          )}

          {(!regAssociationAdmin || formState.resendInvite) && (
            <>
              <Checkbox
                checked={formState.inviteExpires}
                disabled={formState.isLoading}
                label="Invite Expires"
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    inviteExpires: e.currentTarget.checked
                  })
                }
                style={{ fontWeight: 500 }}
              />
              {formState.inviteExpires && (
                <DatePickerInput
                  disabled={formState.isLoading}
                  label="Expiry Date"
                  minDate={minInviteExpireDate}
                  onChange={(value) =>
                    setFormState({
                      ...formState,
                      expiresAt: value
                    })
                  }
                  required
                  value={formState.expiresAt}
                />
              )}
            </>
          )}

          <AppStack style={{ gap: 5 }}>
            <AppFlexbox style={{ justifyContent: 'space-between' }}>
              <AppText weight={500}>Permissions</AppText>
              {!requiredPermissions && (
                <Checkbox
                  checked={allPermissionsSelected}
                  disabled={formState.isLoading}
                  label={allPermissionsSelected ? 'Unselect All' : 'Select All'}
                  onChange={() => {
                    if (allPermissionsSelected) {
                      onPermissionChange([]);
                    }
                    else {
                      onPermissionChange(
                        REG_PERMISSION_LIST.map((p) => ({
                          fkRegPermission: p.value,
                          regAssociationDivisions: []
                        }))
                      );
                    }
                  }}
                  style={{ fontWeight: 500 }}
                />
              )}
            </AppFlexbox>
            <PaginationList
              items={REG_PERMISSION_LIST.filter(
                (p) =>
                  !requiredPermissions ||
                  requiredPermissions.some((v) => v === p.value)
              ).map((p) => {
                const selectedPermission = formState.permissions.find(
                  (v) => v.fkRegPermission === p.value
                );
                const isSuperAdminPermission =
                  p.value === REG_PERMISSION_ENUM.SUPER_ADMIN;
                const disableNonSuperPermissionItem =
                  isSuperAdminSelected && !isSuperAdminPermission;

                return (
                  <AppStack key={p.value} style={{ flex: 1 }}>
                    <AccordionListItem
                      isDisabled={
                        formState.isLoading ||
                        disableNonSuperPermissionItem ||
                        !!requiredPermissions
                      }
                      isSelected={!!selectedPermission || isSuperAdminSelected}
                      label={p.label}
                      noAccordionContent={
                        isSuperAdminPermission || disableNonSuperPermissionItem
                      }
                      onSelect={() => {
                        if (selectedPermission) {
                          onPermissionChange([
                            ...formState.permissions.filter(
                              (v) => v.fkRegPermission !== p.value
                            )
                          ]);
                        }
                        else {
                          onPermissionChange([
                            ...formState.permissions,
                            {
                              fkRegPermission: p.value,
                              regAssociationDivisions: []
                            }
                          ]);
                        }
                      }}
                      subLabel={p.description}
                      value={p.value.toString()}
                    >
                      {selectedPermission && !isSuperAdminPermission && (
                        <AppFlexbox style={{ flex: 2, padding: 15 }}>
                          <CustomMultiSelect
                            data={regAssociationDivisions}
                            disabled={formState.isLoading}
                            label="Permitted Divisions"
                            onChange={(data) => {
                              onPermissionChange([
                                ...formState.permissions.filter(
                                  (f) =>
                                    f.fkRegPermission !==
                                    selectedPermission.fkRegPermission
                                ),
                                {
                                  ...selectedPermission,
                                  regAssociationDivisions: data
                                }
                              ]);
                            }}
                            placeholder={
                              selectedPermission.regAssociationDivisions
                                .length === 0
                                ? 'Unrestricted'
                                : null
                            }
                            style={{ flex: 1 }}
                            value={selectedPermission.regAssociationDivisions}
                          />
                          <AppStack style={{ flex: 1 }} />
                        </AppFlexbox>
                      )}
                    </AccordionListItem>
                  </AppStack>
                );
              })}
              LoadingComponent={AccordionListItem}
              noBorder
              onPageChange={(pageIndex) =>
                setFormState({ ...formState, pageIndex })
              }
              pageIndex={formState.pageIndex}
            />
          </AppStack>
        </AppStack>
      </FormSection>
    </ResponsiveModal>
  );
};

AdminModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  regAssociation: PropTypes.object,
  regAssociationAdmin: PropTypes.object,
  requiredPermissions: PropTypes.array,
  title: PropTypes.string
};

export default AdminModal;
