import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Combobox, InputBase, useCombobox } from '@mantine/core';

const SelectCreatable = ({
  defaultData,
  onChange,
  value,
  placeholder,
  clearable,
  ...rest
}) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption()});
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');

  const exactOptionMatch = data.some((item) => item === search);
  const filteredOptions = exactOptionMatch
    ? data
    : data.filter((item) =>
        item.toLowerCase().includes(search?.toLowerCase().trim())
      );

  const options = filteredOptions.map((item) => (
    <Combobox.Option key={item} value={item}>
      {item}
    </Combobox.Option>
  ));

  useEffect(() => {
    setData(defaultData);
    setSearch(value ?? '');
  }, [defaultData]);

  return (
    <Combobox
      onOptionSubmit={(val) => {
        if (val === '$create') {
          setData((current) => [...current, search]);
          onChange(search);
        }
        else {
          onChange(val);
          setSearch(val);
        }

        combobox.closeDropdown();
      }}
      store={combobox}
      withinPortal={false}
      {...rest}
    >
      <Combobox.Target>
        <InputBase
          onBlur={() => {
            combobox.closeDropdown();
            setSearch(value || '');
          }}
          onChange={(event) => {
            combobox.openDropdown();
            combobox.updateSelectedOptionIndex();
            setSearch(event.currentTarget.value);
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          placeholder={placeholder}
          rightSection={
            clearable ? (
              <Combobox.ClearButton
                onClear={() => {
                  combobox.closeDropdown();
                  setSearch('');
                  onChange('');
                }}
              />
            ) : (
              <Combobox.Chevron />
            )
          }
          rightSectionPointerEvents={clearable ? 'all' : 'none'}
          value={search}
        />
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>
          {options}
          {!exactOptionMatch && search?.trim().length > 0 && (
            <Combobox.Option value="$create">+ Create {search}</Combobox.Option>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

SelectCreatable.propTypes = {
  clearable: PropTypes.bool,
  defaultData: PropTypes.array,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string
};

export default SelectCreatable;
