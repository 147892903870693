import Auth from '@aws-amplify/auth';
import Storage from '@aws-amplify/storage';
import createDataProvider from './createDataProvider';
import sportsheadzApi from '../apis/sportsheadzApi';
import {
  COGNITO_SETTING_DEV,
  COGNITO_SETTING_PROD,
  PRODUCTION
} from '../config/constants';

Auth.configure(PRODUCTION ? COGNITO_SETTING_PROD : COGNITO_SETTING_DEV);
Storage.configure(PRODUCTION ? COGNITO_SETTING_PROD : COGNITO_SETTING_DEV);

const initialState = {
  email: '',
  password: '',
  isAuthenticated: false,
  isWaitingConfirmSignUp: false,
  userData: null,
  error: null,
  tokenAttempted: false,
  regAssociationAdmins: { value: [], loading: false, error: '' },
  loading: false
};

const authReducer = (state, action) => {
  switch (action.type) {
    case 'fetching':
      return {
        ...state,
        loading: true,
        error: null
      };
    case 'success':
      return { ...state, error: null, loading: false, ...action.payload };
    case 'error':
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case 'logout':
      return { ...initialState, tokenAttempted: true };
    case 'reset': {
      return initialState;
    }
    case 'set-association-admins':
      return {
        ...state,
        regAssociationAdmins: {
          ...state.regAssociationAdmins,
          ...action.payload
        }
      };
    default:
      return state;
  }
};

const fetchUserAdminData = async (dispatch) => {
  try {
    dispatch({
      type: 'set-association-admins',
      payload: { loading: true }
    });
    const response = await sportsheadzApi.get(`/api/me/registration-admins`, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      },
      withCredentials: true
    });
    dispatch({
      type: 'success',
      payload: {
        regAssociationAdmins: {
          value: response.data.regAssociationAdmins,
          loading: false,
          error: null
        }
      }
    });
  } catch (error) {
    dispatch({
      type: 'set-association-admins',
      payload: {
        loading: false,
        error: 'Oops something went wrong.'
      }
    });
  }
};

const fetchRegAssociationAdminData = (dispatch) => async () => {
  fetchUserAdminData(dispatch);
};

const tokenSignin = (dispatch) => async () => {
  try {
    await Auth.currentAuthenticatedUser();
    const response = await sportsheadzApi.get('/api/user/auth', {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      },
      withCredentials: true
    });
    if (response.data.isRegistrationAdmin) {
      fetchUserAdminData(dispatch);
    }
    dispatch({
      type: 'success',
      payload: {
        isAuthenticated: true,
        tokenAttempted: true,
        userData: response.data
      }
    });
  } catch {
    dispatch({ type: 'logout' });
  }
};

const signin = (dispatch) => async (
  { email, password },
  onSuccessCallback,
  onErrorCallback
) => {
  if (email && password) {
    try {
      dispatch({ type: 'fetching' });
      await Auth.signIn(email, password);

      const response = await sportsheadzApi.get('/api/user/auth', {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      });
      if (response.data.isRegistrationAdmin) {
        fetchUserAdminData(dispatch);
      }

      dispatch({
        type: 'success',
        payload: {
          email,
          isAuthenticated: true,
          tokenAttempted: true,
          userData: response.data
        }
      });
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    } catch (err) {
      dispatch({ type: 'logout' });
      switch (err.code) {
        case 'NotAuthorizedException':
        case 'UserNotFoundException':
          dispatch({
            type: 'error',
            payload: 'Incorrect username or password.'
          });
          break;
        case 'UserNotConfirmedException':
          dispatch({
            type: 'success',
            payload: { email, password, isWaitingConfirmSignUp: true }
          });
          break;
        default:
          dispatch({
            type: 'error',
            payload: 'An unknown error occured.'
          });
      }
      if (onErrorCallback) {
        onErrorCallback();
      }
    }
  }
};

const createAccount = (dispatch) => async ({
  firstName,
  lastName,
  email,
  password
}) => {
  dispatch({ type: 'fetching' });
  try {
    await Auth.signUp({
      username: email,
      password,
      attributes: {
        given_name: firstName,
        family_name: lastName
      }
    });
    dispatch({
      type: 'success',
      payload: { email, password, isWaitingConfirmSignUp: true }
    });
  } catch (err) {
    let errorMessage;
    switch (err.code) {
      case 'UsernameExistsException':
        errorMessage = 'User with email already exists.';
        break;
      default:
        errorMessage = 'Oops something went wrong';
    }
    dispatch({ type: 'error', payload: errorMessage });
  }
};

const confirmAccount = (dispatch) => async (
  { email, confirmationCode },
  successCallback
) => {
  dispatch({ type: 'fetching' });
  try {
    await Auth.confirmSignUp(email, confirmationCode);
    dispatch({
      type: 'success',
      payload: { email, isWaitingConfirmSignUp: false }
    });

    if (successCallback) {
      successCallback();
    }
  } catch (err) {
    let errorMessage;
    switch (err.code) {
      case 'ExpiredCodeException':
        errorMessage = 'Code is expired. Are you already verified?';
        break;
      case 'LimitExceededException':
        errorMessage = 'Attempt limit exceeded, please try after some time.';
        break;
      case 'CodeMismatchException':
        errorMessage = 'Invalid verification code provided, please try again.';
        break;
      default:
        errorMessage = 'Oops something went wrong';
    }
    dispatch({ type: 'error', payload: errorMessage });
  }
};

const logout = (dispatch) => async () => {
  dispatch({ type: 'fetching' });
  await Auth.signOut();
  dispatch({ type: 'reset' });
};

const reset = (dispatch) => async () => {
  dispatch({ type: 'reset' });
};

const clearErrors = (dispatch) => async () => {
  dispatch({ type: 'error', payload: null });
};

const checkForExistingUser = (dispatch) => async (email, successCallback) => {
  dispatch({ type: 'fetching' });

  try {
    await Auth.confirmSignUp(email, '0');
  } catch (e) {
    if (e.code === 'UserNotFoundException') {
      dispatch({ type: 'error', payload: 'Oops something went wrong' });
    }
    else {
      successCallback();
      dispatch({
        type: 'success',
        payload: { email }
      });
    }
  }
};

const resendVerificationCode = (dispatch) => async (email, successCallback) => {
  dispatch({ type: 'fetching' });

  try {
    await Auth.resendSignUp(email);
    if (successCallback) {
      successCallback();
    }

    dispatch({
      type: 'success',
      payload: {
        email,
        isWaitingConfirmSignUp: true
      }
    });
  } catch (e) {
    dispatch({
      type: 'error',
      payload: 'Oops something went wrong. Are you already verified?'
    });
  }
};

const sendForgotPasswordConfirmation = (dispatch) => async (
  email,
  successCallback
) => {
  dispatch({ type: 'fetching' });

  try {
    await Auth.forgotPassword(email);
    dispatch({ type: 'success', payload: { email } });
    if (successCallback) {
      successCallback();
    }
  } catch (e) {
    if (e.code === 'InvalidParameterException') {
      dispatch({
        type: 'success',
        payload: {
          email,
          isWaitingConfirmSignUp: true
        }
      });
    }
    else {
      switch (e.code) {
        case 'LimitExceededException':
          dispatch({
            type: 'error',
            payload: 'Attempt limit exceeded, please try after some time.'
          });
          break;
        default:
          dispatch({ type: 'error', payload: 'Oops something went wrong.' });
      }
    }
  }
};

const resetPassword = (dispatch) => async (
  { email, code, newPassword },
  successCallback
) => {
  dispatch({ type: 'fetching' });

  try {
    await Auth.forgotPasswordSubmit(email, code, newPassword);
    dispatch({
      type: 'success',
      payload: { email }
    });
    if (successCallback) {
      successCallback();
    }
  } catch (e) {
    let errorMessage;
    switch (e.code) {
      case 'ExpiredCodeException':
        errorMessage = 'Code is expired. Are you already verified?';
        break;
      case 'LimitExceededException':
        errorMessage = 'Attempt limit exceeded, please try after some time.';
        break;
      case 'CodeMismatchException':
        errorMessage = 'Invalid verification code provided, please try again.';
        break;
      default:
        errorMessage = 'Error changing password';
    }
    dispatch({ type: 'error', payload: errorMessage });
  }
};

const changePassword = (dispatch) => async (
  { oldPassword, newPassword },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, oldPassword, newPassword);
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    let errorMessage;
    switch (e.code) {
      case 'NotAuthorizedException':
        errorMessage = 'Old password is invalid.';
        break;
      default:
        errorMessage = 'Error changing password';
    }
    if (onErrorCallback) {
      onErrorCallback(errorMessage);
    }
  }
};

const updateProfile = (dispatch) => async (
  { firstName, lastName, phone, avatar },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    await sportsheadzApi.put(
      `/api/me/profile`,
      { firstName, lastName, phone, avatar },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    const response = await sportsheadzApi.get('/api/user/auth', {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      },
      withCredentials: true
    });
    dispatch({
      type: 'success',
      payload: {
        isAuthenticated: true,
        tokenAttempted: true,
        userData: response.data
      }
    });
    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch (e) {
    if (onErrorCallback) {
      onErrorCallback();
    }
  }
};

const updateAvatar = (dispatch) => async (
  { avatar },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    await sportsheadzApi.put(
      `/api/me/avatar`,
      { avatar },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    const response = await sportsheadzApi.get('/api/user/auth', {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      },
      withCredentials: true
    });
    dispatch({
      type: 'success',
      payload: {
        isAuthenticated: true,
        tokenAttempted: true,
        userData: response.data
      }
    });
    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch (e) {
    if (onErrorCallback) {
      onErrorCallback();
    }
  }
};

export const { Provider, Context } = createDataProvider(
  authReducer,
  {
    tokenSignin,
    signin,
    logout,
    reset,
    createAccount,
    confirmAccount,
    clearErrors,
    checkForExistingUser,
    fetchRegAssociationAdminData,
    resendVerificationCode,
    sendForgotPasswordConfirmation,
    resetPassword,
    changePassword,
    updateProfile,
    updateAvatar
  },
  initialState
);
