const calculateCartTotals = (lineItems, includeFinancialAssistance) => {
  const payableLineItems = lineItems.filter(
    (i) => !i.financialAssistance || includeFinancialAssistance
  );
  return {
    subtotalInCents: payableLineItems.reduce(
      (r, c) => r + c.subtotalInCents,
      0
    ),
    discountInCents: payableLineItems.reduce(
      (r, c) => r + c.discountInCents,
      0
    ),
    taxInCents: payableLineItems.reduce((r, c) => r + c.taxInCents, 0),
    serviceFeeInCents: payableLineItems.reduce(
      (r, c) => (c.customerPaysServiceFees ? r + c.serviceFeeInCents : r),
      0
    ),
    totalInCents: payableLineItems.reduce((r, c) => r + c.totalInCents, 0)
  };
};

const calculateRegFormSubmissionBalanceTotals = (regFormSubmissionBalance) => {
  const addOninvoices = regFormSubmissionBalance.balance.invoices.filter(
    (i) =>
      i.isAdditionalCharge && (i.invoiceStatus === 1 || i.invoiceStatus === 2)
  );

  return {
    registrationAmountPaid:
      regFormSubmissionBalance.balance.amountPaidInCents / 100,
    registrationCost: regFormSubmissionBalance.balance.totalInCents / 100,
    registrationAmountRefunded:
      regFormSubmissionBalance.balance.refundedAmountInCents / 100,
    registrationBalance: regFormSubmissionBalance.balance.balanceInCents / 100,
    invoiceAmountPaid:
      addOninvoices.reduce((r, c) => r + c.amountPaidInCents, 0) / 100,
    invoiceAmountRefunded:
      addOninvoices.reduce((r, c) => r + c.refundedAmountInCents, 0) / 100,
    invoiceCost: addOninvoices.reduce((r, c) => r + c.totalInCents, 0) / 100,
    invoiceBalance:
      addOninvoices.reduce(
        (r, c) =>
          r + c.totalInCents - c.amountPaidInCents + c.refundedAmountInCents,
        0
      ) / 100,
    totalForgivenessAmount:
      (regFormSubmissionBalance.balance.forgivenessAmountInCents +
        addOninvoices.reduce((r, c) => r + c.forgivenessAmountInCents, 0)) /
      100,
    totalRefundAmount:
      (regFormSubmissionBalance.balance.refundedAmountInCents +
        addOninvoices.reduce((r, c) => r + c.refundedAmountInCents, 0)) /
      100,
    totalServiceFee:
      (regFormSubmissionBalance.balance.serviceFeeInCents +
        addOninvoices.reduce((r, c) => r + c.serviceFeeInCents, 0)) /
      100
  };
};

const isOfflinePayment = (p) =>
  !p.regFormSubmissionInvoice &&
  (p.fkRegFormSubmissionPaymentType === 1 ||
    p.fkRegFormSubmissionPaymentType === 2 ||
    p.fkRegFormSubmissionPaymentType === 3 ||
    p.fkRegFormSubmissionPaymentType === 4);

const calculatePassedOnFeeInCents = (
  amountInCents,
  flatFeeInCents,
  percentageFee
) => {
  if (amountInCents <= 0) {
    return 0;
  }

  let serviceFee = flatFeeInCents;
  if (percentageFee) {
    serviceFee =
      (amountInCents + flatFeeInCents) / (1 - percentageFee) - amountInCents;
  }
  return serviceFee;
};

const getFormSubmissionAddonProductsPrice = (
  sections,
  regFormSubmissionAnswers,
  roleAnswers = []
) => {
  const productControls = sections.reduce(
    (r, c) => [
      ...r,
      ...c.regFormControls.filter(
        (fc) => fc.product || fc.options.some((op) => op.product)
      )
    ],
    []
  );

  const addOnsAmount = regFormSubmissionAnswers.reduce((r, c) => {
    const productControl = productControls.find(
      (p) => p.pkRegFormControl === c.fkRegFormControl
    );
    if (productControl) {
      const productPrice =
        productControl?.product?.price ||
        productControl.options.find((o) => o.option === c.answer)?.product
          ?.price;

      return r + (productPrice || 0);
    }
    return r;
  }, 0);

  return addOnsAmount + roleAnswers.reduce((r, c) => r + (c.price || 0), 0);
};

export {
  calculateCartTotals,
  calculateRegFormSubmissionBalanceTotals,
  isOfflinePayment,
  calculatePassedOnFeeInCents,
  getFormSubmissionAddonProductsPrice
};
