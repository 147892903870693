import React, { useState } from 'react';
import { Button, Checkbox } from '@mantine/core';
import PropTypes from 'prop-types';
import { AlertCircle, FileDownload } from 'tabler-icons-react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import Papa from 'papaparse';
import AppStack from '../../common/AppStack';
import LoadableView from '../../common/LoadableView';
import AppCard from '../../common/AppCard';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import ReportTable from './ReportTable';
import ResponsiveModal from '../../common/ResponsiveModal';
import FormSection from '../../common/FormSection';
import AppTitle from '../../common/AppTitle';

const ReportTableView = ({ isLoading, reportResult }) => {
  const mqIndex = useMediaQueryIndex();
  const [downloadModalState, setDownloadModalState] = useState({
    isOpen: false,
    withFilters: true
  });
  const [filterState, setFilterState] = useState({
    filters: [],
    orderByHeaderKey: 0,
    descendingSort: false,
    sortCondition: null
  });

  const timeGenerated = reportResult ? new Date() : null;

  const reportHeaders =
    reportResult?.report.headers.map((h, i) => ({
      ...h,
      selectOptions:
        h.inputType === 'select'
          ? [
              ...new Set(
                reportResult.report.rows.map((r) => r.columns[i]?.value ?? '')
              )
            ]
          : [],
      key: i
    })) || [];
  const reportRows =
    reportResult?.report.rows.map((r, i) => ({ ...r, key: i })) || [];

  const downloadCsv = (withFilters) => {
    const maxRowColumnLength = Math.max(
      ...reportRows.map((r) => r.columns.length)
    );

    const rows = withFilters
      ? reportRows
          .filter((f) =>
            filterState.filters.every((e) =>
              e.condition(f.columns[e.key].sortValue)
            )
          )
          .sort(filterState.sortCondition)
      : reportResult.report.multiReport
      ? reportRows
      : reportRows.sort(filterState.sortCondition);

    const csvRowData = [];
    rows.forEach((r) => {
      const rowData = [];
      if (r.topRowSpacing) {
        for (let i = 0; i < r.topRowSpacing; i += 1) {
          rowData.push([...Array(maxRowColumnLength).map((s) => '')]);
        }
      }

      rowData.push([...r.columns.map((v) => v.value)]);

      if (r.bottomRowSpacing) {
        for (let i = 0; i < r.bottomRowSpacing; i += 1) {
          rowData.push([...Array(maxRowColumnLength).map((s) => '')]);
        }
      }

      csvRowData.push(...rowData);
    });

    const csv = Papa.unparse({
      fields: [
        ...reportHeaders.map((h) => h.value),
        ...Array(maxRowColumnLength - reportHeaders.length).fill('')
      ],
      data: csvRowData
    });
    const blob = new Blob([csv]);
    const csvURL = URL.createObjectURL(blob, { type: 'text/plain' });
    const link = document.createElement('a');
    link.setAttribute('href', csvURL);
    link.setAttribute(
      'download',
      `${reportResult.report.name}-${dayjs(timeGenerated).format(
        'MM/DD/YYYY_h:mma'
      )}.csv`
    );
    document.body.appendChild(link);
    link.click();
  };

  return (
    <LoadableView isLoading={isLoading}>
      <AppStack style={{ height: 'calc(100vh - 90px)' }}>
        {!isLoading && (
          <AppStack style={{ gap: 0, flex: 1, height: '100%' }}>
            <AppCard
              radius={0}
              shadow="md"
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: rs(['unset', 'unset', 80], mqIndex),
                padding: rs([10, '10px 20px', '0px 20px'], mqIndex)
              }}
            >
              <AppFlexbox
                style={{
                  flex: 1,
                  flexDirection: rs(['column', 'row'], mqIndex),
                  alignItems: 'center'
                }}
              >
                <AppFlexbox
                  style={{
                    flex: 1,
                    justifyContent: rs(['center', 'start'], mqIndex)
                  }}
                >
                  <AppStack
                    style={{
                      gap: 0,
                      whiteSpace: rs(['nowrap', 'unset'], mqIndex),
                      alignItems: rs(['center', 'start'], mqIndex)
                    }}
                  >
                    <AppText weight={700}>{reportResult.report.name}</AppText>

                    <AppText>
                      {dayjs(timeGenerated).format('MM/DD/YYYY h:mm a')}
                    </AppText>
                  </AppStack>
                </AppFlexbox>
                <AppFlexbox
                  style={{
                    flex: 1,
                    justifyContent: rs(['center', 'unset'], mqIndex),
                    flexDirection: rs(['row', 'row-reverse'], mqIndex),
                    flexWrap: rs(['wrap', 'wrap', 'nowrap'], mqIndex)
                  }}
                >
                  <Button
                    color="blue"
                    disabled={reportResult.report.rows.length === 0}
                    leftSection={<FileDownload />}
                    onClick={() => {
                      if (
                        filterState.filters.length > 0 ||
                        filterState.descendingSort ||
                        filterState.orderByHeaderKey !== 0
                      ) {
                        setDownloadModalState({
                          ...downloadModalState,
                          isOpen: true,
                          withFilters: true
                        });
                      }
                      else {
                        downloadCsv(false);
                      }
                    }}
                    variant="filled"
                  >
                    Download
                  </Button>
                </AppFlexbox>
              </AppFlexbox>
            </AppCard>
            {reportResult.report.rows.length === 0 ? (
              <AppCard
                style={{
                  padding: 5,
                  alignSelf: 'center',
                  width: '100%',
                  flex: 1,
                  display: 'flex'
                }}
              >
                <AppStack
                  style={{
                    gap: 20,
                    padding: rs([20, 50], mqIndex),
                    placeItems: 'center',
                    justifyContent: 'center',
                    flex: 1
                  }}
                >
                  <AppFlexbox style={{ gap: 20, justifyContent: 'center' }}>
                    <AlertCircle color="red" size={45} />
                    <AppText style={{ textAlign: 'center' }}>
                      No data could be generated for the selected report.
                    </AppText>
                  </AppFlexbox>
                  <Button
                    color="blue"
                    component={Link}
                    style={{ maxWidth: 250, width: '100%' }}
                    to="/reports"
                  >
                    View Reports
                  </Button>
                </AppStack>
              </AppCard>
            ) : (
              <AppCard style={{ flex: 1, padding: 0, height: '100%' }}>
                <ReportTable
                  disableSort={reportResult.report.multiReport}
                  filterState={filterState}
                  reportHeaders={reportHeaders}
                  reportRows={reportRows}
                  setFilterState={setFilterState}
                />
              </AppCard>
            )}
          </AppStack>
        )}
      </AppStack>
      <ResponsiveModal
        isOpen={downloadModalState.isOpen}
        onClose={() =>
          setDownloadModalState({ ...downloadModalState, isOpen: false })
        }
        size={600}
        title={<AppTitle order={3}>Download Report</AppTitle>}
      >
        <FormSection
          onCancel={() =>
            setDownloadModalState({ ...downloadModalState, isOpen: false })
          }
          onSubmit={() => {
            downloadCsv(downloadModalState.withFilters);
            setDownloadModalState({ ...downloadModalState, isOpen: false });
          }}
          submitTitle="Download"
        >
          <AppStack style={{ gap: 30 }}>
            <Checkbox
              checked={downloadModalState.withFilters}
              label="Download with selected filters"
              onChange={(e) =>
                setDownloadModalState({
                  ...downloadModalState,
                  withFilters: e.target.checked
                })
              }
              style={{ fontWeight: 500, marginTop: 10 }}
            />
          </AppStack>
        </FormSection>
      </ResponsiveModal>
    </LoadableView>
  );
};

ReportTableView.propTypes = {
  isLoading: PropTypes.bool,
  reportResult: PropTypes.object
};

export default ReportTableView;
