import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Calendar, User } from 'tabler-icons-react';
import { Checkbox, Divider } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { Context as AuthContext } from '../../../providers/AuthProvider';
import { useModalState, useSortByFilter } from '../../../helpers/hooks';
import AppCard from '../../common/AppCard';
import TableView from '../../common/TableView';
import { REG_PERMISSION_ENUM } from '../../../config/constants';
import AdminModal from '../administrators/AdminModal';
import ConfirmModal from '../../common/ConfirmModal';
import AppText from '../../common/AppText';
import { triggerNotification } from '../../../helpers/notificationHelper';
import AppStack from '../../common/AppStack';

const VIEW_ACTIONS_ENUM = {
  create: 'CREATE',
  reinstate: 'REINSTATE',
  resend: 'RESEND',
  remove: 'REMOVE'
};

const VIEW_ACTIONS = [
  {
    label: 'Edit',
    value: VIEW_ACTIONS_ENUM.create,
    isVisible: (item) => !item.deleted
  },
  {
    label: 'Reinstate',
    value: VIEW_ACTIONS_ENUM.reinstate,
    isVisible: (item) => item.deleted && !!item.user
  },
  {
    label: 'Resend',
    value: VIEW_ACTIONS_ENUM.resend,
    isVisible: (item) => !item.user
  },
  {
    label: 'Revoke',
    value: VIEW_ACTIONS_ENUM.remove,
    isVisible: (item) => !item.deleted && !!item.user
  },
  {
    label: 'Expire',
    value: VIEW_ACTIONS_ENUM.remove,
    isVisible: (item) => !item.deleted && !item.user
  }
];

const TABLE_COLUMNS = [
  {
    label: 'Name',
    value: 'name',
    sortable: true
  },
  {
    label: 'Status',
    value: 'status',
    sortable: true
  }
];

const STATUS_OPTIONS = [
  { label: 'Active', value: 'active' },
  { label: 'Invited', value: 'invited' },
  { label: 'Expired', value: 'expired' },
  { label: 'Revoked', value: 'revoked' }
];

const EvaluatorListView = ({ isLoading, regAssociation, modalAction }) => {
  const { state: authState } = useContext(AuthContext);
  const {
    state,
    fetchRegAssociationAdministrators,
    respondToRegAssociationAdminInvite,
    updateRegAssociationAdministrator
  } = useContext(RegistrationAdminContext);
  const [confirmModalState, setConfirmModalState] = useState({
    isLoading: false,
    inviteExpires: false,
    expiresAt: null
  });
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const [filterState, setFilterState] = useState({
    status: null,
    search: ''
  });
  const {
    sortValue,
    isDescendingSort,
    onChangeSortBy,
    customFilterData
  } = useSortByFilter(TABLE_COLUMNS);

  const evaluatorAdmins = state.regAssociationAdmins.value.filter((a) =>
    a.regAssociationAdminPermissions.some(
      (p) => p.fkRegPermission === REG_PERMISSION_ENUM.EVALUATIONS
    )
  );

  useEffect(() => {
    if (modalAction === VIEW_ACTIONS_ENUM.create) {
      onOpenModal(modalAction);
    }
  }, [modalAction]);

  useEffect(() => {
    if (modalState.isOpen) {
      setConfirmModalState({
        isLoading: false,
        inviteExpires: false,
        expiresAt: null
      });
    }
  }, [modalState.isOpen]);

  return (
    <AppCard style={{ flex: 1, padding: 0, height: '100%' }}>
      <TableView
        columns={TABLE_COLUMNS}
        emptyMessage="No Evaluators Available"
        filters={[
          {
            key: 1,
            label: 'Status',
            type: 'select',
            value: filterState.status,
            placeholder: 'Filter by status',
            data: STATUS_OPTIONS,
            clearable: true,
            searchable: true,
            onChange: (value) => {
              setFilterState({
                ...filterState,
                status: value
              });
            }
          },
          {
            key: 2,
            label: 'Evaluator',
            type: 'text',
            value: filterState.search,
            placeholder: 'Filter by evaluator',
            onChange: (value) => {
              setFilterState((c) => ({
                ...c,
                search: value
              }));
            }
          }
        ]}
        isDescendingSort={isDescendingSort}
        isLoading={isLoading}
        lastUpdated={state.regAssociationAdmins.lastUpdated}
        onAction={(action, item) => {
          onOpenModal(
            action,
            state.regAssociationAdmins.value.find(
              (s) => s.pkRegAssociationAdmin === item.key
            )
          );
        }}
        onChangeSortBy={onChangeSortBy}
        onRefresh={() =>
          fetchRegAssociationAdministrators(regAssociation.pkRegAssociation, {includeDeleted: true})
        }
        rows={evaluatorAdmins
          .filter(
            (a) =>
              !filterState.search ||
              a.user?.name
                .toLowerCase()
                .includes(filterState.search.toLowerCase()) ||
              (a.user?.email || a.inviteSentTo)
                .toLowerCase()
                .includes(filterState.search.toLowerCase())
          )
          .map((a) => {
            const isExpired =
              a.inviteExpiresAt && new Date(a.inviteExpiresAt) < new Date();
            const statusInfo = a.user
              ? a.deleted
                ? { label: 'Revoked', color: 'red' }
                : { label: 'Active', color: 'green' }
              : a.deleted || isExpired
              ? { label: 'Expired', color: 'red' }
              : { label: 'Invited', color: 'blue' };

            return {
              key: a.pkRegAssociationAdmin,
              actions:
                a.user?.pkUser !== authState.userData.user.pkUser
                  ? VIEW_ACTIONS.filter((f) => f.isVisible(a))
                  : null,
              columns: [
                {
                  key: 1,
                  label: a.user?.name || 'Invite',
                  weight: 500,
                  icon: <User color="black" size="30" />,
                  onClick: () => onOpenModal(VIEW_ACTIONS_ENUM.create, a),
                  subLabel: a.user?.email || a.inviteSentTo,
                  onSubLabelClick: () =>
                    window.open(`mailto:${a.user?.email || a.inviteSentTo}`),
                  sortValue: `${a.user?.name || 'Invite'}${
                    a.user?.email || a.inviteSentTo
                  }`
                },
                {
                  key: 2,
                  label: statusInfo.label,
                  color: statusInfo.color
                }
              ]
            };
          })}
        sortBy={sortValue}
        sortFilter={customFilterData}
        tableTitle="Evaluators"
      />

      <AdminModal
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.create
        }
        onClose={onCloseModal}
        regAssociation={regAssociation}
        regAssociationAdmin={modalState.item}
        requiredPermissions={[REG_PERMISSION_ENUM.EVALUATIONS]}
        title={modalState.item ? 'Edit Evaluator' : 'Invite Evaluator'}
      />

      <ConfirmModal
        confirmActionColor="red"
        isLoading={confirmModalState.isLoading}
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.remove
        }
        message={
          modalState.item?.user ? (
            <AppText style={{ textAlign: 'center' }}>
              Are you sure you want to remove <b>{modalState.item.user.name}</b>{' '}
              as an evaluator?
            </AppText>
          ) : (
            <AppText style={{ textAlign: 'center' }}>
              Are you sure you want to expire the invite to{' '}
              <b>{modalState.item?.inviteSentTo}</b>?
            </AppText>
          )
        }
        onCancel={onCloseModal}
        onConfirm={() => {
          setConfirmModalState({ ...confirmModalState, isLoading: true });
          respondToRegAssociationAdminInvite(
            modalState.item.pkRegAssociationAdmin,
            { isAccepted: false },
            () => {
              triggerNotification(
                modalState.item.user ? 'Evaluator Revoked' : 'Invite Expired',
                'Success',
                'green'
              );
              onCloseModal();
            },
            (e) => {
              triggerNotification(e);
              setConfirmModalState({ ...confirmModalState, isLoading: false });
            }
          );
        }}
        title={modalState.item?.user ? 'Revoke Evaluator' : 'Expire Invite'}
      />

      <ConfirmModal
        confirmActionText={modalState.item?.user ? 'Reinstate' : 'Resend'}
        isLoading={confirmModalState.isLoading}
        isOpen={
          modalState.isOpen &&
          (modalState.action === VIEW_ACTIONS_ENUM.reinstate ||
            modalState.action === VIEW_ACTIONS_ENUM.resend)
        }
        message={
          modalState.item?.user ? (
            <AppText style={{ textAlign: 'center' }}>
              Are you sure you want to reinstate{' '}
              <b>{modalState.item.user.name}</b> as an evaluator?
            </AppText>
          ) : (
            <AppText style={{ textAlign: 'center' }}>
              Are you sure you want to resend the invite to{' '}
              <b>{modalState.item?.inviteSentTo}</b>?
            </AppText>
          )
        }
        onCancel={onCloseModal}
        onConfirm={() => {
          if (modalState.item?.user) {
            setConfirmModalState({ ...confirmModalState, isLoading: true });

            respondToRegAssociationAdminInvite(
              modalState.item.pkRegAssociationAdmin,
              { isAccepted: true },
              () => {
                triggerNotification('Evaluator Reinstated', 'Success', 'green');
                onCloseModal();
              },
              (e) => {
                triggerNotification(e);
                setConfirmModalState({
                  ...confirmModalState,
                  isLoading: false
                });
              }
            );
          }
          else if (
            confirmModalState.inviteExpires &&
            (!confirmModalState.expiresAt ||
              confirmModalState.expiresAt < new Date())
          ) {
            triggerNotification('Invalid Expiry Date');
          }
          else {
            setConfirmModalState({ ...confirmModalState, isLoading: true });

            updateRegAssociationAdministrator(
              [
                {
                  pkRegAssociationAdmin: modalState.item.pkRegAssociationAdmin,
                  sentTo: modalState.item.inviteSentTo,
                  inviteExpires: confirmModalState.inviteExpires,
                  expiresAt: confirmModalState.inviteExpires
                    ? new Date(confirmModalState.expiresAt)
                    : null,
                  permissions: modalState.item.regAssociationAdminPermissions.map(
                    (p) => ({
                      fkRegPermission: p.fkRegPermission,
                      regAssociationDivisions: p.regAssociationDivisions.map(
                        (m) => m.pkRegAssociationDivision.toString()
                      )
                    })
                  ),
                  resendInvite: true
                }
              ],
              () => {
                triggerNotification('Invite Sent', 'Success', 'green');
                onCloseModal();
              },
              (e) => {
                triggerNotification(e);
                setConfirmModalState({
                  ...confirmModalState,
                  isLoading: false
                });
              }
            );
          }
        }}
        title={
          modalState.item?.user ? 'Reinstate Evaluator' : 'Invite Evaluator'
        }
      >
        {modalState.item && !modalState.item.user && (
          <AppStack>
            <Divider />

            <Checkbox
              checked={confirmModalState.inviteExpires}
              disabled={confirmModalState.isLoading}
              label="Invite Expires"
              onChange={(e) =>
                setConfirmModalState({
                  ...confirmModalState,
                  inviteExpires: e.currentTarget.checked
                })
              }
              style={{ fontWeight: 500, marginTop: 20 }}
            />
            {confirmModalState.inviteExpires && (
              <DatePickerInput
                clearable
                disabled={confirmModalState.isLoading}
                label="Expires At"
                leftSection={<Calendar color="#000" size={16} />}
                minDate={new Date()}
                onChange={(value) =>
                  setConfirmModalState({
                    ...confirmModalState,
                    expiresAt: value
                  })
                }
                placeholder="MM/DD/YYYY"
                required
                value={confirmModalState.expiresAt}
                valueFormat="MM/DD/YYYY"
              />
            )}
          </AppStack>
        )}
      </ConfirmModal>
    </AppCard>
  );
};

EvaluatorListView.propTypes = {
  isLoading: PropTypes.bool,
  modalAction: PropTypes.string,
  regAssociation: PropTypes.object
};

export default EvaluatorListView;
