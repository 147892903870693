import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import ResponsiveModal from '../../common/ResponsiveModal';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import AppTitle from '../../common/AppTitle';
import RegistrationList from '../financials/RegistrationList';

const CouponRegistrationsModal = ({ isOpen, onClose, coupon, groupCoupon }) => {
  const { state, fetchAdminFinancialRegistrations } = useContext(
    RegistrationAdminContext
  );
  const regAssociation = state.regAssociations.value.find(
    (a) =>
      a.pkRegAssociation.toString() === coupon?.fkRegAssociation?.toString()
  );

  useEffect(() => {
    if (isOpen && (coupon || groupCoupon)) {
      fetchAdminFinancialRegistrations(regAssociation.pkRegAssociation, {
        coupon: coupon?.pkRegAssociationCoupon,
        groupCoupon: groupCoupon?.pkRegAssociationGroupCoupon
      });
    }
  }, [coupon, groupCoupon]);

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClose}
      size={1100}
      title={<AppTitle order={2}>Redeemed Coupon registrations</AppTitle>}
    >
      <RegistrationList
        isLoading={state.regFormSubmissions.loading}
        regAssociation={regAssociation}
        regFormSubmissions={state.regFormSubmissions.value.sort(
          (a, b) =>
            new Date(b.submittedAt) - new Date(a.submittedAt) ||
            `${a.regPerson.firstName}${a.regPerson.lastName}`.localeCompare(
              `${b.regPerson.firstName}${b.regPerson.lastName}`
            )
        )}
      />
    </ResponsiveModal>
  );
};

CouponRegistrationsModal.propTypes = {
  coupon: PropTypes.object,
  groupCoupon: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  pkRegAssociation: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default CouponRegistrationsModal;
