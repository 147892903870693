import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Context as AuthContext } from '../../../providers/AuthProvider';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import { theme as sportsHeadzTheme } from '../../../styles/theme';
import { formatUtcDate, isDarkText } from '../../../helpers/format';
import RegisterFormModal from './RegisterFormModal';
import RegistrationsList from './RegistrationsList';
import AssociationSidebar from './AssociationSidebar';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import AppStack from '../../common/AppStack';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import LoadableView from '../../common/LoadableView';
import AppFlexbox from '../../common/AppFlexbox';
import AppCard from '../../common/AppCard';
import AssociationSocials from './AssociationSocials';
import { getInelligibleRegPersonReason } from '../../../helpers/registrationHelper';
import MerchPackageShowcase from '../merch/MerchPackageShowcase';
import AppTitle from '../../common/AppTitle';
import { ANALYTIC_MERCH_PACKAGE_SHOWCASE_LOCATION_ENUM } from '../../../config/constants';

const REGISTRATION_SORT_FUNCTIONS = {
  1: (a, b) => new Date(a.regForm.endDate) - new Date(b.regForm.endDate),
  2: (a, b) =>
    new Date(b.regAssociationDivision.minDob) -
    new Date(a.regAssociationDivision.minDob),
  4: (a, b) =>
    (a.titleOverride ?? a.regForm.name).localeCompare(
      b.titleOverride ?? b.regForm.name
    ),
  5: (a, b) => a.regProduct.price - b.regProduct.price,
  6: (a, b) =>
    a.regAssociationDivision.name.localeCompare(b.regAssociationDivision.name)
};

const AssociationDetails = () => {
  const mqIndex = useMediaQueryIndex();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const hasFetched = useRef(false);
  const sandboxModeCheckedFor = useRef(false);
  const {
    state,
    fetchRegAssociation,
    fetchMerchPackages,
    fetchRegistrants
  } = useContext(RegistrationContext);
  const { state: authState } = useContext(AuthContext);
  const { pkRegAssociation } = useParams();
  const [selectedDivisionFormState, setSelectedDivisionFormState] = useState({
    isOpen: false,
    divisionForm: null
  });
  const regAssociation =
    state.regAssociation.value?.pkRegAssociation.toString() === pkRegAssociation
      ? state.regAssociation.value
      : null;
  const associationSocials =
    regAssociation?.socials.filter((s) => s.value) ?? [];
  const associationSidebars =
    regAssociation?.sidebars.filter((s) => s.visible) ?? [];
  const openRegForms =
    state.regAssociation.value?.regAssociationDivisionForms
      .filter(
        (f) =>
          !f.regForm.deleted &&
          new Date(formatUtcDate(f.regForm.startDate)) <= new Date() &&
          new Date(formatUtcDate(f.regForm.endDate)) >= new Date()
      )
      .sort(
        REGISTRATION_SORT_FUNCTIONS[
          state.regAssociation.value.fkRegFormSortType
        ] ?? REGISTRATION_SORT_FUNCTIONS['1']
      ) ?? [];
  const closedRegForms =
    state.regAssociation.value?.regAssociationDivisionForms
      .filter(
        (f) =>
          !f.regForm.deleted &&
          (new Date(formatUtcDate(f.regForm.endDate)) < new Date() ||
            new Date(formatUtcDate(f.regForm.startDate)) > new Date())
      )
      .sort(
        REGISTRATION_SORT_FUNCTIONS[
          state.regAssociation.value.fkRegFormSortType
        ] ?? REGISTRATION_SORT_FUNCTIONS['1']
      ) ?? [];

  const relevantRegistrations = authState.isAuthenticated
    ? openRegForms.filter((f) =>
        state.regRegistrants.value.some(
          (r) => !getInelligibleRegPersonReason(r, f)
        )
      )
    : [];

  const primaryColor = regAssociation?.primaryColor ?? sportsHeadzTheme.black;
  const secondaryColor =
    regAssociation?.secondaryColor ?? sportsHeadzTheme.colors.blue[7];
  const primaryTextColor = isDarkText(primaryColor) ? '#000' : '#FFF';
  const secondaryTextColor = isDarkText(secondaryColor) ? '#000' : '#FFF';

  const isLoading =
    !hasFetched.current ||
    state.regAssociation.loading ||
    !sandboxModeCheckedFor.current;

  useEffect(() => {
    if (pkRegAssociation) {
      fetchRegAssociation(
        pkRegAssociation,
        () => {},
        () => navigate('/leagues')
      );
      hasFetched.current = true;
    }
  }, [pkRegAssociation]);

  useEffect(() => {
    if (regAssociation && authState.tokenAttempted) {
      if (regAssociation.merchEnabled) {
        fetchMerchPackages([pkRegAssociation]);
      }

      if (
        regAssociation.sandboxEnabled &&
        !authState.userData?.isRegistrationAdmin
      ) {
        navigate('/leagues');
      }
      fetchRegistrants();
      sandboxModeCheckedFor.current = true;
    }
  }, [regAssociation, authState.tokenAttempted]);

  return (
    <LoadableView isLoading={isLoading}>
      {!isLoading && (
        <>
          {regAssociation.heroImageUrl && (
            <AppStack
              style={{
                flex: 1,
                backgroundImage: `url(${regAssociation.heroImageUrl})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                width: '100%',
                maxWidth: 1500,
                minHeight: rs([70, 125, 150, 215, 250], mqIndex),
                maxHeight: rs([70, 125, 150, 215, 250], mqIndex),
                alignSelf: 'center'
              }}
            />
          )}
          <AppFlexbox
            style={{
              flex: 1,
              marginTop: rs([0, 0, 0, 0, 20], mqIndex),
              justifyContent: 'center'
            }}
          >
            <AppStack
              style={{
                flex: 1,
                maxWidth: 1500,
                margin: rs([0, 0, 0, 10, 20], mqIndex)
              }}
            >
              <AppFlexbox
                style={{
                  flex: 1,
                  placeItems: 'start',
                  gap: rs([10, 10, 10, 10, 40], mqIndex),
                  alignItems: 'stretch',
                  flexDirection: rs(
                    ['column', 'column', 'column', 'row'],
                    mqIndex
                  )
                }}
              >
                {(associationSocials.length > 0 ||
                  associationSidebars.length > 0) && (
                  <AppStack
                    style={{
                      flex: 1,
                      minWidth: rs(['unset', 'unset', 200], mqIndex)
                    }}
                  >
                    {associationSocials.length > 0 && (
                      <AssociationSocials
                        primaryColor={primaryColor}
                        primaryTextColor={primaryTextColor}
                        regAssociation={regAssociation}
                        socials={associationSocials}
                      />
                    )}
                    {associationSidebars.length > 0 && (
                      <AppStack visibleFrom="sm">
                        {associationSidebars
                          .sort((a, b) => a.order - b.order)
                          .map((s, index) => (
                            <AssociationSidebar
                              key={s.pkRegAssociationSidebar}
                              headerColor={
                                index % 2 === 0 ? secondaryColor : primaryColor
                              }
                              sidebar={s}
                            />
                          ))}
                      </AppStack>
                    )}
                  </AppStack>
                )}
                <AppStack style={{ flex: 3 }}>
                  {regAssociation.merchEnabled && (
                    <AppCard
                      shadow="lg"
                      style={{ padding: '20px 35px', maxWidth: '100vw' }}
                    >
                      <AppStack>
                        <AppTitle>Available Merch</AppTitle>
                        <MerchPackageShowcase
                          analyticCta={
                            ANALYTIC_MERCH_PACKAGE_SHOWCASE_LOCATION_ENUM.ASSOCIATION_PAGE_HEADER
                          }
                          regAssociation={regAssociation}
                        />
                      </AppStack>
                    </AppCard>
                  )}

                  {relevantRegistrations.length > 0 && (
                    <AppCard shadow="lg" style={{ padding: 0 }}>
                      <RegistrationsList
                        onSelectRegForm={(divisionForm) =>
                          setSelectedDivisionFormState({
                            isOpen: true,
                            divisionForm
                          })
                        }
                        primaryColor={primaryColor}
                        primaryTextColor={primaryTextColor}
                        regAssociationDivisionForms={
                          regAssociation?.isSortDescending
                            ? relevantRegistrations.reverse()
                            : relevantRegistrations
                        }
                        secondaryColor={secondaryColor}
                        secondaryTextColor={secondaryTextColor}
                        title="Relevant Registrations"
                        titleTooltip="Relevant registrations for your created participants"
                      />
                    </AppCard>
                  )}

                  <AppCard shadow="lg" style={{ padding: 0 }}>
                    <RegistrationsList
                      onSelectRegForm={(divisionForm) => {
                        !authState.isAuthenticated
                          ? navigate(`/auth/login?redirect=${pathname}`)
                          : setSelectedDivisionFormState({
                              isOpen: true,
                              divisionForm
                            });
                      }}
                      primaryColor={primaryColor}
                      primaryTextColor={primaryTextColor}
                      regAssociationDivisionForms={
                        regAssociation?.isSortDescending
                          ? openRegForms.reverse()
                          : openRegForms
                      }
                      secondaryColor={secondaryColor}
                      secondaryTextColor={secondaryTextColor}
                      title="Open Registrations"
                    />
                  </AppCard>
                  {closedRegForms.length > 0 && (
                    <AppCard shadow="lg" style={{ padding: 0 }}>
                      <RegistrationsList
                        disabled
                        primaryColor={primaryColor}
                        primaryTextColor={primaryTextColor}
                        regAssociationDivisionForms={
                          regAssociation?.isSortDescending
                            ? closedRegForms.reverse()
                            : closedRegForms
                        }
                        secondaryColor={secondaryColor}
                        secondaryTextColor={secondaryTextColor}
                        title="Closed Registrations"
                      />
                    </AppCard>
                  )}
                </AppStack>

                {associationSidebars.length > 0 && (
                  <AppStack hiddenFrom="sm" style={{ gap: 10 }}>
                    {associationSidebars
                      .sort((a, b) => a.order - b.order)
                      .map((s, index) => (
                        <AssociationSidebar
                          key={s.pkRegAssociationSidebar}
                          headerColor={
                            index % 2 === 0 ? secondaryColor : primaryColor
                          }
                          sidebar={s}
                        />
                      ))}
                  </AppStack>
                )}
              </AppFlexbox>
            </AppStack>
          </AppFlexbox>

          <RegisterFormModal
            isOpen={selectedDivisionFormState.isOpen}
            onClose={() =>
              setSelectedDivisionFormState({
                ...selectedDivisionFormState,
                isOpen: false
              })
            }
            regAssociation={regAssociation}
            regAssociationDivisionForm={selectedDivisionFormState.divisionForm}
          />
        </>
      )}
    </LoadableView>
  );
};

export default AssociationDetails;
