import React, { useContext, useEffect, useState } from 'react';
import { Skeleton } from '@mantine/core';
import { PropTypes } from 'prop-types';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import RegistrationForm from './RegistrationForm';
import { dataUrlToFile, uploadFileToStorage } from '../../../helpers/awsHelper';
import { triggerNotification } from '../../../helpers/notificationHelper';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppTitle from '../../common/AppTitle';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import RegistrationFormSkeleton from './RegistrationFormSkeleton';

const ViewRegistrationFormModal = ({
  pkRegFormSubmission,
  isOpen,
  onClose,
  error,
  isDisabled,
  isLoading,
  isViewable,
  isViewerAdmin,
  onSubmit,
  showMerchPackageShowcase,
  merchPackageRegAssociation
}) => {
  const mqIndex = useMediaQueryIndex();
  const [isUploading, setIsUploading] = useState(false);
  const { state, fetchRegFormSubmission } = useContext(RegistrationContext);

  const formSubmission =
    pkRegFormSubmission === state.regFormSubmission.value?.pkRegFormSubmission
      ? state.regFormSubmission.value
      : null;

  useEffect(() => {
    if (isOpen && !formSubmission) {
      fetchRegFormSubmission(pkRegFormSubmission);
    }
  }, [isOpen, formSubmission]);

  const uploadFileContentRecursively = (fileControls, formData, extraData) => {
    let isFileUploading = false;
    for (const control of fileControls) {
      const answer = formData.regFormSubmissionAnswers.find(
        (a) => a.fkRegFormControl === control.pkRegFormControl
      );
      if (answer?.answer) {
        if (
          control.type === 'SIGNATURE' &&
          answer.answer?.startsWith('data:image/png;base64,')
        ) {
          dataUrlToFile(
            answer.answer,
            `signature.png`,
            (file) => {
              uploadFileToStorage(
                `signature-pkRegFormSubmission-${pkRegFormSubmission}-pkRegFormControl-${answer.fkRegFormControl}`,
                file,
                (location) => {
                  answer.answer = location;
                  uploadFileContentRecursively(fileControls, formData);
                },
                triggerNotification
              );
            },
            triggerNotification
          );

          isFileUploading = true;
          break;
        }
        else if (
          control.type === 'FILE_UPLOAD' &&
          typeof answer.answer === 'object'
        ) {
          uploadFileToStorage(
            `${answer.answer.name.split('.')[0]}-`,
            answer.answer,
            (location) => {
              answer.answer = location;
              uploadFileContentRecursively(fileControls, formData);
            },
            triggerNotification
          );
          isFileUploading = true;
          break;
        }
      }
    }

    if (!isFileUploading) {
      onSubmit(formData, extraData);
      setIsUploading(false);
    }
  };

  const onSubmitFormContent = (formData, extraData) => {
    const controls =
      formSubmission?.regAssociationDivisionForm.regForm.regFormSections.reduce(
        (result, current) => result.concat(current.regFormControls),
        []
      ) ?? [];

    const fileControls = controls.filter(
      (c) => c.type === 'SIGNATURE' || c.type === 'FILE_UPLOAD'
    );
    if (fileControls.length > 0) {
      setIsUploading(true);
    }

    uploadFileContentRecursively(fileControls, formData, extraData);
  };

  return (
    <ResponsiveModal
      onClose={onClose}
      opened={isOpen}
      sidePadding={15}
      size={900}
      title={
        !formSubmission ? (
          <Skeleton
            height={36}
            style={{ margin: rs([0, 'auto'], mqIndex) }}
            width="75%"
          />
        ) : (
          <AppTitle
            style={{ flex: 1, textAlign: rs(['start', 'center'], mqIndex) }}
          >
            {formSubmission.regAssociationDivisionForm.regForm.name}
          </AppTitle>
        )
      }
    >
      {!formSubmission ? (
        <RegistrationFormSkeleton
          showSubmitButton={isViewerAdmin || !isDisabled}
        />
      ) : (
        <RegistrationForm
          assignedRoles={
            formSubmission.regAssociationDivisionForm.assignedRoles
          }
          divisionRoles={
            formSubmission.regAssociationDivisionForm.regAssociationDivision
              .roles
          }
          error={error}
          form={formSubmission.regAssociationDivisionForm.regForm}
          isDisabled={isDisabled}
          isLoading={isLoading || isUploading}
          isViewable={isViewable}
          isViewerAdmin={isViewerAdmin}
          merchPackageRegAssociation={merchPackageRegAssociation}
          onCancel={onClose}
          onSubmit={onSubmitFormContent}
          regFormSubmission={formSubmission}
          sections={
            formSubmission.regAssociationDivisionForm.regForm.regFormSections
          }
          showMerchPackageShowcase={showMerchPackageShowcase}
        />
      )}
    </ResponsiveModal>
  );
};

ViewRegistrationFormModal.propTypes = {
  error: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  isViewable: PropTypes.bool,
  isViewerAdmin: PropTypes.bool,
  merchPackageRegAssociation: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  pkRegFormSubmission: PropTypes.number,
  showMerchPackageShowcase: PropTypes.bool
};

export default ViewRegistrationFormModal;
