import React, { useContext, useEffect, useState } from 'react';
import { Plus, User } from 'tabler-icons-react';
import PropTypes from 'prop-types';
import { Button } from '@mantine/core';
import { useModalState } from '../../../helpers/hooks';
import TableView from '../../common/TableView';
import EvaluationPlayerModal from './EvaluationPlayerModal';
import {
  getEvaluationEntryProgress,
  getEvaluationRating
} from '../../../helpers/evaluationPlayerHelper';
import { REG_SCOUT_REPORT_QUESTION_TYPE } from '../../../config/constants';
import PlayerStatsModal from '../players/PlayerStatsModal';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';

const VIEW_ACTIONS_ENUM = {
  view: 'VIEW',
  create: 'CREATE',
  delete: 'DELETE'
};

const VIEW_ACTIONS = [
  {
    label: 'View',
    value: VIEW_ACTIONS_ENUM.view,
    completedEvalOnly: true
  },
  {
    label: 'Edit',
    value: VIEW_ACTIONS_ENUM.create,
    isCustomOnly: true
  },
  {
    label: 'Delete',
    value: VIEW_ACTIONS_ENUM.delete,
    isCustomOnly: true
  }
];

const TABLE_COLUMNS = [
  {
    label: 'Name',
    value: 'name',
    sortable: true,
    requiresNamePermissions: true
  },
  {
    label: 'Jersey Number',
    value: 'jerseyNumber',
    sortable: true
  },
  {
    label: 'Jersey Color',
    value: 'jerseyColor',
    sortable: true
  },
  {
    label: 'Gender',
    value: 'gender',
    sortable: true
  },
  {
    label: 'Overall Score',
    value: 'overallRating',
    sortable: true
  }
];

const EvaluationSessionPlayerSelect = ({
  players,
  fkRegAssociationDivision,
  isLoading,
  onRefresh,
  sortValue,
  isDescendingSort,
  onChangeSortBy,
  sortFilter,
  selectedPlayers,
  onSelectPlayers,
  isCustomPerson,
  modalAction,
  attributes,
  regScoutReportEvaluations,
  userEvaluationSessionEntry,
  regScoutReportEvaluationSession,
  regScoutReportQuestions,
  regAssociation
}) => {
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const { state } = useContext(RegistrationAdminContext);
  const [filterState, setFilterState] = useState({ search: '' });

  const completedEvaluationEntries = regScoutReportEvaluationSession?.regScoutReportEvaluationSessionEntries?.filter(
    (entry) =>
      getEvaluationEntryProgress(entry, regScoutReportEvaluationSession) >= 100
  );
  const metricQuestions = regScoutReportQuestions.filter(
    (q) =>
      q.fkRegScoutReportQuestionType === REG_SCOUT_REPORT_QUESTION_TYPE.METRIC
  );

  useEffect(() => {
    if (modalAction === VIEW_ACTIONS_ENUM.create) {
      onOpenModal(modalAction);
    }
  }, [modalAction]);

  return (
    <>
      <TableView
        columns={[
          ...TABLE_COLUMNS.filter(
            (f) =>
              isLoading ||
              !f.requiresNamePermissions ||
              !userEvaluationSessionEntry ||
              userEvaluationSessionEntry.canViewPlayerName
          ),
          ...attributes.map((a) => ({
            label: a.value,
            value: `${a.value}-${a.pkRegScoutReportQuestion}`,
            sortable: true
          }))
        ]}
        emptyActionButton={
          isCustomPerson && !userEvaluationSessionEntry ? (
            <Button
              leftSection={<Plus />}
              onClick={() => onOpenModal(VIEW_ACTIONS_ENUM.create)}
            >
              Create Player
            </Button>
          ) : null
        }
        emptyMessage="No Players Available"
        filters={
          !userEvaluationSessionEntry ||
          userEvaluationSessionEntry?.canViewPlayerName
            ? [
                {
                  key: 1,
                  label: 'Name',
                  type: 'text',
                  value: filterState.search,
                  placeholder: 'Filter by name',
                  onChange: (value) => {
                    setFilterState((c) => ({
                      ...c,
                      search: value
                    }));
                  }
                }
              ]
            : null
        }
        isDescendingSort={isDescendingSort}
        isLoading={isLoading}
        lastUpdated={state.regScoutReportEvaluationPlayers.lastUpdated}
        onAction={(action, item) => {
          onOpenModal(
            action,
            players.find((s) => s.person.entityId === item.key)
          );
        }}
        onChangeSortBy={onChangeSortBy}
        onRefresh={onRefresh}
        onSelect={
          isLoading ||
          !userEvaluationSessionEntry ||
          userEvaluationSessionEntry.canSelectPlayers
            ? onSelectPlayers
            : null
        }
        rows={players
          .filter(
            (p) =>
              (!userEvaluationSessionEntry ||
                userEvaluationSessionEntry.canSelectPlayers ||
                selectedPlayers.includes(p.person.entityId)) &&
              (!filterState.search ||
                `${p.person.firstName} ${p.person.lastName}`
                  .toLowerCase()
                  .includes(filterState.search.toLowerCase()) ||
                p.person.email
                  .toLowerCase()
                  .includes(filterState.search.toLowerCase()))
          )
          .map((p) => {
            const evaluations = regScoutReportEvaluations.filter(
              (e) =>
                e.person.entityId === p.person.entityId &&
                e.person.isCustomPerson === isCustomPerson
            );
            const evaluation =
              evaluations.find((e) => !e.deleted) || evaluations[0];

            const evalRating =
              evaluation && completedEvaluationEntries.length > 0
                ? getEvaluationRating(
                    evaluation,
                    metricQuestions,
                    completedEvaluationEntries
                  )
                : {};

            return {
              key: p.person.entityId,
              actions: VIEW_ACTIONS.filter(
                (f) =>
                  (!f.isCustomOnly || p.person.isCustomPerson) &&
                  (!f.completedEvalOnly || evalRating?.overallRating > 0)
              ),
              columns: [
                ...(!userEvaluationSessionEntry ||
                userEvaluationSessionEntry.canViewPlayerName
                  ? [
                      {
                        key: 1,
                        label: `${p.person.firstName} ${p.person.lastName}`,
                        subLabel: p.person.email,
                        weight: 500,
                        icon: <User color="black" size="30" />
                      }
                    ]
                  : []),
                {
                  key: 2,
                  label: evaluation?.jerseyNumber,
                  sortValueType: 'number'
                },
                {
                  key: 3,
                  label: evaluation?.jerseyColor
                },
                {
                  key: 4,
                  label: p.person.gender
                },
                {
                  key: 5,
                  label: evalRating?.overallRating
                    ? Math.round(evalRating.overallRating * 10) / 10
                    : '',
                  sortValueType: 'number'
                },
                ...attributes.map((a, i) => {
                  const answerValue = evaluation?.regScoutReportQuestionAnswers.find(
                    (f) =>
                      f.fkRegScoutReportQuestion === a.pkRegScoutReportQuestion
                  )?.value;

                  const label = answerValue
                    ? a.regScoutReportQuestionOptions.length > 0
                      ? a.regScoutReportQuestionOptions?.find(
                          (f) => f.value === answerValue
                        )?.value || ''
                      : answerValue
                    : '';

                  return {
                    key: i + 6,
                    label
                  };
                })
              ]
            };
          })}
        selectedRows={selectedPlayers}
        sortBy={sortValue}
        sortFilter={sortFilter}
      />

      <PlayerStatsModal
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.view
        }
        onClose={onCloseModal}
        player={modalState.item}
        regAssociation={regAssociation}
        regScoutReportEvaluationSessions={[regScoutReportEvaluationSession]}
      />

      <EvaluationPlayerModal
        fkRegAssociationDivision={fkRegAssociationDivision}
        isOpen={
          modalState.isOpen && modalState.action !== VIEW_ACTIONS_ENUM.view
        }
        onClose={onCloseModal}
        pkRegAssociation={regAssociation?.pkRegAssociation}
        regScoutReportPerson={modalState.item}
        showDelete={modalState.action === VIEW_ACTIONS_ENUM.delete}
      />
    </>
  );
};

EvaluationSessionPlayerSelect.propTypes = {
  attributes: PropTypes.array,
  fkRegAssociationDivision: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  isCustomPerson: PropTypes.bool,
  isDescendingSort: PropTypes.bool,
  isLoading: PropTypes.bool,
  modalAction: PropTypes.string,
  onChangeSortBy: PropTypes.func,
  onRefresh: PropTypes.func,
  onSelectPlayers: PropTypes.func,
  players: PropTypes.array,
  regAssociation: PropTypes.object,
  regScoutReportEvaluationSession: PropTypes.object,
  regScoutReportEvaluations: PropTypes.array,
  regScoutReportQuestions: PropTypes.array,
  selectedPlayers: PropTypes.array,
  sortFilter: PropTypes.object,
  sortValue: PropTypes.string,
  userEvaluationSessionEntry: PropTypes.object
};

export default EvaluationSessionPlayerSelect;
