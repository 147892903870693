import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeft, X } from 'tabler-icons-react';
import { ActionIcon, Burger, Checkbox, Drawer, Skeleton } from '@mantine/core';
import { Context as AuthContext } from '../../../providers/AuthProvider';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import AppFlexbox from '../../common/AppFlexbox';
import ActionableIcon from '../../common/ActionableIcon';
import AppText from '../../common/AppText';
import AppImage from '../../common/AppImage';
import ColoredAvatar from '../../common/ColoredAvatar';
import EvaluationSessionEvaluatePlayersView from './EvaluationSessionEvaluatePlayersView';
import EvaluationSessionEvaluateCriterionView from './EvaluationSessionEvaluateCriterionView';
import EvaluationSessionEvaluateGridView from './EvaluationSessionEvaluateGridView';
import HeaderAccountNavigationMenu from '../navigation/HeaderAccountNavigationMenu';
import { useRegAssociationBranding } from '../../../helpers/styles';
import { useEvaluationSessionEntryState } from '../../../helpers/evaluationHelper';
import { triggerNotification } from '../../../helpers/notificationHelper';
import { REG_SCOUT_REPORT_QUESTION_TYPE } from '../../../config/constants';
import { usePrompt } from '../../../helpers/prompt';
import ConfirmModal from '../../common/ConfirmModal';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import EvaluationSessionEvaluateMenuNav from './EvaluationSessionEvaluateMenuNav';

const EVALUATION_SESSION_VIEWS_ENUM = {
  player: 'SCORE_BY_PLAYER',
  criterion: 'SCORE_BY_CRITERION',
  grid: 'SCORING_GRID'
};

const EvaluationSessionEvaluateView = () => {
  const mqIndex = useMediaQueryIndex();
  const isMobileView = mqIndex === 0;
  const isTabletView = mqIndex <= 1;
  const isMediumView = mqIndex <= 2;
  const navigate = useNavigate();
  const {
    pkRegScoutReportEvaluationSession,
    pkRegScoutReportEvaluationSessionEntry
  } = useParams();
  const { state: authState } = useContext(AuthContext);
  const [viewState, setViewState] = useState({
    currentView: EVALUATION_SESSION_VIEWS_ENUM.player,
    dontShowCompletedConfirmation: false,
    showCompleteModal: false,
    navDrawerOpen: false,
    filter: {
      search: '',
      sortBy: 'number',
      hideCompleted: false,
      expandAll: false
    }
  });
  const {
    isLoading,
    isSaving,
    hasUnsavedChanges,
    regScoutReportEvaluationSession,
    regScoutReports,
    regScoutReportQuestions,
    regScoutReportEvaluations,
    selectedEvaluationSessionEntry,
    selectedRegScoutReportEvaluation,
    selectedRegScoutReportQuestion,
    onUpdateEvaluations,
    onSelectEvaluation,
    onSelectQuestion,
    onUpdateEvaluationAnswer,
    onSaveChanges
  } = useEvaluationSessionEntryState(
    pkRegScoutReportEvaluationSession,
    pkRegScoutReportEvaluationSessionEntry
  );

  const regAssociation = regScoutReports[0]?.regAssociation;
  const {
    headerImage,
    primaryColor,
    secondaryColor,
    isPrimaryDarkText,
    isSecondaryDarkText,
    lightMode
  } = useRegAssociationBranding(regAssociation);

  const metricQuestions = regScoutReportQuestions.filter(
    (q) =>
      q.fkRegScoutReportQuestionType === REG_SCOUT_REPORT_QUESTION_TYPE.METRIC
  );

  const isSelectedEvaluationCompleted =
    selectedRegScoutReportEvaluation &&
    metricQuestions.every(
      (q) =>
        !!selectedRegScoutReportEvaluation.regScoutReportQuestionAnswers.find(
          (a) =>
            a.fkRegScoutReportQuestion === q.pkRegScoutReportQuestion &&
            a.fkRegScoutReportEvaluationSessionEntry ===
              selectedEvaluationSessionEntry.pkRegScoutReportEvaluationSessionEntry
        )?.value
    );

  const evaluationCompleted =
    regScoutReportEvaluations.length > 0 &&
    regScoutReportEvaluations.every((evaluation) =>
      metricQuestions.every(
        (q) =>
          !!evaluation.regScoutReportQuestionAnswers.find(
            (a) =>
              a.fkRegScoutReportQuestion === q.pkRegScoutReportQuestion &&
              a.fkRegScoutReportEvaluationSessionEntry ===
                selectedEvaluationSessionEntry.pkRegScoutReportEvaluationSessionEntry
          )?.value
      )
    );

  useEffect(() => {
    if (
      !hasUnsavedChanges &&
      evaluationCompleted &&
      !viewState.dontShowCompletedConfirmation
    ) {
      setViewState({ ...viewState, showCompleteModal: true });
    }
  }, [hasUnsavedChanges]);

  usePrompt(
    'Are you sure you want to leave without saving?',
    !isSaving && hasUnsavedChanges
  );

  const onViewChange = (view) => {
    if (view !== viewState.currentView) {
      setViewState({
        ...viewState,
        currentView: view,
        filter: {
          ...viewState.filter,
          search: ''
        }
      });
    }
  };

  const onSaveEvaluationSessionChanges = () => {
    onSaveChanges(
      () => {
        triggerNotification('Changes Saved!', 'Success', 'green');
      },
      (error) => {
        triggerNotification(error);
      }
    );
  };

  return (
    <AppStack style={{ flex: 1, gap: 0, backgroundColor: '#f7f7f7' }}>
      <AppCard
        style={{
          padding: 0,
          height: 80,
          backgroundColor: primaryColor,
          color: isPrimaryDarkText ? '#000' : '#FFF',
          borderRadius: 0
        }}
      >
        <AppFlexbox
          style={{
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px 20px',
            gap: 0
          }}
        >
          {isLoading ? (
            <>
              <AppFlexbox
                hiddenFrom="md"
                style={{ gap: 15, alignItems: 'center' }}
              >
                <Skeleton height={34} width={34} />
              </AppFlexbox>

              <AppFlexbox
                style={{ gap: 15, alignItems: 'center' }}
                visibleFrom="md"
              >
                <Skeleton circle height={55} width={55} />
                <AppStack style={{ gap: 5 }}>
                  <Skeleton height={24} width={200} />
                  <Skeleton height={16} width={150} />
                </AppStack>
              </AppFlexbox>

              <AppFlexbox
                style={{ gap: 15, alignItems: 'center' }}
                visibleFrom="md"
              >
                <AppStack style={{ gap: 5, alignItems: 'end' }}>
                  <Skeleton height={24} width={200} />
                  <Skeleton height={16} width={150} />
                </AppStack>
                <Skeleton circle height={55} width={55} />
              </AppFlexbox>
            </>
          ) : (
            <>
              <AppFlexbox hiddenFrom="md">
                <Drawer
                  onClose={() => {
                    setViewState({
                      ...viewState,
                      navDrawerOpen: false
                    });
                  }}
                  opened={viewState.navDrawerOpen}
                  size="85%"
                  styles={{
                    body: {
                      padding: 0,
                      backgroundColor: '#f7f7f7',
                      height: '100%'
                    },
                    content: { maxWidth: 500 }
                  }}
                  withCloseButton={false}
                >
                  <AppStack style={{ gap: 0 }}>
                    <AppFlexbox
                      style={{
                        gap: 15,
                        alignItems: 'center',
                        justifyContent: 'space-betweem',
                        backgroundColor: primaryColor,
                        color: isPrimaryDarkText ? '#000' : '#FFF',
                        height: 60,
                        padding: 10
                      }}
                    >
                      <AppFlexbox
                        style={{
                          flex: 1,
                          gap: 15,
                          alignItems: 'center',
                          overflow: 'hidden'
                        }}
                      >
                        <ActionableIcon
                          color={secondaryColor}
                          onClick={() =>
                            navigate(
                              `/admin/evaluations/sessions/${regScoutReportEvaluationSession.pkRegScoutReportEvaluationSession}`
                            )
                          }
                          radius={100}
                          size={35}
                          style={{color: isSecondaryDarkText ? '#000' : '#FFF'}}
                          variant="filled"
                        >
                          <ArrowLeft size={25} />
                        </ActionableIcon>
                        <AppStack
                          style={{
                            gap: 0,
                            overflow: 'hidden'
                          }}
                        >
                          <AppText
                            style={{
                              fontSize: 18,
                              lineHeight: '22px',
                              textWrap: 'noWrap'
                            }}
                            weight={500}
                          >
                            {regScoutReportEvaluationSession.name}
                          </AppText>
                          <AppText
                            style={{
                              color: isPrimaryDarkText ? '#000' : '#e2e8f0',
                              textWrap: 'noWrap'
                            }}
                          >
                            {
                              regScoutReportEvaluationSession
                                .regAssociationDivision.name
                            }
                          </AppText>
                        </AppStack>
                      </AppFlexbox>

                      <ActionIcon
                        color={isPrimaryDarkText ? '#000' : '#FFF'}
                        onClick={() => {
                          setViewState({
                            ...viewState,
                            navDrawerOpen: false
                          });
                        }}
                        variant="subtle"
                      >
                        <X size={25} />
                      </ActionIcon>
                    </AppFlexbox>

                    <AppStack style={{ gap: 0, padding: 10 }}>
                      <EvaluationSessionEvaluateMenuNav
                        currentView={viewState.currentView}
                        evaluationSessionEntry={selectedEvaluationSessionEntry}
                        filter={viewState.filter}
                        hasUnsavedChanges={hasUnsavedChanges}
                        isLoading={isLoading}
                        isSaving={isSaving}
                        onSaveChanges={onSaveEvaluationSessionChanges}
                        onSelectEvaluation={(i, subItem, closeDrawer) => {
                          onSelectEvaluation(i, subItem);
                          if (closeDrawer) {
                            setViewState({
                              ...viewState,
                              navDrawerOpen: false
                            });
                          }
                        }}
                        onSelectQuestion={(q) => {
                          onSelectQuestion(q);
                          if (
                            viewState.currentView ===
                            EVALUATION_SESSION_VIEWS_ENUM.player
                          ) {
                            setViewState({
                              ...viewState,
                              navDrawerOpen: false
                            });
                          }
                        }}
                        onViewChange={onViewChange}
                        regAssociationDivision={
                          regScoutReportEvaluationSession?.regAssociationDivision
                        }
                        regScoutReportEvaluations={regScoutReportEvaluations}
                        regScoutReportQuestions={metricQuestions}
                        regScoutReports={regScoutReports}
                        selectedRegScoutReportEvaluation={
                          selectedRegScoutReportEvaluation
                        }
                        selectedRegScoutReportQuestion={
                          selectedRegScoutReportQuestion
                        }
                        setFilter={(filter) =>
                          setViewState({ ...viewState, filter })
                        }
                      />
                    </AppStack>
                  </AppStack>
                </Drawer>
                <Burger
                  aria-label="Toggle navigation"
                  color={isSecondaryDarkText ? '#000' : '#FFF'}
                  onClick={() => {
                    setViewState({
                      ...viewState,
                      navDrawerOpen: true
                    });
                  }}
                  opened={viewState.navDrawerOpen}
                />
              </AppFlexbox>

              <AppFlexbox
                style={{ flex: 1, gap: 15, alignItems: 'center' }}
                visibleFrom="md"
              >
                <ActionableIcon
                  color={secondaryColor}
                  onClick={() =>
                    navigate(
                      `/admin/evaluations/sessions/${regScoutReportEvaluationSession.pkRegScoutReportEvaluationSession}`
                    )
                  }
                  radius={100}
                  size={50}
                  style={{ color: isSecondaryDarkText ? '#000' : '#FFF' }}
                  variant="filled"
                >
                  <ArrowLeft size={30} />
                </ActionableIcon>
                <AppStack style={{ gap: 0 }}>
                  <AppText
                    style={{ fontSize: 18, lineHeight: '22px' }}
                    weight={500}
                  >
                    {regScoutReportEvaluationSession.name}
                  </AppText>
                  <AppText
                    style={{ color: isPrimaryDarkText ? '#000' : '#e2e8f0' }}
                  >
                    {
                      regScoutReportEvaluationSession.regAssociationDivision
                        .name
                    }
                  </AppText>
                </AppStack>
              </AppFlexbox>
              <AppFlexbox
                style={{
                  gap: 10,
                  justifyContent: 'center',
                  alignItems: 'center',
                  flex: isMediumView ? 1 : 'unset',
                  marginRight: isMediumView ? 34 : 0
                }}
              >
                {selectedRegScoutReportEvaluation &&
                viewState.currentView ===
                  EVALUATION_SESSION_VIEWS_ENUM.player ? (
                  <>
                    <AppStack
                      style={{
                        border: isSelectedEvaluationCompleted
                          ? 'solid 3px #40C057'
                          : 'solid 3px #FCC419',
                        borderRadius: 100,
                        padding: 1
                      }}
                    >
                      <ColoredAvatar
                        name={`${selectedRegScoutReportEvaluation.person.firstName} ${selectedRegScoutReportEvaluation.person.lastName}`}
                        style={{ padding: 0 }}
                        uniqueId={selectedRegScoutReportEvaluation.person.email}
                      />
                    </AppStack>
                    {selectedRegScoutReportEvaluation.jerseyColor && (
                      <AppStack
                        style={{
                          border: 'solid 1px #e2e8f0',
                          backgroundColor:
                            selectedRegScoutReportEvaluation.jerseyColor,
                          height: 20,
                          width: 20,
                          borderRadius: 18
                        }}
                      />
                    )}
                    <AppText style={{ fontSize: 18 }} weight={500}>
                      {selectedRegScoutReportEvaluation.jerseyNumber
                        ? `${selectedRegScoutReportEvaluation.jerseyNumber} - `
                        : ''}
                      {selectedRegScoutReportEvaluation.person.firstName}{' '}
                      {selectedRegScoutReportEvaluation.person.lastName}
                    </AppText>
                  </>
                ) : (
                  selectedRegScoutReportQuestion &&
                  viewState.currentView ===
                    EVALUATION_SESSION_VIEWS_ENUM.criterion && (
                    <AppText style={{ fontSize: 18 }} weight={500}>
                      {selectedRegScoutReportQuestion.value}
                    </AppText>
                  )
                )}
              </AppFlexbox>

              <AppFlexbox
                style={{
                  gap: 15,
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'end'
                }}
                visibleFrom="md"
              >
                <HeaderAccountNavigationMenu
                  control={
                    <AppFlexbox
                      style={{
                        gap: 15,
                        alignItems: 'center',
                        justifyContent: 'end'
                      }}
                    >
                      <AppStack style={{ gap: 0, textAlign: 'end' }}>
                        {regAssociation && (
                          <AppText
                            style={{
                              fontSize: 18,
                              lineHeight: '22px',
                              color: isPrimaryDarkText ? '#000' : '#e2e8f0'
                            }}
                            weight={500}
                          >
                            {regAssociation.association.name}
                          </AppText>
                        )}

                        <AppText style={{ color: '#e2e8f0' }}>
                          {authState.userData.user.name}
                        </AppText>
                      </AppStack>
                      <AppImage
                        alt="SportsHeadz Main Logo"
                        fit="contain"
                        height={50}
                        src={headerImage}
                        style={{ minWidth: 'unset', maxWidth: 125 }}
                        width="100%"
                      />
                    </AppFlexbox>
                  }
                  lightMode={lightMode}
                />
              </AppFlexbox>
            </>
          )}
        </AppFlexbox>
      </AppCard>

      <AppFlexbox
        style={{
          flex: 1,
          gap: 5,
          maxHeight: 'calc(100vh - 80px)',
          overflow: 'hidden'
        }}
      >
        {isLoading ? (
          <EvaluationSessionEvaluatePlayersView isLoading />
        ) : viewState.currentView === EVALUATION_SESSION_VIEWS_ENUM.player ? (
          <EvaluationSessionEvaluatePlayersView
            currentView={viewState.currentView}
            evaluationSessionEntry={selectedEvaluationSessionEntry}
            filter={viewState.filter}
            hasUnsavedChanges={hasUnsavedChanges}
            isLoading={isLoading}
            isSaving={isSaving}
            isSmallScreen={isMobileView}
            onSaveChanges={onSaveEvaluationSessionChanges}
            onSelectEvaluation={onSelectEvaluation}
            onSelectQuestion={onSelectQuestion}
            onUpdateEvaluationAnswer={onUpdateEvaluationAnswer}
            onViewChange={onViewChange}
            regAssociationDivision={
              regScoutReportEvaluationSession?.regAssociationDivision
            }
            regScoutReportEvaluations={regScoutReportEvaluations}
            regScoutReportQuestions={metricQuestions}
            regScoutReports={regScoutReports}
            selectedRegScoutReportEvaluation={selectedRegScoutReportEvaluation}
            selectedRegScoutReportQuestion={selectedRegScoutReportQuestion}
            setFilter={(filter) => setViewState({ ...viewState, filter })}
          />
        ) : viewState.currentView ===
          EVALUATION_SESSION_VIEWS_ENUM.criterion ? (
          <EvaluationSessionEvaluateCriterionView
            currentView={viewState.currentView}
            evaluationSessionEntry={selectedEvaluationSessionEntry}
            filter={viewState.filter}
            hasUnsavedChanges={hasUnsavedChanges}
            isSaving={isSaving}
            isSmallScreen={isMobileView}
            onSaveChanges={onSaveEvaluationSessionChanges}
            onSelectEvaluation={onSelectEvaluation}
            onSelectQuestion={onSelectQuestion}
            onUpdateEvaluationAnswer={onUpdateEvaluationAnswer}
            onViewChange={onViewChange}
            regAssociationDivision={
              regScoutReportEvaluationSession?.regAssociationDivision
            }
            regScoutReportEvaluations={regScoutReportEvaluations}
            regScoutReportQuestions={metricQuestions}
            regScoutReports={regScoutReports}
            selectedRegScoutReportEvaluation={selectedRegScoutReportEvaluation}
            selectedRegScoutReportQuestion={selectedRegScoutReportQuestion}
            setFilter={(filter) => setViewState({ ...viewState, filter })}
          />
        ) : (
          <EvaluationSessionEvaluateGridView
            currentView={viewState.currentView}
            evaluationSessionEntry={selectedEvaluationSessionEntry}
            hasUnsavedChanges={hasUnsavedChanges}
            isSaving={isSaving}
            onSaveChanges={onSaveEvaluationSessionChanges}
            onUpdateEvaluationAnswer={onUpdateEvaluationAnswer}
            onUpdateEvaluations={onUpdateEvaluations}
            onViewChange={onViewChange}
            regScoutReportEvaluations={regScoutReportEvaluations}
            regScoutReportEvaluationSession={regScoutReportEvaluationSession}
            regScoutReportQuestions={metricQuestions}
            regScoutReports={regScoutReports}
          />
        )}
      </AppFlexbox>
      <ConfirmModal
        cancelActionText="Keep Evaluating"
        confirmActionText="Close Session"
        isOpen={viewState.showCompleteModal}
        message="Evaluation has been completed"
        onCancel={() =>
          setViewState({ ...viewState, showCompleteModal: false })
        }
        onConfirm={() =>
          navigate(
            `/admin/evaluations/sessions/${regScoutReportEvaluationSession.pkRegScoutReportEvaluationSession}`
          )
        }
        title="Evaluation Complete"
      >
        <Checkbox
          checked={viewState.dontShowCompletedConfirmation}
          label="Dont show again"
          onChange={() =>
            setViewState({
              ...viewState,
              dontShowCompletedConfirmation: !viewState.dontShowCompletedConfirmation
            })
          }
          style={{ fontWeight: 500 }}
        />
      </ConfirmModal>
    </AppStack>
  );
};

EvaluationSessionEvaluateView.propTypes = {};

export default EvaluationSessionEvaluateView;
