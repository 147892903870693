import React, { useState } from 'react';
import { Accordion, Button, Checkbox, Switch } from '@mantine/core';
import PropTypes from 'prop-types';
import { DeviceFloppy } from 'tabler-icons-react';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import EvaluationTemplateQuestion from './EvaluationTemplateQuestion';
import EvaluationSessionEvaluateMenuNav from './EvaluationSessionEvaluateMenuNav';
import { validateEvaluationQuestionRestrictions } from '../../../helpers/evaluationPlayerHelper';

const EVALUATION_SESSION_VIEWS_ENUM = {
  player: 'SCORE_BY_PLAYER',
  criterion: 'SCORE_BY_CRITERION',
  grid: 'SCORING_GRID'
};

const EvaluationSessionEvaluateCriterionView = ({
  regScoutReports,
  onSelectEvaluation,
  onSelectQuestion,
  regScoutReportEvaluations,
  regScoutReportQuestions,
  regAssociationDivision,
  evaluationSessionEntry,
  selectedRegScoutReportEvaluation,
  selectedRegScoutReportQuestion,
  currentView,
  onViewChange,
  onUpdateEvaluationAnswer,
  onSaveChanges,
  hasUnsavedChanges,
  isSaving,
  filter,
  setFilter,
  isSmallScreen
}) => {
  const [expandScoringState, setExpandScoringState] = useState(true);

  return (
    <>
      <AppStack
        style={{
          flex: 1,
          minWidth: 450,
          maxWidth: 600,
          overflow: 'auto',
          padding: 10
        }}
        visibleFrom="md"
      >
        <EvaluationSessionEvaluateMenuNav
          currentView={currentView}
          evaluationSessionEntry={evaluationSessionEntry}
          filter={filter}
          hasUnsavedChanges={hasUnsavedChanges}
          isSaving={isSaving}
          onSaveChanges={onSaveChanges}
          onSelectEvaluation={onSelectEvaluation}
          onSelectQuestion={onSelectQuestion}
          onViewChange={onViewChange}
          regAssociationDivision={regAssociationDivision}
          regScoutReportEvaluations={regScoutReportEvaluations}
          regScoutReportQuestions={regScoutReportQuestions}
          regScoutReports={regScoutReports}
          selectedRegScoutReportEvaluation={selectedRegScoutReportEvaluation}
          selectedRegScoutReportQuestion={selectedRegScoutReportQuestion}
          setFilter={setFilter}
        />
      </AppStack>
      <AppStack
        style={{
          gap: 0,
          padding: 10,
          paddingBottom: 60,
          flex: 3,
          overflow: 'auto'
        }}
      >
        <AppFlexbox style={{ justifyContent: 'space-between' }}>
          <AppText style={{ fontSize: 18, fontWeight: 500 }}>Scoring</AppText>
          <Button
            color="dark"
            onClick={() => onViewChange(EVALUATION_SESSION_VIEWS_ENUM.grid)}
            size="compact-md"
            variant="outline"
            visibleFrom="md"
          >
            SCORING GRID
          </Button>
        </AppFlexbox>
        <AppStack style={{ gap: 10 }}>
          <AppFlexbox>
            <Switch
              checked={expandScoringState}
              label="EXPAND"
              onChange={() => setExpandScoringState(!expandScoringState)}
            />
          </AppFlexbox>
          <Checkbox
            checked={filter.sortBy === 'name'}
            label="SORT BY NAME"
            onChange={() =>
              setFilter({
                ...filter,
                sortBy: 'name'
              })
            }
          />
          <Checkbox
            checked={filter.sortBy === 'number'}
            label="SORT BY NUMBER"
            onChange={() =>
              setFilter({
                ...filter,
                sortBy: 'number'
              })
            }
          />

          <Accordion
            onChange={() => setExpandScoringState(!expandScoringState)}
            styles={{
              item: { border: 'none' },
              control: { padding: 0, paddingBottom: 10 },
              label: { padding: 0 },
              content: { padding: 0 }
            }}
            value={expandScoringState ? 'scoring' : ''}
          >
            <Accordion.Item value="scoring">
              <Accordion.Control>
                <AppFlexbox
                  style={{
                    justifyContent: 'space-between',
                    minHeight: 30,
                    alignItems: 'center',
                    marginRight: 10
                  }}
                >
                  <AppText style={{ fontSize: 18, fontWeight: 500 }}>
                    {regAssociationDivision.name}
                  </AppText>
                  {hasUnsavedChanges && (
                    <Button
                      color="blue"
                      loading={isSaving}
                      onClick={(e) => {
                        e.stopPropagation();
                        onSaveChanges();
                      }}
                      rightSection={<DeviceFloppy />}
                      size="compact-md"
                      variant="filled"
                    >
                      Save Changes
                    </Button>
                  )}
                </AppFlexbox>
              </Accordion.Control>
              <Accordion.Panel>
                <AppStack style={{ gap: 10 }}>
                  {selectedRegScoutReportQuestion ? (
                    regScoutReportEvaluations
                      .filter((e) =>
                        validateEvaluationQuestionRestrictions(
                          e,
                          selectedRegScoutReportQuestion
                        )
                      )
                      .sort((a, b) =>
                        filter.sortBy === 'number'
                          ? a.jerseyNumber - b.jerseyNumber ||
                            `${a.person.firstName}${a.person.lastName}`.localeCompare(
                              `${b.person.firstName}${b.person.lastName}`
                            ) ||
                            new Date(a.person.createdAt) -
                              new Date(b.person.createdAt)
                          : `${a.person.firstName}${a.person.lastName}`.localeCompare(
                              `${b.person.firstName}${b.person.lastName}`
                            ) ||
                            new Date(a.person.createdAt) -
                              new Date(b.person.createdAt)
                      )
                      .map((e) => (
                        <EvaluationTemplateQuestion
                          key={e.pkRegScoutReportEvaluation}
                          evaluation={e}
                          evaluationSessionEntry={evaluationSessionEntry}
                          id={`question-card-${e.pkRegScoutReportEvaluation}`}
                          isSelected={
                            selectedRegScoutReportEvaluation?.pkRegScoutReportEvaluation ===
                            e.pkRegScoutReportEvaluation
                          }
                          isSmallScreen={isSmallScreen}
                          onUpdateEvaluationAnswer={onUpdateEvaluationAnswer}
                          question={selectedRegScoutReportQuestion}
                          showPlayerView
                        />
                      ))
                  ) : (
                    <AppStack style={{ padding: 30 }}>
                      <AppText style={{ textAlign: 'center', fontWeight: 500 }}>
                        Select a question to begin evaluating
                      </AppText>
                    </AppStack>
                  )}
                </AppStack>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </AppStack>
      </AppStack>
    </>
  );
};

EvaluationSessionEvaluateCriterionView.propTypes = {
  currentView: PropTypes.string,
  evaluationSessionEntry: PropTypes.object,
  filter: PropTypes.object,
  hasUnsavedChanges: PropTypes.bool,
  isSaving: PropTypes.bool,
  isSmallScreen: PropTypes.bool,
  onSaveChanges: PropTypes.func,
  onSelectEvaluation: PropTypes.func,
  onSelectQuestion: PropTypes.func,
  onUpdateEvaluationAnswer: PropTypes.func,
  onViewChange: PropTypes.func,
  regAssociationDivision: PropTypes.object,
  regScoutReportEvaluations: PropTypes.array,
  regScoutReportQuestions: PropTypes.array,
  regScoutReports: PropTypes.array,
  selectedRegScoutReportEvaluation: PropTypes.object,
  selectedRegScoutReportQuestion: PropTypes.object,
  setFilter: PropTypes.func
};

export default EvaluationSessionEvaluateCriterionView;
